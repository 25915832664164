import { Button } from 'antd';
import { Fragment } from 'react';
import { getInputElementsSingle } from '../../../utils/get-input-elements';
import { DetailsTabElementsType } from '../helpers';

type PropsType = {
  detailsTabElements: DetailsTabElementsType;
  detailsInputChangedHandler: (
    name: keyof DetailsTabElementsType,
    value: string
  ) => void;
  setCurrentTabIndex: (index: string) => void;
};

function DetailsTab({
  detailsTabElements,
  detailsInputChangedHandler,
  setCurrentTabIndex,
}: PropsType) {
  const single = getInputElementsSingle({
    formElements: { ...detailsTabElements },
    inputChangedHandler: detailsInputChangedHandler,
    sliceValue: [0, 3],
  });

  return (
    <Fragment>
      {single}

      <div className="row justify-content-center">
        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4">
          <Button
            block
            type="primary"
            htmlType="button"
            size="large"
            onClick={() => setCurrentTabIndex('2')}>
            Next
          </Button>
        </div>
      </div>
    </Fragment>
  );
}

export default DetailsTab;
