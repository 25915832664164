import { Fragment } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import PasswordChange from '../components/PasswordChange';
import { UserDataType } from '../type-definitions/api-types';

interface PropsType extends RouteComponentProps {
  isAuth?: boolean;
  userData: Partial<UserDataType>;
  userPermissionList: string[];
}

function UserChangePassword({
  isAuth,
  userData,
  userPermissionList,
}: PropsType) {
  if (!userData.userID) {
    return <Redirect to="/" />;
  }
  return (
    <Fragment>
      <PasswordChange
        isUserChangePass={true}
        userPermissionList={userPermissionList}
        isAuth={isAuth}
      />
    </Fragment>
  );
}
export default UserChangePassword;
