import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Fragment, useEffect, useRef, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { Modal, Checkbox, Row, Col, Button } from 'antd';

interface PropsType {
  checkboxListData: {
    [k: string]: boolean;
  };
  handleListData: (
    data: { [k: string]: boolean },
    checkboxListName?: string,
    modalKeyName?: string,
    inputFieldName?: any
  ) => void;
  isDisabled: boolean;
  showModal: boolean;
  handleModal: () => void;
  checkboxListName?: string;
  modalKeyName?: string;
  inputFieldName?: any;
}

interface StateType {
  isAllChecked: boolean;
  checkboxList: {
    [k: string]: boolean;
  };
}

const initState = {
  isAllChecked: false,
  checkboxList: {},
};

function CheckboxListModal({
  checkboxListData,
  handleListData,
  isDisabled,
  showModal,
  handleModal,
  checkboxListName,
  modalKeyName,
  inputFieldName,
}: PropsType) {
  const [state, setState] = useState<Readonly<StateType>>(initState);
  const _isMounted = useRef(false);

  function handleState(data: object) {
    _isMounted.current &&
      setState((prevState) => ({
        ...prevState,
        ...data,
      }));
  }

  useEffect(() => {
    _isMounted.current = true;
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    let isAllChecked = false;
    if (checkboxListData) {
      isAllChecked = Object.keys(checkboxListData).every(
        (item) => checkboxListData[item]
      );

      handleState({ isAllChecked, checkboxList: checkboxListData });
    }
  }, [checkboxListData]);

  function onChangeSelect(event: CheckboxChangeEvent) {
    const name = event?.target?.name;
    const checked = event?.target?.checked;
    const fields = cloneDeep(state.checkboxList);
    if (name) {
      if (name === 'selectAll') {
        for (const key in fields) {
          fields[key] = checked;
        }
        handleState({ checkboxList: fields, isAllChecked: checked });
      } else {
        fields[name] = checked;
        const isAllChecked = Object.keys(fields).every((item) => fields[item]);
        handleState({ checkboxList: fields, isAllChecked });
      }
    }
  }

  function onModalOkay() {
    handleListData(
      state.checkboxList,
      checkboxListName,
      modalKeyName,
      inputFieldName
    );
  }

  let isEditable = true;
  if (isDisabled) {
    isEditable = false;
  }

  const footerElements = [
    <Button onClick={handleModal} htmlType="button" key={1}>
      Cancel
    </Button>,
  ];
  if (isEditable) {
    footerElements.push(
      <Button type="primary" onClick={onModalOkay} htmlType="button" key={2}>
        OK
      </Button>
    );
  }

  return (
    <Fragment>
      <Modal
        visible={showModal}
        // onCancel={handleModal}
        // onOk={onModalOkay}
        width={500}
        closable={false}
        footer={[...footerElements]}>
        {isEditable && (
          <Row justify="center">
            <Col className="pb-3">
              <Checkbox
                name={`selectAll`}
                onChange={onChangeSelect}
                checked={state.isAllChecked}>
                {!state.isAllChecked ? `Select All` : `Deselect All`}
              </Checkbox>
            </Col>
          </Row>
        )}
        <Row>
          {state.checkboxList &&
            Object.keys(state.checkboxList).map((item, index) => {
              return (
                <Col xs={24} sm={12} className="py-2" key={index}>
                  <Checkbox
                    disabled={!isEditable}
                    name={item}
                    onChange={onChangeSelect}
                    checked={state.checkboxList[item]}>
                    {item}
                  </Checkbox>
                </Col>
              );
            })}
        </Row>
      </Modal>
    </Fragment>
  );
}

export default CheckboxListModal;
