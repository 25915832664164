import {
  FormEvent,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import axios, { CancelTokenSource } from 'axios';
import { v4 } from 'uuid';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { Row, Col } from 'antd';

import update from 'immutability-helper';

import {
  ApiErrorType,
  ApiSuccessType,
  GroupListType,
  UserDataType,
} from '../../../type-definitions/api-types';
import { Input } from 'antd';
import { updateToken } from '../../../redux/actions';
import { apiCall } from '../../../api-services/api';
import { userApi } from '../../../api-services/api-list';
import AntdTable from '../../AntdTable';
import { getKeysFromEnum, handleTableSearch } from '../../../utils';
import { GroupListEnumKeys } from '../../../api-services/api-responses';
import NotificationHandler from '../../NotificationHandler';
import FilterInput from '../../FilterInput';

const initState = {
  groupList: [],
  initGroupList: [],
  userGroupList: [],
  initUserGroupList: [],
  loading: true,
  success: {},
  error: {},

  userGroupSearchInput: '',
  searchValueGroups: '',
  partnerDataGroups: {},
};

type PropsType = {
  userID: string;
  userData: Partial<UserDataType>;
  partnerLevel: string;
  hasEditRights: boolean;
};

type GroupsTabStateType = {
  groupList: GroupListType[];
  initGroupList: GroupListType[];
  userGroupList: GroupListType[];
  initUserGroupList: GroupListType[];
  loading: boolean;
  success: ApiSuccessType;
  error: ApiErrorType;
  userGroupSearchInput: string;

  searchValueGroups: string;
  partnerDataGroups: { [k: string]: boolean };
};

const tableColumnKeys = getKeysFromEnum(GroupListEnumKeys);

function GroupsTab({
  userData,
  userID,
  hasEditRights,
  partnerLevel,
}: PropsType) {
  const [state, setState] = useState<Readonly<GroupsTabStateType>>(initState);
  const _isMounted = useRef(false);
  const axiosCancelSource = useRef<CancelTokenSource>();
  const dispatch = useDispatch();

  function handleState(data: object) {
    _isMounted.current &&
      setState((prevState) => ({
        ...prevState,
        ...data,
      }));
  }

  useEffect(() => {
    axiosCancelSource.current = axios.CancelToken.source();
    _isMounted.current = true;
    return () => {
      _isMounted.current = false;
      axiosCancelSource.current?.cancel('Component Unmounted');
    };
  }, []);

  const handleFetchedData = useCallback(() => {
    async function fetchGroupData({
      userData,
      userID,
    }: {
      userData: Partial<UserDataType>;
      userID: string;
    }) {
      const data: {
        error?: ApiErrorType;
        groupList?: GroupListType[];
        userGroupList?: GroupListType[];
      } = {};
      try {
        const group = userApi.getAssignableUserGroups();
        const userGroup = userApi.getGroups(null, { userID });
        const values = await Promise.all([
          apiCall({
            storeToken: userData.token,
            url: group.url,
            method: group.method,
            contentType: group.contentType,
            cancelToken: axiosCancelSource.current?.token,
          }),
          apiCall({
            storeToken: userData.token,
            url: userGroup.url,
            method: userGroup.method,
            contentType: userGroup.contentType,
            cancelToken: axiosCancelSource.current?.token,
          }),
        ]);
        const response = values[0];
        const response2 = values[1];
        const result = response?.data;
        const result2 = response2?.data;
        dispatch(updateToken(result));
        dispatch(updateToken(result2));
        if (result?.status === 'error') {
          data.error = result;
        } else {
          data.groupList = result.data;
        }
        if (result2?.status === 'error') {
          data.error = result2;
        } else {
          data.userGroupList = result2.data;
        }
      } catch (error: any) {
        data.error = error;
      }

      return data;
    }

    (async function () {
      if (!state.loading) {
        handleState({ loading: true });
      }
      const stateData: Partial<GroupsTabStateType> = {};
      try {
        const {
          groupList,
          userGroupList,
          error,
        }: {
          error?: ApiErrorType;
          groupList?: GroupListType[];
          userGroupList?: GroupListType[];
        } = await fetchGroupData({
          userData,
          userID,
        });

        stateData.loading = false;
        if (error) {
          stateData.error = error;
        } else {
          if (
            groupList &&
            userGroupList &&
            groupList.length > 0 &&
            userGroupList.length > 0
          ) {
            const tempUserGroupList = userGroupList.map((item) => {
              item.uuid = v4();
              return item;
            });

            const tempGroupList = groupList.filter((item) => {
              item.uuid = v4();
              return !tempUserGroupList.find(
                (el) => el.groupID === item.groupID
              );
            });

            let tempPartnerData: { [k: string]: boolean } = {};
            if (tempGroupList) {
              tempGroupList.forEach((el) => {
                if (el.partnerID) {
                  tempPartnerData = update(tempPartnerData, {
                    [el.partnerID]: { $set: true },
                  });
                }
              });
            }

            stateData.userGroupList = tempUserGroupList;
            stateData.initUserGroupList = tempUserGroupList;
            stateData.groupList = tempGroupList;
            stateData.initGroupList = tempGroupList;
            stateData.partnerDataGroups = tempPartnerData;
          } else if (groupList && groupList.length > 0) {
            const tempGroupList = groupList.filter((item) => {
              item.uuid = v4();
              return item;
            });
            let tempPartnerData: { [k: string]: boolean } = {};
            if (tempGroupList) {
              tempGroupList.forEach((el) => {
                if (el.partnerID) {
                  tempPartnerData = update(tempPartnerData, {
                    [el.partnerID]: { $set: true },
                  });
                }
              });
            }
            stateData.groupList = tempGroupList;
            stateData.initGroupList = tempGroupList;
            stateData.partnerDataGroups = tempPartnerData;
          } else if (userGroupList && userGroupList.length > 0) {
            const tempUserGroupList = userGroupList.map((item) => {
              item.uuid = v4();
              return item;
            });
            stateData.userGroupList = tempUserGroupList;
            stateData.initUserGroupList = tempUserGroupList;
          }
        }
      } catch (error: any) {
        stateData.loading = false;
        stateData.error = error;
      }

      handleState({ ...stateData });
    })();
  }, [dispatch, state.loading, userData, userID]);

  useEffect(() => {
    handleFetchedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddGroup = useCallback(
    async (groupID: string) => {
      if (!state.loading) {
        handleState({ loading: true });
      }

      try {
        const { url, method, contentType } = userApi.putGroup(null, {
          groupID,
          userID,
        });

        const response = await apiCall({
          storeToken: userData?.token,
          url,
          method,
          contentType,
          cancelToken: axiosCancelSource.current?.token,
        });

        const result = response?.data;
        if (result) {
          dispatch(updateToken(result));
        }

        if (result.status === 'ok') {
          handleState({ success: result });
          setTimeout(() => {
            handleFetchedData();
          }, 100);
        } else {
          handleState({ error: result });
        }
      } catch (error) {
        handleState({ error, loading: false });
      }
    },
    // dispatch, handleFetchedData, state.loading, userData?.token, userID
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.loading]
  );

  // async function handleAddGroup(groupID: string) {

  // }

  const handleDeleteGroup = useCallback(
    async (groupID: string) => {
      if (!state.loading) {
        handleState({ loading: true });
      }

      try {
        const { url, method, contentType } = userApi.deleteGroup(null, {
          userID,
          groupID,
        });
        const response = await apiCall({
          storeToken: userData?.token,
          url,
          method,
          contentType,
          cancelToken: axiosCancelSource.current?.token,
        });
        const result = response?.data;
        if (result) {
          dispatch(updateToken(result));
        }
        if (result?.status === 'ok') {
          handleState({ success: result });
          setTimeout(() => {
            handleFetchedData();
          }, 100);
        } else {
          handleState({ error: result });
        }
      } catch (error) {
        handleState({ error, loading: false });
      }
    },
    // dispatch, handleFetchedData, state.loading, userData?.token, userID
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.loading]
  );

  function handleGroupSearch(event: FormEvent<HTMLInputElement>) {
    const value = event?.currentTarget?.value;
    const stateData: Partial<GroupsTabStateType> = {
      searchValueGroups: value,
    };
    let tempData = cloneDeep(state.initGroupList);
    if (value) {
      const columns = tableColumnKeys;
      tempData = handleTableSearch({
        data: tempData,
        columnList: columns,
        searchData: value,
      });
      stateData.groupList = tempData;
    } else {
      stateData.groupList = tempData;
    }
    handleState({ ...stateData });
  }

  function handleUserGroupSearch(event: FormEvent<HTMLInputElement>) {
    const value = event?.currentTarget?.value;
    const stateData: Partial<GroupsTabStateType> = {
      userGroupSearchInput: value,
    };
    if (value) {
      let tempData = cloneDeep(state.userGroupList);
      const columns = tableColumnKeys;
      tempData = handleTableSearch({
        data: tempData,
        columnList: columns,
        searchData: value,
      });
      stateData.userGroupList = tempData;
    } else {
      stateData.userGroupList = state.initUserGroupList;
    }
    handleState({ ...stateData });
  }

  const handleCheckboxFilter = useCallback(
    (checkBoxData: { [k: string]: boolean }) => {
      const tempGroups = cloneDeep(state.initGroupList);
      const filteredData = tempGroups?.filter((el) => {
        if (el.partnerID && checkBoxData[el.partnerID]) {
          return el;
        }
        return null;
      });

      handleState({ groupList: filteredData, partnerDataGroups: checkBoxData });
    },
    [state.initGroupList]
  );

  console.log('groupList', state.groupList);
  console.log('partnerData', state.partnerDataGroups);

  return (
    <Fragment>
      <NotificationHandler
        success={state.success}
        error={state.error}
        obj={{ _isMounted: _isMounted.current, setState }}
      />
      {/* ## -- User Groups Table -- */}
      <Row justify="end" className="pt-4">
        <Col xs={24} md={12}>
          <Input
            disabled={state.loading || !hasEditRights}
            type="text"
            value={state.userGroupSearchInput}
            placeholder="Search"
            name="searchInput"
            onChange={handleUserGroupSearch}
          />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col xs={24}>
          <AntdTable
            loading={state.loading}
            columns={getColumns({
              handleDeleteGroup,
              hasEditRights,
              partnerLevel,
            })}
            dataSource={state.userGroupList}
            pagination={{
              pageSize: 5,
              total: state.userGroupList.length,
            }}
            customConfig={{ rowKeyValue: 'uuid' }}
          />
        </Col>
      </Row>
      {/* ## -- User Groups Table -- end */}

      {/* ## -- Groups Table -- */}
      <Row justify="end" className="pt-4">
        <Col xs={24} md={12}>
          {/* <Input
            disabled={state.loading || !hasEditRights}
            type="text"
            value={state.searchValueGroups}
            placeholder="Search"
            name="searchInput"
            onChange={handleGroupSearch}
          /> */}

          <FilterInput
            handleSearchFilter={handleGroupSearch}
            loading={state.loading}
            searchValue={state.searchValueGroups}
            listData={state.partnerDataGroups}
            handleCheckboxFilter={handleCheckboxFilter}
          />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col xs={24}>
          <AntdTable
            loading={state.loading}
            columns={getColumns({ handleAddGroup, hasEditRights })}
            dataSource={state.groupList}
            pagination={{ pageSize: 5, total: state.groupList.length }}
            customConfig={{ rowKeyValue: 'uuid' }}
          />
        </Col>
      </Row>
      {/* ## -- Groups Table -- end */}
    </Fragment>
  );
}

export default GroupsTab;

function getColumns({
  hasEditRights,
  handleAddGroup,
  handleDeleteGroup,
  partnerLevel,
}: {
  hasEditRights: boolean;
  partnerLevel?: string;
  handleAddGroup?: (groupID: string) => void;
  handleDeleteGroup?: (groupID: string) => void;
}) {
  return [
    {
      title: 'Group Name',
      dataIndex: 'groupName',
      key: 'groupName',
    },
    {
      title: 'Partner',
      dataIndex: 'partnerID',
      key: 'partnerID',
    },
    {
      title: 'Action',
      key: 'action',
      render: (record: GroupListType) => {
        if (handleAddGroup) {
          const plusIconProps: {
            style?: { cursor: string; color: string };
            onClick?: () => void;
          } = {};
          if (hasEditRights) {
            plusIconProps.style = {
              cursor: 'pointer',
              color: '#2db98f',
            };
            plusIconProps.onClick = () => handleAddGroup(record.groupID);
          }
          return (
            <Fragment>
              <FaPlus size="1.2em" {...plusIconProps} />
            </Fragment>
          );
        }
        if (handleDeleteGroup) {
          const trashIconProps: {
            style?: { cursor: string; color: string };
            onClick?: () => void;
          } = {};
          if (hasEditRights) {
            trashIconProps.style = {
              cursor: 'pointer',
              color: 'red',
            };
            trashIconProps.onClick = () => handleDeleteGroup(record.groupID);
          }
          if (partnerLevel !== record.groupID) {
            return (
              <Fragment>
                <FaTrashAlt size="1.2em" {...trashIconProps} />
              </Fragment>
            );
          } else {
            return <Fragment></Fragment>;
          }
        }
      },
    },
  ];
}
