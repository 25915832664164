import { FormInputType } from '../../../type-definitions';

export const initialElements: FormElementsType = {
  userName: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'User Name',
      name: 'userName',
      // autoComplete: 'new-password',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'User Name is required',
    label: 'User Name *',
  },
};

export type FormElementsType = {
  userName: FormInputType;
};

export interface StateType {
  formElements: FormElementsType;
  loading: boolean;
}
