import { Button } from 'antd';
import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Popup } from 'react-leaflet';
import { deviceRoutes, locationRoutes } from '../../../Routes/routes-list';
import { deviceRights, locationRights } from '../../../utils/permission-list';
import { getUtcTime } from '../../../utils';

function MapCustomPopup({ item }: { item: any }) {
  const history = useHistory();

  const isStockLocation = item?.status === 'STOCK';

  function handleLocationRedirect() {
    history.push({
      pathname: locationRoutes.details(),
      state: {
        locationID: item?.locationID,
        allowAllEdit:
          item?.rights &&
          item.rights.length > 0 &&
          item.rights.includes(locationRights.edit),
        allowEdit:
          item?.rights &&
          item.rights.length > 0 &&
          item.rights.includes(locationRights.addNote),
      },
    });
  }

  function handleDeviceRedirect() {
    history.push({
      pathname: deviceRoutes.details(),
      state: {
        deviceID: item?.locationMac,
        allowEdit:
          item?.rights &&
          item.rights.length > 0 &&
          item.rights.includes(deviceRights.edit),
      },
    });
  }

  function handleDeviceRedirectStock() {
    history.push({
      pathname: locationRoutes.details(),
      state: {
        locationID: item?.locationID,
        allowAllEdit:
          item?.rights &&
          item.rights.length > 0 &&
          item.rights.includes(locationRights.edit),
        allowEdit:
          item?.rights &&
          item.rights.length > 0 &&
          item.rights.includes(locationRights.addNote),
        hasStock: isStockLocation,
      },
    });
  }

  const deviceIDButtonProps = {
    onClick: handleDeviceRedirect,
  };
  if (isStockLocation) {
    deviceIDButtonProps.onClick = handleDeviceRedirectStock;
  }

  return (
    <Fragment>
      <Popup>
        {/* <div className="row">
          <div className="col-12">
            Current location: {item?.locationName}
            <pre>
              {JSON.stringify({ lat: item.lng, lng: item.lng }, null, 2)}
            </pre>
          </div>
        </div> */}
        {!isStockLocation && (
          <div className="row">
            <div className="col-12">
              Last Contact:{' '}
              {getUtcTime({ date: item?.lastContact, format: 'dateTime' })}
            </div>
          </div>
        )}

        <div className="row pt-2 justify-content-center">
          <div className="col-12 text-center">
            {'Location: '}
            <Button
              size="small"
              type="primary"
              htmlType="button"
              onClick={handleLocationRedirect}>
              {item?.locationName}
            </Button>
          </div>
          <div className="col-12 text-center pt-2">
            {isStockLocation ? `Stock: ` : `Device: `}
            <Button
              {...deviceIDButtonProps}
              disabled={item?.locationMac === 'UNALLOCATED'}
              size="small"
              type="primary"
              htmlType="button">
              {isStockLocation ? 'Devices' : item?.locationMac}
            </Button>
          </div>
        </div>
      </Popup>
    </Fragment>
  );
}

export default MapCustomPopup;
