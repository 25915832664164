import React, { ReactNode } from 'react';
import AntdCoverSpinner from '../AntdCoverSpinner';
import AntdCard from '../../components-shared/AntdCard';

type PropsType = Partial<{
  title: string;
  children: ReactNode;
  loading: boolean;
  titleRootClasses: string;
  childrenRootClasses: string;
  cardClasses: string;
}>;

function FormWrapper({
  title,
  children,
  loading,
  titleRootClasses,
  childrenRootClasses,
  cardClasses,
}: PropsType) {
  return (
    <React.Fragment>
      <AntdCoverSpinner active={loading} size="large">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-6 col-md-8 col-sm-10">
            <AntdCard classNames={cardClasses || ''}>
              <div className={`${titleRootClasses || 'row py-4 px-4'}`}>
                <div className="col-xl-12">
                  <h5>
                    <strong>{title}</strong>
                  </h5>
                </div>
              </div>
              <div className={`${childrenRootClasses || 'row py-4 px-4'}`}>
                <div className="col-xl-12">{children}</div>
              </div>
            </AntdCard>
          </div>
        </div>
      </AntdCoverSpinner>
    </React.Fragment>
  );
}

export default FormWrapper;
