import { combineReducers } from 'redux';
import auth from './auth.reducer';
import shared from './shared.reducer';
import device from './device.reducer';
import user from './user.reducers';
import location from './location.reducer';
import group from './group.reducer';
import partner from './partner.reducer';
import floorPlan from './floorPlan.reducer';
import hardware from './hardware.reducer';

export const rootReducer = combineReducers({
  auth,
  shared,
  device,
  user,
  location,
  group,
  partner,
  floorPlan,
  hardware,
});
