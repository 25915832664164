import { Button, Col, message, Row, Tag } from 'antd';
import { Component, Fragment } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import QueueAnim from 'rc-queue-anim';
import update from 'immutability-helper';
import { v4 } from 'uuid';
import Modal from 'antd/lib/modal/Modal';

import {
  AttributesTabElementsType,
  attributesTabInputElements,
  AttributesTabOptionsInputElementsType,
  attributesTabOptionsInputElements,
} from '../helpers';
import { checkValidation } from '../../../utils/validation';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';

import AntdInput from '../../AntdInput';

type PropsType = {
  handleAttributesTabElements: (data: AttributesTabElementsType[]) => void;
  attributesTabElements: AttributesTabElementsType[];
  loadingButton: boolean;
};
type StateType = {
  formElements: AttributesTabElementsType;
  showForm: boolean;
  showDefault: boolean;
  showOptionModal: boolean;
  formTypeInputElements: AttributesTabOptionsInputElementsType;
  isOptionsEditing: boolean;
  isEditing: boolean;
};
class AttributesTab extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      formElements: { ...attributesTabInputElements },
      showForm: true,
      showDefault: false,
      showOptionModal: false,
      formTypeInputElements: { ...attributesTabOptionsInputElements },
      isOptionsEditing: false,
      isEditing: true,
    };
  }

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    const { attributesTabElements } = this.props;
    if (attributesTabElements.length > 0) {
      const temp = attributesTabElements[0];
      this.handleState({ formElements: temp, showDefault: true });
    }
  }

  handleState = (data: Partial<StateType>) => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          ...prevState,
          ...data,
        };
      });
  };

  handleInputChanges = (
    name: keyof AttributesTabElementsType,
    value: string
  ) => {
    const { formElements } = this.state;

    let tempFormElements = { ...formElements };

    let stateData: Partial<StateType> = {};

    if (
      name === 'chars' &&
      value &&
      !checkValidation(value, { isNumeric: true })
    ) {
      message.error('Please enter valid input');
      return;
    }
    if (
      name &&
      name !== 'required' &&
      name !== 'options' &&
      name !== 'default' &&
      name !== 'uuid'
    ) {
      tempFormElements = update(tempFormElements, {
        [name]: {
          touched: { $set: true },
          valid: {
            $set: checkValidation(value, tempFormElements[name].validation),
          },
          value: { $set: value },
        },
      });

      if (name === 'type' && value === 'option') {
        stateData = update(stateData, { showDefault: { $set: true } });
      } else if (name === 'type' && value !== 'option') {
        stateData = update(stateData, { showDefault: { $set: false } });
      }
    } else if (name === 'default') {
      tempFormElements = update(tempFormElements, {
        [name]: {
          touched: { $set: true },
          valid: {
            $set: checkValidation(value, tempFormElements[name].validation),
          },
          value: { $set: value },
        },
      });
    }

    if (!tempFormElements.uuid) {
      tempFormElements = update(tempFormElements, {
        uuid: { $set: v4() },
      });
    }

    stateData = update(stateData, {
      formElements: { $set: tempFormElements },
    });

    this.handleState(stateData);
  };

  handleInputChangesOptions = (
    name: keyof AttributesTabOptionsInputElementsType,
    value: string
  ) => {
    const { formTypeInputElements } = this.state;

    let tempFormElements = { ...formTypeInputElements };

    if (name) {
      if (name !== 'uuid') {
        tempFormElements = update(tempFormElements, {
          [name]: {
            touched: { $set: true },
            valid: {
              $set: checkValidation(value, tempFormElements[name].validation),
            },
            value: { $set: value },
          },
        });
      }

      if (!tempFormElements.uuid) {
        tempFormElements = update(tempFormElements, {
          uuid: { $set: v4() },
        });
      }

      this.setState({ formTypeInputElements: tempFormElements });
    }
  };

  handleCheckbox = (event: CheckboxChangeEvent) => {
    const { formElements } = this.state;

    let tempFormElements = { ...formElements };

    tempFormElements = update(tempFormElements, {
      required: { $set: event.target.checked },
    });

    this.setState({ formElements: tempFormElements });
  };

  onShowForm = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showForm: true,
      };
    });
  };

  onDoneForm = () => {
    const { handleAttributesTabElements, attributesTabElements } = this.props;
    const { formElements, isEditing } = this.state;
    let tempFormElements = { ...formElements };
    let key: keyof typeof tempFormElements;

    let tempAttributesTabElements = [...attributesTabElements];

    for (key in tempFormElements) {
      if (
        key !== 'required' &&
        key !== 'options' &&
        key !== 'default' &&
        key !== 'uuid'
      ) {
        tempFormElements = update(tempFormElements, {
          [key]: {
            touched: { $set: true },
            valid: {
              $set: checkValidation(
                tempFormElements[key].value,
                tempFormElements[key].validation
              ),
            },
          },
        });
      }
    }

    let valid = true;
    for (key in tempFormElements) {
      if (
        key !== 'required' &&
        key !== 'options' &&
        key !== 'default' &&
        key !== 'uuid' &&
        !tempFormElements[key].valid
      ) {
        valid = false;
      }
    }

    if (
      tempFormElements.type.value === 'option' &&
      tempFormElements.options.length === 0
    ) {
      message.error('Please enter at least one options!');
      return;
    }

    const matchedNames = tempAttributesTabElements.find(
      (el) =>
        el.name.value.toLowerCase() ===
        tempFormElements.name.value.toLowerCase()
    );

    if (!isEditing) {
      if (matchedNames) {
        message.error(`Attribute Name with same text already exists!`);
        return;
      }

      if (valid) {
        tempAttributesTabElements = update(tempAttributesTabElements, {
          $push: [tempFormElements],
        });
        handleAttributesTabElements(tempAttributesTabElements);

        this.setState({
          isEditing: false,
          showForm: false,
          showDefault: false,
          showOptionModal: false,
          formElements: { ...attributesTabInputElements },
        });
      } else {
        this.setState({
          formElements: { ...tempFormElements },
        });
        message.error('Please fill all the fields');
        return;
      }
    } else {
      const matched = tempAttributesTabElements.find(
        (el) => el.uuid === tempFormElements.uuid
      );

      if (matchedNames && matchedNames.uuid !== matched?.uuid) {
        message.error(`Attribute Name with same text already exists!`);
        return;
      }

      tempAttributesTabElements = tempAttributesTabElements.map((el) => {
        if (el.uuid === tempFormElements.uuid) {
          return update(el, { $merge: { ...tempFormElements } });
        }
        return el;
      });

      handleAttributesTabElements(tempAttributesTabElements);

      this.setState({
        isEditing: false,
        showForm: false,
        showDefault: false,
        showOptionModal: false,
        formElements: { ...attributesTabInputElements },
      });
    }
  };

  onCloseForm = () => {
    this.setState({
      formElements: { ...attributesTabInputElements },
      showForm: false,
      showDefault: false,
      showOptionModal: false,
      isEditing: false,
    });
  };

  onAttributeTagClick = (id: string) => {
    const { formElements } = this.state;
    const { attributesTabElements } = this.props;

    const matchedFormElements = attributesTabElements.find(
      (el) => el.uuid === id
    );

    let tempFormElements = { ...formElements };

    let isDefaultShow = false;

    if (matchedFormElements) {
      isDefaultShow = matchedFormElements.type.value === 'option';

      let key: keyof typeof tempFormElements;
      for (key in tempFormElements) {
        if (key !== 'options' && key !== 'uuid' && key !== 'required') {
          tempFormElements = update(tempFormElements, {
            [key]: {
              value: {
                $set: matchedFormElements[key].value,
              },
            },
          });
        }
      }

      let tempDefaultOptionValues = matchedFormElements.options.map((el) => {
        return { text: el.label, value: el.value };
      });
      tempDefaultOptionValues = update(tempDefaultOptionValues, {
        $unshift: [{ text: 'Please Select', value: '' }],
      });
      tempFormElements = update(tempFormElements, {
        default: {
          optionValues: { $set: tempDefaultOptionValues },
          value: { $set: matchedFormElements.default.value },
        },
      });

      tempFormElements = update(tempFormElements, {
        required: { $set: matchedFormElements.required },
      });
      tempFormElements = update(tempFormElements, {
        options: { $set: matchedFormElements.options },
      });

      tempFormElements = update(tempFormElements, {
        uuid: { $set: matchedFormElements.uuid },
      });
    }

    this.setState({
      formElements: tempFormElements,
      showForm: true,
      showDefault: isDefaultShow,
      isEditing: true,
    });
  };

  openOptionsModal = () => {
    this.setState({ showOptionModal: true });
  };

  closeOptionsModal = () => {
    this.setState({
      showOptionModal: false,
      formTypeInputElements: { ...attributesTabOptionsInputElements },
      isOptionsEditing: false,
    });
  };

  onOptionsTagClick = (id: string) => {
    const { formElements, formTypeInputElements } = this.state;
    const matchedFormElements = formElements.options.find(
      (el) => el.uuid === id
    );
    let tempFormElements = { ...formTypeInputElements };
    if (matchedFormElements) {
      tempFormElements = update(tempFormElements, {
        label: {
          value: {
            $set: matchedFormElements.label,
          },
        },
        value: {
          value: {
            $set: matchedFormElements.value,
          },
        },
        uuid: { $set: matchedFormElements.uuid },
      });

      this.setState({
        formTypeInputElements: tempFormElements,
        showOptionModal: true,
        isOptionsEditing: true,
      });
    }
  };

  onDoneOptionsModal = () => {
    const { formElements, formTypeInputElements, isOptionsEditing } =
      this.state;
    let tempFormElements = { ...formElements };
    let tempFormTypeInputElements = { ...formTypeInputElements };

    let key: keyof typeof tempFormTypeInputElements;
    for (key in tempFormTypeInputElements) {
      if (key !== 'uuid') {
        tempFormTypeInputElements = update(tempFormTypeInputElements, {
          [key]: {
            touched: { $set: true },
            valid: {
              $set: checkValidation(
                tempFormTypeInputElements[key].value,
                tempFormTypeInputElements[key].validation
              ),
            },
          },
        });
      }
    }

    let valid = true;
    for (key in tempFormTypeInputElements) {
      if (key !== 'uuid' && !tempFormTypeInputElements[key].valid) {
        valid = false;
      }
    }

    if (!valid) {
      message.error('Please fill all the fields');
      return;
    }

    const matchedLabels = tempFormElements.options.find(
      (el) =>
        el.label.toLowerCase() ===
        tempFormTypeInputElements.label.value.toLowerCase()
    );

    if (!isOptionsEditing) {
      if (matchedLabels) {
        message.error(`"Labels" with same name already exists!`);
        return;
      }

      tempFormElements = update(tempFormElements, {
        options: {
          $push: [
            {
              label: tempFormTypeInputElements.label.value,
              value: tempFormTypeInputElements.value.value,
              uuid: tempFormTypeInputElements.uuid,
            },
          ],
        },
        default: {
          optionValues: {
            $push: [
              {
                text: tempFormTypeInputElements.label.value,
                value: tempFormTypeInputElements.value.value,
              },
            ],
          },
        },
      });
    } else {
      const matched = tempFormElements.options.find(
        (el) => el.uuid === tempFormTypeInputElements.uuid
      );

      if (matchedLabels && matchedLabels.uuid !== matched?.uuid) {
        message.error(`"Labels" with same name already exists!`);
        return;
      }

      const tempOptionsData = {
        label: tempFormTypeInputElements.label.value,
        value: tempFormTypeInputElements.value.value,
        uuid: tempFormTypeInputElements.uuid,
      };

      const tempOptions = tempFormElements.options.map((el) => {
        if (el.uuid === tempFormTypeInputElements.uuid) {
          return update(el, { $merge: { ...tempOptionsData } });
        }
        return el;
      });

      tempFormElements = update(tempFormElements, {
        options: { $set: tempOptions },
      });

      let tempDefaultOptionValues = tempOptions.map((el) => {
        return { text: el.label, value: el.value };
      });

      tempDefaultOptionValues = update(tempDefaultOptionValues, {
        $unshift: [{ text: 'Please Select', value: '' }],
      });

      tempFormElements = update(tempFormElements, {
        default: {
          optionValues: { $set: tempDefaultOptionValues },
          value: { $set: '' },
        },
      });
    }

    this.handleState({
      formElements: tempFormElements,
      showOptionModal: false,
      formTypeInputElements: { ...attributesTabOptionsInputElements },
      isOptionsEditing: false,
    });
  };

  onDelete = () => {
    const { formElements } = this.state;
    const { handleAttributesTabElements, attributesTabElements } = this.props;

    let temp = [...attributesTabElements];

    temp = temp.filter((el) => el.uuid !== formElements.uuid);

    handleAttributesTabElements(temp);

    this.handleState({
      showForm: false,
      formElements: { ...attributesTabInputElements },
      isEditing: false,
    });
  };

  onOptionsTagDelete = (id: string) => {
    let { formElements } = this.state;

    let tempOptions = [...formElements.options];
    let tempDefaultOptionValues = formElements?.default?.optionValues
      ? [...formElements.default.optionValues]
      : [];

    const matched = tempOptions.find((el) => el.uuid === id);

    tempOptions = tempOptions.filter((el) => el.uuid !== id);
    if (matched) {
      tempDefaultOptionValues = tempDefaultOptionValues.filter(
        (el) => el.text !== matched.label
      );
    }

    formElements = update(formElements, {
      options: { $set: tempOptions },
      default: { optionValues: { $set: tempDefaultOptionValues } },
    });

    this.handleState({ formElements, showOptionModal: false });
  };

  render() {
    const { attributesTabElements, loadingButton } = this.props;
    const {
      formElements,
      showForm,
      showDefault,
      showOptionModal,
      formTypeInputElements,
    } = this.state;
    const {
      required,
      options,
      default: defaultValue,
      uuid,
      ...restFormElements
    } = formElements;
    const { uuid: uuidOptions, ...restOptions } = formTypeInputElements;

    let isDeleteDisabled = true;
    if (restFormElements.name.value === 'transport') {
      isDeleteDisabled = true;
    }

    let defaultElem: JSX.Element | null = null;
    if (showDefault) {
      defaultElem = (
        <Row key={12}>
          <Col xs={24}>
            <AntdInput
              {...defaultValue}
              onInputChanged={this.handleInputChanges}
            />
          </Col>
        </Row>
      );
    }

    return (
      <Fragment>
        <Row justify="center" className="py-3">
          <Col>
            <Tag
              style={{
                cursor: !showForm ? 'pointer' : 'default',
                backgroundColor: !showForm ? '#1890ff' : '#FAFAFA',
                color: !showForm ? '#fff' : 'rgba(0, 0, 0, 0.85)',
                borderColor: !showForm ? '#1890ff' : '#d9d9d9',
              }}
              onClick={!showForm ? this.onShowForm : () => {}}>
              <AiOutlinePlus /> Add Attribute
            </Tag>
          </Col>
        </Row>

        <Row justify="center" className="py-3">
          {attributesTabElements.length > 0 &&
            attributesTabElements.map((item) => {
              return (
                <Fragment key={item.uuid}>
                  <Col className="py-2">
                    <Tag
                      key={item.uuid}
                      style={{ fontSize: '0.8rem', cursor: 'pointer' }}
                      color="blue"
                      onClick={() => this.onAttributeTagClick(item.uuid)}>
                      {item.name.value}
                    </Tag>
                  </Col>
                </Fragment>
              );
            })}
        </Row>

        <Row justify="center" className="py-2">
          <Col>
            <QueueAnim leaveReverse>
              {showForm
                ? [
                    <Row align="middle" className="pb-3" key={5}>
                      <Col xs={6}>Required</Col>
                      <Col xs={6}>
                        <Checkbox
                          checked={formElements.required}
                          onChange={this.handleCheckbox}></Checkbox>
                      </Col>
                    </Row>,
                    <Row key={6}>
                      <Col xs={24}>
                        <AntdInput
                          {...restFormElements.name}
                          onInputChanged={this.handleInputChanges}
                        />
                      </Col>
                    </Row>,
                    <Row key={7}>
                      <Col xs={24}>
                        <AntdInput
                          {...restFormElements.label}
                          onInputChanged={this.handleInputChanges}
                        />
                      </Col>
                    </Row>,
                    <Row key={8}>
                      <Col xs={24}>
                        <AntdInput
                          {...restFormElements.helptext}
                          onInputChanged={this.handleInputChanges}
                        />
                      </Col>
                    </Row>,
                    <Row key={9}>
                      <Col xs={24}>
                        <AntdInput
                          {...restFormElements.chars}
                          onInputChanged={this.handleInputChanges}
                        />
                      </Col>
                    </Row>,
                    <Row key={10}>
                      <Col xs={24}>
                        <AntdInput
                          {...restFormElements.type}
                          onInputChanged={this.handleInputChanges}
                        />
                      </Col>
                    </Row>,
                    defaultElem,
                    <Row key={13} justify="center">
                      <Col>
                        <QueueAnim leaveReverse>
                          {showDefault
                            ? [
                                <Row key={14} justify="center">
                                  <Col>
                                    <Tag
                                      style={{ cursor: 'pointer' }}
                                      onClick={this.openOptionsModal}>
                                      <AiOutlinePlus /> Add Options
                                    </Tag>
                                  </Col>
                                </Row>,
                              ]
                            : null}
                        </QueueAnim>
                      </Col>
                    </Row>,
                    <Row className="py-3" justify="center" key={15}>
                      {formElements.options.map((elem, idx) => {
                        return (
                          <Col className="py-2" key={51 + idx}>
                            <Tag
                              key={idx}
                              style={{
                                fontSize: '0.8rem',
                                cursor: 'pointer',
                              }}
                              color={'blue'}
                              onClick={() => this.onOptionsTagClick(elem.uuid)}>
                              {elem.label}
                            </Tag>
                          </Col>
                        );
                      })}
                    </Row>,
                    <Row
                      className="py-3"
                      align="middle"
                      justify="center"
                      key={16}>
                      <Col>
                        <Button
                          block
                          danger
                          disabled={isDeleteDisabled}
                          type="primary"
                          htmlType="button"
                          onClick={this.onDelete}>
                          Delete
                        </Button>
                      </Col>
                      <Col className="pl-3">
                        <Button
                          block
                          type="primary"
                          htmlType="button"
                          onClick={this.onDoneForm}>
                          Done
                        </Button>
                      </Col>
                      <Col className="pl-3">
                        <Button
                          danger
                          block
                          type="primary"
                          htmlType="button"
                          onClick={this.onCloseForm}>
                          Close
                        </Button>
                      </Col>
                    </Row>,
                  ]
                : null}
            </QueueAnim>
          </Col>
        </Row>

        <Row justify="center">
          <Col sm={12}>
            <Button
              block
              type="primary"
              htmlType="submit"
              size="large"
              disabled={showForm}
              loading={loadingButton}>
              Submit
            </Button>
          </Col>
        </Row>

        {showOptionModal && (
          <Modal
            key={101}
            visible={showOptionModal}
            onCancel={this.closeOptionsModal}
            width={400}
            closable={false}
            footer={[
              <Fragment key={1}>
                <Row justify="end">
                  <Col className="px-2">
                    <Button
                      onClick={() => this.onOptionsTagDelete(uuidOptions)}
                      block
                      type="primary"
                      htmlType="button"
                      danger>
                      Delete
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      block
                      type="primary"
                      htmlType="button"
                      onClick={this.onDoneOptionsModal}>
                      Done
                    </Button>
                  </Col>
                  <Col className="px-2">
                    <Button
                      danger
                      block
                      type="primary"
                      htmlType="button"
                      onClick={this.closeOptionsModal}>
                      Close
                    </Button>
                  </Col>
                </Row>
              </Fragment>,
            ]}>
            <Fragment>
              <Row key={103}>
                <Col xs={24}>
                  <AntdInput
                    {...restOptions.label}
                    onInputChanged={this.handleInputChangesOptions}
                  />
                </Col>
              </Row>
              <Row key={104}>
                <Col xs={24}>
                  <AntdInput
                    {...restOptions.value}
                    onInputChanged={this.handleInputChangesOptions}
                  />
                </Col>
              </Row>
            </Fragment>
          </Modal>
        )}
      </Fragment>
    );
  }
}

export default AttributesTab;
