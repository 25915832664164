type RightsType = Readonly<{
  view: string;
  edit: string;
  create: string;
  remove: string;
}>;

export const locationRights: RightsType & {
  readonly addNote: string;
  readonly editLocationGroups: string;
  readonly setLocationId: string;
} = {
  view: `ViewLocations`,
  edit: `EditLocations`,
  create: `CreateLocations`,
  remove: `DeleteLocations`,
  addNote: `AddNotes`,
  editLocationGroups: `EditLocationGroups`,
  setLocationId: `SetLocationIDs`,
};
export const deviceRights: RightsType = {
  view: `ViewDevices`,
  edit: `EditDevices`,
  create: `CreateDevices`,
  remove: `DeleteDevices`,
};
export const userRights: RightsType & { editUserGroups: string } = {
  view: `ViewUsers`,
  edit: `EditUsers`,
  create: `CreateUsers`,
  remove: `DeleteUsers`,
  editUserGroups: `EditUserGroups`,
};
export const groupRights: RightsType = {
  view: `ViewGroups`,
  edit: `EditGroups`,
  create: `CreateGroups`,
  remove: `DeleteGroups`,
};
export const partnerRights: RightsType = {
  view: `ViewPartners`,
  edit: `EditPartners`,
  create: `CreatePartners`,
  remove: `DeletePartners`,
};
export const floorPlanRights: RightsType = {
  view: `ViewFloorplans`,
  edit: `EditFloorplans`,
  create: `CreateFloorplans`,
  remove: `DeleteFloorplans`,
};
export const hardwareRights: RightsType = {
  view: `ViewHardwares`,
  edit: `EditHardwares`,
  create: `CreateHardwares`,
  remove: `DeleteHardwares`,
};

export const quickProcessRights = {
  viewData: 'QuickProcesses',
  ursalink: 'QP_URSALINK',
};
