import { Fragment } from 'react';
import { Col, Row } from 'antd';
import { ImArrowLeft, ImArrowRight } from 'react-icons/im';

const SwapContent = ({
  deviceID,
  locationID,
  otherDevice,
  otherLocation,
}: {
  deviceID: string;
  locationID: string;
  otherDevice: string;
  otherLocation: string;
}) => {
  return (
    <Fragment>
      <Fragment>
        <Row justify="center" align="middle" className="h-100">
          <Col xs={24}>
            <Row justify="center">
              <Col
                xs={8}
                className="text-center"
                style={{
                  border: '1px solid #AEAEAE',
                  minHeight: '200px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                Location: {otherLocation}
              </Col>
              <Col xs={8} className="text-center">
                <Row className="h-100">
                  <Col xs={24} style={{ alignSelf: 'flex-start' }}>
                    <Row>
                      <Col xs={24}>Device: {deviceID}</Col>
                      <Col
                        xs={24}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <ImArrowRight size="2em" />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} style={{ alignSelf: 'flex-end' }}>
                    <Row>
                      <Col
                        xs={24}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <ImArrowLeft size="2em" />
                      </Col>
                      <Col xs={24}>Device: {otherDevice}</Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col
                xs={8}
                className="text-center"
                style={{
                  border: '1px solid #1890FF',
                  minHeight: '100px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                Location: {locationID}
              </Col>
            </Row>
          </Col>
        </Row>
      </Fragment>
    </Fragment>
  );
};

export default SwapContent;
