import { Button, Col, message, Row, Tag } from 'antd';
import { Component, Fragment, useCallback, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import QueueAnim from 'rc-queue-anim';
import update from 'immutability-helper';
import { Modal, Input } from 'antd';

import { SensorsTabElementsType, sensorsTabInputElements } from '../helpers';
import { checkValidation } from '../../../utils/validation';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { getSingleInputElement } from '../../../utils/get-input-element';
import { v4 } from 'uuid';
import { ChangeEvent } from 'react';
type PropsType = {
  handleSensorsTabElements: (data: SensorsTabElementsType[]) => void;
  sensorsTabElements: SensorsTabElementsType[];
  setCurrentTabIndex: (index: string) => void;
  allowEdit: boolean;
};
type StateType = {
  formElements: SensorsTabElementsType;
  showForm: boolean;
  isEditing: boolean;
  showMarkersModal: boolean;
  currentSelectedMarker: {
    label: string;
    colour: string;
    markerValue: string;
    text: string;
    uuid: string;
  };
};
class SensorsTab extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      formElements: { ...sensorsTabInputElements },
      showForm: false,
      isEditing: false,
      showMarkersModal: false,
      currentSelectedMarker: {
        label: '',
        colour: '',
        markerValue: '',
        text: '',
        uuid: '',
      },
    };
  }

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleState = (data: Partial<StateType>) => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          ...prevState,
          ...data,
        };
      });
  };

  handleInputChanges = (name: keyof SensorsTabElementsType, value: string) => {
    const { formElements } = this.state;

    let tempFormElements = { ...formElements };

    if (name) {
      if (
        value &&
        (name === 'maxScale' || name === 'minScale') &&
        !checkValidation(value, { isNegativeFloat: true })
      ) {
        message.warning('Enter valid input!');
        return;
      }

      if (name !== 'compass' && name !== 'uuid' && name !== 'markers') {
        tempFormElements = update(tempFormElements, {
          [name]: {
            touched: { $set: true },
            valid: {
              $set: checkValidation(value, tempFormElements[name].validation),
            },
            value: { $set: value },
          },
        });
      }

      if (!tempFormElements.uuid) {
        tempFormElements = update(tempFormElements, {
          uuid: { $set: v4() },
        });
      }

      this.setState({ formElements: tempFormElements });
    }
  };

  handleCheckbox = (event: CheckboxChangeEvent) => {
    const { formElements } = this.state;

    let tempFormElements = { ...formElements };

    tempFormElements = update(tempFormElements, {
      compass: { $set: event.target.checked },
    });

    this.setState({ formElements: tempFormElements });
  };

  onShowForm = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showForm: true,
      };
    });
  };

  onDoneForm = () => {
    const { handleSensorsTabElements, sensorsTabElements } = this.props;
    const { formElements, isEditing } = this.state;
    let tempFormElements = { ...formElements };
    let tempSensorsTabElements = [...sensorsTabElements];
    let key: keyof typeof tempFormElements;

    for (key in tempFormElements) {
      if (key !== 'compass' && key !== 'uuid' && key !== 'markers') {
        tempFormElements = update(tempFormElements, {
          [key]: {
            touched: { $set: true },
            valid: {
              $set: checkValidation(
                tempFormElements[key].value,
                tempFormElements[key].validation
              ),
            },
          },
        });
      }
    }

    this.handleState({ formElements: tempFormElements });

    let valid = true;
    for (key in tempFormElements) {
      if (
        key !== 'compass' &&
        key !== 'uuid' &&
        key !== 'markers' &&
        !tempFormElements[key].valid
      ) {
        valid = false;
      }
    }

    if (!valid) {
      message.error('Please fill all the fields');
      return;
    }

    const matchedShortNames = tempSensorsTabElements.find(
      (el) =>
        el.shortName.value.toLowerCase() ===
        tempFormElements.shortName.value.toLowerCase()
    );

    if (!isEditing) {
      if (matchedShortNames) {
        message.error(`Short Name with same text already exists!`);
        return;
      }

      tempSensorsTabElements = update(tempSensorsTabElements, {
        $push: [tempFormElements],
      });
      handleSensorsTabElements(tempSensorsTabElements);

      this.handleState({
        isEditing: false,
        showForm: false,
        formElements: { ...sensorsTabInputElements },
      });
    } else {
      const matched = tempSensorsTabElements.find(
        (el) => el.uuid === tempFormElements.uuid
      );

      if (matchedShortNames && matchedShortNames.uuid !== matched?.uuid) {
        message.error(`Short Name with same text already exists!`);
        return;
      }

      tempSensorsTabElements = tempSensorsTabElements.map((el) => {
        if (el.uuid === tempFormElements.uuid) {
          return update(el, { $merge: { ...tempFormElements } });
        }
        return el;
      });

      handleSensorsTabElements(tempSensorsTabElements);

      this.handleState({
        isEditing: false,
        showForm: false,
        formElements: { ...sensorsTabInputElements },
      });
    }
  };

  onCloseForm = () => {
    this.setState({
      formElements: { ...sensorsTabInputElements },
      showForm: false,
      isEditing: false,
    });
  };

  onTagClick = (uuid: string) => {
    const { formElements } = this.state;
    const { sensorsTabElements } = this.props;
    const matchedFormElements = sensorsTabElements.find(
      (el) => el.uuid === uuid
    );

    let tempFormElements = { ...formElements };

    if (matchedFormElements) {
      let key: keyof typeof tempFormElements;

      for (key in tempFormElements) {
        if (key !== 'compass' && key !== 'uuid' && key !== 'markers') {
          tempFormElements = update(tempFormElements, {
            [key]: {
              value: {
                $set: matchedFormElements[key].value,
              },
              disabled: { $set: matchedFormElements[key].disabled },
            },
          });
        }
      }

      tempFormElements = update(tempFormElements, {
        compass: { $set: matchedFormElements.compass },
      });
      tempFormElements = update(tempFormElements, {
        uuid: { $set: matchedFormElements.uuid },
      });
      tempFormElements = update(tempFormElements, {
        markers: { $set: matchedFormElements.markers },
      });
    }

    this.setState({
      formElements: tempFormElements,
      showForm: true,
      isEditing: true,
    });
  };

  onDelete = () => {
    const { formElements } = this.state;
    const { handleSensorsTabElements, sensorsTabElements } = this.props;

    let temp = [...sensorsTabElements];

    temp = temp.filter((el) => el.uuid !== formElements.uuid);

    handleSensorsTabElements(temp);

    this.handleState({
      isEditing: false,
      showForm: false,
      formElements: { ...sensorsTabInputElements },
    });
  };

  openMarkersModal = () => {
    this.setState({ showMarkersModal: true });
  };

  closeMarkersModal = () => {
    this.setState({
      showMarkersModal: false,
      currentSelectedMarker: {
        label: '',
        colour: '',
        markerValue: '',
        text: '',
        uuid: '',
      },
    });
  };

  onDoneMarkersModal = ({
    label,
    colour,
    markerValue,
    text,
    uuid,
  }: {
    label: string;
    colour: string;
    markerValue: number;
    text: string;
    uuid: string;
  }) => {
    const { formElements } = this.state;

    let tempFormElements = { ...formElements };
    let tempMarkers = [...tempFormElements.markers];

    const matchedLabel = tempMarkers.find((el) => el.label === label);

    if (!uuid) {
      if (matchedLabel) {
        message.error(`Label with same text already exists!!`);
        return;
      }

      tempMarkers = update(tempMarkers, {
        $push: [{ label, colour, value: markerValue, uuid: v4(), text }],
      });
    } else {
      if (matchedLabel && matchedLabel?.uuid !== uuid) {
        message.error(`Label with same text already exists!!`);
        return;
      }

      tempMarkers = tempMarkers.map((el) => {
        let tempObj = { ...el };
        if (tempObj.uuid === uuid) {
          tempObj = update(el, {
            label: { $set: label },
            value: { $set: markerValue },
            colour: { $set: colour },
            text: { $set: text },
            uuid: { $set: uuid },
          });
        }

        return tempObj;
      });
    }

    tempFormElements = update(tempFormElements, {
      markers: { $set: tempMarkers },
    });

    this.setState((prev) => ({
      ...prev,
      formElements: { ...tempFormElements },
      showMarkersModal: false,
      currentSelectedMarker: {
        label: '',
        colour: '',
        markerValue: '',
        text: '',
        uuid: '',
      },
    }));
  };

  onMarkersTagClick = (uuid?: string) => {
    const { formElements } = this.state;
    const matched = formElements.markers.find((el) => el.uuid === uuid);

    if (matched) {
      this.setState((prev) => ({
        ...prev,
        showMarkersModal: true,
        currentSelectedMarker: {
          label: matched.label,
          colour: matched.colour,
          markerValue: matched.value.toString(),
          text: matched.text,
          uuid: matched.uuid || '',
        },
      }));
    }
  };

  onMarkerDelete = (uuid: string) => {
    const { formElements } = this.state;
    let tempFormElements = { ...formElements };
    let tempMarkers = [...tempFormElements.markers];
    tempMarkers = tempMarkers.filter((el) => el.uuid !== uuid);

    tempFormElements = update(tempFormElements, {
      markers: { $set: tempMarkers },
    });

    this.setState({
      showMarkersModal: false,
      currentSelectedMarker: {
        label: '',
        colour: '',
        markerValue: '',
        text: '',
        uuid: '',
      },
      formElements: { ...tempFormElements },
    });
  };

  render() {
    const { sensorsTabElements, allowEdit } = this.props;
    const {
      formElements,
      showForm,
      isEditing,
      showMarkersModal,
      currentSelectedMarker,
    } = this.state;
    const { compass, uuid, markers, ...restFormElements } = formElements;
    const elements = getSingleInputElement({
      formElements: { ...restFormElements },
      inputChangedHandler: this.handleInputChanges,
      sliceValue: [0, 8],
    });

    let isDeleteDisabled = true;
    if (isEditing && allowEdit) {
      isDeleteDisabled = false;
    }

    let isSubmitDisabled = !allowEdit;
    if (allowEdit && showForm) {
      isSubmitDisabled = true;
    }

    return (
      <Fragment>
        {allowEdit && (
          <Row justify="center" className="py-3">
            <Col>
              <Tag
                style={{
                  cursor: !showForm ? 'pointer' : 'default',
                  backgroundColor: !showForm ? '#1890ff' : '#FAFAFA',
                  color: !showForm ? '#fff' : 'rgba(0, 0, 0, 0.85)',
                  borderColor: !showForm ? '#1890ff' : '#d9d9d9',
                }}
                onClick={!showForm ? this.onShowForm : () => {}}>
                <AiOutlinePlus /> Add Sensor
              </Tag>
            </Col>
          </Row>
        )}

        <Row justify="center" className="py-3">
          {sensorsTabElements.length > 0 &&
            sensorsTabElements.map((item) => {
              return (
                <Fragment key={item.uuid}>
                  <Col className="py-2">
                    <Tag
                      key={item.uuid}
                      style={{ fontSize: '0.8rem', cursor: 'pointer' }}
                      color="blue"
                      onClick={() => this.onTagClick(item.uuid)}>
                      {item.shortName.value}
                    </Tag>
                  </Col>
                </Fragment>
              );
            })}
        </Row>

        <Row justify="center" className="py-2">
          <Col>
            <QueueAnim leaveReverse>
              {showForm
                ? [
                    ...elements,
                    <Row
                      key={elements.length + 2}
                      className="pb-3"
                      align="middle">
                      <Col xs={6}>Compass</Col>
                      <Col xs={6}>
                        <Checkbox
                          disabled={!allowEdit}
                          checked={formElements.compass}
                          onChange={this.handleCheckbox}></Checkbox>
                      </Col>
                    </Row>,
                    <Row key={elements.length + 3} justify="center">
                      <Col>
                        <Tag
                          style={{ cursor: 'pointer' }}
                          onClick={this.openMarkersModal}>
                          <AiOutlinePlus /> Add Markers
                        </Tag>
                      </Col>
                    </Row>,
                    <Row
                      key={elements.length + 4}
                      className="py-3"
                      justify="center">
                      {markers &&
                        markers.length > 0 &&
                        markers.map((el, idx) => {
                          return (
                            <Fragment key={idx}>
                              <Col className="py-2" key={idx}>
                                <Tag
                                  key={idx}
                                  style={{
                                    fontSize: '0.8rem',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() =>
                                    this.onMarkersTagClick(el.uuid)
                                  }
                                  color={'blue'}>
                                  {el.label}
                                </Tag>
                              </Col>
                            </Fragment>
                          );
                        })}
                    </Row>,
                    <Row
                      justify="center"
                      className="py-3"
                      key={elements.length + 5}>
                      <Col>
                        <Button
                          block
                          danger
                          disabled={isDeleteDisabled}
                          type="primary"
                          htmlType="button"
                          onClick={this.onDelete}>
                          Delete
                        </Button>
                      </Col>
                      <Col className="pl-3">
                        <Button
                          block
                          disabled={!allowEdit}
                          type="primary"
                          htmlType="button"
                          onClick={this.onDoneForm}>
                          Done
                        </Button>
                      </Col>
                      <Col className="pl-3">
                        <Button
                          danger
                          block
                          type="primary"
                          htmlType="button"
                          onClick={this.onCloseForm}>
                          Close
                        </Button>
                      </Col>
                    </Row>,
                  ]
                : null}
            </QueueAnim>
          </Col>
        </Row>

        <Row justify="center">
          <Col sm={12} md={8}>
            <Button
              block
              type="primary"
              htmlType="submit"
              size="large"
              disabled={isSubmitDisabled}>
              Submit
            </Button>
          </Col>
        </Row>

        {showMarkersModal && (
          <MarkersModal
            showMarkersModal={showMarkersModal}
            onDoneMarkersModal={this.onDoneMarkersModal}
            closeMarkersModal={this.closeMarkersModal}
            currentSelectedMarker={currentSelectedMarker}
            onMarkerDelete={this.onMarkerDelete}
          />
        )}
      </Fragment>
    );
  }
}

export default SensorsTab;

const MarkersModal = ({
  showMarkersModal,
  closeMarkersModal,
  onDoneMarkersModal,
  currentSelectedMarker,
  onMarkerDelete,
}: {
  showMarkersModal: boolean;
  closeMarkersModal: () => void;
  onDoneMarkersModal: ({
    label,
    colour,
    markerValue,
    uuid,
  }: {
    label: string;
    colour: string;
    markerValue: number;
    text: string;
    uuid: string;
  }) => void;
  currentSelectedMarker: {
    label: string;
    colour: string;
    markerValue: string;
    text: string;
    uuid: string;
  };
  onMarkerDelete: (uuid: string) => void;
}) => {
  const [label, setLabel] = useState(currentSelectedMarker.label || '');
  const [colour, setColour] = useState(currentSelectedMarker.colour || '');
  const [textValue, setTextValue] = useState(currentSelectedMarker.text || '');
  const [markerValue, setMarkerValue] = useState(
    currentSelectedMarker.markerValue || ''
  );

  const onDone = useCallback(() => {
    if (label && colour && checkValidation(markerValue, { isNumeric: true })) {
      onDoneMarkersModal({
        label,
        colour,
        markerValue: Number(markerValue),
        text: textValue,
        uuid: currentSelectedMarker.uuid,
      });
    } else {
      message.error('Fill up the fields properly');
      return;
    }
  }, [
    colour,
    currentSelectedMarker.uuid,
    label,
    markerValue,
    onDoneMarkersModal,
    textValue,
  ]);

  const onMarkerValueChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const val = e?.currentTarget?.value;
      if (val && !checkValidation(val, { isNumeric: true })) {
        message.error('Fill up the fields properly');
        return;
      }
      setMarkerValue(val);
    },
    []
  );

  return (
    <Fragment>
      <Modal
        key={1}
        visible={showMarkersModal}
        onCancel={closeMarkersModal}
        width={400}
        closable={false}
        footer={[
          <Fragment key={1}>
            <Row justify="end">
              <Col className="pr-2">
                <Button
                  block
                  danger
                  type="primary"
                  htmlType="button"
                  onClick={() => onMarkerDelete(currentSelectedMarker.uuid)}>
                  Delete
                </Button>
              </Col>
              <Col>
                <Button block type="primary" htmlType="button" onClick={onDone}>
                  Done
                </Button>
              </Col>
              <Col className="px-2">
                <Button
                  danger
                  block
                  type="primary"
                  htmlType="button"
                  onClick={closeMarkersModal}>
                  Close
                </Button>
              </Col>
            </Row>
          </Fragment>,
        ]}>
        <label htmlFor="label">Label</label>
        <Row className="pb-2">
          <Col xs={24}>
            <Input
              id="label"
              value={label}
              onChange={(e) => setLabel(e.currentTarget.value)}
            />
          </Col>
        </Row>

        <label htmlFor="Colour">Colour</label>
        <Row className="pb-2">
          <Col xs={24}>
            <Input
              id="Colour"
              value={colour}
              onChange={(e) => setColour(e.currentTarget.value)}
            />
          </Col>
        </Row>

        <label htmlFor="markerValue">Marker Value</label>
        <Row className="pb-2">
          <Col xs={24}>
            <Input
              id="markerValue"
              value={markerValue}
              onChange={onMarkerValueChange}
            />
          </Col>
        </Row>

        <label htmlFor="textValue">Text</label>
        <Row className="pb-2">
          <Col xs={24}>
            <Input
              id="textValue"
              value={textValue}
              onChange={(e) => setTextValue(e.currentTarget.value)}
            />
          </Col>
        </Row>
      </Modal>
    </Fragment>
  );
};
