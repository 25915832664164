import {
  Dispatch,
  Fragment,
  useReducer,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Modal, Row, Col, Checkbox, message, Button } from 'antd';
import update from 'immutability-helper';
import moment from 'moment';

import useHttp from '../../../../hooks/use-http-allSettled';
import { getUserData } from '../../../../redux/selectors';
import {
  BooleanObjectType,
  ReducerHookActionType,
  SelectOptionsType,
} from '../../../../type-definitions';
import {
  CreateRoleModalStateType,
  createRoleModalReducer,
  createRoleModalState,
  CreateRoleFormElementsType,
  createRoleInitFormElements,
  actionTypes,
} from '../../helpers';
import AntdInput from '../../../AntdInput';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { checkValidation } from '../../../../utils/validation';
import { handleNotification } from '../../../../utils/notification-handler';
import {
  groupApi,
  HttpMethodContentType,
  partnerApi,
} from '../../../../api-services/api-list';
import {
  ApiResponseConfigDataType,
  ApiResponseDataType,
} from '../../../../type-definitions/api-types';
import AntdCoverSpinner from '../../../AntdCoverSpinner';
import { getCurrentUTCDate, getUtcTime } from '../../../../utils';

interface PropsType {
  showModal: boolean;
  partnerID: string;
  onCloseModal: () => void;
  onSuccess: () => void;
  rolesList: string[];
}

const CreateRoleModal = ({
  showModal,
  partnerID,
  onCloseModal,
  onSuccess,
  rolesList,
}: PropsType) => {
  const handlePhenomModal = useCallback(() => {
    dispatchToState({
      type: actionTypes.setModalVisibility,
      payload: { name: 'showPhenomModal' },
    });
  }, []);

  const handleExtendedPermissionModal = useCallback(() => {
    dispatchToState({
      type: actionTypes.setModalVisibility,
      payload: { name: 'showExtendedPermissionModal' },
    });
  }, []);

  const handleDeviceManagementPermissionModal = useCallback(() => {
    dispatchToState({
      type: actionTypes.setModalVisibility,
      payload: { name: 'showDeviceManagementPermissionModal' },
    });
  }, []);

  const [state, dispatchToState]: [
    state: CreateRoleModalStateType,
    dispatchToState: Dispatch<ReducerHookActionType>
  ] = useReducer(createRoleModalReducer, {
    ...createRoleModalState,
    formElements: {
      ...createRoleInitFormElements,
      partnerID: { ...createRoleInitFormElements.partnerID, value: partnerID },
      minDate: {
        ...createRoleInitFormElements.minDate,
        value: getCurrentUTCDate(),
      },
      maxDate: {
        ...createRoleInitFormElements.maxDate,
        value: getCurrentUTCDate(),
      },
      phenoms: {
        ...createRoleInitFormElements.phenoms,
        onInputClick: handlePhenomModal,
      },
      extendedPermission: {
        ...createRoleInitFormElements.extendedPermission,
        onInputClick: handleExtendedPermissionModal,
      },
      deviceManagementPermission: {
        ...createRoleInitFormElements.deviceManagementPermission,
        onInputClick: handleDeviceManagementPermissionModal,
      },
    },
  });
  const { isLoading, sendRequest } = useHttp();
  const userData = useSelector(getUserData);

  const {
    formElements,
    showPhenomModal,
    showExtendedPermissionModal,
    showDeviceManagementPermissionModal,
    phenomList,
    extendedList,
    deviceMgtList,
    chooseRoleType,
  } = state;

  useEffect(() => {
    if (
      showModal &&
      userData.token &&
      formElements.roleTemplate.optionValues &&
      formElements.roleTemplate.optionValues.length === 0 &&
      Object.keys(phenomList).length === 0
    ) {
      try {
        const permissionExt = partnerApi.getPartnerPermissions(
          { type: 'extended' },
          { partnerID }
        );
        const phenoms = partnerApi.getPartnerPhenoms(undefined, { partnerID });
        const permissionMgt = partnerApi.getPartnerPermissions(
          { type: 'management' },
          { partnerID }
        );
        const templateRoles = partnerApi.getPartnerTemplateRoles();

        const handleResponses = (
          responses: PromiseSettledResult<
            ApiResponseConfigDataType<ApiResponseDataType>
          >[]
        ) => {
          if (responses.length > 0) {
            let tempPhenomsObj = {};
            let tempExtendedObj = {};
            let tempDeviceMngObj = {};
            let tempFormElements = { ...formElements };

            if (responses[0].status === 'fulfilled') {
              if (responses[0].value) {
                const tempPhenoms: any[] = responses[0].value.data?.data;
                tempPhenoms.forEach((el) => {
                  tempPhenomsObj = update(tempPhenomsObj, {
                    [el.id]: { $set: false },
                  });
                });
                tempPhenomsObj = update(tempPhenomsObj, {
                  all: { $set: false },
                });
              }
            } else {
              handleNotification('error', {
                message: responses[0].reason?.response?.data?.message,
              });
            }

            if (responses[1].status === 'fulfilled') {
              const tempExtended: string[] = responses[1].value.data?.data;
              tempExtended.forEach((el) => {
                tempExtendedObj = update(tempExtendedObj, {
                  [el]: { $set: false },
                });
              });
              tempExtendedObj = update(tempExtendedObj, {
                all: { $set: false },
              });
            } else {
              handleNotification('error', {
                message: responses[1].reason?.response?.data?.message,
              });
            }

            if (responses[2].status === 'fulfilled') {
              const tempDeviceMng: string[] = responses[2].value?.data?.data;
              tempDeviceMng.forEach((el) => {
                tempDeviceMngObj = update(tempDeviceMngObj, {
                  [el]: { $set: false },
                });
              });
              tempDeviceMngObj = update(tempDeviceMngObj, {
                all: { $set: false },
              });
            } else {
              handleNotification('error', {
                message: responses[2].reason?.response?.data?.message,
              });
            }

            if (responses[3].status === 'fulfilled') {
              let tempPartnerRoles: string[] = responses[3].value?.data?.data;

              if (tempPartnerRoles && tempPartnerRoles.length > 0) {
                tempPartnerRoles = tempPartnerRoles.filter(
                  (el) => !rolesList.find((el2) => el === el2)
                );

                if (tempPartnerRoles.length > 0) {
                  const roleTemplates: SelectOptionsType[] =
                    tempPartnerRoles.map((el) => {
                      return { text: el, value: el };
                    });

                  tempFormElements = update(tempFormElements, {
                    roleTemplate: {
                      optionValues: { $set: roleTemplates },
                      value: { $set: roleTemplates[0].value },
                    },
                  });
                }
              }
            } else {
              handleNotification('error', {
                message: responses[3].reason?.response?.data?.message,
              });
            }

            dispatchToState({
              type: actionTypes.setState,
              payload: {
                phenomList: tempPhenomsObj,
                extendedList: tempExtendedObj,
                deviceMgtList: tempDeviceMngObj,
                formElements: tempFormElements,
              },
            });
          }
        };

        sendRequest({
          requestConfig: [
            { ...phenoms },
            { ...permissionExt },
            { ...permissionMgt },
            { ...templateRoles },
          ],
          headersConfig: { storeToken: userData.token },
          applyData: handleResponses,
        });
      } catch (error: any) {
        handleNotification('error', error?.data);
      }
    }
  }, [
    formElements,
    partnerID,
    phenomList,
    rolesList,
    sendRequest,
    showModal,
    userData.token,
  ]);

  const onInputChanged = useCallback(
    (name: keyof CreateRoleFormElementsType, value: string) => {
      let tempFormElements = { ...formElements };

      if (name) {
        if (
          value &&
          name === 'maxDaysFromToday' &&
          !checkValidation(value, { isNumeric: true })
        ) {
          message.error('Please enter valid input');
          return;
        }

        if (name !== 'allPhenoms') {
          tempFormElements = update(tempFormElements, {
            [name]: {
              value: { $set: value },
              touched: { $set: true },
              valid: {
                $set: checkValidation(value, tempFormElements[name].validation),
              },
            },
          });
        }

        if (name === 'minDate' || name === 'maxDate') {
          if (
            moment(tempFormElements.minDate.value).unix() >
            moment(tempFormElements.maxDate.value).unix()
          ) {
            tempFormElements = update(tempFormElements, {
              maxDate: {
                touched: { $set: true },
                valid: { $set: false },
                errorMessage: {
                  $set: `Please select a 'To' date that is a after 'From' date`,
                },
              },
            });
          } else {
            tempFormElements = update(tempFormElements, {
              maxDate: {
                valid: { $set: true },
              },
            });
          }
        }

        dispatchToState({
          type: actionTypes.setState,
          payload: { formElements: tempFormElements },
        });
      }
    },
    [formElements]
  );

  const onCheckboxChange = useCallback(
    (event: CheckboxChangeEvent) => {
      const name = event?.target?.name;
      const checked = event?.target?.checked;

      if (name) {
        let tempFormElements = update(formElements, {
          [name]: { $set: checked },
        });

        if (name === 'allPhenoms') {
          tempFormElements = update(tempFormElements, {
            phenoms: { disabled: { $set: checked } },
          });
        }

        dispatchToState({
          type: actionTypes.setState,
          payload: { formElements: tempFormElements },
        });
      }
    },
    [formElements]
  );

  const onModalOkay = useCallback(
    (data: BooleanObjectType) => {
      if (showPhenomModal) {
        let tempFormElements = update(formElements, {
          phenoms: {
            value: {
              $set: Object.keys(data)
                .filter((el) => {
                  if (el !== 'all' && data[el]) {
                    return el;
                  }
                  return null;
                })
                .join(', '),
            },
          },
        });
        dispatchToState({
          type: actionTypes.setState,
          payload: {
            phenomList: data,
            showPhenomModal: false,
            formElements: tempFormElements,
          },
        });
      } else if (showExtendedPermissionModal) {
        let tempFormElements = update(formElements, {
          extendedPermission: {
            value: {
              $set: Object.keys(data)
                .filter((el) => {
                  if (el !== 'all' && data[el]) {
                    return el;
                  }
                  return null;
                })
                .join(', '),
            },
          },
        });
        dispatchToState({
          type: actionTypes.setState,
          payload: {
            extendedList: data,
            showExtendedPermissionModal: false,
            formElements: tempFormElements,
          },
        });
      } else if (showDeviceManagementPermissionModal) {
        let tempFormElements = update(formElements, {
          deviceManagementPermission: {
            value: {
              $set: Object.keys(data)
                .filter((el) => {
                  if (el !== 'all' && data[el]) {
                    return el;
                  }
                  return null;
                })
                .join(', '),
            },
          },
        });
        dispatchToState({
          type: actionTypes.setState,
          payload: {
            deviceMgtList: data,
            showDeviceManagementPermissionModal: false,
            formElements: tempFormElements,
          },
        });
      }
    },
    [
      formElements,
      showDeviceManagementPermissionModal,
      showExtendedPermissionModal,
      showPhenomModal,
    ]
  );

  const onSubmit = useCallback(() => {
    let tempFormElements = { ...formElements };
    let key: keyof typeof tempFormElements;

    const submitCall = async (data: any, ctype: HttpMethodContentType) => {
      const { url, method, contentType } = groupApi.postGroup(
        undefined,
        undefined,
        ctype
      );
      try {
        if (userData.token) {
          const handleResponses = (
            responses: PromiseSettledResult<
              ApiResponseConfigDataType<ApiResponseDataType>
            >[]
          ) => {
            if (responses.length > 0) {
              if (responses[0].status === 'fulfilled') {
                const data = responses[0].value;
                handleNotification('success', { message: data.data?.message });
                onSuccess();
              } else {
                handleNotification('error', {
                  message: responses[0].reason?.response?.data?.message,
                });
              }
            }
          };

          sendRequest({
            requestConfig: [{ url, method, contentType, data }],
            headersConfig: { storeToken: userData.token },
            applyData: handleResponses,
          });
        }
      } catch (error: any) {
        handleNotification('error', error?.data);
      }
    };

    if (chooseRoleType.value === 'customNewRole') {
      for (key in tempFormElements) {
        if (key !== 'allPhenoms') {
          tempFormElements = update(tempFormElements, {
            [key]: {
              touched: { $set: true },
              valid: {
                $set: checkValidation(
                  tempFormElements[key].value,
                  tempFormElements[key].validation
                ),
              },
            },
          });
        }
      }

      if (
        moment(tempFormElements.minDate.value).unix() >
        moment(tempFormElements.maxDate.value).unix()
      ) {
        tempFormElements = update(tempFormElements, {
          maxDate: {
            touched: { $set: true },
            valid: { $set: false },
            errorMessage: {
              $set: `Please select a 'To' date that is a after 'From' date`,
            },
          },
        });

        handleNotification('error', {
          message: `'To' date should be greater than 'From' date`,
        });
      } else {
        tempFormElements = update(tempFormElements, {
          maxDate: {
            valid: { $set: true },
          },
        });
      }

      dispatchToState({
        type: actionTypes.setState,
        payload: {
          formElements: tempFormElements,
        },
      });

      for (key in tempFormElements) {
        if (key !== 'allPhenoms' && !tempFormElements[key].valid) {
          message.error('Please fill all the fields');
          return;
        }
      }

      const tempData = {
        maxdaysfromtoday: '',
        mindate: '',
        maxdate: '',
      };
      if (tempFormElements.dateTimeRadio.value === 'dateAccess') {
        const maxDateVal = getUtcTime({
          date: `${tempFormElements.maxDate.value} ${
            tempFormElements.maxTime.value || '00:00:00'
          }`,
        });
        const minDateVal = getUtcTime({
          date: `${tempFormElements.minDate.value} ${
            tempFormElements.minTime.value || '00:00:00'
          }`,
        });

        tempData.mindate = minDateVal;
        tempData.maxdate = maxDateVal;
      } else if (tempFormElements.dateTimeRadio.value === 'rollingAccess') {
        tempData.maxdaysfromtoday = tempFormElements.maxDaysFromToday.value;
      } else {
        tempData.maxdaysfromtoday = '-1';
      }

      const data = {
        group_id: tempFormElements.groupID.value,
        group_name: tempFormElements.groupName.value,
        grouptype: 'ROLE',
        partner_id: tempFormElements.partnerID.value,
        allPhenoms: tempFormElements.allPhenoms,
        ...tempData,
      };

      const tempFormData = [];
      const phenomlist: string[] = [];
      const extendedpermissions: string[] = [];
      const devicemanagementpermissions: string[] = [];
      let key2: keyof typeof data;
      for (key2 in data) {
        let encodedValue: string | null = encodeURIComponent(data[key2]);
        if (
          (key2 === 'maxdate' ||
            key2 === 'mindate' ||
            key2 === 'maxdaysfromtoday') &&
          !data[key2]
        ) {
          encodedValue = null;
        }
        tempFormData.push(key2 + '=' + encodedValue);
      }
      for (const key3 in phenomList) {
        if (key3 !== 'all' && phenomList[key3] === true) {
          phenomlist.push(key3);
        }
      }
      for (const key3 in extendedList) {
        if (key3 !== 'all' && extendedList[key3] === true) {
          extendedpermissions.push(key3);
        }
      }
      for (const key3 in deviceMgtList) {
        if (key3 !== 'all' && deviceMgtList[key3] === true) {
          devicemanagementpermissions.push(key3);
        }
      }

      tempFormData.push('phenomlist=' + JSON.stringify(phenomlist));
      tempFormData.push(
        'extendedpermissions=' + JSON.stringify(extendedpermissions)
      );
      tempFormData.push(
        'devicemanagementpermissions=' +
          JSON.stringify(devicemanagementpermissions)
      );
      const formData: string = tempFormData.join('&');

      submitCall(formData, 'formUrlEncoded');
    } else {
      try {
        submitCall(
          {
            partner_id: tempFormElements.partnerID.value,
            group_name: tempFormElements.roleTemplate.value,
            fromTemplate: true,
          },
          'json'
        );
      } catch (error: any) {
        handleNotification('error', error?.data);
      }
    }
  }, [
    chooseRoleType.value,
    deviceMgtList,
    extendedList,
    formElements,
    onSuccess,
    phenomList,
    sendRequest,
    userData.token,
  ]);

  return (
    <Fragment>
      <Modal
        closable={false}
        footer={null}
        visible={showModal}
        onCancel={onCloseModal}
        width="70vw">
        <AntdCoverSpinner active={isLoading}>
          <Row>
            <Col xs={24}>
              <Row justify="center">
                <Col xs={24} md={12} className="px-md-2 pb-2">
                  <AntdInput
                    {...chooseRoleType}
                    onInputChanged={(_, value) =>
                      dispatchToState({
                        type: actionTypes.setRoleType,
                        payload: { roleType: value },
                      })
                    }
                  />
                </Col>
              </Row>
              {chooseRoleType.value === 'customNewRole' ? (
                <CustomNewRole
                  formElements={formElements}
                  onCheckboxChange={onCheckboxChange}
                  onInputChanged={onInputChanged}
                />
              ) : (
                <RoleFromTemplate
                  formElements={formElements}
                  onInputChanged={onInputChanged}
                />
              )}

              <Row justify="center" className="pt-3">
                <Col xs={24} sm={6} className="px-2">
                  <Button block htmlType="button" onClick={onCloseModal}>
                    Cancel
                  </Button>
                </Col>
                <Col xs={24} sm={6} className="px-2 pt-2 pt-sm-0">
                  <Button
                    block
                    disabled={
                      chooseRoleType.value !== 'customNewRole' &&
                      formElements.roleTemplate.optionValues &&
                      formElements.roleTemplate.optionValues.length === 0
                    }
                    type="primary"
                    htmlType="button"
                    onClick={onSubmit}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </AntdCoverSpinner>
      </Modal>

      {showPhenomModal && (
        <PermissionModal
          showModal={showPhenomModal}
          handleModal={handlePhenomModal}
          onModalOkay={onModalOkay}
          checkboxData={phenomList}
        />
      )}

      {showExtendedPermissionModal && (
        <PermissionModal
          showModal={showExtendedPermissionModal}
          handleModal={handleExtendedPermissionModal}
          onModalOkay={onModalOkay}
          checkboxData={extendedList}
        />
      )}

      {showDeviceManagementPermissionModal && (
        <PermissionModal
          showModal={showDeviceManagementPermissionModal}
          handleModal={handleDeviceManagementPermissionModal}
          onModalOkay={onModalOkay}
          checkboxData={deviceMgtList}
        />
      )}
    </Fragment>
  );
};

export default CreateRoleModal;

const CustomNewRole = ({
  formElements,
  onInputChanged,
  onCheckboxChange,
}: {
  formElements: CreateRoleFormElementsType;
  onInputChanged: (name: any, value: any) => void;
  onCheckboxChange: (event: CheckboxChangeEvent) => void;
}) => {
  return (
    <Fragment>
      <Row>
        <Col xs={24} md={12} className="px-md-2">
          <AntdInput
            {...formElements.partnerID}
            onInputChanged={onInputChanged}
          />
        </Col>
        <Col xs={24} md={12} className="px-md-2">
          <AntdInput
            {...formElements.groupName}
            onInputChanged={onInputChanged}
          />
        </Col>
      </Row>

      {/* <Row>
  <Col xs={24} md={12} className="px-md-2">
    <AntdInput
      {...formElements.groupType}
      onInputChanged={onInputChanged}
    />
  </Col>
</Row> */}

      <Row>
        <Col sm={24} md={12} className="px-md-2">
          <AntdInput
            {...formElements.dateTimeRadio}
            onInputChanged={onInputChanged}
          />
        </Col>
      </Row>

      {formElements.dateTimeRadio.value === 'rollingAccess' && (
        <Row>
          <Col xs={24} md={12} className="px-md-2">
            <AntdInput
              {...formElements.maxDaysFromToday}
              onInputChanged={onInputChanged}
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col xs={24} md={12} className="px-md-2">
          <AntdInput
            {...formElements.minDate}
            onInputChanged={onInputChanged}
          />
        </Col>
        <Col xs={24} md={12} className="px-md-2">
          <AntdInput
            {...formElements.minTime}
            onInputChanged={onInputChanged}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={24} md={12} className="px-md-2">
          <AntdInput
            {...formElements.maxDate}
            onInputChanged={onInputChanged}
          />
        </Col>
        <Col xs={24} md={12} className="px-md-2">
          <AntdInput
            {...formElements.maxTime}
            onInputChanged={onInputChanged}
          />
        </Col>
      </Row>

      <Row className="px-md-2 pb-3 pt-1">
        <Col>
          <Checkbox
            name="allPhenoms"
            checked={formElements.allPhenoms}
            onChange={onCheckboxChange}>
            All Phenoms
          </Checkbox>
        </Col>
      </Row>

      <Row>
        <Col xs={24} md={12} className="px-md-2">
          <AntdInput
            {...formElements.phenoms}
            onInputChanged={onInputChanged}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={24} md={12} className="px-md-2">
          <AntdInput
            {...formElements.extendedPermission}
            onInputChanged={onInputChanged}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={24} md={12} className="px-md-2">
          <AntdInput
            {...formElements.deviceManagementPermission}
            onInputChanged={onInputChanged}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

const RoleFromTemplate = ({
  formElements,
  onInputChanged,
}: {
  formElements: CreateRoleFormElementsType;
  onInputChanged: (name: any, value: any) => void;
}) => {
  return (
    <Fragment>
      <Row justify="center">
        {formElements.roleTemplate.optionValues &&
        formElements.roleTemplate.optionValues.length > 0 ? (
          <Fragment>
            <Col sm={24} md={12} className="px-md-2">
              <AntdInput
                {...formElements.roleTemplate}
                onInputChanged={onInputChanged}
              />
            </Col>
          </Fragment>
        ) : (
          <Fragment>
            <Col sm={24} md={12} className="text-center">
              Currently there is no template available!
            </Col>
          </Fragment>
        )}
      </Row>
    </Fragment>
  );
};

interface PermissionModalPropsType {
  showModal: boolean;
  handleModal: () => void;
  onModalOkay: (checkboxData: BooleanObjectType) => void;
  checkboxData: BooleanObjectType;
}

const PermissionModal = ({
  showModal,
  handleModal,
  onModalOkay,
  checkboxData,
}: PermissionModalPropsType) => {
  const [data, setData] = useState<BooleanObjectType>(checkboxData);

  const onChange = useCallback(
    (event: CheckboxChangeEvent) => {
      const name = event?.target?.name;
      const checked = event?.target?.checked;
      let fields: BooleanObjectType = { ...data };

      if (name) {
        if (name === 'all') {
          for (const key in fields) {
            fields = update(fields, { [key]: { $set: checked } });
          }
        } else {
          fields = update(fields, {
            [name]: { $set: checked },
          });

          const isAllChecked = Object.keys(fields)
            .filter((el) => el !== 'all')
            .every((item) => fields[item]);

          fields = update(fields, {
            all: { $set: isAllChecked },
          });
        }

        setData(fields);
      }
    },
    [data]
  );

  return (
    <Fragment>
      <Modal
        visible={showModal}
        width={500}
        closable={false}
        footer={[
          <Button onClick={handleModal} htmlType="button" key={1}>
            Cancel
          </Button>,
          <Button
            type="primary"
            onClick={() => onModalOkay(data)}
            htmlType="button"
            key={2}>
            OK
          </Button>,
        ]}>
        <Row justify="center">
          <Col className="pb-3">
            <Checkbox name={`all`} onChange={onChange} checked={data.all}>
              {!data.all ? `Select All` : `Deselect All`}
            </Checkbox>
          </Col>
        </Row>
        <Row>
          {data &&
            Object.keys(data).map((item, index) => {
              if (item !== 'all') {
                return (
                  <Col xs={12} sm={8} className="py-2" key={index}>
                    <Checkbox
                      name={item}
                      onChange={onChange}
                      checked={data[item]}>
                      {item}
                    </Checkbox>
                  </Col>
                );
              }
              return null;
            })}
        </Row>
      </Modal>
    </Fragment>
  );
};
