import { Component, FormEvent, Fragment } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import cloneDeep from 'lodash/cloneDeep';
import axios from 'axios';
import { Col, message, Row, Tabs } from 'antd';
import update from 'immutability-helper';

import {
  StateType,
  FormElementsType,
  detailsTabElements,
  locationTabElements,
  calibrationTabSingleElements,
  CalibrationTabSingleElementsType,
  journalTabElements,
} from '../components/DeviceDetails/helpers';
import {
  DefaultObjectType,
  FormInputType,
  SelectOptionsType,
} from '../type-definitions';

import {
  ApiErrorType,
  ApiSuccessType,
  CalibrationType,
  DeviceDetailsType,
  PartnerListType,
  UserDataType,
} from '../type-definitions/api-types';
import { generatePassword, getUtcTime, getZuluFormatUTC } from '../utils';
import { deviceApi, partnerApi } from '../api-services/api-list';
import { apiCall } from '../api-services/api';
import { checkValidation } from '../utils/validation';
import AntdInput from '../components/AntdInput';
import FormWrapper from '../components/FormWrapper';
import AllocateToStock from '../components/DeviceDetails/AllocateToStock';
import { deviceRoutes, locationRoutes } from '../Routes/routes-list';
import AssignToLocation from '../components/DeviceDetails/AssignToLocation';
import SwapDeviceModal from '../components/DeviceDetails/SwapDeviceModal';
import MoveDeviceModal from '../components/DeviceDetails/MoveDeviceModal';
import DetailsTab from '../components/DeviceDetails/DetailsTab';
import LocationsTab from '../components/DeviceDetails/LocationsTab';
import CalibrationTab from '../components/DeviceDetails/CalibrationTab';
import { v4 } from 'uuid';
import PromptPopup from '../components/PromptPopup';
import { handleNotification } from '../utils/notification-handler';
import JournalTab from '../components/DeviceDetails/JournalTab';
import { DeviceAssociatedNodeButton } from './DeviceAdd';

interface PropsType
  extends RouteComponentProps<
    {},
    any,
    {
      deviceID?: string;
      allowEdit?: boolean;
      locationID?: string;
    }
  > {
  userData: Partial<UserDataType>;
  userPermissionList: string[];
}

const currentDate = getUtcTime({ date: new Date(), format: 'date' });

const initialElements = {
  ...detailsTabElements,
  ...locationTabElements,
};

class DeviceDetails extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    const { location } = props;
    this.state = {
      // formElements: {
      //   ...cloneDeep(detailsTabElements),
      //   ...cloneDeep(locationTabElements),
      //   ...cloneDeep(notesTabElements),
      // },
      formElements: {
        ...detailsTabElements,
        ...locationTabElements,
        ...journalTabElements,
      },
      formElementsList: {},
      showStockModal: false,
      showLocationModal: false,
      loading: true,
      showMoveDeviceModal: false,
      previousDetails: {},
      showSwapDeviceModal: false,
      currentTabIndex: '1',
      calibrationValues: [],
      calibrationTabFormElements: { ...calibrationTabSingleElements },
      hasInputChanged: false,

      allowEdit: location?.state?.allowEdit ?? false,
      deviceID: location?.state?.deviceID ?? '',
    };
  }

  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    this._isMounted = true;
    const { deviceID } = this.state;

    if (deviceID) {
      this.handleFetchedData({ deviceID });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  handleState = (data: Partial<StateType>) => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          ...prevState,
          ...data,
        };
      });
  };

  fetchData = async ({ deviceID }: { deviceID: string }) => {
    const { userData } = this.props;

    try {
      const deviceDetailApi = deviceApi.getDeviceDetails(null, {
        deviceID,
      });
      const partnerListApi = partnerApi.getPartners();
      const response = await apiCall({
        storeToken: userData?.token,
        url: deviceDetailApi.url,
        method: deviceDetailApi.method,
        contentType: deviceDetailApi.contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      const response2 = await apiCall({
        storeToken: userData?.token,
        url: partnerListApi.url,
        method: partnerListApi.method,
        contentType: partnerListApi.contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result2 = response2?.data;
      if (result.status === 'ok') {
        return {
          deviceDetails: result.data,
          devicePartners: result2.data || [],
        };
      } else {
        return { error: result };
      }
    } catch (error) {
      return { error };
    }
  };

  onAssociatedNodeClick = (name: string) => {
    this.inputChangedHandler2(
      name,
      generatePassword(),
      true,
      <DeviceAssociatedNodeButton
        onAssociatedNodeClick={() => this.onAssociatedNodeClick(name)}
        disabled={true}
      />
    );
  };

  handleFetchedData = async ({ deviceID }: { deviceID: string }) => {
    const {
      error,
      deviceDetails,
      devicePartners,
    }: {
      error?: ApiErrorType;
      deviceDetails?: Partial<DeviceDetailsType>;
      devicePartners?: PartnerListType[];
    } = await this.fetchData({
      deviceID,
    });
    if (error) {
      this._isMounted && handleNotification('error', error);
      this._isMounted && this.setState({ loading: false });
    } else {
      const {
        formElementsList,
        formElements,
        calibrationTabFormElements,
        allowEdit,
      } = this.state;
      let formElementsValues = cloneDeep(formElements);
      let formElementsListValues = cloneDeep(formElementsList);

      let stateData: Partial<StateType> = {};
      let tempCalibrationFormElements = { ...calibrationTabFormElements };

      if (deviceDetails && devicePartners && devicePartners.length > 0) {
        if (
          deviceDetails?.template &&
          deviceDetails.template.length > 0 &&
          deviceDetails?.attributes &&
          Object.keys(deviceDetails.attributes).length > 0
        ) {
          deviceDetails.template.forEach((item) => {
            let tempElement: FormInputType = {
              elementType: 'input',
              elementConfig: {
                placeholder: item.helptext,
                name: item.name,
              },
              optionValues: [{ value: '', text: '' }],
              value: deviceDetails?.attributes?.[item.name] || '',
              validation: {
                required: item.required || false,
              },
              valid: false,
              touched: false,
              errorMessage: `${item.label} is required`,
              label: `${item.label} ${item.required ? '*' : ''}`,
              disabled: true,
            };

            if (allowEdit) {
              tempElement.disabled = false;
            }

            if (item.type === 'string') {
              tempElement.elementType = 'input';
              tempElement.elementConfig.type = 'text';
            } else if (item.type === 'date') {
              tempElement.elementType = 'date';
              tempElement.value =
                tempElement.value !== ''
                  ? getUtcTime({ date: tempElement.value, format: 'date' })
                  : currentDate;
            } else if (item.type === 'option') {
              if (item?.options && item.options.length === 1) {
                tempElement.elementType = 'input';
                tempElement.disabled = true;
                // tempElement.value = item.options[0].value;
              } else if (item?.options && item.options.length > 1) {
                tempElement.elementType = 'select';
                item.options.forEach((el) => {
                  tempElement = update(tempElement, {
                    optionValues: {
                      $push: [
                        {
                          value: el.value,
                          text: el.label,
                        },
                      ],
                    },
                  });
                });
                // tempElement.value = item.options[0].value;
              }
            }

            if (item.type === 'string' && item.allowGeneration === true) {
              // const tempCheckboxElem: FormInputType = {
              //   elementType: 'checkbox',
              //   elementConfig: {
              //     name: 'allowGeneration',
              //   },
              //   checkboxText: 'Allow Auto Generation',
              //   value: '',
              //   validation: {},
              //   valid: false,
              //   touched: false,
              //   errorMessage: '',
              //   hasParentWrapper: false,
              // };

              tempElement.hasAssociatedNode = (
                <DeviceAssociatedNodeButton
                  onAssociatedNodeClick={() =>
                    this.onAssociatedNodeClick(item.name)
                  }
                />
              );
            }

            formElementsListValues = update(formElementsListValues, {
              [item.name]: { $set: { ...tempElement } },
            });
          });
        }

        let sensorData = '';
        if (
          deviceDetails?.sensorSpecs &&
          deviceDetails.sensorSpecs.length > 0
        ) {
          deviceDetails.sensorSpecs.forEach((item, index) => {
            sensorData = sensorData + item.shortName + '(' + item.units + ')';
            if (deviceDetails.sensorSpecs) {
              if (index < deviceDetails.sensorSpecs.length - 1) {
                sensorData = sensorData + ', ';
              }
            }
          });
        }

        const tempFormElements: any = { ...formElementsValues };
        if (allowEdit) {
          for (const key in tempFormElements) {
            if (
              key === 'deviceStatus' ||
              key === 'partnerID' ||
              key === 'notes' ||
              key === 'journalNote'
            ) {
              tempFormElements[key].disabled = false;
            } else {
              tempFormElements[key].disabled = true;
            }
          }
        } else {
          for (const key in tempFormElements) {
            tempFormElements[key].disabled = true;
          }
        }

        formElementsValues = { ...tempFormElements };

        formElementsValues.deviceMac.value = deviceDetails.mac || '';
        formElementsValues.serialNumber.value = deviceDetails.serial || '';
        formElementsValues.locationID.value = deviceDetails.locID || '';
        formElementsValues.manufacturer.value = deviceDetails.make || '';
        formElementsValues.model.value = deviceDetails.model || '';
        formElementsValues.locationStatus.value =
          deviceDetails.LocationStatus || '';
        formElementsValues.sensors.value = sensorData;
        formElementsValues.locationName.value =
          deviceDetails.locationName || '';
        if (devicePartners && devicePartners.length > 1) {
          const tempPartnerOptions: SelectOptionsType[] = [];
          devicePartners.forEach((item) => {
            if (item?.partnerID && item?.partnerName) {
              const data = { value: item.partnerID, text: item.partnerName };
              tempPartnerOptions.push(data);
            }
          });
          if (tempPartnerOptions.length > 0) {
            formElementsValues.partnerName.optionValues = [
              ...tempPartnerOptions,
            ];
            if (deviceDetails.partnerID) {
              formElementsValues.partnerName.value = deviceDetails.partnerID;
            } else {
              const selected = devicePartners.find(
                (item) => item?.partnerID === 'AIRSENSA'
              );
              if (selected) {
                formElementsValues.partnerName.value = selected.partnerID;
              } else {
                formElementsValues.partnerName.value =
                  tempPartnerOptions[0].value;
              }
            }
          }
        } else if (devicePartners && devicePartners.length === 1) {
          formElementsValues.partnerName.elementType = 'input';
          formElementsValues.partnerName.elementConfig.type = 'text';
          formElementsValues.partnerName.elementConfig.placeholder =
            'Partner Name';
          formElementsValues.partnerName.value =
            devicePartners[0].partnerID || '';
          formElementsValues.partnerName.disabled = true;
        }

        stateData = update(stateData, {
          formElements: { $set: formElementsValues },
          formElementsList: { $set: formElementsListValues },
          loading: { $set: false },
          previousDetails: { $set: deviceDetails || {} },
        });

        if (deviceDetails.calibrationDate) {
          const calibrationDateTime = getUtcTime({
            date: deviceDetails.calibrationDate,
            format: 'dateTime',
          });

          tempCalibrationFormElements = update(tempCalibrationFormElements, {
            calibrationDate: {
              value: { $set: calibrationDateTime.split(' ')[0] },
            },
            calibrationTime: {
              value: {
                $set: calibrationDateTime.split(' ')[1],
              },
            },
          });

          stateData = update(stateData, {
            calibrationTabFormElements: { $set: tempCalibrationFormElements },
          });
        }

        if (
          deviceDetails.calibrationvalues &&
          deviceDetails.calibrationvalues.length > 0
        ) {
          stateData = update(stateData, {
            calibrationValues: {
              $set: deviceDetails.calibrationvalues.map((el) => ({
                ...el,
                uuid: v4(),
              })),
            },
          });
        }
      } else {
        stateData = update(stateData, { loading: { $set: false } });
      }

      this.handleState(stateData);
    }
  };

  calibrationTabInputChangedHandler = (
    name: keyof CalibrationTabSingleElementsType,
    value: string
  ) => {
    const { calibrationTabFormElements } = this.state;
    let tempInputElements = { ...calibrationTabFormElements };
    if (name) {
      tempInputElements = update(tempInputElements, {
        [name]: {
          value: { $set: value },
          touched: { $set: true },
          valid: {
            $set: checkValidation(value, tempInputElements[name].validation),
          },
        },
      });

      this.handleState({ calibrationTabFormElements: tempInputElements });
    }
  };

  inputChangedHandler = (name: keyof FormElementsType, value: any) => {
    const { formElements } = this.state;

    let tempFormElements = { ...formElements };
    let hasInputChanged = false;

    if (name) {
      tempFormElements = update(tempFormElements, {
        [name]: {
          value: { $set: value },
          touched: { $set: true },
          valid: {
            $set: checkValidation(value, tempFormElements[name].validation),
          },
        },
      });

      if (name !== 'journalNote') {
        hasInputChanged = true;
      }

      this._isMounted &&
        this.setState({
          formElements: tempFormElements,
          hasInputChanged,
        });
    }
  };

  inputChangedHandler2 = (
    name: string,
    value: any,
    isDisabled?: boolean,
    hasAssociatedNode?: any
  ) => {
    const { formElementsList } = this.state;

    let tempFormElementsList = { ...formElementsList };

    if (name) {
      tempFormElementsList = update(tempFormElementsList, {
        [name]: {
          value: { $set: value },
          touched: { $set: true },
          valid: {
            $set: checkValidation(value, tempFormElementsList[name].validation),
          },
          disabled: {
            $set: isDisabled ?? tempFormElementsList[name].disabled,
          },
          hasAssociatedNode: {
            $set:
              hasAssociatedNode ?? tempFormElementsList[name].hasAssociatedNode,
          },
        },
      });
    }

    this._isMounted &&
      this.setState({
        formElementsList: tempFormElementsList,
        hasInputChanged: true,
      });
  };

  onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    this.handleSubmit();
  };

  handleSubmit = async (onConfirm?: () => void) => {
    const {
      formElementsList,
      formElements,
      calibrationValues,
      calibrationTabFormElements,
    } = this.state;
    const { userData } = this.props;
    const tempFormElementsList = cloneDeep(formElementsList);

    let key2: keyof typeof tempFormElementsList;

    for (key2 in tempFormElementsList) {
      tempFormElementsList[key2].touched = true;
      tempFormElementsList[key2].valid = checkValidation(
        tempFormElementsList[key2].value,
        tempFormElementsList[key2].validation
      );
    }

    this._isMounted &&
      this.setState({ formElementsList: tempFormElementsList });

    for (key2 in tempFormElementsList) {
      if (tempFormElementsList[key2].valid === false) {
        message.error('Please fill all the fields');
        return;
      }
    }

    const data: {
      locID: string;
      partnerID: string;
      attributes?: DefaultObjectType;
      calibrationvalues: CalibrationType[];
      calibrationDate: string;
    } = {
      locID: formElements.locationID.value,
      partnerID: formElements.partnerName.value,
      calibrationvalues: calibrationValues.map((el) => {
        const { uuid, ...rest } = el;
        return rest;
      }),
      calibrationDate: getZuluFormatUTC(
        calibrationTabFormElements.calibrationDate.value,
        calibrationTabFormElements.calibrationTime.value
      ),
    };
    const data2: any = {};
    for (const key in tempFormElementsList) {
      data2[key] = tempFormElementsList[key].value;
    }
    data.attributes = { ...data2 };
    this._isMounted && this.setState({ loading: true });
    try {
      const { url, method, contentType } = deviceApi.putDevice(null, {
        deviceID: formElements.deviceMac.value,
      });
      const response = await apiCall({
        storeToken: userData.token,
        data,
        url,
        method,
        contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      if (result.status === 'ok') {
        this._isMounted && handleNotification('success', result);
        this._isMounted &&
          this.setState(
            {
              loading: false,
              hasInputChanged: false,
            },
            () => {
              onConfirm?.();
            }
          );
      } else {
        this._isMounted && handleNotification('error', result);
        this._isMounted && this.setState({ loading: false });
      }
    } catch (error: any) {
      this._isMounted && handleNotification('error', error?.data);
      this._isMounted && this.setState({ loading: false });
    }
  };

  handlePopupConfirm = (onConfirm: () => void) => {
    this.handleSubmit(onConfirm);
  };

  getInputElementsList = () => {
    const { formElementsList } = this.state;
    const formElementsArray = [];

    for (const key in formElementsList) {
      formElementsArray.push({
        id: key,
        config: formElementsList[key],
      });
    }

    const inputElement = formElementsArray.map((item) => {
      return (
        <Row gutter={[{ md: 12 }, 0]} key={item.id} align="middle">
          <Col xs={24} md={12}>
            <AntdInput
              {...item.config}
              onInputChanged={this.inputChangedHandler2}
            />
          </Col>
          <Col xs={24} md={12} className="pt-3">
            {item.config.hasAssociatedNode}
          </Col>
        </Row>
      );
    });

    return inputElement;
  };

  handleCancelRedirect = () => {
    const { history } = this.props;
    history.push({
      pathname: deviceRoutes.list,
    });
  };

  handleLocationIDValue = (locationID: string) => {
    const { formElements } = this.state;
    const tempFormElements = cloneDeep(formElements);
    tempFormElements.locationID.value = locationID;

    this._isMounted &&
      this.setState((prevState) => {
        return {
          formElements: tempFormElements,
          showStockModal: !prevState.showStockModal,
        };
      });
  };

  handleStockModal = () => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          showStockModal: !prevState.showStockModal,
        };
      });
  };

  handleLocationModal = () => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          showLocationModal: !prevState.showLocationModal,
        };
      });
  };

  onLocationSelectFromLocationModal = (locationID: string) => {
    const { formElements } = this.state;
    const tempFormElements = cloneDeep(formElements);
    tempFormElements.locationID.value = locationID;
    this._isMounted &&
      this.setState((prevState) => {
        return {
          showLocationModal: !prevState.showLocationModal,
          formElements: tempFormElements,
        };
      });
  };

  handleRefresh = () => {
    const { location } = this.props;
    const deviceID = location.state.deviceID;
    this.handleFetchedData({ deviceID: deviceID || '' });
  };

  handleMoveDeviceModal = () => {
    this._isMounted &&
      this.setState((prevState) => {
        return { showMoveDeviceModal: !prevState.showMoveDeviceModal };
      });
  };

  onCloseMoveDeviceModal = ({
    successChild,
    refreshForm,
  }: {
    successChild: ApiSuccessType;
    refreshForm: boolean;
  }) => {
    const stateData: any = {
      showMoveDeviceModal: false,
    };
    if (refreshForm) {
      stateData.formElementsList = {};
      stateData.formElements = cloneDeep(initialElements);
      stateData.loading = true;
      this._isMounted && handleNotification('success', successChild);
    }
    this._isMounted &&
      this.setState({ ...stateData }, () => {
        if (refreshForm) {
          this.handleRefresh();
        }
      });
  };

  handleSwapDeviceModal = () => {
    this._isMounted &&
      this.setState((prevState) => {
        return { showSwapDeviceModal: !prevState.showSwapDeviceModal };
      });
  };

  onCloseSwapDeviceModal = ({
    successChild,
    refreshForm,
  }: {
    successChild: ApiSuccessType;
    refreshForm: boolean;
  }) => {
    const stateData: any = {
      showSwapDeviceModal: false,
    };
    if (refreshForm) {
      stateData.formElementsList = {};
      stateData.formElements = cloneDeep(initialElements);
      stateData.loading = true;
      this._isMounted && handleNotification('success', successChild);
    }
    this._isMounted &&
      this.setState({ ...stateData }, () => {
        if (refreshForm) {
          this.handleRefresh();
        }
      });
  };

  setCurrentTabIndex = (index: string) => {
    this._isMounted && this.setState({ currentTabIndex: index });
  };

  handleRedirectToLocation = () => {
    const { history } = this.props;
    const { formElements, deviceID } = this.state;

    history.push({
      pathname: locationRoutes.details(),
      state: { locationID: formElements.locationID.value, deviceID },
    });
  };

  handleCalibrationTabElements = (data: CalibrationType[]) => {
    this.handleState({ calibrationValues: data });
  };

  render() {
    const dynamicInputElements = this.getInputElementsList();
    const { userPermissionList, userData } = this.props;

    const {
      showStockModal,
      showLocationModal,
      formElements,
      loading,
      showMoveDeviceModal,
      showSwapDeviceModal,
      currentTabIndex,
      calibrationValues,
      calibrationTabFormElements,
      hasInputChanged,
      allowEdit,
      deviceID,
    } = this.state;

    if (!deviceID) {
      return <Redirect to={deviceRoutes.list} />;
    }

    if (userPermissionList.length === 0) {
      return <Redirect to="/" />;
    }

    // let isEditAllowed = false;
    // if (
    //   userPermissionList.length > 0 &&
    //   userPermissionList.includes(deviceRights.edit) &&
    //   allowEdit
    // ) {
    //   isEditAllowed = true;
    // }

    return (
      <Fragment>
        <PromptPopup
          when={hasInputChanged}
          handleConfirm={this.handlePopupConfirm}
        />
        <FormWrapper
          title={allowEdit ? 'Device Edit' : 'Device View'}
          loading={loading}>
          <form onSubmit={this.onFormSubmit} noValidate>
            <Tabs
              activeKey={currentTabIndex}
              onTabClick={this.setCurrentTabIndex}>
              <Tabs.TabPane tab="Details" key="1">
                <DetailsTab
                  formElements={formElements}
                  inputChangedHandler={this.inputChangedHandler}
                  dynamicInputElements={dynamicInputElements}
                  handleCancelRedirect={this.handleCancelRedirect}
                  isEditAllowed={allowEdit}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Location" key="2">
                <LocationsTab
                  formElements={formElements}
                  inputChangedHandler={this.inputChangedHandler}
                  handleMoveDeviceModal={this.handleMoveDeviceModal}
                  handleSwapDeviceModal={this.handleSwapDeviceModal}
                  handleRedirectToLocation={this.handleRedirectToLocation}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Calibration" key="3">
                <CalibrationTab
                  calibrationValues={calibrationValues}
                  handleCalibrationTabElements={
                    this.handleCalibrationTabElements
                  }
                  allowEdit={allowEdit}
                  calibrationTabFormElements={calibrationTabFormElements}
                  calibrationTabInputChangedHandler={
                    this.calibrationTabInputChangedHandler
                  }
                  handleCancelRedirect={this.handleCancelRedirect}
                  isEditAllowed={allowEdit}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Journal" key="4">
                <JournalTab
                  deviceID={deviceID}
                  journalNote={formElements.journalNote}
                  inputChangedHandler={this.inputChangedHandler}
                />
              </Tabs.TabPane>
            </Tabs>
          </form>
        </FormWrapper>

        {showStockModal && (
          <AllocateToStock
            showModal={showStockModal}
            handleModal={this.handleStockModal}
            userData={userData}
            handleLocationIDValue={this.handleLocationIDValue}
          />
        )}
        {showLocationModal && (
          <AssignToLocation
            showModal={showLocationModal}
            handleModal={this.handleLocationModal}
            userData={userData}
            deviceID={formElements.deviceMac.value}
            onLocationSelectFromLocationModal={
              this.onLocationSelectFromLocationModal
            }
          />
        )}
        {showMoveDeviceModal && (
          <MoveDeviceModal
            showModal={showMoveDeviceModal}
            onCloseModal={this.onCloseMoveDeviceModal}
            handleModal={this.handleMoveDeviceModal}
            userData={userData}
            locationMac={formElements.deviceMac.value}
          />
        )}
        {showSwapDeviceModal && (
          <SwapDeviceModal
            showModal={showSwapDeviceModal}
            handleModal={this.handleSwapDeviceModal}
            userData={userData}
            locationMac={formElements.deviceMac.value}
            onCloseModal={this.onCloseSwapDeviceModal}
          />
        )}
      </Fragment>
    );
  }
}

export default DeviceDetails;
