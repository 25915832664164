import { FormEvent, Fragment, PureComponent } from 'react';
import { Modal, Table, Card, Button } from 'antd';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import axios from 'axios';
import { Row, Col } from 'antd';
import update from 'immutability-helper';

// import AntdModal from '../../';
import { apiCall } from '../../../api-services/api';
import { deviceApi } from '../../../api-services/api-list';
import {
  UserDataType,
  ApiErrorType,
  StockLocationsType,
} from '../../../type-definitions/api-types';
import { AllocateToStockStateType } from '../helpers/index';
// import NotificationHandler from '../../NotificationHandler';
import NotificationHandler from '../../NotificationHandler';
import { handleSorting, handleTableSearch } from '../../../utils';
import FilterInput from '../../FilterInput';
import { cloneDeep } from '../../../utils/lodash-libs';
import { AntdTableColumnsType } from '../../../type-definitions';

type PropsType = {
  userData: Partial<UserDataType>;
  handleLocationIDValue: (id: string) => void;
  showModal: boolean;
  handleModal: () => void;
};

class AllocateToStock extends PureComponent<
  Readonly<PropsType>,
  Readonly<AllocateToStockStateType>
> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      dataList: [],
      perPage: 10,
      showModalConfirm: false,
      loading: true,
      success: {},
      error: {},
      searchValue: '',
      filterByPartnerObj: {},
    };
  }

  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();
  initDataList: StockLocationsType[] = [];

  componentDidMount() {
    this._isMounted = true;
    this.handleFetchedData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  fetchData = async () => {
    const { userData } = this.props;

    try {
      const { url, method, contentType } = deviceApi.getStockLocations();
      const response = await apiCall({
        storeToken: userData.token,
        url,
        method,
        contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      if (result?.status === 'ok') {
        return { stockLocationList: result.data || [] };
      } else {
        return { error: result, stockLocationList: [] };
      }
    } catch (error: any) {
      return { error };
    }
  };

  handleFetchedData = async () => {
    const {
      error,
      stockLocationList,
    }: {
      error?: ApiErrorType;
      stockLocationList?: Array<StockLocationsType>;
    } = await this.fetchData();
    if (error) {
      this._isMounted && this.setState({ error, loading: false });
    } else {
      if (stockLocationList && stockLocationList.length > 0) {
        const temp: StockLocationsType[] = [];
        stockLocationList.forEach((item) => {
          if (item?.rights?.includes('AllocateToStock')) {
            temp.push(item);
          }
        });

        this.initDataList = update(this.initDataList, { $set: temp });

        let tempPartnerObj: { [k: string]: boolean } = {};
        if (temp) {
          temp.forEach((el) => {
            if (el.partnerID) {
              tempPartnerObj = update(tempPartnerObj, {
                [el.partnerID]: { $set: true },
              });
            }
          });
        }

        this._isMounted &&
          this.setState({
            dataList: temp,
            loading: false,
            filterByPartnerObj: tempPartnerObj,
          });
      } else {
        this._isMounted && this.setState({ loading: false });
      }
    }
  };

  showConfirm = (record: StockLocationsType) => {
    const { handleLocationIDValue } = this.props;
    Modal.confirm({
      title:
        'This stock location is at or over capacity, are you sure you want to continue?',
      icon: <AiOutlineExclamationCircle />,
      // content: 'Some descriptions',
      onOk() {
        handleLocationIDValue(record.locationID);
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };

  handleSelect = (record: StockLocationsType) => {
    const { handleLocationIDValue } = this.props;
    if (record.capacity !== -1 && record.stockLevel >= record.capacity) {
      this.showConfirm(record);
    } else {
      handleLocationIDValue(record.locationID);
    }
  };

  handleSearchOtherGroups = (e: FormEvent<HTMLInputElement>) => {
    const value = e?.currentTarget?.value;

    let tempData = cloneDeep(this.initDataList);
    if (value) {
      const columns = getColumns(this.handleSelect).map((el) => el.key);

      tempData = handleTableSearch({
        data: tempData,
        columnList: columns,
        searchData: value,
      });
    }

    this._isMounted &&
      this.setState((prev) => ({
        ...prev,
        dataList: tempData,
        searchValue: value,
      }));
  };

  handleCheckboxFilter = (checkBoxData: { [k: string]: boolean }) => {
    const tempData = cloneDeep(this.initDataList);
    const filteredData = tempData?.filter((el) => {
      if (el.partnerID && checkBoxData[el.partnerID]) {
        return el;
      }
      return null;
    });

    this._isMounted &&
      this.setState((prev) => ({
        ...prev,
        dataList: filteredData,
        filterByPartnerObj: checkBoxData,
      }));
  };

  render() {
    const { showModal, handleModal } = this.props;
    const {
      dataList,
      loading,
      success,
      error,
      filterByPartnerObj,
      searchValue,
    } = this.state;

    const columns = getColumns(this.handleSelect);

    return (
      <Fragment>
        <NotificationHandler success={success} error={error} obj={this} />

        <Modal
          visible={showModal}
          closable={false}
          width={720}
          onCancel={handleModal}
          footer={null}>
          <Card bordered={false} loading={loading}>
            <Row className="py-3" justify="end">
              <Col md={12}>
                <FilterInput
                  handleSearchFilter={this.handleSearchOtherGroups}
                  loading={loading}
                  searchValue={searchValue}
                  listData={filterByPartnerObj}
                  handleCheckboxFilter={this.handleCheckboxFilter}
                  dropdownPlacement="topLeft"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Table
                  className="table-responsive"
                  rowKey={(record) => record['locationID']}
                  bordered
                  columns={columns}
                  dataSource={dataList}
                  pagination={{ pageSize: 5 }}
                />
              </Col>
            </Row>
          </Card>
        </Modal>
      </Fragment>
    );
  }
}

function getColumns(
  handleSelect: (record: StockLocationsType) => void
): AntdTableColumnsType<StockLocationsType>[] {
  return [
    {
      title: 'Location ID',
      key: 'locationID',
      dataIndex: 'locationID',
      sorter: (a: StockLocationsType, b: StockLocationsType) =>
        handleSorting(a.locationID, b.locationID),
    },
    {
      title: 'Location Name',
      key: 'locationName',
      dataIndex: 'locationName',
      sorter: (a: StockLocationsType, b: StockLocationsType) =>
        handleSorting(a.locationName, b.locationName),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Stock Level',
      key: 'stockLevel',
      dataIndex: 'stockLevel',
    },
    {
      title: 'Capacity',
      key: 'capacity',
      // dataIndex: 'capacity'
      render: (text: string, record: StockLocationsType) => {
        let capacity: any = record.capacity;
        if (record.capacity === -1) {
          capacity = 'Unlimited';
        }
        return <span>{capacity}</span>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, record: StockLocationsType) => (
        <Fragment>
          <Button type="primary" onClick={(event) => handleSelect(record)}>
            Select
          </Button>
        </Fragment>
      ),
    },
  ];
}

export default AllocateToStock;
