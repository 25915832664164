import { Fragment } from 'react';
import { Col, Row, Typography } from 'antd';

const NoteContent = ({ text }: { text: string }) => {
  return (
    <Fragment>
      <Row justify="center" align="middle" className="h-100">
        <Col>
          <Typography.Text keyboard strong style={{ fontSize: '1.5rem' }}>
            {text}
          </Typography.Text>
        </Col>
      </Row>
    </Fragment>
  );
};

export default NoteContent;
