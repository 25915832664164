import { ReduxActionType } from '../../type-definitions';
import { LOGOUT } from '../actionTypes/auth.types';
import { FILTER_SAVED } from '../actionTypes/user.types';

const initialState = {
  searchInput: '',
  filterByPartnerObj: {},
  searchApiKeys: '',
};

function reducer(state = initialState, action: ReduxActionType) {
  switch (action.type) {
    case FILTER_SAVED:
      const stateData: { [k: string]: any } = { ...action.payload };

      return {
        ...state,
        ...stateData,
      };

    case LOGOUT:
      return {
        ...state,
        searchInput: '',
        filterByPartnerObj: {},
        searchApiKeys: '',
      };

    default:
      return state;
  }
}

export default reducer;
