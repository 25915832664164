import update from 'immutability-helper';

import {
  FormInputType,
  ReducerHookActionType,
  SelectOptionsType,
} from '../../../type-definitions';
import { ApiResponseDataType } from '../../../type-definitions/api-types';
import { reducerHookActions } from '../../../utils/constants';

export const initState: StateType = {
  selectedLabels: [],
  hasInputChanged: false,
  labelsCollection: [],
  successData: {},
  formElements: {
    title: {
      elementType: 'input',
      elementConfig: {
        name: 'title',
        type: 'text',
        placeholder: 'Title',
      },
      value: '',
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
      errorMessage: 'Title is required',
      label: 'Title *',
    },
    body: {
      elementType: 'textarea',
      elementConfig: {
        name: 'body',
        type: 'text',
        placeholder: 'Body',
        rows: 6,
      },
      value: '',
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
      errorMessage: 'Body is required',
      label: 'Body *',
    },
  },
};

export interface FormElementsType {
  title: FormInputType;
  body: FormInputType;
}

export interface StateType {
  selectedLabels: string[];
  hasInputChanged: boolean;
  labelsCollection: SelectOptionsType[];
  successData: ApiResponseDataType;
  formElements: FormElementsType;
}

export const actionTypes = {
  ...reducerHookActions,
  tagClick: 'tagClick',
};

export const reducer = (state: StateType, action: ReducerHookActionType) => {
  switch (action.type) {
    case actionTypes.setState: {
      const newState = update(state, {
        $merge: { ...action.payload },
      });
      return { ...newState };
    }

    case actionTypes.tagClick: {
      const labelVal = action.payload?.labelVal;
      let newState = { ...state };
      let tempLabels = [...state.selectedLabels];
      if (labelVal) {
        const matched = tempLabels.find((el) => el === labelVal);
        if (matched) {
          tempLabels = tempLabels.filter((el) => el !== labelVal);
          newState = update(state, {
            selectedLabels: { $set: tempLabels },
          });
        } else {
          newState = update(state, {
            selectedLabels: { $push: [labelVal] },
          });
        }
      }

      return { ...newState };
    }

    default:
      return { ...state };
  }
};
