import { ReduxHardwareStateType } from '../../type-definitions';
import { STORE_DATA } from '../actionTypes/hardware.types';

export function storeHardwareData(data: Partial<ReduxHardwareStateType>) {
  return {
    type: STORE_DATA,
    payload: {
      ...data,
    },
  };
}
