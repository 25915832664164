import { useState, useEffect, Fragment, FC, memo, FormEvent } from 'react';
import { Input, Menu, Dropdown, Checkbox } from 'antd';
import { FaSearch, FaFilter } from 'react-icons/fa';

import styles from './filterInput.module.scss';
import { cloneDeep } from 'lodash';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

type PropsType = {
  handleSearchFilter: (event: FormEvent<HTMLInputElement>) => void;
  handleCheckboxFilter: (data: { [k: string]: boolean }) => void;
  loading: boolean;
  searchValue: string;
  listData: { [k: string]: boolean };
  overlayClassNames?: string;
  dropdownPlacement?:
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight';
};

const FilterInput: FC<PropsType> = ({
  handleSearchFilter,
  loading,
  handleCheckboxFilter,
  searchValue,
  listData,
  overlayClassNames,
  dropdownPlacement,
}) => {
  const [allCheck, setAllCheck] = useState(false);
  let sorted = listData && Object.entries(listData).sort();
  const entries = new Map(sorted);
  listData = Object.fromEntries(entries);

  useEffect(() => {
    let isAllChecked = false;
    if (listData) {
      isAllChecked = Object.keys(listData).every((item) => listData[item]);
      setAllCheck(isAllChecked);
    }
  }, [listData]);

  function handleCheckbox(event: CheckboxChangeEvent) {
    const name = event.target.name;
    const checked = event.target.checked;
    const temp = cloneDeep(listData);
    if (name) {
      if (name === 'ALL') {
        for (const key in temp) {
          temp[key] = checked;
        }
        setAllCheck(checked);
      } else {
        temp[name] = checked;
      }
      handleCheckboxFilter(temp);
    }
  }

  return (
    <Fragment>
      <Input
        value={searchValue}
        disabled={loading}
        onChange={handleSearchFilter}
        size="large"
        placeholder="Search within results"
        prefix={<FaSearch style={{ marginRight: '0.3rem' }} />}
        suffix={
          <Dropdown
            overlayClassName={overlayClassNames}
            overlay={
              <DropdownMenu
                listData={listData}
                handleCheckbox={handleCheckbox}
                checkBoxAll={allCheck}
              />
            }
            trigger={['click']}
            placement={dropdownPlacement || 'bottomCenter'}>
            <FaFilter
              onClick={(e) => e.preventDefault()}
              style={{
                cursor: 'pointer',
              }}
              className={`${!allCheck ? styles.iconColor : ''}`}
            />
          </Dropdown>
        }
      />
    </Fragment>
  );
};

export default memo(FilterInput);

function DropdownMenu({
  listData,
  handleCheckbox,
  checkBoxAll,
}: {
  listData: { [k: string]: boolean };
  handleCheckbox: (e: CheckboxChangeEvent) => void;
  checkBoxAll: boolean;
}) {
  return (
    <Fragment>
      <Menu className={`${styles.menuComponent}`}>
        <Menu.Item key={'ALL'}>
          <Checkbox checked={checkBoxAll} name="ALL" onChange={handleCheckbox}>
            {!checkBoxAll ? `Select All` : `Deselect All`}
          </Checkbox>
        </Menu.Item>
        {listData &&
          Object.keys(listData).map((item, idx) => {
            return (
              <Menu.Item key={idx}>
                <Checkbox
                  key={idx}
                  name={item}
                  onChange={handleCheckbox}
                  checked={listData[item]}>
                  {item}
                </Checkbox>
              </Menu.Item>
            );
          })}
      </Menu>
    </Fragment>
  );
}
