import axios from 'axios';
import { Fragment, Component } from 'react';
import update from 'immutability-helper';

import { deviceApi } from '../../../../api-services/api-list';
import {
  DeviceListType,
  FloorPlanLocationListType,
  AssignableDeviceListType,
} from '../../../../type-definitions/api-types';
import { handleNotification } from '../../../../utils/notification-handler';
import { webViewApiCall } from '../../../services/api-call';
import { v4 } from 'uuid';
import { Button, Card, Col, Row } from 'antd';
import AntdTable from '../../../../components/AntdTable';
import { AntdTableColumnsType } from '../../../../type-definitions';

import cssStyles from '../styles/webViewFloorPlanEngineer.module.scss';

type PropsType = {
  selectedFloorPlan: Partial<FloorPlanLocationListType>;
  token: string;
  onSwapModalClose: () => void;
};

type StateType = {
  loading: boolean;
  loadingTable: boolean;
  deviceList: DeviceDataType[];
};

type DeviceDataType = {
  deviceID: string;
  locationID: string;
  uuid: string;
};

class SwapModal extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      loading: true,
      loadingTable: false,
      deviceList: [],
    };
  }

  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();
  initDataList: DeviceDataType[] = [];

  componentDidMount() {
    this._isMounted = true;

    this.handleFetchedData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  handleState = (data: Partial<StateType>) => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          ...prevState,
          ...data,
        };
      });
  };

  handleFetchedData = async () => {
    const { selectedFloorPlan, token } = this.props;

    let stateData = { ...this.state };

    if (selectedFloorPlan?.deviceID && token && selectedFloorPlan.locationID) {
      if (
        selectedFloorPlan.deviceID === 'UNALLOCATED' ||
        selectedFloorPlan.status === 'STOCK'
      ) {
        try {
          const { url, method, contentType, params } =
            deviceApi.putAssignableDevices({
              locationID: selectedFloorPlan.locationID,
            });
          const response = await webViewApiCall({
            token,
            url,
            method,
            params,
            contentType,
            cancelToken: this.axiosCancelSource.token,
          });
          const result = response?.data;

          if (result?.status === 'ok') {
            if (result.data && result.data.length > 0) {
              const temp: DeviceDataType[] = result.data.map(
                (el: AssignableDeviceListType) => {
                  return { deviceID: el.mac, locationID: el.locID, uuid: v4() };
                }
              );

              this.initDataList = [...temp];

              stateData = update(stateData, { deviceList: { $set: temp } });
            }
          } else {
            this._isMounted && handleNotification('error', result);
          }
        } catch (error: any) {
          this._isMounted && handleNotification('error', error.data);
        }
      } else {
        try {
          const { url, method, contentType, params } =
            deviceApi.putSubstituteDevices({
              deviceID: selectedFloorPlan.deviceID,
            });
          const response = await webViewApiCall({
            token,
            url,
            method,
            params,
            contentType,
            cancelToken: this.axiosCancelSource.token,
          });
          const result = response?.data;

          if (result?.status === 'ok') {
            if (result.data && result.data.length > 0) {
              const temp: DeviceDataType[] = result.data.map(
                (el: DeviceListType) => {
                  return { deviceID: el.mac, locationID: el.locID, uuid: v4() };
                }
              );

              this.initDataList = [...temp];

              stateData = update(stateData, { deviceList: { $set: temp } });
            }
          } else {
            this._isMounted && handleNotification('error', result);
          }
        } catch (error: any) {
          this._isMounted && handleNotification('error', error.data);
        }
      }
    }

    stateData = update(stateData, { loading: { $set: false } });

    this.handleState({ ...stateData });
  };

  onSelect = async (record: DeviceDataType) => {
    const { selectedFloorPlan, token, onSwapModalClose } = this.props;

    this.handleState({ loading: true });

    if (selectedFloorPlan?.deviceID && record && selectedFloorPlan.locationID) {
      if (
        selectedFloorPlan.deviceID === 'UNALLOCATED' ||
        selectedFloorPlan.status === 'STOCK'
      ) {
        try {
          const { url, method, contentType } = deviceApi.putAssignToLocation(
            undefined,
            {
              deviceID: record.deviceID,
              locationID: selectedFloorPlan.locationID,
            }
          );

          const response = await webViewApiCall({
            token,
            url,
            method,
            contentType,
            cancelToken: this.axiosCancelSource.token,
          });
          const result = response?.data;

          if (result?.status === 'ok') {
            this._isMounted && handleNotification('success', result);
            onSwapModalClose();
          } else {
            this._isMounted && handleNotification('error', result);
          }
        } catch (error: any) {
          this._isMounted && handleNotification('error', error.data);
        }
      } else {
        try {
          const { url, method, contentType } = deviceApi.putSwapDevice(
            undefined,
            {
              previousDeviceID: selectedFloorPlan?.deviceID,
              newDeviceID: record.deviceID,
            }
          );

          const response = await webViewApiCall({
            token,
            url,
            method,
            contentType,
            cancelToken: this.axiosCancelSource.token,
          });
          const result = response?.data;

          if (result?.status === 'ok') {
            this._isMounted && handleNotification('success', result);
            onSwapModalClose();
          } else {
            this._isMounted && handleNotification('error', result);
          }
        } catch (error: any) {
          this._isMounted && handleNotification('error', error.data);
        }
      }
    }
    this.handleState({ loading: false });
  };

  getColumns = (): AntdTableColumnsType<DeviceDataType>[] => {
    return [
      { title: 'Device ID', key: 'deviceID', dataIndex: 'deviceID' },
      {
        title: 'Action',
        key: 'action',
        render: (text: string, record: DeviceDataType) => {
          return (
            <Fragment>
              <Button
                type="primary"
                htmlType="button"
                onClick={() => this.onSelect(record)}>
                Select
              </Button>
            </Fragment>
          );
        },
      },
    ];
  };

  render() {
    const { deviceList, loading } = this.state;

    return (
      <Fragment>
        <Card
          // loading={loading}
          bordered={false}
          className={cssStyles.swapModalCard}>
          <Row>
            <Col xs={24}>
              <AntdTable
                loading={loading}
                dataSource={deviceList}
                columns={this.getColumns()}
                customConfig={{ rowKeyValue: 'uuid' }}
                pagination={{
                  total: deviceList.length,
                  pageSize: 5,
                  simple: true,
                }}
              />
            </Col>
          </Row>
        </Card>
      </Fragment>
    );
  }
}

export default SwapModal;
