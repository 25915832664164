import { Fragment, Component, FormEvent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import update from 'immutability-helper';

import { updateToken } from '../redux/actions';
import {
  HardwareAttributeType,
  HardwareDetailsType,
  PartnerListType,
  SensorListType,
  UserDataType,
} from '../type-definitions/api-types';
import {
  StateType,
  detailsTabInputElements,
  AttributesTabElementsType,
  DetailsTabElementsType,
  SensorsTabElementsType,
  sensorsTabInputElements,
  attributesTabInputElements,
  AttributesOptionsType,
} from '../components/HardwareDetails/helpers';
import axios from 'axios';
import { hardwareApi, partnerApi } from '../api-services/api-list';
import { apiCall } from '../api-services/api';
import { SelectOptionsType } from '../type-definitions';
import { handleNotification } from '../utils/notification-handler';
import { v4 } from 'uuid';
import { checkValidation } from '../utils/validation';
import { Tabs } from 'antd';
import FormWrapper from '../components/FormWrapper';
import DetailsTab from '../components/HardwareDetails/DetailsTab';
import SensorsTab from '../components/HardwareDetails/SensorsTab';
import AttributesTab from '../components/HardwareDetails/AttributesTab';
import { hardwareRoutes } from '../Routes/routes-list';
import PromptPopup from '../components/PromptPopup';

interface PropsType
  extends RouteComponentProps<
      {},
      any,
      { hardwareID: string; allowEdit: boolean }
    >,
    PropsFromRedux {
  userData: Partial<UserDataType>;
  userPermissionList: string[];
}

class HardwareDetails extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    const { location } = props;
    this.state = {
      loading: true,
      currentTabIndex: '1',
      detailsTabElements: { ...detailsTabInputElements },
      sensorsTabElements: [],
      // attributesTabElements: [...this.getDefaultAttributeTabElements()],
      attributesTabElements: [],
      partnerList: [],
      hasInputChanged: false,

      allowEdit: location?.state?.allowEdit ?? false,
      hardwareID: location?.state?.hardwareID ?? '',
    };
  }

  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    this._isMounted = true;

    this.handleFetchedData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  handleState = (data: Partial<StateType>, callback?: () => void) => {
    this._isMounted &&
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            ...data,
          };
        },
        () => {
          callback?.();
        }
      );
  };

  getDefaultAttributeTabElements = (): AttributesTabElementsType[] => {
    return [
      {
        required: true,
        name: {
          elementType: 'input',
          elementConfig: {
            name: 'name',
          },
          value: 'transport',
          validation: {
            required: true,
          },
          valid: true,
          touched: true,
          errorMessage: 'Attribute Name is required',
          label: 'Attribute Name',
          disabled: true,
        },
        label: {
          elementType: 'input',
          elementConfig: {
            name: 'label',
          },
          value: 'Transport',
          validation: {
            required: true,
          },
          valid: true,
          touched: true,
          errorMessage: 'Attribute Label is required',
          label: 'Attribute Label',
          disabled: true,
        },
        helptext: {
          elementType: 'input',
          elementConfig: {
            name: 'helptext',
          },
          value: '',
          validation: {
            // required: true,
          },
          valid: true,
          touched: true,
          errorMessage: 'Helper Text is required',
          label: 'Helper Text',
        },
        chars: {
          elementType: 'input',
          elementConfig: {
            name: 'chars',
          },
          value: '20',
          validation: {
            required: true,
            isNumeric: true,
          },
          valid: true,
          touched: true,
          errorMessage: 'Chars is required',
          label: 'Chars',
        },
        type: {
          elementType: 'select',
          elementConfig: {
            name: 'type',
          },
          value: 'option',
          optionValues: [
            { text: 'String', value: 'string' },
            { text: 'Date', value: 'date' },
            { text: 'Option', value: 'option' },
          ],
          validation: {
            // required: true,
          },
          valid: true,
          touched: true,
          errorMessage: 'Type is required',
          label: 'Type',
        },
        default: {
          elementType: 'select',
          elementConfig: {
            name: 'default',
          },
          value: '',
          optionValues: [{ text: 'Please Select', value: '' }],
          validation: {
            // required: true,
          },
          valid: false,
          touched: false,
          errorMessage: 'Default is required',
          label: 'Default',
        },
        options: [],
        uuid: v4(),
      },
    ];
  };

  handleFetchedData = async () => {
    const { userData } = this.props;
    const { detailsTabElements, partnerList, allowEdit, hardwareID } =
      this.state;
    let tempFormElements = { ...detailsTabElements };

    let stateData: Partial<StateType> = {};

    if (partnerList.length === 0) {
      try {
        const { url, method, contentType } = partnerApi.getPartners();
        const partnerResponse = await apiCall({
          storeToken: userData.token,
          url,
          method,
          contentType,
          cancelToken: this.axiosCancelSource.token,
        });
        const partnerResult = partnerResponse?.data;

        if (partnerResult?.status === 'ok') {
          const tempPartnerData: PartnerListType[] = partnerResult.data;
          if (tempPartnerData) {
            tempFormElements = this.setPartnerSelectOptions(
              tempPartnerData,
              tempFormElements
            );

            stateData = update(stateData, {
              detailsTabElements: { $set: tempFormElements },
              partnerList: { $set: [...tempPartnerData] },
            });
          }
        } else {
          this._isMounted && handleNotification('error', partnerResult);
        }
      } catch (error: any) {
        this._isMounted && handleNotification('error', error?.data);
      }
    } else {
      tempFormElements = this.setPartnerSelectOptions(
        partnerList,
        tempFormElements
      );

      stateData = update(stateData, {
        detailsTabElements: { $set: tempFormElements },
        partnerList: { $set: [...partnerList] },
      });
    }

    if (hardwareID) {
      try {
        const { url, method, contentType } = hardwareApi.getHardwareDetails(
          undefined,
          {
            templateID: hardwareID,
          }
        );
        const detailsResponse = await apiCall({
          storeToken: userData.token,
          url,
          method,
          contentType,
          cancelToken: this.axiosCancelSource.token,
        });
        const detailsResult = detailsResponse?.data;

        if (detailsResult?.status === 'ok') {
          const tempDetails: HardwareDetailsType = detailsResult.data;
          if (tempDetails) {
            tempFormElements = update(tempFormElements, {
              hardwareID: { value: { $set: tempDetails.id } },
              make: { value: { $set: tempDetails.make } },
              model: { value: { $set: tempDetails.model } },
              partner: { value: { $set: tempDetails.partnerID } },
            });

            stateData = update(stateData, {
              detailsTabElements: { $set: tempFormElements },
            });

            let tempSensorTabElements: SensorsTabElementsType[] = [];
            if (
              tempDetails?.sensorSpecs &&
              tempDetails.sensorSpecs.length > 0
            ) {
              tempDetails.sensorSpecs.forEach((el) => {
                let tempSensor: SensorsTabElementsType = {
                  ...sensorsTabInputElements,
                };

                let tempMarkers =
                  el.markers &&
                  el.markers.map((elem) => ({ ...elem, uuid: v4() }));

                tempSensor = update(tempSensor, {
                  shortName: {
                    value: { $set: el.shortName },
                    disabled: { $set: !allowEdit },
                  },
                  units: {
                    value: { $set: el.units },
                    disabled: { $set: !allowEdit },
                  },
                  longName: {
                    value: { $set: el.longName },
                    disabled: { $set: !allowEdit },
                  },
                  minScale: {
                    value: { $set: el.minScale.toString() },
                    disabled: { $set: !allowEdit },
                  },
                  maxScale: {
                    value: { $set: el.maxScale.toString() },
                    disabled: { $set: !allowEdit },
                  },
                  // amberPoint: {
                  //   value: { $set: el.amberPoint?.toString() || '' },
                  //   disabled: { $set: !allowEdit },
                  // },
                  // redPoint: {
                  //   value: { $set: el.redPoint?.toString() || '' },
                  //   disabled: { $set: !allowEdit },
                  // },
                  graphColour: {
                    value: { $set: el.graphColour.toString() },
                    disabled: { $set: !allowEdit },
                  },
                  compass: { $set: el.compass },
                  uuid: { $set: v4() },
                  markers: { $set: tempMarkers },
                });
                tempSensorTabElements = update(tempSensorTabElements, {
                  $push: Object.keys(tempSensor).length > 0 ? [tempSensor] : [],
                });
              });
            }
            stateData = update(stateData, {
              sensorsTabElements: { $set: tempSensorTabElements },
            });

            let tempAttributesTabElements: AttributesTabElementsType[] = [];
            if (tempDetails?.attributes && tempDetails.attributes.length > 0) {
              tempDetails.attributes.forEach((el) => {
                let tempAttribute: AttributesTabElementsType = {
                  ...attributesTabInputElements,
                };
                let tempOptionList: AttributesOptionsType[] = [];

                el?.options?.forEach((elem) => {
                  let tempOption: AttributesOptionsType = {
                    label: elem.label,
                    value: elem.value,
                    uuid: v4(),
                  };
                  tempOptionList = update(tempOptionList, {
                    $push: [tempOption],
                  });
                });

                tempAttribute = update(tempAttribute, {
                  name: {
                    value: { $set: el.name },
                    disabled: { $set: !allowEdit },
                  },
                  label: {
                    value: { $set: el.label },
                    disabled: { $set: !allowEdit },
                  },
                  helptext: {
                    value: { $set: el.helptext },
                    disabled: { $set: !allowEdit },
                  },
                  type: {
                    value: { $set: el.type },
                    disabled: { $set: !allowEdit },
                  },
                  chars: {
                    value: { $set: el.chars.toString() },
                    disabled: { $set: !allowEdit },
                  },
                  required: { $set: el.required },
                  default: {
                    value: { $set: el.default },
                    disabled: { $set: !allowEdit },
                  },
                  options: { $set: tempOptionList },
                  uuid: { $set: v4() },
                });

                tempAttributesTabElements = update(tempAttributesTabElements, {
                  $push:
                    Object.keys(tempAttribute).length > 0
                      ? [tempAttribute]
                      : [],
                });
              });
            }

            stateData = update(stateData, {
              attributesTabElements: { $set: tempAttributesTabElements },
            });
          }
        } else {
          this._isMounted && handleNotification('error', detailsResult);
        }
      } catch (error: any) {
        console.log(error);
        this._isMounted && handleNotification('error', error.data);
      }
    }

    for (const key in tempFormElements) {
      tempFormElements = update(tempFormElements, {
        [key]: { disabled: { $set: !allowEdit } },
      });
    }

    stateData = update(stateData, {
      loading: { $set: false },
      detailsTabElements: { $set: tempFormElements },
    });

    this.handleState(stateData);
  };

  setPartnerSelectOptions = (
    partners: PartnerListType[],
    elements: DetailsTabElementsType
  ) => {
    const { userData } = this.props;
    const partnerOptions: SelectOptionsType[] = partners.map((el) => ({
      text: el.partnerName,
      value: el.partnerID,
    }));
    const matched = partners.find((el) => el.partnerID === userData.partnerID);

    elements = update(elements, {
      partner: {
        optionValues: { $set: [...partnerOptions] },
        value: {
          $set: matched ? matched.partnerID : partners[0].partnerID,
        },
      },
    });

    return elements;
  };

  detailsInputChangedHandler = (
    name: keyof DetailsTabElementsType,
    value: string
  ) => {
    const { detailsTabElements } = this.state;

    let tempFormElements = { ...detailsTabElements };

    if (name) {
      tempFormElements = update(tempFormElements, {
        [name]: {
          touched: { $set: true },
          valid: {
            $set: checkValidation(value, tempFormElements[name].validation),
          },
          value: { $set: value },
        },
      });

      this._isMounted &&
        this.setState({
          detailsTabElements: tempFormElements,
          hasInputChanged: true,
        });
    }
  };

  handleSensorsTabElements = (data: SensorsTabElementsType[]) => {
    this.handleState({ sensorsTabElements: data, hasInputChanged: true });
  };

  handleAttributesTabElements = (data: AttributesTabElementsType[]) => {
    this.handleState({ attributesTabElements: data, hasInputChanged: true });
  };

  onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    this.handleSubmit();
  };

  handleSubmit = async (onConfirm?: () => void) => {
    const { detailsTabElements, sensorsTabElements, attributesTabElements } =
      this.state;

    const { userData, updateToken } = this.props;

    // if (sensorsTabElements.length === 0) {
    //   message.error('At least one sensor is needed');
    //   return;
    // }

    try {
      this._isMounted && this.setState({ loading: true });
      const { url, method, contentType } = hardwareApi.putHardwareDetails(
        { undefined },
        { templateID: detailsTabElements.hardwareID.value }
      );
      let sensorsData: Partial<SensorListType>[] = [];
      sensorsTabElements.forEach((el) => {
        let markers = undefined;
        if (el.markers && el.markers.length > 0) {
          markers = el.markers.map((el) => {
            const { uuid, ...rest } = el;
            return rest;
          });
        }
        sensorsData = update(sensorsData, {
          $push: [
            {
              shortName: el.shortName.value,
              units: el.units.value,
              longName: el.longName.value,
              minScale: parseInt(el.minScale.value),
              maxScale: parseInt(el.maxScale.value),
              // amberPoint: parseInt(el.amberPoint.value),
              // redPoint: parseInt(el.redPoint.value),
              graphColour: el.graphColour.value,
              compass: el.compass,
              markers,
            },
          ],
        });
      });
      let attributesData: Partial<HardwareAttributeType>[] = [];
      attributesTabElements.forEach((el) => {
        attributesData = update(attributesData, {
          $push: [
            {
              name: el.name.value,
              label: el.label.value,
              helptext: el.helptext.value,
              type: el.type.value,
              chars: parseInt(el.chars.value),
              required: el.required,
              default: el.default.value || undefined,
              options:
                el.options.length > 0
                  ? el.options.map((elem) => {
                      const { uuid, ...rest } = elem;
                      return rest;
                    })
                  : undefined,
            },
          ],
        });
      });
      const apiData = {
        make: detailsTabElements.make.value,
        model: detailsTabElements.model.value,
        sensorSpecs: [...sensorsData],
        attributes: [...attributesData],
        partnerID: detailsTabElements.partner.value,
      };
      const response = await apiCall({
        storeToken: userData.token,
        url,
        method,
        contentType,
        cancelToken: this.axiosCancelSource.token,
        data: apiData,
      });
      const result = response?.data;
      if (result?.status === 'ok') {
        updateToken(result);
        this._isMounted && handleNotification('success', result);

        this._isMounted &&
          this.setState(
            {
              loading: false,
              currentTabIndex: '1',
              detailsTabElements: { ...detailsTabInputElements },
              sensorsTabElements: [],
              attributesTabElements: [],
              hasInputChanged: false,
            },
            () => {
              if (onConfirm) {
                onConfirm();
              } else {
                this.handleFetchedData();
              }
            }
          );
      } else {
        this._isMounted && handleNotification('error', result);
        this.handleState({ currentTabIndex: '1', loading: false });
      }
    } catch (error: any) {
      console.log('error', error);
      this._isMounted && handleNotification('error', error.data);
      this.handleState({ currentTabIndex: '1', loading: false });
    }
  };

  handlePopupConfirm = (onConfirm: () => void) => {
    this.handleSubmit(onConfirm);
  };

  setCurrentTabIndex = (index: string) => {
    this._isMounted && this.setState({ currentTabIndex: index });
  };

  checkFormValidation = (): boolean => {
    const { detailsTabElements, currentTabIndex } = this.state;

    let isValid = true;

    if (currentTabIndex === '1') {
      let tempFormElements = { ...detailsTabElements };
      let key: keyof typeof tempFormElements;
      let elementsName: string[] = Object.keys(detailsTabElements);

      for (key in tempFormElements) {
        if (elementsName.includes(key)) {
          tempFormElements = update(tempFormElements, {
            [key]: {
              touched: { $set: true },
              valid: {
                $set: checkValidation(
                  tempFormElements[key].value,
                  tempFormElements[key].validation
                ),
              },
            },
          });
        }
      }

      for (key in tempFormElements) {
        if (elementsName.includes(key) && !tempFormElements[key].valid) {
          isValid = false;
        }
      }
    } else if (currentTabIndex === '2') {
      // if (sensorsTabElements.length === 0) {
      //   isValid = false;
      // }
    }

    return isValid;
  };

  render() {
    const {
      loading,
      currentTabIndex,
      detailsTabElements,
      sensorsTabElements,
      attributesTabElements,
      hasInputChanged,
      allowEdit,
      hardwareID,
    } = this.state;

    const { userPermissionList } = this.props;

    if (!hardwareID) {
      return <Redirect to={hardwareRoutes.list} />;
    }

    if (userPermissionList.length === 0) {
      return <Redirect to="/" />;
    }

    let isSubmitDisabled = !this.checkFormValidation();

    if (allowEdit === false) {
      isSubmitDisabled = true;
    }

    return (
      <Fragment>
        {hasInputChanged && (
          <PromptPopup
            when={hasInputChanged}
            handleConfirm={this.handlePopupConfirm}
          />
        )}
        <FormWrapper
          loading={loading}
          title={allowEdit ? 'Hardware Edit' : 'Hardware View'}>
          <form onSubmit={this.onFormSubmit} noValidate>
            <Tabs
              activeKey={currentTabIndex}
              onTabClick={this.setCurrentTabIndex}>
              <Tabs.TabPane tab={'Details'} key="1">
                <DetailsTab
                  detailsTabElements={detailsTabElements}
                  detailsInputChangedHandler={this.detailsInputChangedHandler}
                  isSubmitDisabled={isSubmitDisabled}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab={'Sensors'} key="2">
                <SensorsTab
                  handleSensorsTabElements={this.handleSensorsTabElements}
                  sensorsTabElements={sensorsTabElements}
                  setCurrentTabIndex={this.setCurrentTabIndex}
                  allowEdit={allowEdit}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab={'Attributes'} key="3">
                <AttributesTab
                  handleAttributesTabElements={this.handleAttributesTabElements}
                  attributesTabElements={attributesTabElements}
                  allowEdit={allowEdit}
                />
              </Tabs.TabPane>
            </Tabs>
          </form>
        </FormWrapper>
      </Fragment>
    );
  }
}

const mapDispatch = {
  updateToken: updateToken,
};

const connector = connect(null, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(HardwareDetails);
