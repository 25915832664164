import React, { Fragment, Component, FormEvent } from 'react';
import { Checkbox } from 'antd';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import cloneDeep from 'lodash/cloneDeep';
import { Row, Col } from 'antd';
import update from 'immutability-helper';

import { GroupListType } from '../../../type-definitions/api-types';
import { GroupsTabPropsType, GroupsTabStateType } from '../helpers';
import { connect, ConnectedProps } from 'react-redux';
import { updateToken } from '../../../redux/actions';
import {
  AntdTableColumnsType,
  ReduxStateType,
} from '../../../type-definitions';
import axios from 'axios';
import { apiCall } from '../../../api-services/api';
import { locationApi } from '../../../api-services/api-list';
import { v4 } from 'uuid';
import AntdTable from '../../AntdTable';
import {
  handleFormBody,
  handleSorting,
  handleTableSearch,
} from '../../../utils';
import { handleNotification } from '../../../utils/notification-handler';
import FilterInput from '../../FilterInput';

type PropsType = PropsFromRedux & GroupsTabPropsType;

class GroupsTab extends Component<PropsType, GroupsTabStateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      locationGroups: [],
      groups: [],
      initGroups: [],
      defaultGroups: [],
      loading: true,
      error: {},
      searchValueOtherGroups: '',
      filterByPartnerObj: {},
    };
  }

  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    this._isMounted = true;

    this.handleFetchedData();
  }

  handleFetchedData = async () => {
    const { updateToken, userData, partnerID, locationGroupList } = this.props;
    const { groups, loading, locationGroups } = this.state;
    const { url, method, contentType } =
      locationApi.getAssignableLocationGroups();
    let tempGroups = [...groups];
    if (groups.length === 0) {
      if (!loading) this._isMounted && this.setState({ loading: true });
      const response = await apiCall({
        storeToken: userData?.token,
        url,
        method,
        contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      updateToken(result);

      if (result?.status === 'error') {
        this.setState({ error: result, loading: false });
      } else if (result?.data && result.data.length > 0) {
        tempGroups = result.data.map((item: GroupListType) => ({
          ...item,
          uuid: v4(),
        }));
      }
    }

    let tempLocationGroups = cloneDeep(locationGroups);
    if (locationGroupList && locationGroupList.length > 0) {
      tempLocationGroups = cloneDeep(locationGroupList);
    }

    const tempDefaultGroups: GroupListType[] = [];
    if (tempGroups.length > 0) {
      tempGroups.forEach((item) => {
        if (item.groupType === 'ROLE' && item.partnerID === partnerID) {
          tempDefaultGroups.push(item);
        }
      });
    }

    // if (tempDefaultGroups.length === 0) {
    //   message.warning(
    //     `Selected Partner ID does not match with Group Partner ID's`
    //   );
    // }

    this.handleCheckboxes({
      locationGroups: tempLocationGroups,
      groups: tempGroups,
      defaultGroups: tempDefaultGroups,
    });
  };

  handleCheckboxes = ({
    locationGroups,
    groups,
    defaultGroups,
  }: {
    locationGroups: GroupListType[];
    groups: GroupListType[];
    defaultGroups: GroupListType[];
  }) => {
    let tempGroups = cloneDeep(groups);
    let tempLocationGroups = cloneDeep(locationGroups);
    let tempDefaultGroups = cloneDeep(defaultGroups);

    if (tempLocationGroups.length === 0) {
      tempGroups = tempGroups.filter((item) => {
        return !tempDefaultGroups.find((el) => {
          return item.groupID === el.groupID;
        });
      });
      tempLocationGroups = cloneDeep(defaultGroups);
    } else if (tempLocationGroups.length > 0) {
      tempGroups = tempGroups.filter((item) => {
        return !tempLocationGroups.find((el) => {
          return item.groupID === el.groupID;
        });
      });

      // tempGroupList = tempGroupList.filter((item) => {
      //   const temp = tempLocationGroupList.find(
      //     (el) => item.groupID === el.groupID
      //   );
      //   if (temp) {
      //     return null;
      //   }
      //   return item;
      // });
    }
    let tempPartnerObj: { [k: string]: boolean } = {};
    if (tempGroups) {
      tempGroups.forEach((el) => {
        if (el.partnerID) {
          tempPartnerObj = update(tempPartnerObj, {
            [el.partnerID]: { $set: true },
          });
        }
      });
    }

    this.setState((prev) => ({
      ...prev,
      loading: false,
      groups: tempGroups,
      initGroups: tempGroups,
      locationGroups: tempLocationGroups,
      defaultGroups: tempDefaultGroups,
      filterByPartnerObj: tempPartnerObj,
    }));
  };

  handleDelete = async (record: GroupListType) => {
    if (record.groupID) {
      const { locationGroups, groups } = this.state;
      const { onOkayLocationGroupsTab, userData, formElements } = this.props;
      let tempLocationGroupList = [...locationGroups];
      let tempGroupList = [...groups];

      try {
        const { url, method, contentType } = locationApi.deleteLocationGroup(
          undefined,
          {
            locationID: formElements.locationID.value,
            groupID: record.groupID,
          }
        );
        const response = await apiCall({
          storeToken: userData.token,
          url,
          method,
          contentType,
          cancelToken: this.axiosCancelSource.token,
        });

        const result = response?.data;

        if (result?.status === 'ok') {
          tempLocationGroupList = tempLocationGroupList.filter(
            (item) => item.groupID !== record.groupID
          );

          tempGroupList.unshift(record);
          handleNotification('success', result);
          this.setState(
            {
              groups: tempGroupList,
              locationGroups: tempLocationGroupList,
            },
            () => {
              onOkayLocationGroupsTab(tempLocationGroupList);
            }
          );
        } else {
          handleNotification('error', result);
        }
      } catch (error: any) {
        handleNotification('error', error.data);
      }
    } else {
      handleNotification('error', {
        status: 'error',
        message: 'Group ID is required!',
      });
    }
  };

  handleAdd = async (record: GroupListType) => {
    if (record.groupID) {
      const { locationGroups, groups } = this.state;
      const { onOkayLocationGroupsTab, formElements, userData } = this.props;
      let tempLocationGroupList = [...locationGroups];
      let tempGroupList = [...groups];

      try {
        const { url, method, contentType } = locationApi.postLocationGroup(
          undefined,
          {
            locationID: formElements.locationID.value,
          }
        );
        const response = await apiCall({
          storeToken: userData.token,
          url,
          method,
          contentType,
          data: handleFormBody({ groupid: record.groupID }),
          cancelToken: this.axiosCancelSource.token,
        });
        const result = response?.data;
        if (result?.status === 'ok') {
          tempGroupList = tempGroupList.filter(
            (item) => item.groupID !== record.groupID
          );

          tempLocationGroupList.unshift(record);
          handleNotification('success', result);
          this.setState(
            {
              groups: tempGroupList,
              locationGroups: tempLocationGroupList,
            },
            () => {
              onOkayLocationGroupsTab(tempLocationGroupList);
            }
          );
        } else {
          handleNotification('error', result);
        }
      } catch (error: any) {
        handleNotification('error', error.data);
      }
    } else {
      handleNotification('error', {
        status: 'error',
        message: 'Group ID is required!',
      });
    }
  };

  handleSearchOtherGroups = (e: FormEvent<HTMLInputElement>) => {
    const value = e?.currentTarget?.value;
    const { initGroups } = this.state;
    const { defaultGroupsChecked } = this.props;
    let tempGroups = cloneDeep(initGroups);
    if (value) {
      const columns = getColumns({
        handleAdd: this.handleAdd,
        defaultGroupsChecked,
      }).map((el) => el.key);

      tempGroups = handleTableSearch({
        data: tempGroups,
        columnList: columns,
        searchData: value,
      });
    }

    this._isMounted &&
      this.setState((prev) => ({
        ...prev,
        groups: tempGroups,
        searchValueOtherGroups: value,
      }));
  };

  handleCheckboxFilter = (checkBoxData: { [k: string]: boolean }) => {
    const { initGroups } = this.state;
    const tempGroups = cloneDeep(initGroups);
    const filteredData = tempGroups?.filter((el) => {
      if (el.partnerID && checkBoxData[el.partnerID]) {
        return el;
      }
      return null;
    });

    this._isMounted &&
      this.setState((prev) => ({
        ...prev,
        groups: filteredData,
        filterByPartnerObj: checkBoxData,
      }));
  };

  render() {
    const {
      loading,
      locationGroups,
      groups,
      searchValueOtherGroups,
      filterByPartnerObj,
    } = this.state;
    const { defaultGroupsChecked, handleCheckbox } = this.props;

    return (
      <Fragment>
        <Row className="my-3">
          <Col xs={12}>
            <Checkbox
              name={`defaultGroupsChecked`}
              onChange={handleCheckbox}
              checked={defaultGroupsChecked}>
              {`Add To Default Groups`}
            </Checkbox>
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <Row>
              <Col xs={24} md={12}>
                <strong
                  style={{
                    fontSize: `1.25rem`,
                  }}>{`Current Groups`}</strong>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={24}>
                <AntdTable
                  loading={loading}
                  columns={getColumns({
                    handleDelete: this.handleDelete,
                    defaultGroupsChecked,
                  })}
                  dataSource={locationGroups}
                  pagination={{
                    pageSize: 5,
                    total: locationGroups.length,
                  }}
                  customConfig={{ rowKeyValue: 'groupID' }}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col xs={24}>
            <Row>
              <Col xs={24} md={12}>
                <strong
                  style={{
                    fontSize: `1.25rem`,
                  }}>{`Other Groups`}</strong>
              </Col>
              <Col xs={24} md={12} className="pt-2 pt-md-0">
                <FilterInput
                  handleSearchFilter={this.handleSearchOtherGroups}
                  loading={loading}
                  searchValue={searchValueOtherGroups}
                  listData={filterByPartnerObj}
                  handleCheckboxFilter={this.handleCheckboxFilter}
                />
              </Col>
            </Row>
            <Row className="py-3">
              <Col xs={24}>
                <AntdTable
                  loading={loading}
                  columns={getColumns({
                    handleAdd: this.handleAdd,
                    defaultGroupsChecked,
                  })}
                  dataSource={groups}
                  pagination={{
                    pageSize: 5,
                    total: groups.length,
                  }}
                  customConfig={{ rowKeyValue: 'groupID' }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapDispatch = {
  updateToken: updateToken,
};

function mapStateToProps(state: ReduxStateType) {
  return {
    userData: state.auth.userData,
  };
}

const connector = connect(mapStateToProps, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(GroupsTab);

function getColumns({
  handleAdd,
  handleDelete,
  defaultGroupsChecked,
}: {
  handleAdd?: (data: GroupListType) => void;
  handleDelete?: (data: GroupListType) => void;
  defaultGroupsChecked: boolean;
}): AntdTableColumnsType<GroupListType>[] {
  return [
    {
      title: 'Group Name',
      dataIndex: 'groupName',
      key: 'groupName',
      sorter: (a: GroupListType, b: GroupListType) =>
        handleSorting(a.groupName, b.groupName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Partner',
      dataIndex: 'partnerID',
      key: 'partnerID',
      sorter: (a: GroupListType, b: GroupListType) =>
        handleSorting(a.partnerID, b.partnerID),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Group Type',
      dataIndex: 'groupType',
      key: 'groupType',
      sorter: (a: GroupListType, b: GroupListType) =>
        handleSorting(a.groupType, b.groupType),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record: GroupListType) => (
        <Fragment>
          {handleDelete && !defaultGroupsChecked && (
            <FaTrashAlt
              style={{ color: 'red', cursor: 'pointer' }}
              onClick={() => handleDelete(record)}
            />
          )}

          {handleAdd && !defaultGroupsChecked && (
            <FaPlus
              style={{ cursor: 'pointer', color: 'green' }}
              onClick={() => handleAdd(record)}
            />
          )}
        </Fragment>
      ),
    },
  ];
}
