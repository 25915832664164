import { FormInputType } from '../../../type-definitions';
import { UserDetailsType } from '../../../type-definitions/api-types';

export const initialElements: FormElementsType = {
  // 0
  userID: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'User ID',
      name: 'userID',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'User ID is required',
    label: 'User ID *',
    disabled: true,
    notVisible: true,
  },
  // 1
  userName: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'User Name',
      name: 'userName',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'User Name is required',
    label: 'User Name *',
    fieldRights: 'EditUserNames',
  },
  // 2
  partnerName: {
    elementType: 'select',
    elementConfig: {
      name: 'partnerName',
    },
    value: '',
    optionValues: [],
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Partner Name is required',
    label: 'Partner Name *',
    // fieldRights: 'EditUserNames',
  },
  // 3
  userEmail: {
    elementType: 'input',
    elementConfig: {
      type: 'email',
      placeholder: 'User Email',
      name: 'userEmail',
    },
    value: '',
    validation: {
      // required: true,
      isEmail: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'User Email must be valid',
    label: 'User Email',
    fieldRights: 'EditUserEmails',
  },
  // 4
  userPhone: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'User Phone',
      name: 'userPhone',
    },
    value: '',
    validation: {
      // required: true,
      isPhone: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'User Phone must be valid',
    label: 'User Phone *',
    fieldRights: 'EditUserPhones',
  },
  // 5
  latitude: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Lat',
      name: 'latitude',
    },
    value: '0',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Lat is required',
    label: 'Lat',
    labelIcon: true,
    colValue: 4,
    fieldRights: 'EditUserPositions',
  },
  // 6
  longitude: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Lon',
      name: 'longitude',
    },
    value: '0',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Lon is required',
    label: 'Lon',
    labelIcon: true,
    colValue: 4,
    fieldRights: 'EditUserPositions',
  },
  // 7
  userLevel: {
    elementType: 'select',
    elementConfig: {
      name: 'userLevel',
    },
    optionValues: [],
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'User Level is required',
    label: 'User Level *',
  },
  // 8
  tempPassword: {
    elementType: 'input',
    elementConfig: {
      type: 'password',
      placeholder: 'Temporary Password',
      name: 'tempPassword',
      autoComplete: 'new-password',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Temporary Password is required',
    label: 'Temporary Password',
    showPeep: true,
    fieldRights: 'EditUserTemporaryPasswords',
  },
};

export type FormElementsType = {
  userID: FormInputType;
  userName: FormInputType;
  partnerName: FormInputType;
  userEmail: FormInputType;
  userPhone: FormInputType;
  latitude: FormInputType;
  longitude: FormInputType;
  userLevel: FormInputType;
  tempPassword: FormInputType;
};

export type StateType = {
  formElements: FormElementsType;
  isChecked: boolean;
  showMap: boolean;
  mapZoom: number;
  loading: boolean;
  initLat: string;
  initLng: string;
  initZoom: number;
  currentTabIndex: string;
  currentUserDetails: Partial<UserDetailsType>;
  hasInputChanged: boolean;

  allowEdit: boolean;
  userID: string;
};
