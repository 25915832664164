import axios from 'axios';
import { Component, FormEvent, Fragment, ReactNode } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import update from 'immutability-helper';

import {
  FormElementsType,
  initialElements,
  StateType,
} from '../components/UserDetails/helpers';
import { updateToken } from '../redux/actions';
import {
  ApiErrorType,
  PartnerListType,
  UserDataType,
  UserDetailsType,
} from '../type-definitions/api-types';
import { apiCall } from '../api-services/api';
import { partnerApi, userApi } from '../api-services/api-list';
import { FormInputObjectType, SelectOptionsType } from '../type-definitions';
import { userRights } from '../utils/permission-list';
import { checkValidation } from '../utils/validation';
import { Button, message, Tabs } from 'antd';
import { handleFormBody } from '../utils';
import { userRoutes } from '../Routes/routes-list';
import FormWrapper from '../components/FormWrapper';
import AntdInput from '../components/AntdInput';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { v4 } from 'uuid';
import GroupsTab from '../components/UserDetails/components/GroupsTab';

import cssStyles from '../components/UserDetails/styles/userDetails.module.scss';
import ApiKeysTab from '../components/UserDetails/components/ApiKeysTab';
import Modal from 'antd/lib/modal/Modal';
import LeafletMap from '../components-shared/LeafletMap';
import { handleNotification } from '../utils/notification-handler';
import PromptPopup from '../components/PromptPopup';

interface PropsType
  extends RouteComponentProps<{}, any, { userID: string; allowEdit: boolean }>,
    PropsFromRedux {
  userData: Partial<UserDataType>;
  userPermissionList: string[];
}

class UserDetails extends Component<PropsType, StateType> {
  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  constructor(props: PropsType) {
    super(props);
    const { location } = props;
    this.state = {
      isChecked: false,
      showMap: false,
      mapZoom: 10,
      loading: true,
      initLat: '0',
      initLng: '0',
      initZoom: 10,
      currentTabIndex: '1',
      currentUserDetails: {},
      formElements: {
        ...initialElements,
        tempPassword: {
          ...initialElements.tempPassword,
          showPeep: true,
          onInputHover: this.handlePeepIcon,
        },
      },
      hasInputChanged: false,

      allowEdit: location?.state?.allowEdit ?? false,
      userID: location?.state?.userID ?? '',
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.axiosCancelSource = axios.CancelToken.source();
    const { userID } = this.state;

    if (userID) {
      this.handleFetchedData({ userID });
    } else {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  fetchData = async ({ userID }: { userID: string }) => {
    const { userData, updateToken } = this.props;
    try {
      const user = userApi.getUserDetails(null, { userID });
      const response = await apiCall({
        storeToken: userData?.token,
        url: user.url,
        method: user.method,
        contentType: user.contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      updateToken(result);
      const partner = partnerApi.getPartners();
      const partnerResponse = await apiCall({
        storeToken: userData.token,
        url: partner.url,
        method: partner.method,
        contentType: partner.contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const partnerResult = partnerResponse?.data;
      updateToken(partnerResult);
      const partners: PartnerListType[] = partnerResult?.data;
      const tempUserDetails: UserDetailsType = result?.data;
      if (result.status === 'ok' && tempUserDetails?.partnerid) {
        const roles = partnerApi.getPartnerRoles(undefined, {
          partnerID: tempUserDetails?.partnerid,
        });
        const response2 = await apiCall({
          storeToken: userData?.token,
          url: roles.url,
          method: roles.method,
          contentType: roles.contentType,
          cancelToken: this.axiosCancelSource.token,
        });
        const result2 = response2?.data;
        updateToken(result2);
        return {
          userDetails: result.data,
          partnerRoles: result2.data || [],
          partners: partners || [],
        };
      } else {
        return { error: result };
      }
    } catch (error) {
      return { error };
    }
  };

  handleFetchedData = async ({ userID }: { userID: string }) => {
    const {
      userDetails,
      error,
      partnerRoles,
      partners,
    }: {
      userDetails?: UserDetailsType;
      error?: ApiErrorType;
      partnerRoles?: string[];
      partners?: PartnerListType[];
    } = await this.fetchData({ userID });
    if (error) {
      this._isMounted && handleNotification('error', error);
    } else {
      const { formElements, allowEdit } = this.state;
      let tempFormElements = { ...formElements };

      const { userData } = this.props;
      if (
        userDetails &&
        Object.keys(userDetails).length > 0 &&
        tempFormElements.userLevel.optionValues &&
        tempFormElements.userLevel.optionValues.length === 0
      ) {
        if (partnerRoles && partnerRoles.length > 1) {
          const userLevelOptionValues: SelectOptionsType[] = [
            { value: '', text: 'Please Select' },
          ];
          partnerRoles.forEach((item) => {
            userLevelOptionValues.push({ value: item, text: item });
          });
          tempFormElements = update(tempFormElements, {
            userLevel: {
              optionValues: { $set: [...userLevelOptionValues] },
              value: { $set: userDetails?.level },
            },
          });
        } else if (
          partnerRoles &&
          (partnerRoles.length === 0 ||
            (partnerRoles.length === 1 &&
              userDetails.level === partnerRoles[0]))
        ) {
          tempFormElements = update(tempFormElements, {
            userLevel: {
              disabled: { $set: true },
              value: { $set: userDetails?.level },
            },
          });
        } else if (
          partnerRoles &&
          partnerRoles.length === 1 &&
          userDetails.level !== partnerRoles[0]
        ) {
          const userLevelOptionValues: SelectOptionsType[] = [
            { value: '', text: 'Please Select' },
          ];
          partnerRoles.forEach((item) => {
            userLevelOptionValues.push({ value: item, text: item });
          });
          tempFormElements = update(tempFormElements, {
            userLevel: {
              optionValues: { $set: [...userLevelOptionValues] },
              value: { $set: userDetails?.level },
            },
          });
        }

        let key: keyof typeof tempFormElements;
        for (key in tempFormElements) {
          tempFormElements = update(tempFormElements, {
            [key]: {
              disabled: { $set: !allowEdit },
            },
          });
        }

        tempFormElements = update(tempFormElements, {
          userID: {
            value: { $set: userDetails?.uid },
          },
          userName: {
            value: { $set: userDetails?.username },
          },
          userEmail: {
            value: { $set: userDetails?.email },
          },
          userPhone: {
            value: { $set: userDetails?.phone },
          },
          latitude: {
            value: {
              $set:
                userDetails.lat?.toString() || userData.lat?.toString() || '',
            },
          },
          longitude: {
            value: {
              $set:
                userDetails.lng?.toString() || userData.lng?.toString() || '',
            },
          },
        });

        if (partners) {
          partners.forEach((el) => {
            tempFormElements = update(tempFormElements, {
              partnerName: {
                optionValues: {
                  $push: [{ text: el.partnerName, value: el.partnerID }],
                },
              },
            });
          });
          tempFormElements = update(tempFormElements, {
            partnerName: { value: { $set: userDetails.partnerid } },
          });
        }

        this._isMounted &&
          this.setState({
            formElements: tempFormElements,
            isChecked: userDetails.allocatenewtokenonlogin,
            loading: false,
            currentUserDetails: userDetails || {},
            initLat: tempFormElements.latitude.value,
            initLng: tempFormElements.longitude.value,
          });
      } else {
        this._isMounted && this.setState({ loading: false });
      }
    }
  };

  inputChangedHandler = (name: keyof FormElementsType, value: string) => {
    const { formElements } = this.state;

    let tempFormElements = { ...formElements };

    if (
      value &&
      (name === 'latitude' || name === 'longitude') &&
      !checkValidation(value, { isNegativeFloat: true })
    ) {
      message.error('Please enter valid Coordinates');
      return;
    }

    if (name) {
      tempFormElements = update(tempFormElements, {
        [name]: {
          value: { $set: value },
          touched: { $set: true },
          valid: {
            $set: checkValidation(value, tempFormElements[name].validation),
          },
        },
      });

      this._isMounted &&
        this.setState({
          formElements: { ...tempFormElements },
          hasInputChanged: true,
        });
    }
  };

  onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    this.handleSubmit();
  };

  handleSubmit = async (onConfirm?: () => void) => {
    const { formElements, isChecked, currentUserDetails } = this.state;
    const { userData, updateToken } = this.props;

    let tempFormElements = { ...formElements };

    let key: keyof typeof tempFormElements;

    for (key in tempFormElements) {
      tempFormElements = update(tempFormElements, {
        [key]: {
          touched: { $set: true },
          valid: {
            $set: checkValidation(
              tempFormElements[key].value,
              tempFormElements[key].validation
            ),
          },
        },
      });
    }

    this._isMounted && this.setState({ formElements: tempFormElements });

    for (key in tempFormElements) {
      if (tempFormElements[key].fieldRights) {
        let hasPermission: boolean | undefined;
        if (currentUserDetails.rights) {
          hasPermission = currentUserDetails.rights.includes(
            tempFormElements[key].fieldRights || ''
          );
        }

        if (hasPermission && !tempFormElements[key].valid) {
          return;
        }
      } else {
        if (!tempFormElements[key].valid) {
          return;
        }
      }
    }

    const data = {
      user_name: tempFormElements.userName.value,
      user_email: tempFormElements.userEmail.value,
      user_phone: tempFormElements.userPhone.value,
      user_level: tempFormElements.userLevel.value,
      user_pwd_token: tempFormElements.tempPassword.value,
      allocate_new_token_on_login: isChecked,
      lat: tempFormElements.latitude.value,
      lng: tempFormElements.longitude.value,
      partnerid: tempFormElements.partnerName.value,
    };
    const formData = handleFormBody(data);

    this._isMounted && this.setState({ loading: true });

    try {
      const { url, method, contentType } = userApi.putUser(null, {
        userID: tempFormElements.userID.value,
      });
      const response = await apiCall({
        storeToken: userData?.token,
        url,
        method,
        contentType,
        data: formData,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      updateToken(result);
      if (result?.status === 'ok') {
        this._isMounted && handleNotification('success', result);
        this._isMounted &&
          this.setState({ loading: false, hasInputChanged: false }, () => {
            onConfirm?.();
          });
      } else {
        this._isMounted && handleNotification('error', result);
        this._isMounted && this.setState({ loading: false });
      }
    } catch (error: any) {
      this._isMounted && handleNotification('error', error.data);
      this._isMounted && this.setState({ loading: false });
    }
  };

  handlePopupConfirm = (onConfirm: () => void) => {
    this.handleSubmit(onConfirm);
  };

  handleCheckbox = (value: boolean) => {
    this._isMounted &&
      this.setState({
        isChecked: value,
      });
  };

  handleCancelRedirect = () => {
    const { history } = this.props;
    history.push({
      pathname: userRoutes.list,
    });
  };

  handlePeepIcon = () => {
    const { formElements } = this.state;
    let tempFormElements = { ...formElements };

    if (tempFormElements.tempPassword.elementConfig.type === 'password') {
      tempFormElements = update(tempFormElements, {
        tempPassword: { elementConfig: { type: { $set: 'text' } } },
      });
    } else {
      tempFormElements = update(tempFormElements, {
        tempPassword: { elementConfig: { type: { $set: 'password' } } },
      });
    }

    this._isMounted && this.setState({ formElements: tempFormElements });
  };

  onOkayMapModal = () => {
    const { formElements, mapZoom } = this.state;

    this._isMounted &&
      this.setState((prevState) => {
        return {
          showMap: !prevState.showMap,
          initLat: formElements.latitude.value,
          initLng: formElements.longitude.value,
          initZoom: mapZoom,
          hasInputChanged: true,
        };
      });
  };

  handleMapModal = () => {
    const { formElements, initLat, initLng, initZoom } = this.state;
    const tempFormElements = update(formElements, {
      latitude: { value: { $set: initLat } },
      longitude: { value: { $set: initLng } },
    });

    this._isMounted &&
      this.setState((prevState) => {
        return {
          showMap: !prevState.showMap,
          formElements: tempFormElements,
          mapZoom: initZoom,
        };
      });
  };

  onMarkerMove = (lat: number, lng: number) => {
    const { formElements } = this.state;

    const tempFormElements = update(formElements, {
      latitude: { value: { $set: lat.toString() } },
      longitude: { value: { $set: lng.toString() } },
    });

    this._isMounted && this.setState({ formElements: tempFormElements });
  };

  onMapZoom = (zoom: number) => {
    this._isMounted && this.setState({ mapZoom: zoom });
  };

  setCurrentTabIndex = (index: string) => {
    this._isMounted && this.setState({ currentTabIndex: index });
  };

  getTabData = () => {
    const { userData } = this.props;
    const { formElements, currentUserDetails, isChecked, userID, allowEdit } =
      this.state;

    const inputElements = getInputElementsSingle({
      formElements,
      inputChangedHandler: this.inputChangedHandler,
      sliceValue: [1, 5],
      currentUserDetails,
      hasEditRights: allowEdit,
    });
    const inputElements2 = getInputElementsMultiple({
      formElements,
      inputChangedHandler: this.inputChangedHandler,
      sliceValue: [5, 7],
      currentUserDetails,
      hasEditRights: allowEdit,
      optionalElement: (
        <Fragment>
          {formElements.latitude.fieldRights &&
            currentUserDetails.rights?.includes(
              formElements.latitude.fieldRights
            ) && (
              <div className="col-xl-4 pb-3 pt-xl-2 pb-xl-0">
                <div className="pt-xl-1" />
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={this.handleMapModal}>
                  MAP
                </Button>
              </div>
            )}
        </Fragment>
      ),
    });
    const inputElements3 = getInputElementsSingle({
      formElements,
      inputChangedHandler: this.inputChangedHandler,
      sliceValue: [7, 9],
      currentUserDetails,
      hasEditRights: allowEdit,
    });

    const checkboxElem = (
      <div className="row align-items-center my-3" key={v4()}>
        <div className="col-12">
          <Checkbox
            disabled={!allowEdit}
            name={`newToken`}
            onChange={(event) => {
              this.handleCheckbox(event.target.checked);
            }}
            checked={isChecked}>
            {`New Token on Login`}
          </Checkbox>
        </div>
      </div>
    );

    const detailsElements = {
      inputElements,
      inputElements2,
      inputElements3,
      checkboxElem,
    };
    if (allowEdit) {
      if (Array.isArray(currentUserDetails.rights)) {
        const hasPermission = currentUserDetails.rights.includes(
          'EditUserAllocateTokens'
        );

        if (!hasPermission) {
          detailsElements.checkboxElem = <Fragment></Fragment>;
        }
      }
    }

    const groupElements = (
      <div className={cssStyles.groupsTabWrapper}>
        <GroupsTab
          userData={userData}
          userID={userID}
          partnerLevel={`${currentUserDetails.partnerid}_${formElements.userLevel.value}`}
          hasEditRights={allowEdit}
        />
      </div>
    );

    const apiKeyElements = (
      <ApiKeysTab
        userData={userData}
        userID={userID}
        hasEditRights={allowEdit}
      />
    );

    return { detailsElements, groupElements, apiKeyElements };
  };

  render() {
    const {
      formElements,
      showMap,
      mapZoom,
      loading,
      currentTabIndex,
      currentUserDetails,
      hasInputChanged,
      allowEdit,
      userID,
    } = this.state;
    const { userPermissionList } = this.props;

    if (!userID) {
      return <Redirect to={userRoutes.list} />;
    }

    if (userPermissionList.length === 0) {
      return <Redirect to="/" />;
    }

    const { detailsElements, groupElements, apiKeyElements } =
      this.getTabData();

    let disableGroupTab = true;
    if (
      currentUserDetails?.rights &&
      currentUserDetails.rights.length > 0 &&
      currentUserDetails.rights.includes(userRights.editUserGroups)
    ) {
      disableGroupTab = false;
    }

    return (
      <Fragment>
        <PromptPopup
          when={hasInputChanged}
          handleConfirm={this.handlePopupConfirm}
        />
        <FormWrapper
          loading={loading}
          title={allowEdit ? 'User Edit' : 'User View'}>
          <form noValidate onSubmit={this.onFormSubmit}>
            <Tabs
              activeKey={currentTabIndex}
              onTabClick={this.setCurrentTabIndex}>
              <Tabs.TabPane key="1" tab="Details">
                {detailsElements.inputElements}
                {detailsElements.inputElements2}
                {detailsElements.inputElements3}
                {detailsElements.checkboxElem}
              </Tabs.TabPane>
              <Tabs.TabPane key="2" disabled={disableGroupTab} tab="Groups">
                {groupElements}
              </Tabs.TabPane>
              <Tabs.TabPane key="3" tab="API Keys">
                {apiKeyElements}
              </Tabs.TabPane>
            </Tabs>

            <div className="row justify-content-center px-4">
              {currentTabIndex !== '2' && currentTabIndex !== '3' && (
                <Fragment>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4">
                    <Button type="primary" htmlType="submit" size="large" block>
                      Submit
                    </Button>
                  </div>
                </Fragment>
              )}

              {currentTabIndex !== '2' && currentTabIndex !== '3' && (
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 btn-mobile-padding-top">
                  <Button
                    type="primary"
                    htmlType="button"
                    size="large"
                    block
                    onClick={this.handleCancelRedirect}>
                    Cancel
                  </Button>
                </div>
              )}
            </div>
          </form>
        </FormWrapper>

        {showMap && (
          <Modal
            width={'60%'}
            visible={showMap}
            onCancel={this.handleMapModal}
            closable={false}
            onOk={this.onOkayMapModal}>
            <div className="row mt-4">
              <div className="col-md-12">
                <LeafletMap
                  onMapClickMarkerMove={this.onMarkerMove}
                  onMapZoom={this.onMapZoom}
                  lat={parseFloat(formElements.latitude.value)}
                  lng={parseFloat(formElements.longitude.value)}
                  zoom={mapZoom}
                  height={'50vh'}
                />
              </div>
            </div>
          </Modal>
        )}
      </Fragment>
    );
  }
}

const mapDispatch = {
  updateToken,
};

const connector = connect(null, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UserDetails);

function getInputElementsSingle({
  formElements,
  inputChangedHandler,
  sliceValue,
  currentUserDetails,
  hasEditRights,
}: {
  formElements: FormInputObjectType;
  inputChangedHandler: (name: any, value: any) => void;
  sliceValue: number[];
  currentUserDetails: Partial<UserDetailsType>;
  hasEditRights: boolean;
}) {
  let formElementsArray = [];
  for (const key in formElements) {
    formElementsArray.push({
      id: key,
      config: formElements[key],
    });
  }
  formElementsArray = formElementsArray.slice(...sliceValue);

  const inputElements = formElementsArray.map((item) => {
    if (hasEditRights) {
      if (Array.isArray(currentUserDetails.rights) && item.config.fieldRights) {
        const hasPermission = currentUserDetails.rights.includes(
          item.config.fieldRights
        );

        if (!hasPermission) {
          if (item.id === 'userName') {
            item.config.disabled = true;
          } else {
            return null;
          }
        }
      }
    }

    return (
      <Fragment key={item.id}>
        <div className="row">
          <div className="col-xl-12">
            <AntdInput {...item.config} onInputChanged={inputChangedHandler} />
          </div>
        </div>
      </Fragment>
    );
  });

  return inputElements;
}

function getInputElementsMultiple({
  formElements,
  inputChangedHandler,
  sliceValue,
  currentUserDetails,
  hasEditRights,
  optionalElement,
}: {
  formElements: FormInputObjectType;
  inputChangedHandler: (name: any, value: any) => void;
  sliceValue: number[];
  currentUserDetails: Partial<UserDetailsType>;
  hasEditRights: boolean;
  optionalElement?: ReactNode;
}) {
  let formElementsArray = [];

  for (const key in formElements) {
    formElementsArray.push({
      id: key,
      config: formElements[key],
    });
  }
  formElementsArray = formElementsArray.slice(...sliceValue);
  const inputElements = (
    <Fragment>
      <div className="row align-items-center">
        {formElementsArray.map((item) => {
          if (hasEditRights) {
            if (
              Array.isArray(currentUserDetails.rights) &&
              item.config.fieldRights
            ) {
              const hasPermission = currentUserDetails.rights.includes(
                item.config.fieldRights
              );

              if (!hasPermission) {
                if (item.id === 'userName') {
                  item.config.disabled = true;
                } else {
                  return null;
                }
              }
            }
          }

          return (
            <Fragment key={item.id}>
              <div className={`col-xl-${item.config.colValue}`}>
                <AntdInput
                  {...item.config}
                  onInputChanged={inputChangedHandler}
                />
              </div>
            </Fragment>
          );
        })}
        {optionalElement || null}
      </div>
    </Fragment>
  );

  return inputElements;
}
