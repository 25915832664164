import { FC, Fragment, memo, ReactNode } from 'react';
import { Spin } from 'antd';
import { SpinProps } from 'antd/lib/spin';

interface PropsType extends SpinProps {
  active?: boolean;
  children?: ReactNode;
}

const AntdCoverSpinner: FC<PropsType> = ({ active, children, size }) => {
  return (
    <Fragment>
      {active ? <Spin size={size || 'large'}>{children}</Spin> : children}
    </Fragment>
  );
};

export default memo(AntdCoverSpinner);
