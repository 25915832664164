import { Route, Redirect } from 'react-router-dom';
import AppLayout from '../components-shared/AppLayout';
import LoadingPage from '../components-shared/LoadingPage';
import { UserDataType, UserRightListType } from '../type-definitions/api-types';
import { getFromLocal } from '../utils/manage-storage';
import { authRoutes } from './routes-list';

type PropsType = {
  component: any;
  isAuth?: boolean;
  path: string;
  userData: Partial<UserDataType>;
  userPermissionList: string[];
  userRightList: UserRightListType[];
};

function PrivateRoute({
  component: Component,
  isAuth,
  path,
  ...rest
}: PropsType) {
  const userToken = getFromLocal('token');

  if (!userToken) {
    return (
      <Redirect
        to={{
          pathname: authRoutes.login,
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      exact
      path={path}
      render={(props) =>
        isAuth ? (
          <AppLayout>
            <Component {...props} {...rest} />
          </AppLayout>
        ) : (
          <LoadingPage />
        )
      }
    />
  );
}

export default PrivateRoute;
