import { Button, Col, Row } from 'antd';
import { Fragment, ReactNode } from 'react';
import AntdInput from '../../AntdInput';
import { FormElementsType } from '../helpers';

interface PropsType {
  formElements: FormElementsType;
  inputChangedHandler: (name: any, value: any) => void;
  dynamicInputElements: ReactNode;
  handleCancelRedirect: () => void;
  isEditAllowed: boolean;
}

function DetailsTab({
  formElements,
  inputChangedHandler,
  dynamicInputElements,
  handleCancelRedirect,
  isEditAllowed,
}: PropsType) {
  return (
    <Fragment>
      <Row gutter={[{ md: 12 }, 0]}>
        <Col xs={24} md={12}>
          <AntdInput
            {...formElements.deviceMac}
            onInputChanged={inputChangedHandler}
          />
        </Col>
        <Col xs={24} md={12}>
          <AntdInput
            {...formElements.serialNumber}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>

      <Row gutter={[{ md: 12 }, 0]}>
        <Col xs={24}>
          <AntdInput
            {...formElements.partnerName}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>

      <Row gutter={[{ md: 12 }, 0]}>
        <Col xs={24} md={12}>
          <AntdInput
            {...formElements.manufacturer}
            onInputChanged={inputChangedHandler}
          />
        </Col>
        <Col xs={24} md={12}>
          <AntdInput
            {...formElements.model}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>

      <Row gutter={[{ md: 12 }, 0]}>
        <Col xs={24}>
          <AntdInput
            {...formElements.sensors}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>

      {dynamicInputElements}

      <Row justify="center" gutter={[{ md: 12 }, { xs: 12 }]}>
        {isEditAllowed && (
          <Col xs={24} sm={12} md={8}>
            <Button type="primary" htmlType="submit" size="large" block>
              Submit
            </Button>
          </Col>
        )}

        <Col xs={24} sm={12} md={8}>
          <Button
            type="primary"
            htmlType="button"
            size="large"
            block
            onClick={handleCancelRedirect}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
}

export default DetailsTab;
