import { Col, message, Row, Tabs } from 'antd';
import axios from 'axios';
import { Fragment, Component, FormEvent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import update from 'immutability-helper';

import { apiCall } from '../api-services/api';
import { partnerApi } from '../api-services/api-list';
import FormWrapper from '../components-shared/FormWrapper';
import DetailsTab from '../components/PartnerDetails/DetailsTab';
import {
  detailsTabInitElements,
  StateType,
  DetailsTabFormElementsType,
} from '../components/PartnerDetails/helpers';
import { updateToken } from '../redux/actions';
import {
  PartnerDetailsType,
  UserDataType,
} from '../type-definitions/api-types';
import { handleNotification } from '../utils/notification-handler';
import { checkValidation } from '../utils/validation';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Modal from 'antd/lib/modal/Modal';
import LeafletMap from '../components-shared/LeafletMap';
import { partnerRoutes } from '../Routes/routes-list';
import PromptPopup from '../components/PromptPopup';
import RolesTab from '../components/PartnerDetails/RolesTab';

type PropsType = RouteComponentProps<
  {},
  any,
  { partnerID: string; allowEdit: boolean }
> &
  PropsFromRedux & {
    userData: Partial<UserDataType>;
    userPermissionList: string[];
  };

class PartnerDetails extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    const { location } = props;
    this.state = {
      loading: true,
      loadingButton: false,
      currentTabIndex: '1',
      detailsTabFormElements: { ...detailsTabInitElements },
      mapZoom: 10,
      showMap: false,
      initLat: '0',
      initLng: '0',
      initZoom: 10,
      hasInputChanged: false,

      allowEdit: location?.state?.allowEdit ?? false,
      partnerID: location?.state?.partnerID ?? '',
    };
  }

  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    this._isMounted = true;

    this.handleFetchedData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  handleState = (data: Partial<StateType>, callback?: () => void) => {
    this._isMounted &&
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            ...data,
          };
        },
        () => {
          callback?.();
        }
      );
  };

  handleFetchedData = async () => {
    const { userData } = this.props;
    const { detailsTabFormElements, allowEdit, partnerID } = this.state;

    let tempFormElements = { ...detailsTabFormElements };

    if (partnerID) {
      try {
        const { url, method, contentType } = partnerApi.getPartnerDetails(
          undefined,
          { partnerID }
        );

        const response = await apiCall({
          storeToken: userData?.token,
          url,
          method,
          contentType,
          cancelToken: this.axiosCancelSource.token,
        });

        const result = response?.data;

        if (result?.status === 'ok') {
          if (result.data) {
            const tempPartnerDetails: PartnerDetailsType = result.data;

            tempFormElements = update(tempFormElements, {
              partnerID: { value: { $set: tempPartnerDetails.partnerid } },
              partnerName: {
                value: { $set: tempPartnerDetails.partnername },
                disabled: { $set: !allowEdit },
              },
              latitude: {
                value: { $set: tempPartnerDetails.lat.toString() },
                disabled: { $set: !allowEdit },
              },
              longitude: {
                value: { $set: tempPartnerDetails.lng.toString() },
                disabled: { $set: !allowEdit },
              },
              prefix: {
                value: { $set: tempPartnerDetails.prefix.toString() },
                disabled: { $set: !allowEdit },
              },
              childrenAllowed: { $set: tempPartnerDetails.childrenAllowed },
            });
          }
        } else {
          this._isMounted && handleNotification('error', result);
        }
      } catch (error) {
        this._isMounted && handleNotification('error', error.data);
      }
    }

    this.handleState({
      loading: false,
      detailsTabFormElements: { ...tempFormElements },
    });
  };

  inputChangedHandler = (
    name: keyof DetailsTabFormElementsType,
    value: string
  ) => {
    const { detailsTabFormElements } = this.state;

    let tempFormElements = { ...detailsTabFormElements };

    if (
      value &&
      (name === 'latitude' || name === 'longitude') &&
      !checkValidation(value, { isNegativeFloat: true })
    ) {
      message.error('Please enter valid Coordinates');
      return;
    }

    if (name && name !== 'childrenAllowed') {
      tempFormElements = update(tempFormElements, {
        [name]: {
          value: { $set: value },
          touched: { $set: true },
          valid: {
            $set: checkValidation(value, tempFormElements[name].validation),
          },
        },
      });

      this.handleState({
        detailsTabFormElements: { ...tempFormElements },
        hasInputChanged: true,
      });
    }
  };

  handleCheckbox = (event: CheckboxChangeEvent) => {
    const name = event?.target?.name;
    const checked = event?.target?.checked;

    const { detailsTabFormElements } = this.state;

    let tempFormElements = { ...detailsTabFormElements };

    if (name === 'childrenAllowed') {
      tempFormElements = update(tempFormElements, {
        [name]: {
          $set: checked,
        },
      });

      this.handleState({
        detailsTabFormElements: { ...tempFormElements },
        hasInputChanged: true,
      });
    }
  };

  onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    this.handleSubmit();
  };

  handleSubmit = async (onConfirm?: () => void) => {
    const { detailsTabFormElements, partnerID } = this.state;
    const { userData } = this.props;

    let tempFormElements = { ...detailsTabFormElements };

    let key: keyof typeof tempFormElements;

    for (key in tempFormElements) {
      if (key !== 'childrenAllowed') {
        tempFormElements = update(tempFormElements, {
          [key]: {
            touched: { $set: true },
            valid: {
              $set: checkValidation(
                tempFormElements[key].value,
                tempFormElements[key].validation
              ),
            },
          },
        });
      }
    }

    this.handleState({ detailsTabFormElements: tempFormElements });

    for (key in tempFormElements) {
      if (key !== 'childrenAllowed' && !tempFormElements[key].valid) {
        message.error('Please fill all the fields');
        return;
      }
    }

    this.handleState({ loadingButton: true });

    try {
      const { url, method, contentType } = partnerApi.putPartnerDetails(
        undefined,
        { partnerID }
      );

      const data: Partial<PartnerDetailsType> = {
        partnerid: tempFormElements.partnerID.value,
        partnername: tempFormElements.partnerName.value,
        lat: Number(tempFormElements.latitude.value),
        lng: Number(tempFormElements.longitude.value),
        prefix: tempFormElements.prefix.value,
        childrenAllowed: tempFormElements.childrenAllowed,
      };

      const response = await apiCall({
        storeToken: userData?.token,
        url,
        method,
        contentType,
        data,
        cancelToken: this.axiosCancelSource.token,
      });

      const result = response?.data;

      if (result?.status === 'ok') {
        this._isMounted && handleNotification('success', result);
        this.handleState({ loadingButton: false, hasInputChanged: false }, () =>
          onConfirm?.()
        );
      } else {
        this._isMounted && handleNotification('error', result);
        this.handleState({ loadingButton: false });
      }
    } catch (error) {
      this._isMounted && handleNotification('error', error.data);
      this.handleState({ loadingButton: false });
    }
  };

  handlePopupConfirm = (onConfirm: () => void) => {
    this.handleSubmit(onConfirm);
  };

  setCurrentTabIndex = (index: string) => {
    this.handleState({ currentTabIndex: index });
  };

  onOpenMapModal = () => {
    this.handleState({ showMap: true });
  };

  onCancelMapModal = () => {
    const { detailsTabFormElements, initLat, initLng, initZoom } = this.state;

    const tempFormElements = update(detailsTabFormElements, {
      latitude: { value: { $set: initLat } },
      longitude: { value: { $set: initLng } },
    });

    this.handleState({
      showMap: false,
      detailsTabFormElements: { ...tempFormElements },
      mapZoom: initZoom,
    });
  };

  onOkayMapModal = () => {
    const { detailsTabFormElements, mapZoom } = this.state;

    this.handleState({
      showMap: false,
      initLat: detailsTabFormElements.latitude.value,
      initLng: detailsTabFormElements.longitude.value,
      initZoom: mapZoom,
    });
  };

  onMapZoom = (zoom: number) => {
    this.handleState({ mapZoom: zoom });
  };

  onMapClickMarkerMove = (lat: number, lng: number) => {
    const { detailsTabFormElements } = this.state;

    const tempFormElements = update(detailsTabFormElements, {
      latitude: { value: { $set: lat.toString() } },
      longitude: { value: { $set: lng.toString() } },
    });

    this.handleState({
      detailsTabFormElements: tempFormElements,
      hasInputChanged: true,
    });
  };

  handleCancelRedirect = () => {
    const { history } = this.props;
    history.push({
      pathname: partnerRoutes.list,
    });
  };

  render() {
    const {
      loading,
      currentTabIndex,
      detailsTabFormElements,
      showMap,
      mapZoom,
      loadingButton,
      hasInputChanged,
      allowEdit,
      partnerID,
    } = this.state;
    const { userPermissionList } = this.props;

    if (!partnerID) {
      return <Redirect to={partnerRoutes.list} />;
    }

    if (userPermissionList.length === 0) {
      return <Redirect to="/" />;
    }

    return (
      <Fragment>
        {hasInputChanged && (
          <PromptPopup
            when={hasInputChanged}
            handleConfirm={this.handlePopupConfirm}
          />
        )}
        <FormWrapper
          loading={loading}
          title={allowEdit ? `Partner Edit` : `Partner View`}>
          <form noValidate onSubmit={this.onFormSubmit}>
            <Tabs
              activeKey={currentTabIndex}
              onTabClick={this.setCurrentTabIndex}>
              <Tabs.TabPane key="1" tab="Details">
                <DetailsTab
                  formElements={detailsTabFormElements}
                  inputChangedHandler={this.inputChangedHandler}
                  handleCheckbox={this.handleCheckbox}
                  allowEdit={allowEdit}
                  onOpenMapModal={this.onOpenMapModal}
                  handleCancelRedirect={this.handleCancelRedirect}
                  loadingButton={loadingButton}
                />
              </Tabs.TabPane>
              <Tabs.TabPane key="2" tab="Roles">
                <RolesTab partnerID={partnerID} />
              </Tabs.TabPane>
            </Tabs>
          </form>
        </FormWrapper>

        {showMap && (
          <Modal
            width={'60%'}
            visible={showMap}
            onCancel={this.onCancelMapModal}
            closable={false}
            onOk={this.onOkayMapModal}>
            <Row>
              <Col xs={24}>
                <LeafletMap
                  onMapClickMarkerMove={this.onMapClickMarkerMove}
                  onMapZoom={this.onMapZoom}
                  lat={parseFloat(detailsTabFormElements.latitude.value)}
                  lng={parseFloat(detailsTabFormElements.longitude.value)}
                  zoom={mapZoom}
                  height={'50vh'}
                />
              </Col>
            </Row>
          </Modal>
        )}
      </Fragment>
    );
  }
}

const mapDispatch = {
  updateToken: updateToken,
};

const connector = connect(null, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PartnerDetails);
