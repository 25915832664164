import { Fragment, useEffect } from 'react';
import { notification } from 'antd';
import { ApiErrorType, ApiSuccessType } from '../../type-definitions/api-types';

type PropsType = {
  success?: ApiSuccessType;
  error?: ApiErrorType;
  obj: { _isMounted?: boolean; setState: any };
  onErrorClose?: () => void;
  onSuccessClose?: () => void;
};

function NotificationHandler({
  success,
  error,
  obj,
  onErrorClose,
  onSuccessClose,
}: PropsType) {
  useEffect(() => {
    if (success?.status === 'ok') {
      setTimeout(() => {
        obj?._isMounted &&
          obj?.setState((prevState: object) => ({
            ...prevState,
            success: {},
          }));
      }, 1000);

      notification.success({
        message: `Success`,
        description: success.message,
        duration: 1.5,
        onClose: onSuccessClose,
      });
    }

    if (error?.status === 'error') {
      setTimeout(() => {
        obj?._isMounted &&
          obj?.setState((prevState: object) => ({
            ...prevState,
            error: {},
          }));
      }, 1000);

      notification.error({
        message: `Error`,
        description: error.message,
        duration: 0,
        onClose: onErrorClose,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, success]);

  return <Fragment></Fragment>;
}

export default NotificationHandler;
