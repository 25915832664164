import { Button, Col, Row } from 'antd';
import { Fragment } from 'react';
import Leaflet from 'leaflet';
import {
  FloorPlanLocationListType,
  LocationListType,
} from '../../../type-definitions/api-types';
import FloorPlanMapMultiple from '../FloorPlanMapMultiple';
import { FormElementsType } from '../helpers';

import cssStyles from '../styles/floorPlanDetails.module.scss';
import AntdTable from '../../AntdTable';
import {
  AntdTableColumnsType,
  MapDetailsType,
} from '../../../type-definitions';

function LocationsTab({
  mapDetails,
  floorPlanLocationList,
  selectedLocation,
  onUpdateLocation,
  onRemoveLocation,
  handleAddLocationModal,
  setMapDefaultParameters,
  isEditable,
  onFloorPlanLocationSelect,
  selectedFloorPlanFromTable,
  updateFloorPlansInMap,
  onAddRegionBtnClicked,
  addRegionClickedLocId,
  floorPlanId,
  token,
}: {
  formElements: FormElementsType;
  inputChangedHandler: (name: any, value: any) => void;
  mapDetails: MapDetailsType;
  floorPlanLocationList: FloorPlanLocationListType[];
  selectedLocation: LocationListType | {};
  onUpdateLocation: (data: FloorPlanLocationListType) => void;
  onRemoveLocation: (data: FloorPlanLocationListType) => void;
  handleAddLocationModal: () => void;
  setMapDefaultParameters: (
    position: Leaflet.LatLng,
    coordinates: { x: number; y: number }
  ) => void;
  isEditable: boolean;
  onFloorPlanLocationSelect: (data: FloorPlanLocationListType) => void;
  selectedFloorPlanFromTable: Partial<FloorPlanLocationListType>;
  updateFloorPlansInMap: boolean;
  onAddRegionBtnClicked: (id: string) => void;
  addRegionClickedLocId: string;
  floorPlanId: string;
  token: string;
}) {
  return (
    <Fragment>
      <Row justify="center" className="py-3">
        <Col sm={12} lg={16} className="text-center">
          <Button
            onClick={handleAddLocationModal}
            type="primary"
            size="middle"
            disabled={!isEditable}>
            Add Location
          </Button>
        </Col>
      </Row>

      <Row justify="center">
        <Col xs={24} lg={12}>
          <AntdTable
            dataSource={floorPlanLocationList}
            columns={getColumns({
              onAddRegionBtnClicked,
              addRegionClickedLocId,
            })}
            customConfig={{
              rowKeyValue: 'locationID',
              onRowClick: onFloorPlanLocationSelect,
            }}
            pagination={{
              pageSize: 5,
              total: floorPlanLocationList.length,
            }}
          />
        </Col>
        <Col xs={24} lg={12} className="pl-lg-3 pt-3 pt-lg-0">
          <FloorPlanMapMultiple
            mapDetails={mapDetails}
            // updateLocation={updateLocation}
            floorPlanLocationList={floorPlanLocationList}
            selectedLocation={selectedLocation}
            // removeLocation={removeLocation}
            classes={cssStyles.floorPlanMap}
            onUpdateLocation={onUpdateLocation}
            onRemoveLocation={onRemoveLocation}
            setMapDefaultParameters={setMapDefaultParameters}
            isEditable={isEditable}
            selectedFloorPlanFromTable={selectedFloorPlanFromTable}
            updateFloorPlansInMap={updateFloorPlansInMap}
            addRegionClickedLocId={addRegionClickedLocId}
            onAddRegionBtnClicked={onAddRegionBtnClicked}
            floorPlanId={floorPlanId}
            token={token}
          />
        </Col>
      </Row>
    </Fragment>
  );
}

export default LocationsTab;

function getColumns({
  onAddRegionBtnClicked,
  addRegionClickedLocId,
}: {
  onAddRegionBtnClicked: (id: string) => void;
  addRegionClickedLocId: string;
}): AntdTableColumnsType<FloorPlanLocationListType>[] {
  return [
    {
      title: 'Location ID',
      key: 'locationID',
      dataIndex: 'locationID',
      render: (text: string, record: FloorPlanLocationListType) => {
        if (
          record.isClickedFromTable === true ||
          addRegionClickedLocId === record.locationID
        ) {
          return {
            props: {
              style: { background: `#D3D3D3` },
            },
            children: <Fragment>{text}</Fragment>,
          };
        }
        return {
          children: <Fragment>{text}</Fragment>,
        };
      },
    },
    {
      title: 'Location Name',
      key: 'locationName',
      dataIndex: 'locationName',
      render: (text: string, record: FloorPlanLocationListType) => {
        if (
          record.isClickedFromTable === true ||
          addRegionClickedLocId === record.locationID
        ) {
          return {
            props: {
              style: { background: `#D3D3D3` },
            },
            children: <Fragment>{text}</Fragment>,
          };
        }
        return {
          children: <Fragment>{text}</Fragment>,
        };
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, record: FloorPlanLocationListType) => {
        if (
          record.isClickedFromTable === true ||
          addRegionClickedLocId === record.locationID
        ) {
          return {
            props: {
              style: { background: `#D3D3D3` },
            },
            children: (
              <Fragment>
                <Row>
                  <Col>
                    <Button
                      type="primary"
                      htmlType="button"
                      size="small"
                      onClick={(event) => {
                        event?.stopPropagation?.();
                        onAddRegionBtnClicked(record.locationID);
                      }}>
                      Add Region
                    </Button>
                  </Col>
                </Row>
              </Fragment>
            ),
          };
        }
        return {
          children: (
            <Fragment>
              <Row>
                <Col>
                  <Button
                    type="primary"
                    htmlType="button"
                    size="small"
                    onClick={(event) => {
                      event?.stopPropagation?.();
                      onAddRegionBtnClicked(record.locationID);
                    }}>
                    Add Region
                  </Button>
                </Col>
              </Row>
            </Fragment>
          ),
        };
      },
    },
  ];
}
