import { Fragment, createRef, Component, ReactNode } from 'react';
import { MapContainer, Marker, ImageOverlay } from 'react-leaflet';
import Leaflet from 'leaflet';
import update from 'immutability-helper';

import 'leaflet/dist/leaflet.css';
import { MapDetailsType } from '../../../../type-definitions';
import { FloorPlanLocationListType } from '../../../../type-definitions/api-types';
import {
  getBoundsUnproject,
  getLeafletIcon,
  getMapPoint,
} from '../../../../utils/leaflet-helpers';

interface PropsType {
  mapDetails: MapDetailsType;
  floorPlanLocationList: FloorPlanLocationListType[];
  classes?: string;
  isEditable: boolean;
  currentlyEditing?: {
    locationID?: string;
    deviceID?: string;
    status?: string;
  };
  mapPopup?: (params: {
    isEditable?: boolean;
    deviceID?: string;
    status?: string;
    locationID?: string;
  }) => ReactNode;
  onMapClick?: () => void;

  mapMarkerRender?: (params: {
    record: FloorPlanLocationListType;
    mapPopup?: (params: {
      isEditable?: boolean;
      deviceID?: string;
      status?: string;
      locationID?: string;
    }) => ReactNode;
  }) => ReactNode;

  onUpdateLocation: (record: FloorPlanLocationListType) => void;
}

interface StateType {
  currentPosition: Partial<Leaflet.LatLng>;
  mapBounds?: Leaflet.LatLngBounds;
  floorPlanLocations: FloorPlanLocationListType[];
  mapInstance?: Leaflet.Map;
}

const customCRS = (Leaflet as any).extend({}, Leaflet.CRS, {
  projection: Leaflet.Projection.LonLat,
  transformation: new Leaflet.Transformation(1, 0, 1, 0),
});

const unprojectZoom = 4;

export default class FloorPlanMapMultiple extends Component<
  PropsType,
  StateType
> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      currentPosition: {},
      mapBounds: undefined,
      floorPlanLocations: [],
      mapInstance: undefined,
    };
  }

  markerRef = createRef<Leaflet.Marker<any>>();
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps: PropsType, prevState: StateType) {
    const { floorPlanLocationList, currentlyEditing } = this.props;
    const { mapInstance } = this.state;

    if (
      floorPlanLocationList !== prevProps.floorPlanLocationList &&
      !currentlyEditing?.locationID &&
      mapInstance
    ) {
      this.handleMap(mapInstance);
    }
  }

  handleState = (data: Partial<StateType>) => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          ...prevState,
          ...data,
        };
      });
  };

  handleMap = (map: Leaflet.Map) => {
    const { mapDetails, floorPlanLocationList, onMapClick } = this.props;

    if (mapDetails.height && mapDetails.width) {
      const bounds = getBoundsUnproject(
        map,
        mapDetails.height,
        mapDetails.width,
        unprojectZoom
      );

      if (bounds) {
        map.fitBounds(bounds);
      }

      let tempFloorPlanLocationList: FloorPlanLocationListType[] = [];

      floorPlanLocationList.forEach((item) => {
        const point = getMapPoint({
          x: item.x,
          y: item.y,
        });
        const position = map.unproject(point, unprojectZoom);
        const tempObj = update(item, { position: { $set: position } });
        tempFloorPlanLocationList = update(tempFloorPlanLocationList, {
          $push: [tempObj],
        });
      });

      this._isMounted &&
        this.setState({
          floorPlanLocations: tempFloorPlanLocationList,
          mapBounds: bounds,
          mapInstance: map,
        });
    }

    map.on('click', (event) => {
      onMapClick?.();
    });
  };

  onMarkerDragEnd = (
    event: Leaflet.DragEndEvent,
    record: FloorPlanLocationListType
  ) => {
    const { mapInstance } = this.state;
    const { onUpdateLocation } = this.props;

    const tempLatLng = event?.target?.getLatLng?.();

    const position = {
      lat: tempLatLng.lat,
      lng: tempLatLng.lng,
    };

    if (mapInstance) {
      const coordinates = mapInstance?.project(tempLatLng, unprojectZoom);

      record = update(record, {
        position: { lat: { $set: position.lat }, lng: { $set: position.lng } },
        x: { $set: coordinates.x },
        y: { $set: coordinates.y },
      });

      onUpdateLocation(record);
    }
  };

  render() {
    const {
      mapDetails,
      classes,
      isEditable,
      mapPopup,
      mapMarkerRender,
      currentlyEditing,
    } = this.props;
    const { mapBounds, currentPosition, floorPlanLocations } = this.state;

    const mapImage = `data:image/svg+xml,${encodeURIComponent(
      mapDetails.image || ''
    )}`;

    const center = Leaflet.latLng(
      currentPosition.lat || 0,
      currentPosition.lng || 0
    );

    return (
      <Fragment>
        <MapContainer
          tap={false}
          className={classes}
          whenCreated={this.handleMap}
          center={center}
          crs={customCRS}
          zoom={unprojectZoom}>
          {mapDetails.image && mapBounds && (
            <ImageOverlay bounds={mapBounds} url={mapImage} />
          )}
          {floorPlanLocations.length > 0 &&
            floorPlanLocations.map((item) => {
              if (item.position) {
                if (mapMarkerRender) {
                  return mapMarkerRender({ record: item, mapPopup: mapPopup });
                }

                return (
                  <Fragment key={item.locationID}>
                    <Marker
                      key={item.locationID}
                      title={item.locationID}
                      icon={getLeafletIcon(
                        currentlyEditing?.locationID === item.locationID
                          ? 'red'
                          : 'blue'
                      )}
                      draggable={
                        currentlyEditing?.locationID === item.locationID
                      }
                      position={[item.position?.lat, item.position?.lng]}
                      ref={this.markerRef}
                      eventHandlers={{
                        dragend: (event) => this.onMarkerDragEnd(event, item),
                      }}>
                      {mapPopup?.({
                        isEditable,
                        deviceID: item.deviceID,
                        status: item.status,
                        locationID: item.locationID,
                      })}
                    </Marker>
                  </Fragment>
                );
              }
              return null;
            })}
        </MapContainer>
      </Fragment>
    );
  }
}
