import { Fragment } from 'react';
import { Row, Col } from 'antd';

import { FormElementsType } from '../helpers';
import AntdInput from '../../AntdInput';

function AddressTab({
  formElements,
  inputChangedHandler,
}: {
  formElements: FormElementsType;
  inputChangedHandler: (name: any, value: any) => void;
}) {
  return (
    <Fragment>
      <Row justify="center" align="middle">
        <Col xs={24}>
          <AntdInput
            {...formElements.street}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>

      <Row justify="center" align="middle">
        <Col xs={24}>
          <AntdInput
            {...formElements.street2}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>

      <Row justify="center" align="middle">
        <Col xs={24}>
          <AntdInput
            {...formElements.city}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>

      <Row justify="center" align="middle">
        <Col xs={24}>
          <AntdInput
            {...formElements.province}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>

      <Row justify="center" align="middle">
        <Col xs={24}>
          <AntdInput
            {...formElements.postalCode}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>

      <Row justify="center" align="middle">
        <Col xs={24}>
          <AntdInput
            {...formElements.country}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>
    </Fragment>
  );
}

export default AddressTab;
