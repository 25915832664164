import {
  LOGOUT_REQUESTED,
  TOKEN_REQUESTED,
  USER_RIGHT_REQUESTED,
  AUTH_SUCCESS,
} from '../actionTypes/auth.types';

export function authLogout() {
  return {
    type: LOGOUT_REQUESTED,
  };
}

export function getUserByToken() {
  return {
    type: TOKEN_REQUESTED,
  };
}

export function getUserRights() {
  return {
    type: USER_RIGHT_REQUESTED,
  };
}

export function authSuccess(data = {}) {
  return {
    type: AUTH_SUCCESS,
    payload: {
      ...data,
    },
  };
}
