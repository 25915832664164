import {
  ERRORED,
  UPDATE_TOKEN,
  SET_USER_RIGHTS,
  SET_USER_RIGHTS_REQUESTED,
  UPDATE_TOKEN_REQUESTED,
  CLEAR_PAGINATION_START,
  CLEAR_PAGINATION_DONE,
} from '../actionTypes/shared.types';
import {
  setInLocal,
  getFromLocal,
  getFromSession,
  setInSession,
} from '../../utils/manage-storage';
import { handleUserPermssion } from '../../utils';
import { all, takeEvery, select, put, takeLatest } from '../../utils/saga-libs';
import { ReduxActionPayloadType, ReduxStateType } from '../../type-definitions';
import {
  ApiErrorType,
  ApiResultType,
  UserDataType,
  UserRightListType,
} from '../../type-definitions/api-types';
import { browserStorageKey } from '../../utils/constants';

const token = getFromLocal('token');

export default function* watcherSaga() {
  yield all([
    takeLatest(SET_USER_RIGHTS_REQUESTED, setUserRightsSaga),
    takeEvery(UPDATE_TOKEN_REQUESTED, updateTokenSaga),
    takeEvery(CLEAR_PAGINATION_START, clearPaginationSaga),
  ]);
}

export function* updateTokenSaga(result: Partial<ApiResultType>) {
  if (
    result &&
    result?.status === 'error' &&
    result?.message === 'Token not found'
  ) {
    const error: ApiErrorType = yield select(
      (state: ReduxStateType) => state.auth.error
    );
    setInLocal('token', '');
    if (!error.status) {
      yield put({ type: ERRORED, payload: result, invalidToken: true });
    }
  } else if (
    result &&
    token &&
    result?.status !== 'error' &&
    result?.token &&
    result?.token.toString() !== token.toString()
  ) {
    setInLocal('token', result.token);
    yield put({ type: UPDATE_TOKEN, payload: result.token });
  }
}

const getUserRightList = (state: ReduxStateType) => state.auth.userRightList;
const getUserData = (state: ReduxStateType) => state.auth.userData;

function* setUserRightsSaga() {
  const userRightList: UserRightListType[] = yield select(getUserRightList);
  const userData: UserDataType = yield select(getUserData);
  let userPermission = [];
  if (
    userData &&
    userRightList &&
    userRightList.length > 0 &&
    Object.keys(userData).length > 0
  ) {
    // userPermission = handleUserPermssion(userRightList, userData.partnerID);
    userPermission = handleUserPermssion(userRightList);
    yield put({ type: SET_USER_RIGHTS, payload: { userPermission } });
  }
}

function* clearPaginationSaga({
  payload,
}: {
  payload: { paginationKey: string };
}) {
  const { paginationKey } = payload;
  const paginationStore: { [k: string]: number } = yield select(
    (state: ReduxStateType) => state.shared.paginationDetails
  );

  const pagination = getFromSession(browserStorageKey.shared.pagination);
  for (const el in pagination) {
    if (el !== paginationKey) {
      paginationStore[el] = 1;
    }
  }

  setInSession(browserStorageKey.shared.pagination, paginationStore);
  const actionPayload: ReduxActionPayloadType = {
    paginationData: paginationStore,
  };
  yield put({
    type: CLEAR_PAGINATION_DONE,
    payload: actionPayload,
  });
}
