import axios from 'axios';
import { Component, FormEvent, Fragment } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import moment from 'moment';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { Button, Modal, Tabs } from 'antd';
import update from 'immutability-helper';

import {
  FormElementsType,
  initialElements,
  StateType,
} from '../components/GroupDetails/helpers';
import { updateToken } from '../redux/actions';
import {
  ApiErrorType,
  ApiResultType,
  GroupDetailsType,
  UserDataType,
} from '../type-definitions/api-types';
import { apiCall } from '../api-services/api';
import { groupApi, partnerApi } from '../api-services/api-list';
import { getUtcTime } from '../utils';
import { checkValidation } from '../utils/validation';
import { message } from 'antd';
import { groupRoutes } from '../Routes/routes-list';
import FormWrapper from '../components/FormWrapper';
import CheckboxListModal from '../components/CheckboxListModal';
import { handleNotification } from '../utils/notification-handler';
import DetailsTab from '../components/GroupDetails/DetailsTab';
import UsersTab from '../components/GroupDetails/UsersTab';
import LocationsTab from '../components/GroupDetails/LocationsTab';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import PromptPopup from '../components/PromptPopup';

interface PropsType
  extends RouteComponentProps<{}, any, { groupID: string; allowEdit: boolean }>,
    PropsFromRedux {
  userData: Partial<UserDataType>;
  userPermissionList: string[];
}

class GroupDetails extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    const { location } = props;
    this.state = {
      formElements: update(initialElements, {
        phenomList: {
          onInputClick: { $set: this.handlePhenomModal },
          styles: { $set: { cursor: 'pointer' } },
        },
        extendedPermission: {
          onInputClick: { $set: this.handleExtendedPermissionModal },
          styles: { $set: { cursor: 'pointer' } },
        },
        deviceManagementPermission: {
          onInputClick: { $set: this.handleDeviceManagementPermissionModal },
          styles: { $set: { cursor: 'pointer' } },
        },
      }),
      loading: true,
      showExtendedPermissionModal: false,
      initExtendedPermissionCollection: {},
      extendedPermissionCollection: {},
      showPhenomModal: false,
      initPhenomCollection: {},
      phenomCollection: {},
      showDeviceManagementPermissionModal: false,
      initDeviceManagementCollection: {},
      deviceManagementCollection: {},
      currentTabIndex: '1',
      hasInputChanged: false,

      allowEdit: location?.state?.allowEdit ?? false,
      groupID: location?.state?.groupID ?? false,
    };
  }

  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    this._isMounted = true;
    const { groupID } = this.state;

    if (groupID) {
      this.handleFetchedData({ groupID });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  handleState = (data: Partial<StateType>) => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          ...prevState,
          ...data,
        };
      });
  };

  fetchData = async ({ groupID }: { groupID: string }) => {
    const { userData, updateToken } = this.props;
    try {
      const { url, method, contentType } = groupApi.getGroupDetails(undefined, {
        groupID,
      });
      const response = await apiCall({
        storeToken: userData?.token,
        url,
        method,
        contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result: ApiResultType = response?.data;
      updateToken(result);
      if (result?.status === 'ok' && result.data) {
        return { groupDetails: result.data };
      } else {
        return { error: result };
      }
    } catch (error) {
      return { error };
    }
  };

  handleFetchedData = async ({ groupID }: { groupID: string }) => {
    const {
      error,
      groupDetails,
    }: {
      error?: ApiErrorType;
      groupDetails?: GroupDetailsType;
    } = await this.fetchData({ groupID });
    if (error) {
      this._isMounted && handleNotification('error', error.data);
      this._isMounted && this.setState({ loading: false });
    } else {
      const { formElements, allowEdit } = this.state;
      let tempFormElements = { ...formElements };

      if (groupDetails) {
        tempFormElements = update(tempFormElements, {
          groupID: { value: { $set: groupDetails.group_id || '' } },
          groupName: {
            value: { $set: groupDetails.group_name || '' },
            disabled: { $set: !allowEdit },
          },
          groupType: {
            value: { $set: groupDetails.grouptype || '' },
            disabled: { $set: !allowEdit },
          },
          minDate: {
            value: {
              $set: groupDetails.mindate
                ? getUtcTime({ date: groupDetails.mindate, format: 'date' })
                : '',
            },
            disabled: { $set: !allowEdit },
          },
          maxDate: {
            value: {
              $set: groupDetails.maxdate
                ? getUtcTime({ date: groupDetails.maxdate, format: 'date' })
                : '',
            },
            disabled: { $set: !allowEdit },
          },
          minTime: {
            value: {
              $set: groupDetails.mindate
                ? getUtcTime({ date: groupDetails.mindate, format: 'time' })
                : '',
            },
            disabled: { $set: !allowEdit },
          },
          maxTime: {
            value: {
              $set: groupDetails.maxdate
                ? getUtcTime({ date: groupDetails.maxdate, format: 'time' })
                : '',
            },
            disabled: { $set: !allowEdit },
          },
          maxDaysFromToday: {
            value: {
              $set:
                groupDetails.maxdaysfromtoday !== -1
                  ? groupDetails.maxdaysfromtoday.toString()
                  : '',
            },
            disabled: { $set: !allowEdit },
          },
          dateTimeRadio: {
            value: {
              $set:
                groupDetails.maxdaysfromtoday === -1
                  ? 'unlimitedAccess'
                  : 'dateAccess',
            },
          },
        });

        if (
          Array.isArray(groupDetails.phenomlist) &&
          groupDetails.phenomlist.length > 0
        ) {
          tempFormElements = update(tempFormElements, {
            phenomList: { value: { $set: groupDetails.phenomlist.join(', ') } },
          });
        }

        if (
          Array.isArray(groupDetails.extendedpermissions) &&
          groupDetails.extendedpermissions.length > 0
        ) {
          tempFormElements = update(tempFormElements, {
            extendedPermission: {
              value: { $set: groupDetails.extendedpermissions.join(', ') },
            },
          });
        }

        if (
          Array.isArray(groupDetails.devicemanagementpermissions) &&
          groupDetails.devicemanagementpermissions.length > 0
        ) {
          tempFormElements = update(tempFormElements, {
            deviceManagementPermission: {
              value: {
                $set: groupDetails.devicemanagementpermissions.join(', '),
              },
            },
          });
        }

        if (
          groupDetails.mindate === '0001-01-01T00:00:00Z' &&
          groupDetails.maxdate === '0001-01-01T00:00:00Z'
        ) {
          tempFormElements = update(tempFormElements, {
            dateTimeRadio: {
              value: {
                $set:
                  groupDetails.maxdaysfromtoday === -1
                    ? 'unlimitedAccess'
                    : 'rollingAccess',
              },
            },
          });
        }
        tempFormElements = update(tempFormElements, {
          dateTimeRadio: {
            disabled: {
              $set: !allowEdit,
            },
          },
          allPhenoms: { $set: groupDetails.allPhenoms },
          phenomList: { disabled: { $set: groupDetails.allPhenoms } },
        });

        if (groupDetails.partner_id) {
          tempFormElements = update(tempFormElements, {
            partnerName: {
              value: {
                $set: groupDetails.partner_id || '',
              },
            },
          });

          this.fetchListData({
            partnerID: groupDetails.partner_id,
            extendedPermission: groupDetails.extendedpermissions,
            phenomList: groupDetails.phenomlist,
            deviceManagementPermission:
              groupDetails.devicemanagementpermissions,
            formElements: tempFormElements,
          });
        } else {
          this._isMounted &&
            this.setState({ loading: false, formElements: tempFormElements });
        }
      } else {
        this._isMounted && this.setState({ loading: false });
      }
    }
  };

  fetchListData = async ({
    partnerID,
    formElements,
    extendedPermission,
    phenomList,
    deviceManagementPermission,
  }: {
    partnerID: string;
    formElements: FormElementsType;
    extendedPermission?: string[];
    phenomList?: string[];
    deviceManagementPermission?: string[];
  }) => {
    const { updateToken, userData } = this.props;
    const permission = partnerApi.getPartnerPermissions(
      { type: 'extended' },
      { partnerID }
    );
    const response = await apiCall({
      storeToken: userData.token,
      url: permission.url,
      method: permission.method,
      params: permission.params,
      contentType: permission.contentType,
      cancelToken: this.axiosCancelSource.token,
    });
    const result: ApiResultType = response?.data;
    const phenoms = partnerApi.getPartnerPhenoms(undefined, { partnerID });
    const response2 = await apiCall({
      storeToken: userData?.token,
      url: phenoms.url,
      method: phenoms.method,
      contentType: phenoms.contentType,
      cancelToken: this.axiosCancelSource.token,
    });
    const result2 = response2?.data;
    const permission2 = partnerApi.getPartnerPermissions(
      { type: 'management' },
      { partnerID }
    );
    const response3 = await apiCall({
      storeToken: userData.token,
      url: permission2.url,
      method: permission2.method,
      params: permission2.params,
      contentType: permission2.contentType,
      cancelToken: this.axiosCancelSource.token,
    });
    const result3 = response3?.data;
    updateToken(result);
    updateToken(result2);
    updateToken(result3);

    let stateData: Partial<StateType> = {};

    if (result?.status === 'ok') {
      if (result.data) {
        const tempExtenedRights: string[] = result.data;
        const tempRights: { [k: string]: boolean } = {};
        tempExtenedRights.forEach((item) => {
          tempRights[item] = false;
        });
        if (
          Array.isArray(extendedPermission) &&
          extendedPermission.length > 0
        ) {
          extendedPermission.forEach((item) => {
            tempRights[item] = true;
          });
        }
        stateData = update(stateData, {
          extendedPermissionCollection: { $set: tempRights },
          initExtendedPermissionCollection: { $set: tempRights },
        });
      }
    } else {
      this._isMounted && handleNotification('error', result);
    }

    if (result2?.status === 'ok') {
      if (result2.data) {
        const tempPhenomsData: {
          id: string;
          name: string;
          units: string;
          description: string;
          type: string;
        }[] = result2.data;

        const tempPhenoms: { [k: string]: boolean } = {};
        tempPhenomsData.forEach((item) => {
          tempPhenoms[item.id] = false;
        });
        if (Array.isArray(phenomList) && phenomList.length > 0) {
          phenomList.forEach((item) => {
            tempPhenoms[item] = true;
          });
        }

        stateData = update(stateData, {
          phenomCollection: { $set: tempPhenoms },
          initPhenomCollection: { $set: tempPhenoms },
        });
      }
    } else {
      this._isMounted && handleNotification('error', result2);
    }

    if (result3?.status === 'ok') {
      if (result3.data) {
        const tempManagementRights: string[] = result3.data;
        const tempDeviceMng: { [k: string]: boolean } = {};
        tempManagementRights.forEach((item) => {
          tempDeviceMng[item] = false;
        });
        if (
          Array.isArray(deviceManagementPermission) &&
          deviceManagementPermission.length > 0
        ) {
          deviceManagementPermission.forEach((item) => {
            tempDeviceMng[item] = true;
          });
        }
        stateData = update(stateData, {
          deviceManagementCollection: { $set: tempDeviceMng },
          initDeviceManagementCollection: { $set: tempDeviceMng },
        });
      }
    } else {
      this._isMounted && handleNotification('error', result2);
    }

    this._isMounted &&
      this.setState((prevState) => ({
        ...prevState,
        formElements,
        loading: false,
        ...stateData,
      }));
  };

  inputChangedHandler = (name: keyof FormElementsType, value: string) => {
    const { formElements } = this.state;

    let tempFormElements = { ...formElements };

    if (name) {
      if (
        value &&
        name === 'maxDaysFromToday' &&
        !checkValidation(value, { isNumeric: true })
      ) {
        message.error('Please enter valid input!');
        return;
      }

      if (name !== 'allPhenoms') {
        tempFormElements = update(tempFormElements, {
          [name]: {
            value: { $set: value },
            touched: { $set: true },
            valid: {
              $set: checkValidation(value, tempFormElements[name].validation),
            },
          },
        });
      }

      if (name === 'partnerName' && value) {
        this.fetchListData({
          partnerID: value,
          formElements: tempFormElements,
        });
      } else {
        if (name === 'minDate' || name === 'maxDate') {
          if (
            moment(tempFormElements.minDate.value).unix() >
            moment(tempFormElements.maxDate.value).unix()
          ) {
            tempFormElements = update(tempFormElements, {
              maxDate: {
                touched: { $set: true },
                valid: { $set: false },
                errorMessage: {
                  $set: `Please select a 'To' date that is a after 'From' date`,
                },
              },
            });
          } else {
            tempFormElements = update(tempFormElements, {
              maxDate: {
                valid: { $set: true },
              },
            });
          }
        }
        this._isMounted &&
          this.setState({
            formElements: tempFormElements,
            hasInputChanged: true,
          });
      }
    }
  };

  onFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    this.handleSubmit();
  };

  handleSubmit = async (onConfirm?: () => void) => {
    const { updateToken, userData } = this.props;
    const {
      formElements,
      phenomCollection,
      extendedPermissionCollection,
      deviceManagementCollection,
    } = this.state;

    let tempFormElements = { ...formElements };

    let key: keyof typeof tempFormElements;

    for (key in tempFormElements) {
      if (key !== 'allPhenoms') {
        tempFormElements = update(tempFormElements, {
          [key]: {
            touched: { $set: true },
            valid: {
              $set: checkValidation(
                tempFormElements[key].value,
                tempFormElements[key].validation
              ),
            },
          },
        });
      }
    }

    if (
      moment(tempFormElements.minDate.value).unix() >
      moment(tempFormElements.maxDate.value).unix()
    ) {
      tempFormElements = update(tempFormElements, {
        maxDate: {
          touched: { $set: true },
          valid: { $set: false },
          errorMessage: {
            $set: `Please select a 'To' date that is a after 'From' date`,
          },
        },
      });

      this._isMounted &&
        handleNotification('error', {
          status: 'error',
          message: `'To' date should be greater than 'From' date`,
        });
    } else {
      tempFormElements = update(tempFormElements, {
        maxDate: {
          valid: { $set: true },
        },
      });
    }

    this._isMounted && this.setState({ formElements: tempFormElements });

    for (key in tempFormElements) {
      if (key !== 'allPhenoms' && !tempFormElements[key].valid) {
        message.error('Please fill all the fields');
        return;
      }
    }

    let tempData: {
      maxdaysfromtoday?: number;
      mindate?: string;
      maxdate?: string;
    } = {};
    if (tempFormElements.dateTimeRadio.value === 'dateAccess') {
      const maxDateVal = tempFormElements.maxDate.value
        ? getUtcTime({
            date: `${tempFormElements.maxDate.value} ${tempFormElements.maxTime.value}`,
          })
        : '';
      const minDateVal = tempFormElements.minDate.value
        ? getUtcTime({
            date: `${tempFormElements.minDate.value} ${tempFormElements.minTime.value}`,
          })
        : '';

      tempData = update(tempData, {
        mindate: { $set: minDateVal },
        maxdate: { $set: maxDateVal },
      });
    } else if (tempFormElements.dateTimeRadio.value === 'rollingAccess') {
      tempData = update(tempData, {
        maxdaysfromtoday: {
          $set: parseInt(tempFormElements.maxDaysFromToday.value),
        },
      });
    } else {
      tempData = update(tempData, {
        maxdaysfromtoday: {
          $set: -1,
        },
      });
    }

    const data = {
      group_id: tempFormElements.groupID.value,
      group_name: tempFormElements.groupName.value,
      grouptype: tempFormElements.groupType.value,
      partner_id: tempFormElements.partnerName.value,
      allPhenoms: tempFormElements.allPhenoms,
      ...tempData,
    };

    let tempFormData: string[] = [];
    let phenomlist: string[] = [];
    let extendedpermissions: string[] = [];
    let devicemanagementpermissions: string[] = [];
    let key2: keyof typeof data;
    if (data) {
      for (key2 in data) {
        if (key2 && data[key2] !== undefined) {
          let encodedValue: string | null = encodeURIComponent(data[key2]!);
          if (
            (key2 === 'maxdate' ||
              key2 === 'mindate' ||
              key2 === 'maxdaysfromtoday') &&
            !data[key2]
          ) {
            encodedValue = null;
          }
          // tempFormData.push(key2 + '=' + encodedValue);
          tempFormData = update(tempFormData, {
            $push: [key2 + '=' + encodedValue],
          });
        }
      }
    }

    for (const key3 in phenomCollection) {
      if (phenomCollection[key3] === true) {
        // phenomlist.push(key3);
        phenomlist = update(phenomlist, {
          $push: [key3],
        });
      }
    }
    for (const key4 in extendedPermissionCollection) {
      if (extendedPermissionCollection[key4] === true) {
        // extendedpermissions.push(key4);
        extendedpermissions = update(extendedpermissions, {
          $push: [key4],
        });
      }
    }
    for (const key5 in deviceManagementCollection) {
      if (deviceManagementCollection[key5] === true) {
        // devicemanagementpermissions.push(key5);
        devicemanagementpermissions = update(devicemanagementpermissions, {
          $push: [key5],
        });
      }
    }
    // tempFormData.push('phenomlist=' + JSON.stringify(phenomlist));
    // tempFormData.push(
    //   'extendedpermissions=' + JSON.stringify(extendedpermissions)
    // );
    // tempFormData.push(
    //   'devicemanagementpermissions=' +
    //     JSON.stringify(devicemanagementpermissions)
    // );

    tempFormData = update(tempFormData, {
      $push: [
        'phenomlist=' + JSON.stringify(phenomlist),
        'extendedpermissions=' + JSON.stringify(extendedpermissions),
        'devicemanagementpermissions=' +
          JSON.stringify(devicemanagementpermissions),
      ],
    });

    const formData: string = tempFormData.join('&');

    this._isMounted && this.setState({ loading: true });
    try {
      const { url, method, contentType } = groupApi.putGroup(undefined, {
        groupID: tempFormElements.groupID.value,
      });
      const response = await apiCall({
        storeToken: userData.token,
        url,
        method,
        contentType,
        cancelToken: this.axiosCancelSource.token,
        data: formData,
      });
      const result: ApiResultType = response?.data;
      updateToken(result);
      if (result?.status === 'ok') {
        this._isMounted && handleNotification('success', result);
        this._isMounted &&
          this.setState({ loading: false, hasInputChanged: false }, () =>
            onConfirm?.()
          );
      } else {
        this._isMounted && handleNotification('error', result);
        this._isMounted && this.setState({ loading: false });
      }
    } catch (error) {
      this._isMounted && handleNotification('error', error.data);
      this._isMounted && this.setState({ loading: false });
    }
  };

  handlePopupConfirm = (onConfirm: () => void) => {
    this.handleSubmit(onConfirm);
  };

  handleCancelRedirect = () => {
    const { history } = this.props;
    history.push({
      pathname: groupRoutes.list,
    });
  };

  handlePhenomModal = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showPhenomModal: !prevState.showPhenomModal,
      };
    });
  };

  handleExtendedPermissionModal = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showExtendedPermissionModal: !prevState.showExtendedPermissionModal,
      };
    });
  };

  handleDeviceManagementPermissionModal = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showDeviceManagementPermissionModal:
          !prevState.showDeviceManagementPermissionModal,
      };
    });
  };

  // handleListData = <T extends keyof StateType>(
  //   listData: { [k: string]: boolean },
  //   checkboxListName?: string,
  //   modalKeyName?: string,
  //   inputFieldName?: keyof FormElementsType
  // ) => {
  //   const { formElements } = this.state;

  //   const stateData: { [k: string]: any } = {};

  //   if (checkboxListName) {
  //     stateData[checkboxListName] = listData;
  //   }

  //   if (modalKeyName) {
  //     stateData[modalKeyName] = false;
  //   }

  //   if (inputFieldName) {
  //     const tempData = [];
  //     for (const key in listData) {
  //       if (listData[key]) {
  //         tempData.push(key);
  //       }
  //     }
  //     formElements[inputFieldName].value = tempData
  //       .map((item) => `${item}`)
  //       .join(', ');

  //     stateData.formElements = formElements;
  //   }

  //   this._isMounted && this.setState(stateData as { [P in T]: StateType[P] });
  // };

  handleListData = (
    listData: { [k: string]: boolean },
    checkboxListName?: string,
    modalKeyName?: string,
    inputFieldName?: keyof FormElementsType
  ) => {
    const { formElements } = this.state;
    let tempFormElements = { ...formElements };

    let stateData: Partial<StateType> = {};

    if (checkboxListName) {
      stateData = update(stateData, { [checkboxListName]: { $set: listData } });
    }

    if (modalKeyName) {
      stateData = update(stateData, { [modalKeyName]: { $set: false } });
    }

    if (inputFieldName) {
      let tempData: string[] = [];
      for (const key in listData) {
        if (listData[key]) {
          tempData = update(tempData, { $push: [key] });
        }
      }
      tempFormElements = update(tempFormElements, {
        [inputFieldName]: {
          value: { $set: tempData.map((item) => `${item}`).join(', ') },
        },
      });

      stateData.formElements = tempFormElements;
    }

    this._isMounted &&
      this.setState((prevState) => ({ ...prevState, ...stateData }));
  };

  handleDelete = () => {
    const { userData } = this.props;

    const onDeleteConfirm = async () => {
      const { formElements } = this.state;
      if (formElements.groupID.value) {
        this.setState({ loading: true });
        const { url, method, contentType } = groupApi.deleteGroup(undefined, {
          groupID: formElements.groupID.value,
        });
        const response = await apiCall({
          storeToken: userData.token,
          url,
          method,
          contentType,
          cancelToken: this.axiosCancelSource.token,
        });
        const result: ApiResultType = response?.data;

        if (result?.status === 'ok') {
          this._isMounted && handleNotification('success', result);
        } else {
          this._isMounted && handleNotification('error', result);
        }

        setTimeout(() => {
          this.handleCancelRedirect();
        }, 1005);
      }
    };

    Modal.confirm({
      title: 'Are you sure?',
      icon: <AiOutlineExclamationCircle />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        onDeleteConfirm();
      },
      onCancel: () => {},
    });
  };

  onCheckboxChange = (event: CheckboxChangeEvent) => {
    const name = event?.target?.name;
    const checked = event?.target?.checked;
    const { formElements } = this.state;

    if (name) {
      let tempFormElements = update(formElements, {
        [name]: { $set: checked },
      });

      if (name === 'allPhenoms') {
        tempFormElements = update(tempFormElements, {
          phenomList: { disabled: { $set: checked } },
        });
      }

      this.handleState({ formElements: tempFormElements });
    }
  };

  setCurrentTabIndex = (index: string) => {
    this._isMounted && this.setState({ currentTabIndex: index });
  };

  render() {
    const { userPermissionList, userData } = this.props;
    const {
      loading,
      extendedPermissionCollection,
      showExtendedPermissionModal,
      phenomCollection,
      showPhenomModal,
      deviceManagementCollection,
      showDeviceManagementPermissionModal,
      currentTabIndex,
      formElements,
      hasInputChanged,
      allowEdit,
      groupID,
    } = this.state;

    if (!groupID) {
      return <Redirect to={groupRoutes.list} />;
    }

    if (userPermissionList.length === 0) {
      return <Redirect to="/" />;
    }

    return (
      <Fragment>
        <PromptPopup
          when={hasInputChanged}
          handleConfirm={this.handlePopupConfirm}
        />
        <FormWrapper
          loading={loading}
          title={allowEdit ? `Group Edit` : `Group View`}>
          <form noValidate onSubmit={this.onFormSubmit}>
            <Tabs
              activeKey={currentTabIndex}
              onTabClick={this.setCurrentTabIndex}>
              <Tabs.TabPane tab="Details" key="1">
                <DetailsTab
                  formElements={formElements}
                  inputChangedHandler={this.inputChangedHandler}
                  onCheckboxChange={this.onCheckboxChange}
                  allowEdit={allowEdit}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Users" key="2">
                <UsersTab
                  userData={userData}
                  groupID={formElements.groupID.value}
                  allowEdit={allowEdit}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Locations" key="3">
                <LocationsTab
                  userData={userData}
                  groupID={formElements.groupID.value}
                  allowEdit={allowEdit}
                />
              </Tabs.TabPane>
            </Tabs>

            <div className="row justify-content-center">
              {allowEdit && (
                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                  <Button type="primary" htmlType="submit" block size="large">
                    Submit
                  </Button>
                </div>
              )}

              <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 btn-mobile-padding-top">
                <Button
                  type="primary"
                  htmlType="button"
                  block
                  size="large"
                  onClick={this.handleCancelRedirect}>
                  Cancel
                </Button>
              </div>
              {allowEdit && (
                <Fragment>
                  <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 btn-mobile-padding-top">
                    <Button
                      type="primary"
                      htmlType="button"
                      block
                      danger
                      size="large"
                      onClick={this.handleDelete}>
                      Delete
                    </Button>
                  </div>
                </Fragment>
              )}
            </div>
          </form>
        </FormWrapper>

        {showPhenomModal && (
          <CheckboxListModal
            checkboxListData={phenomCollection}
            handleListData={this.handleListData}
            isDisabled={!allowEdit}
            showModal={showPhenomModal}
            handleModal={this.handlePhenomModal}
            checkboxListName={`phenomCollection`}
            modalKeyName={`showPhenomModal`}
            inputFieldName={`phenomList`}
          />
        )}

        {showExtendedPermissionModal && (
          <CheckboxListModal
            checkboxListData={extendedPermissionCollection}
            handleListData={this.handleListData}
            isDisabled={!allowEdit}
            showModal={showExtendedPermissionModal}
            handleModal={this.handleExtendedPermissionModal}
            checkboxListName={`extendedPermissionCollection`}
            modalKeyName={`showExtendedPermissionModal`}
            inputFieldName={`extendedPermission`}
          />
        )}

        {showDeviceManagementPermissionModal && (
          <CheckboxListModal
            checkboxListData={deviceManagementCollection}
            handleListData={this.handleListData}
            isDisabled={!allowEdit}
            showModal={showDeviceManagementPermissionModal}
            handleModal={this.handleDeviceManagementPermissionModal}
            checkboxListName={`deviceManagementCollection`}
            modalKeyName={`showDeviceManagementPermissionModal`}
            inputFieldName={`deviceManagementPermission`}
          />
        )}
      </Fragment>
    );
  }
}

const mapDispatch = {
  updateToken: updateToken,
};
const connector = connect(null, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(GroupDetails);
