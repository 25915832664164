import { Fragment, Component } from 'react';
import update from 'immutability-helper';
import { AiOutlinePlus } from 'react-icons/ai';
import { v4 } from 'uuid';

import {
  CalibrationTabElementsType,
  calibrationTabElements,
  CalibrationTabSingleElementsType,
} from '../helpers';
import { CalibrationType } from '../../../type-definitions/api-types';
import { Button, Col, message, Row, Tag } from 'antd';
import {
  getMultipleInputElements,
  getSingleInputElement,
} from '../../../utils/get-input-element';
import QueueAnim from 'rc-queue-anim';
import { checkValidation } from '../../../utils/validation';

type StateType = {
  formElements: CalibrationTabElementsType;
  showForm: boolean;
  isEditing: boolean;
};

type PropsType = {
  calibrationValues: CalibrationType[];
  handleCalibrationTabElements: (data: CalibrationType[]) => void;
  allowEdit?: boolean;
  calibrationTabInputChangedHandler: (name: any, value: string) => void;
  calibrationTabFormElements: CalibrationTabSingleElementsType;
  handleCancelRedirect: () => void;
  isEditAllowed: boolean;
};

class CalibrationTab extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      formElements: { ...calibrationTabElements },
      showForm: false,
      isEditing: false,
    };
  }

  _isMounted = false;
  componentDidMount() {
    this._isMounted = true;
  }

  handleState = (data: Partial<StateType>) => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          ...prevState,
          ...data,
        };
      });
  };

  inputChangedHandler = (
    name: keyof CalibrationTabElementsType,
    value: string
  ) => {
    const { formElements } = this.state;
    let tempFormElements = { ...formElements };
    if (name && name !== 'uuid') {
      if (
        (name === 'slope' || name === 'offset') &&
        value &&
        !checkValidation(value, { isNegativeFloat: true })
      ) {
        message.warning('Enter valid input!');
        return;
      }

      tempFormElements = update(tempFormElements, {
        [name]: {
          value: { $set: value },
          touched: { $set: true },
          valid: {
            $set: checkValidation(value, tempFormElements[name].validation),
          },
        },
        uuid: { $set: !tempFormElements.uuid ? v4() : tempFormElements.uuid },
      });
    }

    this.handleState({ formElements: tempFormElements });
  };

  onDoneForm = () => {
    const { calibrationValues, handleCalibrationTabElements } = this.props;
    const { formElements, isEditing } = this.state;

    let tempFormElements = { ...formElements };
    let tempCalibrations = [...calibrationValues];

    let key: keyof typeof tempFormElements;

    for (key in tempFormElements) {
      if (key !== 'uuid') {
        tempFormElements = update(tempFormElements, {
          [key]: {
            touched: { $set: true },
            valid: {
              $set: checkValidation(
                tempFormElements[key].value,
                tempFormElements[key].validation
              ),
            },
          },
        });
      }
    }

    let valid = true;
    for (key in tempFormElements) {
      if (key !== 'uuid' && !tempFormElements[key].valid) {
        valid = false;
      }
    }

    this.handleState({
      formElements: { ...tempFormElements },
    });

    if (!valid) {
      message.error('Please fill all the fields');
      return;
    }

    const matchedPhenomenon = tempCalibrations.find(
      (el) =>
        el.phenomenon.toLowerCase() ===
        tempFormElements.phenomenon.value.toLowerCase()
    );

    if (isEditing) {
      const matchedID = tempCalibrations.find(
        (el) => el.uuid === tempFormElements.uuid
      );

      if (matchedPhenomenon && matchedPhenomenon.uuid !== matchedID?.uuid) {
        message.error(`Phenomenon with same text already exists!`);
        return;
      }

      tempCalibrations = tempCalibrations.map((el) => {
        if (el.uuid === tempFormElements.uuid) {
          el = update(el, {
            phenomenon: { $set: tempFormElements.phenomenon.value },
            ModelName: { $set: tempFormElements.modelName.value },
            calibrationFactor: {
              slope: { $set: Number(tempFormElements.slope.value) },
              offset: { $set: Number(tempFormElements.offset.value) },
            },
          });
        }
        return el;
      });

      handleCalibrationTabElements(tempCalibrations);

      this.handleState({
        isEditing: false,
        showForm: false,
        formElements: { ...calibrationTabElements },
      });
    } else {
      if (matchedPhenomenon) {
        message.error(`Phenomenon with same text already exists!`);
        return;
      }

      tempCalibrations = update(tempCalibrations, {
        $push: [
          {
            phenomenon: tempFormElements.phenomenon.value,
            ModelName: tempFormElements.modelName.value,
            calibrationFactor: {
              slope: Number(tempFormElements.slope.value),
              offset: Number(tempFormElements.offset.value),
            },
            uuid: tempFormElements.uuid,
          },
        ],
      });

      handleCalibrationTabElements(tempCalibrations);

      this.handleState({
        isEditing: false,
        showForm: false,
        formElements: { ...calibrationTabElements },
      });
    }
  };

  onShowForm = () => {
    this.handleState({ showForm: true });
  };

  onCloseForm = () => {
    this.handleState({
      showForm: false,
      formElements: { ...calibrationTabElements },
      isEditing: false,
    });
  };

  onTagClick = (data: CalibrationType) => {
    const { formElements } = this.state;
    const { allowEdit } = this.props;
    let tempFormElements = update(formElements, {
      phenomenon: {
        value: { $set: data.phenomenon },
        disabled: { $set: allowEdit !== undefined ? !allowEdit : false },
      },
      modelName: {
        value: { $set: data.ModelName },
        disabled: { $set: allowEdit !== undefined ? !allowEdit : false },
      },
      slope: {
        value: { $set: data.calibrationFactor.slope.toString() },
        disabled: { $set: allowEdit !== undefined ? !allowEdit : false },
      },
      offset: {
        value: { $set: data.calibrationFactor.offset.toString() },
        disabled: { $set: allowEdit !== undefined ? !allowEdit : false },
      },
      uuid: { $set: data.uuid || '' },
    });

    this.handleState({
      formElements: tempFormElements,
      showForm: true,
      isEditing: true,
    });
  };

  onDelete = () => {
    const { formElements } = this.state;
    const { handleCalibrationTabElements, calibrationValues } = this.props;

    let tempCalibrations = [...calibrationValues];

    tempCalibrations = tempCalibrations.filter(
      (el) => el.uuid !== formElements.uuid
    );
    handleCalibrationTabElements(tempCalibrations);

    this.handleState({
      isEditing: false,
      showForm: false,
      formElements: { ...calibrationTabElements },
    });
  };

  render() {
    const {
      calibrationValues,
      allowEdit,
      calibrationTabInputChangedHandler,
      handleCancelRedirect,
      isEditAllowed,
      calibrationTabFormElements,
    } = this.props;
    const { formElements, showForm, isEditing } = this.state;

    const { uuid, ...restFormElements } = formElements;
    const single = getSingleInputElement({
      formElements: { ...restFormElements },
      inputChangedHandler: this.inputChangedHandler,
      sliceValue: [0, 5],
    });

    let tempCalibrationTabFormElements = update(calibrationTabFormElements, {
      calibrationDate: { disabled: { $set: !isEditAllowed } },
      calibrationTime: { disabled: { $set: !isEditAllowed } },
    });

    const single2 = getMultipleInputElements({
      formElements: { ...tempCalibrationTabFormElements },
      inputChangedHandler: calibrationTabInputChangedHandler,
      sliceValue: [0, 3],
    });

    let isDeleteDisabled = true;
    if (isEditing && allowEdit) {
      isDeleteDisabled = false;
    }

    return (
      <Fragment>
        {single2}
        <Row justify="center" className="py-3">
          {allowEdit !== undefined && allowEdit && (
            <Col>
              <Tag
                style={{
                  cursor: !showForm ? 'pointer' : 'default',
                }}
                onClick={!showForm ? this.onShowForm : () => {}}>
                <AiOutlinePlus /> Add Calibration
              </Tag>
            </Col>
          )}
        </Row>
        <Row justify="center" className="py-3">
          {calibrationValues &&
            calibrationValues.length > 0 &&
            calibrationValues.map((el, idx) => {
              return (
                <Fragment key={idx}>
                  <Col className="py-2 py-md-0">
                    <Tag
                      style={{ fontSize: '0.8rem', cursor: 'pointer' }}
                      color="blue"
                      onClick={() => this.onTagClick(el)}>
                      {el.phenomenon}
                    </Tag>
                  </Col>
                </Fragment>
              );
            })}
        </Row>
        <Row justify="center" className="py-3">
          <Col>
            <QueueAnim leaveReverse>
              {showForm
                ? [
                    ...single,
                    <Row justify="center" key={single.length + 11}>
                      <Col>
                        <Button
                          block
                          danger
                          disabled={isDeleteDisabled}
                          type="primary"
                          htmlType="button"
                          onClick={this.onDelete}>
                          Delete
                        </Button>
                      </Col>
                      <Col className="pl-3">
                        <Button
                          block
                          disabled={
                            allowEdit !== undefined ? !allowEdit : false
                          }
                          type="primary"
                          htmlType="button"
                          onClick={this.onDoneForm}>
                          Done
                        </Button>
                      </Col>
                      <Col className="pl-3">
                        <Button
                          danger
                          block
                          type="primary"
                          htmlType="button"
                          onClick={this.onCloseForm}>
                          Close
                        </Button>
                      </Col>
                    </Row>,
                  ]
                : null}
            </QueueAnim>
          </Col>
        </Row>

        <Row justify="center" className="pt-2">
          {isEditAllowed && (
            <Col sm={12} md={8}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                block
                disabled={showForm}>
                Submit
              </Button>
            </Col>
          )}

          <Col sm={12} md={8} className="pl-3">
            <Button
              type="primary"
              htmlType="button"
              size="large"
              block
              onClick={handleCancelRedirect}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default CalibrationTab;
