import { Fragment, useReducer, Dispatch } from 'react';
import { Row, Col, Button, Tag, message } from 'antd';

import { ReducerHookActionType } from '../../../type-definitions';
import {
  AttributesCollectionType,
  AttributesTabStateType,
  initAttributesTabState,
  attributesReducer,
  actionTypes,
} from '../helpers';
import AntdInput from '../../AntdInput';
import { updateImmutably } from '../../../shared/helpers';

interface PropsType {
  attributesCollection?: AttributesCollectionType[];
  onAttributesUpdate: ({
    data,
    isDelete,
    isEdit,
  }: {
    data: AttributesCollectionType;
    isDelete?: boolean;
    isEdit?: boolean;
  }) => void;
}

const AttributesTab = ({
  attributesCollection,
  onAttributesUpdate,
}: PropsType) => {
  const [state, dispatchToState]: [
    state: AttributesTabStateType,
    dispatchToState: Dispatch<ReducerHookActionType>
  ] = useReducer(attributesReducer, initAttributesTabState);

  const { formElements } = state;

  const onInputChanged = (name: string, value: any) => {
    const tempFormElements = updateImmutably(formElements, {
      [name]: {
        value: { $set: value },
      },
    });

    dispatchToState({
      type: actionTypes.setAttributeInput,
      payload: { formElements: tempFormElements },
    });
  };

  const onCancelClicked = () => {
    const tempFormElements = updateImmutably(formElements, {
      attributeName: {
        value: { $set: '' },
      },
      attributeValue: {
        value: { $set: '' },
      },
      uuid: { $set: '' },
    });

    dispatchToState({
      type: actionTypes.setState,
      payload: { formElements: tempFormElements },
    });
  };

  const onSubmitClick = () => {
    if (formElements.attributeName.value && formElements.attributeValue.value) {
      const editMatch = attributesCollection?.find(
        (el) => el.uuid === formElements.uuid
      );
      const addMatch = attributesCollection?.find(
        (el) => el.attributeName.value === formElements.attributeName.value
      );
      if (editMatch) {
        onAttributesUpdate({ data: formElements, isEdit: true });
        onCancelClicked();
      } else if (addMatch) {
        message.error('Attribute Name already exists!');
        return;
      } else {
        onAttributesUpdate({ data: formElements });
        onCancelClicked();
      }
    } else {
      message.error('All fields are required!');
      return;
    }
  };

  const onAttributeTagClick = (data: AttributesCollectionType) => {
    const tempFormElements = updateImmutably(formElements, {
      attributeName: {
        value: { $set: data.attributeName.value },
      },
      attributeValue: {
        value: { $set: data.attributeValue.value },
      },
      uuid: { $set: data.uuid },
    });

    dispatchToState({
      type: actionTypes.setState,
      payload: { formElements: tempFormElements },
    });
  };

  const onDeleteClick = () => {
    onAttributesUpdate({ data: formElements, isDelete: true });
    onCancelClicked();
  };

  return (
    <Fragment>
      <Row gutter={[16, 32]}>
        <Col xs={24}>
          <Row justify="center" className="py-3">
            {attributesCollection &&
              attributesCollection.length > 0 &&
              attributesCollection.map((item) => {
                return (
                  <Fragment key={item.uuid}>
                    <Col className="py-2">
                      <Tag
                        key={item.uuid}
                        style={{ fontSize: '0.8rem', cursor: 'pointer' }}
                        color="blue"
                        onClick={() => onAttributeTagClick(item)}>
                        {item.attributeName.value}
                      </Tag>
                    </Col>
                  </Fragment>
                );
              })}
          </Row>
          <Row justify="center">
            <Col xs={24} md={12}>
              <AntdInput
                {...state.formElements.attributeName}
                onInputChanged={onInputChanged}
              />
            </Col>
          </Row>
          <Row justify="center">
            <Col xs={24} md={12}>
              <AntdInput
                {...state.formElements.attributeValue}
                onInputChanged={onInputChanged}
              />
            </Col>
          </Row>
          <Row justify="center">
            <Col xs={24} md={12}>
              <Row justify="center" gutter={[0, { xs: 16 }]}>
                <Col xs={24} md={8} className="text-center">
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={onSubmitClick}
                    disabled={
                      !formElements.attributeName.value &&
                      !formElements.attributeValue.value
                    }>
                    Submit
                  </Button>
                </Col>

                <Col xs={24} md={8} className="text-center">
                  <Button
                    type="primary"
                    htmlType="button"
                    danger
                    onClick={onCancelClicked}
                    disabled={
                      !formElements.attributeName.value &&
                      !formElements.attributeValue.value
                    }>
                    Cancel
                  </Button>
                </Col>

                <Col xs={24} md={8} className="text-center">
                  <Button
                    type="primary"
                    htmlType="button"
                    danger
                    ghost
                    onClick={onDeleteClick}
                    disabled={
                      !formElements.attributeName.value &&
                      !formElements.attributeValue.value
                    }>
                    Delete
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AttributesTab;
