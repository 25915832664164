import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import update from 'immutability-helper';
import { v4 } from 'uuid';

import {
  FormInputType,
  ReducerHookActionType,
} from '../../../type-definitions';
import {
  ApiErrorType,
  GroupListType,
  JournalListType,
  LocationDetailsType,
} from '../../../type-definitions/api-types';
import { reducerHookActions } from '../../../utils/constants';

export const detailsTabElements: DetailsTabElementsType = {
  // 0
  locationID: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Location ID',
      name: 'locationID',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Location ID is required',
    label: 'Location ID *',
    disabled: true,
  },
  // 1
  level: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Level',
      name: 'level',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Level is required',
    label: 'Level',
    disabled: true,
  },
  // 2
  partnerName: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Partner Name',
      name: 'partnerName',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Partner Name is required',
    label: 'Partner Name',
    disabled: true,
  },
  // 3
  latitude: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Lat',
      name: 'latitude',
    },
    value: '0',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Lat is required',
    label: 'Lat',
    colValue: 4,
  },
  // 4
  longitude: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Long',
      name: 'longitude',
    },
    value: '0',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Long is required',
    label: 'Long',
    colValue: 4,
  },
  // 5
  height: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Height in M',
      name: 'height',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Height is required',
    label: 'Height',
  },
  // 6
  locationName: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Location Name',
      name: 'locationName',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Location Name is required',
    label: 'Location Name *',
  },
  // 7
  status: {
    elementType: 'select',
    elementConfig: {
      type: 'text',
      placeholder: 'Status',
      name: 'status',
    },
    value: '',
    optionValues: [],
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Status is required',
    label: 'Status',
    dataTestId: 'statusSelect',
  },
  // 8
  capacity: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Capacity',
      name: 'capacity',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Capacity is required',
    label: 'Capacity',
  },
  // 9
  friendlyName: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Friendly Name',
      name: 'friendlyName',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Friendly Name is required',
    label: 'Friendly Name',
  },
  // 10
  friendlyDesc: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Friendly Description',
      name: 'friendlyDesc',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Friendly Description is required',
    label: 'Friendly Description',
  },

  staticCheck: false,
  internalCheck: false,
};

export const deviceTabElements: DeviceTabElementsType = {
  // 11
  locationMac: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Location MAC',
      name: 'locationMac',
      // readOnly: true,
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Location MAC is required',
    label: 'Location MAC',
    disabled: true,
  },
};

export const addressElements: AddressTabElementsType = {
  // 12
  street: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Address Line 1',
      name: 'street',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Address Line 1 is required',
    label: 'Address',
  },
  // 13
  street2: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Address Line 2',
      name: 'street2',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Address Line 2 is required',
    // label: 'Address'
  },
  // 14
  city: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Town/City',
      name: 'city',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Town/City is required',
    // label: 'Address'
  },
  // 15
  province: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'State/Province/County',
      name: 'province',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'State/Province/County is required',
    // label: 'Address'
  },
  // 16
  postalCode: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Postal Code',
      name: 'postalCode',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Postal Code is required',
    // label: 'Address'
  },
  // 17
  country: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Country',
      name: 'country',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Country is required',
    // label: 'Address'
  },
};

export const groupsTabElements: GroupsTabElementsType = {
  // 19
  locationGroupInput: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Groups',
      name: 'locationGroupInput',
      readOnly: true,
    },
    optionValues: [],
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Groups are required',
    label: 'Groups',
  },
};

export const sponsorshipTabElements: SponsorshipTabElementsType = {
  sponsorHTML: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Sponsorship',
      name: 'sponsorHTML',
    },
    optionValues: [],
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Sponsorship is required',
    label: 'Sponsorship',
  },
};

export const copyrightTabElements: CopyrightTabElementsType = {
  copyright: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Copyright',
      name: 'copyright',
    },
    optionValues: [],
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Copyright is required',
    label: 'Copyright',
  },
  defaultCopyright: false,
};

export interface DetailsTabElementsType {
  locationID: FormInputType;
  level: FormInputType;
  partnerName: FormInputType;
  latitude: FormInputType;
  longitude: FormInputType;
  height: FormInputType;
  locationName: FormInputType;
  status: FormInputType;
  capacity: FormInputType;
  staticCheck: boolean;
  internalCheck: boolean;
  friendlyName: FormInputType;
  friendlyDesc: FormInputType;
}
export interface DeviceTabElementsType {
  locationMac: FormInputType;
}
export interface AddressTabElementsType {
  street: FormInputType;
  street2: FormInputType;
  city: FormInputType;
  province: FormInputType;
  postalCode: FormInputType;
  country: FormInputType;
}
export interface GroupsTabElementsType {
  locationGroupInput: FormInputType;
}
export interface SponsorshipTabElementsType {
  sponsorHTML: FormInputType;
}
export interface CopyrightTabElementsType {
  copyright: FormInputType;
  defaultCopyright: boolean;
}

export type FormElementsType = DetailsTabElementsType &
  DeviceTabElementsType &
  AddressTabElementsType &
  GroupsTabElementsType &
  SponsorshipTabElementsType &
  CopyrightTabElementsType &
  JournalTabElementsType;

export type StateType = {
  formElements: FormElementsType;

  showStatus: boolean;
  showMap: boolean;
  mapZoom: number;
  loading: boolean;
  isRightsSet: boolean;
  locationGroupList: GroupListType[];
  defaultGroupsChecked: boolean;
  hasInputChanged: boolean;
  previousLocationDetails: Partial<LocationDetailsType>;
  currentTabIndex: string;
  showSwapModal: boolean;
  showMoveModal: boolean;
  showAddAssignModal: boolean;
  swapSelectedDeviceID: string;
  moveSelectedDeviceID: string;
  deviceModalStatus: '' | 'closed';
  initLat: string;
  initLng: string;
  initZoom: number;

  allowAllEdit?: boolean;
  allowNoteEdit?: boolean;
  hasStock?: boolean;
  locationID: string;

  locationDetails: Partial<LocationDetailsType>;

  attributesCollection?: AttributesCollectionType[];
};

export interface AttributesCollectionType {
  attributeName: FormInputType;
  attributeValue: FormInputType;
  uuid: string;
}

enum LocationGroupsEnumKeys {
  'group_id',
  'group_name',
  'grouptype',
  'mindate',
  'maxdate',
  'maxdaysfromtoday',
  'partner_id',
  'allPhenoms',
  'phenomlist',
  'extendedpermissions',
  'devicemanagementpermissions',
  'rights',
}

// ## -- Location Groups --
type LocationGroupsEnumKeysType = keyof typeof LocationGroupsEnumKeys;
export type LocationGroupsType = { [key in LocationGroupsEnumKeysType]: any };
// ## -- Location Groups -- end

export interface GroupsTabPropsType {
  locationGroupList: GroupListType[];
  partnerID: string;
  formElements: FormElementsType;
  setCurrentTabIndex: (index: string) => void;
  inputChangedHandler: (name: any, value: any) => void;
  defaultGroupsChecked: boolean;
  handleCheckbox: (event: CheckboxChangeEvent) => void;
  onOkayLocationGroupsTab: (locationGroups: GroupListType[]) => void;
}

export interface GroupsTabStateType {
  locationGroups: GroupListType[];
  groups: GroupListType[];
  initGroups: GroupListType[];
  defaultGroups: GroupListType[];
  loading: boolean;
  error: ApiErrorType;
  searchValueOtherGroups: string;
  filterByPartnerObj: { [k: string]: boolean };
}

export const journalTabElements: JournalTabElementsType = {
  journalNote: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Note',
      name: 'journalNote',
    },
    value: '',
    validation: {
      // required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Note is required',
    label: 'Note',
  },
};

export interface JournalTabElementsType {
  journalNote: FormInputType;
}

export const initJournalState: JournalTabStateType = {
  journalList: [],
  initJournalList: [],
  searchValue: '',
  deviceIDList: [],
  entryTypeList: [],
  filterEntryTypeValue: '',
  filterDeviceIDValue: '',
  tablePagination: {
    totalCount: 0,
    perPage: 10,
    currentPage: 1,
    lastPagination: 1,
  },
  showNoteModal: false,
  selectedJournalTS: '',
};

export interface JournalTabStateType {
  journalList: JournalListType[];
  initJournalList: JournalListType[];
  searchValue: string;
  deviceIDList: string[];
  entryTypeList: string[];
  filterDeviceIDValue: string;
  filterEntryTypeValue: string;
  tablePagination: {
    totalCount: number;
    perPage: number;
    currentPage: number;
    lastPagination: number;
  };
  showNoteModal: boolean;
  selectedJournalTS: string;
}

export const actionTypes = {
  ...reducerHookActions,
  setInitJournals: 'setInitJournals',
  setJournals: 'setJournals',
  setPagination: 'setPagination',
  resetPagination: 'resetPagination',
  setSearchValue: 'setSearchValue',
  setSelectValue: 'setSelectValue',
  clearSelectValue: 'clearSelectValue',
  setAttributeInput: 'setAttributeInput',
};

export const reducer = (
  state: JournalTabStateType,
  action: ReducerHookActionType
) => {
  switch (action.type) {
    case actionTypes.setState: {
      const newState = update(state, {
        $merge: { ...action.payload },
      });
      return { ...newState };
    }

    case actionTypes.setInitJournals: {
      const journalList = action.payload?.journalList;
      const tablePagination = action.payload?.tablePagination;
      const deviceIDList = action.payload?.deviceIDList;
      const entryTypeList = action.payload?.entryTypeList;

      const newState = update(state, {
        journalList: {
          $set: [...journalList],
        },
        initJournalList: {
          $set: [...journalList],
        },
        deviceIDList: {
          $set: deviceIDList || state.deviceIDList,
        },
        entryTypeList: {
          $set: entryTypeList || state.entryTypeList,
        },
        tablePagination: {
          $merge: { ...tablePagination },
        },
      });

      return { ...newState };
    }

    case actionTypes.setJournals: {
      const journalList = action.payload?.journalList;
      const tablePagination = action.payload?.tablePagination;
      const deviceIDList = action.payload?.deviceIDList;
      const entryTypeList = action.payload?.entryTypeList;

      const newState = update(state, {
        journalList: {
          $push: [...journalList],
        },
        initJournalList: {
          $push: [...journalList],
        },
        deviceIDList: {
          $set: deviceIDList || state.deviceIDList,
        },
        entryTypeList: {
          $set: entryTypeList || state.entryTypeList,
        },
        tablePagination: {
          $merge: { ...tablePagination },
        },
      });

      return { ...newState };
    }

    case actionTypes.setPagination: {
      const newState = update(state, {
        tablePagination: {
          $merge: { ...action.payload },
        },
      });
      return { ...newState };
    }

    case actionTypes.setSearchValue: {
      const searchValue = action.payload?.searchValue;
      const journalList = action.payload?.journalList;
      const tablePagination = action.payload?.tablePagination;

      const newState = update(state, {
        searchValue: { $set: searchValue },
        journalList: { $set: journalList },
        tablePagination: {
          $merge: { ...tablePagination },
        },
      });

      return { ...newState };
    }

    case actionTypes.resetPagination: {
      const newState = update(state, {
        journalList: {
          $set: state.journalList.splice(0, action.payload?.currentPage * 10),
        },
        initJournalList: {
          $set: state.initJournalList.splice(
            0,
            action.payload?.currentPage * 10
          ),
        },
        tablePagination: {
          $merge: { ...action.payload },
        },
      });
      return { ...newState };
    }

    case actionTypes.setSelectValue: {
      const name: 'filterDeviceIDValue' | 'filterEntryTypeValue' =
        action.payload?.name;
      const value = action.payload?.value;

      const newState = update(state, {
        filterEntryTypeValue: {
          $set: name === 'filterDeviceIDValue' ? '' : value,
        },
        filterDeviceIDValue: {
          $set: name === 'filterEntryTypeValue' ? '' : value,
        },
        journalList: { $set: [] },
        initJournalList: { $set: [] },
      });
      return { ...newState };
    }

    case actionTypes.clearSelectValue: {
      const name: 'filterDeviceIDValue' | 'filterEntryTypeValue' =
        action.payload?.name;

      const newState = update(state, {
        [name]: {
          $set: '',
        },
        journalList: { $set: [] },
        initJournalList: { $set: [] },
      });
      return { ...newState };
    }

    default:
      return { ...state };
  }
};

export interface AttributesTabStateType {
  formElements: AttributesCollectionType;
}

export const initAttributesTabState: AttributesTabStateType = {
  formElements: {
    attributeName: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: 'Key',
        name: 'attributeName',
      },
      value: '',
      validation: {
        // required: true,
      },
      valid: false,
      touched: false,
      errorMessage: 'Key is required',
      label: 'Key',
    },
    attributeValue: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: 'Value',
        name: 'attributeValue',
      },
      value: '',
      validation: {
        // required: true,
      },
      valid: false,
      touched: false,
      errorMessage: 'Value is required',
      label: 'Value',
    },
    uuid: '',
  },
};

export const attributesReducer = (
  state: AttributesTabStateType,
  action: ReducerHookActionType
) => {
  switch (action.type) {
    case actionTypes.setState: {
      const newState = update(state, {
        $merge: { ...action.payload },
      });
      return { ...newState };
    }

    case actionTypes.setAttributeInput: {
      const uuid = state.formElements.uuid ? state.formElements.uuid : v4();
      const newState = update(state, {
        formElements: { $set: { ...action.payload?.formElements, uuid } },
      });
      return { ...newState };
    }

    default:
      return { ...state };
  }
};
