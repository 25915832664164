import axios from 'axios';
import { Fragment, Component, FormEvent } from 'react';
import update from 'immutability-helper';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';

import { apiCall } from '../../../api-services/api';
import { groupApi, userApi } from '../../../api-services/api-list';
import {
  GroupUserListType,
  GroupAssignableUserListType,
  UserDataType,
  ApiResultType,
} from '../../../type-definitions/api-types';
import { handleNotification } from '../../../utils/notification-handler';
import { v4 } from 'uuid';
import AntdTable from '../../AntdTable';
import { Input } from 'antd';
import { handleSorting, handleTableSearch } from '../../../utils';
import { AntdTableColumnsType } from '../../../type-definitions';

type PropsType = {
  groupID: string;
  userData: Partial<UserDataType>;
  allowEdit: boolean;
};

type StateType = {
  userList: GroupUserListType[];
  assignableUserList: GroupAssignableUserListType[];
  loading: boolean;
  userListSearch: string;
  assignableUserListSearch: string;
};

class UsersTab extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      userList: [],
      assignableUserList: [],
      loading: true,
      userListSearch: '',
      assignableUserListSearch: '',
    };
  }

  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();
  initUserList: GroupUserListType[] = [];
  initAssignableUserList: GroupAssignableUserListType[] = [];

  componentDidMount() {
    this._isMounted = true;

    this.handleFetchedData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  handleState = (data: Partial<StateType>) => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          ...prevState,
          ...data,
        };
      });
  };

  handleFetchedData = async () => {
    const { userData, groupID } = this.props;

    let stateData: Partial<StateType> = {};
    if (groupID) {
      try {
        const { url, method, contentType } = groupApi.getGroupUsers(undefined, {
          groupID,
        });
        const response = await apiCall({
          storeToken: userData.token,
          url,
          method,
          contentType,
          cancelToken: this.axiosCancelSource.token,
        });
        const result: ApiResultType = response?.data;

        if (result?.status === 'ok') {
          if (result.data) {
            const tempData: GroupUserListType[] = result.data.map(
              (el: GroupUserListType) => ({
                ...el,
                uuid: v4(),
              })
            );
            stateData = update(stateData, {
              userList: { $set: [...tempData] },
            });
            this.initUserList = update(this.initUserList, {
              $set: [...tempData],
            });
          }
        } else {
          this._isMounted && handleNotification('error', result);
        }
      } catch (error: any) {
        this._isMounted && handleNotification('error', error.data);
      }

      try {
        const { url, method, contentType } = groupApi.getGroupAssignableUsers(
          undefined,
          { groupID }
        );

        const response = await apiCall({
          storeToken: userData.token,
          url,
          method,
          contentType,
          cancelToken: this.axiosCancelSource.token,
        });

        const result: ApiResultType = response?.data;

        if (result?.status === 'ok') {
          if (result.data) {
            const tempData: GroupAssignableUserListType[] = result.data.map(
              (el: GroupAssignableUserListType) => ({
                ...el,
                uuid: v4(),
              })
            );

            stateData = update(stateData, {
              assignableUserList: { $set: [...tempData] },
            });
            this.initAssignableUserList = update(this.initAssignableUserList, {
              $set: [...tempData],
            });
          }
        } else {
          this._isMounted && handleNotification('error', result);
        }
      } catch (error: any) {
        this._isMounted && handleNotification('error', error.data);
      }
    }

    stateData = update(stateData, { loading: { $set: false } });

    this.handleState(stateData);
  };

  handleDelete = async (userID: string) => {
    const { userData, groupID } = this.props;
    this.handleState({ loading: true });
    try {
      const { url, method, contentType } = userApi.deleteGroup(undefined, {
        userID,
        groupID,
      });
      const response = await apiCall({
        storeToken: userData.token,
        url,
        method,
        contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result: ApiResultType = response?.data;
      if (result?.status === 'ok') {
        this._isMounted && handleNotification('success', result);
        this.handleFetchedData();
      } else {
        this._isMounted && handleNotification('error', result);
      }
    } catch (error: any) {
      this._isMounted && handleNotification('error', error.data);
    }
  };

  handleAdd = async (userID: string) => {
    const { userData, groupID } = this.props;
    this.handleState({ loading: true });
    try {
      const { url, method, contentType } = userApi.putGroup(undefined, {
        userID,
        groupID,
      });
      const response = await apiCall({
        storeToken: userData.token,
        url,
        method,
        contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result: ApiResultType = response?.data;
      if (result?.status === 'ok') {
        this._isMounted && handleNotification('success', result);
        this.handleFetchedData();
      } else {
        this._isMounted && handleNotification('error', result);
      }
    } catch (error: any) {
      this._isMounted && handleNotification('error', error.data);
    }
  };

  handleAssignableUserSearch = (event: FormEvent<HTMLInputElement>) => {
    const value = event?.currentTarget?.value;

    if (value) {
      const columns = this.getAssignableUserColumns().map((el) => el.key);
      const sortedData = handleTableSearch({
        data: [...this.initAssignableUserList],
        columnList: columns,
        searchData: value,
      });

      this.handleState({
        assignableUserList: sortedData,
        assignableUserListSearch: value,
      });
    } else {
      this.handleState({
        assignableUserList: [...this.initAssignableUserList],
        assignableUserListSearch: value,
      });
    }
  };

  handleUserSearch = (event: FormEvent<HTMLInputElement>) => {
    const value = event?.currentTarget?.value;

    if (value) {
      const columns = this.getUserColumns().map((el) => el.key);
      const sortedData = handleTableSearch({
        data: [...this.initUserList],
        columnList: columns,
        searchData: value,
      });

      this.handleState({
        userList: sortedData,
        userListSearch: value,
      });
    } else {
      this.handleState({
        userList: [...this.initUserList],
        userListSearch: value,
      });
    }
  };

  getUserColumns = (): AntdTableColumnsType<GroupUserListType>[] => {
    const { allowEdit } = this.props;
    return [
      {
        title: 'User ID',
        dataIndex: 'userID',
        key: 'userID',
        sorter: (a: GroupUserListType, b: GroupUserListType) =>
          handleSorting(a.userID, b.userID),
        defaultSortOrder: 'ascend',
      },
      {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
        sorter: (a: GroupUserListType, b: GroupUserListType) =>
          handleSorting(a.username, b.username),
      },
      {
        title: 'User Level',
        dataIndex: 'level',
        key: 'level',
        sorter: (a: GroupUserListType, b: GroupUserListType) =>
          handleSorting(a.level, b.level),
      },
      {
        title: 'Partner ID',
        dataIndex: 'partnerID',
        key: 'partnerID',
        sorter: (a: GroupUserListType, b: GroupUserListType) =>
          handleSorting(a.partnerID, b.partnerID),
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record: GroupUserListType) => (
          <Fragment>
            {allowEdit && (
              <FaTrashAlt
                style={{ color: 'red', cursor: 'pointer' }}
                onClick={() => this.handleDelete(record.userID)}
              />
            )}
          </Fragment>
        ),
      },
    ];
  };

  getAssignableUserColumns =
    (): AntdTableColumnsType<GroupAssignableUserListType>[] => {
      const { allowEdit } = this.props;
      return [
        {
          title: 'User ID',
          dataIndex: 'uid',
          key: 'uid',
          sorter: (
            a: GroupAssignableUserListType,
            b: GroupAssignableUserListType
          ) => handleSorting(a.uid, b.uid),
          defaultSortOrder: 'ascend',
        },
        {
          title: 'Username',
          dataIndex: 'username',
          key: 'username',
          sorter: (
            a: GroupAssignableUserListType,
            b: GroupAssignableUserListType
          ) => handleSorting(a.username, b.username),
        },
        {
          title: 'User Level',
          dataIndex: 'level',
          key: 'level',
          sorter: (
            a: GroupAssignableUserListType,
            b: GroupAssignableUserListType
          ) => handleSorting(a.level, b.level),
        },
        {
          title: 'Partner ID',
          dataIndex: 'partnerid',
          key: 'partnerid',
          sorter: (
            a: GroupAssignableUserListType,
            b: GroupAssignableUserListType
          ) => handleSorting(a.partnerid, b.partnerid),
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, record: GroupAssignableUserListType) => (
            <Fragment>
              {allowEdit && (
                <FaPlus
                  style={{ cursor: 'pointer', color: 'green' }}
                  onClick={() => this.handleAdd(record.uid)}
                />
              )}
            </Fragment>
          ),
        },
      ];
    };

  render() {
    const {
      loading,
      userList,
      assignableUserList,
      userListSearch,
      assignableUserListSearch,
    } = this.state;

    return (
      <Fragment>
        <div className="row pt-3">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-6">
                <strong
                  style={{
                    fontSize: `1.25rem`,
                  }}>{`Current Users`}</strong>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 offset-md-8">
                <Input
                  value={userListSearch}
                  onChange={this.handleUserSearch}
                />
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-lg-12">
                <AntdTable
                  loading={loading}
                  columns={this.getUserColumns()}
                  dataSource={userList}
                  pagination={{
                    pageSize: 5,
                    total: userList.length,
                  }}
                  customConfig={{ rowKeyValue: 'uuid' }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row py-5">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-6">
                <strong
                  style={{
                    fontSize: `1.25rem`,
                  }}>{`Other Users`}</strong>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 offset-md-8">
                <Input
                  value={assignableUserListSearch}
                  onChange={this.handleAssignableUserSearch}
                />
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-lg-12">
                <AntdTable
                  loading={loading}
                  columns={this.getAssignableUserColumns()}
                  dataSource={assignableUserList}
                  pagination={{
                    pageSize: 5,
                    total: assignableUserList.length,
                  }}
                  customConfig={{ rowKeyValue: 'uuid' }}
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default UsersTab;
