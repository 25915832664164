import { Fragment, Component, FormEvent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Button, Col, message, Row } from 'antd';
import moment from 'moment';
import update from 'immutability-helper';

import {
  FormElementsType,
  initialElements,
  StateType,
} from '../components/GroupAdd/helpers';
import { updateToken } from '../redux/actions';
import { PartnerListType, UserDataType } from '../type-definitions/api-types';
import { getUtcTime } from '../utils';
import axios from 'axios';
import { groupRoutes } from '../Routes/routes-list';
import { apiCall } from '../api-services/api';
import { groupApi, partnerApi } from '../api-services/api-list';
import { SelectOptionsType } from '../type-definitions';
import { checkValidation } from '../utils/validation';
import FormWrapper from '../components/FormWrapper';
import CheckboxListModal from '../components/CheckboxListModal';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import {
  getMultipleInputElements,
  getSingleInputElement,
} from '../utils/get-input-element';
import { groupRights } from '../utils/permission-list';
import { handleNotification } from '../utils/notification-handler';
import PromptPopup from '../components/PromptPopup';

const currentDate = getUtcTime({ date: new Date(), format: 'date' });

interface PropsType extends RouteComponentProps, PropsFromRedux {
  userData: Partial<UserDataType>;
  userPermissionList: string[];
}

class GroupAdd extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      formElements: {
        ...initialElements,
        minDate: {
          ...initialElements.minDate,
          value: currentDate,
        },
        maxDate: {
          ...initialElements.maxDate,
          value: currentDate,
        },
        phenomList: {
          ...initialElements.phenomList,
          onInputClick: this.handlePhenomModal,
          styles: { cursor: 'pointer' },
        },
        extendedPermission: {
          ...initialElements.extendedPermission,
          onInputClick: this.handleExtendedPermissionModal,
          styles: { cursor: 'pointer' },
        },
        deviceManagementPermission: {
          ...initialElements.deviceManagementPermission,
          onInputClick: this.handleDeviceManagementPermissionModal,
          styles: { cursor: 'pointer' },
        },
      },
      loading: true,
      showExtendedPermissionModal: false,
      initExtendedPermissionCollection: {},
      extendedPermissionCollection: {},
      showPhenomModal: false,
      initPhenomCollection: {},
      phenomCollection: {},
      showDeviceManagementPermissionModal: false,
      initDeviceManagementCollection: {},
      deviceManagementCollection: {},

      hasInputChanged: false,
    };
  }

  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    this._isMounted = true;
    this.handleFetchedData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  handleState = (data: Partial<StateType>, callback?: () => void) => {
    this._isMounted &&
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            ...data,
          };
        },
        () => {
          callback?.();
        }
      );
  };

  handleFetchedData = async () => {
    const { updateToken, userData } = this.props;
    try {
      const { url, method, params, contentType } = partnerApi.getPartners({
        right: 'CreateGroups',
      });
      const response = await apiCall({
        storeToken: userData?.token,
        url,
        method,
        params,
        contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      updateToken(result);
      if (result.status === 'ok') {
        const { formElements } = this.state;
        let tempFormElements = { ...formElements };
        const data: PartnerListType[] = result.data;
        if (data) {
          let partnerData: PartnerListType[] = [];
          if (data.length > 0) {
            partnerData = data.filter((el) =>
              el.rights.includes(groupRights.create)
            );
          }

          if (partnerData.length > 1) {
            const tempPartner: SelectOptionsType[] = [];
            partnerData.forEach((item) => {
              tempPartner.push({
                value: item.partnerID,
                text: item.partnerName,
              });
            });

            tempFormElements = update(tempFormElements, {
              partnerName: { optionValues: { $set: [...tempPartner] } },
            });

            const selected = partnerData.find(
              (item) => item?.partnerID === 'AIRSENSA'
            );
            if (selected) {
              tempFormElements = update(tempFormElements, {
                partnerName: { value: { $set: selected.partnerID } },
              });

              this.fetchListData({
                partnerID: selected.partnerID,
                formElements: tempFormElements,
              });
            } else {
              tempFormElements = update(tempFormElements, {
                partnerName: { value: { $set: tempPartner[0].value } },
              });
              this.fetchListData({
                partnerID: tempPartner[0].value,
                formElements: tempFormElements,
              });
            }
          } else if (partnerData.length === 1) {
            tempFormElements = update(tempFormElements, {
              partnerName: {
                elementType: { $set: 'input' },
                elementConfig: {
                  type: { $set: 'text' },
                  placeholder: { $set: 'Partner Name' },
                },
                disabled: { $set: true },
                value: { $set: partnerData[0].partnerID },
              },
            });

            this.fetchListData({
              partnerID: partnerData[0].partnerID,
              formElements: tempFormElements,
            });
          } else {
            this._isMounted && this.setState({ loading: false });
          }
        } else {
          this._isMounted && this.setState({ loading: false });
        }
      } else {
        this._isMounted && handleNotification('error', result);
        this._isMounted && this.setState({ loading: false });
      }
    } catch (error) {
      this._isMounted && handleNotification('error', error?.data);
      this._isMounted && this.setState({ loading: false });
    }
  };

  fetchListData = async ({
    partnerID,
    formElements,
  }: {
    partnerID: string;
    formElements: FormElementsType;
  }) => {
    const { updateToken, userData } = this.props;
    const permission = partnerApi.getPartnerPermissions(
      { type: 'extended' },
      { partnerID }
    );
    const response = await apiCall({
      storeToken: userData.token,
      url: permission.url,
      method: permission.method,
      params: permission.params,
      contentType: permission.contentType,
      cancelToken: this.axiosCancelSource.token,
    });
    const result = response?.data;
    const phenoms = partnerApi.getPartnerPhenoms(undefined, { partnerID });
    const response2 = await apiCall({
      storeToken: userData?.token,
      url: phenoms.url,
      method: phenoms.method,
      contentType: phenoms.contentType,
      cancelToken: this.axiosCancelSource.token,
    });
    const result2 = response2?.data;
    const permission2 = partnerApi.getPartnerPermissions(
      { type: 'management' },
      { partnerID }
    );
    const response3 = await apiCall({
      storeToken: userData.token,
      url: permission2.url,
      method: permission2.method,
      params: permission2.params,
      contentType: permission2.contentType,
      cancelToken: this.axiosCancelSource.token,
    });
    const result3 = response3?.data;
    updateToken(result);
    updateToken(result2);
    updateToken(result3);

    const stateData: Partial<StateType> = {};

    if (result.status === 'ok' && result.data) {
      const tempExtenedRights: string[] = result.data;
      const tempRights: { [k: string]: boolean } = {};
      tempExtenedRights.forEach((item) => {
        tempRights[item] = false;
      });
      stateData.extendedPermissionCollection = tempRights;
      stateData.initExtendedPermissionCollection = tempRights;
    }

    if (result2.status === 'ok' && result2.data) {
      const tempPhenomsData: {
        id: string;
        name: string;
        units: string;
        description: string;
        type: string;
      }[] = result2.data;

      const tempPhenoms: { [k: string]: boolean } = {};
      tempPhenomsData.forEach((item) => {
        tempPhenoms[item.id] = false;
      });
      stateData.phenomCollection = tempPhenoms;
      stateData.initPhenomCollection = tempPhenoms;
    }

    if (result3.status === 'ok' && result3.data) {
      const tempManagementRights: string[] = result3.data;
      const tempDeviceMng: { [k: string]: boolean } = {};
      tempManagementRights.forEach((item) => {
        tempDeviceMng[item] = false;
      });
      stateData.deviceManagementCollection = tempDeviceMng;
      stateData.initDeviceManagementCollection = tempDeviceMng;
    }

    this._isMounted &&
      this.setState({
        loading: false,
        formElements,
        deviceManagementCollection: stateData.deviceManagementCollection || {},
        initDeviceManagementCollection:
          stateData.initDeviceManagementCollection || {},
        phenomCollection: stateData.phenomCollection || {},
        initPhenomCollection: stateData.initPhenomCollection || {},
        extendedPermissionCollection:
          stateData.extendedPermissionCollection || {},
        initExtendedPermissionCollection:
          stateData.initExtendedPermissionCollection || {},
      });
  };

  inputChangedHandler = (name: keyof FormElementsType, value: string) => {
    const { formElements } = this.state;
    let tempFormElements = { ...formElements };

    if (name) {
      if (
        name === 'maxDaysFromToday' &&
        !checkValidation(value, { isNumeric: true })
      ) {
        message.error('Please enter valid input');
        return;
      }

      if (name !== 'allPhenoms') {
        tempFormElements = update(tempFormElements, {
          [name]: {
            value: { $set: value },
            touched: { $set: true },
            valid: {
              $set: checkValidation(value, tempFormElements[name].validation),
            },
          },
        });
      }

      if (name === 'partnerName' && value) {
        this._isMounted &&
          this.setState({ formElements: tempFormElements }, () => {
            this.fetchListData({
              partnerID: value,
              formElements: tempFormElements,
            });
          });
      } else {
        if (name === 'minDate' || name === 'maxDate') {
          if (
            moment(tempFormElements.minDate.value).unix() >
            moment(tempFormElements.maxDate.value).unix()
          ) {
            tempFormElements = update(tempFormElements, {
              maxDate: {
                touched: { $set: true },
                valid: { $set: false },
                errorMessage: {
                  $set: `Please select a 'To' date that is a after 'From' date`,
                },
              },
            });
          } else {
            tempFormElements = update(tempFormElements, {
              maxDate: {
                valid: { $set: true },
              },
            });
          }
        }
        this._isMounted &&
          this.setState({
            formElements: tempFormElements,
            hasInputChanged: true,
          });
      }
    }
  };

  onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    this.handleSubmit();
  };

  handleSubmit = async (onConfirm?: () => void) => {
    const { updateToken, userData } = this.props;
    const {
      formElements,
      phenomCollection,
      extendedPermissionCollection,
      deviceManagementCollection,
    } = this.state;

    let tempFormElements = { ...formElements };
    let key: keyof typeof tempFormElements;

    for (key in tempFormElements) {
      if (key !== 'allPhenoms') {
        tempFormElements = update(tempFormElements, {
          [key]: {
            touched: { $set: true },
            valid: {
              $set: checkValidation(
                tempFormElements[key].value,
                tempFormElements[key].validation
              ),
            },
          },
        });
      }
    }

    if (
      moment(tempFormElements.minDate.value).unix() >
      moment(tempFormElements.maxDate.value).unix()
    ) {
      tempFormElements = update(tempFormElements, {
        maxDate: {
          touched: { $set: true },
          valid: { $set: false },
          errorMessage: {
            $set: `Please select a 'To' date that is a after 'From' date`,
          },
        },
      });

      this._isMounted &&
        handleNotification('error', {
          message: `'To' date should be greater than 'From' date`,
        });
    } else {
      tempFormElements = update(tempFormElements, {
        maxDate: {
          valid: { $set: true },
        },
      });
    }

    this.handleState({
      formElements: tempFormElements,
    });

    for (key in tempFormElements) {
      if (key !== 'allPhenoms' && !tempFormElements[key].valid) {
        message.error('Please fill all the fields');
        return;
      }
    }

    const tempData = {
      maxdaysfromtoday: '',
      mindate: '',
      maxdate: '',
    };
    if (tempFormElements.dateTimeRadio.value === 'dateAccess') {
      const maxDateVal = tempFormElements.maxDate.value
        ? getUtcTime({
            date: `${tempFormElements.maxDate.value} ${tempFormElements.maxTime.value}`,
          })
        : '';
      const minDateVal = tempFormElements.minDate.value
        ? getUtcTime({
            date: `${tempFormElements.minDate.value} ${tempFormElements.minTime.value}`,
          })
        : '';

      tempData.mindate = minDateVal;
      tempData.maxdate = maxDateVal;
    } else if (tempFormElements.dateTimeRadio.value === 'rollingAccess') {
      tempData.maxdaysfromtoday = tempFormElements.maxDaysFromToday.value;
    } else {
      tempData.maxdaysfromtoday = '-1';
    }

    const data = {
      group_id: tempFormElements.groupID.value,
      group_name: tempFormElements.groupName.value,
      grouptype: tempFormElements.groupType.value,
      partner_id: tempFormElements.partnerName.value,
      allPhenoms: tempFormElements.allPhenoms,
      ...tempData,
    };

    const tempFormData = [];
    const phenomlist: string[] = [];
    const extendedpermissions: string[] = [];
    const devicemanagementpermissions: string[] = [];
    let key2: keyof typeof data;
    for (key2 in data) {
      let encodedValue: string | null = encodeURIComponent(data[key2]);
      if (
        (key2 === 'maxdate' ||
          key2 === 'mindate' ||
          key2 === 'maxdaysfromtoday') &&
        !data[key2]
      ) {
        encodedValue = null;
      }
      tempFormData.push(key2 + '=' + encodedValue);
    }
    for (const key3 in phenomCollection) {
      if (phenomCollection[key3] === true) {
        phenomlist.push(key3);
      }
    }
    for (const key3 in extendedPermissionCollection) {
      if (extendedPermissionCollection[key3] === true) {
        extendedpermissions.push(key3);
      }
    }
    for (const key3 in deviceManagementCollection) {
      if (deviceManagementCollection[key3] === true) {
        devicemanagementpermissions.push(key3);
      }
    }
    tempFormData.push('phenomlist=' + JSON.stringify(phenomlist));
    tempFormData.push(
      'extendedpermissions=' + JSON.stringify(extendedpermissions)
    );
    tempFormData.push(
      'devicemanagementpermissions=' +
        JSON.stringify(devicemanagementpermissions)
    );
    const formData: string = tempFormData.join('&');

    this.handleState({ loading: true });
    try {
      const { url, method, contentType } = groupApi.postGroup(
        undefined,
        undefined,
        'formUrlEncoded'
      );
      const response = await apiCall({
        storeToken: userData.token,
        url,
        method,
        contentType,
        cancelToken: this.axiosCancelSource.token,
        data: formData,
      });
      const result = response?.data;
      updateToken(result);
      if (result.status === 'ok') {
        this._isMounted && handleNotification('success', result);
        this.handleState({ loading: false, hasInputChanged: false }, () => {
          const { history } = this.props;
          setTimeout(() => {
            history.push({
              pathname: groupRoutes.details(),
              state: {
                groupID: result?.data?.groupID,
                allowEdit: true,
              },
            });
          }, 1000);
        });
      } else {
        this._isMounted && handleNotification('error', result);
        this.handleState({ loading: false });
      }
    } catch (error) {
      this._isMounted && handleNotification('error', error?.data);
      this.handleState({ loading: false });
    }
  };

  handlePopupConfirm = (onConfirm: () => void) => {
    this.handleSubmit(onConfirm);
  };

  handleCancelRedirect = () => {
    const { history } = this.props;
    history.push({
      pathname: groupRoutes.list,
    });
  };

  handlePhenomModal = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showPhenomModal: !prevState.showPhenomModal,
      };
    });
  };

  handleExtendedPermissionModal = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showExtendedPermissionModal: !prevState.showExtendedPermissionModal,
      };
    });
  };

  handleDeviceManagementPermissionModal = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showDeviceManagementPermissionModal:
          !prevState.showDeviceManagementPermissionModal,
      };
    });
  };

  handleListData = <T extends keyof StateType>(
    listData: { [k: string]: boolean },
    checkboxListName?: string,
    modalKeyName?: string,
    inputFieldName?: keyof FormElementsType
  ) => {
    const { formElements } = this.state;

    const stateData: { [k: string]: any } = {};

    if (checkboxListName) {
      stateData[checkboxListName] = listData;
    }

    if (modalKeyName) {
      stateData[modalKeyName] = false;
    }

    if (inputFieldName && inputFieldName !== 'allPhenoms') {
      const tempData = [];
      for (const key in listData) {
        if (listData[key]) {
          tempData.push(key);
        }
      }
      formElements[inputFieldName].value = tempData
        .map((item) => `${item}`)
        .join(', ');

      stateData.formElements = formElements;
    }

    this._isMounted && this.setState(stateData as { [P in T]: StateType[P] });
  };

  onCheckboxChange = (event: CheckboxChangeEvent) => {
    const name = event?.target?.name;
    const checked = event?.target?.checked;
    const { formElements } = this.state;

    if (name) {
      let tempFormElements = update(formElements, {
        [name]: { $set: checked },
      });

      if (name === 'allPhenoms') {
        tempFormElements = update(tempFormElements, {
          phenomList: { disabled: { $set: checked } },
        });
      }

      this.handleState({ formElements: tempFormElements });
    }
  };

  getInputElements = () => {
    const { formElements } = this.state;

    const { allPhenoms, ...restFormElements } = formElements;

    const multiple = getMultipleInputElements({
      formElements: { ...restFormElements },
      inputChangedHandler: this.inputChangedHandler,
      sliceValue: [1, 3],
    });

    const multiple2 = getMultipleInputElements({
      formElements: { ...restFormElements },
      inputChangedHandler: this.inputChangedHandler,
      sliceValue: [3, 4],
    });

    const single = getSingleInputElement({
      formElements: { ...restFormElements },
      inputChangedHandler: this.inputChangedHandler,
      sliceValue: [4, 5],
    });

    const multiple3 = getMultipleInputElements({
      formElements: { ...restFormElements },
      inputChangedHandler: this.inputChangedHandler,
      sliceValue: [5, 7],
    });

    const multiple4 = getMultipleInputElements({
      formElements: { ...restFormElements },
      inputChangedHandler: this.inputChangedHandler,
      sliceValue: [7, 9],
    });

    const multiple5 = getMultipleInputElements({
      formElements: { ...restFormElements },
      inputChangedHandler: this.inputChangedHandler,
      sliceValue: [9, 10],
    });

    const multiple6 = getMultipleInputElements({
      formElements: { ...restFormElements },
      inputChangedHandler: this.inputChangedHandler,
      sliceValue: [10, 11],
    });

    const multiple7 = getMultipleInputElements({
      formElements: { ...restFormElements },
      inputChangedHandler: this.inputChangedHandler,
      sliceValue: [11, 12],
    });

    const multiple8 = getMultipleInputElements({
      formElements: { ...restFormElements },
      inputChangedHandler: this.inputChangedHandler,
      sliceValue: [12, 13],
    });

    let dateTimeElem = (
      <Fragment>
        {multiple3}
        {multiple4}
      </Fragment>
    );

    if (formElements.dateTimeRadio.value === 'rollingAccess') {
      dateTimeElem = multiple5;
    }
    if (formElements.dateTimeRadio.value === 'unlimitedAccess') {
      dateTimeElem = <Fragment></Fragment>;
    }

    return (
      <Fragment>
        {multiple}
        {multiple2}
        {single}
        {dateTimeElem}
        <Row className="pb-3 pt-1">
          <Col>
            <Checkbox
              name="allPhenoms"
              checked={allPhenoms}
              onChange={this.onCheckboxChange}>
              All Phenoms
            </Checkbox>
          </Col>
        </Row>
        {multiple6}
        {multiple7}
        {multiple8}
      </Fragment>
    );
  };

  render() {
    const {
      loading,
      showExtendedPermissionModal,
      extendedPermissionCollection,
      showPhenomModal,
      phenomCollection,
      deviceManagementCollection,
      showDeviceManagementPermissionModal,
      hasInputChanged,
    } = this.state;

    const { userPermissionList } = this.props;

    if (userPermissionList.length === 0) {
      return <Redirect to="/" />;
    }

    const inputElem = this.getInputElements();

    return (
      <Fragment>
        {hasInputChanged && (
          <PromptPopup
            when={hasInputChanged}
            handleConfirm={this.handlePopupConfirm}
          />
        )}
        <FormWrapper loading={loading} title="Group Add">
          <form noValidate onSubmit={this.onFormSubmit}>
            {inputElem}

            <div className="row justify-content-center">
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4">
                <Button type="primary" htmlType="submit" block size="large">
                  Submit
                </Button>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 btn-mobile-padding-top">
                <Button
                  type="primary"
                  htmlType="button"
                  block
                  size="large"
                  onClick={this.handleCancelRedirect}>
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </FormWrapper>

        {showPhenomModal && (
          <CheckboxListModal
            checkboxListData={phenomCollection}
            handleListData={this.handleListData}
            isDisabled={false}
            showModal={showPhenomModal}
            handleModal={this.handlePhenomModal}
            checkboxListName={`phenomCollection`}
            modalKeyName={`showPhenomModal`}
            inputFieldName={`phenomList`}
          />
        )}

        {showExtendedPermissionModal && (
          <CheckboxListModal
            checkboxListData={extendedPermissionCollection}
            handleListData={this.handleListData}
            isDisabled={false}
            showModal={showExtendedPermissionModal}
            handleModal={this.handleExtendedPermissionModal}
            checkboxListName={`extendedPermissionCollection`}
            modalKeyName={`showExtendedPermissionModal`}
            inputFieldName={`extendedPermission`}
          />
        )}

        {showDeviceManagementPermissionModal && (
          <CheckboxListModal
            checkboxListData={deviceManagementCollection}
            handleListData={this.handleListData}
            isDisabled={false}
            showModal={showDeviceManagementPermissionModal}
            handleModal={this.handleDeviceManagementPermissionModal}
            checkboxListName={`deviceManagementCollection`}
            modalKeyName={`showDeviceManagementPermissionModal`}
            inputFieldName={`deviceManagementPermission`}
          />
        )}
      </Fragment>
    );
  }
}

const mapDispatch = {
  updateToken: updateToken,
};
const connector = connect(null, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(GroupAdd);
