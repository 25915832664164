import React, { Fragment, ReactNode } from 'react';
import Input from '../components/AntdInput';
import { FormInputObjectType } from '../type-definitions';

export function getInputElementsSingle({
  formElements,
  inputChangedHandler,
  sliceValue,
  colClasses,
  rowClasses,
}: {
  formElements: FormInputObjectType;
  inputChangedHandler: (name: any, value: any) => void;
  sliceValue: number[];
  colClasses?: string;
  rowClasses?: string;
}) {
  let formElementsArray = [];

  for (const key in formElements) {
    formElementsArray.push({
      id: key,
      config: formElements[key],
    });
  }

  formElementsArray = formElementsArray.slice(...sliceValue);
  const inputElements = formElementsArray.map((item) => {
    return (
      <Fragment key={item.id}>
        <div
          className={`row align-items-center ${rowClasses ? rowClasses : ''}`}>
          <div
            className={`col-xl-${item.config.colValue || 12} ${
              colClasses ? colClasses : ''
            }`}>
            <Input {...item.config} onInputChanged={inputChangedHandler} />
          </div>
        </div>
      </Fragment>
    );
  });

  return inputElements;
}

export function getInputElementsMultiple({
  formElements,
  inputChangedHandler,
  sliceValue,
  optionalElement,
}: {
  formElements: FormInputObjectType;
  inputChangedHandler: (name: any, value: any) => void;
  sliceValue: number[];
  optionalElement?: ReactNode;
}) {
  let formElementsArray = [];

  for (const key in formElements) {
    formElementsArray.push({
      id: key,
      config: formElements[key],
    });
  }
  formElementsArray = formElementsArray.slice(...sliceValue);
  const inputElements = (
    <Fragment>
      <div className="row align-items-center">
        {formElementsArray.map((item) => {
          return (
            <Fragment key={item.id}>
              <div className={`col-xl-${item.config.colValue || 6}`}>
                <Input {...item.config} onInputChanged={inputChangedHandler} />
              </div>
            </Fragment>
          );
        })}
        {optionalElement || null}
      </div>
    </Fragment>
  );

  return inputElements;
}
