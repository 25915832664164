import { Fragment, createRef, Component } from 'react';
import {
  MapContainer,
  Marker,
  Popup,
  ImageOverlay,
  FeatureGroup,
} from 'react-leaflet';
import L from 'leaflet';
import { Button, Col, message, Row } from 'antd';
import update from 'immutability-helper';
import { EditControl } from 'react-leaflet-draw';
import intersect from '@turf/intersect';
import booleanContains from '@turf/boolean-contains';
import booleanWithin from '@turf/boolean-within';

// import update from 'immutability-helper';

// import 'leaflet-path-drag';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

import { cloneDeep } from '../../../utils/lodash-libs';
import {
  AxiosHttpAllSettledResponsesType,
  FloorPlanLocationListType,
  LocationListType,
} from '../../../type-definitions/api-types';
import {
  getBoundsUnproject,
  getLeafletIcon,
  getMapPoint,
} from '../../../utils/leaflet-helpers';
import {
  GeoJSONFeaturesType,
  GeoJSONType,
  MapDetailsType,
} from '../../../type-definitions';
import {
  httpCallAllSettled,
  httpCallErrorHandling,
} from '../../../api-services/api';
import { floorPlanApi } from '../../../api-services/api-list';
import { handleNotification } from '../../../utils/notification-handler';

interface PropsType {
  mapDetails: MapDetailsType;
  floorPlanLocationList: FloorPlanLocationListType[];
  selectedLocation: Partial<LocationListType>;
  classes?: string;
  onUpdateLocation: (data: FloorPlanLocationListType) => void;
  onRemoveLocation: (data: FloorPlanLocationListType) => void;
  setMapDefaultParameters: (
    defaultPosition: L.LatLng,
    defaultCoordinates: { x: number; y: number }
  ) => void;
  isEditable: boolean;
  selectedFloorPlanFromTable?: Partial<FloorPlanLocationListType>;
  updateFloorPlansInMap: boolean;
  addRegionClickedLocId: string;
  onAddRegionBtnClicked: (id: string) => void;
  floorPlanId: string;
  token: string;
}

interface StateType {
  // currentPosition: { lat?: number; lng?: number };
  currentPosition: Partial<L.LatLng>;
  mapBounds?: L.LatLngBounds;
  floorPlanLocations: FloorPlanLocationListType[];
  movedFloorPlanLocations: FloorPlanLocationListType[];
  selectedLoc: Partial<LocationListType>;
  mapInstance?: L.Map;
  selectedDataFromTable: Partial<FloorPlanLocationListType>;
  addRegionBtnLoc: string;
}

// const customCRS = (L as any).extend({}, L.CRS, {
//   projection: L.Projection.LonLat,
//   transformation: new L.Transformation(1, 0, 1, 0),
// });

const customCRS = L.CRS.Simple;

const unprojectZoom = 4;

export default class FloorPlanMapMultiple extends Component<
  PropsType,
  StateType
> {
  _isMounted = false;
  markerRef = createRef<L.Marker<any>>();
  _editableFG: any;
  _outerPolygon: GeoJSONType = { type: 'FeatureCollection' };
  _innerPolygon: GeoJSONType = { type: 'FeatureCollection' };
  _floorPlanLocations: FloorPlanLocationListType[] = [];
  _region: { _outerPolygon: GeoJSONType; _innerPolygon: GeoJSONType } = {
    _outerPolygon: { type: 'FeatureCollection' },
    _innerPolygon: { type: 'FeatureCollection' },
  };

  constructor(props: PropsType) {
    super(props);

    this.state = {
      currentPosition: {},
      mapBounds: undefined,
      floorPlanLocations: [],
      movedFloorPlanLocations: [],
      selectedLoc: {},
      mapInstance: undefined,
      selectedDataFromTable: {},
      addRegionBtnLoc: '',
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps: PropsType, prevState: StateType) {
    const {
      selectedLocation,
      floorPlanLocationList,
      selectedFloorPlanFromTable,
      updateFloorPlansInMap,
      mapDetails,
      setMapDefaultParameters,
      addRegionClickedLocId,
    } = this.props;
    const {
      floorPlanLocations,
      mapInstance,
      selectedDataFromTable,
      addRegionBtnLoc,
    } = this.state;

    const center = mapInstance?.getCenter?.();
    if (addRegionClickedLocId !== addRegionBtnLoc) {
      this._onChangeHandlePolygon();
      if (this._editableFG) {
        this._onFeatureGroupReady(this._editableFG);
      }
      this.setState((prev) => ({
        ...prev,
        addRegionBtnLoc: addRegionClickedLocId,
      }));
    }

    if (center && !mapDetails?.defaultCoordinates) {
      let tempCoordinates = mapInstance?.project(center, unprojectZoom);
      const defaultCoordinates = {
        x: tempCoordinates?.x,
        y: tempCoordinates?.y,
      };

      if (defaultCoordinates?.x && defaultCoordinates?.y) {
        setMapDefaultParameters(center, {
          x: defaultCoordinates?.x,
          y: defaultCoordinates?.y,
        });
      }
    }

    if (updateFloorPlansInMap && mapInstance) {
      this.handleMap(mapInstance);
    }

    if (
      prevProps.selectedLocation &&
      selectedLocation &&
      prevProps.selectedLocation.locationID !== selectedLocation.locationID
    ) {
      this._isMounted && this.setState({ selectedLoc: selectedLocation });
    }

    if (
      floorPlanLocationList.length !== floorPlanLocations.length &&
      mapInstance
    ) {
      this.handleMap(mapInstance);
    }

    if (
      selectedDataFromTable?.locationID !==
        selectedFloorPlanFromTable?.locationID ||
      selectedDataFromTable?.isClickedFromTable !==
        selectedFloorPlanFromTable?.isClickedFromTable
    ) {
      const matched = floorPlanLocations.find(
        (el) => el.locationID === selectedFloorPlanFromTable?.locationID
      );

      if (matched) {
        matched.isClickedFromTable =
          selectedFloorPlanFromTable?.isClickedFromTable;

        let tempFloorPlans = floorPlanLocations.filter(
          (el) => el.locationID !== matched.locationID
        );

        tempFloorPlans = update(tempFloorPlans, { $push: [matched] });
        this._floorPlanLocations = cloneDeep(tempFloorPlans);
        this.handleState({
          floorPlanLocations: tempFloorPlans,
          selectedDataFromTable: selectedFloorPlanFromTable,
        });
      }
    }
  }

  handleState = (data: Partial<StateType>, callback?: () => void) => {
    this._isMounted &&
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            ...data,
          };
        },
        () => {
          callback?.();
        }
      );
  };

  handleMap = (map: L.Map) => {
    const { mapDetails, floorPlanLocationList } = this.props;

    if (mapDetails.height && mapDetails.width && map) {
      const bounds = getBoundsUnproject(
        map,
        mapDetails.height,
        mapDetails.width,
        unprojectZoom
      );

      if (bounds) {
        map.fitBounds(bounds);
      }

      const tempFloorPlanLocationList = cloneDeep(floorPlanLocationList);

      const temp = tempFloorPlanLocationList.map((item) => {
        const point = getMapPoint({
          x: item?.x,
          y: item?.y,
        });
        const position = map.unproject(point, unprojectZoom);

        item = {
          ...item,
          position: {
            ...item.position,
            lat: position?.lat || 0,
            lng: position?.lng || 0,
          },
          region: { ...item.region },
        };

        return { ...item };
      });
      this._floorPlanLocations = cloneDeep(temp);
      this._isMounted &&
        this.setState({
          floorPlanLocations: temp,
          mapBounds: bounds,
          mapInstance: map,
        });
    }
  };

  _onChangeHandlePolygon = () => {
    const { mapInstance } = this.state;
    const { addRegionClickedLocId } = this.props;
    if (this._floorPlanLocations.length > 0 && mapInstance) {
      if (
        this._innerPolygon.features &&
        (this._innerPolygon.features.length === 0 ||
          this._innerPolygon.features.length > 0)
      ) {
        // const inner = new L.GeoJSON<GeoJSONType>(this._innerPolygon);
        // inner.eachLayer((layer) => {
        //   mapInstance.removeLayer(layer);
        // });

        mapInstance.eachLayer((layer: any) => {
          if (layer?.feature?.regionType === 'inner') {
            mapInstance.removeLayer(layer);
          }
        });

        this._innerPolygon = update(this._innerPolygon, {
          features: { $set: undefined },
        });
      }

      if (
        this._outerPolygon.features &&
        (this._outerPolygon.features.length === 0 ||
          this._outerPolygon.features.length > 0)
      ) {
        // const outer = new L.GeoJSON<GeoJSONType>(this._outerPolygon);
        // outer.eachLayer((layer) => {
        //   mapInstance.removeLayer(layer);
        // });

        mapInstance.eachLayer((layer: any) => {
          if (layer?.feature?.regionType === 'outer') {
            mapInstance.removeLayer(layer);
          }
        });

        this._outerPolygon = update(this._outerPolygon, {
          features: { $set: undefined },
        });
      }

      let outerFeatures: GeoJSONFeaturesType[] = [];
      let innerFeatures: GeoJSONFeaturesType[] = [];
      this._floorPlanLocations.forEach((el) => {
        if (
          el.locationID === addRegionClickedLocId &&
          el.region?.outerPolygon?.features &&
          el.region.outerPolygon.features.length > 0
        ) {
          outerFeatures = [
            ...outerFeatures,
            ...el.region.outerPolygon.features,
          ];
        }
        if (
          el.locationID === addRegionClickedLocId &&
          el.region?.innerPolygons?.features &&
          el.region.innerPolygons.features.length > 0
        ) {
          innerFeatures = [
            ...innerFeatures,
            ...el.region.innerPolygons.features,
          ];
        }
      });

      if (outerFeatures && outerFeatures.length > 0) {
        this._outerPolygon.features = outerFeatures;
      }

      if (innerFeatures && innerFeatures.length > 0) {
        this._innerPolygon.features = innerFeatures;
      }
    }
  };

  onMoveMarker = (
    event: L.DragEndEvent,
    location: FloorPlanLocationListType
  ) => {
    const { movedFloorPlanLocations, mapInstance } = this.state;
    // this.markerRef.current?.getLatLng();
    const tempLatLng = event?.target?.getLatLng?.();

    const position = {
      lat: tempLatLng.lat,
      lng: tempLatLng.lng,
    };

    const coordinates = mapInstance?.project(tempLatLng, unprojectZoom);

    let temp: FloorPlanLocationListType[] = [];
    if (movedFloorPlanLocations.length > 0 && coordinates?.x) {
      temp = cloneDeep(movedFloorPlanLocations);
      const matched = temp.find((el) => el.locationID === location?.locationID);
      if (matched) {
        temp = temp.map((item) => {
          if (location.locationID === item.locationID) {
            item.x = coordinates.x;
            item.y = coordinates.y;
            item.position = position;
          }
          return item;
        });
      } else {
        temp.push({
          ...location,
          position,
          x: coordinates?.x || location.x,
          y: coordinates?.y || location.y,
        });
      }
    } else {
      temp.push({
        ...location,
        position,
        x: coordinates?.x || location.x,
        y: coordinates?.y || location.y,
      });
    }

    const temp2 = temp!;

    this._isMounted &&
      this.setState({ movedFloorPlanLocations: temp2, selectedLoc: {} });
  };

  handleReset = (location: Partial<FloorPlanLocationListType>) => {
    const { movedFloorPlanLocations, mapInstance } = this.state;
    let temp = cloneDeep(movedFloorPlanLocations);
    temp = temp.filter((item) => item.locationID !== location.locationID);
    mapInstance?.closePopup?.();
    this._isMounted &&
      this.setState({
        movedFloorPlanLocations: temp,
      });
  };

  handleDelete = (location: FloorPlanLocationListType) => {
    const { floorPlanLocations, mapInstance } = this.state;
    const { onRemoveLocation } = this.props;
    let temp = cloneDeep(floorPlanLocations);
    temp = temp.filter((item) => item.locationID !== location.locationID);
    mapInstance?.closePopup?.();
    onRemoveLocation(location);
    this._floorPlanLocations = cloneDeep(temp);
    this._isMounted &&
      this.setState({
        floorPlanLocations: temp,
        selectedLoc: {},
      });
  };

  handleConfirm = (locationData: FloorPlanLocationListType) => {
    const { mapInstance, floorPlanLocations, movedFloorPlanLocations } =
      this.state;
    mapInstance?.closePopup?.();
    const { onUpdateLocation } = this.props;

    let tempFloorPlans = cloneDeep(floorPlanLocations);
    let temp = cloneDeep(movedFloorPlanLocations);
    temp = temp.filter((item) => item.locationID !== locationData.locationID);
    tempFloorPlans = tempFloorPlans.filter(
      (item) => item.locationID !== locationData.locationID
    );

    tempFloorPlans.push({
      floorplanID: locationData.floorplanID,
      locationID: locationData.locationID,
      deviceID: locationData.deviceID,
      locationName: locationData.locationName,
      x: locationData.x,
      y: locationData.y,
      height: locationData.height,
      lastContact: locationData.lastContact,
      status: locationData.status,
      position: locationData.position,
      uuid: locationData.uuid,
      region: locationData.region,
    });
    onUpdateLocation(locationData);
    this._floorPlanLocations = cloneDeep(tempFloorPlans);
    this._isMounted &&
      this.setState({
        movedFloorPlanLocations: temp,
        floorPlanLocations: tempFloorPlans,
      });
  };

  _onCreated = (event: any) => {
    const { mapInstance } = this.state;
    const { addRegionBtnLoc } = this.state;
    if (event?.layerType === 'polygon') {
      let shouldUpdate = true;
      const shape = event?.layer?.toGeoJSON?.();

      if (
        this._outerPolygon.features &&
        this._outerPolygon.features.length > 0
      ) {
        const polygonData: GeoJSONFeaturesType = {
          locationId: addRegionBtnLoc,
          leafletId: event?.layer?._leaflet_id,
          regionType: 'inner',
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Polygon',
            coordinates: shape?.geometry?.coordinates,
          },
        };
        this._outerPolygon.features.forEach((el) => {
          const first: any = el;
          if (booleanContains(first, shape)) {
            this._innerPolygon = update(this._innerPolygon, {
              features: this._innerPolygon.features
                ? { $push: [polygonData] }
                : { $set: [polygonData] },
            });
          } else if (booleanWithin(first, shape)) {
            shouldUpdate = false;
            mapInstance?.removeLayer(event?.layer);
            message.error(`You can't create multiple Outer Polygon!`);
            return;
          } else if (intersect(first, shape)) {
            shouldUpdate = false;
            mapInstance?.removeLayer(event?.layer);
            message.error(`Polygons can't intersect with each other`);
            return;
          }
        });
      } else {
        this._outerPolygon = update(this._outerPolygon, {
          features: {
            $set: [
              {
                locationId: addRegionBtnLoc,
                leafletId: event?.layer?._leaflet_id,
                regionType: 'outer',
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'Polygon',
                  coordinates: shape?.geometry?.coordinates,
                },
              },
            ],
          },
        });
      }
      if (shouldUpdate) {
        this.updatePolygon();
        if (this._editableFG) {
          mapInstance?.removeLayer(event?.layer);
          this._onFeatureGroupReady(this._editableFG, 'created');
        }
      }
    }
  };

  _onEdited = (event: any) => {
    const { addRegionBtnLoc } = this.state;
    let tempOuter = { ...this._outerPolygon };
    let tempInner = { ...this._innerPolygon };
    event?.layers?.eachLayer?.((layer: any) => {
      const poly = new L.Polygon(layer?._latlngs);
      const shape = layer?.toGeoJSON?.();
      const polygonData: GeoJSONFeaturesType = {
        locationId: addRegionBtnLoc,
        leafletId: layer?._leaflet_id,
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: shape?.geometry?.coordinates,
        },
      };

      if (
        tempOuter.features &&
        tempOuter.features.find((el) => el.leafletId === layer?._leaflet_id)
      ) {
        if (tempInner.features) {
          tempInner.features.forEach((innerEl) => {
            const first: any = innerEl;
            if (booleanContains(first, shape)) {
              if (tempInner.features && tempOuter.features) {
                tempInner.features = tempInner.features.filter(
                  (elem) => elem.leafletId !== innerEl.leafletId
                );
                tempInner.features = [...tempInner.features, polygonData];

                tempOuter.features = tempOuter.features.filter(
                  (elem) => elem.leafletId !== layer?._leaflet_id
                );
                tempOuter.features = [...tempOuter.features, innerEl];
              }
            }
            // else if (intersect(first, shape)) {
            //   mapInstance?.removeLayer(layer);
            //   message.error(`Polygons can't intersect with each other`);
            //   return;
            // }
          });
        }

        const tempFeatures: any = tempOuter.features.map((el) => {
          if (el.leafletId === layer?._leaflet_id) {
            el = {
              ...el,
              geometry: {
                ...el.geometry,
                coordinates: poly.toGeoJSON().geometry.coordinates,
              },
            };
          }
          return { ...el };
        });
        const tempOuterRegion = update(tempOuter, {
          features: { $set: tempFeatures },
        });

        this._outerPolygon = { ...tempOuterRegion };
        this._innerPolygon = { ...tempInner };
      } else if (
        tempInner.features &&
        tempInner.features.find((el) => el.leafletId === layer?._leaflet_id)
      ) {
        if (tempOuter.features) {
          tempOuter.features.forEach((outerEl) => {
            const first: any = outerEl;
            if (booleanWithin(first, shape)) {
              if (tempInner.features && tempOuter.features) {
                tempOuter.features = tempOuter.features.filter(
                  (elem) => elem.leafletId !== outerEl.leafletId
                );
                tempOuter.features = [...tempOuter.features, polygonData];

                tempInner.features = tempInner.features.filter(
                  (elem) => elem.leafletId !== layer?._leaflet_id
                );
                tempInner.features = [...tempInner.features, outerEl];
              }
            }
            // else if (intersect(first, shape)) {
            //   mapInstance?.removeLayer(layer);
            //   message.error(`Polygons can't intersect with each other`);
            //   return;
            // }
          });
        }

        const tempFeatures: any = tempInner.features.map((el) => {
          if (el.leafletId === layer?._leaflet_id) {
            el = {
              ...el,
              geometry: {
                ...el.geometry,
                coordinates: poly.toGeoJSON().geometry.coordinates,
              },
            };
          }
          return { ...el };
        });
        const tempInnerRegion = update(tempInner, {
          features: { $set: tempFeatures },
        });
        this._innerPolygon = { ...tempInnerRegion };
        this._outerPolygon = { ...tempOuter };
      }
    });

    this.updatePolygon();
  };

  _onDeleted = (event: any) => {
    let tempOuter = { ...this._outerPolygon };
    let tempInner = { ...this._innerPolygon };
    if (event?.layers) {
      event?.layers?.eachLayer((layer: any) => {
        tempOuter = update(tempOuter, {
          features: {
            $set: tempOuter.features?.filter(
              (el) => el.leafletId !== layer._leaflet_id
            ),
          },
        });
        tempInner = update(tempInner, {
          features: {
            $set: tempInner.features?.filter(
              (el) => el.leafletId !== layer._leaflet_id
            ),
          },
        });
      });

      if (
        tempOuter.features &&
        tempOuter.features.length === 0 &&
        tempInner.features &&
        tempInner.features.length === 1
      ) {
        tempOuter.features = [...tempInner.features];
        tempInner.features = undefined;
      }

      this._innerPolygon = { ...tempInner };
      this._outerPolygon = { ...tempOuter };

      this.updatePolygon();
    }
  };

  _addPolygonLayers = ({
    leafletFG,
    mapInstance,
    geoJSON,
  }: {
    leafletFG: any;
    mapInstance: L.Map;
    geoJSON: GeoJSONType;
  }) => {
    mapInstance.eachLayer((layer: any) => {
      if (
        layer?.feature?.regionType === 'outer' ||
        layer?.feature?.regionType === 'inner'
      ) {
        mapInstance.removeLayer(layer);
      }
    });

    // if (this._innerPolygon.features) {
    //   const inner = new L.GeoJSON<GeoJSONType>(this._innerPolygon);
    //   inner.eachLayer((layer) => {
    //     mapInstance.removeLayer(layer);
    //   });
    // }

    // if (this._outerPolygon.features) {
    //   const outer = new L.GeoJSON<GeoJSONType>(this._outerPolygon);
    //   outer.eachLayer((layer) => {
    //     mapInstance.removeLayer(layer);
    //   });
    // }

    const leafletGeoJSON = new L.GeoJSON<GeoJSONType>(geoJSON);
    let tempRegion: GeoJSONType = { type: 'FeatureCollection' };
    leafletGeoJSON.eachLayer((layer: any) => {
      const geoJSON: GeoJSONFeaturesType = layer.toGeoJSON();
      if (geoJSON) {
        geoJSON.leafletId = layer._leaflet_id;
        tempRegion = update(tempRegion, {
          features: tempRegion.features
            ? { $push: [geoJSON] }
            : { $set: [geoJSON] },
        });
      }

      leafletFG.addLayer(layer);
    });

    if (tempRegion.features && tempRegion.features.length > 0) {
      const outerFeatures: GeoJSONFeaturesType[] = [];
      const innerFeatures: GeoJSONFeaturesType[] = [];
      tempRegion.features.forEach((el) => {
        if (el.regionType === 'inner') {
          innerFeatures.push(el);
        } else if (el.regionType === 'outer') {
          outerFeatures.push(el);
        }
      });
      this._outerPolygon.features = outerFeatures;
      this._innerPolygon.features = innerFeatures;
    }
  };

  _onFeatureGroupReady = (
    reactFGref: any,
    eventType?: 'created' | 'edited' | 'deleted'
  ) => {
    if (reactFGref === null) {
      return;
    }
    const { mapInstance, floorPlanLocations } = this.state;
    if (!this._editableFG) {
      this._editableFG = reactFGref;
    }

    if (
      mapInstance &&
      floorPlanLocations.length > 0 &&
      (this._outerPolygon.features || this._innerPolygon.features)
    ) {
      let geoJSON: GeoJSONType = { type: 'FeatureCollection', features: [] };
      if (this._outerPolygon.features) {
        this._outerPolygon.features.forEach((el) => {
          geoJSON.features?.push(el);
        });
      }
      if (this._innerPolygon.features) {
        this._innerPolygon.features.forEach((el) => {
          geoJSON.features?.push(el);
        });
      }

      const leafletFG = reactFGref;

      if (
        this.props.addRegionClickedLocId !== this.state.addRegionBtnLoc &&
        geoJSON.features &&
        geoJSON.features.length > 0
      ) {
        this._addPolygonLayers({ leafletFG, mapInstance, geoJSON });
      } else if (eventType === 'created') {
        this._addPolygonLayers({ leafletFG, mapInstance, geoJSON });
      }
    }
  };

  getFormData = () => {
    const region: any = {
      outerPolygon: this._outerPolygon,
      innerPolygons: this._innerPolygon,
    };
    let tempFormData = [];
    tempFormData.push('region=' + JSON.stringify(region));
    const formData: string = tempFormData.join('&');
    return { formData };
  };

  updatePolygon = () => {
    const { floorPlanId, token } = this.props;
    const { floorPlanLocations, addRegionBtnLoc } = this.state;
    if (token) {
      const { formData } = this.getFormData();
      const apiDetails = floorPlanApi.patchFloorPlanLocation(undefined, {
        floorPlanID: floorPlanId,
        locationID: addRegionBtnLoc,
      });
      const handleResponses = (responses: AxiosHttpAllSettledResponsesType) => {
        if (responses?.[0].status === 'fulfilled') {
          handleNotification('success', {
            message: 'Polygon Updated Successfully!',
          });

          const temp = floorPlanLocations.map((el) => {
            if (el.locationID === addRegionBtnLoc) {
              el = update(el, {
                region: {
                  outerPolygon: {
                    $set: cloneDeep(this._outerPolygon),
                  },
                  innerPolygons: {
                    $set: cloneDeep(this._innerPolygon),
                  },
                },
              });
            }
            return { ...el };
          });

          this._floorPlanLocations = cloneDeep(temp);
          this.setState((prev) => ({ floorPlanLocations: temp }));
        } else {
          httpCallErrorHandling(responses?.[0]);
        }
      };
      httpCallAllSettled({
        requestConfig: [
          {
            ...apiDetails,
            data: formData,
          },
        ],
        headersConfig: { token },
        applyData: handleResponses,
      });
    }
  };

  render() {
    const { mapDetails, classes, isEditable, addRegionClickedLocId } =
      this.props;
    const {
      mapBounds,
      currentPosition,
      floorPlanLocations,
      movedFloorPlanLocations,
      selectedLoc,
    } = this.state;

    const mapImage = `data:image/svg+xml,${encodeURIComponent(
      mapDetails.image || ''
    )}`;

    const center = L.latLng(currentPosition.lat || 0, currentPosition.lng || 0);

    const editControProps = {
      edit: {
        // edit: !!addRegionClickedLocId,
        edit: addRegionClickedLocId
          ? {
              allowIntersection: false,
              shapeOptions: {
                color: '#4390FE',
                fillColor: '#D6E7FF',
              },
            }
          : false,
        remove: !!addRegionClickedLocId,
        poly: !!addRegionClickedLocId,
        allowIntersection: false,
      },
      draw: {
        polyline: false,
        rectangle: false,
        circle: false,
        marker: false,
        circlemarker: false,
        polygon: addRegionClickedLocId
          ? {
              allowIntersection: false,
              shapeOptions: {
                color: '#4390FE',
              },
            }
          : false,
      },
    };

    return (
      <Fragment>
        <MapContainer
          tap={false}
          className={classes}
          whenCreated={this.handleMap}
          center={center}
          crs={customCRS}
          zoom={unprojectZoom}>
          {mapDetails.image && mapBounds && (
            <ImageOverlay bounds={mapBounds} url={mapImage} />
          )}
          <FeatureGroup
            ref={(reactFGref) => {
              this._onFeatureGroupReady(reactFGref);
            }}>
            <EditControl
              {...editControProps}
              position="topright"
              onCreated={this._onCreated}
              onEdited={this._onEdited}
              onDeleted={this._onDeleted}
            />
          </FeatureGroup>
          {floorPlanLocations.length > 0 &&
            floorPlanLocations.map((item) => {
              if (item.position) {
                const { locationName } = item;
                let position = item.position;

                let matchedLocation: FloorPlanLocationListType | undefined =
                  undefined;
                if (movedFloorPlanLocations.length > 0) {
                  matchedLocation = movedFloorPlanLocations.find(
                    (el) => el.locationID === item.locationID
                  );
                }

                if (matchedLocation?.position) {
                  position = matchedLocation.position;
                }

                const popupDetails = {
                  locationName,
                };
                if (matchedLocation?.locationName) {
                  popupDetails.locationName = matchedLocation.locationName;
                }

                let icon = getLeafletIcon('blue');
                if (selectedLoc.locationID === item.locationID) {
                  icon = getLeafletIcon('grey');
                }
                if (matchedLocation?.locationID === item.locationID) {
                  icon = getLeafletIcon('red');
                }
                if (item.isClickedFromTable === true) {
                  icon = getLeafletIcon('grey');
                }

                return (
                  <Fragment key={item.locationID}>
                    <Marker
                      key={item.locationID}
                      title={item.locationID}
                      icon={icon}
                      draggable={isEditable}
                      position={[position?.lat || 0, position?.lng || 0]}
                      ref={this.markerRef}
                      eventHandlers={{
                        dragend: (event) => {
                          this.onMoveMarker(event, item);
                        },
                      }}>
                      <CustomPopup
                        popupDetails={popupDetails}
                        floorPlanLocation={item}
                        matchedLocation={matchedLocation}
                        handleDelete={this.handleDelete}
                        handleConfirm={this.handleConfirm}
                        handleReset={this.handleReset}
                        isEditable={isEditable}
                      />
                    </Marker>
                  </Fragment>
                );
              }
              return null;
            })}
        </MapContainer>
      </Fragment>
    );
  }
}

const CustomPopup = ({
  popupDetails,
  floorPlanLocation,
  matchedLocation,
  handleReset,
  handleConfirm,
  handleDelete,
  isEditable,
}: {
  popupDetails: { locationName: string };
  floorPlanLocation: FloorPlanLocationListType;
  matchedLocation?: FloorPlanLocationListType;
  handleReset: (location: FloorPlanLocationListType) => void;
  handleConfirm: (location: FloorPlanLocationListType) => void;
  handleDelete: (location: FloorPlanLocationListType) => void;
  isEditable: boolean;
}) => {
  return (
    <Popup>
      <Row justify="center">
        <Col>Location Name: {popupDetails.locationName}</Col>
      </Row>
      <Row justify="center" className="pt-2">
        <Col>Do you want to update?</Col>
      </Row>
      <Row justify="center" className="pt-4">
        <Col className="text-center">
          <Button
            disabled={!isEditable}
            size={`small`}
            onClick={(event) =>
              handleReset(matchedLocation || floorPlanLocation)
            }>
            Cancel
          </Button>
        </Col>
        <Col className="text-center pl-2">
          <Button
            disabled={!isEditable}
            type="primary"
            size={`small`}
            onClick={(event) =>
              handleConfirm(matchedLocation || floorPlanLocation)
            }>
            Yes
          </Button>
        </Col>
        <Col className="text-center pl-2">
          <Button
            disabled={!isEditable}
            danger
            type="primary"
            size={`small`}
            onClick={(event) =>
              handleDelete(matchedLocation || floorPlanLocation)
            }>
            Remove
          </Button>
        </Col>
      </Row>
    </Popup>
  );
};
