import { Fragment, useState } from 'react';
import { Row, Col } from 'antd';
import AntdCard from '../components-shared/AntdCard';

import cssStyles from '../components/Home/styles/home.module.scss';
import { useSelector } from 'react-redux';
import { getUserPermissionList } from '../redux/selectors';
import { quickProcessRights } from '../utils/permission-list';
import UrsalinkModal from '../components/Home/UrsalinkModal';

const Home = () => {
  const userPermissionList = useSelector(getUserPermissionList);
  const [showUrsalinkModal, setShowUrsalinkModal] = useState<boolean>(false);

  let showData = false;
  if (
    userPermissionList.length &&
    userPermissionList.includes(quickProcessRights.viewData)
  ) {
    showData = true;
  }

  let showQuickProcess = false;
  if (
    userPermissionList.length &&
    userPermissionList.some((el) => el.includes('QP_'))
  ) {
    if (userPermissionList.includes(quickProcessRights.ursalink)) {
      showQuickProcess = true;
    }
  }

  return (
    <Fragment>
      {showData && (
        <Row className="py-4 px-4">
          {showQuickProcess && (
            <Col xs={12} sm={6} md={3}>
              <AntdCard
                classNames={cssStyles.card}
                onClick={() => setShowUrsalinkModal((prev) => !prev)}>
                URSALINK Quick Process
              </AntdCard>
            </Col>
          )}
        </Row>
      )}

      {showUrsalinkModal && (
        <UrsalinkModal
          visible={showUrsalinkModal}
          onCancel={() => setShowUrsalinkModal((prev) => !prev)}
        />
      )}
    </Fragment>
  );
};

export default Home;
