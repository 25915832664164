import { Component, ErrorInfo } from 'react';
import { Result, Button } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface PropsType extends RouteComponentProps<any> {}

type StateType = {
  hasError: boolean;
};

class ErrorBoundary extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    this.state = { hasError: false };
  }

  // static getDerivedStateFromError<T>(error: T) {
  //   // Update state so the next render will show the fallback UI.
  //   return { hasError: true };
  // }

  static getDerivedStateFromError(_: Error): StateType {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // componentDidCatch<T, K>(error: T, errorInfo: K) {
  //   // You can also log the error to an error reporting service
  // }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  // ## for .tsx
  // generic = <T extends unknown>(value: T) => {
  //   return value;
  // };

  // ## for .tsx
  // generic = <T,>(value: T) => {
  //   return value;
  // };

  // ## for .ts
  // generic = <T> (value: T) => {
  //   return value;
  // }

  // generic: { <T>(value: T): T } = (value) => {
  //   return value;
  // };

  handleRedirect = () => {
    const { history } = this.props;
    history.push({
      pathname: `/`,
    });
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Button type="primary" onClick={this.handleRedirect}>
              Back Home
            </Button>
          }
        />
      );
    }

    return children;
  }
}

export default withRouter(ErrorBoundary);
