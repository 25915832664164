import axios, { CancelTokenSource } from 'axios';
import {
  FormEvent,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { FaCog, FaTrashAlt } from 'react-icons/fa';
import { v4 } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';
import { apiCall } from '../api-services/api';
import { floorPlanApi } from '../api-services/api-list';
import { StateType } from '../components/FloorPlans/helpers';
import {
  saveFilterFloorPlan,
  savePagination,
  updateToken,
} from '../redux/actions';
import { AntdTableColumnsType, ReduxStateType } from '../type-definitions';
import {
  FloorPlanListType,
  UserDataType,
  ApiResultType,
} from '../type-definitions/api-types';
import { floorPlanRights } from '../utils/permission-list';
import { getKeysFromEnum, handleSorting, handleTableSearch } from '../utils';
import { FloorPlanListEnumKeys } from '../api-services/api-responses';
import { floorPlanRoutes } from '../Routes/routes-list';
import { Modal } from 'antd';
import NotificationHandler from '../components/NotificationHandler';
import TableWrapper, {
  AddButton,
  PaginationDropdown,
} from '../components/TableWrapper';
import FilterInput from '../components/FilterInput';

interface PropsType extends RouteComponentProps {
  userData: Partial<UserDataType>;
  userPermissionList: string[];
}

const initState = {
  loading: true,
  success: {},
  error: {},
  initDataList: [],
  dataList: [],
  filteredList: [],
  perPage: 10,
  pageName: 'floorPlans',
};

const tableColumnsKeys = getKeysFromEnum(FloorPlanListEnumKeys);

function FloorPlans({ userData, userPermissionList, history }: PropsType) {
  const [state, setState] = useState<Readonly<StateType>>(initState);
  const _isMounted = useRef(false);
  const axiosCancelSource = useRef<CancelTokenSource>();
  const dispatch = useDispatch();
  const paginationDetails = useSelector(
    (state: ReduxStateType) => state.shared.paginationDetails
  );
  const filterByPartnerObj = useSelector(
    (state: ReduxStateType) => state.floorPlan.filterByPartnerObj
  );
  const searchInput = useSelector(
    (state: ReduxStateType) => state.floorPlan.searchInput
  );

  function handleState(data: object) {
    _isMounted.current &&
      setState((prevState) => ({
        ...prevState,
        ...data,
      }));
  }

  useEffect(() => {
    _isMounted.current = true;
    axiosCancelSource.current = axios.CancelToken.source();
    return () => {
      _isMounted.current = false;
      axiosCancelSource.current?.cancel('Component Unmounted');
    };
  }, []);

  const handleFetchedData = useCallback(async () => {
    try {
      const { url, method, contentType } = floorPlanApi.getFloorPlans();
      const response = await apiCall({
        storeToken: userData.token,
        url,
        method,
        contentType,
        cancelToken: axiosCancelSource.current?.token,
      });
      const result = response?.data;
      dispatch(updateToken(result));
      if (result.status === 'ok') {
        const stateData: Partial<StateType> = {};
        const tempData: FloorPlanListType[] = result.data;
        if (tempData && tempData.length > 0) {
          const floorPlanList = tempData.map((item) => ({
            ...item,
            uuid: v4(),
          }));
          const tempList = cloneDeep(floorPlanList);

          stateData.initDataList = tempList;
          stateData.dataList = tempList;

          // ## partner list and filtering
          let tempPartner: string[] = [];
          tempList.length > 0 &&
            tempList.forEach((item) => {
              if (item.partnerID) {
                tempPartner.push(item.partnerID);
              }
            });
          tempPartner = [...new Set(tempPartner)];

          const partnerData: { [k: string]: boolean } = {};
          const filterByPartnerKeys =
            filterByPartnerObj && Object.keys(filterByPartnerObj);
          tempPartner.forEach((item) => {
            if (filterByPartnerKeys.length > 0) {
              if (filterByPartnerObj[item]) {
                partnerData[item] = true;
              } else {
                partnerData[item] = false;
              }
            } else {
              partnerData[item] = false;
            }
          });
          // ## -- end -- partner list and filtering

          // ## saving the partner data in redux store
          dispatch(saveFilterFloorPlan({ filterByPartnerObj: partnerData }));

          // ## if filtering needed on route change
          const hasFilter =
            filterByPartnerKeys.length > 0 &&
            filterByPartnerKeys.some(
              (item) => filterByPartnerObj[item] === true
            );

          if (hasFilter) {
            const temp = cloneDeep(stateData.dataList);
            const filteredData = temp.filter((item) => {
              if (partnerData[item.partnerID]) {
                return item;
              }
              return null;
            });
            stateData.dataList = filteredData;
            stateData.filteredList = filteredData;
          }
          // ## -- end -- if filtering needed on route change

          // ## search from data
          if (searchInput) {
            let searchList = stateData.dataList;
            if (
              Array.isArray(stateData.filteredList) &&
              stateData.filteredList.length > 0
            ) {
              searchList = stateData.filteredList;
            }
            const sortedData = handleTableSearch({
              data: searchList,
              columnList: tableColumnsKeys,
              searchData: searchInput,
            });
            stateData.dataList = sortedData;
          }
          // ## -- end -- search from data

          handleState({
            loading: false,
            ...stateData,
          });
        } else {
          handleState({ loading: false });
        }
      } else {
        handleState({ loading: false, error: result });
      }
    } catch (error) {
      handleState({ loading: false, error });
    }
  }, [dispatch, filterByPartnerObj, searchInput, userData.token]);

  useEffect(() => {
    if (state.initDataList.length === 0 && userData.token) {
      handleFetchedData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.initDataList.length, userData.token]);

  const handleDelete = useCallback(
    (floorPlanID: string) => {
      const onDeleteConfirm = async () => {
        const stateData = {
          success: {},
          error: {},
        };
        try {
          if (floorPlanID) {
            handleState({ loading: true });
            const { url, method, contentType } = floorPlanApi.deleteFloorPlan(
              undefined,
              {
                floorPlanID,
              }
            );
            const response = await apiCall({
              storeToken: userData.token,
              url,
              method,
              contentType,
              cancelToken: axiosCancelSource.current?.token,
            });
            const result: ApiResultType = response?.data;

            if (result?.status === 'ok') {
              stateData.success = result;
              handleFetchedData();
            }
          }
        } catch (error: any) {
          stateData.error = error?.data;
        }

        handleState({ ...stateData, loading: false });
      };

      Modal.confirm({
        title: 'Are you sure?',
        icon: <AiOutlineExclamationCircle />,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: () => {
          onDeleteConfirm();
        },
        onCancel: () => {},
      });
    },
    [handleFetchedData, userData.token]
  );

  const handlePaginationOption = (value: string) => {
    const { pageName } = state;
    handleState({ perPage: parseInt(value) });
    dispatch(savePagination({ paginationKey: pageName, paginationValue: 1 }));
  };

  const onPaginationChange = (page: number, pageSize?: number) => {
    // console.log('params', page, pageSize);
    const { pageName } = state;
    dispatch(
      savePagination({ paginationKey: pageName, paginationValue: page })
    );
  };

  const handleSearch = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const targetValue = event.currentTarget.value;

      dispatch(
        savePagination({ paginationKey: state.pageName, paginationValue: 1 })
      );
      dispatch(saveFilterFloorPlan({ searchInput: targetValue }));

      const tempList =
        state.filteredList.length > 0 ? state.filteredList : state.initDataList;

      const stateData = {
        dataList: tempList,
      };

      if (targetValue) {
        const tempData = cloneDeep(tempList);
        const sortedData = handleTableSearch({
          data: tempData,
          columnList: tableColumnsKeys,
          searchData: targetValue,
        });
        stateData.dataList = sortedData;
      }

      handleState({
        ...stateData,
      });
    },
    [dispatch, state.filteredList, state.initDataList, state.pageName]
  );

  const handleCheckboxFilter = useCallback(
    (checkBoxData: { [k: string]: boolean }) => {
      const stateData: {
        dataList: FloorPlanListType[];
        filteredList: FloorPlanListType[];
      } = { dataList: [], filteredList: [] };

      if (checkBoxData) {
        dispatch(
          savePagination({ paginationKey: state.pageName, paginationValue: 1 })
        );
        dispatch(saveFilterFloorPlan({ filterByPartnerObj: checkBoxData }));

        const temp = cloneDeep(state.initDataList);
        const filteredData = temp?.filter((item) => {
          if (checkBoxData[item.partnerID]) {
            return item;
          }
          return null;
        });

        stateData.dataList = filteredData;
        stateData.filteredList = filteredData;
      }

      handleState({
        ...stateData,
      });
    },
    [dispatch, state.initDataList, state.pageName]
  );

  const handleRedirect = useCallback(
    (record: FloorPlanListType) => {
      const { rights, floorplanID, assetID, width, height, lat, lng } = record;
      const allowEdit = rights?.includes(floorPlanRights.edit) ?? false;
      history.push({
        pathname: floorPlanRoutes.details(),
        state: {
          floorPlanID: floorplanID,
          assetID,
          allowEdit,
          mapWidth: width,
          mapHeight: height,
          floorPlanLat: lat,
          floorPlanLng: lng,
        },
      });
    },
    [history]
  );

  if (userPermissionList.length === 0) {
    return <Redirect to="/" />;
  }

  const showAdd = userPermissionList?.includes(floorPlanRights.create) ?? false;

  let currentPage: number | undefined = 1;
  if (paginationDetails && state.pageName) {
    currentPage = paginationDetails['floorPlans'];
  }
  const pagination = {
    current: currentPage,
    pageSize: state.perPage,
    total: (state.dataList && state.dataList.length) ?? 0,
    onChange: onPaginationChange,
  };

  return (
    <Fragment>
      <NotificationHandler
        obj={{ _isMounted: _isMounted.current, setState }}
        success={state.success}
        error={state.error}
      />

      <TableWrapper
        tableDetails={{
          dataSource: state.dataList,
          columns: getColumns(handleDelete),
          pagination,
          customConfig: {
            rowKeyValue: 'uuid',
            onRowClick: handleRedirect,
          },
        }}
        loading={state.loading}
        pageTitle="Floor Plans"
        inputComponents={() => (
          <Fragment>
            {state.dataList.length > 1 && (
              <div className="col-lg-2 col-md-2">
                <PaginationDropdown onSelectChange={handlePaginationOption} />
              </div>
            )}
            <div className="col-lg-4 col-md-4 col-sm-6 ml-auto">
              <FilterInput
                handleSearchFilter={handleSearch}
                loading={state.loading}
                searchValue={searchInput}
                listData={filterByPartnerObj}
                handleCheckboxFilter={handleCheckboxFilter}
              />
            </div>

            {showAdd && (
              <div className="col-xl-1 col-lg-2 col-md-3 col-sm-6">
                <AddButton
                  onButtonClick={() => history.push(floorPlanRoutes.add)}
                />
              </div>
            )}
          </Fragment>
        )}
      />
    </Fragment>
  );
}

export default FloorPlans;

function getColumns(
  handleDelete: (id: string) => void
): AntdTableColumnsType<FloorPlanListType>[] {
  return [
    {
      title: 'Floor Plan Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: FloorPlanListType, b: FloorPlanListType) =>
        handleSorting(a.name, b.name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: (a: FloorPlanListType, b: FloorPlanListType) =>
        handleSorting(a.description, b.description),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Partner',
      dataIndex: 'partnerID',
      key: 'partnerID',
      sorter: (a: FloorPlanListType, b: FloorPlanListType) =>
        handleSorting(a.partnerID, b.partnerID),
      sortDirections: ['descend', 'ascend'],
      // defaultSortOrder: 'ascend',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record: FloorPlanListType) => {
        const { rights } = record;
        const allowEdit = rights?.includes(floorPlanRights.edit) ?? false;
        const allowRemove = rights?.includes(floorPlanRights.remove) ?? false;
        return (
          <Fragment>
            <div className="row">
              {allowEdit && (
                <div className="col-2">
                  <Link
                    to={{
                      pathname: floorPlanRoutes.details(),
                      state: {
                        floorPlanID: record.floorplanID,
                        allowEdit,
                        assetID: record.assetID,
                        mapWidth: record.width,
                        mapHeight: record.height,
                        floorPlanLat: record.lat,
                        floorPlanLng: record.lng,
                      },
                    }}>
                    <FaCog size="1.2em" />
                  </Link>
                </div>
              )}

              {allowRemove && (
                <div className="col-2">
                  <FaTrashAlt
                    size="1.2em"
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleDelete(record.floorplanID);
                    }}
                  />
                </div>
              )}
            </div>
          </Fragment>
        );
      },
    },
  ];
}
