import {
  ChangeEvent,
  Dispatch,
  Fragment,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';
import {
  Row,
  Col,
  Input,
  Select,
  Button,
  message,
  Modal,
  Skeleton,
} from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { AiOutlineClear } from 'react-icons/ai';

import { locationApi } from '../../../api-services/api-list';
import useHttp from '../../../hooks/use-http-allSettled';
import { getUserData } from '../../../redux/selectors';
import {
  AntdTableColumnsType,
  FormInputType,
  ReducerHookActionType,
} from '../../../type-definitions';
import {
  AxiosHttpAllSettledResponsesType,
  JournalListType,
  RequestConfigType,
} from '../../../type-definitions/api-types';
import {
  JournalTabStateType,
  initJournalState,
  reducer,
  actionTypes,
} from '../helpers';
import { useCallback } from 'react';
import { handleTableSearch } from '../../../utils';

import cssStyles from '../styles/locationDetails.module.scss';
import { handleNotification } from '../../../utils/notification-handler';
import { httpCallErrorHandling } from '../../../api-services/api';
import AntdInput from '../../AntdInput';
import AntdTable from '../../AntdTable';
import SwapContent from '../../../shared/components/Journal/SwapContent';
import NoteContent from '../../../shared/components/Journal/NoteContent';
import MovementContent from '../../../shared/components/Journal/MovementContent';
import AssignmentContent from '../../../shared/components/Journal/AssignmentContent';
import RemovalContent from '../../../shared/components/Journal/RemovalContent';
import GroupRemovalContent from '../../../shared/components/Journal/GroupRemovalContent';
import GroupAssignmentContent from '../../../shared/components/Journal/GroupAssignmentContent';

interface PropsType {
  locationID?: string;
  journalNote: FormInputType;
  inputChangedHandler: (name: any, value: any) => void;
}

const JournalTab = ({
  locationID,
  journalNote,
  inputChangedHandler,
}: PropsType) => {
  const [state, dispatchToState]: [
    state: JournalTabStateType,
    dispatchToState: Dispatch<ReducerHookActionType>
  ] = useReducer(reducer, initJournalState);
  const { isLoading, sendRequest } = useHttp();
  const userData = useSelector(getUserData);

  const {
    journalList,
    initJournalList,
    searchValue,
    tablePagination,
    deviceIDList,
    entryTypeList,
    filterDeviceIDValue,
    filterEntryTypeValue,
    showNoteModal,
    selectedJournalTS,
  } = state;

  const fetchData = useCallback(
    ({
      currentPage,
      entryType,
      deviceID,
    }: {
      currentPage: number;
      entryType?: string;
      deviceID?: string;
    }) => {
      if (userData.token && locationID) {
        const { url, method, contentType, params } =
          locationApi.getLocationJournals(
            {
              page: currentPage,
              pagesize: tablePagination.perPage,
              entryType,
              deviceID,
            },
            { locationID }
          );

        const handleResponses = (
          responses: AxiosHttpAllSettledResponsesType
        ) => {
          if (responses.length > 0) {
            if (responses[0].status === 'fulfilled') {
              const result = responses[0].value;
              if (result.data?.data) {
                const data = result.data?.data;
                let journalEntries: JournalListType[] = data?.entries;
                if (journalEntries) {
                  journalEntries = journalEntries.map((el) => ({
                    ...el,
                    uuid: v4(),
                  }));

                  let payload: {
                    journalList: JournalListType[];
                    initJournalList: JournalListType[];
                    deviceIDList?: string[];
                    entryTypeList?: string[];
                    tablePagination: {
                      totalCount: number;
                      currentPage: number;
                    };
                  } = {
                    journalList: journalEntries,
                    initJournalList: journalEntries,
                    tablePagination: {
                      totalCount: data?.totalCount || 0,
                      currentPage,
                    },
                  };

                  if (!entryType && !deviceID) {
                    let tempDeviceIDs = journalEntries.map(
                      (el) => el.deviceID && el.deviceID
                    );
                    tempDeviceIDs = Array.from(new Set(tempDeviceIDs));

                    let tempEntryTypes = journalEntries.map(
                      (el) => el.entryType && el.entryType
                    );
                    tempEntryTypes = Array.from(new Set(tempEntryTypes));

                    payload.deviceIDList = tempDeviceIDs;
                    payload.entryTypeList = tempEntryTypes;
                  }

                  dispatchToState({
                    type: actionTypes.setInitJournals,
                    payload: {
                      ...payload,
                    },
                  });
                }
              }
            } else {
              handleNotification('error', {
                message: responses[0].reason?.response?.data?.message,
              });
            }
          }
        };

        sendRequest({
          requestConfig: [{ url, method, contentType, params }],
          headersConfig: { storeToken: userData.token },
          applyData: handleResponses,
        });
      }
    },
    [locationID, sendRequest, tablePagination.perPage, userData.token]
  );

  useEffect(() => {
    fetchData({ currentPage: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearchValueChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e?.currentTarget?.value;
      let tempInitJournals = cloneDeep(initJournalList);
      if (value) {
        const columns = getColumns().map((el) => el.key);
        const tempJournals = handleTableSearch({
          data: tempInitJournals,
          columnList: columns,
          searchData: value,
        });

        dispatchToState({
          type: actionTypes.setSearchValue,
          payload: {
            searchValue: e.currentTarget.value,
            journalList: tempJournals,
            tablePagination: {
              currentPage: 1,
              lastPagination: !searchValue
                ? tablePagination.currentPage
                : tablePagination.lastPagination,
            },
          },
        });
      } else {
        dispatchToState({
          type: actionTypes.setSearchValue,
          payload: {
            searchValue: e.currentTarget.value,
            journalList: tempInitJournals,
            tablePagination: { currentPage: tablePagination.lastPagination },
          },
        });
      }
    },
    [
      initJournalList,
      searchValue,
      tablePagination.currentPage,
      tablePagination.lastPagination,
    ]
  );

  const fetchMultipleData = useCallback(
    (apiCollections: RequestConfigType[]) => {
      const handleResponses = (responses: AxiosHttpAllSettledResponsesType) => {
        if (responses.length > 0) {
          let journalEntries: JournalListType[] = [];
          responses.forEach((el) => {
            if (el.status === 'fulfilled') {
              const result = el?.value;
              if (result.data?.data) {
                const data = result.data.data;
                let tempJournals: JournalListType[] = data?.entries;
                if (tempJournals) {
                  tempJournals = tempJournals.map((el) => ({
                    ...el,
                    uuid: v4(),
                  }));

                  journalEntries.push(...tempJournals);
                }
              }
            } else {
              handleNotification('error', {
                message: el.reason?.response?.data?.message,
              });
            }
          });
          const currentPage =
            apiCollections[apiCollections.length - 1].params?.page;

          dispatchToState({
            type: actionTypes.setJournals,
            payload: {
              journalList: journalEntries,
              initJournalList: journalEntries,
              tablePagination: {
                currentPage,
              },
            },
          });
        }
      };

      sendRequest({
        requestConfig: [...apiCollections],
        headersConfig: { storeToken: userData.token },
        applyData: handleResponses,
      });
    },
    [sendRequest, userData.token]
  );

  const onPaginationChange = useCallback(
    (page: number, pageSize?: number) => {
      if (initJournalList.length < tablePagination.totalCount) {
        if (tablePagination.currentPage + 1 === page) {
          fetchData({
            currentPage: page,
            entryType: filterEntryTypeValue || undefined,
            deviceID: filterDeviceIDValue || undefined,
          });
        } else if (page > tablePagination.currentPage + 1) {
          let apiCollections: RequestConfigType[] = [];
          for (
            let index = tablePagination.currentPage + 1;
            index <= page;
            index++
          ) {
            if (locationID) {
              // fetchData({ currentPage: index });
              const { url, method, contentType, params } =
                locationApi.getLocationJournals(
                  {
                    page: index,
                    pagesize: tablePagination.perPage,
                    entryType: filterEntryTypeValue || undefined,
                    deviceID: filterDeviceIDValue || undefined,
                  },
                  { locationID }
                );

              apiCollections.push({ url, method, contentType, params });
            }
          }

          fetchMultipleData(apiCollections);
        } else {
          dispatchToState({
            type: actionTypes.resetPagination,
            payload: {
              currentPage: page,
            },
          });
        }
      } else {
        dispatchToState({
          type: actionTypes.setPagination,
          payload: {
            currentPage: page,
          },
        });
      }
    },
    [
      fetchData,
      fetchMultipleData,
      filterDeviceIDValue,
      filterEntryTypeValue,
      initJournalList.length,
      locationID,
      tablePagination.currentPage,
      tablePagination.perPage,
      tablePagination.totalCount,
    ]
  );

  const onSelectChange = useCallback(
    (value: string, name: 'filterDeviceIDValue' | 'filterEntryTypeValue') => {
      if (value && name) {
        dispatchToState({
          type: actionTypes.setSelectValue,
          payload: { value, name },
        });

        fetchData({
          currentPage: 1,
          entryType: name === 'filterEntryTypeValue' ? value : undefined,
          deviceID: name === 'filterDeviceIDValue' ? value : undefined,
        });
      }
    },
    [fetchData]
  );

  const onSelectClear = useCallback(
    (name: 'filterDeviceIDValue' | 'filterEntryTypeValue') => {
      dispatchToState({
        type: actionTypes.clearSelectValue,
        payload: { name },
      });

      fetchData({ currentPage: 1 });
    },
    [fetchData]
  );

  const onModalOkay = () => {
    if (!journalNote.value) {
      message.warning('Please fill the field!');
      return;
    }

    if (locationID) {
      const handleResponses = (responses: AxiosHttpAllSettledResponsesType) => {
        if (responses?.[0].status === 'fulfilled') {
          handleNotification('success', {
            message: responses[0].value.data?.data,
          });
          dispatchToState({
            type: actionTypes.setState,
            payload: { showNoteModal: false },
          });
          inputChangedHandler('journalNote', '');
          fetchData({ currentPage: 1 });
        } else {
          httpCallErrorHandling(responses[0]);
        }
      };

      const apiDetails = locationApi.postLocationJournalNotes(undefined, {
        locationId: locationID,
      });

      sendRequest({
        requestConfig: [{ ...apiDetails, data: { notes: journalNote.value } }],
        headersConfig: { storeToken: userData.token },
        applyData: handleResponses,
      });
    }
  };

  const onOpenJournalDetailsModal = (record: any) => {
    dispatchToState({
      type: actionTypes.setState,
      payload: {
        selectedJournalTS: record.ts,
      },
    });
  };

  const onCloseJournalDetailsModal = () => {
    dispatchToState({
      type: actionTypes.setState,
      payload: { selectedJournalTS: '' },
    });
  };

  let paginationTotal = tablePagination.totalCount;
  if (initJournalList.length === tablePagination.totalCount) {
    paginationTotal = journalList.length;
  }
  if (state.searchValue) {
    paginationTotal = journalList.length;
  }

  return (
    <Fragment>
      <Row className="pb-3">
        <Col xs={24}>
          <Row justify="center">
            <Col>
              <Button
                type="primary"
                onClick={() =>
                  dispatchToState({
                    type: actionTypes.setState,
                    payload: { showNoteModal: true },
                  })
                }>
                Add Note
              </Button>
            </Col>
          </Row>
          <Row justify="center" align="middle" className="pt-3">
            <Col xs={24} md={12} className="pr-md-1">
              <Select
                id="filterDeviceIDValue"
                allowClear
                clearIcon={<AiOutlineClear size="1.5em" color="#40A9FF" />}
                className={cssStyles.journalTabSelect}
                value={filterDeviceIDValue || undefined}
                onChange={(value) =>
                  onSelectChange(value, 'filterDeviceIDValue')
                }
                onClear={() => onSelectClear('filterDeviceIDValue')}
                style={{ width: '100%' }}
                placeholder="Select Device...">
                {deviceIDList.length > 0 &&
                  deviceIDList.map((item, index) => {
                    if (item) {
                      return (
                        <Select.Option key={index} value={item}>
                          {item}
                        </Select.Option>
                      );
                    }
                    return null;
                  })}
              </Select>
            </Col>

            <Col xs={24} md={12} className="pl-md-1 pt-2 pt-md-0">
              <Select
                id="filterEntryTypeValue"
                allowClear
                clearIcon={<AiOutlineClear size="1.5em" color="#40A9FF" />}
                className={cssStyles.journalTabSelect}
                value={filterEntryTypeValue || undefined}
                onChange={(value) =>
                  onSelectChange(value, 'filterEntryTypeValue')
                }
                onClear={() => onSelectClear('filterEntryTypeValue')}
                style={{ width: '100%' }}
                placeholder="Select EntryType...">
                {entryTypeList.length > 0 &&
                  entryTypeList.map((item, index) => {
                    if (item) {
                      return (
                        <Select.Option key={index} value={item}>
                          {item}
                        </Select.Option>
                      );
                    }
                    return null;
                  })}
              </Select>
            </Col>
          </Row>

          <Row justify="end" className="pt-3 pb-3">
            <Col>
              <Input
                value={searchValue}
                onChange={onSearchValueChange}
                placeholder="Search..."
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <AntdTable
                dataSource={journalList}
                columns={getColumns()}
                loading={isLoading}
                pagination={{
                  responsive: true,
                  total: paginationTotal,
                  pageSize: 10,
                  size: 'small',
                  showSizeChanger: false,
                  onChange: onPaginationChange,
                  current: tablePagination.currentPage,
                }}
                customConfig={{
                  rowKeyValue: 'uuid',
                  onRowClick: onOpenJournalDetailsModal,
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        closable={false}
        visible={showNoteModal}
        onCancel={() =>
          dispatchToState({
            type: actionTypes.setState,
            payload: { showNoteModal: false },
          })
        }
        onOk={onModalOkay}>
        <Row justify="center">
          <Col>
            <AntdInput {...journalNote} onInputChanged={inputChangedHandler} />
          </Col>
        </Row>
      </Modal>

      {!!selectedJournalTS && (
        <JournalDetailsModal
          showModal={!!selectedJournalTS}
          onClose={onCloseJournalDetailsModal}
          timeStamp={selectedJournalTS}
          locationID={locationID}
        />
      )}
    </Fragment>
  );
};
export default JournalTab;
const getColumns = (): AntdTableColumnsType<any>[] => {
  return [
    { title: 'Device ID', key: 'deviceID', dataIndex: 'deviceID' },
    { title: 'Time Stamp', key: 'ts', dataIndex: 'ts' },
    { title: 'Entry Type', key: 'entryType', dataIndex: 'entryType' },
    { title: 'Description', key: 'text', dataIndex: 'text' },
  ];
};

interface JournalDetailsModalPropsType {
  showModal: boolean;
  onClose: () => void;
  timeStamp: string;
  locationID?: string;
}
export const JournalDetailsModal = ({
  showModal,
  onClose,
  timeStamp,
  locationID,
}: JournalDetailsModalPropsType) => {
  const { isLoading, sendRequest } = useHttp();
  const [journalDetails, setJournalDetails] = useState<
    Partial<JournalListType>
  >({});
  const userData = useSelector(getUserData);

  useEffect(() => {
    if (timeStamp && !journalDetails.ts && locationID && userData.token) {
      const handleResponses = (respones: AxiosHttpAllSettledResponsesType) => {
        if (respones?.[0].status === 'fulfilled') {
          const data = respones[0].value?.data?.data;
          if (data) {
            setJournalDetails(data);
          }
        } else {
          httpCallErrorHandling(respones?.[0]);
        }
      };

      const apiDetails = locationApi.getLocationJournalDetails(undefined, {
        locationID,
        ts: timeStamp,
      });

      sendRequest({
        requestConfig: [{ ...apiDetails }],
        headersConfig: { storeToken: userData.token },
        applyData: handleResponses,
      });
    }
  }, [timeStamp, journalDetails, locationID, userData.token, sendRequest]);

  let content = <Fragment></Fragment>;
  if (journalDetails?.entryType === 'DeviceSwap') {
    content = (
      <SwapContent
        deviceID={journalDetails?.deviceID ?? ''}
        locationID={journalDetails.locationID ?? ''}
        otherDevice={journalDetails?.data?.otherDevice ?? ''}
        otherLocation={journalDetails?.data?.otherLocation ?? ''}
      />
    );
  } else if (journalDetails?.entryType === 'LocationNote') {
    content = <NoteContent text={journalDetails.data} />;
  } else if (journalDetails?.entryType === 'DeviceMovement') {
    content = (
      <MovementContent
        deviceID={journalDetails?.deviceID ?? ''}
        locationID={journalDetails.locationID ?? ''}
        otherLocation={journalDetails?.data?.from ?? ''}
      />
    );
  } else if (journalDetails?.entryType === 'DeviceAssignment') {
    content = (
      <AssignmentContent
        deviceID={journalDetails?.deviceID ?? ''}
        locationID={journalDetails.locationID ?? ''}
        otherLocation={journalDetails?.data?.from ?? ''}
      />
    );
  } else if (journalDetails?.entryType === 'DeviceRemoval') {
    content = (
      <RemovalContent
        deviceID={journalDetails?.deviceID ?? ''}
        locationID={journalDetails.locationID ?? ''}
        otherDevice={journalDetails.data?.deviceID ?? ''}
        otherLocation={journalDetails?.data?.to ?? ''}
      />
    );
  } else if (journalDetails?.entryType === 'LocationGroupRemoval') {
    content = (
      <GroupRemovalContent
        locationID={journalDetails.locationID ?? ''}
        groupID={journalDetails.data?.groupID ?? ''}
      />
    );
  } else if (journalDetails?.entryType === 'LocationGroupAssignment') {
    content = (
      <GroupAssignmentContent
        locationID={journalDetails.locationID ?? ''}
        groupID={journalDetails.data?.groupID ?? ''}
      />
    );
  }

  return (
    <Fragment>
      <Modal
        closable={false}
        visible={showModal}
        onCancel={onClose}
        footer={null}
        width={720}
        style={{ height: 400 }}
        className="antdModalBodyFull">
        {isLoading ? <Skeleton loading={isLoading} active /> : content}

        {/* {isLoading ? (
          <Skeleton loading={isLoading} active />
        ) : (
          <Fragment>
            <Row justify="center" gutter={[0, 24]}>
              <Col>{journalDetails.deviceID}</Col>
            </Row>
            <Row justify="center">
              <Col xs={24}>
                <Timeline mode="alternate">
                  <Timeline.Item className={cssStyles.timelineItem}>
                    <Row>
                      <Col> Device: {journalDetails?.data?.otherDevice}</Col>
                    </Row>
                    <Row>
                      <Col>Location: {journalDetails?.data?.otherLocation}</Col>
                    </Row>
                  </Timeline.Item>
                  <Timeline.Item
                    dot={<AiOutlineClockCircle style={{ fontSize: '16px' }} />}
                    className={cssStyles.timelineItemLast}>
                    <Row>
                      <Col>Device: {journalDetails?.deviceID}</Col>
                    </Row>
                    <Row>
                      <Col>Location: {journalDetails?.locationID}</Col>
                    </Row>
                  </Timeline.Item>
                </Timeline>
              </Col>
            </Row>
          </Fragment>
        )} */}
      </Modal>
    </Fragment>
  );
};
