import { FormInputType } from '../../../type-definitions';
import { PartnerListType } from '../../../type-definitions/api-types';

export const initialElements: FormElementsType = {
  // 0
  partnerName: {
    elementType: 'select',
    elementConfig: {
      name: 'partnerName',
    },
    optionValues: [],
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Partner Name is required',
    label: 'Partner Name *',
  },
  // 1
  userName: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'User Name',
      name: 'userName',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'User Name is required',
    label: 'User Name *',
  },
  // 2
  userEmail: {
    elementType: 'input',
    elementConfig: {
      type: 'email',
      placeholder: 'User Email',
      name: 'userEmail',
    },
    value: '',
    validation: {
      // required: true,
      isEmail: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'User Email must be valid',
    label: 'User Email *',
  },
  // 3
  userPhone: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'User Phone',
      name: 'userPhone',
    },
    value: '',
    validation: {
      // isPhone: true,
      isNumericNotRequired: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'User Phone must be valid',
    label: 'User Phone',
  },
  // 4
  latitude: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Lat',
      name: 'latitude',
    },
    value: '0',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Lat is required',
    label: 'Lat',
    colValue: 4,
  },
  // 5
  longitude: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Lon',
      name: 'longitude',
    },
    value: '0',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Lon is required',
    label: 'Lon',
    colValue: 4,
  },
  // 6
  userLevel: {
    elementType: 'select',
    elementConfig: {
      name: 'userLevel',
    },
    optionValues: [],
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'User Level is required',
    label: 'User Level *',
  },
  // 7
  tempPassword: {
    elementType: 'input',
    elementConfig: {
      type: 'password',
      placeholder: 'Temporary Password',
      name: 'tempPassword',
      autoComplete: 'new-password',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Temporary Password is required',
    label: 'Temporary Password *',
  },
};

export type FormElementsType = {
  partnerName: FormInputType;
  userName: FormInputType;
  userEmail: FormInputType;
  userPhone: FormInputType;
  latitude: FormInputType;
  longitude: FormInputType;
  userLevel: FormInputType;
  tempPassword: FormInputType;
};

export const initState = {
  formElements: { ...initialElements },
  isChecked: false,
  showMap: false,
  mapZoom: 10,
  loadingPartner: true,
  loadingSubmit: false,
  partnerList: [],
  fromCdu: false,
  initLat: '0',
  initLng: '0',
  initZoom: 10,
  hasInputChanged: false,
};

export type StateType = {
  formElements: FormElementsType;
  isChecked: boolean;
  showMap: boolean;
  mapZoom: number;
  loadingPartner: boolean;
  loadingSubmit: boolean;
  partnerList: PartnerListType[];
  fromCdu: boolean;
  initLat: string;
  initLng: string;
  initZoom: number;
  hasInputChanged: boolean;
};
