import { FormInputType } from '../../../type-definitions';
import {
  HardwareAttributeOptionsType,
  MarkersListType,
  PartnerListType,
} from '../../../type-definitions/api-types';

export const detailsTabInputElements: DetailsTabElementsType = {
  // 0
  make: {
    elementType: 'input',
    elementConfig: {
      name: 'make',
      type: 'text',
      placeholder: 'Make',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Device Make is required',
    label: 'Device Make *',
  },
  // 1
  model: {
    elementType: 'input',
    elementConfig: {
      name: 'model',
      type: 'text',
      placeholder: 'Model',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Device Model is required',
    label: 'Device Model *',
  },
  // 2
  partner: {
    elementType: 'select',
    elementConfig: {
      name: 'partner',
    },
    value: '',
    optionValues: [],
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Partner ID is required',
    label: 'Partner ID *',
  },
};

export const sensorsTabInputElements: SensorsTabElementsType = {
  // 0
  shortName: {
    elementType: 'input',
    elementConfig: {
      name: 'shortName',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Short Name is required',
    label: 'Short Name',
    inputSize: 'middle',
  },
  // 1
  units: {
    elementType: 'input',
    elementConfig: {
      name: 'units',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Units is required',
    label: 'Units',
    inputSize: 'middle',
  },
  // 2
  longName: {
    elementType: 'input',
    elementConfig: {
      name: 'longName',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Long Name is required',
    label: 'Long Name',
    inputSize: 'middle',
  },
  // 3
  minScale: {
    elementType: 'input',
    elementConfig: {
      name: 'minScale',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Min Scale is required',
    label: 'Min Scale',
    inputSize: 'middle',
  },
  // 4
  maxScale: {
    elementType: 'input',
    elementConfig: {
      name: 'maxScale',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Max Scale is required',
    label: 'Max Scale',
    inputSize: 'middle',
  },
  // 7
  graphColour: {
    elementType: 'input',
    elementConfig: {
      name: 'graphColour',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Graph Colour is required',
    label: 'Graph Colour',
    inputSize: 'middle',
  },
  // 8
  compass: false,
  // 9
  uuid: '',
  markers: [],
};

export const attributesTabInputElements: AttributesTabElementsType = {
  required: true,
  name: {
    elementType: 'input',
    elementConfig: {
      name: 'name',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Attribute Name is required',
    label: 'Attribute Name',
  },
  label: {
    elementType: 'input',
    elementConfig: {
      name: 'label',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Attribute Label is required',
    label: 'Attribute Label',
  },
  helptext: {
    elementType: 'input',
    elementConfig: {
      name: 'helptext',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Helper Text is required',
    label: 'Helper Text',
  },
  chars: {
    elementType: 'input',
    elementConfig: {
      name: 'chars',
    },
    value: '',
    validation: {
      required: true,
      isNumeric: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Chars is required',
    label: 'Chars',
  },
  type: {
    elementType: 'select',
    elementConfig: {
      name: 'type',
    },
    value: 'string',
    optionValues: [
      { text: 'String', value: 'string' },
      { text: 'Date', value: 'date' },
      { text: 'Options', value: 'option' },
    ],
    validation: {
      // required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Type is required',
    label: 'Type',
  },
  default: {
    elementType: 'select',
    elementConfig: {
      name: 'default',
    },
    value: '',
    optionValues: [{ text: 'Please Select', value: '' }],
    validation: {
      // required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Default is required',
    label: 'Default',
  },
  options: [],
  uuid: '',
};

export const attributesTabOptionsInputElements: AttributesTabOptionsInputElementsType =
  {
    // 0
    label: {
      elementType: 'input',
      elementConfig: {
        name: 'label',
      },
      value: '',
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
      errorMessage: 'Label is required',
      label: 'Label',
    },
    // 1
    value: {
      elementType: 'input',
      elementConfig: {
        name: 'value',
      },
      value: '',
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
      errorMessage: 'Value is required',
      label: 'Value',
    },
    uuid: '',
  };

export interface DetailsTabElementsType {
  make: FormInputType;
  model: FormInputType;
  partner: FormInputType;
}

export interface SensorsTabElementsType {
  shortName: FormInputType;
  units: FormInputType;
  longName: FormInputType;
  minScale: FormInputType;
  maxScale: FormInputType;
  // amberPoint: FormInputType;
  // redPoint: FormInputType;
  graphColour: FormInputType;
  compass: boolean;
  uuid: string;
  markers: MarkersListType[];
}

export interface AttributesOptionsType extends HardwareAttributeOptionsType {
  uuid: string;
}

export interface AttributesTabElementsType {
  name: FormInputType;
  label: FormInputType;
  helptext: FormInputType;
  type: FormInputType;
  chars: FormInputType;
  required: boolean;
  default: FormInputType;
  options: AttributesOptionsType[];
  uuid: string;
}

export interface AttributesTabOptionsInputElementsType {
  label: FormInputType;
  value: FormInputType;
  uuid: string;
}

export interface StateType {
  loading: boolean;
  loadingButton: boolean;
  currentTabIndex: string;
  detailsTabElements: DetailsTabElementsType;
  sensorsTabElements: SensorsTabElementsType[];
  attributesTabElements: AttributesTabElementsType[];
  partnerList: PartnerListType[];
  hasInputChanged: boolean;
}
