import axios from 'axios';
import { Fragment, Component } from 'react';
import update from 'immutability-helper';
import cloneDeep from 'lodash/cloneDeep';

import { assetApi, floorPlanApi } from '../../api-services/api-list';
import { handleNotification } from '../../utils/notification-handler';

import {
  PropsType,
  StateType,
} from '../components/WebViewFloorPlanEngineer/helpers';
import { webViewApiCall } from '../services/api-call';
import { FloorPlanLocationListType } from '../../type-definitions/api-types';
import { v4 } from 'uuid';
import { Button, Card, Col, Row } from 'antd';
import FloorPlanMapMultiple from '../components/WebViewFloorPlanEngineer/FloorPlanMapMultiple';

import cssStyles from '../components/WebViewFloorPlanEngineer/styles/webViewFloorPlanEngineer.module.scss';
import Modal from 'antd/lib/modal/Modal';
import SwapModal from '../components/WebViewFloorPlanEngineer/SwapModal';
import { Popup } from 'react-leaflet';
import { handleFormBody } from '../../utils';

class WebViewFloorPlanEngineer extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    const { match, location } = props;

    let isEditable = false;
    const tempRight = match?.params?.isEditable;
    if (tempRight) {
      isEditable = JSON.parse(tempRight);
    }

    const search = location?.search;
    let assetID: string | undefined;
    let mapWidth: number | undefined;
    let mapHeight: number | undefined;
    let userLat: number | undefined;
    let userLng: number | undefined;

    if (search) {
      const params = new URLSearchParams(search);
      const tempID = params.get('assetid');
      const tempWidth = params.get('mapwidth');
      const tempHeight = params.get('mapheight');
      const tempLat = params.get('lat');
      const tempLng = params.get('lng');
      if (tempID) {
        assetID = tempID;
      }
      if (tempWidth) {
        mapWidth = JSON.parse(tempWidth);
      }
      if (tempHeight) {
        mapHeight = JSON.parse(tempHeight);
      }
      if (tempLat) {
        userLat = JSON.parse(tempLat);
      }
      if (tempLng) {
        userLng = JSON.parse(tempLng);
      }
    }

    this.state = {
      loading: true,
      mapDetails: { width: mapWidth, height: mapHeight },
      floorPlanLocationList: [],
      showSwapModal: false,
      selectedFloorPlan: {},

      token: match?.params?.token,
      floorPlanID: match?.params?.floorPlanID,
      isEditable: isEditable,

      assetID: assetID,
      userLat: userLat,
      userLng: userLng,
      currentlyEditing: {},
    };
  }

  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    this._isMounted = true;

    const { match } = this.props;
    const floorPlanID = match?.params?.floorPlanID;
    if (floorPlanID) {
      this.handleFetchedData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  handleState = (data: Partial<StateType>) => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          ...prevState,
          ...data,
        };
      });
  };

  handleFetchedData = async () => {
    const { floorPlanID, token, assetID, mapDetails } = this.state;
    let stateData: Partial<StateType> = {
      ...this.state,
    };

    if (
      floorPlanID &&
      token &&
      assetID &&
      mapDetails.width &&
      mapDetails.height
    ) {
      try {
        const { url, method, contentType } = assetApi.getAsset(undefined, {
          assetID,
        });
        const response = await webViewApiCall({
          token,
          url,
          method,
          contentType,
          cancelToken: this.axiosCancelSource.token,
        });

        const floorPlanImage = response?.data;
        let apiResponseContentType = '';
        if (response?.headers) {
          apiResponseContentType = response?.headers?.['content-type'];
        }

        if (floorPlanImage) {
          stateData = update(stateData, {
            mapDetails: {
              image: {
                $set:
                  apiResponseContentType === 'image/x-icon'
                    ? ''
                    : floorPlanImage || '',
              },
            },
          });
        }
      } catch (error) {
        this._isMounted && handleNotification('error', error.data);
      }

      try {
        const { url, method, contentType } = floorPlanApi.getFloorPlanLocations(
          undefined,
          {
            floorPlanID,
          }
        );

        const response = await webViewApiCall({
          token,
          url,
          method,
          contentType,
          cancelToken: this.axiosCancelSource.token,
        });

        const result = response?.data;
        if (result?.status === 'ok') {
          if (result.data && result.data.length > 0) {
            const tempFloorPlans: FloorPlanLocationListType[] = result.data.map(
              (item: FloorPlanLocationListType) => ({
                ...item,
                uuid: v4(),
              })
            );

            stateData = update(stateData, {
              floorPlanLocationList: { $set: tempFloorPlans },
            });
          }
        } else {
          this._isMounted && handleNotification('error', result);
        }
      } catch (error) {
        this._isMounted && handleNotification('error', error.data);
      }
    }

    stateData = update(stateData, { loading: { $set: false } });

    this.handleState({ ...stateData });
  };

  onMarkerSwapButtonClick = ({
    locationID,
    deviceID,
    status,
  }: {
    locationID?: string;
    deviceID?: string;
    status?: string;
  }) => {
    if (deviceID && locationID && status) {
      const floorPlan = { status, deviceID, locationID };
      this.handleState({ selectedFloorPlan: floorPlan, showSwapModal: true });
    }
  };

  onSwapModalClose = () => {
    this.handleState({ showSwapModal: false });
  };

  onEditPositionClick = ({
    locationID,
    deviceID,
    status,
  }: {
    locationID?: string;
    deviceID?: string;
    status?: string;
  }) => {
    this.handleState({ currentlyEditing: { locationID, deviceID, status } });
  };

  onUpdateLocation = async (record: FloorPlanLocationListType) => {
    const { floorPlanLocationList, token } = this.state;

    const data = {
      x: record.x,
      y: record.y,
    };
    const formData = handleFormBody(data);

    if (token) {
      // this.handleState({ loading: true });
      try {
        const { url, method, contentType } =
          floorPlanApi.patchFloorPlanLocation(undefined, {
            floorPlanID: record.floorplanID,
            locationID: record.locationID,
          });

        const response = await webViewApiCall({
          token,
          url,
          method,
          contentType,
          data: formData,
          cancelToken: this.axiosCancelSource.token,
        });

        const result = response?.data;

        if (result?.status === 'ok') {
          this._isMounted && handleNotification('success', result);
          let tempFloorPlans = cloneDeep(floorPlanLocationList);
          tempFloorPlans = tempFloorPlans.map((el) => {
            let tempObj = { ...el };
            if (el.locationID === record.locationID) {
              // tempObj.position = record.position;
              tempObj.x = record.x;
              tempObj.y = record.y;
            }

            return tempObj;
          });

          this.handleState({
            floorPlanLocationList: tempFloorPlans,
            currentlyEditing: {},
            // loading: false,
          });
        }
      } catch (error) {
        this._isMounted && handleNotification('error', error.data);
      }
    }
  };

  render() {
    const {
      mapDetails,
      floorPlanLocationList,
      isEditable,
      loading,
      showSwapModal,
      selectedFloorPlan,
      token,
      currentlyEditing,
    } = this.state;

    return (
      <Fragment>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100vw',
            height: '100vh',
          }}>
          <Card bordered={false} loading={loading} style={{ width: '100vw' }}>
            <Row align="middle">
              <Col xs={24}>
                <FloorPlanMapMultiple
                  mapDetails={mapDetails}
                  floorPlanLocationList={floorPlanLocationList}
                  classes={cssStyles.floorPlanMap}
                  isEditable={isEditable || false}
                  currentlyEditing={currentlyEditing}
                  onUpdateLocation={this.onUpdateLocation}
                  mapPopup={({ deviceID, locationID, status, isEditable }) => (
                    <MapPopup
                      deviceID={deviceID}
                      locationID={locationID}
                      status={status}
                      isEditable={isEditable}
                      onMarkerButtonClick={this.onMarkerSwapButtonClick}
                      onEditPositionClick={this.onEditPositionClick}
                      currentlyEditing={currentlyEditing}
                    />
                  )}
                />
              </Col>
            </Row>
          </Card>
        </div>
        {showSwapModal && (
          <Modal
            width={'95%'}
            visible={showSwapModal}
            footer={null}
            closable={false}
            onCancel={this.onSwapModalClose}>
            <SwapModal
              selectedFloorPlan={selectedFloorPlan}
              token={token || ''}
              onSwapModalClose={this.onSwapModalClose}
            />
          </Modal>
        )}
      </Fragment>
    );
  }
}

export default WebViewFloorPlanEngineer;

function MapPopup({
  isEditable,
  deviceID,
  locationID,
  status,
  onMarkerButtonClick,
  onEditPositionClick,
  currentlyEditing,
}: {
  isEditable?: boolean;
  locationID?: string;
  deviceID?: string;
  status?: string;
  onMarkerButtonClick: (params: {
    locationID?: string;
    deviceID?: string;
    status?: string;
  }) => void;
  onEditPositionClick: (params: {
    locationID?: string;
    deviceID?: string;
    status?: string;
  }) => void;
  currentlyEditing?: {
    locationID?: string;
    deviceID?: string;
    status?: string;
  };
}) {
  return (
    <Popup>
      <Row justify="center" align="middle">
        <Col>
          <Button
            disabled={!isEditable}
            type="primary"
            size="small"
            htmlType="button"
            onClick={() =>
              onMarkerButtonClick({ locationID, deviceID, status })
            }>
            {deviceID === 'UNALLOCATED' || status === 'STOCK'
              ? 'Add Device'
              : 'Swap Device'}
          </Button>
        </Col>
      </Row>

      <Row justify="center" align="middle">
        <Col>
          <Button
            type="primary"
            size="small"
            htmlType="button"
            onClick={() =>
              onEditPositionClick({ locationID, deviceID, status })
            }
            disabled={currentlyEditing?.locationID === locationID}>
            Edit Position
          </Button>
        </Col>
      </Row>
    </Popup>
  );
}
