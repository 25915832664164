export enum DeviceListEnumKeys {
  'mac',
  'locID',
  'locationName',
  'serial',
  'manufacturer',
  'model',
  'locationStatus',
  'partnerID',
  'createdBy',
  'rights',
  'uuid',
}

export enum UserListEnumKeys {
  'uid',
  'username',
  'level',
  'suppresspublic',
  'partnerid',
  'email',
  'phone',
  'usertype',
  'allocatenewtokenonlogin',
  'lat',
  'lng',
  'defaultGroups',
  'rights',
  'uuid',
}

export enum AssignableLocationsEnumKeys {
  'locationID',
  'locationName',
  'description',
  'friendlyName',
  'locationMac',
  'status',
  'partnerID',
  'level',
  'capacity',
  'lat',
  'lng',
  'lastContact',
  'rights',
}

export enum SubstituteDevicesEnumKeys {
  'mac',
  'locID',
  'locationName',
  'serial',
  'manufacturer',
  'model',
  'locationStatus',
  'partnerID',
  'createdBy',
  'rights',
  'uuid',
}

export enum StockLocationEnumKeys {
  'locationID',
  'locationName',
  'description',
  'capacity',
  'stockLevel',
  'status',
  'partnerID',
  'rights',
}

export enum SensorListEnumKeys {
  'short_name',
  'long_name',
  'units',
  'min_scale',
  'max_scale',
  'amber_point',
  'red_point',
  'graph_colour',
  'icon',
  'compass',
}

export enum UserRightListEnumKeys {
  'partnerid',
  'partnername',
  'Rights',
}

// export enum UserDataEnumKeys {
//   'status',
//   'uid',
//   'level',
//   'username',
//   'partner',
//   'lat',
//   'lng',
//   'oldtoken',
//   'token',
//   'usertype',
//   'message',
// }
export enum UserDataEnumKeys {
  'userID',
  'username',
  'email',
  'lat',
  'lng',
  'phone',
  'token',
  'type',
  'level',
  'partnerID',
  'partnerLat',
  'partnerLng',
}

export enum UserDetailsEnumKeys {
  'uid',
  'username',
  'level',
  'suppresspublic',
  'partnerid',
  'email',
  'phone',
  'usertype',
  'allocatenewtokenonlogin',
  'lat',
  'lng',
  'defaultGroups',
  'rights',
}

export enum GroupListEnumKeys {
  'groupID',
  'groupName',
  'groupType',
  'partnerID',
  'rights',
  'uuid',
}

export enum LocationListEnumKeys {
  'locationID',
  'locationName',
  'description',
  'friendlyName',
  'locationMac',
  'status',
  'partnerID',
  'level',
  'capacity',
  'lat',
  'lng',
  'lastContact',
  'rights',
}

export enum StockDevicesEnumKeys {
  'deviceID',
  'locationID',
  'partnerID',
  'locationName',
  'manufacturer',
  'model',
  'Status',
  'rights',
  'uuid',
}

export enum LocationDetailsEnumKeys {
  'locationID',
  'locationMac',
  'partnerID',
  'locationName',
  'locationLat',
  'locationLng',
  'height',
  'friendlyDescription',
  'friendlyLocation',
  'friendlyName',
  'lastContact',
  'defaultGroups',
  'status',
  'static',
  'internalflag',
  'notes',
  'level',
  'capacity',
  'address',
  'sponsorHTML',
  'copyright',
  'rights',
  'Groups',
}

export enum AssignableDeviceListEnumKeys {
  'mac',
  'locID',
  'locationName',
  'serial',
  'manufacturer',
  'model',
  'locationStatus',
  'partnerID',
  'createdBy',
  'rights',
}

export enum GroupDetailsEnumKeys {
  'group_id',
  'group_name',
  'grouptype',
  'partner_id',
  'mindate',
  'maxdate',
  'maxdaysfromtoday',
  'allPhenoms',
  'phenomlist',
  'extendedpermissions',
  'devicemanagementpermissions',
  'rights',
}

export enum FloorPlanListEnumKeys {
  'floorplanID',
  'name',
  'description',
  'assetID',
  'partnerID',
  'bounds',
  'lat',
  'lng',
  'width',
  'height',
  'noOfLocations',
  'rights',
  'uuid',
}

export enum FloorPlanLocationListEnumKeys {
  'floorplanID',
  'locationID',
  'deviceID',
  'locationName',
  'x',
  'y',
  'height',
  'lastContact',
  'status',
}

export enum FloorPlanDetailsEnumKeys {
  'floorplanID',
  'name',
  'description',
  'assetID',
  'partnerID',
  'bounds',
  'lat',
  'lng',
  'width',
  'height',
  'noOfLocations',
  'rights',
}

export enum HardwareListEnumKeys {
  'id',
  'make',
  'model',
  'attributes',
  'sensors',
  'calibrationValues',
  'flows',
  'partnerID',
  'rights',
}

export enum GroupUserListEnumKeys {
  'userID',
  'username',
  'level',
  'type',
  'partnerID',
}

export enum GroupAssignableUserListEnumKeys {
  'uid',
  'username',
  'level',
  'suppresspublic',
  'partnerid',
  'email',
  'phone',
  'usertype',
  'allocatenewtokenonlogin',
  'lat',
  'lng',
  'defaultGroups',
  'rights',
}
