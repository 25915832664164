import {
  ERRORED,
  SET_USER_RIGHTS,
  SAVE_PAGINATION,
  CLEAR_PAGINATION_DONE,
  // SAVE_NAVBAR_DETAILS,
  SHARED_SUCCESS,
} from '../actionTypes/shared.types';
import { LOGOUT } from '../actionTypes/auth.types';
import {
  PaginationDetailsType,
  ReduxActionType,
  ReduxSharedStateType,
} from '../../type-definitions';

const initialState: Partial<ReduxSharedStateType> = {
  error: {},
  userPermissionList: [],
  paginationDetails: {
    groups: 1,
    devices: 1,
    users: 1,
    locations: 1,
    floorPlans: 1,
    hardwareList: 1,
  },
  navbarDetails: {},
};

function reducer(state = initialState, action: ReduxActionType) {
  switch (action.type) {
    case ERRORED:
      return {
        ...state,
        error: action.payload?.error,
        loading: false,
      };

    case SET_USER_RIGHTS:
      return {
        ...state,
        userPermissionList: action.payload?.userPermission || [],
      };

    case LOGOUT:
      return {
        ...state,
        ...initialState,
      };

    case SAVE_PAGINATION:
      const paginationKey: string | undefined = action?.payload.paginationKey;
      const paginationValue: number | undefined =
        action?.payload?.paginationValue;
      const tempPagination: any = {
        ...state.paginationDetails,
      };
      if (tempPagination && paginationKey && paginationValue) {
        tempPagination[paginationKey] = paginationValue;
      }
      return {
        ...state,
        paginationDetails: tempPagination,
      };

    case CLEAR_PAGINATION_DONE:
      let tempPagination2: PaginationDetailsType = {
        ...state.paginationDetails,
      };
      if (action?.payload?.paginationData) {
        const tempPageData: any = action?.payload?.paginationData;
        if (tempPageData && Object.keys(tempPageData).length > 0) {
          tempPagination2 = tempPageData;
        }
      }

      return {
        ...state,
        paginationDetails: tempPagination2,
      };

    // case SAVE_NAVBAR_DETAILS:
    //   const navbarKey = action?.payload?.navbarKey;
    //   const navbarValue = action?.payload?.navbarValue;
    //   const tempNavbar: { [k: string]: boolean } = { ...state.navbarDetails };
    //   for (const key in tempNavbar) {
    //     if (key) {
    //       tempNavbar[key] = false;
    //     }
    //   }
    //   if (navbarKey) {
    //     if (navbarValue === undefined || navbarValue === null) {
    //       tempNavbar[navbarKey] = true;
    //     } else {
    //       tempNavbar[navbarKey] = false;
    //     }
    //   }
    //   return {
    //     ...state,
    //     navbarDetails: tempNavbar,
    //   };

    case SHARED_SUCCESS:
      if (action.payload && Object.keys(action.payload).length > 0) {
        return {
          ...state,
          ...action.payload,
        };
      }
      return {
        ...state,
      };

    default:
      return state;
  }
}

export default reducer;
