import { Component, FormEvent, Fragment } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import update from 'immutability-helper';

import { updateToken } from '../redux/actions';
import {
  GroupListType,
  PartnerListType,
  UserDataType,
  UserRightListType,
} from '../type-definitions/api-types';
import {
  StateType,
  detailsTabElements,
  deviceTabElements,
  groupsTabElements,
  addressTabElements,
  FormElementsType,
} from '../components/LocationAdd/helpers';
import axios from 'axios';
import { message, Tabs } from 'antd';
import { apiCall } from '../api-services/api';
import { locationApi, partnerApi } from '../api-services/api-list';
import { SelectOptionsType } from '../type-definitions';
import { checkValidation } from '../utils/validation';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { locationRoutes } from '../Routes/routes-list';
import DetailsTab from '../components/LocationAdd/components/DetailsTab';
import AddressTab from '../components/LocationAdd/components/AddressTab';
import DeviceTab from '../components/LocationAdd/components/DeviceTab';
import { locationRights } from '../utils/permission-list';
import FormWrapper from '../components/FormWrapper';
import AssignStockDeviceModal from '../components/LocationAdd/components/AssignStockDeviceModal';
import Modal from 'antd/lib/modal/Modal';
import LeafletMap from '../components-shared/LeafletMap';
import GroupsTab from '../components/LocationAdd/components/GroupsTab';
import PromptPopup from '../components/PromptPopup';
import { handleNotification } from '../utils/notification-handler';

type PropsType = RouteComponentProps &
  PropsFromRedux & {
    userData: Partial<UserDataType>;
    userPermissionList: string[];
    userRightList: UserRightListType[];
  };

class LocationAdd extends Component<PropsType, StateType> {
  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  constructor(props: PropsType) {
    super(props);
    this.state = {
      formElements: {
        ...detailsTabElements,
        ...addressTabElements,
        ...groupsTabElements,
        ...deviceTabElements,
        locationMac: {
          ...deviceTabElements.locationMac,
          styles: { cursor: 'pointer' },
          onInputClick: this.handleAssignStockDevice,
        },
      },
      staticCheck: false,
      showMap: false,
      mapZoom: 6,
      loading: true,
      partnerList: [],
      showAssignStockDevice: false,
      defaultGroupsChecked: true,
      currentTabIndex: '1',
      locationGroupList: [],
      initLat: '0',
      initLng: '0',
      initZoom: 6,
      hasInputChanged: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    this.handleFetchData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  fetchData = async () => {
    const { userData, updateToken } = this.props;
    try {
      const { url, method, contentType } = partnerApi.getPartners();
      const response = await apiCall({
        storeToken: userData.token,
        url,
        method,
        contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      updateToken(result);
      if (result.status === 'ok') {
        return { partners: result.data || [] };
      } else {
        return { partners: [], error: result };
      }
    } catch (error) {
      return { error };
    }
  };

  handleFetchData = async () => {
    const { partnerList, formElements } = this.state;
    let error, partners: PartnerListType[];
    if (partnerList.length === 0) {
      const response = await this.fetchData();
      error = response.error;
      partners = response.partners;
    } else {
      partners = partnerList;
    }
    if (error) {
      this._isMounted && handleNotification('error', error?.data);
      this._isMounted && this.setState({ loading: false });
    } else {
      let tempFormElements = { ...formElements };

      const statusOptionValues: SelectOptionsType[] = [
        { value: 'STOCK', text: 'STOCK' },
        { value: 'LIVE', text: 'LIVE' },
        { value: 'OFFLINE', text: 'OFFLINE' },
      ];

      if (partners && partners.length > 0) {
        partners = partners.filter((el) =>
          el.rights.includes(locationRights.create)
        );
      }

      if (partners && partners.length >= 1) {
        const partnerOptionValues: SelectOptionsType[] = [];
        partners.forEach((item) => {
          if (item?.partnerID && item?.partnerName) {
            partnerOptionValues.push({
              text: item.partnerName,
              value: item.partnerID,
            });
          }
        });
        if (partnerOptionValues.length > 0) {
          tempFormElements = update(tempFormElements, {
            partnerName: { optionValues: { $set: [...partnerOptionValues] } },
          });
          const selected = partners.find(
            (item) => item?.partnerID === 'AIRSENSA'
          );

          const matchedPartner = this.getMatchedPartner(
            selected?.partnerID ?? partners[0].partnerID
          );

          if (selected) {
            tempFormElements = update(tempFormElements, {
              locationID: { notVisible: { $set: !matchedPartner } },
              partnerName: { value: { $set: selected.partnerID } },
              latitude: { value: { $set: selected.lat.toString() } },
              longitude: { value: { $set: selected.lng.toString() } },
            });
          } else {
            tempFormElements = update(tempFormElements, {
              locationID: { notVisible: { $set: !matchedPartner } },
              partnerName: { value: { $set: partners[0].partnerID } },
              latitude: { value: { $set: partners[0].lat.toString() } },
              longitude: { value: { $set: partners[0].lng.toString() } },
            });
          }
        }
      } else if (partners && partners.length === 1) {
        tempFormElements = update(tempFormElements, {
          partnerName: {
            elementType: { $set: 'input' },
            elementConfig: {
              type: { $set: 'text' },
              placeholder: { $set: 'Partner Name' },
            },
            value: { $set: partners[0]?.partnerID || '' },
            disabled: { $set: true },
          },
        });
      }

      tempFormElements = update(tempFormElements, {
        status: {
          value: { $set: statusOptionValues[1].value },
          optionValues: { $set: [...statusOptionValues] },
        },
      });

      this._isMounted &&
        this.setState({
          loading: false,
          formElements: tempFormElements,
          partnerList: partners,
          initLat: tempFormElements.latitude.value,
          initLng: tempFormElements.longitude.value,
        });
    }
  };

  getMatchedPartner = (partnerId: string) => {
    const { userRightList } = this.props;

    return userRightList?.find((el) => {
      if (el.partnerid === partnerId) {
        return el.Rights?.includes(locationRights.setLocationId);
      }
      return false;
    });
  };

  inputChangedHandler = (name: keyof FormElementsType, value: any) => {
    const { formElements, partnerList } = this.state;

    let tempFormElements = { ...formElements };

    if (name) {
      if (
        value &&
        (name === 'latitude' || name === 'longitude') &&
        !checkValidation(value, { isNegativeFloat: true })
      ) {
        message.warning('Enter valid input!!');
        return;
      }

      if (
        value &&
        name === 'capacity' &&
        !checkValidation(value, { isNumeric: true })
      ) {
        message.warning('Please enter valid input');
        return;
      }

      tempFormElements = update(tempFormElements, {
        [name]: {
          value: { $set: value },
          touched: { $set: true },
          valid: {
            $set: checkValidation(value, tempFormElements[name].validation),
          },
        },
      });

      if (name === 'partnerName') {
        const matched = partnerList.find((el) => el.partnerID === value);
        const matchedPartner = this.getMatchedPartner(value);

        if (matched) {
          tempFormElements = update(tempFormElements, {
            latitude: { value: { $set: matched.lat.toString() } },
            longitude: { value: { $set: matched.lng.toString() } },
          });
        }

        if (matchedPartner) {
          tempFormElements = update(tempFormElements, {
            locationID: { notVisible: { $set: !matchedPartner } },
          });
        }
      }

      this._isMounted &&
        this.setState({
          formElements: tempFormElements,
          hasInputChanged: true,
          initLat: tempFormElements.latitude.value,
          initLng: tempFormElements.longitude.value,
        });
    }
  };

  onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    this.handleSubmit();
  };

  handleSubmit = async (onConfirm?: () => void) => {
    const {
      formElements,
      staticCheck,
      defaultGroupsChecked,
      locationGroupList,
    } = this.state;
    const { userData, updateToken } = this.props;

    let tempFormElements = { ...formElements };

    let key: keyof typeof tempFormElements;

    for (key in tempFormElements) {
      tempFormElements = update(tempFormElements, {
        [key]: {
          touched: { $set: true },
          valid: {
            $set: checkValidation(
              tempFormElements[key].value,
              tempFormElements[key].validation
            ),
          },
        },
      });
    }

    this._isMounted && this.setState({ formElements: tempFormElements });

    if (
      tempFormElements.status.value === 'STOCK' &&
      !tempFormElements.locationID.notVisible
    ) {
      for (key in tempFormElements) {
        if (!tempFormElements[key].valid) {
          message.error('Please fill all the fields');
          return;
        }
      }
    } else {
      for (key in tempFormElements) {
        if (
          key !== 'capacity' &&
          key !== 'locationID' &&
          !tempFormElements[key].valid
        ) {
          message.error('Please fill all the fields');
          return;
        }
      }
    }

    const dataProps: Partial<{
      defaultGroups: boolean;
      groupid: string[];
      capacity: string;
    }> = {};
    if (defaultGroupsChecked) {
      dataProps.defaultGroups = true;
    } else {
      const tempLocationGroupList = locationGroupList.map(
        (item) => item.groupID
      );
      dataProps.groupid = tempLocationGroupList;
    }

    if (tempFormElements.status.value === 'STOCK') {
      dataProps.capacity = tempFormElements.capacity.value;
    }

    const data = {
      location_name: tempFormElements.locationName.value,
      location_mac: tempFormElements.locationMac.value,
      status: tempFormElements.status.value,
      // static: staticCheck || 'false',
      static: staticCheck,
      friendly_name: tempFormElements.friendlyName.value,
      friendly_description: tempFormElements.friendlyDesc.value,
      location_lat: tempFormElements.latitude.value,
      location_lng: tempFormElements.longitude.value,
      partner_id: tempFormElements.partnerName.value,
      // ## tab section
      address: {
        street: tempFormElements.street.value,
        street2: tempFormElements.street2.value,
        city: tempFormElements.city.value,
        province: tempFormElements.province.value,
        postalcode: tempFormElements.postalCode.value,
        country: tempFormElements.country.value,
      },
      ...dataProps,
    };

    let tempData = [];
    let key2: keyof typeof data;
    for (key2 in data) {
      const val: any = data[key2];
      const encodedValue = encodeURIComponent(val);
      if (typeof data[key2] === 'object' || key2 === 'groupid') {
        tempData.push(key2 + '=' + JSON.stringify(data[key2]));
      } else {
        tempData.push(key2 + '=' + encodedValue);
      }
    }

    const formData: string = tempData.join('&');

    this._isMounted && this.setState({ loading: true });

    try {
      const { url, method, contentType } = locationApi.postLocation();
      const response = await apiCall({
        storeToken: userData.token,
        url,
        method,
        contentType,
        data: formData,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      updateToken(result);
      if (result.status === 'ok') {
        this._isMounted && handleNotification('success', result);
        this._isMounted &&
          this.setState(
            {
              loading: false,
              hasInputChanged: false,
            },
            () => {
              const { history } = this.props;
              setTimeout(() => {
                history.push({
                  pathname: locationRoutes.details(),
                  state: {
                    locationID: result?.data,
                    allowAllEdit: true,
                    hasStock:
                      tempFormElements.status.value === 'STOCK' ? true : false,
                  },
                });
              }, 1000);
            }
          );
      } else {
        this._isMounted && handleNotification('error', result);
        this._isMounted && this.setState({ loading: false });
      }
    } catch (error: any) {
      this._isMounted && handleNotification('error', error?.data);
      this._isMounted && this.setState({ loading: false });
    }
  };

  // ## --- dynamically call the setState method ---
  // handleChange = <T extends keyof StateType>(
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const newState = {
  //     [event.target.name]: event.target.value,
  //     // keyNotInState: '42', -> would throw a compile time error
  //     // numericKeyInState: 'assigning wrong type' -> would throw a compile time error
  //   };
  //   this.setState(newState as { [P in T]: StateType[P] });
  // };

  // handleCheckbox = (name: string, checked: boolean) => {
  //   if (name) {
  //     this._isMounted &&
  //       this.setState((prevState) => ({
  //         ...prevState,
  //         [name]: checked,
  //       }));
  //   }
  // };

  handleCheckbox = <T extends keyof StateType>(event: CheckboxChangeEvent) => {
    const name = event.target.name;
    const checked = event.target.checked;
    if (name) {
      const stateData = {
        [name]: checked,
      };
      this._isMounted && this.setState(stateData as { [P in T]: StateType[P] });
    }
  };

  onOkayMapModal = () => {
    const { formElements, mapZoom } = this.state;

    this._isMounted &&
      this.setState((prevState) => {
        return {
          showMap: !prevState.showMap,
          initLat: formElements.latitude.value,
          initLng: formElements.longitude.value,
          initZoom: mapZoom,
        };
      });
  };

  onCancelMapModal = () => {
    const { formElements, initLat, initLng, initZoom } = this.state;

    const tempFormElements = update(formElements, {
      latitude: { value: { $set: initLat } },
      longitude: { value: { $set: initLng } },
    });

    this._isMounted &&
      this.setState((prevState) => {
        return {
          showMap: !prevState.showMap,
          formElements: tempFormElements,
          mapZoom: initZoom,
        };
      });
  };

  onMarkerMove = (lat: number, lng: number) => {
    const { formElements } = this.state;
    const tempFormElements = update(formElements, {
      latitude: { value: { $set: lat.toString() } },
      longitude: { value: { $set: lng.toString() } },
    });

    this._isMounted && this.setState({ formElements: tempFormElements });
  };

  onMapZoom = (zoom: number) => {
    this._isMounted && this.setState({ mapZoom: zoom });
  };

  handleAssignStockDevice = () => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          showAssignStockDevice: !prevState.showAssignStockDevice,
        };
      });
  };

  onAssignStockDeviceSelect = (deviceID: string) => {
    const { formElements } = this.state;
    const tempFormElements = update(formElements, {
      locationMac: { value: { $set: deviceID } },
    });

    this._isMounted &&
      this.setState({
        showAssignStockDevice: false,
        formElements: tempFormElements,
      });
  };

  handleRedirect = () => {
    const { history } = this.props;
    history.push({
      pathname: locationRoutes.list,
    });
  };

  setCurrentTabIndex = (index: string) => {
    const { formElements } = this.state;
    let tempFormElements = { ...formElements };
    let key: keyof typeof tempFormElements;
    let elementsName: string[] = [];
    if (index === '2') {
      elementsName = Object.keys(detailsTabElements);
      for (key in tempFormElements) {
        if (elementsName.includes(key)) {
          tempFormElements = update(tempFormElements, {
            [key]: {
              touched: { $set: true },
              valid: {
                $set: checkValidation(
                  tempFormElements[key].value,
                  tempFormElements[key].validation
                ),
              },
            },
          });
        }
      }

      this._isMounted && this.setState({ formElements: tempFormElements });

      if (
        tempFormElements.status.value === 'STOCK' &&
        !tempFormElements.locationID.notVisible
      ) {
        for (key in tempFormElements) {
          if (elementsName.includes(key) && !tempFormElements[key].valid) {
            message.error('Please fill all the fields');
            return;
          }
        }
      } else {
        for (key in tempFormElements) {
          if (
            elementsName.includes(key) &&
            key !== 'capacity' &&
            key !== 'locationID' &&
            !tempFormElements[key].valid
          ) {
            message.error('Please fill all the fields');
            return;
          }
        }
      }
    } else if (index === '3') {
      elementsName = Object.keys(addressTabElements);
    }
    if (index !== '2') {
      for (key in tempFormElements) {
        if (elementsName.includes(key)) {
          tempFormElements = update(tempFormElements, {
            [key]: {
              touched: { $set: true },
              valid: {
                $set: checkValidation(
                  tempFormElements[key].value,
                  tempFormElements[key].validation
                ),
              },
            },
          });
        }
      }

      this._isMounted && this.setState({ formElements: tempFormElements });

      for (key in tempFormElements) {
        if (elementsName.includes(key) && !tempFormElements[key].valid) {
          message.error('Please fill all the fields');
          return;
        }
      }
    }

    this._isMounted && this.setState({ currentTabIndex: index });
  };

  handlePopupConfirm = (onConfirm: () => void) => {
    this.handleSubmit();
  };

  onOkayLocationGroupsTab = (locationGroups: GroupListType[]) => {
    // const { formElements } = this.state;
    // formElements.locationGroupInput.value = locationGroups
    //   .map((item) => ` ${item.groupID}`)
    //   .join(', ');

    this.setState({
      // formElements,
      locationGroupList: locationGroups,
    });
  };

  getTabData = () => {
    const { formElements, staticCheck, defaultGroupsChecked } = this.state;

    const detailsElements = (
      <DetailsTab
        formElements={formElements}
        inputChangedHandler={this.inputChangedHandler}
        onCancelMapModal={this.onCancelMapModal}
        staticCheck={staticCheck}
        handleCheckbox={this.handleCheckbox}
        setCurrentTabIndex={this.setCurrentTabIndex}
      />
    );

    const addressElements = (
      <AddressTab
        formElements={formElements}
        inputChangedHandler={this.inputChangedHandler}
        setCurrentTabIndex={this.setCurrentTabIndex}
      />
    );

    const groupElements = (
      <GroupsTab
        defaultGroupsChecked={defaultGroupsChecked}
        formElements={formElements}
        inputChangedHandler={this.inputChangedHandler}
        handleCheckbox={this.handleCheckbox}
        setCurrentTabIndex={this.setCurrentTabIndex}
        partnerID={formElements.partnerName.value}
        // locationGroupList={locationGroupList}
        onOkayLocationGroupsTab={this.onOkayLocationGroupsTab}
      />
    );

    const deviceElements = (
      <DeviceTab
        formElements={formElements}
        inputChangedHandler={this.inputChangedHandler}
        setCurrentTabIndex={this.setCurrentTabIndex}
      />
    );

    return {
      detailsElements,
      addressElements,
      groupElements,
      deviceElements,
    };
  };

  render() {
    const { userPermissionList } = this.props;
    const {
      showMap,
      formElements,
      mapZoom,
      loading,
      showAssignStockDevice,
      currentTabIndex,
      hasInputChanged,
    } = this.state;

    if (userPermissionList.length === 0) {
      return <Redirect to="/" />;
    }

    const { detailsElements, addressElements, groupElements, deviceElements } =
      this.getTabData();

    return (
      <Fragment>
        {hasInputChanged && (
          <PromptPopup
            when={hasInputChanged}
            handleConfirm={this.handlePopupConfirm}
          />
        )}

        <FormWrapper loading={loading} title="Location Add">
          <form onSubmit={this.onFormSubmit} noValidate>
            <Tabs
              activeKey={currentTabIndex}
              onTabClick={this.setCurrentTabIndex}>
              <Tabs.TabPane tab="Details" key="1">
                {detailsElements}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Address" key="2">
                {addressElements}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Groups" key="3">
                {groupElements}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Device" key="4">
                {deviceElements}
              </Tabs.TabPane>
            </Tabs>
          </form>
        </FormWrapper>

        {showAssignStockDevice && (
          <AssignStockDeviceModal
            handleAssignStockDevice={this.handleAssignStockDevice}
            showModal={showAssignStockDevice}
            onAssignStockDeviceSelect={this.onAssignStockDeviceSelect}
          />
        )}

        {showMap && (
          <Modal
            width={'60%'}
            visible={showMap}
            onCancel={this.onCancelMapModal}
            closable={false}
            onOk={this.onOkayMapModal}>
            <div className="row mt-4">
              <div className="col-md-12">
                <LeafletMap
                  onMapClickMarkerMove={this.onMarkerMove}
                  onMapZoom={this.onMapZoom}
                  lat={Number(formElements.latitude.value)}
                  lng={Number(formElements.longitude.value)}
                  zoom={mapZoom}
                  height={'50vh'}
                />
              </div>
            </div>
          </Modal>
        )}
      </Fragment>
    );
  }
}

const mapDispatch = {
  updateToken: updateToken,
};
const connector = connect(null, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(LocationAdd);
