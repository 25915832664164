import axios, { CancelTokenSource } from 'axios';
import {
  Fragment,
  useState,
  useRef,
  useEffect,
  useCallback,
  FormEvent,
  memo,
} from 'react';
import { useDispatch } from 'react-redux';
import { v4 } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';
import { Button, Input, Modal } from 'antd';

import { apiCall } from '../../../api-services/api';
import {
  ApiErrorType,
  ApiResultType,
  ApiSuccessType,
  AssignableLocationsType,
  UserDataType,
} from '../../../type-definitions/api-types';
import { deviceApi, locationApi } from '../../../api-services/api-list';
import { updateToken } from '../../../redux/actions';
import { getKeysFromEnum, handleTableSearch } from '../../../utils';
import { AssignableLocationsEnumKeys } from '../../../api-services/api-responses';
import NotificationHandler from '../../NotificationHandler';
import AntdTable from '../../AntdTable';

import cssStyles from '../styles/locationDetails.module.scss';

const initState = {
  loading: true,
  success: {},
  error: {},
  dataList: [],
  initDataList: [],
  searchValue: '',
};

interface StateType {
  loading: boolean;
  error: ApiErrorType;
  success: ApiSuccessType;
  searchValue: string;
  dataList: AssignableLocationsType[];
  initDataList: AssignableLocationsType[];
}

interface PropsType {
  userData: Partial<UserDataType>;
  showModal: boolean;
  closeModal: (id?: string) => void;
  deviceID: string;
}

const tableColumnKeys = getKeysFromEnum(AssignableLocationsEnumKeys);

function MoveModal({ userData, deviceID, showModal, closeModal }: PropsType) {
  const [state, setState] = useState<Readonly<StateType>>(initState);
  const _isMounted = useRef(false);
  const axiosCancelSource = useRef<CancelTokenSource>();
  const dispatch = useDispatch();

  function handleState(data: object) {
    _isMounted.current &&
      setState((prevState) => ({
        ...prevState,
        ...data,
      }));
  }

  useEffect(() => {
    axiosCancelSource.current = axios.CancelToken.source();
    _isMounted.current = true;
    return () => {
      _isMounted.current = false;
      axiosCancelSource.current?.cancel('Component Unmounted');
    };
  }, []);

  useEffect(() => {
    if (state.dataList.length === 0) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = useCallback(async () => {
    const { url, method, contentType, params } =
      locationApi.getAssignableLocations({
        deviceID,
      });
    const response = await apiCall({
      storeToken: userData?.token,
      url,
      method,
      contentType,
      params,
      cancelToken: axiosCancelSource.current?.token,
    });
    const result: ApiResultType = response?.data;
    dispatch(updateToken(result));

    if (result.status === 'ok') {
      if (result?.data && result.data.length > 0) {
        const tempData: AssignableLocationsType[] = result.data.map(
          (item: AssignableLocationsType) => ({
            ...item,
            uuid: v4(),
          })
        );
        handleState({
          loading: false,
          dataList: tempData,
          initDataList: tempData,
        });
      } else {
        handleState({ loading: false });
      }
    } else {
      handleState({ error: result, loading: false });
    }
  }, [deviceID, dispatch, userData?.token]);

  function handleOnChange(event: FormEvent<HTMLInputElement>) {
    const value = event?.currentTarget?.value;
    const stateData: Partial<StateType> = {
      searchValue: value,
    };
    if (value) {
      let tempData = cloneDeep(state.dataList);

      tempData = handleTableSearch({
        data: tempData,
        columnList: tableColumnKeys,
        searchData: value,
      });
      stateData.dataList = tempData;
    } else {
      stateData.dataList = state.initDataList;
    }

    handleState({ ...stateData });
  }

  async function handleMoveDevice(id: string) {
    if (!state.loading) {
      handleState({ loading: true });
    }

    const stateData: Partial<StateType> = {};
    try {
      const { url, method, contentType } = deviceApi.putAssignToLocation(
        undefined,
        {
          deviceID,
          locationID: id,
        }
      );
      const response = await apiCall({
        storeToken: userData?.token,
        url,
        method,
        contentType,
        cancelToken: axiosCancelSource.current?.token,
      });
      const result = response?.data;
      if (result) {
        dispatch(updateToken(result));
      }
      stateData.loading = false;
      if (result?.status === 'ok') {
        stateData.success = result;
        setTimeout(() => {
          closeModal(id);
        }, 1000);
      } else {
        stateData.error = result;
      }
    } catch (error: any) {
      stateData.error = error;
      stateData.loading = false;
    }
    handleState({ ...stateData });
  }

  function onCloseModal() {
    closeModal();
  }

  const pageTitle = `Select a location to move ${deviceID} to`;

  return (
    <Fragment>
      <NotificationHandler
        success={state.success}
        error={state.error}
        obj={{ _isMounted: _isMounted.current, setState }}
      />
      <Modal
        // width={760}
        visible={showModal}
        onCancel={onCloseModal}
        closable={false}
        className={cssStyles.move_modal}
        footer={null}>
        <div className="row pt-4 px-4">
          <div className="col-md-12">
            <div className="row pt-3 justify-content-center">
              <div
                className="col-md-8 text-center"
                style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                {pageTitle}
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-md-4 ml-auto text-center">
                <Input
                  disabled={state.loading}
                  type="text"
                  value={state.searchValue}
                  placeholder="Search"
                  name="searchValue"
                  onChange={handleOnChange}
                />
              </div>
            </div>
            <div className="row pt-4">
              <div className="col-md-12">
                <AntdTable
                  loading={state.loading}
                  columns={getColumns({
                    handleMoveDevice,
                  })}
                  dataSource={state.dataList}
                  pagination={{
                    total: state.dataList.length,
                    pageSize: 5,
                  }}
                  customConfig={{ rowKeyValue: 'uuid' }}
                  // tableClasses={cssStyles.move_modal_tbl}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
}

export default memo(MoveModal);

function getColumns({
  handleMoveDevice,
}: {
  handleMoveDevice: (id: string) => void;
}) {
  const columns = [
    // 0
    {
      title: 'Location ID',
      dataIndex: 'locationID',
      key: 'locationID',
    },
    // 1
    {
      title: 'Location Name',
      dataIndex: 'locationName',
      key: 'locationName',
    },
    // 2
    {
      title: 'Location Status',
      dataIndex: 'status',
      key: 'status',
    },
    // 3
    {
      title: 'Partner ID',
      dataIndex: 'partnerID',
      key: 'partnerID',
    },
    // 7
    {
      title: 'Action',
      key: 'action',
      render: (text: string, record: AssignableLocationsType) => {
        return (
          <Fragment>
            <Button
              size="small"
              type="primary"
              htmlType="button"
              onClick={() => handleMoveDevice(record.locationID)}>
              Select
            </Button>
          </Fragment>
        );
      },
    },
  ];

  return columns;
}
