import { Fragment, PureComponent } from 'react';
import { Modal, Table, Card, Button } from 'antd';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import axios from 'axios';

import { apiCall } from '../../../api-services/api';
import { deviceApi } from '../../../api-services/api-list';
import {
  ApiErrorType,
  StockLocationsType,
  UserDataType,
} from '../../../type-definitions/api-types';
import { AllocateToStockStateType } from '../helpers';
import NotificationHandler from '../../NotificationHandler';

type PropsType = {
  userData: Partial<UserDataType>;
  handleLocationIDValue: (id: string) => void;
  showModal: boolean;
  handleModal: () => void;
};

class AllocateToStock extends PureComponent<
  Readonly<PropsType>,
  Readonly<AllocateToStockStateType>
> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      dataList: [],
      perPage: 10,
      showModalConfirm: false,
      loading: true,
      success: {},
      error: {},
    };
  }

  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    this._isMounted = true;
    this.handleFetchedData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  fetchData = async () => {
    const { userData } = this.props;

    try {
      const { url, method, contentType } = deviceApi.getStockLocations();
      const response = await apiCall({
        storeToken: userData.token,
        url,
        method,
        contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      if (result?.status === 'ok') {
        return { stockLocationList: result.data || [] };
      } else {
        return { error: result, stockLocationList: [] };
      }
    } catch (error) {
      return { error };
    }
  };

  handleFetchedData = async () => {
    const {
      error,
      stockLocationList,
    }: {
      error?: ApiErrorType;
      stockLocationList?: Array<StockLocationsType>;
    } = await this.fetchData();
    if (error) {
      this._isMounted && this.setState({ error, loading: false });
    } else {
      if (stockLocationList && stockLocationList.length > 0) {
        const temp: StockLocationsType[] = [];
        stockLocationList.forEach((item) => {
          if (item?.rights?.includes('AllocateToStock')) {
            temp.push(item);
          }
        });
        this._isMounted && this.setState({ dataList: temp, loading: false });
      } else {
        this._isMounted && this.setState({ loading: false });
      }
    }
  };

  showConfirm = (record: StockLocationsType) => {
    const { handleLocationIDValue } = this.props;
    Modal.confirm({
      title:
        'This stock location is at or over capacity, are you sure you want to continue?',
      icon: <AiOutlineExclamationCircle />,
      // content: 'Some descriptions',
      onOk() {
        handleLocationIDValue(record.locationID);
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };

  handleSelect = (record: StockLocationsType) => {
    const { handleLocationIDValue } = this.props;
    if (record.capacity !== -1 && record.stockLevel >= record.capacity) {
      this.showConfirm(record);
    } else {
      handleLocationIDValue(record.locationID);
    }
  };

  render() {
    const { showModal, handleModal } = this.props;
    const { dataList, loading, success, error } = this.state;

    const columns = getColumns(this.handleSelect);

    return (
      <Fragment>
        <NotificationHandler success={success} error={error} obj={this} />

        <Modal
          visible={showModal}
          closable={false}
          width={720}
          onCancel={handleModal}
          footer={null}>
          <Card bordered={false} loading={loading}>
            <div className="row">
              <div className="col-md-12">
                <Table
                  className="table-responsive"
                  rowKey={(record) => record['locationID']}
                  bordered
                  columns={columns}
                  dataSource={dataList}
                  pagination={{ pageSize: 5 }}
                />
              </div>
            </div>
          </Card>
        </Modal>
      </Fragment>
    );
  }
}

function getColumns(handleSelect: (record: StockLocationsType) => void) {
  return [
    {
      title: 'Location ID',
      key: 'locationID',
      dataIndex: 'locationID',
    },
    {
      title: 'Location Name',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Stock Level',
      key: 'stockLevel',
      dataIndex: 'stockLevel',
    },
    {
      title: 'Capacity',
      key: 'capacity',
      // dataIndex: 'capacity'
      render: (record: StockLocationsType) => {
        let capacity: any = record.capacity;
        if (record.capacity === -1) {
          capacity = 'Unlimited';
        }
        return <span>{capacity}</span>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (record: StockLocationsType) => (
        <Fragment>
          <Button type="primary" onClick={(event) => handleSelect(record)}>
            Select
          </Button>
        </Fragment>
      ),
    },
  ];
}

export default AllocateToStock;
