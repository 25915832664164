import { Button } from 'antd';
import { Fragment } from 'react';
import { Row, Col } from 'antd';
import { FormElementsType } from '../helpers';
import AntdInput from '../../AntdInput';

function DeviceTab({
  formElements,
  inputChangedHandler,
  openMoveModal,
  openAddAssignModal,
  openSwapModal,
  isMoveDevice,
  isAddAssignDevice,
  isSwapDevice,
  handleRedirectToDeviceDetails,
}: {
  formElements: FormElementsType;
  isMoveDevice: boolean;
  isAddAssignDevice: boolean;
  isSwapDevice: boolean;
  inputChangedHandler: (name: any, value: any) => void;
  openSwapModal: (id?: string) => void;
  openMoveModal: (id?: string) => void;
  openAddAssignModal: () => void;
  handleRedirectToDeviceDetails: (deviceID: string) => void;
}) {
  let isRedirectToDeviceDetailsDisabled = false;
  if (
    formElements.locationMac.value === 'UNALLOCATED' ||
    formElements.locationMac.value === '' ||
    typeof formElements.locationMac.value === 'undefined'
  ) {
    isRedirectToDeviceDetailsDisabled = true;
  }

  return (
    <Fragment>
      <Row justify="center" align="middle">
        <Col xs={24}>
          <AntdInput
            {...formElements.locationMac}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>

      <Row justify="center" align="middle" className="px-2">
        {isAddAssignDevice && (
          <Col xs={12} md={8}>
            <Button
              type="primary"
              htmlType="button"
              size="large"
              block
              onClick={openAddAssignModal}>
              Assign Device
            </Button>
          </Col>
        )}

        {isSwapDevice && (
          <Col xs={12} md={8} className="px-2">
            <Button
              type="primary"
              htmlType="button"
              size="large"
              block
              onClick={() => openSwapModal(formElements.locationMac.value)}>
              Swap Device
            </Button>
          </Col>
        )}
        {isMoveDevice && (
          <Col xs={12} md={8} className="px-2">
            <Button
              type="primary"
              htmlType="button"
              size="large"
              block
              onClick={() => openMoveModal(formElements.locationMac.value)}>
              Move Device
            </Button>
          </Col>
        )}
      </Row>

      <Row justify="center" align="middle" className="pt-3">
        <Col>
          <Button
            disabled={isRedirectToDeviceDetailsDisabled}
            type="primary"
            htmlType="button"
            onClick={() =>
              handleRedirectToDeviceDetails(formElements.locationMac.value)
            }>
            Device Details
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
}

export default DeviceTab;
