import { Component, createRef, FormEvent, Fragment } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Tabs, message, Row, Col, Button } from 'antd';
import axios from 'axios';
import update from 'immutability-helper';

import {
  ApiErrorType,
  PartnerListType,
  UserDataType,
} from '../type-definitions/api-types';
import { FormInputType, SelectOptionsType } from '../type-definitions';
import { updateToken } from '../redux/actions';
import {
  DeviceFormDataType,
  locationTabElements,
  detailsTabElements,
  StateType,
  FormElementsType,
} from '../components/DeviceAdd/helpers';
import { apiCall } from '../api-services/api';
import { deviceApi, hardwareApi, partnerApi } from '../api-services/api-list';
import { checkValidation } from '../utils/validation';
import { generatePassword, getLocalTime } from '../utils';
import AntdInput from '../components/AntdInput';
import FormWrapper from '../components/FormWrapper';
import DetailsTab from '../components/DeviceAdd/components/DetailsTab';
import LocationTab from '../components/DeviceAdd/components/LocationTab';
import AllocateToStock from '../components/DeviceAdd/components/AllocateToStock';
import { deviceRights } from '../utils/permission-list';
import { deviceRoutes } from '../Routes/routes-list';
import PromptPopup from '../components/PromptPopup';
import { handleNotification } from '../utils/notification-handler';

const currentDate = getLocalTime({ date: new Date(), format: 'date' });

interface PropsType extends RouteComponentProps, PropsFromRedux {
  userData: Partial<UserDataType>;
  userPermissionList: string[];
}

class DeviceAdd extends Component<Readonly<PropsType>, Readonly<StateType>> {
  datePickerRef = createRef();
  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  constructor(props: PropsType) {
    super(props);
    this.state = {
      formElements: {
        ...detailsTabElements,
        ...locationTabElements,
        locationID: {
          ...locationTabElements.locationID,
          styles: { cursor: 'pointer' },
          onInputClick: this.handleStockModal,
        },
      },
      formElementsList: {},
      showStockModal: false,
      loading: true,
      deviceFormMakerData: [],
      selectedManufacturerValue: '',
      currentTabIndex: '1',
      hasInputChanged: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.handleFetchedData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  fetchData = async () => {
    const { userData, updateToken } = this.props;
    try {
      const formData = hardwareApi.getHardwareList();
      const partnerList = partnerApi.getPartners();
      const values = await Promise.all([
        apiCall({
          storeToken: userData?.token,
          url: formData.url,
          method: formData.method,
          contentType: formData.contentType,
          cancelToken: this.axiosCancelSource.token,
        }),
        apiCall({
          storeToken: userData?.token,
          url: partnerList.url,
          method: partnerList.method,
          contentType: partnerList.contentType,
          cancelToken: this.axiosCancelSource.token,
        }),
      ]);
      const response = values[0];
      const response2 = values[1];
      const result = response?.data;
      const result2 = response2?.data;
      updateToken(result);
      updateToken(result2);

      if (result.status === 'ok' && result2.status === 'ok') {
        return {
          deviceFormData: result.data,
          devicePartners: result2.data,
        };
      } else {
        return { error: result || result2 };
      }
    } catch (error) {
      return { error };
    }
  };

  handleFetchedData = async () => {
    let {
      error,
      deviceFormData,
      devicePartners,
    }: {
      error?: ApiErrorType;
      deviceFormData?: Array<DeviceFormDataType>;
      devicePartners?: Array<PartnerListType>;
    } = await this.fetchData();
    if (error) {
      this._isMounted && handleNotification('error', error);
      this._isMounted && this.setState({ loading: false });
    } else {
      const { formElements } = this.state;
      let tempFormElements = { ...formElements };
      if (devicePartners && devicePartners.length > 0) {
        devicePartners = devicePartners.filter((el) =>
          el.rights.includes(deviceRights.create)
        );
      }
      if (
        deviceFormData &&
        deviceFormData.length > 0 &&
        devicePartners &&
        devicePartners.length > 0
      ) {
        let maker: string[] = [];
        deviceFormData.forEach((item) => {
          if (item.make) {
            maker.push(item.make);
          }
        });
        maker = [...new Set(maker)];

        let tempMaker: Array<SelectOptionsType> = [
          { value: '', text: 'Please Select' },
        ];
        maker.forEach((item) => {
          let tempObj: SelectOptionsType = {
            value: item,
            text: item,
          };
          tempMaker.push(tempObj);
        });

        tempFormElements = update(tempFormElements, {
          manufacturer: { optionValues: { $set: [...tempMaker] } },
        });

        if (devicePartners.length > 1) {
          const partnerList: Array<SelectOptionsType> = [];
          devicePartners.forEach((item) => {
            if (item?.partnerID && item?.partnerName) {
              const data: SelectOptionsType = {
                value: item.partnerID,
                text: item.partnerName,
              };
              partnerList.push(data);
            }
          });

          if (partnerList.length > 0) {
            tempFormElements = update(tempFormElements, {
              partnerName: { optionValues: { $set: [...partnerList] } },
            });

            const selected = devicePartners.find(
              (item) => item?.partnerID === 'AIRSENSA'
            );
            if (selected) {
              tempFormElements = update(tempFormElements, {
                partnerName: { value: { $set: selected.partnerID } },
              });
            } else {
              tempFormElements = update(tempFormElements, {
                partnerName: { value: { $set: partnerList[0].value } },
              });
            }
          }
        } else if (
          devicePartners.length === 1 &&
          tempFormElements.partnerName.elementConfig
        ) {
          tempFormElements = update(tempFormElements, {
            partnerName: {
              elementType: { $set: 'input' },
              elementConfig: {
                type: { $set: 'text' },
                placeholder: { $set: 'Partner Name' },
              },
              value: { $set: devicePartners[0]?.partnerID || '' },
              disabled: { $set: true },
            },
          });
        }

        this._isMounted &&
          this.setState({
            loading: false,
            formElements: tempFormElements,
            deviceFormMakerData: deviceFormData,
          });
      } else {
        this._isMounted && this.setState({ loading: false });
      }
    }
  };

  onAssociatedNodeClick = (name: string) => {
    this.inputChangedHandler2(
      name,
      generatePassword(),
      true,
      <DeviceAssociatedNodeButton
        onAssociatedNodeClick={() => this.onAssociatedNodeClick(name)}
        disabled={true}
      />
    );
  };

  inputChangedHandler = async (name: keyof FormElementsType, value: string) => {
    const { formElements, formElementsList, deviceFormMakerData } = this.state;

    let selectedManufacturerValue = '';
    let filteredDeviceFormMakerData: Array<DeviceFormDataType>;

    let tempFormElements = { ...formElements };
    let tempFormElementsList = { ...formElementsList };
    let tempDeviceFormMakerData = [...deviceFormMakerData];

    if (name) {
      tempFormElements = update(tempFormElements, {
        [name]: {
          value: { $set: value },
          touched: { $set: true },
          valid: {
            $set: checkValidation(value, tempFormElements[name].validation),
          },
        },
      });
    }

    if (name === 'manufacturer' && value === '') {
      tempFormElementsList = {};
      tempFormElements = update(tempFormElements, {
        sensors: { value: { $set: '' } },
      });
    }

    if (name === 'manufacturer' && value) {
      selectedManufacturerValue = value;
      tempFormElementsList = {};

      const model: Array<SelectOptionsType> = [
        { value: '', text: 'Please Select' },
      ];

      tempDeviceFormMakerData.forEach((item) => {
        if (item.make === value) {
          const tempObj: SelectOptionsType = {
            value: item.model,
            text: item.model,
          };
          model.push(tempObj);
        }
      });

      tempFormElements = update(tempFormElements, {
        model: {
          value: { $set: '' },
          disabled: { $set: false },
          optionValues: { $set: [...model] },
        },
        sensors: { value: { $set: '' } },
      });
    }

    if (name === 'model' && value === '') {
      tempFormElementsList = {};
      tempFormElements = update(tempFormElements, {
        sensors: { value: { $set: '' } },
      });
    }

    if (name === 'model' && value) {
      tempFormElementsList = {};
      filteredDeviceFormMakerData = tempDeviceFormMakerData.filter((item) => {
        if (item.model === value) {
          return item;
        }
        return null;
      });
      filteredDeviceFormMakerData &&
        filteredDeviceFormMakerData.length > 0 &&
        filteredDeviceFormMakerData.forEach((el) => {
          if (el.model === value) {
            el.attributes?.forEach((item) => {
              if (item) {
                let tempElement: FormInputType = {
                  elementType: 'input',
                  elementConfig: {
                    placeholder: item.helptext,
                    name: item.name || '',
                  },
                  optionValues: [],
                  value: item.default || '',
                  validation: {
                    required: item.required || false,
                  },
                  valid: false,
                  touched: false,
                  errorMessage: `${item.label} is required`,
                  label: `${item.label} ${item.required ? '*' : ''}`,
                  disabled: false,
                };

                if (item.type === 'string') {
                  tempElement.elementType = 'input';
                  tempElement.elementConfig.type = 'text';
                } else if (item.type === 'date') {
                  tempElement.elementType = 'date';
                  tempElement.value = currentDate;
                } else if (item.type === 'option') {
                  if (item?.options && item.options.length === 1) {
                    tempElement.elementType = 'input';
                    tempElement.disabled = true;
                    tempElement.value = item.options[0]?.value!;
                  } else if (item.options && item.options.length > 1) {
                    tempElement.elementType = 'select';
                    item.options.forEach((el) => {
                      if (el?.value && el?.label) {
                        tempElement = update(tempElement, {
                          optionValues: {
                            $push: [
                              {
                                value: el.value,
                                text: el.label,
                              },
                            ],
                          },
                        });
                      }
                    });
                    tempElement.value = item.options[0]?.value!;
                  }
                }

                if (item.type === 'string' && item.allowGeneration === true) {
                  // const tempCheckboxElem: FormInputType = {
                  //   elementType: 'checkbox',
                  //   elementConfig: {
                  //     name: 'allowGeneration',
                  //   },
                  //   checkboxText: 'Allow Auto Generation',
                  //   value: '',
                  //   validation: {},
                  //   valid: false,
                  //   touched: false,
                  //   errorMessage: '',
                  //   hasParentWrapper: false,
                  // };

                  tempElement.hasAssociatedNode = (
                    <DeviceAssociatedNodeButton
                      onAssociatedNodeClick={() =>
                        this.onAssociatedNodeClick(item.name)
                      }
                    />
                  );
                }

                tempFormElementsList = update(tempFormElementsList, {
                  [item.name]: { $set: { ...tempElement } },
                });
              }
            });

            let sensorData = '';
            if (el?.sensorSpecs && el.sensorSpecs.length > 0) {
              el.sensorSpecs.forEach((item, index) => {
                sensorData =
                  sensorData + item?.shortName + '(' + item?.units + ')';
                if (index < el.sensorSpecs.length - 1) {
                  sensorData = sensorData + ', ';
                }
              });
            }

            tempFormElements = update(tempFormElements, {
              sensors: { value: { $set: sensorData } },
            });
          }
        });
    }

    this._isMounted &&
      this.setState({
        formElements: tempFormElements,
        formElementsList: tempFormElementsList,
        selectedManufacturerValue,
        deviceFormMakerData: tempDeviceFormMakerData,
        hasInputChanged: true,
      });
  };

  inputChangedHandler2 = (
    name: string,
    value: any,
    isDisabled?: boolean,
    hasAssociatedNode?: any
  ) => {
    const { formElementsList } = this.state;

    let tempFormElementsList = { ...formElementsList };

    if (name) {
      tempFormElementsList = update(tempFormElementsList, {
        [name]: {
          value: { $set: value },
          touched: { $set: true },
          valid: {
            $set: checkValidation(value, tempFormElementsList[name].validation),
          },
          disabled: {
            $set: isDisabled ?? tempFormElementsList[name].disabled,
          },
          hasAssociatedNode: {
            $set:
              hasAssociatedNode ?? tempFormElementsList[name].hasAssociatedNode,
          },
        },
      });
    }

    this._isMounted &&
      this.setState({
        formElementsList: tempFormElementsList,
        hasInputChanged: true,
      });
  };

  onFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    this.handleSubmit();
  };

  handleSubmit = async (onConfirm?: () => void) => {
    const { formElementsList, formElements } = this.state;
    const { userData } = this.props;

    let tempFormElements = { ...formElements };
    let tempFormElementsList = { ...formElementsList };

    let key: keyof typeof tempFormElements;
    for (key in tempFormElements) {
      tempFormElements = update(tempFormElements, {
        [key]: {
          touched: { $set: true },
          valid: {
            $set: checkValidation(
              tempFormElements[key].value,
              tempFormElements[key].validation
            ),
          },
        },
      });
    }

    let key2: keyof typeof tempFormElementsList;
    for (key2 in tempFormElementsList) {
      tempFormElementsList = update(tempFormElementsList, {
        [key2]: {
          touched: { $set: true },
          valid: {
            $set: checkValidation(
              tempFormElementsList[key2].value,
              tempFormElementsList[key2].validation
            ),
          },
        },
      });
    }

    this._isMounted &&
      this.setState({
        formElements: tempFormElements,
        formElementsList: tempFormElementsList,
      });

    let valid = true;

    for (key in tempFormElements) {
      if (!tempFormElements[key].valid) {
        valid = false;
      }
    }

    for (key2 in tempFormElementsList) {
      if (!tempFormElementsList[key2].valid) {
        valid = false;
      }
    }

    if (!valid) {
      message.error('Please fill all the fields');
      return;
    }

    const data: {
      mac: string;
      serial: string;
      locID: string;
      make: string;
      model: string;
      partnerID: string;
      attributes?: { [key: string]: string | number };
    } = {
      mac: tempFormElements.deviceMac.value,
      serial: tempFormElements.serialNumber.value,
      locID: tempFormElements.locationID.value,
      make: tempFormElements.manufacturer.value,
      model: tempFormElements.model.value,
      partnerID: tempFormElements.partnerName.value,
    };
    const data2: { [key: string]: string | number } = {};
    for (const key in tempFormElementsList) {
      data2[key] = tempFormElementsList[key].value;
    }
    data.attributes = { ...data2 };

    this._isMounted && this.setState({ loading: true });
    try {
      const { url, method, contentType } = deviceApi.postDevice();
      const response = await apiCall({
        storeToken: userData.token,
        data,
        url,
        method,
        contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      if (result.status === 'ok') {
        this._isMounted && handleNotification('success', result);
        this._isMounted &&
          this.setState({ loading: false, hasInputChanged: false }, () => {
            const { history } = this.props;
            setTimeout(() => {
              history.push({
                pathname: deviceRoutes.details(),
                state: {
                  deviceID: tempFormElements.deviceMac.value,
                  allowEdit: true,
                  locationID: tempFormElements.locationID.value,
                },
              });
            }, 1000);
          });
      } else {
        this._isMounted && handleNotification('error', result);
        this._isMounted && this.setState({ loading: false });
      }
    } catch (error: any) {
      this._isMounted && handleNotification('error', error?.data);
      this._isMounted && this.setState({ loading: false });
    }
  };

  handlePopupConfirm = (onConfirm: () => void) => {
    this.handleSubmit(onConfirm);
  };

  getInputElementsList = () => {
    const { formElementsList } = this.state;
    const formElementsArray = [];

    for (const key in formElementsList) {
      formElementsArray.push({
        id: key,
        config: formElementsList[key],
      });
    }

    const inputElement = formElementsArray.map((item) => {
      return (
        <Row gutter={[{ md: 12 }, 0]} key={item.id} align="middle">
          <Col xs={24} md={12}>
            <AntdInput
              {...item.config}
              onInputChanged={this.inputChangedHandler2}
            />
          </Col>
          <Col xs={24} md={12} className="pt-3">
            {item.config.hasAssociatedNode}
          </Col>
        </Row>
      );
    });

    return inputElement;
  };

  // handleCancelRedirect = () => {
  //   const { history } = this.props;
  //   history.push({
  //     pathname: '/devices',
  //   });
  // };

  handleStockModal = () => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          showStockModal: !prevState.showStockModal,
        };
      });
  };

  handleLocationIDValue = (locationID: string) => {
    const { formElements } = this.state;
    let tempFormElements = { ...formElements };
    tempFormElements = update(tempFormElements, {
      locationID: { value: { $set: locationID } },
    });

    this._isMounted &&
      this.setState((prevState) => {
        return {
          formElements: tempFormElements,
          showStockModal: !prevState.showStockModal,
        };
      });
  };

  setCurrentTabIndex = (index: string) => {
    const { formElements, formElementsList } = this.state;
    let tempFormElements = { ...formElements };
    let tempFormElementsList = { ...formElementsList };
    let key: keyof typeof tempFormElements;
    let elementsName: string[] = [];
    if (index === '2') {
      let key2: keyof typeof tempFormElementsList;
      elementsName = Object.keys(detailsTabElements);

      for (key in tempFormElements) {
        if (elementsName.includes(key)) {
          tempFormElements = update(tempFormElements, {
            [key]: {
              touched: { $set: true },
              valid: {
                $set: checkValidation(
                  tempFormElements[key].value,
                  tempFormElements[key].validation
                ),
              },
            },
          });
        }
      }

      for (key2 in tempFormElementsList) {
        tempFormElementsList = update(tempFormElementsList, {
          [key2]: {
            touched: { $set: true },
            valid: {
              $set: checkValidation(
                tempFormElementsList[key2].value,
                tempFormElementsList[key2].validation
              ),
            },
          },
        });
      }

      this._isMounted &&
        this.setState({
          formElements: tempFormElements,
          formElementsList: tempFormElementsList,
        });

      let valid = true;
      for (key in tempFormElements) {
        if (elementsName.includes(key) && !tempFormElements[key].valid) {
          valid = false;
        }
      }

      for (key2 in tempFormElementsList) {
        if (!tempFormElementsList[key2].valid) {
          valid = false;
        }
      }

      if (!valid) {
        message.error('Please fill all the fields');
        return;
      }
    } else if (index === '3') {
      elementsName = Object.keys(locationTabElements);
      for (key in tempFormElements) {
        if (elementsName.includes(key)) {
          tempFormElements = update(tempFormElements, {
            [key]: {
              touched: { $set: true },
              valid: {
                $set: checkValidation(
                  tempFormElements[key].value,
                  tempFormElements[key].validation
                ),
              },
            },
          });
        }
      }

      this._isMounted && this.setState({ formElements: tempFormElements });

      for (key in tempFormElements) {
        if (elementsName.includes(key) && !tempFormElements[key].valid) {
          message.error('Please fill all the fields');
          return;
        }
      }
    }

    this._isMounted && this.setState({ currentTabIndex: index });
  };

  render() {
    const dynamicInputElements = this.getInputElementsList();
    const { userPermissionList, userData } = this.props;
    const {
      showStockModal,
      formElements,
      loading,
      currentTabIndex,
      hasInputChanged,
    } = this.state;

    if (userPermissionList.length === 0) {
      return <Redirect to="/" />;
    }

    return (
      <Fragment>
        {hasInputChanged && (
          <PromptPopup
            when={hasInputChanged}
            handleConfirm={this.handlePopupConfirm}
          />
        )}
        <FormWrapper loading={loading} title="Device Add">
          <form onSubmit={this.onFormSubmit} noValidate>
            <Tabs
              activeKey={currentTabIndex}
              onTabClick={this.setCurrentTabIndex}>
              <Tabs.TabPane tab={'Details'} key="1">
                <DetailsTab
                  formElements={formElements}
                  inputChangedHandler={this.inputChangedHandler}
                  dynamicInputElements={dynamicInputElements}
                  setCurrentTabIndex={this.setCurrentTabIndex}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab={'Location'} key="2">
                <LocationTab
                  formElements={formElements}
                  inputChangedHandler={this.inputChangedHandler}
                  setCurrentTabIndex={this.setCurrentTabIndex}
                />
              </Tabs.TabPane>
            </Tabs>
          </form>
        </FormWrapper>

        {showStockModal && (
          <AllocateToStock
            showModal={showStockModal}
            handleModal={this.handleStockModal}
            userData={userData}
            handleLocationIDValue={this.handleLocationIDValue}
          />
        )}
      </Fragment>
    );
  }
}

const mapDispatch = {
  updateToken,
};

const connector = connect(null, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DeviceAdd);

export const DeviceAssociatedNodeButton = ({
  onAssociatedNodeClick,
  disabled = false,
}: {
  onAssociatedNodeClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <Button type="primary" onClick={onAssociatedNodeClick} disabled={disabled}>
      Generate
    </Button>
  );
};
