import { CSSProperties, Fragment, ReactNode } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import LocationListMap from '../Map';
import { UserDataType } from '../../../type-definitions/api-types';
import AntdCard from '../../../components-shared/AntdCard';
import AntdCoverSpinner from '../../AntdCoverSpinner';
import AntdTable, { AntdTablePropsType } from '../../AntdTable';

import cssStyles from '../styles/locationList.module.scss';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { MarkerInfoType } from '../helpers';

interface PropsType {
  loading?: boolean;
  pageTitle?: string;
  tableDetails: AntdTablePropsType;
  inputComponents: () => ReactNode;
  userData: Partial<UserDataType>;
  handleMapFilter?: (mapList: any[]) => void;
  isMapFilteringAvailable: boolean;
  handleMapFilterAvailability: (event: CheckboxChangeEvent) => void;
  onMarkerInfoClick: (value: MarkerInfoType) => void;
  selectedMarkerInfo: { [key in MarkerInfoType]: boolean };
  mapDataSource: any[];
}

function Wrapper({
  loading,
  pageTitle,
  inputComponents,
  tableDetails,
  userData,
  handleMapFilter,
  isMapFilteringAvailable,
  handleMapFilterAvailability,
  onMarkerInfoClick,
  selectedMarkerInfo,
  mapDataSource,
}: PropsType) {
  const locationListMapProps: {
    handleMapFilter?: (mapList: any[]) => void;
  } = {};

  if (isMapFilteringAvailable) {
    locationListMapProps.handleMapFilter = handleMapFilter;
  }

  return (
    <Fragment>
      <div className={`row ${cssStyles.wrapperRoot}`}>
        <div className="col-lg-12">
          <AntdCoverSpinner active={loading}>
            <AntdCard>
              <div className="row">
                <div className="col-lg-12">
                  <div className="row py-2 title">
                    <div className="col-lg-6">
                      <h5>{pageTitle}</h5>
                    </div>
                  </div>

                  <div className="row align-items-center py-2 input_components">
                    {inputComponents?.()}
                  </div>

                  <div className="row pt-2 d-none d-sm-none d-md-flex align-items-center">
                    <div className="col-md-6">
                      <Checkbox
                        checked={isMapFilteringAvailable}
                        onChange={handleMapFilterAvailability}>
                        Filter locations through Map
                      </Checkbox>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <MarkerInfo
                          color="#01F50D"
                          text="Less than 3 hours"
                          isSelected={!selectedMarkerInfo.lessThan3}
                          onMarkerInfoClick={() =>
                            onMarkerInfoClick('lessThan3')
                          }
                        />
                        <MarkerInfo
                          color="#f3a215"
                          text="Less than 48 hours"
                          isSelected={!selectedMarkerInfo.lessThan48}
                          onMarkerInfoClick={() =>
                            onMarkerInfoClick('lessThan48')
                          }
                        />
                        <MarkerInfo
                          color="#ed0e1d"
                          text="More than 48 hours"
                          isSelected={!selectedMarkerInfo.moreThan48}
                          onMarkerInfoClick={() =>
                            onMarkerInfoClick('moreThan48')
                          }
                        />
                        <MarkerInfo
                          color="#c8c8c8"
                          text="Offline"
                          isSelected={!selectedMarkerInfo.offline}
                          onMarkerInfoClick={() => onMarkerInfoClick('offline')}
                        />
                        <MarkerInfo
                          color="#2c83cb"
                          text="Stock"
                          isSelected={!selectedMarkerInfo.stock}
                          onMarkerInfoClick={() => onMarkerInfoClick('stock')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`row py-2`}>
                    <div className="col-md-6 col-lg-6">
                      <div className="row">
                        <div className={`col-lg-12`}>
                          <AntdTable {...tableDetails} />
                        </div>
                      </div>
                    </div>
                    <div className={`col-md-6 col-lg-6 ${cssStyles.map_root}`}>
                      {tableDetails?.dataSource && (
                        <Fragment>
                          <LocationListMap
                            latitude={userData.lat || 0}
                            longitude={userData.lng || 0}
                            dataSource={mapDataSource}
                            classes={cssStyles.multi_location_map}
                            {...locationListMapProps}
                          />
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </AntdCard>
          </AntdCoverSpinner>
        </div>
      </div>
    </Fragment>
  );
}

export default Wrapper;

function MarkerInfo({
  color,
  text,
  onMarkerInfoClick,
  isSelected,
}: {
  color: string;
  text: string;
  onMarkerInfoClick?: () => void;
  isSelected: boolean;
}) {
  const markerIconProps: {
    onClick?: () => void;
    style: CSSProperties;
  } = {
    style: { color },
  };

  if (onMarkerInfoClick) {
    markerIconProps.onClick = onMarkerInfoClick;
    markerIconProps.style.cursor = 'pointer';
    markerIconProps.style.opacity = isSelected ? '0.3' : 1;
  }
  return (
    <div
      className="col-2"
      style={{
        display: 'flex',
        alignItems: 'center',
      }}>
      <FaMapMarkerAlt
        {...markerIconProps}
        // onClick={onMarkerInfoClick}
        size="2.5em"
        // style={{
        //   color,
        //   cursor: 'pointer',
        //   opacity: isSelected ? '0.3' : 1,
        // }}
      />
      <span
        style={{
          fontSize: '0.7rem',
          paddingLeft: '0.2rem',
          fontWeight: 'bold',
        }}>
        {text}
      </span>
    </div>
  );
}
