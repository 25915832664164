import React, { Fragment, PureComponent } from 'react';
import { Button, Modal } from 'antd';
import NavigationPrompt from 'react-router-navigation-prompt';

import cssStyles from './promptPopup.module.scss';
import { v4 } from 'uuid';

interface PropsType {
  when: boolean;
  handleConfirm: (onConfirm: () => void) => void;
}

class PromptPopup extends PureComponent<PropsType> {
  render() {
    const { when, handleConfirm } = this.props;
    return (
      <Fragment>
        <NavigationPrompt when={when}>
          {({ isActive, onConfirm, onCancel }) => {
            if (isActive) {
              return (
                <Confirm
                  when={when}
                  handleCancel={onCancel}
                  handleConfirm={() => handleConfirm(onConfirm)}
                  handleNo={onConfirm}
                />
              );
            }
            return <Fragment></Fragment>;
          }}
        </NavigationPrompt>
      </Fragment>
    );
  }
}

export default PromptPopup;

function Confirm({
  when,
  handleCancel,
  handleConfirm,
  handleNo,
}: {
  when: boolean;
  handleCancel: () => void;
  handleConfirm: () => void;
  handleNo: () => void;
}) {
  return (
    <Fragment>
      {when && (
        <Modal
          className={cssStyles.modal}
          width={400}
          bodyStyle={{ textAlign: 'center' }}
          closable={false}
          visible={when}
          footer={[
            <Fragment key={v4()}>
              <div className="row justify-content-center" key={v4()}>
                <div className="col-10 text-center" key={v4()}>
                  <Button type="primary" key="yes" onClick={handleConfirm}>
                    Yes
                  </Button>
                  <Button type="primary" danger key="no" onClick={handleNo}>
                    No
                  </Button>
                  <Button type="default" key="cancel" onClick={handleCancel}>
                    Cancel
                  </Button>
                </div>
              </div>
            </Fragment>,
          ]}>
          Would you like to save changes?
        </Modal>
      )}
    </Fragment>
  );
}
