import { Fragment, Component, FormEvent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import update from 'immutability-helper';
import axios from 'axios';
import { message, Tabs } from 'antd';

import {
  StateType,
  detailsTabInputElements,
  DetailsTabElementsType,
  SensorsTabElementsType,
  AttributesTabElementsType,
} from '../components/HardwareAdd/helpers';
import { updateToken } from '../redux/actions';
import {
  HardwareAttributeType,
  PartnerListType,
  SensorListType,
  UserDataType,
} from '../type-definitions/api-types';
import DetailsTab from '../components/HardwareAdd/DetailsTab';
import { checkValidation } from '../utils/validation';
import SensorsTab from '../components/HardwareAdd/SensorsTab';
import AttributesTab from '../components/HardwareAdd/AttributesTab';
import { apiCall } from '../api-services/api';
import { hardwareApi, partnerApi } from '../api-services/api-list';
import { handleNotification } from '../utils/notification-handler';
import { SelectOptionsType } from '../type-definitions';
import { hardwareRights } from '../utils/permission-list';
import { hardwareRoutes } from '../Routes/routes-list';
import { v4 } from 'uuid';
import PromptPopup from '../components/PromptPopup';
import FormWrapper from '../components-shared/FormWrapper';

interface PropsType extends RouteComponentProps, PropsFromRedux {
  userData: Partial<UserDataType>;
  userPermissionList: string[];
}

class HardwareAdd extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      loading: true,
      loadingButton: false,
      currentTabIndex: '1',
      detailsTabElements: { ...detailsTabInputElements },
      sensorsTabElements: [],
      attributesTabElements: [...this.getDefaultAttributeTabElements()],
      partnerList: [],
      hasInputChanged: false,
    };
  }

  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    this._isMounted = true;

    this.handleFetchedData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  handleState = (data: Partial<StateType>, callback?: () => void) => {
    this._isMounted &&
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            ...data,
          };
        },
        () => {
          callback?.();
        }
      );
  };

  getDefaultAttributeTabElements = (): AttributesTabElementsType[] => {
    return [
      {
        required: true,
        name: {
          elementType: 'input',
          elementConfig: {
            name: 'name',
          },
          value: 'transport',
          validation: {
            required: true,
          },
          valid: true,
          touched: true,
          errorMessage: 'Attribute Name is required',
          label: 'Attribute Name',
          disabled: true,
        },
        label: {
          elementType: 'input',
          elementConfig: {
            name: 'label',
          },
          value: 'Transport',
          validation: {
            required: true,
          },
          valid: true,
          touched: true,
          errorMessage: 'Attribute Label is required',
          label: 'Attribute Label',
          disabled: true,
        },
        helptext: {
          elementType: 'input',
          elementConfig: {
            name: 'helptext',
          },
          value: '',
          validation: {
            // required: true,
          },
          valid: true,
          touched: true,
          errorMessage: 'Helper Text is required',
          label: 'Helper Text',
        },
        chars: {
          elementType: 'input',
          elementConfig: {
            name: 'chars',
          },
          value: '20',
          validation: {
            required: true,
            isNumeric: true,
          },
          valid: true,
          touched: true,
          errorMessage: 'Chars is required',
          label: 'Chars',
        },
        type: {
          elementType: 'select',
          elementConfig: {
            name: 'type',
          },
          value: 'option',
          optionValues: [
            { text: 'String', value: 'string' },
            { text: 'Date', value: 'date' },
            { text: 'Options', value: 'option' },
          ],
          validation: {
            // required: true,
          },
          valid: true,
          touched: true,
          errorMessage: 'Type is required',
          label: 'Type',
        },
        default: {
          elementType: 'select',
          elementConfig: {
            name: 'default',
          },
          value: '',
          optionValues: [{ text: 'Please Select', value: '' }],
          validation: {
            // required: true,
          },
          valid: false,
          touched: false,
          errorMessage: 'Default is required',
          label: 'Default',
        },
        options: [],
        uuid: v4(),
      },
    ];
  };

  handleFetchedData = async () => {
    const { userData } = this.props;
    const { detailsTabElements } = this.state;
    let tempFormElements = { ...detailsTabElements };

    let stateData: Partial<StateType> = {};

    try {
      const { url, method, contentType } = partnerApi.getPartners();
      const response = await apiCall({
        storeToken: userData.token,
        url,
        method,
        contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      stateData = update(stateData, {
        loading: { $set: false },
      });
      if (result?.status === 'ok') {
        let tempPartnerData: PartnerListType[] = result.data;
        if (tempPartnerData && tempPartnerData.length > 0) {
          tempPartnerData = tempPartnerData.filter((el) =>
            el.rights.includes(hardwareRights.create)
          );
          tempFormElements = this.setPartnerSelectOptions(
            tempPartnerData,
            tempFormElements
          );

          stateData = update(stateData, {
            detailsTabElements: { $set: tempFormElements },
            partnerList: { $set: [...tempPartnerData] },
          });
        }
      } else {
        this._isMounted && handleNotification('error', result);
      }
    } catch (error: any) {
      this._isMounted && handleNotification('error', error.data);
    }

    this.handleState(stateData);
  };

  setPartnerSelectOptions = (
    partners: PartnerListType[],
    elements: DetailsTabElementsType
  ) => {
    const { userData } = this.props;

    if (partners.length === 1) {
      elements = update(elements, {
        partner: {
          elementType: { $set: 'input' },
          disabled: { $set: true },
          elementConfig: {
            type: { $set: 'text' },
            placeholder: { $set: 'Partner' },
          },
          value: { $set: partners[0].partnerID },
        },
      });
    } else {
      const partnerOptions: SelectOptionsType[] = partners.map((el) => ({
        text: el.partnerName,
        value: el.partnerID,
      }));
      const matched = partners.find(
        (el) => el.partnerID === userData.partnerID
      );
      elements = update(elements, {
        partner: {
          optionValues: { $set: [...partnerOptions] },
          value: {
            $set: matched ? matched.partnerID : partners[0].partnerID,
          },
        },
      });
    }

    return elements;
  };

  detailsInputChangedHandler = (
    name: keyof DetailsTabElementsType,
    value: string
  ) => {
    const { detailsTabElements } = this.state;

    let tempFormElements = { ...detailsTabElements };

    if (name) {
      tempFormElements = update(tempFormElements, {
        [name]: {
          touched: { $set: true },
          valid: {
            $set: checkValidation(value, tempFormElements[name].validation),
          },
          value: { $set: value },
        },
      });

      this._isMounted &&
        this.setState({
          detailsTabElements: tempFormElements,
          hasInputChanged: true,
        });
    }
  };

  handleSensorsTabElements = (data: SensorsTabElementsType[]) => {
    this.handleState({ sensorsTabElements: data, hasInputChanged: true });
  };

  handleAttributesTabElements = (data: AttributesTabElementsType[]) => {
    this.handleState({ attributesTabElements: data, hasInputChanged: true });
  };

  onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    this.handleSubmit();
  };

  handleSubmit = async (onConfirm?: () => void) => {
    const { detailsTabElements, sensorsTabElements, attributesTabElements } =
      this.state;

    const { userData, updateToken } = this.props;

    try {
      this.handleState({ loadingButton: true });
      const { url, method, contentType } = hardwareApi.postHardwareDetails();
      let sensorsData: Partial<SensorListType>[] = [];
      sensorsTabElements.forEach((el) => {
        sensorsData = update(sensorsData, {
          $push: [
            {
              shortName: el.shortName.value,
              units: el.units.value,
              longName: el.longName.value,
              minScale: parseInt(el.minScale.value),
              maxScale: parseInt(el.maxScale.value),
              // amberPoint: parseInt(el.amberPoint.value),
              // redPoint: parseInt(el.redPoint.value),
              graphColour: el.graphColour.value,
              compass: el.compass,
              markers:
                el.markers.length > 0
                  ? el.markers.map((el) => {
                      const { uuid, ...rest } = el;
                      return rest;
                    })
                  : undefined,
            },
          ],
        });
      });
      let attributesData: Partial<HardwareAttributeType>[] = [];
      attributesTabElements.forEach((el) => {
        attributesData = update(attributesData, {
          $push: [
            {
              name: el.name.value,
              label: el.label.value,
              helptext: el.helptext.value,
              type: el.type.value,
              chars: parseInt(el.chars.value),
              required: el.required,
              default: el.default.value || undefined,
              options:
                el.options.length > 0
                  ? el.options.map((elem) => {
                      const { uuid, ...rest } = elem;
                      return rest;
                    })
                  : undefined,
            },
          ],
        });
      });
      const apiData = {
        make: detailsTabElements.make.value,
        model: detailsTabElements.model.value,
        sensorSpecs: [...sensorsData],
        attributes: [...attributesData],
        partnerID: detailsTabElements.partner.value,
      };
      const response = await apiCall({
        storeToken: userData.token,
        url,
        method,
        contentType,
        cancelToken: this.axiosCancelSource.token,
        data: apiData,
      });
      const result = response?.data;

      updateToken(result);

      if (result?.status === 'ok') {
        this._isMounted && handleNotification('success', result);
        this._isMounted &&
          this.setState(
            {
              loadingButton: false,
              hasInputChanged: false,
            },
            () => {
              const { history } = this.props;
              setTimeout(() => {
                history.push({
                  pathname: hardwareRoutes.details(),
                  state: {
                    hardwareID: result?.data?.templateID,
                    allowEdit: true,
                  },
                });
              }, 1000);
            }
          );
      } else {
        this._isMounted && handleNotification('error', result);
        this.handleState({ loadingButton: false });
      }
    } catch (error: any) {
      this._isMounted && handleNotification('error', error?.data);
      this.handleState({ loadingButton: false });
    }
  };

  handlePopupConfirm = (onConfirm: () => void) => {
    this.handleSubmit(onConfirm);
  };

  setCurrentTabIndex = (index: string) => {
    const { detailsTabElements } = this.state;
    if (index === '2') {
      let tempFormElements = { ...detailsTabElements };
      let key: keyof typeof tempFormElements;
      let elementsName: string[] = Object.keys(detailsTabElements);

      for (key in tempFormElements) {
        if (elementsName.includes(key)) {
          tempFormElements = update(tempFormElements, {
            [key]: {
              touched: { $set: true },
              valid: {
                $set: checkValidation(
                  tempFormElements[key].value,
                  tempFormElements[key].validation
                ),
              },
            },
          });
        }
      }

      this._isMounted &&
        this.setState({
          detailsTabElements: tempFormElements,
        });

      for (key in tempFormElements) {
        if (elementsName.includes(key) && !tempFormElements[key].valid) {
          message.error('Please fill all the fields');
          return;
        }
      }
    }
    // else if (index === '3') {
    //   if (sensorsTabElements.length === 0) {
    //     message.error('At least one sensor is needed');
    //     return;
    //   }
    // }
    this._isMounted && this.setState({ currentTabIndex: index });
  };

  render() {
    const {
      loading,
      currentTabIndex,
      detailsTabElements,
      sensorsTabElements,
      attributesTabElements,
      loadingButton,
      hasInputChanged,
    } = this.state;

    const { userPermissionList } = this.props;

    if (userPermissionList.length === 0) {
      return <Redirect to="/" />;
    }

    return (
      <Fragment>
        {hasInputChanged && (
          <PromptPopup
            when={hasInputChanged}
            handleConfirm={this.handlePopupConfirm}
          />
        )}
        <FormWrapper loading={loading} title="Hardware Add">
          <form onSubmit={this.onFormSubmit} noValidate>
            <Tabs
              activeKey={currentTabIndex}
              onTabClick={this.setCurrentTabIndex}>
              <Tabs.TabPane tab={'Details'} key="1">
                <DetailsTab
                  detailsTabElements={detailsTabElements}
                  detailsInputChangedHandler={this.detailsInputChangedHandler}
                  setCurrentTabIndex={this.setCurrentTabIndex}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab={'Sensors'} key="2">
                <SensorsTab
                  handleSensorsTabElements={this.handleSensorsTabElements}
                  sensorsTabElements={sensorsTabElements}
                  setCurrentTabIndex={this.setCurrentTabIndex}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab={'Attributes'} key="3">
                <AttributesTab
                  handleAttributesTabElements={this.handleAttributesTabElements}
                  attributesTabElements={attributesTabElements}
                  loadingButton={loadingButton}
                />
              </Tabs.TabPane>
            </Tabs>
          </form>
        </FormWrapper>
      </Fragment>
    );
  }
}

const mapDispatch = {
  updateToken: updateToken,
};

const connector = connect(null, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(HardwareAdd);
