import { useState, useCallback, useRef, useEffect } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { RequestConfigType } from '../type-definitions/api-types';

type SendRequestType = {
  requestConfig: RequestConfigType[];
  headersConfig: { storeToken?: string };
  applyData?: (responses: PromiseSettledResult<any>[]) => void;
};

const useHttp = (loading?: boolean) => {
  const [isLoading, setIsLoading] = useState(loading || false);
  const isMounted = useRef(false);
  const axiosCancelSource = useRef<CancelTokenSource | null>(null);

  useEffect(() => {
    isMounted.current = true;
    axiosCancelSource.current = axios.CancelToken.source();
    return () => {
      isMounted.current = false;
      axiosCancelSource.current?.cancel('Component Unmounted');
    };
  }, []);

  const sendRequest = useCallback(
    async ({ requestConfig, headersConfig, applyData }: SendRequestType) => {
      isMounted.current && setIsLoading(true);

      const headers: {
        'X-API-KEY'?: undefined | string;
        'Content-Type'?: string;
      } = {};

      if (headersConfig.storeToken) {
        headers['X-API-KEY'] = headersConfig.storeToken;
      }

      const apiCollection: any[] = [];

      requestConfig.forEach((el) => {
        apiCollection.push(
          axios({
            ...el,
            headers: {
              ...headers,
              'Content-Type': el.contentType || 'application/json',
            },
            cancelToken: axiosCancelSource.current?.token,
          })
        );
      });

      const responses = await Promise.allSettled([...apiCollection]);

      isMounted.current && applyData?.(responses);

      isMounted.current && setIsLoading(false);
    },
    []
  );

  return {
    isLoading,
    sendRequest,
  };
};

export default useHttp;
