import { Fragment, useState, useRef, useEffect, FormEvent } from 'react';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import axios, { CancelTokenSource } from 'axios';
import { FormInputObjectType } from '../type-definitions';
import { UserDataType } from '../type-definitions/api-types';
import {
  initialElements,
  StateType,
  FormElementsType,
} from '../components/Login/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { checkValidation } from '../utils/validation';
import { apiCall } from '../api-services/api';
import { authApi } from '../api-services/api-list';
import { authSuccess } from '../redux/actions';
import { setInLocal } from '../utils/manage-storage';
import AntdInput from '../components/AntdInput';
import FormWrapper from '../components/FormWrapper';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { Button, message } from 'antd';
import { authRoutes, deviceRoutes } from '../Routes/routes-list';
import { handleNotification } from '../utils/notification-handler';
import { getUserPermissionList } from '../redux/selectors';

type PropsType = RouteComponentProps<
  { uname?: string; pwd?: string },
  any,
  any
> & {
  isAuth: boolean | undefined;
  userData: Partial<UserDataType>;
};

const initState = {
  formElements: cloneDeep(initialElements),
  isChecked: true,
  loading: false,
};

function Login({
  match,
  location,
  history,
  isAuth,
  userData,
}: Readonly<PropsType>) {
  const [state, setState] = useState<Readonly<StateType>>(initState);
  const _isMounted = useRef(false);
  const axiosCancelSource = useRef<CancelTokenSource>();
  const dispatch = useDispatch();
  const userPermissionList = useSelector(getUserPermissionList);

  function handleState(data: object) {
    _isMounted.current &&
      setState((prevState) => ({
        ...prevState,
        ...data,
      }));
  }

  useEffect(() => {
    _isMounted.current = true;
    axiosCancelSource.current = axios.CancelToken.source();
    return () => {
      _isMounted.current = false;
      axiosCancelSource.current?.cancel('Component Unmounted');
    };
  }, []);

  useEffect(() => {
    const uname = match?.params?.uname;
    const pwd = match?.params?.pwd;
    const { search } = location;
    const params = new URLSearchParams(search);
    const user_name = params.get('uname');
    const pass = params.get('pwd');

    const userName = uname || user_name || '';
    const userPass = pwd || pass || '';

    if (userName || userPass) {
      const inputElements = cloneDeep(state.formElements);
      inputElements.userName.value = userName;
      inputElements.password.value = userPass;

      handleState({ formElements: inputElements });
    }
  }, [location, match, state.formElements]);

  if (isAuth && userPermissionList.length > 0) {
    return <Redirect to={{ pathname: deviceRoutes.list }} />;
  }

  function inputChangedHandler(name: keyof FormElementsType, value: string) {
    const tempFormElements: FormElementsType = { ...state.formElements };
    if (name) {
      tempFormElements[name].value = value;
      tempFormElements[name].touched = true;
      tempFormElements[name].valid = checkValidation(
        value,
        tempFormElements[name].validation
      );

      handleState({ formElements: tempFormElements });
    }
  }

  function onFormSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const { formElements, isChecked } = state;
    const inputElements: FormElementsType = { ...formElements };

    (function () {
      let key: keyof typeof inputElements;
      for (key in inputElements) {
        inputElements[key].touched = true;
        inputElements[key].valid = checkValidation(
          inputElements[key].value,
          inputElements[key].validation
        );
      }
    })();

    handleState({ formElements: inputElements });

    let key: keyof typeof inputElements;
    for (key in inputElements) {
      if (!inputElements[key].valid) {
        message.error('Please fill all the fields');
        return;
      }
    }

    handleState({ loading: true });

    (async function () {
      const data = {
        userName: inputElements.userName.value,
        password: inputElements.password.value,
        checkbox: isChecked,
      };
      const stateData: {
        loading?: boolean;
        formElements?: FormElementsType;
      } = {};
      try {
        const { url, method, contentType, params } = authApi.getLogin({
          username: data.userName,
          password: data.password,
        });
        const response = await apiCall({
          url,
          method,
          contentType,
          params,
          cancelToken: axiosCancelSource.current?.token,
        });
        const result = response?.data;
        if (result?.status === 'ok') {
          dispatch(authSuccess({ userData: result.data }));
          if (data.checkbox) {
            setInLocal('token', result.token);
          }
        }

        // stateData.loading = false;
      } catch (error: any) {
        let result = error.data;
        if (!result) {
          result = error;
        }
        if (result?.status === 'changePassword') {
          // dispatch(authSuccess({ userData: result }));
          setTimeout(() => {
            history.push({
              pathname: authRoutes.changePassword(),
              state: {
                userName: state.formElements.userName.value,
                currentPwd: state.formElements.password.value,
                isChecked: state.isChecked,
                apiResponse: result,
              },
            });
          }, 100);
        } else {
          handleNotification('error', result);
          stateData.loading = false;
        }
      }

      handleState({ ...stateData });
    })();
  }

  function handleCheckbox(event: CheckboxChangeEvent) {
    handleState({ isChecked: event?.target?.checked });
  }

  function handlePeepIcon() {
    const inputElements: any = { ...state.formElements };

    if (inputElements.password.elementConfig.type === 'password') {
      inputElements.password.elementConfig.type = 'text';
    } else {
      inputElements.password.elementConfig.type = 'password';
    }

    handleState({
      formElements: inputElements,
    });
  }

  const elements = getInputElementsSingle({
    formElements: state.formElements,
    inputChangedHandler,
    sliceValue: [0, 2],
    handlePeepIcon,
  });

  return (
    <Fragment>
      <FormWrapper loading={state.loading} title="Login">
        <form noValidate onSubmit={onFormSubmit}>
          {elements}

          <div className="row align-items-center my-3">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <Checkbox
                name={`checkbox`}
                onChange={handleCheckbox}
                checked={state.isChecked}>
                {`Remember me`}
              </Checkbox>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <Link to={authRoutes.resetPassword()}>Reset Password?</Link>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-5 col-lg-4 col-xl-3">
              <Button type="primary" htmlType="submit" block size="large">
                Submit
              </Button>
            </div>
          </div>
        </form>
      </FormWrapper>
    </Fragment>
  );
}

// export default Login as any;
export default Login;

function getInputElementsSingle({
  formElements,
  inputChangedHandler,
  sliceValue,
  handlePeepIcon,
}: {
  formElements: FormInputObjectType;
  inputChangedHandler: (name: any, value: any) => void;
  sliceValue: number[];
  handlePeepIcon: () => void;
}) {
  let formElementsArray = [];

  for (const key in formElements) {
    formElementsArray.push({
      id: key,
      config: formElements[key],
    });
  }

  formElementsArray = formElementsArray.slice(...sliceValue);
  const inputElements = formElementsArray.map((item) => {
    const inputProps: { onInputHover?: () => void } = {};
    if (item.config.showPeep) {
      inputProps.onInputHover = () => handlePeepIcon();
    }

    return (
      <Fragment key={item.id}>
        <div className="row">
          <div className="col-xl-12">
            <AntdInput
              {...item.config}
              {...inputProps}
              onInputChanged={inputChangedHandler}
            />
          </div>
        </div>
      </Fragment>
    );
  });

  return inputElements;
}
