import axios, { CancelTokenSource } from 'axios';
import {
  Fragment,
  useState,
  useRef,
  useEffect,
  useCallback,
  FormEvent,
  memo,
} from 'react';
import { useDispatch } from 'react-redux';
import { v4 } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';
import { Col, Input, Modal, Row } from 'antd';
import { MdAdd } from 'react-icons/md';

import { apiCall } from '../../../api-services/api';
import {
  ApiErrorType,
  ApiResultType,
  ApiSuccessType,
  AssignableDeviceListType,
  UserDataType,
} from '../../../type-definitions/api-types';
import { deviceApi } from '../../../api-services/api-list';
import { updateToken } from '../../../redux/actions';
import { getKeysFromEnum, handleTableSearch } from '../../../utils';
import { AssignableDeviceListEnumKeys } from '../../../api-services/api-responses';
import NotificationHandler from '../../NotificationHandler';
import AntdTable from '../../AntdTable';

import cssStyles from '../styles/locationDetails.module.scss';

const initState = {
  loading: true,
  success: {},
  error: {},
  dataList: [],
  initDataList: [],
  searchValue: '',
};

interface StateType {
  loading: boolean;
  error: ApiErrorType;
  success: ApiSuccessType;
  searchValue: string;
  dataList: AssignableDeviceListType[];
  initDataList: AssignableDeviceListType[];
}

interface PropsType {
  userData: Partial<UserDataType>;
  showModal: boolean;
  // closeModal: (result?: ApiSuccessType) => void;
  closeModal: (id?: string) => void;
  locationID: string;
}

const tableColumnKeys = getKeysFromEnum(AssignableDeviceListEnumKeys);

function AddAssignModal({
  userData,
  locationID,
  showModal,
  closeModal,
}: PropsType) {
  const [state, setState] = useState<Readonly<StateType>>(initState);
  const _isMounted = useRef(false);
  const axiosCancelSource = useRef<CancelTokenSource>();
  const dispatch = useDispatch();

  function handleState(data: object) {
    _isMounted.current &&
      setState((prevState) => ({
        ...prevState,
        ...data,
      }));
  }

  useEffect(() => {
    axiosCancelSource.current = axios.CancelToken.source();
    _isMounted.current = true;
    return () => {
      _isMounted.current = false;
      axiosCancelSource.current?.cancel('Component Unmounted');
    };
  }, []);

  useEffect(() => {
    if (state.dataList.length === 0) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = useCallback(async () => {
    const { url, method, contentType, params } = deviceApi.putAssignableDevices(
      { locationID }
    );
    const response = await apiCall({
      storeToken: userData?.token,
      url,
      method,
      contentType,
      params,
      cancelToken: axiosCancelSource.current?.token,
    });
    const result: ApiResultType = response?.data;
    dispatch(updateToken(result));

    if (result.status === 'ok') {
      if (result.data && result.data.length > 0) {
        const tempData: AssignableDeviceListType[] = result.data.map(
          (item: AssignableDeviceListType) => ({
            ...item,
            uuid: v4(),
          })
        );
        handleState({
          loading: false,
          dataList: tempData,
          initDataList: tempData,
        });
      } else {
        handleState({ loading: false });
      }
    } else {
      handleState({ error: result, loading: false });
    }
  }, [dispatch, locationID, userData?.token]);

  function handleOnChange(event: FormEvent<HTMLInputElement>) {
    const value = event?.currentTarget?.value;
    const stateData: Partial<StateType> = {
      searchValue: value,
    };
    if (value) {
      let tempData = cloneDeep(state.dataList);

      tempData = handleTableSearch({
        data: tempData,
        columnList: tableColumnKeys,
        searchData: value,
      });
      stateData.dataList = tempData;
    } else {
      stateData.dataList = state.initDataList;
    }

    handleState({ ...stateData });
  }

  async function handleAddDevice(id: string) {
    if (!state.loading) {
      handleState({ loading: true });
    }

    const stateData: Partial<StateType> = {};
    try {
      const { url, method, contentType } = deviceApi.putAssignToLocation(
        undefined,
        {
          deviceID: id,
          locationID,
        }
      );
      const response = await apiCall({
        storeToken: userData?.token,
        url,
        method,
        contentType,
        cancelToken: axiosCancelSource.current?.token,
      });
      const result = response?.data;
      if (result) {
        dispatch(updateToken(result));
      }
      stateData.loading = false;
      if (result?.status === 'ok') {
        stateData.success = result;
        setTimeout(() => {
          closeModal(id);
        }, 1000);
      } else {
        stateData.error = result;
      }
    } catch (error: any) {
      stateData.error = error;
      stateData.loading = false;
    }
    handleState({ ...stateData });
  }

  function onCloseModal() {
    closeModal();
  }

  const pageTitle = `Select a device to assign to ${locationID}`;

  return (
    <Fragment>
      <NotificationHandler
        success={state.success}
        error={state.error}
        obj={{ _isMounted: _isMounted.current, setState }}
      />
      <Modal
        // width={760}
        visible={showModal}
        onCancel={onCloseModal}
        closable={false}
        footer={null}
        className={cssStyles.assign_modal}>
        <Row justify="center" className="py-2">
          <Col style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
            {pageTitle}
          </Col>
        </Row>

        <Row className="pt-3 pb-4" justify="space-between" align="middle">
          <Col style={{ fontWeight: 'bold' }}>
            Assign devices to this location from OFFLINE or STOCK locations
          </Col>
          <Col>
            <Input
              disabled={state.loading}
              type="text"
              value={state.searchValue}
              placeholder="Search"
              name="searchValue"
              onChange={handleOnChange}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <AntdTable
              loading={state.loading}
              columns={getColumns({
                handleAddDevice,
              })}
              dataSource={state.dataList}
              pagination={{
                total: state.dataList.length,
                pageSize: 5,
              }}
              customConfig={{ rowKeyValue: 'uuid' }}
              // tableClasses={cssStyles.swap_modal_tbl}
            />
          </Col>
        </Row>
      </Modal>
    </Fragment>
  );
}

export default memo(AddAssignModal);

function getColumns({
  handleAddDevice,
}: {
  handleAddDevice: (id: string) => void;
}) {
  const columns = [
    // 0
    {
      title: 'Device ID',
      dataIndex: 'mac',
      key: 'mac',
    },
    // 1
    {
      title: 'Manufacturer',
      dataIndex: 'manufacturer',
      key: 'manufacturer',
    },
    // 2
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
    },
    // 3
    {
      title: 'Location ID',
      dataIndex: 'locID',
      key: 'locID',
    },
    // 4
    {
      title: 'Location Name',
      dataIndex: 'locationName',
      key: 'locationName',
    },
    // 5
    {
      title: 'Location Status',
      dataIndex: 'locationStatus',
      key: 'locationStatus',
    },
    // 6
    {
      title: 'Partner ID',
      dataIndex: 'partnerID',
      key: 'partnerID',
    },
    // 7
    {
      title: 'Action',
      key: 'action',
      render: (text: string, record: AssignableDeviceListType) => {
        return (
          <Fragment>
            {record.locationStatus !== 'LIVE' && (
              <MdAdd
                size="2em"
                style={{ color: '#1890ff', cursor: 'pointer' }}
                onClick={() => handleAddDevice(record.mac)}
              />
            )}
          </Fragment>
        );
      },
    },
  ];

  return columns;
}
