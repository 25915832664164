export function getApiPath(param: string) {
  if (process.env.NODE_ENV === 'development') {
    return `https://${param}.airsensa.io`;
  }
  if (process.env.NODE_ENV === 'production') {
    return `https://${param}.airsensa.io`;
  }
}

export function getApiPathMaint() {
  if (process.env.NODE_ENV === 'development') {
    return `https://maint.airsensa.tech`;
  }
  if (process.env.NODE_ENV === 'production') {
    return `https://maint.airsensa.tech`;
  }
}
