import { FormEvent, Fragment, ReactNode } from 'react';
import { Select, Input, Button } from 'antd';

import cssStyles from './tableWrapper.module.scss';
import AntdCoverSpinner from '../AntdCoverSpinner';
import AntdCard from '../../components-shared/AntdCard';
import AntdTable, { AntdTablePropsType } from '../AntdTable';

type PropsType = {
  loading?: boolean;
  pageTitle?: string;
  inputComponents: () => ReactNode;
  tableDetails: AntdTablePropsType;
};

function TableWrapper({
  loading,
  pageTitle,
  inputComponents,
  tableDetails,
}: PropsType) {
  return (
    <Fragment>
      <div className={`row ${cssStyles.root}`}>
        <div className="col-lg-12">
          <AntdCoverSpinner active={loading}>
            <AntdCard>
              <div className="row">
                <div className="col-lg-12">
                  <div className="row py-2 title">
                    <div className="col-lg-6">
                      <h5>{pageTitle}</h5>
                    </div>
                  </div>
                  <div className="row align-items-center py-2 input_components">
                    {inputComponents?.()}
                  </div>
                  <div className="row align-items-center py-2">
                    <div className="col-lg-12">
                      <AntdTable {...tableDetails} />
                    </div>
                  </div>
                </div>
              </div>
            </AntdCard>
          </AntdCoverSpinner>
        </div>
      </div>
    </Fragment>
  );
}

export default TableWrapper;

export function PartnerDropdown({
  data,
  onSelectChange,
  value,
}: {
  data: string[];
  onSelectChange: (value: string) => void;
  value: string;
}) {
  return (
    <Fragment>
      <Select
        value={value}
        placeholder="Select Partner..."
        size="large"
        defaultValue=""
        style={{ width: '100%' }}
        onChange={onSelectChange}>
        <Select.Option value="">All</Select.Option>
        {data.length > 0 &&
          data.map((item, index) => {
            return (
              <Select.Option key={index} value={item}>
                {item}
              </Select.Option>
            );
          })}
      </Select>
    </Fragment>
  );
}

export function SearchInput({
  value,
  onInputChanged,
}: {
  value: string;
  onInputChanged?: (name: string, value: string) => void;
}) {
  function handleInputChange(event: FormEvent<HTMLInputElement>) {
    onInputChanged?.(event.currentTarget.name, event.currentTarget.value);
  }
  return (
    <Fragment>
      <Input
        name="searchInput"
        value={value}
        onChange={handleInputChange}
        size="large"
        placeholder="Seacrh..."
      />
    </Fragment>
  );
}

const paginationList = [10, 20, 50, 100];

export function PaginationDropdown({
  onSelectChange,
  classes,
}: {
  onSelectChange: (value: string) => void;
  classes?: string;
}) {
  return (
    <Fragment>
      <Select
        placeholder="Select Pagination..."
        className={cssStyles.pagination || classes}
        defaultValue={'10'}
        size="large"
        onChange={onSelectChange}>
        {paginationList &&
          paginationList.length > 0 &&
          paginationList.map((item) => {
            return (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            );
          })}
      </Select>
    </Fragment>
  );
}

export function AddButton({ onButtonClick }: { onButtonClick: () => void }) {
  return (
    <Fragment>
      <Button
        type="primary"
        size="large"
        block
        htmlType="button"
        onClick={onButtonClick}>
        ADD
      </Button>
    </Fragment>
  );
}
