import { Component, FormEvent, Fragment } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import update from 'immutability-helper';

import {
  StateType,
  initialElements,
  FormElementsType,
} from '../components/ResetPassword/helpers';
import { updateToken } from '../redux/actions';
import { UserDataType } from '../type-definitions/api-types';
import axios from 'axios';
import { checkValidation } from '../utils/validation';
import { apiCall } from '../api-services/api';
import { authApi } from '../api-services/api-list';
import { authRoutes } from '../Routes/routes-list';
import { getInputElementsSingle } from '../utils/get-input-elements';
import FormWrapper from '../components/FormWrapper';
import { Button, message } from 'antd';
import { handleNotification } from '../utils/notification-handler';

interface PropsType extends RouteComponentProps, PropsFromRedux {
  userData: Partial<UserDataType>;
  userPermissionList: string[];
}

class ResetPassword extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      formElements: { ...initialElements },
      loading: false,
    };
  }

  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  inputChangedHandler = (name: keyof FormElementsType, value: string) => {
    const { formElements } = this.state;

    let tempFormElements = { ...formElements };

    if (name) {
      tempFormElements = update(tempFormElements, {
        userName: {
          value: { $set: value },
          touched: { $set: true },
          valid: {
            $set: checkValidation(value, tempFormElements[name].validation),
          },
        },
      });
    }

    this._isMounted && this.setState({ formElements: tempFormElements });
  };

  onFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { updateToken } = this.props;
    const { formElements } = this.state;

    let tempFormElements = { ...formElements };

    let key: keyof typeof tempFormElements;

    for (key in tempFormElements) {
      tempFormElements = update(tempFormElements, {
        userName: {
          touched: { $set: true },
          valid: {
            $set: checkValidation(
              tempFormElements[key].value,
              tempFormElements[key].validation
            ),
          },
        },
      });
    }

    this.setState({ formElements: tempFormElements });

    for (key in tempFormElements) {
      if (!tempFormElements[key].valid) {
        message.error('Please fill all the fields');
        return;
      }
    }

    this._isMounted && this.setState({ loading: true });

    try {
      const { url, method, contentType, params } = authApi.getResetPassword({
        username: tempFormElements.userName.value,
      });
      const response = await apiCall({
        url,
        method,
        params,
        contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      if (result) {
        updateToken(result);

        if (result?.status === 'ok') {
          handleNotification(
            'success',
            {
              status: 'ok',
              message: `Your request has been sent, you should receive an email shortly with a temporary password. Please check your spam folder if you do not receive this within 3 minutes`,
            },
            { onClose: this.handleCancel }
          );
        } else {
          handleNotification('error', result);
        }
      }
      this._isMounted &&
        this.setState({
          loading: false,
        });
    } catch (error) {
      handleNotification('error', error.data);
      this._isMounted && this.setState({ loading: false });
    }
  };

  handleCancel = () => {
    const { history } = this.props;
    history.push({
      pathname: authRoutes.login,
    });
  };

  render() {
    const { formElements, loading } = this.state;

    const single = getInputElementsSingle({
      formElements,
      inputChangedHandler: this.inputChangedHandler,
      sliceValue: [0, 1],
    });

    return (
      <Fragment>
        <FormWrapper loading={loading} title="Reset Password">
          <form noValidate onSubmit={this.onFormSubmit}>
            {single}

            <div className="row justify-content-center">
              <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3 text-center">
                <Button type="primary" size="large" htmlType="submit" block>
                  Submit
                </Button>
              </div>
              <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3 text-center">
                <Button
                  type="primary"
                  size="large"
                  htmlType="button"
                  block
                  onClick={this.handleCancel}>
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </FormWrapper>
      </Fragment>
    );
  }
}

const mapDispatch = {
  updateToken: updateToken,
};
const connector = connect(null, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ResetPassword);
