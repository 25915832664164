import { PureComponent, Fragment, FormEvent } from 'react';
import { Input, Card, Button, Modal } from 'antd';
import { connect, ConnectedProps } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';

import { updateToken } from '../../../redux/actions';
import {
  ApiErrorType,
  ApiSuccessType,
  StockDevicesType,
} from '../../../type-definitions/api-types';
import {
  getKeysFromEnum,
  handleSorting,
  handleTableSearch,
} from '../../../utils';
import { StockDevicesEnumKeys } from '../../../api-services/api-responses';
import { apiCall } from '../../../api-services/api';
import { deviceApi } from '../../../api-services/api-list';
import axios from 'axios';
import { v4 } from 'uuid';
import AntdTable from '../../AntdTable';
import NotificationHandler from '../../NotificationHandler';
import {
  AntdTableColumnsType,
  ReduxStateType,
} from '../../../type-definitions';

type PropsType = PropsFromRedux & {
  showModal: boolean;
  handleAssignStockDevice: () => void;
  onAssignStockDeviceSelect: (deviceID: string) => void;
};

type StateType = {
  dataList: StockDevicesType[];
  initDataList: StockDevicesType[];
  loading: boolean;
  searchInput: string;
  error: ApiErrorType;
  success: ApiSuccessType;
};

const tableColumnsKeys = getKeysFromEnum(StockDevicesEnumKeys);

class AssignStockDeviceModal extends PureComponent<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      dataList: [],
      initDataList: [],
      loading: true,
      searchInput: '',
      error: {},
      success: {},
    };
  }

  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    this._isMounted = true;
    this.handleFetchedData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  fetchData = async () => {
    const { userData, updateToken } = this.props;
    try {
      const { url, method, contentType } = deviceApi.getStockDevices();
      const response = await apiCall({
        storeToken: userData.token,
        url,
        method,
        contentType,
      });
      const result = response?.data;
      updateToken(result);
      if (result.status === 'ok') {
        return { deviceList: result.data || [] };
      } else {
        return { error: result, deviceList: [] };
      }
    } catch (error: any) {
      return { error };
    }
  };

  handleFetchedData = async () => {
    const {
      error,
      deviceList,
    }: {
      error?: ApiErrorType;
      deviceList?: StockDevicesType[];
    } = await this.fetchData();

    if (error) {
      this.setState({ loading: false, error });
    } else {
      if (deviceList && deviceList.length > 0) {
        const tempData = deviceList.map((item) => ({ ...item, uuid: v4() }));
        this.setState({
          loading: false,
          dataList: tempData || [],
          initDataList: tempData || [],
        });
      } else {
        this.setState({ loading: false });
      }
    }
  };

  onPaginationChange = (page: number, pageSize?: number) => {
    // console.log('params', pagination, filters, sorter, extra);
  };

  handleSearch = (event: FormEvent<HTMLInputElement>) => {
    const targetValue = event.currentTarget.value;
    const { initDataList } = this.state;

    const tempInitData = cloneDeep(initDataList);

    if (!targetValue) {
      this.setState({
        dataList: tempInitData,
        searchInput: targetValue,
      });
    } else {
      const sortedData = handleTableSearch({
        data: tempInitData,
        columnList: tableColumnsKeys,
        searchData: targetValue,
      });

      this.setState({
        dataList: sortedData,
        searchInput: targetValue,
      });
    }
  };

  render() {
    const { showModal, handleAssignStockDevice, onAssignStockDeviceSelect } =
      this.props;
    const { loading, searchInput, dataList, success, error } = this.state;

    const paginationConfig = {
      // current: currentPage,
      pageSize: 10,
      total: dataList.length,
      onChange: this.onPaginationChange,
    };

    const columns = getColumns({ onAssignStockDeviceSelect });

    return (
      <Fragment>
        <NotificationHandler success={success} error={error} obj={this} />
        <Modal
          width={760}
          visible={showModal}
          onCancel={handleAssignStockDevice}
          // onOk={this.handleSubmit}
          footer={null}
          closable={false}>
          <Card bordered={false} loading={loading}>
            <div className="row">
              <div className="col-md-4 ml-auto mb-3">
                <label>Search:</label>
                <Input
                  type="text"
                  id="searchInput"
                  name="searchInput"
                  value={searchInput}
                  placeholder="Search..."
                  allowClear
                  onChange={this.handleSearch}
                  size="large"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AntdTable
                  columns={columns}
                  dataSource={dataList}
                  pagination={paginationConfig}
                  customConfig={{ rowKeyValue: 'uuid' }}
                />
              </div>
            </div>
          </Card>
        </Modal>
      </Fragment>
    );
  }
}

function getColumns({
  onAssignStockDeviceSelect,
}: {
  onAssignStockDeviceSelect: (deviceID: string) => void;
}): AntdTableColumnsType<StockDevicesType>[] {
  return [
    {
      title: 'Device ID',
      dataIndex: 'deviceID',
      key: 'deviceID',
      // filters: [],
      // filterMultiple: true,
      onFilter: (value: any, record: StockDevicesType) =>
        record.deviceID.indexOf(value) === 0,
      sorter: (a: StockDevicesType, b: StockDevicesType) =>
        handleSorting(a.deviceID, b.deviceID),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Location Name',
      dataIndex: 'locationName',
      key: 'locationName',
      // filters: [],
      // filterMultiple: true,
      onFilter: (value: any, record: StockDevicesType) =>
        record.locationName.indexOf(value) === 0,
      sorter: (a: StockDevicesType, b: StockDevicesType) =>
        handleSorting(a.locationName, b.locationName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Manufacturer',
      dataIndex: 'manufacturer',
      key: 'manufacturer',
      // filters: [],
      // filterMultiple: true,
      onFilter: (value: any, record: StockDevicesType) =>
        record.manufacturer.indexOf(value) === 0,
      sorter: (a: StockDevicesType, b: StockDevicesType) =>
        handleSorting(a.manufacturer, b.manufacturer),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Partner',
      dataIndex: 'partnerID',
      key: 'partnerID',
      // filters: [],
      // filterMultiple: true,
      onFilter: (value: any, record: StockDevicesType) =>
        record.partnerID.indexOf(value) === 0,
      sorter: (a: StockDevicesType, b: StockDevicesType) =>
        handleSorting(a.partnerID, b.partnerID),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record: StockDevicesType) => (
        <Fragment>
          <Button
            onClick={(event) => onAssignStockDeviceSelect(record.deviceID)}
            type="primary">
            Select
          </Button>
        </Fragment>
      ),
    },
  ];
}

const mapDispatch = {
  updateToken: updateToken,
};

function mapStateToProps(state: ReduxStateType) {
  return {
    userData: state.auth.userData,
  };
}

const connector = connect(mapStateToProps, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AssignStockDeviceModal);
