import { Button } from 'antd';
import { Fragment } from 'react';
import Leaflet from 'leaflet';
import {
  FloorPlanLocationListType,
  LocationListType,
} from '../../../../type-definitions/api-types';
import FloorPlanMapMultiple from '../FloorPlanMapMultiple';

import cssStyles from '../styles/webViewFloorPlanDetails.module.scss';
import { MapDetailsType } from '../../../../type-definitions';

function MapContent({
  mapDetails,
  floorPlanLocationList,
  selectedLocation,
  onUpdateLocation,
  onRemoveLocation,
  handleAddLocationModal,
  setMapDefaultParameters,
  isEditable = false,
}: {
  mapDetails: MapDetailsType;
  floorPlanLocationList: FloorPlanLocationListType[];
  selectedLocation: LocationListType | {};
  onUpdateLocation: (data: FloorPlanLocationListType) => void;
  onRemoveLocation: (data: FloorPlanLocationListType) => void;
  handleAddLocationModal: () => void;
  setMapDefaultParameters: (
    position: Leaflet.LatLng,
    coordinates: { x: number; y: number }
  ) => void;
  isEditable?: boolean;
}) {
  return (
    <Fragment>
      <div className="row justify-content-center pb-3">
        <div className="col-lg-8 col-md-6 col-sm-6 text-center">
          <Button
            disabled={!isEditable}
            onClick={handleAddLocationModal}
            type="primary"
            size="middle">
            Add Location
          </Button>
        </div>
      </div>

      <div className={`row`}>
        <div className={`col-lg-12`}>
          <FloorPlanMapMultiple
            mapDetails={mapDetails}
            // updateLocation={updateLocation}
            floorPlanLocationList={floorPlanLocationList}
            selectedLocation={selectedLocation}
            // removeLocation={removeLocation}
            classes={cssStyles.floorPlanMap}
            onUpdateLocation={onUpdateLocation}
            onRemoveLocation={onRemoveLocation}
            setMapDefaultParameters={setMapDefaultParameters}
            isEditable={isEditable}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default MapContent;
