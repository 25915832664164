export function removeDuplicatesFromArray(data: any[], uniqueID: string) {
  // return [...new Set(data.map((item) => item[uniqueID]))].map((id) =>
  //   data.find((el) => el[uniqueID] === id)
  // );

  if (data.length > 0) {
    return [...new Map(data.map((item) => [item[uniqueID], item])).values()];
  }
  return [];
}
