import { Fragment, ReactNode } from 'react';
import { Button, Col, Row } from 'antd';
import { FormElementsType } from '../helpers/index';
import AntdInput from '../../AntdInput';

function DetailsTab({
  formElements,
  inputChangedHandler,
  dynamicInputElements,
  setCurrentTabIndex,
}: {
  formElements: FormElementsType;
  inputChangedHandler: (name: any, value: any) => void;
  dynamicInputElements?: ReactNode;
  setCurrentTabIndex?: (index: string) => void;
}) {
  return (
    <Fragment>
      <Row gutter={[{ md: 12 }, 0]}>
        <Col xs={24} md={12}>
          <AntdInput
            {...formElements.deviceMac}
            onInputChanged={inputChangedHandler}
          />
        </Col>
        <Col xs={24} md={12}>
          <AntdInput
            {...formElements.serialNumber}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>

      <Row gutter={[{ md: 12 }, 0]}>
        <Col xs={24}>
          <AntdInput
            {...formElements.partnerName}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>

      <Row gutter={[{ md: 12 }, 0]}>
        <Col xs={24} md={12}>
          <AntdInput
            {...formElements.manufacturer}
            onInputChanged={inputChangedHandler}
          />
        </Col>
        <Col xs={24} md={12}>
          <AntdInput
            {...formElements.model}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>

      <Row gutter={[{ md: 12 }, 0]}>
        <Col xs={24}>
          <AntdInput
            {...formElements.sensors}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>
      {dynamicInputElements}
      <Row justify="center">
        <Col xs={24} sm={12} md={8}>
          <Button
            block
            type="primary"
            htmlType="button"
            size="large"
            onClick={() => setCurrentTabIndex?.('2')}>
            Next
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
}

export default DetailsTab;
