import { useState, useCallback, useRef, useEffect } from 'react';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { handleNotification } from '../utils/notification-handler';
import { handleApiError } from '../utils';
import { useDispatch } from 'react-redux';
import { authLogout } from '../redux/actions/auth.actions';
import { RequestConfigType } from '../type-definitions/api-types';

type SendRequestType = {
  requestConfig: RequestConfigType[];
  headersConfig: { storeToken?: string };
  applyData?: (responses: AxiosResponse<any>[]) => void;
};

const useHttp = (loading?: boolean) => {
  const [isLoading, setIsLoading] = useState(loading || false);
  const isMounted = useRef(false);
  const axiosCancelSource = useRef<CancelTokenSource | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    isMounted.current = true;
    axiosCancelSource.current = axios.CancelToken.source();
    return () => {
      isMounted.current = false;
      axiosCancelSource.current?.cancel('Component Unmounted');
    };
  }, []);

  const sendRequest = useCallback(
    async ({ requestConfig, headersConfig, applyData }: SendRequestType) => {
      isMounted.current && setIsLoading(true);

      const headers: {
        'X-API-KEY'?: undefined | string;
        'Content-Type'?: string;
      } = {};

      if (headersConfig.storeToken) {
        headers['X-API-KEY'] = headersConfig.storeToken;
      }

      const apiCollection: any[] = [];

      requestConfig.forEach((el) => {
        apiCollection.push(
          axios({
            ...el,
            headers: {
              ...headers,
              'Content-Type': el.contentType || 'application/json',
            },
            cancelToken: axiosCancelSource.current?.token,
          })
        );
      });

      try {
        const responses = await Promise.all([...apiCollection]);

        isMounted.current && applyData?.(responses);
      } catch (error) {
        console.log(error);
        if (axios.isCancel(error)) {
          // isMounted.current &&
          //   handleNotification('error', { message: 'Axios Request Cancelled' });
        } else {
          const errorResponse = handleApiError(error);
          if (errorResponse?.data?.status === 'invalidTokenError') {
            dispatch(authLogout());
          }
          isMounted.current &&
            handleNotification('error', {
              message: errorResponse?.data?.message,
            });
        }
      }
      isMounted.current && setIsLoading(false);
    },
    [dispatch]
  );

  return {
    isLoading,
    sendRequest,
  };
};

export default useHttp;
