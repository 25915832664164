import {
  ERRORED,
  LOGOUT_REQUESTED,
  LOGOUT,
  TOKEN_REQUESTED,
  USER_RIGHT_LOADED,
  USER_RIGHT_REQUESTED,
  TOKEN_SUCCESS,
} from '../actionTypes/auth.types';

import {
  setInLocal,
  removeFromLocal,
  getFromLocal,
  removeFromSession,
} from '../../utils/manage-storage';
import { apiCall } from '../../api-services/api';
import { authApi } from '../../api-services/api-list';
import { ReduxStateType } from '../../type-definitions';
import {
  ApiResponseDataType,
  UserDataType,
} from '../../type-definitions/api-types';
import { call, all, takeEvery, put, select } from '../../utils/saga-libs';
import { updateTokenSaga } from './shared.sagas';
import { AxiosResponse } from 'axios';

export default function* watcherSaga() {
  yield all([
    takeEvery(LOGOUT_REQUESTED, authLogoutSaga),
    takeEvery(TOKEN_REQUESTED, getUserByTokenSaga),
    takeEvery(USER_RIGHT_REQUESTED, getUserRightsSaga),
  ]);
}

function* authLogoutSaga() {
  const local = localStorage && Object.keys(localStorage);
  const session = sessionStorage && Object.keys(sessionStorage);
  if (local && local.length > 0) {
    local.forEach((item) => {
      removeFromLocal(item);
    });
  }
  if (session && session.length > 0) {
    session.forEach((item) => {
      removeFromSession(item);
    });
  }

  yield put({ type: LOGOUT });
}

function* getUserByTokenSaga() {
  const userToken = getFromLocal('token');
  if (userToken) {
    try {
      const tokenLogin = authApi.getTokenLogin();
      const response: AxiosResponse<any> = yield call(apiCall, {
        storeToken: userToken,
        url: tokenLogin.url,
        method: tokenLogin.method,
        contentType: tokenLogin.contentType,
      });
      const result: ApiResponseDataType = response.data;
      if (result?.status === 'ok') {
        yield put({ type: TOKEN_SUCCESS, payload: { userData: result.data } });

        if (
          result?.data?.token &&
          userToken.toString() !== result.data.token.toString()
        ) {
          setInLocal('token', result.data.token);
        }
      }
    } catch (error) {
      yield put({ type: ERRORED, payload: { error: error.data } });
    }
  }
}

function* getUserRightsSaga() {
  try {
    const userData: UserDataType = yield select(
      (state: ReduxStateType) => state.auth.userData
    );
    const { url, method, contentType } = authApi.getUserRights();
    const response: AxiosResponse<any> = yield call(apiCall, {
      storeToken: userData?.token,
      url,
      method,
      contentType,
    });
    const result = response.data;
    yield* updateTokenSaga(result);
    if (result.data) {
      yield put({
        type: USER_RIGHT_LOADED,
        payload: { userRightList: result.data },
      });
    } else {
      yield put({
        type: ERRORED,
        payload: {
          status: 'notAuthorized',
          payload: `You don't have rights to access the pages!`,
        },
      });
    }
  } catch (error) {
    yield put({ type: ERRORED, payload: error });
  }
}
