import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  CancelToken,
  Method,
} from 'axios';
import { handleApiError } from '../../utils';

type ApiCallTypes = {
  token: string;
  url: string;
  method: Method;
  cancelToken?: CancelToken;
  data?: object | string;
  params?: {} | null;
  contentType?: string;
};

export async function webViewApiCall({
  token,
  url,
  method,
  cancelToken,
  // ## optional
  data,
  params,
  contentType,
}: ApiCallTypes) {
  let headers = {
    'X-API-KEY': token,
    'Content-Type': contentType,
  };

  const options: AxiosRequestConfig = {
    method,
    url,
    headers,
    params,
    data,
    cancelToken,
  };

  try {
    const response: AxiosResponse = await axios({ ...options });
    return response;
  } catch (error) {
    if (axios.isCancel(error)) {
    } else {
      const errorResponse = handleApiError(error);
      throw errorResponse;
    }
  }
}
