import { FormInputType } from '../../../type-definitions';
import {
  ApiErrorType,
  ApiSuccessType,
} from '../../../type-definitions/api-types';

export const initialElements: FormElementsType = {
  userName: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'User Name',
      name: 'userName',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'User Name is required',
    label: 'User Name *',
    disabled: true,
  },
  currentPassword: {
    elementType: 'input',
    elementConfig: {
      type: 'password',
      placeholder: 'Current Password',
      name: 'currentPassword',
      autoComplete: 'new-password',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Current Password is required',
    label: 'Current Password *',
    showPeep: true,
  },
  password: {
    elementType: 'input',
    elementConfig: {
      type: 'password',
      placeholder: 'New Password',
      name: 'password',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'New Password is required',
    label: 'New Password *',
    showPeep: true,
  },
  confirmPassword: {
    elementType: 'input',
    elementConfig: {
      type: 'password',
      placeholder: 'Confirm Password',
      name: 'confirmPassword',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Passwords must match',
    label: 'Confirm Password *',
    showPeep: true,
  },
};

export type FormElementsType = {
  userName: FormInputType;
  currentPassword: FormInputType;
  password: FormInputType;
  confirmPassword: FormInputType;
};

export interface StateType {
  formElements: FormElementsType;
  initElements: FormElementsType;
  loading: boolean;
  error: ApiErrorType;
  success: ApiSuccessType;
}
