import { Fragment, useEffect, useState } from 'react';
import { Button, Upload, Modal } from 'antd';
import { AiOutlineUpload } from 'react-icons/ai';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import {
  getInputElementsMultiple,
  getInputElementsSingle,
} from '../../../utils/get-input-elements';
import { FormElementsType } from '../helpers';
import LeafletMapMultiple from '../../../components-shared/LeafletMapMultiple';
import { UserDataType } from '../../../type-definitions/api-types';
import { v4 } from 'uuid';

function DetailsTab({
  formElements,
  inputChangedHandler,
  handleCancelRedirect,
  fileList,
  beforeFileUpload,
  onFileRemove,
  userData,
  onBoundsMarkerMove,
}: {
  formElements: FormElementsType;
  inputChangedHandler: (name: any, value: any) => void;
  handleCancelRedirect: () => void;
  fileList: any[];
  beforeFileUpload: (file: RcFile, fileList: RcFile[]) => boolean;
  onFileRemove: (file: UploadFile<any>) => void;
  userData: Partial<UserDataType>;
  onBoundsMarkerMove: (
    lat: number,
    lng: number,
    name: 'topLeft' | 'bottomRight'
  ) => void;
}) {
  const [mapVisibility, setMapVisibility] = useState<boolean>(false);
  const [mapZoom, setMapZoom] = useState<number>(10);

  const [mapList, setMapList] = useState<Array<any>>([
    {
      uuid: v4(),
      lat: userData.lat || 0,
      lng: (userData.lng || 0) - 0.05,
      markerName: 'topLeft',
      iconColor: 'blue',
    },
    {
      uuid: v4(),
      lat: userData.lat || 0,
      lng: (userData.lng || 0) + 0.05,
      markerName: 'bottomRight',
      iconColor: 'red',
    },
  ]);

  useEffect(() => {
    if (
      formElements.topLeftLat.value &&
      formElements.topLeftLng.value &&
      formElements.bottomRightLat.value &&
      formElements.bottomRightLng.value
    ) {
      let tempMapList = [...mapList];
      tempMapList = tempMapList.map((item) => {
        if (item.markerName === 'topLeft') {
          item.lat = parseFloat(formElements.topLeftLat.value);
          item.lng = parseFloat(formElements.topLeftLng.value);
        }

        if (item.markerName === 'bottomRight') {
          item.lat = parseFloat(formElements.bottomRightLat.value);
          item.lng = parseFloat(formElements.bottomRightLng.value);
        }

        return item;
      });

      setMapList(tempMapList);
    } else if (formElements.topLeftLat.value && formElements.topLeftLng.value) {
      let tempMapList = [...mapList];
      tempMapList = tempMapList.map((item) => {
        if (item.markerName === 'topLeft') {
          item.lat = parseFloat(formElements.topLeftLat.value);
          item.lng = parseFloat(formElements.topLeftLng.value);
        }
        return item;
      });

      setMapList(tempMapList);
    } else if (
      formElements.bottomRightLat.value &&
      formElements.bottomRightLng.value
    ) {
      let tempMapList = [...mapList];
      tempMapList = tempMapList.map((item) => {
        if (item.markerName === 'bottomRight') {
          item.lat = parseFloat(formElements.bottomRightLat.value);
          item.lng = parseFloat(formElements.bottomRightLng.value);
        }

        return item;
      });

      setMapList(tempMapList);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formElements.topLeftLat.value,
    formElements.topLeftLng.value,
    formElements.bottomRightLat.value,
    formElements.bottomRightLng.value,
  ]);

  const tempFormElements = { ...formElements };
  const single = getInputElementsSingle({
    formElements: tempFormElements,
    inputChangedHandler,
    sliceValue: [0, 3],
  });

  // North West
  const multiple = getInputElementsMultiple({
    formElements: tempFormElements,
    inputChangedHandler,
    sliceValue: [3, 5],
  });

  // South East
  const multiple2 = getInputElementsMultiple({
    formElements: tempFormElements,
    inputChangedHandler,
    sliceValue: [5, 7],
  });

  function handleMarkerMove(
    lat: number,
    lng: number,
    markerName: 'topLeft' | 'bottomRight'
  ) {
    onBoundsMarkerMove(lat, lng, markerName);
  }

  return (
    <Fragment>
      {single}
      <div className="row align-items-center">
        <div className="col-md-10">
          {multiple}
          {multiple2}
        </div>
        <div className="col-md-2">
          <Fragment>
            <div className="col-xl-4 pb-3 pb-sm-3 pb-md-3 pb-lg-3 pb-xl-0 pt-xl-2">
              <Button
                size="small"
                type="primary"
                htmlType="button"
                onClick={() => setMapVisibility(true)}>
                MAP
              </Button>
            </div>
          </Fragment>
        </div>
      </div>

      <div className="row">
        <div className="col-6 pb-3">
          <Upload
            fileList={fileList}
            beforeUpload={beforeFileUpload}
            onRemove={onFileRemove}>
            <Button icon={<AiOutlineUpload />}>Select File</Button>
          </Upload>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4">
          <Button block type="primary" htmlType="submit" size="large">
            Next
          </Button>
        </div>

        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 btn-mobile-padding-top">
          <Button
            block
            type="primary"
            htmlType="button"
            size="large"
            onClick={handleCancelRedirect}>
            Cancel
          </Button>
        </div>
      </div>

      {mapVisibility && (
        <Fragment>
          <Modal
            width={'60%'}
            closable={false}
            footer={null}
            visible={mapVisibility}
            onCancel={() => setMapVisibility(false)}>
            <Fragment>
              <div className="row">
                <div
                  className="col-6 text-center"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <FaMapMarkerAlt
                    size="3em"
                    style={{
                      color: '#2596be',
                    }}
                  />{' '}
                  Top Left
                </div>
                <div
                  className="col-6 text-center"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <FaMapMarkerAlt
                    size="3em"
                    style={{
                      color: '#ed0e1d',
                    }}
                  />{' '}
                  Bottom Right
                </div>
              </div>
              <div className="row py-2">
                <div className="col-12">
                  <LeafletMapMultiple
                    center={[userData.lat || 0, userData.lng || 0]}
                    mapList={mapList}
                    zoom={mapZoom}
                    onMapZoom={(zoom) => setMapZoom(zoom)}
                    onMarkerMove={handleMarkerMove}
                    styles={{ height: '50vh' }}
                  />
                </div>
              </div>
            </Fragment>
          </Modal>
        </Fragment>
      )}
    </Fragment>
  );
}

export default DetailsTab;
