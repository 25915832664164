type RoutesType = Readonly<{
  list: string;
  add: string;
  details: (params?: {}) => string;
}>;

interface AuthRoutesType {
  login: string;
  profileEdit: () => string;
  resetPassword: () => string;
  changePassword: () => string;
  profileChangePassword: () => string;
  bugReporting: () => string;
}

export const authRoutes: AuthRoutesType = {
  login: `/login`,
  profileEdit: function () {
    return `/profile/edit`;
  },
  resetPassword: () => {
    return `/reset-pass`;
  },
  changePassword: () => {
    return `/auth/change-password`;
  },
  profileChangePassword: function () {
    return `/profile/change-password`;
  },
  bugReporting: () => {
    return `/bug-reporting`;
  },
};

export const deviceRoutes: RoutesType = {
  list: `/device/list`,
  add: `/device/add`,
  details: function () {
    return `/device/details`;
  },
};

export const userRoutes: RoutesType = {
  list: `/user/list`,
  add: `/user/add`,
  details: function () {
    return `/user/details`;
  },
};

export const locationRoutes: RoutesType = {
  list: `/location/list`,
  add: `/location/add`,
  details: function () {
    return `/location/details`;
  },
};

export const groupRoutes: RoutesType = {
  list: `/group/list`,
  add: `/group/add`,
  details: function () {
    return `/group/details`;
  },
};

export const partnerRoutes: RoutesType = {
  list: `/partner/list`,
  add: `/partner/add`,
  details: function () {
    return `/partner/details`;
  },
};

export const floorPlanRoutes: RoutesType = {
  list: `/floorPlan/list`,
  add: `/floorPlan/add`,
  details: function () {
    return `/floorPlan/details`;
  },
};

export const hardwareRoutes: RoutesType = {
  list: `/hardware/list`,
  add: `/hardware/add`,
  details: () => {
    return `/hardware/details`;
  },
};

export const webViewRoutes = {
  floorPlanDetails: () => {
    return `/webview/floorplan/details/:token/:floorPlanID/:isEditable`;
  },
  floorPlanEngineer: () => {
    return `/webview/floorplan/engineer/:token/:floorPlanID/:isEditable`;
  },
  floorPlanAssignTo: () => {
    return `/webview/floorplan/assignto/:token/:floorPlanID/:isEditable`;
  },
  floorPlanAdmin: () => {
    return `/webview/floorplan/admin/:token/:floorPlanID/:isEditable`;
  },
};
