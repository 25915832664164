import { RouteComponentProps } from 'react-router';
import update from 'immutability-helper';

import {
  MapDetailsType,
  ReducerHookActionType,
} from '../../../../type-definitions';
import { FloorPlanLocationListType } from '../../../../type-definitions/api-types';
import { reducerHookActions } from '../../../../utils/constants';

export interface PropsType
  extends RouteComponentProps<{
    token: string;
    floorPlanID: string;
    isEditable?: string;
  }> {}

export interface StateType {
  loading: boolean;
  mapDetails: MapDetailsType;
  floorPlanLocationList: FloorPlanLocationListType[];
  selectedLocationDetails: Partial<FloorPlanLocationListType>;
  isFloorPlanFetched: boolean;

  token?: string;
  floorPlanID?: string;
  isEditable: boolean;

  assetID?: string;
  userLat?: number;
  userLng?: number;
  selectedLocationIDFromParam?: string;
  createLocation?: boolean;
}

export const actionTypes = {
  ...reducerHookActions,
  setFloorPlansAndAsset: 'setFloorPlansAndAsset',
  setMapDetails: 'setMapDetails',
};

export const reducer = (state: StateType, action: ReducerHookActionType) => {
  switch (action.type) {
    case actionTypes.setState: {
      const newState = update(state, {
        $merge: { ...action.payload },
      });
      return { ...newState };
    }

    case actionTypes.setFloorPlansAndAsset: {
      const floorPlans = action.payload?.floorPlans;
      const assetImage = action.payload?.assetImage;

      const newState = update(state, {
        floorPlanLocationList: { $set: floorPlans ?? [] },
        mapDetails: {
          image: { $set: assetImage ?? '' },
        },
        isFloorPlanFetched: { $set: true },
      });
      return { ...newState };
    }

    case actionTypes.setMapDetails: {
      const newState = update(state, {
        mapDetails: {
          $merge: { ...action.payload },
        },
      });
      return { ...newState };
    }

    default:
      return { ...state };
  }
};
