import axios from 'axios';
import { Component, FormEvent, Fragment } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, RouteComponentProps, Link } from 'react-router-dom';
import { FaCog, FaTrashAlt } from 'react-icons/fa';
import { v4 } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { Modal } from 'antd';

import { apiCall } from '../api-services/api';
import { userApi } from '../api-services/api-list';
import { StateType } from '../components/Users/helpers';
import { saveFilterUser, savePagination, updateToken } from '../redux/actions';
import { AntdTableColumnsType, ReduxStateType } from '../type-definitions';
import {
  ApiErrorType,
  ApiResultType,
  UserDataType,
  UserListType,
} from '../type-definitions/api-types';
import { getKeysFromEnum, handleSorting, handleTableSearch } from '../utils';
import { userRights } from '../utils/permission-list';
import { userRoutes } from '../Routes/routes-list';
import NotificationHandler from '../components/NotificationHandler';
import TableWrapper, {
  AddButton,
  PaginationDropdown,
} from '../components/TableWrapper';
import FilterInput from '../components/FilterInput';
import { UserListEnumKeys } from '../api-services/api-responses';

interface PropsType extends RouteComponentProps, PropsFromRedux {
  userData: Partial<UserDataType>;
  userPermissionList: string[];
}

const tableColumnsKeys = getKeysFromEnum(UserListEnumKeys);

class Users extends Component<PropsType, StateType> {
  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();
  constructor(props: PropsType) {
    super(props);
    this.state = {
      dataList: [],
      initDataList: [],
      filteredList: [],
      perPage: 10,
      loading: true,
      error: {},
      success: {},
      pageName: 'users',
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { savePagination } = this.props;
    const { pageName } = this.state;
    savePagination({ paginationKey: pageName });

    this.handleFetchedData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  fetchData = async () => {
    const { userData, updateToken } = this.props;
    try {
      const { url, method, contentType } = userApi.getUsers();
      const response = await apiCall({
        storeToken: userData.token,
        url,
        method,
        contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      result && updateToken(result);
      if (result.status === 'ok') {
        let resultData = [];
        if (result.data) {
          if (Array.isArray(result.data)) {
            resultData = result.data;
          } else {
            for (const key in result.data) {
              resultData.push(result.data[key]);
            }
          }
        }
        const data: UserListType[] = resultData.map((item: UserListType) => ({
          ...item,
          uuid: v4(),
        }));

        return { userList: data };
      } else {
        return { userList: [], error: result };
      }
    } catch (error) {
      return { userList: [], error };
    }
  };

  handleFetchedData = async () => {
    const {
      userList,
      error,
    }: {
      userList?: UserListType[];
      error?: ApiErrorType;
    } = await this.fetchData();
    if (error) {
      this._isMounted && this.setState({ error, loading: false });
    } else {
      const { searchInput, filterByPartnerObj, saveFilterUser } = this.props;
      const stateData: Partial<StateType> = {};
      if (userList.length > 0) {
        const tempUserList = cloneDeep(userList);

        stateData.initDataList = tempUserList;
        stateData.dataList = tempUserList;

        // ## -- partner list and filtering --
        let tempPartner: string[] = [];
        tempUserList.length > 0 &&
          tempUserList.forEach((item) => {
            if (item.partnerid) {
              tempPartner.push(item.partnerid);
            }
          });
        tempPartner = [...new Set(tempPartner)];

        const partnerData: { [k: string]: boolean } = {};
        const filterByPartnerKeys =
          filterByPartnerObj && Object.keys(filterByPartnerObj);
        tempPartner.forEach((item) => {
          if (filterByPartnerKeys.length > 0) {
            if (filterByPartnerObj[item]) {
              partnerData[item] = true;
            } else {
              partnerData[item] = false;
            }
          } else {
            partnerData[item] = false;
          }
        });
        // ## -- partner list and filtering -- end

        // ## saving the partner data in redux store
        saveFilterUser({ filterByPartnerObj: partnerData });

        // ## if filtering needed on route change
        const hasFilter =
          filterByPartnerKeys.length > 0 &&
          filterByPartnerKeys.some((item) => filterByPartnerObj[item] === true);

        if (hasFilter) {
          const temp = cloneDeep(stateData.dataList);
          const filteredData = temp.filter((item) => {
            if (partnerData[item.partnerid]) {
              return item;
            }
            return null;
          });
          stateData.dataList = filteredData;
          stateData.filteredList = filteredData;
        }
        // ## -- end -- if filtering needed on route change

        // ## search from data
        if (searchInput) {
          let searchList = stateData.dataList;
          if (
            Array.isArray(stateData.filteredList) &&
            stateData.filteredList.length > 0
          ) {
            searchList = stateData.filteredList;
          }
          const sortedData = handleTableSearch({
            data: searchList,
            columnList: tableColumnsKeys,
            searchData: searchInput,
          });
          stateData.dataList = sortedData;
        }
        // ## -- end -- search from data

        this._isMounted &&
          this.setState({
            loading: false,
            initDataList: stateData.initDataList || [],
            dataList: stateData.dataList || [],
            filteredList: stateData.filteredList || [],
          });
      } else {
        this.setState({ loading: false });
      }
    }
  };

  handleSearch = (event: FormEvent<HTMLInputElement>) => {
    const { saveFilterUser, savePagination } = this.props;
    const { initDataList, pageName, filteredList } = this.state;
    const targetValue = event.currentTarget.value;

    savePagination({ paginationKey: pageName, paginationValue: 1 });
    saveFilterUser({ searchInput: targetValue });

    const tempList = filteredList.length > 0 ? filteredList : initDataList;

    const stateData = {
      dataList: tempList,
    };

    if (targetValue) {
      const tempData = cloneDeep(tempList);
      const sortedData = handleTableSearch({
        data: tempData,
        columnList: tableColumnsKeys,
        searchData: targetValue,
      });
      stateData.dataList = sortedData;
    }

    this._isMounted &&
      this.setState({
        ...stateData,
      });
  };

  handlePaginationOption = (value: string) => {
    const { savePagination } = this.props;
    const { pageName } = this.state;
    this._isMounted &&
      this.setState({
        perPage: parseInt(value),
      });

    savePagination({ paginationKey: pageName, paginationValue: 1 });
  };

  onPaginationChange = (page: number, pageSize?: number) => {
    // console.log('params', page, pageSize);
    const { savePagination } = this.props;
    const { pageName } = this.state;
    savePagination({ paginationKey: pageName, paginationValue: page });
  };

  onDeleteClick = (userID: string) => {
    const { userData } = this.props;

    const onDeleteConfirm = async () => {
      if (userID) {
        const stateData = {
          success: {},
          error: {},
        };
        try {
          this.setState({ loading: true });
          const { url, method, contentType } = userApi.deleteUser(null, {
            userID,
          });
          const response = await apiCall({
            storeToken: userData.token,
            url,
            method,
            contentType,
            cancelToken: this.axiosCancelSource.token,
          });
          const result: ApiResultType = response?.data;

          if (result?.status === 'ok') {
            stateData.success = result;
          } else {
            stateData.error = result;
          }

          this.handleFetchedData();
        } catch (error: any) {
          stateData.error = error?.data;
        }
        this.setState({ ...stateData, loading: false });
      }
    };

    Modal.confirm({
      title: 'Are you sure?',
      icon: <AiOutlineExclamationCircle />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        onDeleteConfirm();
      },
      onCancel: () => {},
    });
  };

  handleCheckboxFilter = (checkBoxData: { [k: string]: boolean }) => {
    const { savePagination, saveFilterUser } = this.props;
    const stateData: {
      dataList?: UserListType[];
      filteredList?: UserListType[];
    } = {};
    if (checkBoxData) {
      const { pageName, initDataList } = this.state;
      savePagination({ paginationKey: pageName, paginationValue: 1 });
      saveFilterUser({ filterByPartnerObj: checkBoxData });

      const temp = cloneDeep(initDataList);
      const filteredData = temp?.filter((item) => {
        if (item.partnerid && checkBoxData[item.partnerid]) {
          return item;
        }
        return null;
      });

      stateData.dataList = filteredData;
      stateData.filteredList = filteredData;
    }

    this._isMounted &&
      this.setState({
        dataList: stateData.dataList || [],
        filteredList: stateData.filteredList || [],
      });
  };

  onRowClickRedirect = (record: UserListType) => {
    const { history } = this.props;
    const { rights, uid } = record;
    const allowEdit = rights?.includes(userRights.edit) ?? false;
    history.push({
      pathname: userRoutes.details(),
      state: {
        userID: uid,
        allowEdit,
      },
    });
  };

  render() {
    const {
      searchInput,
      filterByPartnerObj,
      userPermissionList,
      paginationDetails,
      history,
    } = this.props;
    const { perPage, dataList, loading, pageName, success, error } = this.state;

    if (userPermissionList.length === 0) {
      return <Redirect to="/" />;
    }

    let currentPage: number | undefined = 1;
    if (paginationDetails && pageName) {
      currentPage = paginationDetails['users'];
    }

    const paginationConfig = {
      current: currentPage,
      pageSize: perPage,
      total: (dataList && dataList.length) ?? 0,
      onChange: this.onPaginationChange,
    };

    const showAdd = userPermissionList?.includes(userRights.create) ?? false;

    const columns = getColumns(this.onDeleteClick);

    return (
      <Fragment>
        <NotificationHandler success={success} error={error} obj={this} />

        <TableWrapper
          tableDetails={{
            dataSource: dataList,
            columns,
            pagination: paginationConfig,
            customConfig: {
              rowKeyValue: 'uuid',
              onRowClick: this.onRowClickRedirect,
            },
          }}
          loading={loading}
          pageTitle="Users"
          inputComponents={() => (
            <Fragment>
              <div className="col-lg-2 col-md-2">
                <PaginationDropdown
                  onSelectChange={this.handlePaginationOption}
                />
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 ml-auto">
                <FilterInput
                  handleSearchFilter={this.handleSearch}
                  loading={loading}
                  searchValue={searchInput}
                  listData={filterByPartnerObj}
                  handleCheckboxFilter={this.handleCheckboxFilter}
                />
              </div>

              {showAdd && (
                <div className="col-xl-1 col-lg-2 col-md-3 col-sm-6">
                  <AddButton
                    onButtonClick={() => history.push(userRoutes.add)}
                  />
                </div>
              )}
            </Fragment>
          )}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state: ReduxStateType) {
  return {
    searchInput: state.user.searchInput,
    filterByPartnerObj: state.user.filterByPartnerObj,
    paginationDetails: state.shared.paginationDetails,
  };
}

const mapDispatch = {
  saveFilterUser,
  savePagination,
  updateToken,
};

const connector = connect(mapStateToProps, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Users);

function getColumns(
  onDeleteClick: (id: string) => void
): AntdTableColumnsType<UserListType>[] {
  return [
    {
      title: 'Username',
      key: 'username',
      dataIndex: 'username',
      sorter: (a: UserListType, b: UserListType) =>
        handleSorting(a.username, b.username),
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      sorter: (a: UserListType, b: UserListType) =>
        handleSorting(a.level, b.level),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Partner',
      dataIndex: 'partnerid',
      key: 'partnerid',
      sorter: (a: UserListType, b: UserListType) =>
        handleSorting(a.partnerid, b.partnerid),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record: UserListType) => {
        const { rights } = record;
        const allowEdit = rights?.includes(userRights.edit) ?? false;
        const allowRemove = rights?.includes(userRights.remove) ?? false;
        return (
          <Fragment>
            <div className="row">
              {allowEdit && (
                <div className="col-2">
                  <Link
                    to={{
                      pathname: `/user/${record.uid}`,
                      state: {
                        userID: record.uid,
                        allowEdit,
                      },
                    }}>
                    <FaCog size="1.2em" />
                  </Link>
                </div>
              )}

              {allowRemove && (
                <div className="col-2">
                  <FaTrashAlt
                    size="1.2em"
                    style={{ cursor: 'pointer', color: 'red' }}
                    onClick={(event) => {
                      event.stopPropagation();
                      onDeleteClick(record.uid);
                    }}
                  />
                </div>
              )}
            </div>
          </Fragment>
        );
      },
    },
  ];
}
