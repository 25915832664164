export const ERRORED = 'shared/errored';
export const SET_USER_RIGHTS_REQUESTED = 'shared/set_user_rights_requested';
export const SET_USER_RIGHTS = 'shared/set_user_rights';
export const UPDATE_TOKEN_REQUESTED = 'shared/update_token_requested';
export const UPDATE_TOKEN = 'shared/update_token';
export const CLEAR_PAGINATION_START = 'shared/clear_pagination_start';
export const CLEAR_PAGINATION_DONE = 'shared/clear_pagination_done';
export const SAVE_PAGINATION = 'shared/save_pagination';
export const SAVE_NAVBAR_DETAILS = 'shared/save_navbar_details';
export const SHARED_SUCCESS = 'shared/success';
