import { Button, Col, Row } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { Fragment } from 'react';
import {
  getMultipleInputElements,
  getSingleInputElement,
} from '../../../utils/get-input-element';

import { DetailsTabFormElementsType } from '../helpers';

type PropsType = {
  formElements: DetailsTabFormElementsType;
  inputChangedHandler: (
    name: keyof DetailsTabFormElementsType,
    value: string
  ) => void;
  handleCheckbox: (e: CheckboxChangeEvent) => void;
  allowEdit: boolean;
  onOpenMapModal: () => void;
  handleCancelRedirect: () => void;
  loadingButton: boolean;
};

function DetailsTab({
  formElements,
  inputChangedHandler,
  handleCheckbox,
  allowEdit,
  onOpenMapModal,
  handleCancelRedirect,
  loadingButton,
}: PropsType) {
  const { childrenAllowed, ...rest } = formElements;

  const single = getSingleInputElement({
    formElements: { ...rest },
    inputChangedHandler,
    sliceValue: [0, 2],
  });

  const multiple = getMultipleInputElements({
    formElements: { ...rest },
    inputChangedHandler,
    sliceValue: [2, 4],
    optionalElement: (
      <Col lg={8} className="text-center pt-lg-2 pb-2 pb-lg-0">
        <Button
          htmlType="button"
          type="primary"
          onClick={onOpenMapModal}
          disabled={!allowEdit}>
          MAP
        </Button>
      </Col>
    ),
  });

  const single2 = getSingleInputElement({
    formElements: { ...rest },
    inputChangedHandler,
    sliceValue: [4, 5],
  });

  return (
    <Fragment>
      {single}
      {multiple}
      {single2}
      <Row>
        <Col>
          <Checkbox
            disabled={!allowEdit}
            checked={childrenAllowed}
            name="childrenAllowed"
            onChange={handleCheckbox}>
            Children Allowed
          </Checkbox>
        </Col>
      </Row>

      <Row justify="center" align="middle" className="py-3">
        <Col xs={24} sm={12} className="pr-sm-2">
          <Button
            loading={loadingButton}
            type="primary"
            htmlType="submit"
            size="large"
            block
            disabled={!allowEdit}>
            Submit
          </Button>
        </Col>
        <Col xs={24} sm={12} className="pt-2 pt-sm-0 pl-sm-2">
          <Button
            loading={loadingButton}
            type="primary"
            htmlType="button"
            size="large"
            block
            onClick={handleCancelRedirect}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
}

export default DetailsTab;
