import update from 'immutability-helper';

import {
  FormInputObjectType,
  FormInputType,
  ReducerHookActionType,
} from '../../../type-definitions';

import {
  StockLocationsType,
  ApiErrorType,
  ApiSuccessType,
  AssignableLocationsType,
  DeviceDetailsType,
  CalibrationType,
  JournalListType,
} from '../../../type-definitions/api-types';
import { reducerHookActions } from '../../../utils/constants';

export const detailsTabElements: DetailsTabElementsType = {
  // 0
  deviceMac: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Device MAC',
      name: 'deviceMac',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Device MAC is required',
    label: 'Device MAC',
    disabled: true,
    colValue: 6,
  },
  // 1
  serialNumber: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Serial Number',
      name: 'serialNumber',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Serial Number is required',
    label: 'Serial Number',
    disabled: true,
    colValue: 6,
  },
  // 2
  partnerName: {
    elementType: 'select',
    elementConfig: {
      // type: 'text',
      // placeholder: 'Partner ID',
      name: 'partnerName',
    },
    value: '',
    optionValues: [],
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Partner Name is required',
    label: 'Partner Name',
    disabled: true,
  },
  // 3
  manufacturer: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Manufacturer',
      name: 'manufacturer',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Manufacturer is required',
    label: 'Manufacturer',
    disabled: true,
  },
  // 4
  model: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Model',
      name: 'model',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Model is required',
    label: 'Model',
    disabled: true,
  },
  // 5
  sensors: {
    elementType: 'textarea',
    elementConfig: {
      // type: 'text',
      placeholder: 'Sensors',
      name: 'sensors',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Sensors is required',
    label: 'Sensors',
    disabled: true,
  },
};

export const locationTabElements: LocationTabElementsType = {
  // 6
  locationID: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Location ID',
      name: 'locationID',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Location ID is required',
    label: 'Location ID',
    disabled: true,
  },
  // 7
  locationName: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Location Name',
      name: 'locationName',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Location Name is required',
    label: 'Location Name',
    disabled: true,
  },
  // 8
  locationStatus: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Location Status',
      name: 'locationStatus',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Location Status is required',
    label: 'Location Status',
    disabled: true,
  },
};

export const calibrationTabElements: CalibrationTabElementsType = {
  phenomenon: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Phenomenon',
      name: 'phenomenon',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Phenomenon is required',
    label: 'Phenomenon',
  },
  modelName: {
    elementType: 'select',
    elementConfig: {
      name: 'modelName',
    },
    value: 'linearslopeandoffset',
    optionValues: [
      { text: 'linearslopeandoffset', value: 'linearslopeandoffset' },
    ],
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Model Name is required',
    label: 'Model Name',
  },
  offset: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Offset',
      name: 'offset',
    },
    value: '',
    validation: {
      required: true,
      isFloat: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Offset is required',
    label: 'Offset',
  },
  slope: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Slope',
      name: 'slope',
    },
    value: '',
    validation: {
      required: true,
      isFloat: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Slope is required',
    label: 'Slope',
  },
  uuid: '',
};

export const calibrationTabSingleElements: CalibrationTabSingleElementsType = {
  calibrationDate: {
    elementType: 'date',
    elementConfig: {
      type: 'text',
      placeholder: 'Calibration Date',
      name: 'calibrationDate',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Calibration Date is required',
    label: 'Calibration Date',
    colValue: 12,
  },
  calibrationTime: {
    elementType: 'time',
    elementConfig: {
      type: 'text',
      placeholder: 'Calibration Time',
      name: 'calibrationTime',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Calibration Time is required',
    label: 'Calibration Time',
    colValue: 12,
  },
};

export const journalTabElements: JournalTabElementsType = {
  journalNote: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Note',
      name: 'journalNote',
    },
    value: '',
    validation: {
      // required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Note is required',
    label: 'Note',
  },
};

export type CalibrationTabSingleElementsType = {
  calibrationDate: FormInputType;
  calibrationTime: FormInputType;
};

export interface DetailsTabElementsType {
  deviceMac: FormInputType;
  serialNumber: FormInputType;
  partnerName: FormInputType;
  manufacturer: FormInputType;
  model: FormInputType;
  sensors: FormInputType;
}

export interface LocationTabElementsType {
  locationID: FormInputType;
  locationName: FormInputType;
  locationStatus: FormInputType;
}

export interface JournalTabElementsType {
  journalNote: FormInputType;
}

export type FormElementsType = DetailsTabElementsType &
  LocationTabElementsType &
  JournalTabElementsType;

export type StateType = {
  formElements: FormElementsType;
  formElementsList: FormInputObjectType;
  showStockModal: boolean;
  showLocationModal: boolean;
  loading: boolean;
  showMoveDeviceModal: boolean;
  previousDetails: Partial<DeviceDetailsType>;
  showSwapDeviceModal: boolean;
  currentTabIndex: string;
  calibrationValues: CalibrationType[];
  calibrationTabFormElements: CalibrationTabSingleElementsType;
  hasInputChanged: boolean;

  allowEdit: boolean;
  deviceID: string;
};

export type AllocateToStockStateType = {
  dataList: StockLocationsType[];
  perPage: number;
  showModalConfirm: boolean;
  loading: boolean;
  success: ApiSuccessType;
  error: ApiErrorType;
};

export type AssignToLocationStateType = {
  dataList: AssignableLocationsType[];
  initDataList: AssignableLocationsType[];
  perPage: number;
  showModalConfirm: boolean;
  searchInput: string;
  loading: boolean;
  success: ApiSuccessType;
  error: ApiErrorType;
};

export type CalibrationTabElementsType = {
  phenomenon: FormInputType;
  modelName: FormInputType;
  offset: FormInputType;
  slope: FormInputType;
  uuid: string;
};

export const initJournalState: JournalTabStateType = {
  journalList: [],
  initJournalList: [],
  searchValue: '',
  locationIDList: [],
  entryTypeList: [],
  filterEntryTypeValue: '',
  filterLocationIDValue: '',
  tablePagination: {
    totalCount: 0,
    perPage: 10,
    currentPage: 1,
    lastPagination: 1,
  },
  showNoteModal: false,
  selectedJournalTS: '',
};

export interface JournalTabStateType {
  journalList: JournalListType[];
  initJournalList: JournalListType[];
  searchValue: string;
  locationIDList: string[];
  entryTypeList: string[];
  filterLocationIDValue: string;
  filterEntryTypeValue: string;
  tablePagination: {
    totalCount: number;
    perPage: number;
    currentPage: number;
    lastPagination: number;
  };
  showNoteModal: boolean;
  selectedJournalTS: string;
}

export const actionTypes = {
  ...reducerHookActions,
  setInitJournals: 'setInitJournals',
  setJournals: 'setJournals',
  setPagination: 'setPagination',
  resetPagination: 'resetPagination',
  setSearchValue: 'setSearchValue',
  setSelectValue: 'setSelectValue',
  clearSelectValue: 'clearSelectValue',
};

export const reducer = (
  state: JournalTabStateType,
  action: ReducerHookActionType
) => {
  switch (action.type) {
    case actionTypes.setState: {
      const newState = update(state, {
        $merge: { ...action.payload },
      });
      return { ...newState };
    }

    case actionTypes.setInitJournals: {
      const journalList = action.payload?.journalList;
      const tablePagination = action.payload?.tablePagination;
      const locationIDList = action.payload?.locationIDList;
      const entryTypeList = action.payload?.entryTypeList;

      const newState = update(state, {
        journalList: {
          $set: [...journalList],
        },
        initJournalList: {
          $set: [...journalList],
        },
        locationIDList: {
          $set: locationIDList || state.locationIDList,
        },
        entryTypeList: {
          $set: entryTypeList || state.entryTypeList,
        },
        tablePagination: {
          $merge: { ...tablePagination },
        },
      });

      return { ...newState };
    }

    case actionTypes.setJournals: {
      const journalList = action.payload?.journalList;
      const tablePagination = action.payload?.tablePagination;
      const locationIDList = action.payload?.locationIDList;
      const entryTypeList = action.payload?.entryTypeList;

      console.log('payload', action.payload);

      const newState = update(state, {
        journalList: {
          $push: [...journalList],
        },
        initJournalList: {
          $push: [...journalList],
        },
        locationIDList: {
          $set: locationIDList || state.locationIDList,
        },
        entryTypeList: {
          $set: entryTypeList || state.entryTypeList,
        },
        tablePagination: {
          $merge: { ...tablePagination },
        },
      });

      return { ...newState };
    }

    case actionTypes.setPagination: {
      const newState = update(state, {
        tablePagination: {
          $merge: { ...action.payload },
        },
      });
      return { ...newState };
    }

    case actionTypes.setSearchValue: {
      const searchValue = action.payload?.searchValue;
      const journalList = action.payload?.journalList;
      const tablePagination = action.payload?.tablePagination;

      const newState = update(state, {
        searchValue: { $set: searchValue },
        journalList: { $set: journalList },
        tablePagination: {
          $merge: { ...tablePagination },
        },
      });

      return { ...newState };
    }

    case actionTypes.resetPagination: {
      const newState = update(state, {
        journalList: {
          $set: state.journalList.splice(0, action.payload?.currentPage * 10),
        },
        initJournalList: {
          $set: state.initJournalList.splice(
            0,
            action.payload?.currentPage * 10
          ),
        },
        tablePagination: {
          $merge: { ...action.payload },
        },
      });
      return { ...newState };
    }

    case actionTypes.setSelectValue: {
      const name: 'filterLocationIDValue' | 'filterEntryTypeValue' =
        action.payload?.name;
      const value = action.payload?.value;

      const newState = update(state, {
        filterEntryTypeValue: {
          $set: name === 'filterLocationIDValue' ? '' : value,
        },
        filterLocationIDValue: {
          $set: name === 'filterEntryTypeValue' ? '' : value,
        },
        journalList: { $set: [] },
        initJournalList: { $set: [] },
      });
      return { ...newState };
    }

    case actionTypes.clearSelectValue: {
      const name: 'filterLocationIDValue' | 'filterEntryTypeValue' =
        action.payload?.name;

      const newState = update(state, {
        [name]: {
          $set: '',
        },
        journalList: { $set: [] },
        initJournalList: { $set: [] },
      });
      return { ...newState };
    }

    default:
      return { ...state };
  }
};
