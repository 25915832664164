import { Fragment, MouseEvent } from 'react';
import { Table } from 'antd';
import { TablePaginationConfig, TableProps } from 'antd/lib/table';
import {
  Key,
  SorterResult,
  TableCurrentDataSource,
} from 'antd/lib/table/interface';

export interface AntdTablePropsType extends TableProps<any> {
  customConfig?: {
    rowKeyValue?: string;
    onRowClick?: (record: any) => void;
    onTableChange?: (
      pagination: TablePaginationConfig,
      filters: Record<string, (Key | boolean)[] | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
      extra: TableCurrentDataSource<any>
    ) => void;
  };
}

const AntdTable = ({
  loading = false,
  dataSource,
  className = `table-responsive`,
  bordered = true,
  pagination,
  customConfig,
  rowClassName,
  ...restProps
}: AntdTablePropsType) => {
  const tableProps: any = {
    loading: { size: 'large', spinning: loading },
  };
  if (customConfig?.onRowClick) {
    tableProps.rowClassName = `antdTableRow ${rowClassName || ''}`;

    tableProps.onRow = (record: object, rowIndex?: number) => {
      return {
        onClick: (event: MouseEvent<HTMLButtonElement>) =>
          customConfig?.onRowClick?.(record), // click row
        onDoubleClick: (event: MouseEvent<HTMLButtonElement>) => {}, // double click row
        onContextMenu: (event: MouseEvent<HTMLButtonElement>) => {}, // right button click row
        onMouseEnter: (event: MouseEvent<HTMLButtonElement>) => {}, // mouse enter row
        onMouseLeave: (event: MouseEvent<HTMLButtonElement>) => {}, // mouse leave row
      };
    };
  }

  if (className) {
    className = `table-responsive ${className}`;
  }

  if (dataSource && dataSource.length === 0) {
    tableProps.style = { ...tableProps.style, overflow: 'hidden' };
  }

  if (pagination) {
    pagination = {
      ...pagination,
      showSizeChanger: pagination.showSizeChanger ?? false,
    };
  }

  return (
    <Fragment>
      <Table
        {...tableProps}
        {...restProps}
        dataSource={dataSource}
        bordered={bordered}
        className={className}
        pagination={pagination}
        rowKey={(record) =>
          customConfig?.rowKeyValue && record[customConfig.rowKeyValue]
        }
        onChange={customConfig?.onTableChange}
        // scroll={{ x: '100%' }}
        // scrollToFirstRowOnChange={true}
      />
    </Fragment>
  );
};

export default AntdTable;
