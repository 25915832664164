import { Fragment } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import PasswordChange from '../components/PasswordChange';
import { deviceRoutes, authRoutes } from '../Routes/routes-list';
import { UserDataType } from '../type-definitions/api-types';

type ChangePasswordType = {
  status: string;
  data: null;
  token: string;
  message: string;
};

interface PropsType
  extends RouteComponentProps<
    {},
    any,
    {
      userName?: string;
      currentPwd?: string;
      isChecked?: boolean;
      apiResponse?: ChangePasswordType;
    }
  > {
  isAuth?: boolean;
  userData: Partial<UserDataType>;
  userPermissionList: string[];
}

function ChangePassword({
  isAuth,
  location,
  userData,
  userPermissionList,
}: PropsType) {
  const apiResponse = location?.state?.apiResponse;
  if (!isAuth && apiResponse?.status !== 'changePassword') {
    return <Redirect to={authRoutes.login} />;
  } else if (isAuth) {
    return <Redirect to={deviceRoutes.list} />;
  }

  const userName = location?.state?.userName;
  const currentPwd = location?.state?.currentPwd;
  const isChecked = location?.state?.isChecked;

  return (
    <Fragment>
      <PasswordChange
        isUserChangePass={false}
        userPermissionList={userPermissionList}
        isAuth={isAuth}
        userName={userName}
        currentPwd={currentPwd}
        isChecked={isChecked}
      />
    </Fragment>
  );
}
export default ChangePassword;
