import { Fragment } from 'react';
import { Layout, Menu } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { IoMdArrowDropdown } from 'react-icons/io';
import { NavbarLinksType } from '../../../type-definitions';
import { UserDataType } from '../../../type-definitions/api-types';
import { useDispatch } from 'react-redux';
import { clearPagination } from '../../../redux/actions';

const { Header } = Layout;
const { SubMenu } = Menu;

type PropsType = {
  navbarLinks: NavbarLinksType[];
  userPermissionList: string[] | undefined;
  isAuth: boolean;
  userData?: Partial<UserDataType>;
  handleLogout: () => void;
};

function DefaultNav({
  navbarLinks,
  userPermissionList,
  isAuth,
  userData,
  handleLogout,
}: PropsType) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const currentPath = location?.pathname;

  function handleRedirect(path: string) {
    if (currentPath) {
      const paginationKey = currentPath?.split('/')[1];
      dispatch(clearPagination({ paginationKey }));
    }
    history.push(path);
  }

  return (
    <Fragment>
      <Header style={{ padding: '0' }}>
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['0']}
          selectedKeys={[currentPath]}
          className="d-flex">
          {navbarLinks &&
            navbarLinks.map((item) => {
              let hasPermission: boolean | string = false;
              if (item.permissions) {
                if (
                  Array.isArray(userPermissionList) &&
                  userPermissionList.length > 0 &&
                  item.permissions.some((el) => userPermissionList.includes(el))
                ) {
                  hasPermission = true;
                } else {
                  hasPermission = false;
                }
              } else {
                hasPermission = 'default';
              }

              const hasSubmenu = item.subMenuItems;

              if (
                (item.auth === isAuth || item.auth === 'public') &&
                (hasPermission === 'default' || hasPermission === true)
              ) {
                if (!hasSubmenu) {
                  return (
                    <Menu.Item
                      key={item.path}
                      onClick={() => handleRedirect(item.path)}>
                      <Link to={item.path}>{item.text}</Link>
                    </Menu.Item>
                  );
                }
                return (
                  <SubMenu
                    className="antdSubmenu"
                    key="SubMenu"
                    icon={<IoMdArrowDropdown size="1.5em" />}
                    title={userData?.username || <span>&nbsp;</span>}>
                    {item?.subMenuItems?.map((elem) => {
                      let hasSubMenuPermission: boolean | string = false;
                      if (elem.permissions) {
                        if (
                          Array.isArray(userPermissionList) &&
                          userPermissionList.length > 0 &&
                          elem.permissions.some((el) =>
                            userPermissionList.includes(el)
                          )
                        ) {
                          hasSubMenuPermission = true;
                        } else {
                          hasSubMenuPermission = false;
                        }
                      } else {
                        hasSubMenuPermission = 'default';
                      }

                      if (
                        (elem.auth === isAuth || elem.auth === 'public') &&
                        (hasSubMenuPermission === 'default' ||
                          hasSubMenuPermission === true)
                      ) {
                        return (
                          <Menu.Item
                            selectedKeys={
                              currentPath === elem.path ? [currentPath] : ['0']
                            }
                            // key={`submenu-${idx}`}
                            key={elem.path}
                            onClick={() => handleRedirect(elem.path)}>
                            <Link to={elem.path}>{elem.text}</Link>
                          </Menu.Item>
                        );
                      }
                      return null;
                    })}
                  </SubMenu>
                );
              }
              return null;
            })}

          {isAuth && (
            <Menu.Item
              className="ml-auto pr-4"
              key={`submenu-logout`}
              onClick={handleLogout}>
              <Link to="#" onClick={handleLogout}>
                Logout
              </Link>
            </Menu.Item>
          )}
        </Menu>
      </Header>
    </Fragment>
  );
}

export default DefaultNav;
