import {
  BooleanObjectType,
  FormInputObjectType,
  FormInputType,
} from '../../../type-definitions';

import {
  ApiErrorType,
  ApiSuccessType,
  HardwareAttributeType,
  SensorListType,
  StockLocationsType,
} from '../../../type-definitions/api-types';

export const detailsTabElements: DetailsTabElementsType = {
  // 0
  deviceMac: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Device MAC',
      name: 'deviceMac',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Device MAC is required',
    label: 'Device MAC *',
  },
  // 1
  serialNumber: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Serial Number',
      name: 'serialNumber',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Serial Number is required',
    label: 'Serial Number *',
  },
  // 2
  partnerName: {
    elementType: 'select',
    elementConfig: {
      name: 'partnerName',
    },
    value: '',
    optionValues: [],
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Partner Name is required',
    label: 'Partner Name',
  },
  // 3
  manufacturer: {
    elementType: 'select',
    elementConfig: {
      // type: 'text',
      // placeholder: 'Manufacturer',
      name: 'manufacturer',
    },
    optionValues: [],
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Manufacturer is required',
    label: 'Manufacturer *',
  },
  // 4
  model: {
    elementType: 'select',
    elementConfig: {
      // type: 'text',
      // placeholder: 'Model',
      name: 'model',
    },
    optionValues: [],
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Model is required',
    label: 'Model *',
    disabled: true,
  },
  // 5
  sensors: {
    elementType: 'textarea',
    elementConfig: {
      // type: 'text',
      placeholder: 'Sensors',
      name: 'sensors',
      rows: 3,
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Sensors is required',
    label: 'Sensors',
    disabled: true,
  },
};

export const locationTabElements: LocationTabElementsType = {
  // 6
  locationID: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Location ID',
      name: 'locationID',
      readOnly: true,
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Please assign device to a location',
    label: 'Location ID *',
  },
};

export type DeviceFormDataType = {
  id: string;
  make: string;
  model: string;
  attributes?: HardwareAttributeType[] | [];
  sensorSpecs: Partial<SensorListType[]>;
  calibrationValues: Array<{ name: string; defaultValue: number }>;
  flows: string[];
  partnerID: string;
};

export interface DetailsTabElementsType {
  deviceMac: FormInputType;
  serialNumber: FormInputType;
  partnerName: FormInputType;
  manufacturer: FormInputType;
  model: FormInputType;
  sensors: FormInputType;
}

export interface LocationTabElementsType {
  locationID: FormInputType;
}

export interface NotesTabElementsType {
  notes: FormInputType;
}

export type FormElementsType = DetailsTabElementsType & LocationTabElementsType;

export type StateType = {
  formElements: FormElementsType;
  formElementsList: FormInputObjectType;
  showStockModal: boolean;
  loading: boolean;
  deviceFormMakerData: Array<DeviceFormDataType>;
  selectedManufacturerValue: string;
  currentTabIndex: string;

  hasInputChanged: boolean;
};

export type AllocateToStockStateType = {
  dataList: StockLocationsType[];
  perPage: number;
  showModalConfirm: boolean;
  loading: boolean;
  success: ApiSuccessType;
  error: ApiErrorType;
  searchValue: string;
  filterByPartnerObj: BooleanObjectType;
};
