import { Component, FormEvent, Fragment } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, RouteComponentProps, Link } from 'react-router-dom';
import { FaCog, FaTrashAlt } from 'react-icons/fa';
import { v4 } from 'uuid';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { Col, Modal, Row } from 'antd';
import moment from 'moment';
import rfdc from 'rfdc';
import update from 'immutability-helper';

import { MarkerInfoType, StateType } from '../components/LocationList/helpers';
import { getKeysFromEnum, handleSorting, handleTableSearch } from '../utils';
import { LocationListEnumKeys } from '../api-services/api-responses';
import {
  ApiErrorType,
  ApiResultType,
  UserDataType,
  LocationListType,
} from '../type-definitions/api-types';
import {
  saveFilterLocation,
  savePagination,
  updateToken,
} from '../redux/actions';
import { AntdTableColumnsType, ReduxStateType } from '../type-definitions';
import axios from 'axios';
import { apiCall } from '../api-services/api';
import { locationApi } from '../api-services/api-list';
import { locationRights } from '../utils/permission-list';
import { locationRoutes } from '../Routes/routes-list';
import NotificationHandler from '../components/NotificationHandler';
import { AddButton, PaginationDropdown } from '../components/TableWrapper';
import FilterInput from '../components/FilterInput';
import Wrapper from '../components/LocationList/Wrapper';

import cssStyles from '../components/LocationList/styles/locationList.module.scss';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { removeDuplicatesFromArray } from '../utils/remove-duplicates';
import Avatar from 'antd/lib/avatar/avatar';
import { colorCodes } from '../utils/constants';
import { handleNotification } from '../utils/notification-handler';

const clone = rfdc();

interface PropsType extends RouteComponentProps, PropsFromRedux {
  userData: Partial<UserDataType>;
  userPermissionList: string[];
}

const tableColumnsKeys = getKeysFromEnum(LocationListEnumKeys);
const currentDateTime = moment.utc();
class LocationList extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      dataList: [],
      // initDataList: [],
      perPage: 10,
      loading: true,
      error: {},
      success: {},
      pageName: 'locations',
      isMapFilteringAvailable: false,
      selectedMarkerInfo: {
        moreThan48: true,
        lessThan3: true,
        lessThan48: true,
        offline: true,
        stock: true,
      },

      mapDataList: [],
    };
  }

  filteredDataList: LocationListType[] = [];
  initDataList: LocationListType[] = [];
  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    this._isMounted = true;
    const { savePagination } = this.props;
    const { pageName } = this.state;
    savePagination({ paginationKey: pageName });

    this.handleFetchedData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  fetchData = async () => {
    const { userData, updateToken } = this.props;
    try {
      const { url, method, contentType } = locationApi.getLocations();
      const response = await apiCall({
        storeToken: userData.token,
        url,
        method,
        contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      updateToken(result);
      if (result.status === 'ok') {
        const tempData: LocationListType[] = result?.data?.map(
          (item: LocationListType) => ({
            ...item,
            uuid: v4(),
          })
        );
        return {
          locationList: tempData,
        };
      } else {
        return { error: result };
      }
    } catch (error) {
      return { error };
    }
  };

  handleFetchedData = async () => {
    const {
      error,
      locationList,
    }: {
      error?: ApiErrorType;
      locationList?: LocationListType[];
    } = await this.fetchData();
    if (error) {
      this._isMounted && this.setState({ loading: false, error });
    } else {
      const { searchInput, saveFilterLocation, filterByPartnerObj } =
        this.props;
      const stateData: Partial<StateType> = {};
      if (locationList && locationList.length > 0) {
        const tempList = [...locationList];

        this.initDataList = [...locationList];

        stateData.dataList = tempList;
        // stateData.initDataList = tempList;

        // ## partner list and filtering
        let tempPartner: string[] = [];
        tempList.length > 0 &&
          tempList.forEach((item) => {
            if (item.partnerID) {
              tempPartner = update(tempPartner, { $push: [item.partnerID] });
            }
          });
        tempPartner = [...new Set(tempPartner)];

        const partnerData: { [k: string]: boolean } = {};
        const filterByPartnerKeys =
          filterByPartnerObj && Object.keys(filterByPartnerObj);
        tempPartner.forEach((item) => {
          if (filterByPartnerKeys.length > 0) {
            if (filterByPartnerObj[item]) {
              partnerData[item] = true;
            } else {
              partnerData[item] = false;
            }
          } else {
            partnerData[item] = false;
          }
        });
        // ## -- end -- partner list and filtering

        // ## saving the partner data in redux store
        saveFilterLocation({ filterByPartnerObj: partnerData });

        // ## if filtering needed on route change
        const hasFilter =
          filterByPartnerKeys.length > 0 &&
          filterByPartnerKeys.some((item) => filterByPartnerObj[item] === true);

        if (hasFilter) {
          const temp = [...stateData.dataList];
          const filteredData = temp.filter((item) => {
            if (partnerData[item.partnerID]) {
              return item;
            }
            return null;
          });
          stateData.dataList = filteredData;
        }
        // ## -- end -- if filtering needed on route change

        // ## search from data
        if (searchInput) {
          const sortedData = handleTableSearch({
            data: stateData.dataList,
            columnList: tableColumnsKeys,
            searchData: searchInput,
          });
          stateData.dataList = sortedData;
        }
        // ## -- end -- search from data

        stateData.loading = false;
      } else {
        stateData.loading = false;
      }

      this._isMounted &&
        this.setState({
          loading: false,
          // initDataList: stateData.initDataList || [],
          dataList: stateData.dataList || [],
          mapDataList: stateData.dataList || [],
        });
    }
  };

  handleSearch = (event: FormEvent<HTMLInputElement>) => {
    const { saveFilterLocation, savePagination } = this.props;
    const { pageName, dataList, isMapFilteringAvailable, mapDataList } =
      this.state;
    const targetValue = event.currentTarget.value;

    savePagination({ paginationKey: pageName, paginationValue: 1 });
    saveFilterLocation({ searchInput: targetValue });

    const stateData = {
      dataList,
      mapDataList,
    };

    if (!isMapFilteringAvailable && targetValue) {
      const tempData = [...this.initDataList];
      const sortedData = handleTableSearch({
        data: tempData,
        columnList: tableColumnsKeys,
        searchData: targetValue,
      });
      stateData.dataList = sortedData;
      stateData.mapDataList = sortedData;
    } else if (!isMapFilteringAvailable && !targetValue) {
      stateData.dataList = [...this.initDataList];
      stateData.mapDataList = [...this.initDataList];
    } else if (isMapFilteringAvailable && targetValue) {
      const sortedData = handleTableSearch({
        data: [...this.filteredDataList],
        columnList: tableColumnsKeys,
        searchData: targetValue,
      });
      stateData.dataList = sortedData;

      const mapSortedData = handleTableSearch({
        data: [...this.initDataList],
        columnList: tableColumnsKeys,
        searchData: targetValue,
      });
      stateData.mapDataList = mapSortedData;
    } else if (isMapFilteringAvailable && !targetValue) {
      stateData.dataList = [...this.filteredDataList];
      stateData.mapDataList = [...this.initDataList];
    }

    this._isMounted &&
      this.setState({
        ...stateData,
      });
  };

  handlePaginationOption = (value: string) => {
    const { savePagination } = this.props;
    const { pageName } = this.state;
    this._isMounted &&
      this.setState({
        perPage: parseInt(value),
      });

    savePagination({ paginationKey: pageName, paginationValue: 1 });
  };

  onPaginationChange = (page: number, pageSize?: number) => {
    // console.log('params', page, pageSize);
    const { savePagination } = this.props;
    const { pageName } = this.state;
    savePagination({ paginationKey: pageName, paginationValue: page });
  };

  onDeleteClick = (locationID: string) => {
    const { userData } = this.props;

    const onDeleteConfirm = async () => {
      if (locationID) {
        this.setState({ loading: true });

        try {
          const { url, method, contentType } = locationApi.deleteLocation({
            locationID,
          });
          const response = await apiCall({
            storeToken: userData.token,
            url,
            method,
            contentType,
            cancelToken: this.axiosCancelSource.token,
          });
          const result: ApiResultType = response?.data;
          const stateData = {
            success: {},
            error: {},
          };

          if (result?.status === 'ok') {
            stateData.success = result;
          }

          this.setState({ ...stateData });
          this.handleFetchedData();
        } catch (error: any) {
          this._isMounted && handleNotification('error', error?.data);
          this._isMounted && this.setState({ loading: false });
        }
      }
    };

    Modal.confirm({
      title: 'Are you sure?',
      icon: <AiOutlineExclamationCircle />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        onDeleteConfirm();
      },
      onCancel: () => {},
    });
  };

  handleCheckboxFilter = (checkBoxData: { [k: string]: boolean }) => {
    const { savePagination, saveFilterLocation } = this.props;
    const stateData: {
      dataList?: LocationListType[];
    } = {};
    if (checkBoxData) {
      const { pageName } = this.state;
      savePagination({ paginationKey: pageName, paginationValue: 1 });
      saveFilterLocation({ filterByPartnerObj: checkBoxData });

      const filteredData = this.initDataList.filter((item) => {
        if (item.partnerID && checkBoxData[item.partnerID]) {
          return item;
        }
        return null;
      });

      stateData.dataList = filteredData;
    }

    this._isMounted &&
      this.setState({
        dataList: stateData.dataList || [],
        mapDataList: stateData.dataList ? [...stateData.dataList] : [],
      });
  };

  handleMapFilterAvailability = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked;
    const { dataList, mapDataList } = this.state;
    let tempData = [...dataList];
    let tempMapData = [...mapDataList];
    const tempInitDataList = clone(this.initDataList);
    if (!checked) {
      tempData = [...tempInitDataList];
      tempMapData = [...tempInitDataList];
    }

    this._isMounted &&
      this.setState({
        isMapFilteringAvailable: checked,
        dataList: tempData,
        mapDataList: tempMapData,
      });
  };

  handleMapFilter = (mapList: LocationListType[]) => {
    this.filteredDataList = clone(mapList);
    this._isMounted &&
      this.setState({
        dataList: mapList,
      });
  };

  onMarkerInfoClick = (value: MarkerInfoType) => {
    const {
      selectedMarkerInfo,
      mapDataList,
      dataList,
      isMapFilteringAvailable,
    } = this.state;
    const currentDateTime = moment.utc();
    let tempMapDataList = [...mapDataList];
    let tempDataList = [...dataList];
    const tempSelectedMarkerInfo = { ...selectedMarkerInfo };
    tempSelectedMarkerInfo[value] = !tempSelectedMarkerInfo[value];

    if (value === 'offline' && tempDataList && tempMapDataList) {
      if (tempSelectedMarkerInfo[value] === false) {
        if (isMapFilteringAvailable === true) {
          tempDataList = tempDataList.filter((item) => {
            return item.status !== 'OFFLINE';
          });
        }

        tempMapDataList = tempMapDataList.filter((item) => {
          return item.status !== 'OFFLINE';
        });
      } else {
        this.initDataList.forEach((item) => {
          if (item.status === 'OFFLINE') {
            // tempDataList.push(item);
            // tempMapDataList.push(item);

            if (isMapFilteringAvailable === true) {
              tempDataList = update(tempDataList, { $push: [item] });
              tempDataList = removeDuplicatesFromArray(tempDataList, 'uuid');
            }
            tempMapDataList = update(tempMapDataList, { $push: [item] });
          }
        });
      }
    } else if (value === 'stock' && tempDataList && tempMapDataList) {
      if (tempSelectedMarkerInfo[value] === false) {
        if (isMapFilteringAvailable === true) {
          tempDataList = tempDataList.filter((item) => {
            return item.status !== 'STOCK';
          });
        }
        tempMapDataList = tempMapDataList.filter((item) => {
          return item.status !== 'STOCK';
        });
      } else {
        this.initDataList.forEach((item) => {
          if (item.status === 'STOCK') {
            if (isMapFilteringAvailable === true) {
              tempDataList = update(tempDataList, { $push: [item] });
              tempDataList = removeDuplicatesFromArray(tempDataList, 'uuid');
            }
            tempMapDataList = update(tempMapDataList, { $push: [item] });
          }
        });
      }
    } else if (value === 'moreThan48' && tempDataList && tempMapDataList) {
      if (tempSelectedMarkerInfo[value] === false) {
        if (isMapFilteringAvailable === true) {
          tempDataList = tempDataList.filter((item) => {
            if (!item.lastContact) {
              return null;
            }
            const lastContact = moment.utc(item?.lastContact);
            const timeDiff = currentDateTime.diff(lastContact, 'hours', true);
            if (timeDiff && timeDiff > 48 && item.status !== 'OFFLINE') {
              return null;
            }
            return item;
          });
        }
        tempMapDataList = tempMapDataList.filter((item) => {
          if (!item.lastContact) {
            return null;
          }
          const lastContact = moment.utc(item?.lastContact);
          const timeDiff = currentDateTime.diff(lastContact, 'hours', true);
          if (timeDiff && timeDiff > 48 && item.status !== 'OFFLINE') {
            return null;
          }
          return item;
        });
      } else {
        this.initDataList.forEach((item) => {
          const lastContact = moment.utc(item?.lastContact);
          const timeDiff = currentDateTime.diff(lastContact, 'hours', true);
          if (!item.lastContact) {
            if (isMapFilteringAvailable === true) {
              tempDataList = update(tempDataList, { $push: [item] });
              tempDataList = removeDuplicatesFromArray(tempDataList, 'uuid');
            }
            tempMapDataList = update(tempMapDataList, { $push: [item] });
          } else if (timeDiff && timeDiff > 48 && item.status !== 'OFFLINE') {
            if (isMapFilteringAvailable === true) {
              tempDataList = update(tempDataList, { $push: [item] });
              tempDataList = removeDuplicatesFromArray(tempDataList, 'uuid');
            }
            tempMapDataList = update(tempMapDataList, { $push: [item] });
          }
        });
      }
    } else if (value === 'lessThan48' && tempDataList && tempMapDataList) {
      if (tempSelectedMarkerInfo[value] === false) {
        if (isMapFilteringAvailable === true) {
          tempDataList = tempDataList.filter((item) => {
            const lastContact = moment.utc(item?.lastContact);
            const timeDiff = currentDateTime.diff(lastContact, 'hours', true);
            if (timeDiff && timeDiff < 48 && timeDiff > 3) {
              return null;
            }
            return item;
          });
        }
        tempMapDataList = tempMapDataList.filter((item) => {
          const lastContact = moment.utc(item?.lastContact);
          const timeDiff = currentDateTime.diff(lastContact, 'hours', true);
          if (timeDiff && timeDiff < 48 && timeDiff > 3) {
            return null;
          }
          return item;
        });
      } else {
        this.initDataList.forEach((item) => {
          const lastContact = moment.utc(item?.lastContact);
          const timeDiff = currentDateTime.diff(lastContact, 'hours', true);
          if (timeDiff && timeDiff < 48 && timeDiff > 3) {
            if (isMapFilteringAvailable === true) {
              tempDataList = update(tempDataList, { $push: [item] });
              tempDataList = removeDuplicatesFromArray(tempDataList, 'uuid');
            }
            tempMapDataList = update(tempMapDataList, { $push: [item] });
          }
        });
      }
    } else if (value === 'lessThan3' && tempDataList && tempMapDataList) {
      if (tempSelectedMarkerInfo[value] === false) {
        if (isMapFilteringAvailable === true) {
          tempDataList = tempDataList.filter((item) => {
            const lastContact = moment.utc(item?.lastContact);
            const timeDiff = currentDateTime.diff(lastContact, 'hours', true);
            if (timeDiff && timeDiff < 3) {
              return null;
            }
            return item;
          });
        }
        tempMapDataList = tempMapDataList.filter((item) => {
          const lastContact = moment.utc(item?.lastContact);
          const timeDiff = currentDateTime.diff(lastContact, 'hours', true);
          if (timeDiff && timeDiff < 3) {
            return null;
          }
          return item;
        });
      } else {
        this.initDataList.forEach((item) => {
          const lastContact = moment.utc(item?.lastContact);
          const timeDiff = currentDateTime.diff(lastContact, 'hours', true);
          if (timeDiff && timeDiff < 3) {
            if (isMapFilteringAvailable === true) {
              tempDataList = update(tempDataList, { $push: [item] });
              tempDataList = removeDuplicatesFromArray(tempDataList, 'uuid');
            }
            tempMapDataList = update(tempMapDataList, { $push: [item] });
          }
        });
      }
    }

    this._isMounted &&
      this.setState({
        dataList: tempDataList,
        mapDataList: tempMapDataList,
        selectedMarkerInfo: tempSelectedMarkerInfo,
      });
  };

  onRowClickRedirect = (record: LocationListType) => {
    const { history } = this.props;
    const { rights, locationID } = record;
    history.push({
      pathname: locationRoutes.details(),
      state: {
        locationID,
        allowAllEdit: rights?.includes(locationRights.edit) ?? false,
        allowNoteEdit: rights?.includes(locationRights.addNote) ?? false,
        hasStock: record?.status === 'STOCK',
      },
    });
  };

  render() {
    const {
      searchInput,
      userPermissionList,
      paginationDetails,
      history,
      filterByPartnerObj,
      userData,
    } = this.props;
    const {
      perPage,
      dataList,
      loading,
      pageName,
      success,
      error,
      isMapFilteringAvailable,
      selectedMarkerInfo,
      mapDataList,
    } = this.state;

    if (userPermissionList.length === 0) {
      return <Redirect to="/" />;
    }

    let currentPage: number | undefined = 1;
    if (paginationDetails && pageName) {
      currentPage = paginationDetails['locations'];
    }
    const paginationConfig = {
      current: currentPage,
      pageSize: perPage,
      total: (dataList && dataList.length) ?? 0,
      onChange: this.onPaginationChange,
    };

    const hasCreateRights =
      userPermissionList?.includes(locationRights.create) ?? false;

    const columns = getColumns({
      handleDelete: this.onDeleteClick,
    });

    return (
      <Fragment>
        <NotificationHandler success={success} error={error} obj={this} />
        <Wrapper
          userData={userData}
          handleMapFilter={this.handleMapFilter}
          isMapFilteringAvailable={isMapFilteringAvailable}
          handleMapFilterAvailability={this.handleMapFilterAvailability}
          onMarkerInfoClick={this.onMarkerInfoClick}
          selectedMarkerInfo={selectedMarkerInfo}
          mapDataSource={mapDataList}
          tableDetails={{
            dataSource: dataList,
            columns: columns,
            pagination: paginationConfig,
            customConfig: {
              rowKeyValue: 'uuid',
              onRowClick: this.onRowClickRedirect,
            },
          }}
          loading={loading}
          pageTitle="Locations"
          inputComponents={() => (
            <Fragment>
              <div className="col-lg-2 col-md-2">
                <PaginationDropdown
                  onSelectChange={this.handlePaginationOption}
                />
              </div>
              <div
                className={`col-lg-4 col-md-4 col-sm-6 ml-auto ${cssStyles.filter_input_wrapper}`}>
                <FilterInput
                  handleSearchFilter={this.handleSearch}
                  loading={loading}
                  searchValue={searchInput}
                  listData={filterByPartnerObj}
                  handleCheckboxFilter={this.handleCheckboxFilter}
                />
              </div>

              {hasCreateRights && (
                <div className="col-xl-1 col-lg-2 col-md-3 col-sm-6">
                  <AddButton
                    onButtonClick={() => history.push(locationRoutes.add)}
                  />
                </div>
              )}
            </Fragment>
          )}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state: ReduxStateType) {
  return {
    searchInput: state.location.searchInput,
    filterByPartnerObj: state.location.filterByPartnerObj,
    paginationDetails: state.shared.paginationDetails,
  };
}

const mapDispatch = {
  saveFilterLocation: saveFilterLocation,
  updateToken: updateToken,
  savePagination: savePagination,
};

const connector = connect(mapStateToProps, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LocationList);

// function getColumnRender(text: string, record: LocationListType) {
//   if (record?.status === 'STOCK') {
//     return {
//       props: {
//         style: { background: `#D3D3D3` },
//       },
//       children: <Fragment>{text}</Fragment>,
//     };
//   }

//   return {
//     children: <Fragment>{text}</Fragment>,
//   };
// }

function getColumns({
  handleDelete,
}: {
  handleDelete: (id: string) => void;
}): AntdTableColumnsType<LocationListType>[] {
  return [
    {
      title: 'Location ID',
      key: 'locationID',
      dataIndex: 'locationID',
      sorter: (a: LocationListType, b: LocationListType) =>
        handleSorting(a.locationID, b.locationID),
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'ascend',
      render: (text: string, record: LocationListType) => {
        let iconColor: string = colorCodes.blue;
        if (record.status === 'LIVE') {
          const lastContact = moment.utc(record?.lastContact);
          const timeDiff = currentDateTime.diff(lastContact, 'hours', true);
          if (timeDiff && timeDiff < 3) {
            iconColor = colorCodes.green;
          } else if (timeDiff && timeDiff < 48) {
            iconColor = colorCodes.amber;
          } else {
            iconColor = colorCodes.red;
          }
        } else if (record.status === 'OFFLINE') {
          iconColor = colorCodes.grey;
        }

        return (
          <Fragment>
            <Row align="top">
              <Col xs={20} className="pr-2">
                {text}
              </Col>
              <Col xs={4}>
                <Avatar
                  style={{
                    backgroundColor: iconColor,
                    verticalAlign: 'middle',
                  }}
                  size={16}
                  gap={4}
                />
              </Col>
            </Row>
          </Fragment>
        );
      },
      // render: (text: string, record: LocationListType) =>
      //   getColumnRender(text, record),
    },
    {
      title: 'Location Name',
      dataIndex: 'locationName',
      key: 'locationName',
      sorter: (a: LocationListType, b: LocationListType) =>
        handleSorting(a.locationName, b.locationName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Device ID',
      dataIndex: 'locationMac',
      key: 'locationMac',
      sorter: (a: LocationListType, b: LocationListType) =>
        handleSorting(a.locationMac, b.locationMac),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Friendly Name',
      dataIndex: 'friendlyName',
      key: 'friendlyName',
      sorter: (a: LocationListType, b: LocationListType) =>
        handleSorting(a.friendlyName, b.friendlyName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Partner',
      dataIndex: 'partnerID',
      key: 'partnerID',
      sorter: (a: LocationListType, b: LocationListType) =>
        handleSorting(a.partnerID, b.partnerID),
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, record: LocationListType) => {
        const { rights } = record;

        const allowAllEdit = rights?.includes(locationRights.edit) ?? false;
        const allowNoteEdit = rights?.includes(locationRights.addNote) ?? false;
        const allowDelete = rights?.includes(locationRights.remove) ?? false;
        const hasStock = record?.status === 'STOCK';

        // if (record?.status === 'STOCK') {
        //   return {
        //     props: {
        //       style: { background: `#D3D3D3` },
        //     },
        //     children: (
        //       <Fragment>
        //         <Action
        //           hasStock
        //           locationID={record.locationID}
        //           allowAllEdit={allowAllEdit}
        //           allowNoteEdit={allowNoteEdit}
        //           hasDeleteRights={hasDeleteRights}
        //           allowDelete={allowDelete}
        //           handleDelete={handleDelete}
        //         />
        //       </Fragment>
        //     ),
        //   };
        // }

        return {
          children: (
            <Fragment>
              <Action
                hasStock={hasStock}
                locationID={record.locationID}
                allowAllEdit={allowAllEdit}
                allowNoteEdit={allowNoteEdit}
                allowDelete={allowDelete}
                handleDelete={handleDelete}
              />
            </Fragment>
          ),
        };
      },
    },
  ];
}

function Action({
  allowAllEdit,
  allowNoteEdit,
  allowDelete,
  hasStock,
  locationID,
  handleDelete,
}: {
  allowAllEdit: boolean;
  allowNoteEdit: boolean;
  allowDelete: boolean;
  hasStock: boolean;
  locationID: string;
  handleDelete: (id: string) => void;
}) {
  return (
    <Fragment>
      <div className="row">
        {(allowAllEdit || allowNoteEdit) && (
          <div className="col-2">
            <Link
              to={{
                pathname: locationRoutes.details(),
                state: {
                  locationID,
                  allowAllEdit,
                  allowNoteEdit,
                  hasStock,
                },
              }}>
              <FaCog size="1.2em" />
            </Link>
          </div>
        )}

        {allowDelete && (
          <div className="col-2">
            <FaTrashAlt
              size="1.2em"
              style={{ color: 'red', cursor: 'pointer' }}
              onClick={(event) => {
                event.stopPropagation();
                handleDelete?.(locationID);
              }}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
}
