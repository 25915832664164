import { Fragment, createRef, Component, ReactNode } from 'react';
import { MapContainer, Marker, ImageOverlay } from 'react-leaflet';
import Leaflet from 'leaflet';
import update from 'immutability-helper';

import 'leaflet/dist/leaflet.css';
import { MapDetailsType } from '../../../../type-definitions';
import { FloorPlanLocationListType } from '../../../../type-definitions/api-types';
import {
  getBoundsUnproject,
  getLeafletIcon,
  getMapPoint,
} from '../../../../utils/leaflet-helpers';

interface PropsType {
  mapDetails: MapDetailsType;
  floorPlanLocationList: FloorPlanLocationListType[];
  isEditable: boolean;
  classes?: string;
  mapPopup?: (params: {
    isEditable?: boolean;
    deviceID?: string;
    status?: string;
    locationID?: string;
  }) => ReactNode;
  onMapClick?: () => void;

  mapMarkerRender?: (params: {
    record: FloorPlanLocationListType;
    mapPopup?: (params: {
      isEditable?: boolean;
      deviceID?: string;
      status?: string;
      locationID?: string;
    }) => ReactNode;
  }) => ReactNode;

  onUpdateLocation?: (record: FloorPlanLocationListType) => void;

  onMarkerClick?: (locationDetails: FloorPlanLocationListType) => void;

  selectedLocationDetails: Partial<FloorPlanLocationListType>;
  selectedLocationIDFromParam?: string;

  setMapDefaultParameters?: (
    defaultPosition: Leaflet.LatLng,
    defaultCoordinates: { x: number; y: number }
  ) => void;

  createLocation?: boolean;
}

interface StateType {
  currentPosition: Partial<Leaflet.LatLng>;
  mapBounds?: Leaflet.LatLngBounds;
  floorPlanLocations: FloorPlanLocationListType[];
  mapInstance?: Leaflet.Map;
}

const customCRS = (Leaflet as any).extend({}, Leaflet.CRS, {
  projection: Leaflet.Projection.LonLat,
  transformation: new Leaflet.Transformation(1, 0, 1, 0),
});

const unprojectZoom = 4;

export default class FloorPlanMapMultiple extends Component<
  PropsType,
  StateType
> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      currentPosition: {},
      mapBounds: undefined,
      floorPlanLocations: [],
      mapInstance: undefined,
    };
  }

  markerRef = createRef<Leaflet.Marker<any>>();
  _isMounted = false;
  wrapperDivRef = createRef<HTMLDivElement>();

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps: PropsType, prevState: StateType) {
    const { floorPlanLocationList } = this.props;
    const { mapInstance, floorPlanLocations } = this.state;

    if (
      floorPlanLocationList.length > floorPlanLocations.length &&
      mapInstance
    ) {
      const tempFloorPlanLocationList = floorPlanLocationList.map((item) => {
        let obj = { ...item };
        if (obj.x && obj.y) {
          const point = getMapPoint({
            x: obj.x,
            y: obj.y,
          });
          const position = mapInstance.unproject(point, unprojectZoom);
          obj = update(obj, { position: { $set: position } });

          return { ...obj };
        }
        return { ...obj };
      });

      this.handleState({ floorPlanLocations: tempFloorPlanLocationList });
    }
  }

  handleState = (data: Partial<StateType>, callback?: () => void) => {
    this._isMounted &&
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            ...data,
          };
        },
        () => {
          callback?.();
        }
      );
  };

  handleMap = (map: Leaflet.Map) => {
    const { mapDetails, onMapClick } = this.props;

    if (mapDetails && mapDetails.height && mapDetails.width) {
      const bounds = getBoundsUnproject(
        map,
        mapDetails.height,
        mapDetails.width,
        unprojectZoom
      );

      if (bounds) {
        map.fitBounds(bounds);
      }

      // if (!mapDetails.defaultCoordinates) {
      //   const center = map.getBounds().getCenter();
      //   let tempCoordinates = map.project(
      //     map.getBounds().getCenter(),
      //     unprojectZoom
      //   );
      //   const defaultCoordinates = {
      //     x: tempCoordinates.x,
      //     y: tempCoordinates.y,
      //   };
      //   setMapDefaultParameters?.(center, defaultCoordinates);
      // }

      this._isMounted &&
        this.setState({
          mapBounds: bounds,
          mapInstance: map,
        });
    }

    map.on('click', (event) => {
      onMapClick?.();
    });
  };

  onMarkerDragEnd = (
    event: Leaflet.DragEndEvent,
    record: FloorPlanLocationListType
  ) => {
    const { mapInstance, floorPlanLocations } = this.state;
    const { onUpdateLocation } = this.props;

    const tempLatLng = event?.target?.getLatLng?.();

    const position = {
      lat: tempLatLng.lat,
      lng: tempLatLng.lng,
    };

    if (mapInstance) {
      const coordinates = mapInstance?.project(tempLatLng, unprojectZoom);

      record = update(record, {
        position: { lat: { $set: position.lat }, lng: { $set: position.lng } },
        x: { $set: coordinates.x },
        y: { $set: coordinates.y },
      });

      let temp = [...floorPlanLocations];
      temp = temp.map((el) => {
        let obj = { ...el };

        if (obj.locationID === record.locationID) {
          obj = update(obj, {
            x: { $set: record.x },
            y: { $set: record.y },
            position: { $set: record.position },
          });
        }
        return { ...obj };
      });

      this.handleState({ floorPlanLocations: temp }, () => {
        onUpdateLocation?.(record);
      });
    }
  };

  onLoad = () => {
    setTimeout(() => {
      const { mapInstance } = this.state;
      const {
        onUpdateLocation,
        floorPlanLocationList,
        setMapDefaultParameters,
      } = this.props;
      const center = mapInstance?.getCenter?.();

      let matched = floorPlanLocationList.find(
        (el) => el.locationID === 'createLocation'
      );

      if (matched && mapInstance) {
        const tempFloorPlanLocationList = floorPlanLocationList.map((item) => {
          let obj = { ...item };

          if (item.locationID === matched?.locationID) {
            obj = update(obj, {
              position: { $set: center },
            });
            return { ...obj };
          } else {
            const point = getMapPoint({
              x: item.x,
              y: item.y,
            });
            const position = mapInstance.unproject(point, unprojectZoom);
            obj = update(obj, {
              position: { $set: position },
            });
            return { ...obj };
          }
        });

        this.handleState(
          { floorPlanLocations: tempFloorPlanLocationList },
          () => {
            matched = update(matched, {
              position: { $set: center },
            });
            if (matched) {
              onUpdateLocation?.(matched);

              if (center) {
                let tempCoordinates = mapInstance.project(
                  center,
                  unprojectZoom
                );
                const defaultCoordinates = {
                  x: tempCoordinates.x,
                  y: tempCoordinates.y,
                };
                setMapDefaultParameters?.(center, defaultCoordinates);
              }
            }
          }
        );
      }
    }, 500);
  };

  render() {
    const {
      mapDetails,
      classes,
      isEditable,
      mapPopup,
      mapMarkerRender,
      onMarkerClick,
      // selectedLocationDetails,
      selectedLocationIDFromParam,
      createLocation,
    } = this.props;
    const { mapBounds, currentPosition, floorPlanLocations } = this.state;

    const mapImage = `data:image/svg+xml,${encodeURIComponent(
      mapDetails.image || ''
    )}`;

    const center = Leaflet.latLng(
      currentPosition.lat || 0,
      currentPosition.lng || 0
    );

    return (
      <Fragment>
        <div ref={this.wrapperDivRef} style={{ height: '100%' }}>
          <img
            src={mapImage}
            alt="map"
            style={{ display: 'none' }}
            onLoad={this.onLoad}
          />
          <MapContainer
            tap={false}
            className={classes}
            whenCreated={this.handleMap}
            // whenReady={this.onLoad}
            center={center}
            crs={customCRS}
            zoom={unprojectZoom}>
            {mapDetails.image && mapBounds && (
              <ImageOverlay bounds={mapBounds} url={mapImage} />
            )}
            {floorPlanLocations.length > 0 &&
              floorPlanLocations.map((item) => {
                if (item.position) {
                  if (mapMarkerRender) {
                    return mapMarkerRender({
                      record: item,
                      mapPopup: mapPopup,
                    });
                  }

                  let draggable = false;

                  let iconColor: 'blue' | 'red' | 'grey' | 'amber' | 'green' =
                    'blue';
                  if (selectedLocationIDFromParam === item.locationID) {
                    iconColor = 'red';
                  } else if (item.deviceID === 'UNALLOCATED') {
                    iconColor = 'grey';
                  }

                  if (item.locationID === 'createLocation') {
                    iconColor = 'red';
                  }

                  // if (
                  //   selectedLocationIDFromParam === item.locationID ||
                  //   item.locationID === 'createLocation'
                  // ) {
                  //   draggable = true;
                  // }

                  if (createLocation === true) {
                    draggable = true;
                  }

                  return (
                    <Fragment key={item.locationID}>
                      <Marker
                        key={item.locationID}
                        title={item.locationID}
                        icon={getLeafletIcon(iconColor)}
                        draggable={draggable}
                        position={[item.position?.lat, item.position?.lng]}
                        eventHandlers={{
                          dragend: (event) => this.onMarkerDragEnd(event, item),
                          click: () => onMarkerClick?.(item),
                        }}
                        ref={this.markerRef}>
                        {mapPopup?.({
                          isEditable,
                          deviceID: item.deviceID,
                          status: item.status,
                          locationID: item.locationID,
                        })}
                      </Marker>
                    </Fragment>
                  );
                }
                return null;
              })}
          </MapContainer>
        </div>
      </Fragment>
    );
  }
}
