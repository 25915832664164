import {
  SET_USER_RIGHTS_REQUESTED,
  UPDATE_TOKEN_REQUESTED,
  SAVE_PAGINATION,
  CLEAR_PAGINATION_START,
  // SAVE_NAVBAR_DETAILS,
  SHARED_SUCCESS,
} from '../actionTypes/shared.types';
import { getFromSession, setInSession } from '../../utils/manage-storage';
import { ReduxActionPayloadType } from '../../type-definitions';
import { ApiResultType } from '../../type-definitions/api-types';
import { browserStorageKey } from '../../utils/constants';

export function setUserRights() {
  return {
    type: SET_USER_RIGHTS_REQUESTED,
  };
}

export function updateToken(result: Partial<ApiResultType>) {
  return {
    type: UPDATE_TOKEN_REQUESTED,
    payload: {
      result,
    },
  };
}

export function savePagination(data: {
  paginationKey: string;
  paginationValue?: number;
}) {
  let pagination = getFromSession(browserStorageKey.shared.pagination);
  const { paginationKey, paginationValue } = data;
  if (pagination) {
    pagination[paginationKey] = paginationValue || pagination[paginationKey];
  } else {
    pagination = { [paginationKey]: paginationValue };
  }

  setInSession(browserStorageKey.shared.pagination, pagination);

  const payload: ReduxActionPayloadType = {
    paginationKey,
    paginationValue: paginationValue || pagination[paginationKey],
  };

  return {
    type: SAVE_PAGINATION,
    payload,
  };
}

export function clearPagination({ paginationKey }: { paginationKey: string }) {
  return {
    type: CLEAR_PAGINATION_START,
    payload: {
      paginationKey,
    },
  };
}

// export function saveNavbarDetails({
//   navbarKey,
//   navbarValue,
// }: {
//   navbarKey: string;
//   navbarValue: boolean;
// }) {
//   return {
//     type: SAVE_NAVBAR_DETAILS,
//     payload: {
//       navbarKey,
//       navbarValue,
//     },
//   };
// }

export function sharedSuccess(data: {}) {
  return {
    type: SHARED_SUCCESS,
    payload: {
      ...data,
    },
  };
}
