import { CSSProperties, Fragment, ReactNode, useMemo, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Leaflet, {
  Marker as MarkerType,
  Map as LeafletMapType,
  LeafletMouseEvent,
} from 'leaflet';

import 'leaflet/dist/leaflet.css';

import { getLeafletIcon } from '../../utils/leaflet-helpers';
import { Col, Row } from 'antd';

type PropsType = {
  lat: number;
  lng: number;
  zoom: number;
  classes?: string;
  height?: string | number;
  onMarkerMove?: (lat: number, lng: number) => void;
  onMapClickMarkerMove?: (lat: number, lng: number) => void;
  onMapZoom?: (zoom: number) => void;
  customPopup?: ReactNode;
  markerIcon?: Leaflet.Icon<Leaflet.IconOptions>;
};

export default function LeafletMap({
  onMarkerMove,
  lat,
  lng,
  zoom,
  classes,
  onMapZoom,
  height,
  customPopup,
  markerIcon,
  onMapClickMarkerMove,
}: PropsType) {
  function handleMap(map: LeafletMapType) {
    map.on('zoomend', function (event) {
      // console.log('event==>', event.target._zoom);
      if (onMapZoom) {
        onMapZoom(map.getZoom());
      }
    });

    // markerRef.current?.addEventListener('dragend', function (event) {
    //   const currentPosition = event.target?.getLatLng?.();
    //   // console.log('currentPosition', currentPosition);
    //   onMarkerMove(currentPosition.lat, currentPosition.lng);
    // });

    map.on('click', (event: LeafletMouseEvent) => {
      const currentPosition = event.latlng;
      onMapClickMarkerMove?.(currentPosition.lat, currentPosition.lng);
    });
  }

  const styles: CSSProperties = {};
  if (height) {
    styles.height = typeof height === 'number' ? `${height}px` : height;
  }

  return (
    <Fragment>
      <MapContainer
        tap={false}
        whenCreated={handleMap}
        center={[lat, lng]}
        zoom={zoom}
        maxZoom={16}
        className={classes || ''}
        style={{ ...styles }}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {!onMarkerMove ? (
          <Marker
            position={[lat, lng]}
            icon={markerIcon ? markerIcon : getLeafletIcon()}>
            {customPopup ? (
              customPopup
            ) : (
              <Popup>
                {/* Current location:{' '}
                <pre>{JSON.stringify({ lat, lng }, null, 2)}</pre> */}
                <Row>
                  <Col>Lat: {lat}</Col>
                </Row>
                <Row>
                  <Col>Lng: {lng}</Col>
                </Row>
              </Popup>
            )}
          </Marker>
        ) : (
          <DraggableMarker
            lat={lat}
            lng={lng}
            onMarkerMove={onMarkerMove}
            customPopup={customPopup}
          />
        )}
      </MapContainer>
    </Fragment>
  );
}

function DraggableMarker({
  lat,
  lng,
  onMarkerMove,
  customPopup,
  markerIcon,
}: {
  lat: number;
  lng: number;
  onMarkerMove: (lat: number, lng: number) => void;
  customPopup?: ReactNode;
  markerIcon?: Leaflet.Icon<Leaflet.IconOptions>;
}) {
  const markerRef = useRef<MarkerType<any>>(null);
  const eventHandlers = useMemo(() => {
    return {
      dragend: () => {
        const marker = markerRef.current;
        if (marker !== null) {
          onMarkerMove(marker?.getLatLng?.().lat, marker?.getLatLng?.().lng);
        }
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Marker
      icon={markerIcon ? markerIcon : getLeafletIcon()}
      draggable={true}
      eventHandlers={eventHandlers}
      position={[lat, lng]}
      ref={markerRef}>
      {customPopup ? (
        customPopup
      ) : (
        <Popup>
          Current location: <pre>{JSON.stringify({ lat, lng }, null, 2)}</pre>
        </Popup>
      )}
    </Marker>
  );
}
