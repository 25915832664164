import { Button, Col, Row } from 'antd';
import { Fragment } from 'react';
import AntdInput from '../../AntdInput';
import { FormElementsType } from '../helpers';

interface PropsType {
  formElements: FormElementsType;
  inputChangedHandler: (name: any, value: any) => void;
  handleMoveDeviceModal: () => void;
  handleSwapDeviceModal: () => void;
  handleRedirectToLocation: () => void;
}

function LocationsTab({
  formElements,
  inputChangedHandler,
  handleMoveDeviceModal,
  handleSwapDeviceModal,
  handleRedirectToLocation,
}: PropsType) {
  return (
    <Fragment>
      <Row gutter={[{ md: 12 }, 0]}>
        <Col xs={24}>
          <AntdInput
            {...formElements.locationID}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>

      <Row gutter={[{ md: 12 }, 0]}>
        <Col xs={24}>
          <AntdInput
            {...formElements.locationName}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>

      <Row gutter={[{ md: 12 }, 0]}>
        <Col xs={24}>
          <AntdInput
            {...formElements.locationStatus}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>

      <Row justify="center" gutter={[{ md: 12 }, 16]}>
        <Col xs={24} sm={12} md={8}>
          <Button
            disabled={
              formElements.locationStatus.value === 'LIVE' ? true : false
            }
            type="primary"
            htmlType="button"
            onClick={handleMoveDeviceModal}>
            Move Device
          </Button>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Button
            type="primary"
            htmlType="button"
            onClick={handleSwapDeviceModal}>
            Swap Device
          </Button>
        </Col>
      </Row>

      <Row justify="center" gutter={[{ md: 12 }, 0]}>
        <Col xs={24} sm={12} md={8}>
          <Button
            type="primary"
            htmlType="button"
            onClick={handleRedirectToLocation}>
            Location Details
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
}

export default LocationsTab;
