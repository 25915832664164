import { Component, FormEvent, Fragment } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect, ConnectedProps } from 'react-redux';
import axios from 'axios';
import { Button, message } from 'antd';
import { StateType, initialElements, FormElementsType } from './helpers';
import {
  UserDataType,
  UserRightListType,
} from '../../type-definitions/api-types';
import { checkValidation } from '../../utils/validation';
import { authSuccess, sharedSuccess, updateToken } from '../../redux/actions';
import { apiCall } from '../../api-services/api';
import { authApi } from '../../api-services/api-list';
import { handleUserPermssion } from '../../utils';
import { setInLocal } from '../../utils/manage-storage';
import { getInputElementsSingle } from '../../utils/get-input-elements';
import NotificationHandler from '../../components/NotificationHandler';
import FormWrapper from '../../components/FormWrapper';
import { ReduxStateType } from '../../type-definitions';

interface PropsType extends PropsFromRedux {
  isUserChangePass: boolean;
  userData: Partial<UserDataType>;
  userPermissionList: string[];
  isAuth?: boolean;
  userName?: string;
  currentPwd?: string;
  isChecked?: boolean;
}

class PasswordChange extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    const userName = props?.userName;
    const currentPwd = props?.currentPwd;
    const initElems = {
      ...initialElements,
      userName: {
        ...initialElements.userName,
        value: userName || props.userData.username || '',
      },
      currentPassword: {
        ...initialElements.currentPassword,
        onInputHover: this.handlePeepIcon,
        value: currentPwd || '',
      },
      confirmPassword: {
        ...initialElements.confirmPassword,
        onInputHover: this.handlePeepIcon,
      },
      password: {
        ...initialElements.password,
        onInputHover: this.handlePeepIcon,
      },
    };
    this.state = {
      formElements: cloneDeep(initElems),
      initElements: cloneDeep(initElems),
      success: {},
      error: {},
      loading: false,
    };
  }

  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  inputChangedHandler = (name: keyof FormElementsType, value: string) => {
    const { formElements } = this.state;

    if (name) {
      formElements[name].value = value;
      formElements[name].touched = true;
      formElements[name].valid = checkValidation(
        value,
        formElements[name].validation
      );

      formElements.confirmPassword.valid =
        formElements.password.value === formElements.confirmPassword.value
          ? true
          : false;

      this._isMounted && this.setState({ formElements });
    }
  };

  onFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { formElements, initElements } = this.state;
    const {
      authSuccess,
      sharedSuccess,
      updateToken,
      isUserChangePass,
      isChecked,
    } = this.props;

    let key: keyof typeof formElements;

    for (key in formElements) {
      formElements[key].touched = true;
      formElements[key].valid = checkValidation(
        formElements[key].value,
        formElements[key].validation
      );
    }

    this._isMounted && this.setState({ formElements });

    for (key in formElements) {
      if (!formElements[key].valid) {
        message.error('Please fill all the fields');
        return;
      }
    }

    this._isMounted && this.setState({ loading: true });

    const data = {
      userName: formElements.userName.value,
      password: formElements.currentPassword.value,
      newPassword: formElements.confirmPassword.value,
    };

    try {
      const { url, method, params, contentType } = authApi.getLogin({
        username: data.userName,
        password: data.password,
        newpassword: data.newPassword,
      });
      const response = await apiCall({
        url,
        method,
        params,
        contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      if (result) {
        updateToken(result.data);

        const authReducerData: {
          userData: UserDataType;
          userRightList?: UserRightListType[];
        } = {
          userData: result.data,
        };

        if (result?.status === 'ok') {
          if (!isUserChangePass) {
            const rights = authApi.getUserRights();
            const response2 = await apiCall({
              storeToken: result.token,
              url: rights.url,
              method: rights.method,
              contentType: rights.contentType,
              cancelToken: this.axiosCancelSource.token,
            });
            const result2 = response2?.data;
            if (result2?.data && result2.data.length > 0) {
              // const userPermission = handleUserPermssion(
              //   result2.data,
              //   result?.data?.partnerID
              // );
              const userPermission = handleUserPermssion(
                result2.data
                // result?.data?.partnerID
              );
              sharedSuccess({ userPermissionList: userPermission });
              authReducerData.userRightList = result2.data || [];
            }

            if (isChecked) {
              setInLocal('token', result.token);
            }
          }
          authSuccess({ ...authReducerData });
          this._isMounted &&
            this.setState({
              loading: false,
              formElements: initElements,
              success: result,
            });
        } else {
          this._isMounted && this.setState({ loading: false, error: result });
        }
      }
    } catch (error) {
      this._isMounted && this.setState({ loading: false, error });
    }
  };

  handlePeepIcon = () => {
    const { formElements } = this.state;

    if (formElements.password.elementConfig.type === 'password') {
      formElements.password.elementConfig.type = 'text';
    } else {
      formElements.password.elementConfig.type = 'password';
    }

    if (formElements.confirmPassword.elementConfig.type === 'password') {
      formElements.confirmPassword.elementConfig.type = 'text';
    } else {
      formElements.confirmPassword.elementConfig.type = 'password';
    }

    if (formElements.currentPassword.elementConfig.type === 'password') {
      formElements.currentPassword.elementConfig.type = 'text';
    } else {
      formElements.currentPassword.elementConfig.type = 'password';
    }

    this._isMounted && this.setState({ formElements });
  };

  render() {
    const { loading, error, success, formElements } = this.state;

    const singleElem = getInputElementsSingle({
      formElements,
      inputChangedHandler: this.inputChangedHandler,
      sliceValue: [0, 4],
    });

    return (
      <Fragment>
        <NotificationHandler success={success} error={error} obj={this} />
        <FormWrapper loading={loading} title="Change Password">
          <form noValidate onSubmit={this.onFormSubmit}>
            {singleElem}
            <div className="row justify-content-center">
              <div className="col-md-5 col-lg-4 col-xl-3">
                <Button type="primary" htmlType="submit" block size="large">
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </FormWrapper>
      </Fragment>
    );
  }
}

const mapDispatch = {
  updateToken: updateToken,
  sharedSuccess: sharedSuccess,
  authSuccess: authSuccess,
};

function mapStateToProps(state: ReduxStateType) {
  return {
    userData: state.auth.userData,
  };
}

const connector = connect(mapStateToProps, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(PasswordChange);
