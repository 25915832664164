import { FormInputType } from '../../../type-definitions';

export const initialElements: FormElementsType = {
  // 0
  groupID: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Group ID',
      name: 'groupID',
    },
    value: '',
    validation: {
      // required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Group ID is required',
    label: 'Group ID',
    // disabled: true
  },
  // 1
  partnerName: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      // placeholder: 'Partner ID',
      name: 'partnerName',
    },
    optionValues: [],
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Partner Name is required',
    label: 'Partner Name',
    disabled: true,
  },
  // 2
  groupName: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Group Name',
      name: 'groupName',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Group Name is required',
    label: 'Group Name *',
    // disabled: true
  },
  // 3
  groupType: {
    elementType: 'select',
    elementConfig: {
      type: 'text',
      // placeholder: 'Group Type',
      name: 'groupType',
    },
    optionValues: [
      { value: '', text: 'Please Select' },
      { value: 'NORMAL', text: 'NORMAL' },
      { value: 'SYSTEM', text: 'SYSTEM' },
      { value: 'ROLE', text: 'ROLE' },
    ],
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Group Type is required',
    label: 'Group Type',
    colValue: 12,
    // disabled: true
  },
  // 4
  dateTimeRadio: {
    elementType: 'radioGroup',
    elementConfig: {
      type: 'text',
      // placeholder: 'Partner ID',
      name: 'dateTimeRadio',
    },
    optionValues: [
      {
        value: 'dateAccess',
        text: 'Date Access',
      },
      {
        value: 'rollingAccess',
        text: 'Rolling Access',
      },
      {
        value: 'unlimitedAccess',
        text: 'Unlimited Access',
      },
    ],
    value: 'dateAccess',
    validation: {
      // required: true,
    },
    valid: false,
    touched: false,
    errorMessage: '',
    label: '',
    colValue: 8,
  },
  // 5
  minDate: {
    elementType: 'date',
    elementConfig: {
      // type: 'text',
      // placeholder: 'From',
      name: 'minDate',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'From is required',
    label: 'From',
    // disabled: true
  },
  // 6
  minTime: {
    elementType: 'time',
    elementConfig: {
      // type: 'text',
      // placeholder: 'From',
      name: 'minTime',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: '',
    label: 'nbsp',
  },
  // 7
  maxDate: {
    elementType: 'date',
    elementConfig: {
      // type: 'text',
      // placeholder: 'To',
      name: 'maxDate',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'To is required',
    label: 'To',
    // disabled: true
  },
  // 8
  maxTime: {
    elementType: 'time',
    elementConfig: {
      // type: 'text',
      // placeholder: 'From',
      name: 'maxTime',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: '',
    label: 'nbsp',
    // disabled: true
  },
  // 9
  maxDaysFromToday: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Max Days From Today',
      name: 'maxDaysFromToday',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Max Days From Today is required',
    label: 'Max Days From Today',
    // disabled: true
  },
  // 10
  phenomList: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      // placeholder: 'Phenom List',
      name: 'phenomList',
      readOnly: true,
    },
    value: '',
    validation: {},
    valid: false,
    touched: false,
    errorMessage: 'Phenom List is required',
    label: 'Phenom List',
    disabled: false,
  },
  // 11
  extendedPermission: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      // placeholder: 'Extended Permission',
      name: 'extendedPermission',
      readOnly: true,
    },
    value: '',
    validation: {},
    valid: false,
    touched: false,
    errorMessage: 'Extended Permission is required',
    label: 'Extended Permission',
    disabled: false,
  },
  // 12
  deviceManagementPermission: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      name: 'deviceManagementPermission',
      readOnly: true,
    },
    value: '',
    validation: {},
    valid: false,
    touched: false,
    errorMessage: 'Device Management Permission is required',
    label: 'Device Management Permission',
    disabled: false,
  },
  allPhenoms: false,
};

export type FormElementsType = {
  groupID: FormInputType;
  groupName: FormInputType;
  groupType: FormInputType;
  partnerName: FormInputType;
  dateTimeRadio: FormInputType;
  minDate: FormInputType;
  minTime: FormInputType;
  maxDate: FormInputType;
  maxTime: FormInputType;
  maxDaysFromToday: FormInputType;
  phenomList: FormInputType;
  extendedPermission: FormInputType;
  deviceManagementPermission: FormInputType;
  allPhenoms: boolean;
};

export type StateType = {
  formElements: FormElementsType;
  loading: boolean;
  showExtendedPermissionModal: boolean;
  showPhenomModal: boolean;
  showDeviceManagementPermissionModal: boolean;
  initExtendedPermissionCollection: {
    [k: string]: boolean;
  };
  extendedPermissionCollection: {
    [k: string]: boolean;
  };
  initPhenomCollection: {
    [k: string]: boolean;
  };
  phenomCollection: {
    [k: string]: boolean;
  };
  initDeviceManagementCollection: {
    [k: string]: boolean;
  };
  deviceManagementCollection: {
    [k: string]: boolean;
  };
  currentTabIndex: string;

  hasInputChanged: boolean;

  allowEdit: boolean;
  groupID: string;
};
