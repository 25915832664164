import {
  FormEvent,
  Fragment,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import axios, { CancelTokenSource } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { Col, Input, Row, Modal } from 'antd';
import { v4 } from 'uuid';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { FaCog, FaTrashAlt } from 'react-icons/fa';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

import {
  ApiResultType,
  PartnerListType,
  UserDataType,
} from '../type-definitions/api-types';
import { StateType } from '../components/Partners/helpers';
import { apiCall } from '../api-services/api';
import { partnerApi } from '../api-services/api-list';
import { saveFilterPartner, updateToken } from '../redux/actions';
import { partnerRights } from '../utils/permission-list';
import { handleSorting, handleTableSearch } from '../utils';
import NotificationHandler from '../components/NotificationHandler';
import TableWrapper, { AddButton } from '../components/TableWrapper';
import { AntdTableColumnsType, ReduxStateType } from '../type-definitions';
import { partnerRoutes } from '../Routes/routes-list';

interface PropsType extends RouteComponentProps {
  userData: Partial<UserDataType>;
  userPermissionList: string[];
}

const initState = {
  dataList: [],
  initDataList: [],
  perPage: 10,
  loading: true,
  error: {},
  success: {},
};

function Partners({ userData, userPermissionList, history }: PropsType) {
  const [state, setState] = useState<Readonly<StateType>>(initState);
  const _isMounted = useRef(false);
  const axiosCancelSource = useRef<CancelTokenSource>();
  const dispatch = useDispatch();
  const searchInput = useSelector(
    (state: ReduxStateType) => state.partner.searchInput
  );

  function handleState(data: object) {
    _isMounted.current &&
      setState((prevState) => ({
        ...prevState,
        ...data,
      }));
  }

  useEffect(() => {
    _isMounted.current = true;
    axiosCancelSource.current = axios.CancelToken.source();
    return () => {
      _isMounted.current = false;
      axiosCancelSource.current?.cancel('Component Unmounted');
    };
  }, []);

  const handleFetchedData = useCallback(async () => {
    if (!state.loading) {
      handleState({ loading: true });
    }

    const stateData: Partial<StateType> = {};

    try {
      const { url, method, contentType } = partnerApi.getPartners();
      const response = await apiCall({
        storeToken: userData?.token,
        url,
        method,
        contentType,
        cancelToken: axiosCancelSource.current?.token,
      });
      const result = response?.data;
      stateData.loading = false;
      if (result) {
        dispatch(updateToken(result));
      }
      if (result?.status === 'ok') {
        const tempData: PartnerListType[] = result.data;
        if (tempData && tempData.length > 0) {
          const partnerList = tempData.map((item) => ({
            ...item,
            uuid: v4(),
          }));
          stateData.dataList = partnerList;
          stateData.initDataList = partnerList;
        }
      } else {
        stateData.error = result;
      }
    } catch (error: any) {
      stateData.error = error;
      stateData.loading = false;
    }

    handleState({ ...stateData });
  }, [dispatch, state.loading, userData?.token]);

  useEffect(() => {
    handleFetchedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeleteClick = useCallback(
    (partnerId: string) => {
      const onDeleteConfirm = async () => {
        if (partnerId) {
          const stateData = {
            success: {},
            error: {},
          };
          try {
            handleState({ loading: true });
            const { url, method, contentType } =
              partnerApi.deletePartnerDetails(undefined, {
                partnerId,
              });
            const response = await apiCall({
              storeToken: userData.token,
              url,
              method,
              contentType,
              cancelToken: axiosCancelSource.current?.token,
            });
            const result: ApiResultType = response?.data;

            if (result?.status === 'ok') {
              stateData.success = result;
            }

            handleFetchedData();
          } catch (error: any) {
            stateData.error = error?.data;
          }

          handleState({ ...stateData, loading: false });
        }
      };

      Modal.confirm({
        title: 'Are you sure?',
        icon: <AiOutlineExclamationCircle />,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: () => {
          onDeleteConfirm();
        },
        onCancel: () => {},
      });
    },
    [handleFetchedData, userData.token]
  );

  const handleSearch = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const targetValue = event.currentTarget.value;

      dispatch(saveFilterPartner({ searchInput: targetValue }));

      const tempInitData = cloneDeep(state.initDataList);

      const stateData: Partial<StateType> = {};

      if (targetValue) {
        const tableColumnsKeys = getColumns({ onDeleteClick })
          .map((el) => el.key)
          .filter((el) => el !== 'lat' && el !== 'lng' && el !== 'action');

        const sortedData = handleTableSearch({
          data: tempInitData,
          columnList: tableColumnsKeys,
          searchData: targetValue,
        });
        stateData.dataList = sortedData;
      } else {
        stateData.dataList = [...tempInitData];
      }

      handleState({
        ...stateData,
      });
    },
    [dispatch, onDeleteClick, state.initDataList]
  );

  // function handlePaginationOption(value: string) {
  //   handleState({ perPage: parseInt(value) });
  // }

  function onPaginationChange(page: number, pageSize?: number) {
    // console.log('params', page, pageSize);
  }

  function onShowSizeChange(current: number, size: number) {
    // console.log('current', current);
    // console.log('size', size);
    handleState({ perPage: size });
  }

  function onRowClickRedirect(record: PartnerListType) {
    const { rights, partnerID } = record;
    const allowEdit = rights?.includes(partnerRights.edit) ?? false;
    history.push({
      pathname: partnerRoutes.details(),
      state: {
        partnerID,
        allowEdit,
      },
    });
  }

  const paginationConfig = {
    // current: currentPage,
    pageSize: state.perPage,
    total: (state?.dataList && state.dataList.length) ?? 0,
    onChange: onPaginationChange,
    showSizeChanger: true,
    onShowSizeChange: onShowSizeChange,
  };

  if (userPermissionList.length === 0) {
    return <Redirect to="/" />;
  }

  const showAdd = userPermissionList?.includes(partnerRights.create) ?? false;

  return (
    <Fragment>
      <NotificationHandler
        success={state.success}
        error={state.error}
        obj={{ _isMounted: _isMounted.current, setState }}
      />
      <TableWrapper
        tableDetails={{
          dataSource: state.dataList,
          columns: getColumns({ onDeleteClick }),
          pagination: paginationConfig,
          customConfig: {
            rowKeyValue: 'uuid',
            onRowClick: onRowClickRedirect,
          },
        }}
        loading={state.loading}
        pageTitle="Partners"
        inputComponents={() => (
          <Fragment>
            <div className="col-lg-2 col-md-3 col-sm-6 ml-auto">
              <Input
                value={searchInput}
                onChange={handleSearch}
                placeholder="Search..."
                size="large"
              />
            </div>

            {showAdd && (
              <div className="col-xl-1 col-lg-2 col-md-3 col-sm-6 pt-3 pt-sm-0">
                <AddButton
                  onButtonClick={() => history.push(partnerRoutes.add)}
                />
              </div>
            )}
          </Fragment>
        )}
      />
    </Fragment>
  );
}

export default Partners;

function getColumns({
  onDeleteClick,
}: {
  onDeleteClick: (id: string) => void;
}): AntdTableColumnsType<PartnerListType>[] {
  return [
    {
      title: 'ID',
      key: 'partnerID',
      dataIndex: 'partnerID',
      sorter: (a: PartnerListType, b: PartnerListType) =>
        handleSorting(a.partnerID, b.partnerID),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Name',
      key: 'partnerName',
      dataIndex: 'partnerName',
      sorter: (a: PartnerListType, b: PartnerListType) =>
        handleSorting(a.partnerName, b.partnerName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Latitude',
      dataIndex: 'lat',
      key: 'lat',
      sorter: (a: PartnerListType, b: PartnerListType) =>
        handleSorting(a.lat.toString(), b.lat.toString()),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Longitude',
      dataIndex: 'lng',
      key: 'lng',
      sorter: (a: PartnerListType, b: PartnerListType) =>
        handleSorting(a.lng.toString(), b.lng.toString()),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record: PartnerListType) => {
        const { rights } = record;
        const allowEdit = rights?.includes(partnerRights.edit) ?? false;
        const allowRemove = rights?.includes(partnerRights.remove) ?? false;
        return (
          <Fragment>
            <Row gutter={[16, 0]} align="middle">
              {allowEdit && (
                <Col>
                  <Link
                    to={{
                      pathname: partnerRoutes.details(),
                      state: { allowEdit, partnerID: record.partnerID },
                    }}>
                    <FaCog size="1.2em" />
                  </Link>
                </Col>
              )}

              {allowRemove && (
                <Col>
                  <FaTrashAlt
                    size="1.2em"
                    style={{ cursor: 'pointer', color: 'red' }}
                    onClick={(event) => {
                      event.stopPropagation();
                      onDeleteClick(record.partnerID);
                    }}
                  />
                </Col>
              )}
            </Row>
          </Fragment>
        );
      },
    },
  ];
}
