import { Component, FormEvent, Fragment } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import update from 'immutability-helper';

import {
  ApiErrorType,
  PartnerListType,
  UserDataType,
} from '../type-definitions/api-types';
import { updateToken } from '../redux/actions';
import { connect, ConnectedProps } from 'react-redux';
import { ReduxStateType, SelectOptionsType } from '../type-definitions';
import axios from 'axios';
import {
  StateType,
  initState,
  FormElementsType,
} from '../components/UserAdd/helpers';
import { partnerApi, userApi } from '../api-services/api-list';
import { apiCall } from '../api-services/api';
import { checkValidation } from '../utils/validation';
import { Button, message } from 'antd';
import { handleFormBody } from '../utils';
import { userRoutes } from '../Routes/routes-list';
import { userRights } from '../utils/permission-list';
import FormWrapper from '../components/FormWrapper';
import {
  getInputElementsMultiple,
  getInputElementsSingle,
} from '../utils/get-input-elements';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Modal from 'antd/lib/modal/Modal';
import LeafletMap from '../components-shared/LeafletMap';
import { handleNotification } from '../utils/notification-handler';
import PromptPopup from '../components/PromptPopup';

interface PropsType extends RouteComponentProps, PropsFromRedux {
  userData: Partial<UserDataType>;
  userPermissionList: string[];
}

class UserAdd extends Component<PropsType, StateType> {
  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  constructor(props: PropsType) {
    super(props);
    this.state = {
      ...initState,
      formElements: {
        ...initState.formElements,
        tempPassword: {
          ...initState.formElements.tempPassword,
          showPeep: true,
          onInputHover: this.handlePeepIcon,
        },
      },
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { userRightList, userData } = this.props;
    if (userRightList.length > 0 && userData.lat) {
      this.handleFetchedData();
    } else {
      this._isMounted && this.setState({ fromCdu: true });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  handleState = (data: Partial<StateType>, callback?: () => void) => {
    this._isMounted &&
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            ...data,
          };
        },
        () => {
          callback?.();
        }
      );
  };

  getPartnerRoles = async (partnerID: string) => {
    const { userData, updateToken } = this.props;
    const roles = partnerApi.getPartnerRoles(undefined, { partnerID });
    try {
      const response = await apiCall({
        storeToken: userData.token,
        url: roles.url,
        method: roles.method,
        contentType: roles.contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      updateToken(result);
      return result?.data;
    } catch (error) {
      return error;
    }
  };

  fetchData = async () => {
    const { userData, updateToken } = this.props;
    let result2 = {};
    try {
      const list = partnerApi.getPartners();
      const response = await apiCall({
        storeToken: userData?.token,
        url: list.url,
        method: list.method,
        contentType: list.contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      updateToken(result);

      if (result.status === 'ok') {
        const partners: PartnerListType[] = result.data;

        let partnerRoles: string[] = [];

        if (partners.length === 1 && partners?.[0]) {
          const partnerID = partners[0]?.partnerID;
          partnerRoles = await this.getPartnerRoles(partnerID);
        } else if (partners.length > 1) {
          let id;
          const selected = partners.find(
            (item) => item?.partnerID === 'AIRSENSA'
          );
          if (selected) {
            id = selected.partnerID;
          } else {
            id = partners[0]?.partnerID;
          }
          partnerRoles = await this.getPartnerRoles(id);
        }
        return {
          partners: result.data || [],
          partnerRoles: partnerRoles,
        };
      } else {
        return { partners: [], partnerRoles: [], error: result || result2 };
      }
    } catch (error) {
      return { partners: [], partnerRoles: [], error: error };
    }
  };

  handleFetchedData = async () => {
    let {
      partners,
      partnerRoles,
      error,
    }: {
      partners?: PartnerListType[];
      partnerRoles?: string[];
      error?: ApiErrorType;
    } = await this.fetchData();
    if (error) {
      this._isMounted && handleNotification('error', error);
      this._isMounted && this.setState({ loadingPartner: false });
    } else {
      const { userData, userRightList } = this.props;
      const { formElements } = this.state;

      let tempFormElements = { ...formElements };

      const lat = userData.lat || 0;
      const lng = userData.lng || 0;
      tempFormElements = update(tempFormElements, {
        latitude: { value: { $set: lat.toString() } },
        longitude: { value: { $set: lng.toString() } },
      });

      if (partners && partners.length > 0) {
        partners = partners.filter((el) =>
          el.rights.includes(userRights.create)
        );
      }

      if (partners && partners.length === 1) {
        tempFormElements = update(tempFormElements, {
          partnerName: {
            elementType: { $set: 'input' },
            elementConfig: {
              type: { $set: 'text' },
              placeholder: { $set: 'Partner Name' },
            },
            value: { $set: partners[0]?.partnerID || '' },
            disabled: { $set: true },
          },
        });
      } else if (
        partners &&
        partners.length > 1 &&
        userRightList.length > 0 &&
        tempFormElements.partnerName.optionValues &&
        tempFormElements.partnerName.optionValues.length === 0
      ) {
        let partnerOptions: SelectOptionsType[] = [];
        partners.forEach((item) => {
          userRightList.forEach((el) => {
            if (
              el?.partnerid?.toUpperCase() === item?.partnerID?.toUpperCase()
            ) {
              partnerOptions = update(partnerOptions, {
                $push: [
                  {
                    value: item?.partnerID,
                    text: item?.partnerName,
                  },
                ],
              });
            }
          });
        });

        if (partnerOptions.length > 0) {
          tempFormElements = update(tempFormElements, {
            partnerName: { optionValues: { $set: [...partnerOptions] } },
          });

          const selected = partners.find(
            (item) => item?.partnerID === 'AIRSENSA'
          );
          if (selected) {
            tempFormElements = update(tempFormElements, {
              partnerName: { value: { $set: selected.partnerID } },
            });
          } else {
            tempFormElements = update(tempFormElements, {
              partnerName: { value: { $set: partnerOptions[0].value } },
            });
          }
        }
      }

      if (partnerRoles && partnerRoles.length > 0) {
        let userLevelOptionValues: SelectOptionsType[] = [];
        partnerRoles.forEach((item) => {
          userLevelOptionValues = update(userLevelOptionValues, {
            $push: [{ value: item, text: item }],
          });
        });

        tempFormElements = update(tempFormElements, {
          userLevel: {
            optionValues: { $set: [...userLevelOptionValues] },
            value: { $set: userLevelOptionValues[0].value },
          },
        });
      }

      this._isMounted &&
        this.setState({
          loadingPartner: false,
          initLat: tempFormElements.latitude.value,
          initLng: tempFormElements.longitude.value,
          formElements: tempFormElements,
          partnerList: partners || [],
        });
    }
  };

  inputChangedHandler = async (name: keyof FormElementsType, value: string) => {
    const { formElements } = this.state;

    let tempFormElements = { ...formElements };

    if (
      value &&
      (name === 'latitude' || name === 'longitude') &&
      !checkValidation(value, { isNegativeFloat: true })
    ) {
      message.error('Please enter valid Coordinates');
      return;
    }

    tempFormElements = update(tempFormElements, {
      [name]: {
        value: { $set: value },
        touched: { $set: true },
        valid: {
          $set: checkValidation(value, tempFormElements[name].validation),
        },
      },
    });

    if (value && name === 'partnerName') {
      try {
        const partnerRoles: string[] = await this.getPartnerRoles(
          tempFormElements.partnerName.value
        );

        let userLevelOptionValues: SelectOptionsType[] = [];
        partnerRoles.forEach((item) => {
          userLevelOptionValues = update(userLevelOptionValues, {
            $push: [{ value: item, text: item }],
          });
        });

        tempFormElements = update(tempFormElements, {
          userLevel: {
            optionValues: { $set: [...userLevelOptionValues] },
            value: { $set: userLevelOptionValues[0].value },
          },
        });
      } catch (error) {
        this._isMounted && handleNotification('error', error?.data);
      }
    }

    this._isMounted &&
      this.setState({
        formElements: tempFormElements,
        hasInputChanged: true,
      });
  };

  onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    this.handleSubmit();
  };

  handleSubmit = async (onConfirm?: () => void) => {
    const { formElements, isChecked } = this.state;
    const { userData, updateToken } = this.props;

    let tempFormElements = { ...formElements };

    let stateData: Partial<StateType> = {};

    let key: keyof typeof tempFormElements;

    for (key in tempFormElements) {
      tempFormElements = update(tempFormElements, {
        [key]: {
          touched: { $set: true },
          valid: {
            $set: checkValidation(
              tempFormElements[key].value,
              tempFormElements[key].validation
            ),
          },
        },
      });
    }

    this._isMounted && this.setState({ formElements: tempFormElements });

    for (key in tempFormElements) {
      if (!tempFormElements[key].valid) {
        message.error('Please fill all the fields');
        return;
      }
    }

    this._isMounted && this.setState({ loadingSubmit: true });
    const data = {
      user_name: tempFormElements.userName.value,
      user_email: tempFormElements.userEmail.value,
      user_phone: tempFormElements.userPhone.value,
      user_level: tempFormElements.userLevel.value,
      user_pwd_token: tempFormElements.tempPassword.value,
      allocate_new_token_on_login: isChecked,
      partner_id: tempFormElements.partnerName.value,
      lat: tempFormElements.latitude.value,
      lng: tempFormElements.longitude.value,
    };
    try {
      const formData = handleFormBody(data);
      const { url, method, contentType } = userApi.postUser();
      const response = await apiCall({
        storeToken: userData?.token,
        url,
        method,
        contentType,
        data: formData,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;

      updateToken(result);
      stateData = update(stateData, { loadingSubmit: { $set: false } });
      if (result.status === 'ok') {
        this._isMounted && handleNotification('success', result);
        let cb;
        if (result?.data) {
          stateData = update(stateData, { hasInputChanged: { $set: false } });
          cb = () => {
            const { history } = this.props;
            setTimeout(() => {
              history.push({
                pathname: userRoutes.details(),
                state: { userID: result?.data, allowEdit: true },
              });
            }, 1000);
          };
        }

        this.handleState({ ...stateData }, cb);
      } else {
        this._isMounted && handleNotification('error', result);
        this.handleState({ ...stateData });
      }
    } catch (error) {
      this._isMounted && handleNotification('error', error.data);
      this.handleState({ ...stateData, loadingSubmit: false });
    }
  };

  handlePopupConfirm = (onConfirm: () => void) => {
    this.handleSubmit(onConfirm);
  };

  handleCheckbox = (value: boolean) => {
    this._isMounted &&
      this.setState({
        isChecked: value,
      });
  };

  handlePeepIcon = () => {
    const { formElements } = this.state;

    let tempFormElements = { ...formElements };

    if (tempFormElements.tempPassword.elementConfig.type === 'password') {
      tempFormElements = update(tempFormElements, {
        tempPassword: { elementConfig: { type: { $set: 'text' } } },
      });
    } else {
      tempFormElements = update(tempFormElements, {
        tempPassword: { elementConfig: { type: { $set: 'password' } } },
      });
    }

    this._isMounted && this.setState({ formElements: tempFormElements });
  };

  handleRedirect = () => {
    const { history } = this.props;

    history.push({
      pathname: userRoutes.list,
    });
  };

  onOkayMapModal = () => {
    const { formElements, mapZoom } = this.state;

    this._isMounted &&
      this.setState((prevState) => {
        return {
          showMap: !prevState.showMap,
          initLat: formElements.latitude.value,
          initLng: formElements.longitude.value,
          initZoom: mapZoom,
        };
      });
  };

  handleMapModal = () => {
    const { formElements, initLat, initLng, initZoom } = this.state;
    const tempFormElements = update(formElements, {
      latitude: { value: { $set: initLat } },
      longitude: { value: { $set: initLng } },
    });

    this._isMounted &&
      this.setState((prevState) => {
        return {
          showMap: !prevState.showMap,
          formElements: tempFormElements,
          mapZoom: initZoom,
        };
      });
  };

  onMarkerMove = (lat: number, lng: number) => {
    const { formElements } = this.state;

    const tempFormElements = update(formElements, {
      latitude: { value: { $set: lat.toString() } },
      longitude: { value: { $set: lng.toString() } },
    });

    this._isMounted && this.setState({ formElements: tempFormElements });
  };

  onMapZoom = (zoom: number) => {
    this._isMounted && this.setState({ mapZoom: zoom });
  };

  render() {
    const {
      isChecked,
      formElements,
      showMap,
      mapZoom,
      loadingPartner,
      loadingSubmit,
      hasInputChanged,
    } = this.state;
    const { userPermissionList } = this.props;

    if (userPermissionList.length === 0) {
      return <Redirect to="/" />;
    }

    const single = getInputElementsSingle({
      formElements,
      inputChangedHandler: this.inputChangedHandler,
      sliceValue: [0, 4],
    });

    const multiple = getInputElementsMultiple({
      formElements,
      inputChangedHandler: this.inputChangedHandler,
      sliceValue: [4, 6],
      optionalElement: (
        <Fragment>
          <div className="col-xl-4 pb-3 pt-xl-2 pb-xl-0">
            <div className="pt-xl-1" />
            <Button
              type="primary"
              htmlType="button"
              onClick={this.handleMapModal}>
              MAP
            </Button>
          </div>
        </Fragment>
      ),
    });

    const single2 = getInputElementsSingle({
      formElements,
      inputChangedHandler: this.inputChangedHandler,
      sliceValue: [6, 8],
    });

    return (
      <Fragment>
        {hasInputChanged && (
          <PromptPopup
            when={hasInputChanged}
            handleConfirm={this.handlePopupConfirm}
          />
        )}
        <FormWrapper loading={loadingPartner || loadingSubmit} title="User Add">
          <form onSubmit={this.onFormSubmit} noValidate>
            {single}
            {multiple}
            {single2}
            <div className="row align-items-center my-3">
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <Checkbox
                  name={`checkbox`}
                  onChange={(event) =>
                    this.handleCheckbox(event.target.checked)
                  }
                  checked={isChecked}>
                  {`New Token on Login`}
                </Checkbox>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4">
                <Button type="primary" htmlType="submit" size="large" block>
                  Submit
                </Button>
              </div>

              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 btn-mobile-padding-top">
                <Button
                  type="primary"
                  htmlType="button"
                  size="large"
                  block
                  onClick={this.handleRedirect}>
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </FormWrapper>

        {showMap && (
          <Modal
            width={'60%'}
            visible={showMap}
            onCancel={this.handleMapModal}
            closable={false}
            onOk={this.onOkayMapModal}>
            <div className="row mt-4">
              <div className="col-md-12">
                <LeafletMap
                  onMapClickMarkerMove={this.onMarkerMove}
                  onMapZoom={this.onMapZoom}
                  lat={parseFloat(formElements.latitude.value)}
                  lng={parseFloat(formElements.longitude.value)}
                  zoom={mapZoom}
                  height={'50vh'}
                />
              </div>
            </div>
          </Modal>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state: ReduxStateType) {
  return {
    userRightList: state.auth.userRightList,
  };
}

const mapDispatch = {
  updateToken,
};

const connector = connect(mapStateToProps, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UserAdd);
