import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import App from './App';
import reduxStore from './redux/store';

import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'antd/dist/antd.css';
import './styles/index.scss';
import './styles/margin.scss';

ReactDOM.render(
  <Provider store={reduxStore.store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
