import { Component, FormEvent, Fragment } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import { Button, Col, message, Modal, Row, Tabs } from 'antd';

import {
  StateType,
  detailsTabElements,
  deviceTabElements,
  addressElements,
  groupsTabElements,
  FormElementsType,
  LocationGroupsType,
  sponsorshipTabElements,
  copyrightTabElements,
  journalTabElements,
  AttributesCollectionType,
} from '../components/LocationDetails/helpers';
import { updateToken } from '../redux/actions';
import {
  ApiErrorType,
  GroupListType,
  LocationDetailsType,
  UserDataType,
} from '../type-definitions/api-types';
import axios from 'axios';
import { locationRights } from '../utils/permission-list';
import { apiCall } from '../api-services/api';
import { locationApi } from '../api-services/api-list';
import { deviceRoutes, locationRoutes } from '../Routes/routes-list';
import { DefaultObjectType, SelectOptionsType } from '../type-definitions';
import { checkValidation } from '../utils/validation';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import DetailsTab from '../components/LocationDetails/DetailsTab';
import AddressTab from '../components/LocationDetails/AddressTab';
import DeviceTab from '../components/LocationDetails/DeviceTab';
import AddAssignModal from '../components/LocationDetails/AddAssignModal';
import MoveModal from '../components/LocationDetails/MoveModal';
import SwapModal from '../components/LocationDetails/SwapModal';
import DevicesTab from '../components/LocationDetails/DeivcesTab';
import PromptPopup from '../components/PromptPopup';
import FormWrapper from '../components/FormWrapper';
import LeafletMap from '../components-shared/LeafletMap';
import GroupsTab from '../components/LocationDetails/GroupsTab';
import { v4 } from 'uuid';
import { handleNotification } from '../utils/notification-handler';
import JournalTab from '../components/LocationDetails/JournalTab';
import SponsorshipTab from '../components/LocationDetails/SponsorshipTab';
import CopyrightTab from '../components/LocationDetails/CopyrightTab';
import AttributesTab from '../components/LocationDetails/AttributesTab';
import { createDynamicFormInput, updateImmutably } from '../shared/helpers';

interface PropsType
  extends RouteComponentProps<
      {},
      any,
      {
        locationID?: string;
        deviceID?: string;
        hasStock?: boolean;
        allowAllEdit?: boolean;
        allowNoteEdit?: boolean;
      }
    >,
    PropsFromRedux {
  userData: Partial<UserDataType>;
  userPermissionList: string[];
}

const initFormElements = {
  ...detailsTabElements,
  ...deviceTabElements,
  ...addressElements,
  ...groupsTabElements,
  ...sponsorshipTabElements,
  ...copyrightTabElements,
  ...journalTabElements,
};

class LocationDetails extends Component<PropsType, StateType> {
  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  constructor(props: PropsType) {
    super(props);
    const { location } = props;
    this.state = {
      formElements: cloneDeep(initFormElements),
      showStatus: false,
      showMap: false,
      mapZoom: 6,
      loading: true,
      isRightsSet: false,
      locationGroupList: [],
      defaultGroupsChecked: true,
      hasInputChanged: false,
      previousLocationDetails: {},
      currentTabIndex: location?.state?.hasStock ? '2' : '1',
      showSwapModal: false,
      showMoveModal: false,
      showAddAssignModal: false,
      swapSelectedDeviceID: '',
      moveSelectedDeviceID: '',
      deviceModalStatus: '',
      initLat: '0',
      initLng: '0',
      initZoom: 6,

      allowAllEdit: location?.state?.allowAllEdit,
      allowNoteEdit: location?.state?.allowNoteEdit,
      hasStock: location?.state?.hasStock,
      locationID: location?.state?.locationID ?? '',

      locationDetails: {},
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.axiosCancelSource = axios.CancelToken.source();
    const { locationID } = this.state;

    if (locationID) {
      this.handleFetchData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  fetchData = async ({ locationID }: { locationID: string }) => {
    const { userData, updateToken } = this.props;
    try {
      const details = locationApi.getLocationDetails(null, { locationID });
      const groups = locationApi.getLocationGroups(null, { locationID });
      const response = await apiCall({
        storeToken: userData?.token,
        url: details.url,
        method: details.method,
        contentType: details.contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      const response2 = await apiCall({
        storeToken: userData?.token,
        url: groups.url,
        method: groups.method,
        contentType: groups.contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result2 = response2?.data;
      updateToken(result);
      updateToken(result2);
      if (result.status === 'ok' || result2.status === 'ok') {
        return {
          details: result.data || {},
          locationGroups: result2.data,
        };
      } else {
        return { error: result || result2 };
      }
    } catch (error) {
      return { error };
    }
  };

  handleFetchData = async () => {
    let { formElements, allowAllEdit, allowNoteEdit, locationID, hasStock } =
      this.state;
    const {
      error,
      details,
      locationGroups,
    }: {
      error?: ApiErrorType;
      details?: LocationDetailsType;
      locationGroups?: LocationGroupsType[];
    } = await this.fetchData({
      locationID,
    });
    if (error) {
      this._isMounted && handleNotification('error', error);
      this._isMounted && this.setState({ loading: false });
    } else {
      let tempFormElements = { ...formElements };
      let tempAttributesCollection: AttributesCollectionType[] = [];

      if (allowAllEdit === undefined) {
        allowAllEdit = details?.rights?.includes(locationRights.edit) ?? false;
      }

      if (allowNoteEdit === undefined) {
        allowNoteEdit =
          details?.rights?.includes(locationRights.addNote) ?? false;
      }

      if (hasStock === undefined) {
        hasStock = details?.status === 'STOCK' ?? false;
      }

      tempFormElements = this.handleRights({ allowAllEdit, allowNoteEdit });

      let statusOptionValues: SelectOptionsType[] = [];
      if (!details?.status || details?.status === 'NEW') {
        statusOptionValues = updateImmutably(statusOptionValues, {
          $push: [
            { value: 'STOCK', text: 'STOCK' },
            { value: 'LIVE', text: 'LIVE' },
            { value: 'OFFLINE', text: 'OFFLINE' },
          ],
        });
      } else {
        if (details?.status === 'STOCK') {
          tempFormElements = updateImmutably(tempFormElements, {
            status: {
              disabled: { $set: true },
              elementType: { $set: 'input' },
            },
          });
        } else {
          statusOptionValues = updateImmutably(statusOptionValues, {
            $push: [
              { value: 'LIVE', text: 'LIVE' },
              { value: 'OFFLINE', text: 'OFFLINE' },
            ],
          });
        }
      }

      tempFormElements = updateImmutably(tempFormElements, {
        status: {
          optionValues: { $set: statusOptionValues ?? [] },
          value: { $set: details?.status ?? '' },
        },
      });

      if (details && Object.keys(details).length > 0) {
        tempFormElements = updateImmutably(tempFormElements, {
          locationID: {
            value: { $set: details.locationID ?? '' },
          },
          locationMac: {
            value: { $set: details.locationMac ?? '' },
          },
          partnerName: {
            value: { $set: details.partnerID ?? '' },
          },
          latitude: {
            value: {
              $set: details.locationLat?.toString() ?? '0',
            },
          },
          longitude: {
            value: { $set: details.locationLng?.toString() ?? '0' },
          },
          locationName: {
            value: { $set: details.locationName ?? '' },
          },
          friendlyName: {
            value: { $set: details.friendlyName ?? '' },
          },
          friendlyDesc: {
            value: { $set: details.friendlyDescription ?? '' },
          },
          height: {
            value: { $set: details.height?.toString() ?? '' },
          },
          level: {
            value: { $set: details.level?.toString() ?? '' },
          },
          capacity: {
            value: { $set: details.capacity?.toString() ?? '' },
          },
          staticCheck: {
            $set: details?.static ?? false,
          },
          internalCheck: { $set: details?.internalflag ?? false },
          sponsorHTML: { value: { $set: details.sponsorHTML ?? '' } },
          copyright: {
            value: { $set: details.copyright ?? '' },
            disabled: { $set: details.defaultCopyright ?? false },
          },
          defaultCopyright: {
            $set: details.defaultCopyright ?? false,
          },
        });

        if (details?.address) {
          tempFormElements = updateImmutably(tempFormElements, {
            street: {
              value: { $set: details.address.street ?? '' },
            },
            street2: {
              value: { $set: details.address.street2 ?? '' },
            },
            city: {
              value: { $set: details.address.city ?? '' },
            },
            province: {
              value: { $set: details.address.province ?? '' },
            },
            postalCode: {
              value: { $set: details.address.postalcode ?? '' },
            },
            country: {
              value: { $set: details.address.country ?? '' },
            },
          });
        }

        if (details.attributes && Object.keys(details.attributes).length > 0) {
          for (const key in details.attributes) {
            let attributeName = createDynamicFormInput(
              'attributeName',
              'input'
            );
            let attributeValue = createDynamicFormInput(
              'attributeValue',
              'input'
            );
            attributeName = updateImmutably(attributeName, {
              elementConfig: {
                placeholder: { $set: 'Key' },
              },
              label: { $set: 'Key' },
              value: { $set: key },
            });
            attributeValue = updateImmutably(attributeValue, {
              elementConfig: {
                placeholder: { $set: 'Value' },
              },
              label: { $set: 'Value' },
              value: { $set: details.attributes[key] },
            });

            tempAttributesCollection = updateImmutably(
              tempAttributesCollection,
              {
                $push: [{ attributeName, attributeValue, uuid: v4() }],
              }
            );
          }
        }
      }

      let locationGroupData: GroupListType[] = [];
      if (locationGroups && locationGroups.length > 0) {
        locationGroupData = locationGroups.map((item) => {
          return {
            groupID: item.group_id,
            groupName: item.group_name,
            partnerID: item.partner_id,
            groupType: item.grouptype,
            rights: item.rights,
            uuid: v4(),
          };
        });

        tempFormElements = updateImmutably(tempFormElements, {
          locationGroupInput: {
            value: {
              $set: locationGroupData
                .map((item) => ` ${item.groupID}`)
                .join(', '),
            },
          },
        });
      }

      this._isMounted &&
        this.setState({
          loading: false,
          defaultGroupsChecked:
            details?.defaultGroups !== undefined ? details.defaultGroups : true,
          previousLocationDetails: details ?? {},
          locationGroupList: locationGroupData,
          formElements: tempFormElements,
          initLat: tempFormElements.latitude.value,
          initLng: tempFormElements.longitude.value,
          allowAllEdit,
          allowNoteEdit,
          hasStock,
          locationDetails: details ?? {},
          attributesCollection: tempAttributesCollection,
        });
    }
  };

  handleRights = ({
    allowAllEdit,
    allowNoteEdit,
  }: {
    allowAllEdit: boolean;
    allowNoteEdit: boolean;
  }) => {
    const { formElements } = this.state;
    let tempFormElements = { ...formElements };

    if (allowAllEdit) {
      let key: keyof typeof tempFormElements;
      for (key in tempFormElements) {
        if (
          key !== 'defaultCopyright' &&
          key !== 'staticCheck' &&
          key !== 'internalCheck' &&
          tempFormElements[key].disabled !== true
        ) {
          tempFormElements = updateImmutably(tempFormElements, {
            [key]: { disabled: { $set: false } },
          });
        }
      }
    } else if (!allowAllEdit) {
      if (!allowNoteEdit) {
        let key: keyof typeof tempFormElements;
        for (key in tempFormElements) {
          if (
            key !== 'defaultCopyright' &&
            key !== 'staticCheck' &&
            key !== 'internalCheck'
          ) {
            tempFormElements = updateImmutably(tempFormElements, {
              [key]: { disabled: { $set: true } },
            });
          }
        }
      } else if (allowNoteEdit) {
        let key: keyof typeof tempFormElements;
        for (key in tempFormElements) {
          if (
            key !== 'defaultCopyright' &&
            key !== 'staticCheck' &&
            key !== 'internalCheck'
          ) {
            tempFormElements = updateImmutably(tempFormElements, {
              [key]: { disabled: { $set: true } },
            });
          }
        }
      }
    }

    return tempFormElements;
  };

  inputChangedHandler = (name: keyof FormElementsType, value: string) => {
    const { formElements } = this.state;

    let tempFormElements = { ...formElements };
    let hasInputChanged = false;
    if (name) {
      if (
        value &&
        (name === 'latitude' || name === 'longitude' || name === 'height') &&
        !checkValidation(value, { isNegativeFloat: true })
      ) {
        message.warning('Enter valid input!!');
        return;
      }

      if (
        value &&
        name === 'capacity' &&
        !checkValidation(value, { isNumeric: true })
      ) {
        message.warning('Please enter valid input');
        return;
      }

      if (
        name !== 'defaultCopyright' &&
        name !== 'staticCheck' &&
        name !== 'internalCheck'
      ) {
        tempFormElements = updateImmutably(tempFormElements, {
          [name]: {
            value: { $set: value },
            touched: { $set: true },
            valid: {
              $set: checkValidation(value, tempFormElements[name].validation),
            },
          },
        });
      }
      if (name !== 'journalNote') {
        hasInputChanged = true;
      }

      this._isMounted &&
        this.setState({
          formElements: tempFormElements,
          hasInputChanged,
        });
    }
  };

  onFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    this.handleUpdateLocation();
  };

  handleUpdateLocation = async (onConfirm?: () => void) => {
    const {
      formElements,
      defaultGroupsChecked,
      hasStock,
      attributesCollection,
    } = this.state;
    const { userData, updateToken } = this.props;

    let tempFormElements = { ...formElements };

    let key: keyof typeof tempFormElements;

    for (key in formElements) {
      if (
        key !== 'defaultCopyright' &&
        key !== 'staticCheck' &&
        key !== 'internalCheck'
      ) {
        tempFormElements = updateImmutably(tempFormElements, {
          [key]: {
            touched: { $set: true },
            valid: {
              $set: checkValidation(
                tempFormElements[key].value,
                tempFormElements[key].validation
              ),
            },
          },
        });
      }
    }

    this._isMounted && this.setState({ formElements: tempFormElements });

    for (key in tempFormElements) {
      if (
        key !== 'defaultCopyright' &&
        key !== 'staticCheck' &&
        key !== 'internalCheck' &&
        !tempFormElements[key].valid
      ) {
        return;
      }
    }

    const dataProps: Partial<{
      capacity: string;
      sponsorHTML: string;
      copyright: string;
      defaultCopyright: boolean;
      attributes: DefaultObjectType;
    }> = {};

    if (hasStock) {
      dataProps.capacity = tempFormElements.capacity.value;
    } else {
      dataProps.sponsorHTML = tempFormElements.sponsorHTML.value;
      dataProps.copyright = !tempFormElements.defaultCopyright
        ? tempFormElements.copyright.value
        : '';
      dataProps.defaultCopyright = tempFormElements.defaultCopyright;
    }

    if (attributesCollection && attributesCollection.length > 0) {
      dataProps.attributes = {};
      attributesCollection.forEach((el) => {
        if (dataProps.attributes) {
          dataProps.attributes[el.attributeName.value] =
            el.attributeValue.value;
        }
      });
    }

    const data = {
      location_name: tempFormElements.locationName.value,
      status: tempFormElements.status.value,
      static: tempFormElements.staticCheck,
      friendly_name: tempFormElements.friendlyName.value,
      friendly_description: tempFormElements.friendlyDesc.value,
      location_lat: tempFormElements.latitude.value,
      location_lng: tempFormElements.longitude.value,
      height: tempFormElements.height.value,
      internalflag: tempFormElements.internalCheck,
      address: {
        street: tempFormElements.street.value,
        street2: tempFormElements.street2.value,
        city: tempFormElements.city.value,
        province: tempFormElements.province.value,
        postalcode: tempFormElements.postalCode.value,
        country: tempFormElements.country.value,
      },
      defaultGroups: defaultGroupsChecked,
      ...dataProps,
    };

    let tempData = [];
    let key2: keyof typeof data;
    for (key2 in data) {
      const val: any = data[key2];
      const encodedValue = encodeURIComponent(val);
      // if (typeof data[key2] === 'object' || key2 === 'groupid') {
      if (typeof data[key2] === 'object') {
        tempData.push(key2 + '=' + JSON.stringify(data[key2]));
      } else {
        tempData.push(key2 + '=' + encodedValue);
      }
    }

    const formData: string = tempData.join('&');

    this._isMounted && this.setState({ loading: true });
    try {
      const { url, method, contentType } = locationApi.putLocation(null, {
        locationID: tempFormElements.locationID.value,
      });
      const response = await apiCall({
        storeToken: userData?.token,
        url,
        method,
        contentType,
        data: formData,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      updateToken(result);
      if (result.status === 'ok') {
        this._isMounted && handleNotification('success', result);
        this._isMounted &&
          this.setState(
            {
              loading: false,
              hasInputChanged: false,
            },
            () => onConfirm?.()
          );
      } else {
        this._isMounted && handleNotification('error', result);
        this._isMounted && this.setState({ loading: false });
      }
    } catch (error: any) {
      this._isMounted && handleNotification('error', error?.data);
      this._isMounted && this.setState({ loading: false });
    }
  };

  handleCheckbox = <T extends keyof StateType>(event: CheckboxChangeEvent) => {
    const name = event.target.name;
    const checked = event.target.checked;
    if (name) {
      const stateData = {
        [name]: checked,
      };
      this._isMounted && this.setState(stateData as { [P in T]: StateType[P] });
    }
  };

  handleCheckboxFormElements = (event: CheckboxChangeEvent) => {
    const name = event.target.name;
    const checked = event.target.checked;
    const { formElements } = this.state;
    let tempFormElements = { ...formElements };
    if (name) {
      tempFormElements = updateImmutably(tempFormElements, {
        [name]: {
          $set: checked,
        },
      });
      if (name === 'defaultCopyright') {
        tempFormElements = updateImmutably(tempFormElements, {
          copyright: { disabled: { $set: checked } },
        });
      }

      this._isMounted && this.setState({ formElements: tempFormElements });
    }
  };

  onOkayMapModal = () => {
    const { formElements, mapZoom } = this.state;

    this._isMounted &&
      this.setState((prevState) => {
        return {
          ...prevState,
          showMap: !prevState.showMap,
          initLat: formElements.latitude.value,
          initLng: formElements.longitude.value,
          initZoom: mapZoom,
          hasInputChanged: true,
        };
      });
  };

  onCancelMapModal = () => {
    const { formElements, initLat, initLng, initZoom } = this.state;

    const tempFormElements = updateImmutably(formElements, {
      latitude: { value: { $set: initLat } },
      longitude: { value: { $set: initLng } },
    });

    this._isMounted &&
      this.setState((prevState) => {
        return {
          ...prevState,
          showMap: !prevState.showMap,
          formElements: tempFormElements,
          mapZoom: initZoom,
        };
      });
  };

  onMarkerMove = (lat: number, lng: number) => {
    const { formElements } = this.state;
    const tempFormElements = updateImmutably(formElements, {
      latitude: { value: { $set: lat.toString() } },
      longitude: { value: { $set: lng.toString() } },
    });

    this._isMounted && this.setState({ formElements: tempFormElements });
  };

  onMapZoom = (zoom: number) => {
    this._isMounted && this.setState({ mapZoom: zoom });
  };

  handleRedirect = () => {
    const { history } = this.props;
    history.push({
      pathname: locationRoutes.list,
    });
  };

  handlePopupConfirm = (onConfirm: () => void) => {
    this.handleUpdateLocation(onConfirm);
  };

  onOkayLocationGroupsTab = (locationGroups: GroupListType[]) => {
    // const { formElements } = this.state;
    // formElements.locationGroupInput.value = locationGroups
    //   .map((item) => ` ${item.groupID}`)
    //   .join(', ');

    this.setState({
      // formElements,
      locationGroupList: locationGroups,
    });
  };

  openSwapModal = (id: string = '') => {
    this._isMounted &&
      this.setState({
        showSwapModal: true,
        swapSelectedDeviceID: id,
        deviceModalStatus: '',
      });
  };

  openMoveModal = (id: string = '') => {
    this._isMounted &&
      this.setState({
        showMoveModal: true,
        moveSelectedDeviceID: id,
        deviceModalStatus: '',
      });
  };

  openAddAssignModal = () => {
    this._isMounted &&
      this.setState({
        showAddAssignModal: true,
        deviceModalStatus: '',
      });
  };

  onCloseModal = (id?: string) => {
    const { formElements } = this.state;
    let tempFormElements = { ...formElements };
    if (id) {
      tempFormElements = updateImmutably(tempFormElements, {
        locationMac: { value: { $set: id } },
      });
    }
    this._isMounted &&
      this.setState(
        {
          showSwapModal: false,
          swapSelectedDeviceID: '',
          showMoveModal: false,
          moveSelectedDeviceID: '',
          showAddAssignModal: false,
          deviceModalStatus: 'closed',
          formElements: tempFormElements,
        },
        () => {
          if (id) {
            this.handleFetchData();
          }
        }
      );
  };

  getTabData = () => {
    const {
      formElements,
      defaultGroupsChecked,
      locationGroupList,
      deviceModalStatus,
      allowAllEdit,
      allowNoteEdit,
      hasStock,
      locationID,
    } = this.state;
    const { userData } = this.props;

    let isStock = hasStock;

    let showOnlyNotes = false;
    if (allowNoteEdit && !allowAllEdit) {
      showOnlyNotes = true;
    }

    let isAddAssignDevice = false;
    let isSwapDevice = false;
    let isMoveDevice = false;
    if (formElements?.status?.value === 'LIVE') {
      if (formElements?.locationMac?.value === 'UNALLOCATED') {
        isAddAssignDevice = true;
      } else {
        isSwapDevice = true;
      }
    } else if (
      formElements?.status?.value !== 'LIVE' &&
      formElements?.status?.value !== 'STOCK'
    ) {
      if (formElements?.locationMac?.value === 'UNALLOCATED') {
        isAddAssignDevice = true;
      } else {
        isSwapDevice = true;
        isMoveDevice = true;
      }
    }

    if (formElements.status.value === 'STOCK') {
      isStock = true;
    }

    const detailsElements = (
      <DetailsTab
        hasStock={isStock}
        allowAllEdit={allowAllEdit}
        formElements={formElements}
        inputChangedHandler={this.inputChangedHandler}
        onCancelMapModal={this.onCancelMapModal}
        handleCheckboxFormElements={this.handleCheckboxFormElements}
      />
    );

    const addressElements = (
      <AddressTab
        formElements={formElements}
        inputChangedHandler={this.inputChangedHandler}
      />
    );

    const groupsTab = (
      <GroupsTab
        defaultGroupsChecked={defaultGroupsChecked}
        formElements={formElements}
        inputChangedHandler={this.inputChangedHandler}
        handleCheckbox={this.handleCheckbox}
        setCurrentTabIndex={this.setCurrentTabIndex}
        partnerID={formElements.partnerName.value}
        locationGroupList={locationGroupList}
        onOkayLocationGroupsTab={this.onOkayLocationGroupsTab}
      />
    );

    const devicesElements = (
      <DevicesTab
        locationID={locationID}
        userData={userData}
        openSwapModal={this.openSwapModal}
        openMoveModal={this.openMoveModal}
        openAddAssignModal={this.openAddAssignModal}
        deviceModalStatus={deviceModalStatus}
        handleRedirectToDeviceDetails={this.handleRedirectToDeviceDetails}
      />
    );

    const deviceElements = (
      <DeviceTab
        formElements={formElements}
        inputChangedHandler={this.inputChangedHandler}
        isAddAssignDevice={isAddAssignDevice}
        isSwapDevice={isSwapDevice}
        isMoveDevice={isMoveDevice}
        openSwapModal={this.openSwapModal}
        openMoveModal={this.openMoveModal}
        openAddAssignModal={this.openAddAssignModal}
        handleRedirectToDeviceDetails={this.handleRedirectToDeviceDetails}
      />
    );

    return {
      detailsElements,
      addressElements,
      devicesElements,
      deviceElements,
      groupsTab,
      permissions: { showOnlyNotes, hasStock },
    };
  };

  setCurrentTabIndex = (index: string) => {
    this._isMounted && this.setState({ currentTabIndex: index });
  };

  handleRedirectToDeviceDetails = (deviceID: string) => {
    const { history } = this.props;

    history.push({
      pathname: deviceRoutes.details(),
      state: { deviceID },
    });
  };

  onAttributesUpdate = ({
    data,
    isDelete,
    isEdit,
  }: {
    data: AttributesCollectionType;
    isDelete?: boolean;
    isEdit?: boolean;
  }) => {
    const { attributesCollection } = this.state;

    let temp = attributesCollection ? [...attributesCollection] : [];

    if (isDelete && temp && temp.length > 0) {
      temp = temp?.filter((el) => el.uuid !== data.uuid);
    } else if (temp && temp.length > 0) {
      if (isEdit) {
        temp = temp.map((el) => {
          if (el.uuid === data.uuid) {
            el = updateImmutably(el, {
              attributeName: { $set: data.attributeName },
              attributeValue: { $set: data.attributeValue },
            });
          }
          return { ...el };
        });
      } else {
        temp = updateImmutably(temp, {
          $push: [data],
        });
      }
    } else if (temp.length === 0) {
      temp = updateImmutably(temp, {
        $push: [data],
      });
    }

    this.setState({ attributesCollection: temp });
  };

  render() {
    const {
      showAddAssignModal,
      showMoveModal,
      showSwapModal,
      moveSelectedDeviceID,
      swapSelectedDeviceID,
      hasInputChanged,
      loading,
      currentTabIndex,
      showMap,
      mapZoom,
      formElements,
      allowAllEdit,
      allowNoteEdit,
      locationDetails,
      hasStock,
      attributesCollection,
    } = this.state;
    const { userData, location, userPermissionList } = this.props;
    const locationID = location.state.locationID;

    if (!locationID) {
      return <Redirect to={locationRoutes.list} />;
    }

    if (userPermissionList.length === 0) {
      return <Redirect to="/" />;
    }

    const {
      detailsElements,
      addressElements,
      devicesElements,
      deviceElements,
      permissions,
      groupsTab,
    } = this.getTabData();

    return (
      <Fragment>
        {hasInputChanged && (
          <PromptPopup
            when={hasInputChanged}
            handleConfirm={this.handlePopupConfirm}
          />
        )}

        <FormWrapper
          loading={loading}
          title={
            allowAllEdit || allowNoteEdit ? 'Location Edit' : 'Location View'
          }>
          <form onSubmit={this.onFormSubmit} noValidate>
            <Tabs
              activeKey={currentTabIndex}
              onTabClick={this.setCurrentTabIndex}>
              <Fragment>
                <Tabs.TabPane key="1" tab="Details">
                  {detailsElements}
                </Tabs.TabPane>
                <Tabs.TabPane
                  key="2"
                  tab={permissions.hasStock ? 'Devices' : 'Device'}>
                  {permissions.hasStock ? devicesElements : deviceElements}
                </Tabs.TabPane>
                <Tabs.TabPane key="3" tab="Address">
                  {addressElements}
                </Tabs.TabPane>
                <Tabs.TabPane
                  key="4"
                  tab="Groups"
                  disabled={
                    !locationDetails?.rights?.includes(
                      locationRights.editLocationGroups
                    )
                  }>
                  {groupsTab}
                </Tabs.TabPane>
                <Tabs.TabPane key="5" tab="Journal">
                  <JournalTab
                    locationID={locationID}
                    journalNote={formElements.journalNote}
                    inputChangedHandler={this.inputChangedHandler}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane key="6" tab="Attributes">
                  <AttributesTab
                    attributesCollection={attributesCollection}
                    onAttributesUpdate={this.onAttributesUpdate}
                  />
                </Tabs.TabPane>
                {!hasStock && (
                  <Fragment>
                    <Tabs.TabPane key="7" tab="Sponsorship">
                      <SponsorshipTab
                        formElements={formElements}
                        inputChangedHandler={this.inputChangedHandler}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane key="8" tab="Copyright">
                      <CopyrightTab
                        formElements={formElements}
                        inputChangedHandler={this.inputChangedHandler}
                        handleCheckboxFormElements={
                          this.handleCheckboxFormElements
                        }
                      />
                    </Tabs.TabPane>
                  </Fragment>
                )}
              </Fragment>
            </Tabs>

            {currentTabIndex !== '2' &&
              currentTabIndex !== '5' &&
              currentTabIndex !== '6' && (
                <Fragment>
                  <Row>
                    {(allowAllEdit || allowNoteEdit) && (
                      <Col xs={24} sm={12} className="pr-sm-2">
                        <Button
                          type="primary"
                          htmlType="submit"
                          size="large"
                          block>
                          Submit
                        </Button>
                      </Col>
                    )}
                    <Col xs={24} sm={12} className="pt-3 pt-sm-0 pl-sm-2">
                      <Button
                        type="primary"
                        htmlType="button"
                        size="large"
                        block
                        onClick={this.handleRedirect}>
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Fragment>
              )}
          </form>
        </FormWrapper>

        {showMap && (
          <Modal
            width={'60%'}
            visible={showMap}
            onCancel={this.onCancelMapModal}
            closable={false}
            onOk={this.onOkayMapModal}>
            <Row>
              <Col xs={24}>
                <LeafletMap
                  onMapClickMarkerMove={this.onMarkerMove}
                  onMapZoom={this.onMapZoom}
                  lat={parseFloat(formElements.latitude.value)}
                  lng={parseFloat(formElements.longitude.value)}
                  zoom={mapZoom}
                  height={'50vh'}
                />
              </Col>
            </Row>
          </Modal>
        )}

        {showAddAssignModal && (
          <AddAssignModal
            userData={userData}
            showModal={showAddAssignModal}
            closeModal={this.onCloseModal}
            locationID={locationID}
          />
        )}
        {showMoveModal && (
          <MoveModal
            userData={userData}
            showModal={showMoveModal}
            closeModal={this.onCloseModal}
            deviceID={moveSelectedDeviceID}
          />
        )}
        {showSwapModal && (
          <SwapModal
            userData={userData}
            showModal={showSwapModal}
            closeModal={this.onCloseModal}
            deviceID={swapSelectedDeviceID}
          />
        )}
      </Fragment>
    );
  }
}

const mapDispatch = {
  updateToken: updateToken,
};
const connector = connect(null, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(LocationDetails);
