import axios from 'axios';
import { Fragment, Component, ReactNode } from 'react';
import update from 'immutability-helper';

import { assetApi, deviceApi, floorPlanApi } from '../../api-services/api-list';
// import { handleNotification } from '../../utils/notification-handler';

import {
  PropsType,
  StateType,
} from '../components/WebViewFloorPlanAssignTo/helpers';
import { webViewApiCall } from '../services/api-call';
import { FloorPlanLocationListType } from '../../type-definitions/api-types';
import { v4 } from 'uuid';
import { Button, Card, Col, Row } from 'antd';
import FloorPlanMapMultiple from '../shared-components/FloorPlanMapMultiple';

import '../styles/webView.scss';
import { Marker, Popup } from 'react-leaflet';
import { getLeafletIcon } from '../../utils/leaflet-helpers';

const nativeWindow: any = window;

class WebViewFloorPlanAssignTo extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    const { match, location } = props;

    let isEditable = false;
    const tempRight = match?.params?.isEditable;
    if (tempRight) {
      isEditable = JSON.parse(tempRight);
    }

    const search = location?.search;
    let assetID: string | undefined;
    let mapWidth: number | undefined;
    let mapHeight: number | undefined;
    let userLat: number | undefined;
    let userLng: number | undefined;
    let deviceID: string | undefined;

    if (search) {
      const params = new URLSearchParams(search);
      const tempAssetID = params.get('assetid');
      const tempDeviceID = params.get('deviceid');
      const tempWidth = params.get('mapwidth');
      const tempHeight = params.get('mapheight');
      const tempLat = params.get('lat');
      const tempLng = params.get('lng');

      if (tempAssetID) {
        assetID = tempAssetID;
      }
      if (tempDeviceID) {
        deviceID = tempDeviceID;
      }
      if (tempWidth) {
        mapWidth = JSON.parse(tempWidth);
      }
      if (tempHeight) {
        mapHeight = JSON.parse(tempHeight);
      }
      if (tempLat) {
        userLat = JSON.parse(tempLat);
      }
      if (tempLng) {
        userLng = JSON.parse(tempLng);
      }
    }

    this.state = {
      loading: true,
      mapDetails: { width: mapWidth, height: mapHeight },
      floorPlanLocationList: [],
      selectedLocationDetails: {},

      token: match?.params?.token,
      floorPlanID: match?.params?.floorPlanID,
      isEditable,
      deviceID,

      assetID,
      userLat,
      userLng,
    };
  }

  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    this._isMounted = true;

    const { match } = this.props;
    const floorPlanID = match?.params?.floorPlanID;
    if (floorPlanID) {
      this.handleFetchedData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  handleState = (data: Partial<StateType>) => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          ...prevState,
          ...data,
        };
      });
  };

  handleFetchedData = async () => {
    const { floorPlanID, token, assetID, mapDetails } = this.state;
    let stateData: Partial<StateType> = {
      ...this.state,
    };

    if (
      floorPlanID &&
      token &&
      assetID &&
      mapDetails.width &&
      mapDetails.height
    ) {
      try {
        const { url, method, contentType } = assetApi.getAsset(undefined, {
          assetID,
        });
        const response = await webViewApiCall({
          token,
          url,
          method,
          contentType,
          cancelToken: this.axiosCancelSource.token,
        });

        const floorPlanImage = response?.data;
        let apiResponseContentType = '';
        if (response?.headers) {
          apiResponseContentType = response?.headers?.['content-type'];
        }

        if (floorPlanImage) {
          stateData = update(stateData, {
            mapDetails: {
              image: {
                $set:
                  apiResponseContentType === 'image/x-icon'
                    ? ''
                    : floorPlanImage || '',
              },
            },
          });
        }
      } catch (error) {
        nativeWindow?.ReactNativeWebView?.postMessage?.('error');
        // this._isMounted && handleNotification('error', error.data);
      }

      try {
        const { url, method, contentType } = floorPlanApi.getFloorPlanLocations(
          undefined,
          {
            floorPlanID,
          }
        );

        const response = await webViewApiCall({
          token,
          url,
          method,
          contentType,
          cancelToken: this.axiosCancelSource.token,
        });

        const result = response?.data;
        if (result?.status === 'ok') {
          if (result.data && result.data.length > 0) {
            const tempFloorPlans: FloorPlanLocationListType[] = result.data.map(
              (item: FloorPlanLocationListType) => ({
                ...item,
                uuid: v4(),
              })
            );

            stateData = update(stateData, {
              floorPlanLocationList: { $set: tempFloorPlans },
            });
          }
        } else {
          nativeWindow?.ReactNativeWebView?.postMessage?.('error');
          // this._isMounted && handleNotification('error', result);
        }
      } catch (error) {
        nativeWindow?.ReactNativeWebView?.postMessage?.('error');
        // this._isMounted && handleNotification('error', error.data);
      }
    }

    stateData = update(stateData, { loading: { $set: false } });

    this.handleState({ ...stateData });
  };

  onMarkerButtonClick = async () => {
    const { token, deviceID, selectedLocationDetails } = this.state;

    if (deviceID && selectedLocationDetails.locationID && token) {
      try {
        const { url, method, contentType } = deviceApi.putAssignToLocation(
          undefined,
          {
            deviceID,
            locationID: selectedLocationDetails.locationID,
          }
        );
        const response = await webViewApiCall({
          token,
          url,
          method,
          contentType,
          cancelToken: this.axiosCancelSource.token,
        });

        const result = response?.data;

        if (result?.status === 'ok') {
          nativeWindow?.ReactNativeWebView?.postMessage?.('success');
          // this._isMounted && handleNotification('success', result);
        } else {
          nativeWindow?.ReactNativeWebView?.postMessage?.('error');
          // this._isMounted && handleNotification('error', result);
        }
      } catch (error) {
        nativeWindow?.ReactNativeWebView?.postMessage?.('error');
        // this._isMounted && handleNotification('error', error.data);
      }
    }
  };

  onMarkerPopupOpen = (locationID?: string) => {
    const { floorPlanLocationList } = this.state;
    if (locationID && floorPlanLocationList.length > 0) {
      const tempSelected = floorPlanLocationList.find(
        (el) => el.locationID === locationID
      );

      if (tempSelected) {
        this.handleState({ selectedLocationDetails: tempSelected });
      }
    }
  };

  onMapClick = () => {
    this.handleState({ selectedLocationDetails: {} });
  };

  render() {
    const {
      mapDetails,
      floorPlanLocationList,
      isEditable,
      loading,
      selectedLocationDetails,
    } = this.state;

    return (
      <Fragment>
        <Row align="middle" style={{ width: '100vw', height: '100vh' }}>
          <Col>
            <Row justify="center">
              <Col style={{ fontSize: '18px', fontWeight: 'bold' }}>
                Please select a destination
              </Col>
            </Row>
            {/* --- Map Section --- */}
            <Row>
              <Col>
                <Card
                  bordered={false}
                  loading={loading}
                  style={{ width: '100vw' }}>
                  <Row align="middle">
                    <Col xs={24}>
                      <FloorPlanMapMultiple
                        mapDetails={mapDetails}
                        floorPlanLocationList={floorPlanLocationList}
                        classes={'floor-plan-map'}
                        isEditable={isEditable || false}
                        onMapClick={this.onMapClick}
                        mapMarkerRender={({ record, mapPopup }) => (
                          <MapMarkerRender
                            record={record}
                            mapPopup={mapPopup}
                            selectedLocationDetails={selectedLocationDetails}
                            isEditable={isEditable}
                          />
                        )}
                        mapPopup={({ isEditable, locationID }) => (
                          <MapPopup
                            isEditable={isEditable}
                            locationID={locationID}
                            onMarkerPopupOpen={this.onMarkerPopupOpen}
                            selectedLocationDetails={selectedLocationDetails}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            {/* --- Map Section --- end */}

            {/* --- Button Section --- */}
            <Row justify="center">
              <Col>
                <Button
                  disabled={!selectedLocationDetails.locationID}
                  type="primary"
                  htmlType="button"
                  onClick={this.onMarkerButtonClick}>
                  Submit
                </Button>
              </Col>
            </Row>
            {/* --- Button Section --- end */}
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default WebViewFloorPlanAssignTo;

function MapPopup({
  isEditable,
  locationID,
  onMarkerPopupOpen,
  selectedLocationDetails,
}: {
  isEditable?: boolean;
  locationID?: string;
  onMarkerPopupOpen: (locationID?: string) => void;
  selectedLocationDetails: Partial<FloorPlanLocationListType>;
}) {
  return (
    <Popup onOpen={() => onMarkerPopupOpen(locationID)}>
      <Row justify="center">
        <Col xs={24}>ID: {selectedLocationDetails.locationID}</Col>
        <Col xs={24}>Name: {selectedLocationDetails.locationName}</Col>
        <Col xs={24}>Status: {selectedLocationDetails.status}</Col>
      </Row>
    </Popup>
  );
}

function MapMarkerRender({
  record,
  mapPopup,
  selectedLocationDetails,
  isEditable,
}: {
  record: FloorPlanLocationListType;
  mapPopup?: (params: {
    isEditable?: boolean;
    deviceID?: string;
    status?: string;
    locationID?: string;
  }) => ReactNode;
  selectedLocationDetails: Partial<FloorPlanLocationListType>;
  isEditable?: boolean;
}) {
  if (
    record.position &&
    (record.status === 'STOCK' || record.deviceID === 'UNALLOCATED')
  ) {
    let icon = getLeafletIcon('blue');

    if (selectedLocationDetails?.locationID === record.locationID) {
      icon = getLeafletIcon('red');
    }

    return (
      <Fragment key={record.locationID}>
        <Marker
          key={record.locationID}
          title={record.locationID}
          icon={icon}
          draggable={true}
          position={[record.position?.lat, record.position?.lng]}>
          {mapPopup?.({
            isEditable,
            deviceID: record.deviceID,
            status: record.status,
            locationID: record.locationID,
          })}
        </Marker>
      </Fragment>
    );
  }
  return null;
}
