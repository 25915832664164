import { FormInputType } from '../../../type-definitions';

export type StateType = {
  loadingButton: boolean;
  currentTabIndex: string;
  detailsTabFormElements: DetailsTabFormElementsType;
  showMap: boolean;
  mapZoom: number;
  initLat: string;
  initLng: string;
  initZoom: number;
  hasInputChanged: boolean;
};

export type DetailsTabFormElementsType = {
  // 0
  partnerID: FormInputType;
  // 1
  partnerName: FormInputType;
  // 2
  latitude: FormInputType;
  // 3
  longitude: FormInputType;
  // 4
  prefix: FormInputType;
  // 5
  childrenAllowed: boolean;
};

export const detailsTabInitElements: DetailsTabFormElementsType = {
  partnerID: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Partner ID',
      name: 'partnerID',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Partner ID is required',
    label: 'Partner ID *',
  },
  partnerName: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Partner Name',
      name: 'partnerName',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Partner Name is required',
    label: 'Partner Name *',
  },
  latitude: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Latitude',
      name: 'latitude',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Latitude is required',
    label: 'Lat *',
    colValue: 8,
  },
  longitude: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Longitude',
      name: 'longitude',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Longitude is required',
    label: 'Long *',
    colValue: 8,
  },
  prefix: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Prefix',
      name: 'prefix',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Prefix is required',
    label: 'Prefix *',
  },
  childrenAllowed: false,
};
