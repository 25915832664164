import { Fragment } from 'react';
import { Row, Col } from 'antd';

import { FormElementsType } from '../helpers';
import AntdInput from '../../AntdInput';

type PropsType = {
  formElements: FormElementsType;
  inputChangedHandler: (name: any, value: any) => void;
};

const SponsorshipTab = ({ formElements, inputChangedHandler }: PropsType) => {
  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <AntdInput
            {...formElements.sponsorHTML}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default SponsorshipTab;
