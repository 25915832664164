import axios from 'axios';
import { Component, FormEvent, Fragment } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import update from 'immutability-helper';
import { v4 } from 'uuid';
import Leaflet from 'leaflet';

import { apiCall } from '../api-services/api';
import { assetApi, floorPlanApi, partnerApi } from '../api-services/api-list';
import {
  StateType,
  initialElements,
  FormElementsType,
} from '../components/FloorPlanDetails/helpers';
import { updateToken } from '../redux/actions';
import {
  UserDataType,
  FloorPlanLocationListType,
  LocationListType,
  FloorPlanDetailsType,
  PartnerListType,
} from '../type-definitions/api-types';
import { handleFormBody } from '../utils';
import { message, Tabs } from 'antd';
import DetailsTab from '../components/FloorPlanDetails/DetailsTab';
import LocationsTab from '../components/FloorPlanDetails/LocationsTab';
import { floorPlanRoutes } from '../Routes/routes-list';
import { checkValidation } from '../utils/validation';
import AddLocationModal from '../components/FloorPlanDetails/AddLocationModal';
import FormWrapper from '../components-shared/FormWrapper';
import { handleNotification } from '../utils/notification-handler';
import PromptPopup from '../components/PromptPopup';
import { cloneDeep } from '../utils/lodash-libs';

interface PropsType
  extends RouteComponentProps<
      {},
      any,
      {
        floorPlanID: string;
        assetID: string;
        allowEdit: boolean;
        mapWidth: number;
        mapHeight: number;
        floorPlanLat: number;
        floorPlanLng: number;
      }
    >,
    PropsFromRedux {
  userData: Partial<UserDataType>;
  userPermissionList: string[];
}

class FloorPlanDetails extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    const { location } = props;
    this.state = {
      formElements: {
        ...initialElements,
      },
      loading: true,
      mapDetails: {
        width: location?.state?.mapWidth,
        height: location?.state?.mapHeight,
        image: undefined,
        defaultPosition: undefined,
        defaultCoordinates: undefined,
      },
      floorPlanLocationList: [],
      selectedLocation: {},
      showLocationModal: false,
      floorPlanDetails: {},
      currentTabIndex: '1',
      selectedFloorPlanFromTable: {},
      hasInputChanged: false,
      updateFloorPlansInMap: false,

      allowEdit: location?.state?.allowEdit ?? false,
      floorPlanID: location?.state?.floorPlanID ?? '',
      assetID: location?.state?.assetID ?? '',

      addRegionClickedLocId: '',
    };
  }

  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    this._isMounted = true;
    const { location } = this.props;
    const floorPlanID = location?.state?.floorPlanID;
    if (floorPlanID) {
      this.handleFetchedData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  handleState = (data: Partial<StateType>) => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          ...prevState,
          ...data,
        };
      });
  };

  handleFetchedData = async () => {
    const { userData, updateToken } = this.props;
    const { formElements, floorPlanID, assetID, allowEdit } = this.state;

    let formElemKey: keyof typeof formElements;
    let tempFormElements = { ...formElements };

    try {
      const floorPlanLocations = floorPlanApi.getFloorPlanLocations(undefined, {
        floorPlanID,
      });
      const floorPlanDetailsApi = floorPlanApi.getFloorPlanDetails(undefined, {
        floorPlanID,
      });
      const asset = assetApi.getAsset(undefined, { assetID });

      const response = await apiCall({
        storeToken: userData?.token,
        url: floorPlanDetailsApi.url,
        method: floorPlanDetailsApi.method,
        contentType: floorPlanDetailsApi.contentType,
        cancelToken: this.axiosCancelSource.token,
      });

      const floorPlanDetailsResult = response?.data;

      const partnerApiList = partnerApi.getPartners();
      const response2 = await apiCall({
        storeToken: userData?.token,
        url: partnerApiList.url,
        method: partnerApiList.method,
        contentType: partnerApiList.contentType,
        cancelToken: this.axiosCancelSource.token,
      });

      const partnerResult = response2?.data;

      const response3 = await apiCall({
        storeToken: userData?.token,
        url: floorPlanLocations.url,
        method: floorPlanLocations.method,
        contentType: floorPlanLocations.contentType,
        cancelToken: this.axiosCancelSource.token,
      });

      const floorPlanLocationsResult = response3?.data;

      const response4 = await apiCall({
        storeToken: userData?.token,
        url: asset.url,
        method: asset.method,
        contentType: asset.contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      let apiResponseContentType = '';
      if (response4?.headers) {
        apiResponseContentType = response4?.headers?.['content-type'];
      }

      const assetResult = response4?.data;

      let tempPartnerList = [] as PartnerListType[];
      if (partnerResult?.status !== 'error') {
        tempPartnerList = partnerResult.data;
      }

      for (formElemKey in tempFormElements) {
        tempFormElements = update(tempFormElements, {
          [formElemKey]: {
            disabled: { $set: !allowEdit },
          },
        });
      }

      updateToken(floorPlanDetailsResult);
      updateToken(partnerResult);
      let tempFloorPlanDetails = {} as FloorPlanDetailsType;
      if (floorPlanDetailsResult?.status !== 'error') {
        if (floorPlanDetailsResult.data) {
          tempFloorPlanDetails = floorPlanDetailsResult.data;

          tempFormElements = update(tempFormElements, {
            floorPlanName: {
              value: { $set: tempFloorPlanDetails.name },
            },
            description: {
              value: { $set: tempFloorPlanDetails.description },
            },
            partnerID: {
              value: { $set: tempFloorPlanDetails.partnerID },
            },
            topLeftLat: {
              value: {
                $set: tempFloorPlanDetails.bounds.topLeftLat?.toString(),
              },
            },
            topLeftLng: {
              value: {
                $set: tempFloorPlanDetails.bounds.topLeftLng?.toString(),
              },
            },
            bottomRightLat: {
              value: {
                $set: tempFloorPlanDetails.bounds.bottomRightLat?.toString(),
              },
            },
            bottomRightLng: {
              value: {
                $set: tempFloorPlanDetails.bounds.bottomRightLng?.toString(),
              },
            },
          });

          if (tempPartnerList.length > 0) {
            tempFormElements = update(tempFormElements, {
              partnerID: {
                optionValues: {
                  $set: tempPartnerList.map((item) => {
                    return { text: item.partnerName, value: item.partnerID };
                  }),
                },
              },
            });
          }
        }
      } else {
        this._isMounted && handleNotification('error', floorPlanDetailsResult);
      }

      updateToken(floorPlanLocationsResult);

      let tempFloorPlanLocationList: FloorPlanLocationListType[] = [];

      if (floorPlanLocationsResult?.status !== 'error') {
        if (
          floorPlanLocationsResult.data &&
          floorPlanLocationsResult.data.length > 0
        ) {
          tempFloorPlanLocationList = floorPlanLocationsResult.data.map(
            (item: FloorPlanLocationListType) => ({
              ...item,
              uuid: v4(),
            })
          );
        }
      } else {
        this._isMounted &&
          handleNotification('error', floorPlanLocationsResult);
      }

      this._isMounted &&
        this.setState((prevState) => {
          return {
            floorPlanLocationList: tempFloorPlanLocationList,
            mapDetails: {
              ...prevState.mapDetails,
              image:
                apiResponseContentType === 'image/x-icon'
                  ? ''
                  : assetResult || '',
            },
            loading: false,
            formElements: tempFormElements,
            floorPlanDetails: tempFloorPlanDetails,
          };
        });
    } catch (error: any) {
      this._isMounted && handleNotification('error', error?.data);
      this._isMounted && this.setState({ loading: false });
    }
  };

  // Locations Tab
  onUpdateLocation = async (locationData: FloorPlanLocationListType) => {
    const { userData } = this.props;
    const { floorPlanLocationList, floorPlanID } = this.state;
    try {
      let tempFloorPlanLocationList = cloneDeep(floorPlanLocationList);

      tempFloorPlanLocationList = tempFloorPlanLocationList.map((item) => {
        let tempObj = update(item, { $merge: { ...item } });
        if (item.locationID === locationData.locationID) {
          tempObj = update(tempObj, {
            x: { $set: locationData.x },
            y: { $set: locationData.y },
            position: { $set: locationData.position },
            region: { $set: locationData.region },
          });
        }
        return tempObj;
      });

      this._isMounted &&
        this.setState(
          (prev) => ({
            ...prev,
            floorPlanLocationList: tempFloorPlanLocationList,
            updateFloorPlansInMap: true,
          }),
          () =>
            this.setState((prev2) => ({
              ...prev2,
              updateFloorPlansInMap: false,
            }))
        );

      const floorPlanAPIUpdateMethod = locationData.deviceID
        ? floorPlanApi.patchFloorPlanLocation
        : floorPlanApi.putFloorPlanLocation;
      const { url, method, contentType } = floorPlanAPIUpdateMethod(undefined, {
        floorPlanID,
        locationID: locationData.locationID,
      });

      const data = {
        x: locationData.x,
        y: locationData.y,
      };
      const formData = handleFormBody(data);

      const response = await apiCall({
        storeToken: userData?.token,
        url,
        method,
        contentType,
        data: formData,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;

      this._isMounted && handleNotification('success', result);
      // let tempFloorPlanLocationList = cloneDeep(floorPlanLocationList);

      // tempFloorPlanLocationList = tempFloorPlanLocationList.filter((item) => {
      //   return item.locationID !== locationData.locationID;
      // });

      // tempFloorPlanLocationList.push({
      //   floorplanID: floorPlanID,
      //   locationID: locationData.locationID,
      //   deviceID: locationData.deviceID,
      //   locationName: locationData.locationName,
      //   x: locationData.x,
      //   y: locationData.y,
      //   height: locationData.height,
      //   lastContact: locationData.lastContact,
      //   status: locationData.status,
      //   position: locationData.position,
      //   uuid: locationData.uuid,
      // });

      this._isMounted &&
        this.setState({
          // floorPlanLocationList: tempFloorPlanLocationList,
          selectedLocation: {},
        });

      if (floorPlanID && userData.token) {
        this.fetchFloorPlanLocations({
          floorPlanID,
          token: userData.token,
        });
      }
    } catch (error: any) {
      this._isMounted && handleNotification('error', error?.data);
    }
  };

  fetchFloorPlanLocations = async ({
    floorPlanID,
    token,
  }: {
    floorPlanID: string;
    token: string;
  }) => {
    try {
      const { url, method, contentType } = floorPlanApi.getFloorPlanLocations(
        undefined,
        {
          floorPlanID,
        }
      );

      const response = await apiCall({
        storeToken: token,
        url,
        method,
        contentType,
        cancelToken: this.axiosCancelSource.token,
      });

      const result = response?.data;
      let tempFloorPlanLocations: FloorPlanLocationListType[] = [];

      if (result?.data && result.data.length > 0) {
        tempFloorPlanLocations = result.data.map(
          (item: FloorPlanLocationListType) => ({
            ...item,
            uuid: v4(),
          })
        );
      }

      this._isMounted &&
        this.setState(
          (prev) => ({
            ...prev,
            floorPlanLocationList: tempFloorPlanLocations,
            updateFloorPlansInMap: true,
          }),
          () =>
            this.setState((prev2) => ({
              ...prev2,
              updateFloorPlansInMap: false,
            }))
        );
    } catch (error: any) {
      this._isMounted && handleNotification('error', error?.data);
    }
  };

  onRemoveLocation = async (locationData: FloorPlanLocationListType) => {
    const { userData } = this.props;
    const { floorPlanLocationList, floorPlanID } = this.state;
    try {
      const { url, method, contentType } = floorPlanApi.deleteFloorPlanLocation(
        undefined,
        {
          floorPlanID,
          locationID: locationData.locationID,
        }
      );
      const response = await apiCall({
        storeToken: userData?.token,
        url,
        method,
        contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      if (result?.status === 'ok') {
        this._isMounted && handleNotification('success', result);
        let tempFloorPlanLocationList = [...floorPlanLocationList];
        tempFloorPlanLocationList = tempFloorPlanLocationList.filter((item) => {
          return item.locationID !== locationData.locationID;
        });

        this._isMounted &&
          this.setState({
            floorPlanLocationList: tempFloorPlanLocationList,
            selectedLocation: {},
          });
      } else {
        this._isMounted && handleNotification('error', result);
      }
    } catch (error: any) {
      this._isMounted && handleNotification('error', error?.data);
    }
  };

  handleAddLocationModal = () => {
    this._isMounted &&
      this.setState((prevState) => ({
        ...prevState,
        showLocationModal: !prevState.showLocationModal,
      }));
  };

  onLocationSelectModal = (locationData: LocationListType) => {
    const { floorPlanLocationList, mapDetails, floorPlanID } = this.state;

    let tempFloorPlanLocationList = [...floorPlanLocationList];

    tempFloorPlanLocationList = tempFloorPlanLocationList.filter((item) => {
      return item.locationID !== locationData.locationID;
    });

    tempFloorPlanLocationList.push({
      floorplanID: floorPlanID,
      locationID: locationData.locationID,
      deviceID: '',
      locationName: locationData.locationName,
      x: mapDetails.defaultCoordinates?.x || 0,
      y: mapDetails.defaultCoordinates?.x || 0,
      position: {
        lat: mapDetails.defaultPosition?.lat || 0,
        lng: mapDetails.defaultPosition?.lng || 0,
      },
      height: 1,
      lastContact: '',
      status: '',
      uuid: locationData.uuid,
    });
    this._isMounted &&
      this.setState({
        selectedLocation: locationData,
        showLocationModal: false,
        floorPlanLocationList: tempFloorPlanLocationList,
      });
  };

  inputChangedHandler = (name: keyof FormElementsType, value: string) => {
    const { formElements } = this.state;
    let tempFormElements = { ...formElements };
    if (name) {
      if (
        value &&
        (name === 'topLeftLat' ||
          name === 'topLeftLng' ||
          name === 'bottomRightLat' ||
          name === 'bottomRightLng') &&
        !checkValidation(value, { isNegativeFloat: true })
      ) {
        message.error('Please enter valid input');
        return;
      }

      tempFormElements = update(tempFormElements, {
        [name]: {
          value: { $set: value },
          touched: { $set: true },
          valid: {
            $set: checkValidation(value, tempFormElements[name].validation),
          },
        },
      });

      this._isMounted &&
        this.setState({
          formElements: tempFormElements,
          hasInputChanged: true,
        });
    }
  };

  onBoundsMarkerMove = (
    lat: number,
    lng: number,
    name: 'topLeft' | 'bottomRight'
  ) => {
    const { formElements } = this.state;

    let tempFormElements = { ...formElements };

    if (name === 'topLeft') {
      tempFormElements = update(tempFormElements, {
        topLeftLat: {
          value: { $set: lat.toString() },
        },
        topLeftLng: {
          value: { $set: lng.toString() },
        },
      });
    }
    if (name === 'bottomRight') {
      tempFormElements = update(tempFormElements, {
        bottomRightLat: {
          value: { $set: lat.toString() },
        },
        bottomRightLng: {
          value: { $set: lng.toString() },
        },
      });
    }
    this._isMounted &&
      this.setState({ formElements: tempFormElements, hasInputChanged: true });
  };

  onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    this.handleSubmit();
  };

  handleSubmit = (onConfirm?: () => void) => {
    const { formElements, floorPlanID } = this.state;
    const { userData } = this.props;

    let formElemKey: keyof typeof formElements;

    let tempFormElements = { ...formElements };

    for (formElemKey in tempFormElements) {
      tempFormElements = update(tempFormElements, {
        [formElemKey]: {
          touched: { $set: true },
          valid: {
            $set: checkValidation(
              tempFormElements[formElemKey].value,
              tempFormElements[formElemKey].validation
            ),
          },
        },
      });
    }

    this._isMounted && this.setState({ formElements: tempFormElements });

    for (formElemKey in tempFormElements) {
      if (!tempFormElements[formElemKey].valid) {
        message.error('Please fill all the fields');
        return;
      }
    }

    this._isMounted && this.setState({ loading: true });

    (async () => {
      try {
        const { url, method, contentType } = floorPlanApi.putFloorPlan(
          undefined,
          { floorPlanID }
        );
        const response = await apiCall({
          storeToken: userData?.token,
          url,
          method,
          contentType,
          data: {
            name: tempFormElements.floorPlanName.value,
            description: tempFormElements.description.value,
            partnerID: tempFormElements.partnerID.value,
            bounds: {
              topLeftLat: parseFloat(tempFormElements.topLeftLat.value),
              topLeftLng: parseFloat(tempFormElements.topLeftLng.value),
              bottomRightLat: parseFloat(tempFormElements.bottomRightLat.value),
              bottomRightLng: parseFloat(tempFormElements.bottomRightLng.value),
            },
          },
          cancelToken: this.axiosCancelSource.token,
        });
        const result = response?.data;
        if (result?.status !== 'error') {
          this._isMounted && handleNotification('success', result);
          this._isMounted &&
            this.setState({ loading: false, hasInputChanged: false }, () =>
              onConfirm?.()
            );
        } else {
          this._isMounted && handleNotification('error', result);
          this._isMounted && this.setState({ loading: false });
        }
      } catch (error: any) {
        this._isMounted && handleNotification('error', error?.data);
        this._isMounted && this.setState({ loading: false });
      }
    })();
  };

  handlePopupConfirm = (onConfirm: () => void) => {
    this.handleSubmit(onConfirm);
  };

  handleCancelRedirect = () => {
    const { history } = this.props;
    history.push(floorPlanRoutes.list);
  };

  onAddRegionBtnClicked = (locationId: string) => {
    this.handleState({ addRegionClickedLocId: locationId });
  };

  getTabData = () => {
    const {
      formElements,
      mapDetails,
      floorPlanLocationList,
      selectedLocation,
      selectedFloorPlanFromTable,
      updateFloorPlansInMap,
      allowEdit,
      addRegionClickedLocId,
      floorPlanID,
    } = this.state;
    const { userData } = this.props;

    const detailsTabElem = (
      <DetailsTab
        formElements={formElements}
        inputChangedHandler={this.inputChangedHandler}
        isEditable={allowEdit}
        handleCancelRedirect={this.handleCancelRedirect}
        userData={userData}
        onBoundsMarkerMove={this.onBoundsMarkerMove}
      />
    );

    const locationsTabElem = (
      <LocationsTab
        formElements={formElements}
        inputChangedHandler={this.inputChangedHandler}
        mapDetails={mapDetails}
        floorPlanLocationList={floorPlanLocationList}
        selectedLocation={selectedLocation}
        onUpdateLocation={this.onUpdateLocation}
        onRemoveLocation={this.onRemoveLocation}
        handleAddLocationModal={this.handleAddLocationModal}
        setMapDefaultParameters={this.setMapDefaultParameters}
        isEditable={allowEdit}
        onFloorPlanLocationSelect={this.onFloorPlanLocationSelect}
        selectedFloorPlanFromTable={selectedFloorPlanFromTable}
        updateFloorPlansInMap={updateFloorPlansInMap}
        onAddRegionBtnClicked={this.onAddRegionBtnClicked}
        addRegionClickedLocId={addRegionClickedLocId}
        floorPlanId={floorPlanID}
        token={userData.token ?? ''}
      />
    );

    return { detailsTabElem, locationsTabElem };
  };

  setMapDefaultParameters = (
    defaultPosition: Leaflet.LatLng,
    defaultCoordinates: { x: number; y: number }
  ) => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          ...prevState,
          mapDetails: {
            ...prevState.mapDetails,
            defaultPosition,
            defaultCoordinates,
          },
        };
      });
  };

  onFloorPlanLocationSelect = (
    selectedFloorPlan: FloorPlanLocationListType
  ) => {
    const { floorPlanLocationList } = this.state;

    let tempFloorPlanLocationList = [...floorPlanLocationList];

    let selected;

    if (selectedFloorPlan) {
      tempFloorPlanLocationList = tempFloorPlanLocationList.map((el) => {
        if (el.uuid === selectedFloorPlan.uuid) {
          const tempObj = {
            ...el,
            isClickedFromTable:
              el.isClickedFromTable !== undefined
                ? !el.isClickedFromTable
                : true,
          };
          selected = { ...tempObj };
          return tempObj;
        }
        return el;
      });

      this.handleState({
        floorPlanLocationList: tempFloorPlanLocationList,
        selectedFloorPlanFromTable: selected || {},
      });
    }
  };

  setCurrentTabIndex = (index: string) => {
    this._isMounted && this.setState({ currentTabIndex: index });
  };

  render() {
    const {
      loading,
      showLocationModal,
      currentTabIndex,
      floorPlanLocationList,
      hasInputChanged,
      floorPlanDetails,
      floorPlanID,
      allowEdit,
    } = this.state;

    const { userPermissionList, userData } = this.props;

    if (!floorPlanID) {
      return <Redirect to={floorPlanRoutes.list} />;
    }

    if (userPermissionList.length === 0) {
      return <Redirect to="/" />;
    }

    const { detailsTabElem, locationsTabElem } = this.getTabData();

    let radiusCenterLat = userData.lat || 0;
    let radiusCenterLng = userData.lng || 0;
    if (floorPlanDetails?.bounds?.bottomRightLat) {
      radiusCenterLat =
        (floorPlanDetails.bounds.topLeftLat +
          floorPlanDetails.bounds.bottomRightLat) /
        2;
      radiusCenterLng =
        (floorPlanDetails.bounds.topLeftLng +
          floorPlanDetails.bounds.bottomRightLng) /
        2;
    }

    return (
      <Fragment>
        {hasInputChanged && (
          <PromptPopup
            when={hasInputChanged}
            handleConfirm={this.handlePopupConfirm}
          />
        )}

        <FormWrapper
          loading={loading}
          title={allowEdit ? 'FloorPlan Details' : 'FloorPlan View'}
          colDetails={{ one: { lg: 16 }, three: { lg: 24 } }}>
          <form onSubmit={this.onFormSubmit} noValidate>
            <Tabs
              activeKey={currentTabIndex}
              onTabClick={this.setCurrentTabIndex}>
              <Tabs.TabPane key="1" tab="Details">
                {detailsTabElem}
              </Tabs.TabPane>

              <Tabs.TabPane key="2" tab="Locations">
                {locationsTabElem}
              </Tabs.TabPane>
            </Tabs>
          </form>
        </FormWrapper>

        {showLocationModal && (
          <AddLocationModal
            userData={userData}
            showLocationModal={showLocationModal}
            handleAddLocationModal={this.handleAddLocationModal}
            onLocationSelectModal={this.onLocationSelectModal}
            floorPlanLocationList={floorPlanLocationList}
            radiusCenterLat={radiusCenterLat}
            radiusCenterLng={radiusCenterLng}
          />
        )}
      </Fragment>
    );
  }
}

const mapDispatch = {
  updateToken: updateToken,
};
const connector = connect(null, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(FloorPlanDetails);
