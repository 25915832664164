import { FILTER_SAVED } from '../actionTypes/group.types';

export function saveFilterGroup(data = {}) {
  return {
    type: FILTER_SAVED,
    payload: {
      ...data,
    },
  };
}
