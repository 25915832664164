import { Fragment, ReactNode, MouseEvent, FormEvent, ChangeEvent } from 'react';
import {
  DatePicker,
  Select,
  Input,
  TimePicker,
  Radio,
  Space,
  Checkbox,
} from 'antd';
import moment from 'moment';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { AiOutlineEye } from 'react-icons/ai';
import { getLocalTime } from '../../utils';

import cssStyles from './input.module.scss';
import { RadioChangeEvent } from 'antd/lib/radio';
import {
  FormInputElementConfigType,
  FormInputElementType,
} from '../../type-definitions';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

type PropsType = {
  showPeep?: boolean;
  disabled?: boolean;
  touched?: boolean;
  valid: boolean;
  notVisible?: boolean;

  errorMessage?: string;
  elementType?: FormInputElementType;
  value?: any;
  classes?: string;
  label?: string;
  checkboxText?: string;

  styles?: object;
  elementConfig: FormInputElementConfigType;

  optionValues?: Array<{ value: string; text: string }>;

  onIconClick?: () => void;
  onInputHover?: () => void;
  onInputClick?: () => void;
  onSelectClear?: () => void;
  onInputChanged?: (name: any, value: any) => void;

  labelIcon?: ReactNode;

  inputSize?: 'small' | 'middle' | 'large';

  breakPoint?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  dataTestId?: string;
  hasParentWrapper?: boolean;
};

function AntdInput({
  showPeep,
  disabled,
  touched,
  valid,
  notVisible,

  errorMessage,
  elementType,
  value,
  classes,
  label,
  checkboxText,

  styles,
  elementConfig,

  optionValues,

  onIconClick,
  onInputHover,
  onInputClick,
  onSelectClear,
  onInputChanged,

  labelIcon,

  inputSize,

  breakPoint,

  dataTestId,

  hasParentWrapper,
  ...restProps
}: PropsType) {
  const screens = useBreakpoint();
  let inputElement = null;
  let parentDivClass = `pb-3`;

  const showError = !valid && touched;
  if (showError) {
    parentDivClass = `${parentDivClass} ${cssStyles.inputErrorDiv}`;
  }

  function handleInputChange(event: FormEvent<HTMLInputElement>) {
    onInputChanged?.(event.currentTarget.name, event.currentTarget.value);
  }

  function handleSelectChange(val: any) {
    onInputChanged?.(elementConfig?.name, val);
  }

  function handleDateTimeChange(objValue: any, val: string) {
    onInputChanged?.(elementConfig?.name, val);
  }

  function handleRadioChange(event: RadioChangeEvent) {
    const name = event.target.name || elementConfig?.name;
    onInputChanged?.(name, event.target.value);
  }

  function handleTextAreaChange(event: ChangeEvent<HTMLTextAreaElement>) {
    onInputChanged?.(event.currentTarget.name, event.currentTarget.value);
  }

  const handleCheckboxChange = (event: CheckboxChangeEvent) => {
    const name = event?.target?.name;
    const checked = event?.target?.checked;
    onInputChanged?.(name, checked);
  };

  const checkVertical = () => {
    let isVertical = false;

    if (breakPoint === 'sm') {
      if ((screens.sm && !screens.md) || (screens.xs && !screens.sm)) {
        isVertical = true;
      }
    } else if (breakPoint === 'md') {
      if (
        (screens.md && !screens.lg) ||
        (screens.sm && !screens.md) ||
        (screens.xs && !screens.sm)
      ) {
        isVertical = true;
      }
    } else if (breakPoint === 'lg') {
      if (
        (screens.lg && !screens.xl) ||
        (screens.md && !screens.lg) ||
        (screens.sm && !screens.md) ||
        (screens.xs && !screens.sm)
      ) {
        isVertical = true;
      }
    } else if (breakPoint === 'xl') {
      if (
        (screens.xl && !screens.xxl) ||
        (screens.lg && !screens.xl) ||
        (screens.md && !screens.lg) ||
        (screens.sm && !screens.md) ||
        (screens.xs && !screens.sm)
      ) {
        isVertical = true;
      }
    }

    return isVertical;
  };

  switch (elementType) {
    case 'input':
      const antdInputProps: { suffix?: ReactNode } = {};

      if (showPeep) {
        const suffixProps: {
          onClick?: (event: MouseEvent) => void;
          onMouseEnter?: (event: MouseEvent) => void;
          onMouseLeave?: (event: MouseEvent) => void;
        } = {};
        if (onIconClick) {
          suffixProps.onClick = () => onIconClick();
        } else if (onInputHover) {
          suffixProps.onMouseEnter = () => onInputHover();
          suffixProps.onMouseLeave = () => onInputHover();
        }
        antdInputProps.suffix = (
          <Fragment>
            <AiOutlineEye {...suffixProps} style={{ cursor: 'pointer' }} />
          </Fragment>
        );
      }
      inputElement = (
        <Input
          {...elementConfig}
          {...antdInputProps}
          size={inputSize ? inputSize : 'large'}
          disabled={disabled || false}
          value={value}
          onChange={handleInputChange}
          onClick={onInputClick}
          className={classes}
          style={styles}
          data-testid={dataTestId}
        />
      );
      break;

    case 'textarea': {
      inputElement = (
        <Input.TextArea
          {...elementConfig}
          size={inputSize ? inputSize : 'large'}
          disabled={disabled || false}
          value={value}
          onChange={handleTextAreaChange}
          onClick={onInputClick}
          className={classes}
          style={styles}
          data-testid={dataTestId}
        />
      );
      break;
    }

    case 'date':
      const currentDate = getLocalTime({
        date: new Date(),
        format: 'date',
      });
      const dateValue = value || currentDate;
      inputElement = (
        <DatePicker
          {...elementConfig}
          disabled={disabled || false}
          className={`${disabled ? cssStyles.disabledInput : ''}`}
          size={inputSize ? inputSize : 'large'}
          style={{ width: '100%' }}
          value={moment(dateValue, 'YYYY-MM-DD')}
          onChange={handleDateTimeChange}
          data-testid={dataTestId}
        />
      );
      break;

    case 'time':
      const timeValue = value || '00:00:00';
      inputElement = (
        <TimePicker
          {...elementConfig}
          disabled={disabled || false}
          className={`${disabled ? cssStyles.disabledInput : ''}`}
          size={inputSize ? inputSize : 'large'}
          style={{ width: '100%' }}
          value={moment(timeValue, 'HH:mm:ss')}
          onChange={handleDateTimeChange}
          data-testid={dataTestId}
        />
      );
      break;

    case 'radioGroup': {
      const isVertical = checkVertical();

      inputElement = (
        <Radio.Group
          {...elementConfig}
          disabled={disabled || false}
          className={cssStyles.antdRadio}
          onChange={handleRadioChange}
          value={value}>
          <Space direction={isVertical ? `vertical` : `horizontal`}>
            {optionValues?.map((op) => (
              <Radio key={op.value} value={op.value}>
                {op.text}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      );
      break;
    }

    case 'multiSelect':
      inputElement = (
        <Select
          disabled={disabled || false}
          mode="multiple"
          allowClear
          onClear={onSelectClear}
          value={value}
          size={inputSize ? inputSize : 'large'}
          style={{ width: '100%' }}
          placeholder="Please select"
          onChange={handleSelectChange}
          data-testid={dataTestId}>
          {optionValues?.map((op, index) => (
            <Select.Option key={index} value={op.value}>
              {op.text}
            </Select.Option>
          ))}
        </Select>
      );
      break;

    case 'select':
      inputElement = (
        <Select
          {...elementConfig}
          disabled={disabled || false}
          className={`${disabled ? cssStyles.disabledInput : ''}`}
          value={value}
          defaultValue={value || ''}
          style={{ width: '100%' }}
          size={inputSize ? inputSize : 'large'}
          onChange={handleSelectChange}
          data-testid={dataTestId}>
          {optionValues?.map((op, index) => (
            <Select.Option key={index} value={op.value}>
              {op.text}
            </Select.Option>
          ))}
        </Select>
      );
      break;

    case 'checkbox':
      inputElement = (
        <Checkbox
          {...elementConfig}
          disabled={disabled || false}
          className={`${disabled ? cssStyles.disabledInput : ''}`}
          checked={value}
          onChange={handleCheckboxChange}
          data-testid={dataTestId}>
          {checkboxText}
        </Checkbox>
      );
      break;

    default:
      inputElement = <Fragment></Fragment>;
  }

  return (
    <Fragment>
      {!notVisible && hasParentWrapper !== false && (
        <div className={`${parentDivClass}`}>
          {label && (
            <label htmlFor={elementConfig?.name} className={cssStyles.label}>
              {label !== 'nbsp' ? label : <span>&nbsp;</span>}
              {labelIcon && labelIcon}
            </label>
          )}
          {inputElement}
          {showError && (
            <div className={cssStyles.errorMsg}>{errorMessage}</div>
          )}
        </div>
      )}

      {!notVisible && hasParentWrapper === false && (
        <Fragment>{inputElement}</Fragment>
      )}
    </Fragment>
  );
}

export default AntdInput;
