import axios from 'axios';
import { Component, FormEvent, Fragment } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { FaCog, FaTrashAlt } from 'react-icons/fa';
import { Modal } from 'antd';
import update from 'immutability-helper';

import { apiCall } from '../api-services/api';
import { groupApi } from '../api-services/api-list';
import { GroupListEnumKeys } from '../api-services/api-responses';
import { StateType } from '../components/Groups/helpers';
import { saveFilterGroup, savePagination, updateToken } from '../redux/actions';
import { AntdTableColumnsType, ReduxStateType } from '../type-definitions';
import {
  ApiErrorType,
  ApiResultType,
  GroupListType,
  UserDataType,
} from '../type-definitions/api-types';
import { getKeysFromEnum, handleSorting, handleTableSearch } from '../utils';
import { v4 } from 'uuid';
import { groupRoutes } from '../Routes/routes-list';
import { groupRights } from '../utils/permission-list';
import NotificationHandler from '../components/NotificationHandler';
import TableWrapper, {
  AddButton,
  PaginationDropdown,
} from '../components/TableWrapper';
import FilterInput from '../components/FilterInput';

interface PropsType extends RouteComponentProps, PropsFromRedux {
  userData: Partial<UserDataType>;
  userPermissionList: string[];
}

const tableColumnsKeys = getKeysFromEnum(GroupListEnumKeys);

class Groups extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      perPage: 10,
      dataList: [],
      initDataList: [],
      filteredList: [],
      error: {},
      success: {},
      loading: true,
      pageName: 'groups',
    };
  }

  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    this._isMounted = true;
    const { userData, savePagination } = this.props;
    const { pageName } = this.state;
    savePagination({ paginationKey: pageName });

    if (userData?.username) {
      this.handleFetchedData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  fetchData = async () => {
    const { userData, updateToken } = this.props;
    try {
      const { url, method, contentType } = groupApi.getGroups();
      const response = await apiCall({
        storeToken: userData?.token,
        url,
        method,
        contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      updateToken(result);
      if (result.status === 'ok') {
        return { groups: result.data };
      } else {
        return { error: result };
      }
    } catch (error) {
      return { error };
    }
  };

  handleFetchedData = async () => {
    const {
      error,
      groups,
    }: {
      error?: ApiErrorType;
      groups?: GroupListType[];
    } = await this.fetchData();
    if (error) {
      this._isMounted && this.setState({ loading: false, error });
    } else {
      const { searchInput, filterByPartnerObj, saveFilterGroup } = this.props;

      const stateData: Partial<StateType> = {};

      if (groups && groups.length > 0) {
        let tempData = groups.map((item) => ({ ...item, uuid: v4() }));

        stateData.dataList = tempData;
        stateData.initDataList = tempData;

        let tempPartner: string[] = [];

        tempData.forEach((item) => {
          if (item.partnerID) {
            tempPartner = update(tempPartner, { $push: [item.partnerID] });
          }
        });
        tempPartner = [...new Set(tempPartner)];

        stateData.dataList = tempData;
        stateData.initDataList = tempData;

        const partnerData: { [k: string]: boolean } = {};
        const filterByPartnerKeys =
          filterByPartnerObj && Object.keys(filterByPartnerObj);
        tempPartner.forEach((item) => {
          if (filterByPartnerKeys.length > 0) {
            if (filterByPartnerObj[item]) {
              partnerData[item] = true;
            } else {
              partnerData[item] = false;
            }
          } else {
            partnerData[item] = false;
          }
        });
        // ## -- end -- partner list and filtering

        // ## saving the partner data in redux store
        saveFilterGroup({ filterByPartnerObj: partnerData });

        // ## if filtering needed on route change
        const hasFilter =
          filterByPartnerKeys.length > 0 &&
          filterByPartnerKeys.some((item) => filterByPartnerObj[item] === true);

        if (hasFilter) {
          const temp = [...stateData.dataList];
          const filteredData = temp.filter((item) => {
            if (partnerData[item.partnerID]) {
              return item;
            }
            return null;
          });
          stateData.dataList = filteredData;
          stateData.filteredList = filteredData;
        }
        // ## -- end -- if filtering needed on route change

        // ## search from data
        if (searchInput) {
          let searchList = stateData.dataList;
          if (
            Array.isArray(stateData.filteredList) &&
            stateData.filteredList.length > 0
          ) {
            searchList = stateData.filteredList;
          }
          const sortedData = handleTableSearch({
            data: searchList,
            columnList: tableColumnsKeys,
            searchData: searchInput,
          });
          stateData.dataList = sortedData;
        }
        // ## -- end -- search from data
      }

      this._isMounted &&
        this.setState({
          loading: false,
          initDataList: stateData.initDataList || [],
          dataList: stateData.dataList || [],
          filteredList: stateData.filteredList || [],
        });
    }
  };

  handleSearch = (event: FormEvent<HTMLInputElement>) => {
    const { saveFilterGroup, savePagination } = this.props;
    const { initDataList, pageName, filteredList } = this.state;
    const targetValue = event.currentTarget.value;

    savePagination({ paginationKey: pageName, paginationValue: 1 });
    saveFilterGroup({ searchInput: targetValue });

    const tempList = filteredList.length > 0 ? filteredList : initDataList;

    const stateData = {
      dataList: tempList,
    };

    if (targetValue) {
      const tempData = [...tempList];
      const sortedData = handleTableSearch({
        data: tempData,
        columnList: tableColumnsKeys,
        searchData: targetValue,
      });
      stateData.dataList = sortedData;
    }
    this._isMounted &&
      this.setState({
        ...stateData,
      });
  };

  handlePaginationOption = (value: string) => {
    const { savePagination } = this.props;
    const { pageName } = this.state;
    this._isMounted &&
      this.setState({
        perPage: parseInt(value),
      });

    savePagination({ paginationKey: pageName, paginationValue: 1 });
  };

  onPaginationChange = (page: number, pageSize?: number) => {
    // console.log('params', page, pageSize);
    const { savePagination } = this.props;
    const { pageName } = this.state;
    savePagination({ paginationKey: pageName, paginationValue: page });
  };

  onDeleteClick = (groupID: string) => {
    const { userData } = this.props;

    const onDeleteConfirm = async () => {
      if (groupID) {
        this.setState({ loading: true });
        const { url, method, contentType } = groupApi.deleteGroup(undefined, {
          groupID,
        });
        const response = await apiCall({
          storeToken: userData.token,
          url,
          method,
          contentType,
          cancelToken: this.axiosCancelSource.token,
        });
        const result: ApiResultType = response?.data;
        const stateData = {
          success: {},
          error: {},
        };

        if (result?.status === 'ok') {
          stateData.success = result;
        } else {
          stateData.error = result;
        }

        this.setState({ ...stateData });
        this.handleFetchedData();
      }
    };

    Modal.confirm({
      title: 'Are you sure?',
      icon: <AiOutlineExclamationCircle />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        onDeleteConfirm();
      },
      onCancel: () => {},
    });
  };

  handleCheckboxFilter = (checkBoxData: { [k: string]: boolean }) => {
    const { savePagination, saveFilterGroup } = this.props;
    const stateData: {
      dataList?: GroupListType[];
      filteredList?: GroupListType[];
    } = {};
    if (checkBoxData) {
      const { pageName, initDataList } = this.state;
      savePagination({ paginationKey: pageName, paginationValue: 1 });
      saveFilterGroup({ filterByPartnerObj: checkBoxData });

      const temp = [...initDataList];
      const filteredData = temp.filter((item) => {
        if (checkBoxData[item.partnerID]) {
          return item;
        }
        return null;
      });

      stateData.dataList = filteredData;
      stateData.filteredList = filteredData;
    }

    this._isMounted &&
      this.setState({
        dataList: stateData.dataList || [],
        filteredList: stateData.filteredList || [],
      });
  };

  onRowClickRedirect = (record: GroupListType) => {
    const { history } = this.props;
    const { rights, groupID } = record;
    const allowEdit = rights?.includes(groupRights.edit) ?? false;
    history.push({
      pathname: groupRoutes.details(),
      state: {
        groupID,
        allowEdit,
      },
    });
  };

  render() {
    const {
      searchInput,
      filterByPartnerObj,
      paginationDetails,
      userPermissionList,
      history,
    } = this.props;
    const { perPage, dataList, loading, pageName, success, error } = this.state;

    if (userPermissionList.length === 0) {
      return <Redirect to="/" />;
    }

    let currentPage: number | undefined = 1;
    if (paginationDetails && pageName) {
      currentPage = paginationDetails['groups'];
    }

    const paginationConfig = {
      current: currentPage,
      pageSize: perPage,
      total: (dataList && dataList.length) ?? 0,
      onChange: this.onPaginationChange,
    };

    const columns = getColumns({ onDeleteClick: this.onDeleteClick });

    const showAdd = userPermissionList?.includes(groupRights.create) ?? false;

    return (
      <Fragment>
        <NotificationHandler success={success} error={error} obj={this} />

        <TableWrapper
          tableDetails={{
            dataSource: dataList,
            columns,
            pagination: paginationConfig,
            customConfig: {
              rowKeyValue: 'uuid',
              onRowClick: this.onRowClickRedirect,
            },
          }}
          loading={loading}
          pageTitle="Groups"
          inputComponents={() => (
            <Fragment>
              <div className="col-lg-2 col-md-2">
                <PaginationDropdown
                  onSelectChange={this.handlePaginationOption}
                />
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 ml-auto">
                <FilterInput
                  handleSearchFilter={this.handleSearch}
                  loading={loading}
                  searchValue={searchInput}
                  listData={filterByPartnerObj}
                  handleCheckboxFilter={this.handleCheckboxFilter}
                />
              </div>

              {showAdd && (
                <div className="col-xl-1 col-lg-2 col-md-3 col-sm-6">
                  <AddButton
                    onButtonClick={() => history.push(groupRoutes.add)}
                  />
                </div>
              )}
            </Fragment>
          )}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state: ReduxStateType) {
  return {
    searchInput: state.group.searchInput,
    filterByPartnerObj: state.group.filterByPartnerObj,
    paginationDetails: state.shared.paginationDetails,
  };
}

const mapDispatch = {
  saveFilterGroup: saveFilterGroup,
  updateToken: updateToken,
  savePagination: savePagination,
};

const connector = connect(mapStateToProps, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Groups);

function getColumns({
  onDeleteClick,
}: {
  onDeleteClick: (id: string) => void;
}): AntdTableColumnsType<GroupListType>[] {
  return [
    {
      title: 'Group Name',
      dataIndex: 'groupName',
      key: 'groupName',
      sorter: (a: GroupListType, b: GroupListType) =>
        handleSorting(a.groupName, b.groupName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Group Type',
      dataIndex: 'groupType',
      key: 'groupType',
      sorter: (a: GroupListType, b: GroupListType) =>
        handleSorting(a.groupType, b.groupType),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Partner',
      dataIndex: 'partnerID',
      key: 'partnerID',
      sorter: (a: GroupListType, b: GroupListType) =>
        handleSorting(a.partnerID, b.partnerID),
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record: GroupListType) => {
        const { rights } = record;
        const allowEdit = rights?.includes(groupRights.edit) ?? false;
        const allowRemove = rights?.includes(groupRights.remove) ?? false;
        return (
          <Fragment>
            <div className="row">
              {allowEdit && (
                <div className="col-2">
                  <Link
                    to={{
                      pathname: groupRoutes.details(),
                      state: {
                        groupID: record.groupID,
                        allowEdit,
                      },
                    }}>
                    <FaCog size="1.2em" />
                  </Link>
                </div>
              )}

              {allowRemove && (
                <div className="col-2">
                  <FaTrashAlt
                    size="1.2em"
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={(event) => {
                      event.stopPropagation();
                      onDeleteClick(record.groupID);
                    }}
                  />
                </div>
              )}
            </div>
          </Fragment>
        );
      },
    },
  ];
}
