import { Fragment } from 'react';
import { Button, Col, Row } from 'antd';
import AntdInput from '../../AntdInput';
import { FormElementsType } from '../helpers/index';

function LocationTab({
  formElements,
  inputChangedHandler,
  setCurrentTabIndex,
}: {
  formElements: FormElementsType;
  inputChangedHandler: (name: any, value: any) => void;
  setCurrentTabIndex?: (index: string) => void;
}) {
  return (
    <Fragment>
      <Row gutter={[{ md: 12 }, 0]}>
        <Col xs={24}>
          <AntdInput
            {...formElements.locationID}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>
      <Row justify="center" gutter={[{ md: 12 }, { xs: 12 }]}>
        <Col xs={24} sm={12} md={8}>
          <Button
            block
            type="primary"
            htmlType="button"
            size="large"
            onClick={() => setCurrentTabIndex?.('1')}>
            Back
          </Button>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Button block type="primary" htmlType="submit" size="large">
            Submit
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
}

export default LocationTab;
