import React, { FormEvent, Fragment, useEffect, useRef, useState } from 'react';
import { Input, Button, Modal } from 'antd';
import axios, { CancelTokenSource } from 'axios';
import AntdTable from '../../AntdTable';
import { v4 } from 'uuid';
import {
  UserDataType,
  SubstituteDevicesType,
  ApiErrorType,
  ApiSuccessType,
} from '../../../type-definitions/api-types';
import { apiCall } from '../../../api-services/api';
import { deviceApi } from '../../../api-services/api-list';
import { getKeysFromEnum, handleTableSearch } from '../../../utils';
import NotificationHandler from '../../NotificationHandler';
import { SubstituteDevicesEnumKeys } from '../../../api-services/api-responses';

import cssStyles from '../styles/deviceDetails.module.scss';
import { cloneDeep } from '../../../utils/lodash-libs';

type StateType = {
  loading: boolean;
  searchValue: string;
  tableData: SubstituteDevicesType[];
  initTableData: SubstituteDevicesType[];
  error: ApiErrorType;
  success: ApiSuccessType;
};

type PropsType = {
  showModal: boolean;
  onCloseModal: (params: {
    successChild: ApiSuccessType;
    refreshForm: boolean;
  }) => void;
  handleModal: () => void;
  userData: Partial<UserDataType>;
  locationMac: string;
};

const tableColumnKeys = getKeysFromEnum(SubstituteDevicesEnumKeys);

const initState = {
  loading: true,
  searchValue: '',
  tableData: [],
  initTableData: [],
  error: {},
  success: {},
};

function SwapDeviceModal({
  showModal,
  handleModal,
  onCloseModal,
  userData,
  locationMac,
}: PropsType) {
  const [state, setState] = useState(initState);
  const _isMounted = useRef(false);
  const axiosCancelSource = useRef<CancelTokenSource>();

  function handleState(data: object) {
    setState((prevState) => ({
      ...prevState,
      ...data,
    }));
  }

  useEffect(() => {
    _isMounted.current = true;
    axiosCancelSource.current = axios.CancelToken.source();
    return () => {
      _isMounted.current = false;
      axiosCancelSource.current?.cancel('component unmounted');
    };
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchData() {
    if (!state.loading) {
      _isMounted.current && handleState({ loading: true });
    }

    const stateData: Partial<StateType> = {};
    try {
      const { url, method, contentType, params } =
        deviceApi.putSubstituteDevices({
          deviceID: locationMac,
        });
      const response = await apiCall({
        storeToken: userData?.token,
        url,
        method,
        contentType,
        params,
        cancelToken: axiosCancelSource.current?.token,
      });
      const result = response?.data;
      stateData.loading = false;

      if (result?.status === 'ok') {
        if (Array.isArray(result.data) && result.data.length > 0) {
          const tempData: SubstituteDevicesType[] = result.data.map(
            (item: SubstituteDevicesType) => ({
              ...item,
              uuid: v4(),
            })
          );
          stateData.tableData = tempData;
          stateData.initTableData = tempData;
        }
      }
    } catch (error: any) {
      stateData.loading = false;
      stateData.error = error;
    }

    _isMounted.current && handleState({ ...stateData });
  }

  function handleSearch(event: FormEvent<HTMLInputElement>) {
    const value = event?.currentTarget?.value;

    const stateData: Partial<StateType> = {
      searchValue: value,
    };

    const tempInitData = cloneDeep(state.initTableData);

    if (value) {
      const tempData = handleTableSearch({
        data: tempInitData,
        columnList: tableColumnKeys,
        searchData: value,
      });
      stateData.tableData = tempData;
    } else {
      stateData.tableData = tempInitData;
    }

    _isMounted.current && handleState({ ...stateData });
  }

  async function handleSwapDevice(deviceID: string) {
    if (!state.loading) {
      _isMounted.current && handleState({ loading: true });
    }

    const stateData: Partial<StateType> = {};
    try {
      const { url, method, contentType } = deviceApi.putSwapDevice(undefined, {
        newDeviceID: deviceID,
        previousDeviceID: locationMac,
      });
      const response = await apiCall({
        storeToken: userData?.token,
        url,
        method,
        contentType,
        cancelToken: axiosCancelSource.current?.token,
      });
      const result = response?.data;
      stateData.loading = false;
      if (result) {
        if (result?.status === 'ok') {
          // stateData.success = result;
          // setTimeout(() => {
          //   closeModal({ refresh: true });
          // }, 1000);
          onCloseModal({ successChild: result, refreshForm: true });
        } else {
          stateData.error = result;
        }
      }
    } catch (error: any) {
      stateData.loading = false;
      stateData.error = error;
    }
    _isMounted.current && handleState({ ...stateData });
  }

  const tableDetails = {
    paginationConfig: {
      pageSize: 5,
      total: state.tableData.length,
    },
  };

  return (
    <Fragment>
      <NotificationHandler
        success={state.success}
        error={state.error}
        obj={{ _isMounted: _isMounted.current, setState }}
      />
      <Modal
        // width={760}
        className={cssStyles.swap_modal}
        visible={showModal}
        closable={false}
        onCancel={handleModal}
        footer={null}>
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <span
              style={{
                fontSize: '1.5rem',
                fontWeight: 'bold',
              }}>{`Select a device to swap with ${locationMac}`}</span>
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-md-6 ml-auto">
            <Input
              disabled={state.loading}
              type="text"
              value={state.searchValue}
              placeholder="Search"
              name="searchValue"
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-md-12">
            <AntdTable
              loading={state.loading}
              columns={getColumns(handleSwapDevice)}
              dataSource={state.tableData}
              pagination={tableDetails.paginationConfig}
              customConfig={{ rowKeyValue: 'uuid' }}
            />
          </div>
        </div>
      </Modal>
    </Fragment>
  );
}

export default SwapDeviceModal;

function getColumns(handleSwapDevice: (id: string) => void) {
  return [
    // 0
    {
      title: 'Device ID',
      dataIndex: 'mac',
      key: 'mac',
    },
    // 1
    {
      title: 'Manufacturer',
      dataIndex: 'manufacturer',
      key: 'manufacturer',
    },
    // 2
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
    },
    // 3
    {
      title: 'Partner ID',
      dataIndex: 'partnerID',
      key: 'partnerID',
    },
    // 4
    {
      title: 'Location ID',
      dataIndex: 'locID',
      key: 'locID',
    },
    // 5
    {
      title: 'Location Name',
      dataIndex: 'locationName',
      key: 'locationName',
    },
    // 6
    {
      title: 'Location Status',
      dataIndex: 'locationStatus',
      key: 'locationStatus',
    },
    // 7
    {
      title: 'Action',
      // dataIndex: 'locationStatus',
      key: 'action',
      render: (text: string, record: SubstituteDevicesType) => {
        return (
          <Fragment>
            <Button
              size="small"
              type="primary"
              htmlType="button"
              onClick={() => handleSwapDevice(record.mac)}>
              Select
            </Button>
          </Fragment>
        );
      },
    },
  ];
}
