import {
  authRoutes,
  deviceRoutes,
  floorPlanRoutes,
  groupRoutes,
  hardwareRoutes,
  locationRoutes,
  partnerRoutes,
  userRoutes,
} from '../../../Routes/routes-list';
import { NavbarLinksType } from '../../../type-definitions';
import {
  locationRights,
  deviceRights,
  userRights,
  groupRights,
  floorPlanRights,
  partnerRights,
  hardwareRights,
} from '../../../utils/permission-list';

export const navbarLinks: NavbarLinksType[] = [
  {
    path: '/',
    text: 'Home',
    auth: 'public',
  },
  {
    path: authRoutes.login,
    text: 'Login',
    auth: false,
  },
  {
    path: deviceRoutes.list,
    text: 'Devices',
    auth: true,
    permissions: [
      deviceRights.view,
      deviceRights.edit,
      deviceRights.create,
      deviceRights.remove,
    ],
  },
  {
    path: userRoutes.list,
    text: 'Users',
    auth: true,
    permissions: [
      userRights.view,
      userRights.edit,
      userRights.create,
      userRights.remove,
    ],
  },
  {
    path: locationRoutes.list,
    text: 'Locations',
    auth: true,
    permissions: [
      locationRights.view,
      locationRights.edit,
      locationRights.create,
      locationRights.remove,
    ],
  },
  {
    path: groupRoutes.list,
    text: 'Groups',
    auth: true,
    permissions: [
      groupRights.view,
      groupRights.edit,
      groupRights.create,
      groupRights.remove,
    ],
  },
  {
    path: floorPlanRoutes.list,
    text: 'Floor Plans',
    auth: true,
    permissions: [
      floorPlanRights.view,
      floorPlanRights.edit,
      floorPlanRights.create,
      floorPlanRights.remove,
    ],
  },
  // {
  //   path: '/location/map',
  //   text: 'Map',
  //   auth: true,
  // },
  // {
  //   path: '/groups/chart',
  //   text: 'Groups Chart',
  //   auth: true,
  // },
  {
    path: partnerRoutes.list,
    text: 'Partners',
    auth: true,
    permissions: [
      partnerRights.view,
      partnerRights.edit,
      partnerRights.create,
      partnerRights.remove,
    ],
  },
  {
    path: hardwareRoutes.list,
    text: 'Hardware',
    auth: true,
    permissions: [
      hardwareRights.view,
      hardwareRights.edit,
      hardwareRights.create,
      hardwareRights.remove,
    ],
  },
  {
    path: '#',
    text: '',
    auth: true,
    dropdown: true,
    menuName: 'profile',
    subMenuItems: [
      {
        path: authRoutes.profileEdit(),
        text: 'Profile Edit',
        auth: true,
      },
      {
        path: authRoutes.profileChangePassword(),
        text: 'Change Password',
        auth: true,
      },
      {
        path: authRoutes.bugReporting(),
        text: 'Bug Reporting',
        auth: true,
        permissions: ['ReportIssues'],
      },
    ],
  },

  // /material-ui/skeleton
  // {
  //   path: '/login',
  //   text: 'Login',
  //   auth: false
  // },
  // {
  //   path: '/user/list',
  //   text: 'Users',
  //   auth: true
  // }
];
