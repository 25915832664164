import { Button, Col, Row } from 'antd';
import { Fragment } from 'react';
import { getSingleInputElement } from '../../../utils/get-input-element';
import { DetailsTabElementsType } from '../helpers';

type PropsType = {
  detailsTabElements: DetailsTabElementsType;
  detailsInputChangedHandler: (
    name: keyof DetailsTabElementsType,
    value: string
  ) => void;
  isSubmitDisabled: boolean;
};

function DetailsTab({
  detailsTabElements,
  detailsInputChangedHandler,
  isSubmitDisabled,
}: PropsType) {
  const single = getSingleInputElement({
    formElements: { ...detailsTabElements },
    inputChangedHandler: detailsInputChangedHandler,
    sliceValue: [0, 4],
  });

  return (
    <Fragment>
      {single}

      <Row justify="center">
        <Col sm={12} md={8}>
          <Button
            block
            type="primary"
            htmlType="submit"
            size="large"
            disabled={isSubmitDisabled}>
            Submit
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
}

export default DetailsTab;
