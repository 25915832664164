import { Fragment, useState } from 'react';
import { Layout, Drawer, Menu } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import { NavbarLinksType } from '../../../type-definitions';
import { UserDataType } from '../../../type-definitions/api-types';
import { useDispatch } from 'react-redux';
import { clearPagination } from '../../../redux/actions';

const { Header } = Layout;
const { SubMenu } = Menu;

type PropsType = {
  navbarLinks: NavbarLinksType[];
  userPermissionList: string[] | undefined;
  isAuth: boolean;
  userData?: Partial<UserDataType>;
  handleLogout: () => void;
};

function MobileNav({
  navbarLinks,
  userPermissionList,
  isAuth,
  userData,
  handleLogout,
}: PropsType) {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const currentPath = location?.pathname;

  function handleDrawer() {
    setVisible((prev) => !prev);
  }

  function onLogout() {
    handleLogout();
    setVisible(false);
  }

  function handleRedirect(path: string) {
    if (currentPath) {
      const paginationKey = currentPath?.split('/')[1];
      dispatch(clearPagination({ paginationKey }));
    }
    history.push(path);
  }

  return (
    <Fragment>
      <Header style={{ padding: '0' }}>
        <div className="row mx-0">
          <div className="col-3 ml-auto px-5" style={{ textAlign: 'right' }}>
            <GiHamburgerMenu
              size="1.5em"
              style={{ color: 'white', cursor: 'pointer' }}
              onClick={handleDrawer}
            />
          </div>
        </div>
      </Header>

      <Drawer
        // title="Basic Drawer"
        placement="left"
        closable={false}
        onClose={handleDrawer}
        visible={visible}
        key="left">
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={['0']}
          selectedKeys={[currentPath]}>
          {navbarLinks.map((item, index) => {
            let hasPermission: boolean | string = false;
            if (item.permissions) {
              if (
                userPermissionList &&
                userPermissionList.length > 0 &&
                item.permissions.some((el) => userPermissionList.includes(el))
              ) {
                hasPermission = true;
              } else {
                hasPermission = false;
              }
            } else {
              hasPermission = 'default';
            }

            const hasSubmenu = item.subMenuItems;

            if (
              (item.auth === isAuth || item.auth === 'public') &&
              (hasPermission === 'default' || hasPermission === true)
            ) {
              if (!hasSubmenu) {
                return (
                  <Menu.Item
                    // key={`mobile-menu-${index}`}
                    key={item.path}
                    onClick={handleDrawer}>
                    <Link to={'#'} onClick={() => handleRedirect(item.path)}>
                      {item.text}
                    </Link>
                  </Menu.Item>
                );
              }
              return (
                <SubMenu key="SubMenu" title={userData?.username}>
                  {item?.subMenuItems?.map((elem) => {
                    let hasSubMenuPermission: boolean | string = false;
                    if (elem.permissions) {
                      if (
                        Array.isArray(userPermissionList) &&
                        userPermissionList.length > 0 &&
                        elem.permissions.some((el) =>
                          userPermissionList.includes(el)
                        )
                      ) {
                        hasSubMenuPermission = true;
                      } else {
                        hasSubMenuPermission = false;
                      }
                    } else {
                      hasSubMenuPermission = 'default';
                    }

                    if (
                      (elem.auth === isAuth || elem.auth === 'public') &&
                      (hasSubMenuPermission === 'default' ||
                        hasSubMenuPermission === true)
                    ) {
                      return (
                        <Menu.Item
                          // key={`mobile-submenu-${idx}`}
                          key={elem.path}
                          onClick={handleDrawer}>
                          <Link
                            to={'#'}
                            onClick={() => handleRedirect(elem.path)}>
                            {elem.text}
                          </Link>
                        </Menu.Item>
                      );
                    }
                    return null;
                  })}
                </SubMenu>
              );
            }
            return null;
          })}

          {isAuth && (
            <Menu.Item
              className="ml-auto"
              key={`submenu-logout`}
              onClick={onLogout}>
              <Link to="#" onClick={onLogout}>
                Logout
              </Link>
            </Menu.Item>
          )}
        </Menu>
      </Drawer>
    </Fragment>
  );
}

export default MobileNav;
