import update from 'immutability-helper';

import {
  ReduxActionType,
  ReduxHardwareStateType,
} from '../../type-definitions';
import { LOGOUT } from '../actionTypes/auth.types';
import { STORE_DATA } from '../actionTypes/hardware.types';

const initialState: ReduxHardwareStateType = {
  searchInput: '',
  filterByPartnerObj: {},
};

function reducer(state = initialState, action: ReduxActionType) {
  switch (action.type) {
    case STORE_DATA:
      const searchInput = action?.payload?.searchInput;
      const filterByPartnerObj: { [k: string]: boolean } | undefined =
        action?.payload?.filterByPartnerObj;
      let stateData = { ...state };
      if (searchInput !== undefined) {
        stateData = update(stateData, { searchInput: { $set: searchInput } });
      }
      if (filterByPartnerObj) {
        stateData = update(stateData, {
          filterByPartnerObj: { $merge: filterByPartnerObj },
        });
      }

      return {
        ...stateData,
      };

    case LOGOUT:
      return {
        ...state,
        searchInput: '',
        filterByPartnerObj: {},
      };

    default:
      return state;
  }
}

export default reducer;
