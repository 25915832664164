import axios, {
  CancelToken,
  AxiosRequestConfig,
  AxiosResponse,
  Method,
} from 'axios';
import {
  AxiosHttpAllSettledErrorType,
  RequestConfigType,
} from '../type-definitions/api-types';
import { handleApiError } from '../utils/';
import { handleNotification } from '../utils/notification-handler';

type ApiCallTypes = {
  storeToken?: string;
  url?: string;
  method?: Method;
  cancelToken?: CancelToken;
  data?: object | string;
  params?: {} | null;
  contentType?: string;
};

export async function apiCall({
  storeToken,
  url,
  method,
  cancelToken,
  // ## optional
  data,
  params,
  contentType,
}: ApiCallTypes) {
  let headers = {
    'X-API-KEY': storeToken,
    'Content-Type': contentType,
  };

  const options: AxiosRequestConfig = {
    method,
    url,
    headers,
    params,
    data,
    cancelToken,
  };

  try {
    const response: AxiosResponse = await axios({ ...options });
    return response;
  } catch (error: any) {
    if (axios.isCancel(error)) {
    } else {
      const errorResponse = handleApiError(error);
      throw errorResponse;
    }
  }
}

type SendRequestType = {
  requestConfig: RequestConfigType[];
  headersConfig: { token?: string; cancelToken?: CancelToken };
  applyData?: (responses: PromiseSettledResult<any>[]) => void;
};

export const httpCallAllSettled = async ({
  requestConfig,
  headersConfig,
  applyData,
}: SendRequestType) => {
  const headers: {
    'X-API-KEY'?: undefined | string;
    'Content-Type'?: string;
  } = {
    'X-API-KEY': headersConfig.token,
  };

  // if (headersConfig.token) {
  //   headers['X-API-KEY'] = headersConfig.token;
  // }

  const apiCollection: any[] = [];

  requestConfig.forEach((el) => {
    apiCollection.push(
      axios({
        ...el,
        headers: {
          ...headers,
          'Content-Type': el.contentType || 'application/json',
        },
        cancelToken: headersConfig.cancelToken,
      })
    );
  });

  try {
    const responses = await Promise.allSettled([...apiCollection]);

    applyData?.(responses);
  } catch (error: any) {
    handleNotification('error', { message: error?.response?.data?.message });
  }
};

export const httpCallErrorHandling = (
  errorData: AxiosHttpAllSettledErrorType
) => {
  handleNotification('error', {
    message:
      errorData?.reason?.response?.data?.message ?? errorData?.reason?.message,
  });
};
