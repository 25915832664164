import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FormInputType } from '../../../type-definitions';
import {
  ApiErrorType,
  GroupListType,
  PartnerListType,
} from '../../../type-definitions/api-types';

export const detailsTabElements: DetailsTabElementsType = {
  // 0
  locationID: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      name: 'locationID',
      placeholder: 'Location ID',
    },
    optionValues: [],
    value: '',
    validation: {
      // required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Location ID is required',
    label: 'Location ID',
    notVisible: true,
  },
  // 1
  partnerName: {
    elementType: 'select',
    elementConfig: {
      name: 'partnerName',
    },
    optionValues: [],
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Partner Name is required',
    label: 'Partner Name',
  },
  // 1
  locationName: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Location Name',
      name: 'locationName',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Location Name is required',
    label: 'Location Name *',
  },
  // 2
  latitude: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Lat',
      name: 'latitude',
    },
    value: '0',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Lat is required',
    label: 'Lat *',
    colValue: 4,
  },
  // 3
  longitude: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Long',
      name: 'longitude',
    },
    value: '0',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Long is required',
    label: 'Long *',
    colValue: 4,
  },
  // 4
  status: {
    elementType: 'select',
    elementConfig: {
      type: 'text',
      placeholder: 'Status',
      name: 'status',
    },
    value: '',
    optionValues: [],
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Status is required',
    label: 'Status',
    disabled: false,
    colValue: 6,
  },
  // 5
  capacity: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Capacity',
      name: 'capacity',
    },
    optionValues: [],
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Capacity is required',
    label: 'Capacity *',
    colValue: 6,
  },
  // 6
  friendlyName: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Friendly Name',
      name: 'friendlyName',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Friendly Name is required',
    label: 'Friendly Name',
  },
  // 7
  friendlyDesc: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Friendly Description',
      name: 'friendlyDesc',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Friendly Description is required',
    label: 'Friendly Description',
  },
};

export const addressTabElements: AddressTabElementsType = {
  // 8
  street: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Address Line 1',
      name: 'street',
    },
    value: '',
    validation: {
      // required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Address Line 1 is required',
    label: 'Address',
    colValue: 12,
  },
  // 9
  street2: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Address Line 2',
      name: 'street2',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Address Line 2 is required',
    colValue: 12,
  },
  // 10
  city: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Town/City',
      name: 'city',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Town/City is required',
    colValue: 6,
  },
  // 11
  province: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'State/Province/County',
      name: 'province',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'State/Province/County is required',
    colValue: 6,
  },
  // 12
  postalCode: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Postal Code',
      name: 'postalCode',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Postal Code is required',
    colValue: 6,
  },
  // 13
  country: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Country',
      name: 'country',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Country is required',
    colValue: 6,
  },
};

export const groupsTabElements: GroupsTabElementsType = {
  // 15
  locationGroupInput: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Groups',
      name: 'locationGroupInput',
      readOnly: true,
    },
    optionValues: [],
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Groups are required',
    label: 'Groups',
  },
};

export const deviceTabElements: DeviceTabElementsType = {
  // 16
  locationMac: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Device ID',
      name: 'locationMac',
      readOnly: true,
    },
    value: 'UNALLOCATED',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Device ID is required',
    label: 'Device ID',
    colValue: 6,
  },
};

export interface DetailsTabElementsType {
  locationID: FormInputType;
  partnerName: FormInputType;
  locationName: FormInputType;
  latitude: FormInputType;
  longitude: FormInputType;
  status: FormInputType;
  capacity: FormInputType;
  friendlyName: FormInputType;
  friendlyDesc: FormInputType;
}
export interface GroupsTabElementsType {
  locationGroupInput: FormInputType;
}
export interface DeviceTabElementsType {
  locationMac: FormInputType;
}
export interface AddressTabElementsType {
  street: FormInputType;
  street2: FormInputType;
  city: FormInputType;
  province: FormInputType;
  postalCode: FormInputType;
  country: FormInputType;
}

export type FormElementsType = DetailsTabElementsType &
  GroupsTabElementsType &
  DeviceTabElementsType &
  AddressTabElementsType;

export type StateType = {
  formElements: FormElementsType;
  partnerList: PartnerListType[];
  staticCheck: boolean;
  showMap: boolean;
  showAssignStockDevice: boolean;
  defaultGroupsChecked: boolean;
  hasInputChanged: boolean;
  loading: boolean;
  mapZoom: number;
  currentTabIndex: string;
  locationGroupList: GroupListType[];
  initLat: string;
  initLng: string;
  initZoom: number;
};

export interface GroupsTabPropsType {
  // locationGroupList: GroupListType[];
  partnerID: string;
  formElements: FormElementsType;
  setCurrentTabIndex: (index: string) => void;
  inputChangedHandler: (name: any, value: any) => void;
  defaultGroupsChecked: boolean;
  handleCheckbox: (event: CheckboxChangeEvent) => void;
  onOkayLocationGroupsTab: (locationGroups: GroupListType[]) => void;
}

export interface GroupsTabStateType {
  locationGroups: GroupListType[];
  groups: GroupListType[];
  initGroups: GroupListType[];
  defaultGroups: GroupListType[];
  loading: boolean;
  error: ApiErrorType;

  searchValueOtherGroups: string;
  filterByPartnerObj: {
    [k: string]: boolean;
  };
}
