import { Fragment } from 'react';
import { Button, Row, Col } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';

import { DetailsTabElementsType } from '../helpers';
import AntdInput from '../../AntdInput';

function DetailsTab({
  formElements,
  inputChangedHandler,
  hasStock,
  allowAllEdit,
  onCancelMapModal,
  handleCheckboxFormElements,
}: {
  formElements: DetailsTabElementsType;
  inputChangedHandler: (name: any, value: any) => void;
  onCancelMapModal: () => void;
  handleCheckboxFormElements: (event: CheckboxChangeEvent) => void;
  hasStock?: boolean;
  allowAllEdit?: boolean;
}) {
  return (
    <Fragment>
      <Row justify="center" align="middle">
        <Col xs={24}>
          <AntdInput
            {...formElements.locationID}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <Col xs={24}>
          <AntdInput
            {...formElements.level}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <Col xs={24}>
          <AntdInput
            {...formElements.partnerName}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>

      <Row justify="center" align="middle">
        <Col xs={24} md={8} className="pr-md-2">
          <AntdInput
            {...formElements.latitude}
            onInputChanged={inputChangedHandler}
          />
        </Col>
        <Col xs={24} md={8} className="pl-md-2 pr-md-2">
          <AntdInput
            {...formElements.longitude}
            onInputChanged={inputChangedHandler}
          />
        </Col>
        <Col xs={24} md={8} className="pl-md-4 pt-md-0_75">
          <Button type="primary" htmlType="button" onClick={onCancelMapModal}>
            MAP
          </Button>
        </Col>
      </Row>

      <Row justify="center" align="middle">
        <Col xs={24}>
          <AntdInput
            {...formElements.height}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>

      <Row justify="center" align="middle">
        <Col xs={24}>
          <AntdInput
            {...formElements.locationName}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>

      <Row justify="center" align="middle">
        <Col xs={24}>
          <AntdInput
            {...formElements.status}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>

      {hasStock && (
        <Row justify="center" align="middle">
          <Col xs={24}>
            <AntdInput
              {...formElements.capacity}
              onInputChanged={inputChangedHandler}
            />
          </Col>
        </Row>
      )}

      <Row align="middle" className="my-2">
        <Col xs={12}>
          <Checkbox
            name={`staticCheck`}
            onChange={handleCheckboxFormElements}
            checked={formElements.staticCheck}>
            {`Static`}
          </Checkbox>
        </Col>
        <Col xs={12}>
          <Checkbox
            disabled={!allowAllEdit}
            name={`internalCheck`}
            onChange={handleCheckboxFormElements}
            checked={formElements.internalCheck}>
            {`Internal`}
          </Checkbox>
        </Col>
      </Row>

      <Row justify="center" align="middle">
        <Col xs={24}>
          <AntdInput
            {...formElements.friendlyName}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>

      <Row justify="center" align="middle">
        <Col xs={24}>
          <AntdInput
            {...formElements.friendlyDesc}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>
    </Fragment>
  );
}

export default DetailsTab;
