import { FormInputType } from '../../../type-definitions';

export const initialElements: FormElementsType = {
  userName: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Username',
      name: 'userName',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Username is required',
    label: 'Username *',
  },

  password: {
    elementType: 'input',
    elementConfig: {
      type: 'password',
      placeholder: 'Password',
      name: 'password',
      autoComplete: 'new-password',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Password is required',
    label: 'Password *',
    showPeep: true,
  },
};

export type FormElementsType = {
  userName: FormInputType;
  password: FormInputType;
};

export type StateType = {
  formElements: FormElementsType;
  isChecked: boolean;
  loading: boolean;
};
