import { Fragment, Component, FormEvent } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Row, Col } from 'antd';

import {
  StateType,
  initialElements,
  FormElementsType,
} from '../components/ProfileEdit/helpers';
import { UserDataType, UserDetailsType } from '../type-definitions/api-types';
import axios from 'axios';
import { updateToken } from '../redux/actions';
import { connect, ConnectedProps } from 'react-redux';
import { apiCall } from '../api-services/api';
import { userApi } from '../api-services/api-list';
import { checkValidation } from '../utils/validation';
import { Button, message, Modal } from 'antd';
import { handleFormBody } from '../utils';
import { userRoutes } from '../Routes/routes-list';
import NotificationHandler from '../components/NotificationHandler';
import LeafletMap from '../components-shared/LeafletMap';
import {
  getMultipleInputElements,
  getSingleInputElement,
} from '../utils/get-input-element';
import FormWrapper from '../components-shared/FormWrapper';

interface PropsType extends RouteComponentProps, PropsFromRedux {
  userData: Partial<UserDataType>;
  userPermissionList: string[];
}

class ProfileEdit extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      formElements: {
        ...initialElements,
      },
      showMap: false,
      mapZoom: 10,
      initZoom: 10,
      initLat: '0',
      initLng: '0',
      loading: true,
      userDetails: {},
      success: {},
      error: {},
    };
  }

  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    this._isMounted = true;
    const { userData } = this.props;

    if (userData?.userID) {
      this.handleFetchedData();
    }
  }

  componentDidUpdate() {
    const { userDetails } = this.state;
    const { userData } = this.props;
    if (!userDetails?.uid && userData?.userID) {
      this.handleFetchedData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  handleFetchedData = async () => {
    const { userData, updateToken } = this.props;
    if (userData.userID) {
      try {
        const { url, method, contentType } = userApi.getUserDetails(undefined, {
          userID: userData.userID,
        });
        const response = await apiCall({
          storeToken: userData.token,
          url,
          method,
          contentType,
          cancelToken: this.axiosCancelSource.token,
        });
        const result = response?.data;
        updateToken(result);
        if (result.status === 'ok') {
          if (result.data) {
            const tempData: UserDetailsType = result.data;
            const { formElements } = this.state;

            formElements.userEmail.value = tempData.email;
            formElements.userPhone.value = tempData.phone;
            // formElements.userLevel.value = tempData.level;
            formElements.latitude.value = tempData.lat.toString();
            formElements.longitude.value = tempData.lng.toString();
            this._isMounted &&
              this.setState({
                loading: false,
                userDetails: result.data,
                formElements,
                initLat: tempData.lat.toString(),
                initLng: tempData.lng.toString(),
              });
          } else {
            this._isMounted && this.setState({ loading: false, error: result });
          }
        } else {
          this._isMounted && this.setState({ loading: false, error: result });
        }
      } catch (error) {
        this._isMounted && this.setState({ loading: false, error });
      }
    }
  };

  inputChangedHandler = (name: keyof FormElementsType, value: string) => {
    const { userDetails, formElements, initLat, initLng } = this.state;

    if (name) {
      if (
        (name === 'latitude' && !checkValidation(value, { isFloat: true })) ||
        (name === 'longitude' && !checkValidation(value, { isFloat: true }))
      ) {
        message.error('Please enter valid Coordinates');
        return;
      }

      formElements[name].value = value;
      formElements[name].touched = true;
      formElements[name].valid = checkValidation(
        value,
        formElements[name].validation
      );
    }

    let initialLatitude = initLat;
    let initialLongitude = initLng;
    if (formElements.latitude.value === '') {
      formElements.latitude.value = userDetails.lat?.toString() || '';
      initialLatitude = userDetails.lat?.toString() || '';
    }

    if (formElements.longitude.value === '') {
      formElements.longitude.value = userDetails.lng?.toString() || '';
      initialLongitude = userDetails.lng?.toString() || '';
    }

    this._isMounted &&
      this.setState({
        formElements,
        initLat: initialLatitude,
        initLng: initialLongitude,
      });
  };

  onFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { formElements, userDetails } = this.state;
    const { updateToken, userData } = this.props;

    let key: keyof typeof formElements;

    for (key in formElements) {
      formElements[key].touched = true;
      formElements[key].valid = checkValidation(
        formElements[key].value,
        formElements[key].validation
      );
    }

    this._isMounted && this.setState({ formElements });

    for (key in formElements) {
      if (!formElements[key].valid) {
        message.error('Please fill all the fields');
        return;
      }
    }

    this._isMounted && this.setState({ loading: true });

    const data = {
      // user_name: userDetails.username,
      user_email: formElements.userEmail.value,
      user_phone: formElements.userPhone.value,
      // user_level: formElements.userLevel.value,
      lat: formElements.latitude.value,
      lng: formElements.longitude.value,
    };
    const formData = handleFormBody(data);

    if (userDetails?.uid) {
      try {
        const { url, method, contentType } = userApi.putUser(undefined, {
          userID: userDetails.uid,
        });
        const response = await apiCall({
          storeToken: userData.token,
          url,
          method,
          data: formData,
          contentType,
          cancelToken: this.axiosCancelSource.token,
        });
        const result = response?.data;
        updateToken(result);
        if (result?.status === 'ok') {
          this._isMounted && this.setState({ success: result, loading: false });
        } else {
          this._isMounted && this.setState({ error: result, loading: false });
        }
      } catch (error) {
        this._isMounted && this.setState({ error, loading: false });
      }
    }
  };

  onOkayMapModal = () => {
    const { formElements, mapZoom } = this.state;

    this._isMounted &&
      this.setState((prevState) => {
        return {
          showMap: !prevState.showMap,
          initLat: formElements.latitude.value,
          initLng: formElements.longitude.value,
          initZoom: mapZoom,
        };
      });
  };

  handleMapModal = () => {
    const { formElements, initLat, initLng, initZoom } = this.state;
    formElements.latitude.value = initLat;
    formElements.longitude.value = initLng;
    this._isMounted &&
      this.setState((prevState) => {
        return {
          showMap: !prevState.showMap,
          formElements,
          mapZoom: initZoom,
        };
      });
  };

  onMarkerMove = (lat: number, lng: number) => {
    const { formElements } = this.state;
    formElements.latitude.value = lat.toString();
    formElements.longitude.value = lng.toString();
    this._isMounted && this.setState({ formElements });
  };

  onMapZoom = (zoom: number) => {
    this._isMounted && this.setState({ mapZoom: zoom });
  };

  render() {
    const { userData } = this.props;
    const { formElements, showMap, mapZoom, loading, success, error } =
      this.state;

    const userID = userData?.userID;

    if (!userID) {
      return <Redirect to={userRoutes.list} />;
    }

    const inputElements = getSingleInputElement({
      formElements,
      inputChangedHandler: this.inputChangedHandler,
      sliceValue: [0, 2],
    });
    const inputElements2 = getMultipleInputElements({
      formElements,
      inputChangedHandler: this.inputChangedHandler,
      sliceValue: [2, 4],
      optionalElement: (
        <Col className="pt-lg-2 pl-lg-4">
          <Button
            size="small"
            type="primary"
            htmlType="button"
            onClick={this.handleMapModal}>
            MAP
          </Button>
        </Col>
      ),
    });

    return (
      <Fragment>
        <NotificationHandler success={success} error={error} obj={this} />
        <FormWrapper loading={loading} title="My Profile Edit">
          <form noValidate onSubmit={this.onFormSubmit}>
            {inputElements}
            {inputElements2}
            <Row justify="center" className="pt-3">
              <Col xs={24} sm={8} md={10} lg={8} xl={6}>
                <Button type="primary" htmlType="submit" size="large" block>
                  Submit
                </Button>
              </Col>
            </Row>
          </form>
        </FormWrapper>

        {showMap && (
          <Modal
            width={500}
            visible={showMap}
            onCancel={this.handleMapModal}
            closable={false}
            onOk={this.onOkayMapModal}>
            <Row justify="center" align="middle" className="mt-4">
              <Col xs={24}>
                <LeafletMap
                  onMapClickMarkerMove={this.onMarkerMove}
                  onMapZoom={this.onMapZoom}
                  lat={parseFloat(formElements.latitude.value)}
                  lng={parseFloat(formElements.longitude.value)}
                  zoom={mapZoom}
                  height={235}
                />
              </Col>
            </Row>
          </Modal>
        )}
      </Fragment>
    );
  }
}

const mapDispatch = {
  updateToken: updateToken,
};
const connector = connect(null, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ProfileEdit);
