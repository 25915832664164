import { FormEvent, Fragment, useEffect, useRef, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { v4 } from 'uuid';
import { Button, Modal } from 'antd';
import { Row, Col } from 'antd';
import update from 'immutability-helper';

import {
  ApiErrorType,
  ApiSuccessType,
  AssignableLocationsType,
  UserDataType,
} from '../../../type-definitions/api-types';
import { apiCall } from '../../../api-services/api';
import { deviceApi, locationApi } from '../../../api-services/api-list';
import { handleTableSearch } from '../../../utils';
import NotificationHandler from '../../NotificationHandler';
import AntdTable from '../../AntdTable';
import FilterInput from '../../FilterInput';
import { useCallback } from 'react';
import { cloneDeep } from '../../../utils/lodash-libs';

type StateType = {
  loading: boolean;
  searchValue: string;
  dataList: AssignableLocationsType[];
  initDataList: AssignableLocationsType[];
  error: ApiErrorType;
  success: ApiSuccessType;
  partnerData: { [k: string]: boolean };
};

type PropsType = {
  showModal: boolean;
  onCloseModal: (params: {
    successChild: ApiSuccessType;
    refreshForm: boolean;
  }) => void;
  handleModal: () => void;
  userData: Partial<UserDataType>;
  locationMac: string;
};

const initState: StateType = {
  loading: true,
  success: {},
  error: {},
  dataList: [],
  initDataList: [],
  searchValue: '',
  partnerData: {},
};

function MoveDeviceModal({
  showModal,
  handleModal,
  onCloseModal,
  userData,
  locationMac,
}: PropsType) {
  const [state, setState] = useState(initState);
  const _isMounted = useRef(false);
  const axiosCancelSource = useRef<CancelTokenSource>();

  function handleState(data: object) {
    setState((prevState) => ({
      ...prevState,
      ...data,
    }));
  }

  useEffect(() => {
    _isMounted.current = true;
    axiosCancelSource.current = axios.CancelToken.source();
    return () => {
      _isMounted.current = false;
      axiosCancelSource.current?.cancel('Component Unmounted');
    };
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchData() {
    let result;
    const stateData: Partial<StateType> = {};

    try {
      const { url, method, contentType, params } =
        locationApi.getAssignableLocations({ deviceID: locationMac });
      const response = await apiCall({
        storeToken: userData?.token,
        url,
        method,
        params,
        contentType,
        cancelToken: axiosCancelSource.current?.token,
      });
      result = response?.data;
      stateData.loading = false;
      if (result) {
        if (result?.status === 'ok' && result.data && result.data.length > 0) {
          const tempData: AssignableLocationsType[] = result.data;
          const tempDataList = tempData.map((item) => ({
            ...item,
            uuid: v4(),
          }));

          let tempPartnerObj: { [k: string]: boolean } = {};
          if (tempDataList.length > 0) {
            tempDataList.forEach((el) => {
              if (el.partnerID) {
                tempPartnerObj = update(tempPartnerObj, {
                  [el.partnerID]: { $set: true },
                });
              }
            });
          }

          stateData.dataList = tempDataList;
          stateData.initDataList = tempDataList;
          stateData.partnerData = tempPartnerObj;
        } else {
          stateData.error = result;
        }
      }
    } catch (error: any) {
      stateData.loading = false;
      stateData.error = error;
    }

    _isMounted.current && handleState({ ...stateData });
  }

  const handleAssignDeviceToLocation = useCallback(
    async (locationID: string) => {
      if (!state.loading) {
        _isMounted.current && handleState({ loading: true });
      }
      const stateData: Partial<StateType> = {};

      try {
        const { url, method, contentType } = deviceApi.putAssignToLocation(
          undefined,
          {
            deviceID: locationMac,
            locationID,
          }
        );
        const response = await apiCall({
          storeToken: userData?.token,
          url,
          method,
          contentType,
          cancelToken: axiosCancelSource.current?.token,
        });
        const result = response?.data;
        if (result) {
          stateData.loading = false;
          if (result?.status === 'ok') {
            // stateData.success = result;
            // setTimeout(() => {
            //   closeModal({ refresh: true });
            // }, 1000);
            onCloseModal({ successChild: result, refreshForm: true });
          } else {
            stateData.error = result;
          }
        }
      } catch (error: any) {
        stateData.error = error;
        stateData.loading = false;
      }

      _isMounted.current && handleState({ ...stateData });
    },
    [locationMac, onCloseModal, state.loading, userData?.token]
  );

  const handleSearch = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const value = event?.currentTarget?.value;
      const stateData: Partial<StateType> = {
        searchValue: value,
      };
      const columns = getColumns({ handleAssignDeviceToLocation }).map(
        (el) => el.key
      );

      let tempInitData = cloneDeep(state.initDataList);

      if (value) {
        const tempData = handleTableSearch({
          data: tempInitData,
          columnList: columns,
          searchData: value,
        });
        stateData.dataList = tempData;
      } else {
        stateData.dataList = tempInitData;
      }

      _isMounted.current && handleState({ ...stateData });
    },
    [handleAssignDeviceToLocation, state.initDataList]
  );

  const handleCheckboxFilter = useCallback(
    (checkBoxData: { [k: string]: boolean }) => {
      const tempData = cloneDeep(state.initDataList);
      const filteredData = tempData?.filter((el) => {
        if (el.partnerID && checkBoxData[el.partnerID]) {
          return el;
        }
        return null;
      });

      _isMounted.current &&
        handleState({ dataList: filteredData, partnerData: checkBoxData });
    },
    [state.initDataList]
  );

  const tableDetails = {
    paginationConfig: {
      pageSize: 5,
      total: state.dataList.length,
    },
  };

  return (
    <Fragment>
      <NotificationHandler
        success={state.success}
        error={state.error}
        obj={{ _isMounted: _isMounted.current, setState }}
      />
      <Modal
        visible={showModal}
        closable={false}
        width={760}
        onCancel={handleModal}
        footer={null}>
        <Row justify="center">
          <Col xs={24} md={16} className="text-center">
            <span
              style={{
                fontSize: '1.5rem',
                fontWeight: 'bold',
              }}>{`Select a location to move ${locationMac} to`}</span>
          </Col>
        </Row>
        <Row className="pt-3" justify="end">
          <Col xs={24} md={12}>
            <FilterInput
              handleSearchFilter={handleSearch}
              loading={state.loading}
              searchValue={state.searchValue}
              listData={state.partnerData}
              handleCheckboxFilter={handleCheckboxFilter}
              dropdownPlacement="topLeft"
            />
          </Col>
        </Row>

        <Row className="pt-4">
          <Col xs={24}>
            <AntdTable
              loading={state.loading}
              columns={getColumns({ handleAssignDeviceToLocation })}
              dataSource={state.dataList}
              pagination={tableDetails.paginationConfig}
              customConfig={{ rowKeyValue: 'uuid' }}
            />
          </Col>
        </Row>
      </Modal>
    </Fragment>
  );
}

export default MoveDeviceModal;

function getColumns({
  handleAssignDeviceToLocation,
}: {
  handleAssignDeviceToLocation?: (id: string) => void;
}) {
  return [
    {
      title: 'Location ID',
      dataIndex: 'locationID',
      key: 'locationID',
    },

    {
      title: 'Location Name',
      dataIndex: 'locationName',
      key: 'locationName',
    },

    {
      title: 'Location Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Partner ID',
      dataIndex: 'partnerID',
      key: 'partnerID',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, record: AssignableLocationsType) => {
        return (
          <Fragment>
            <TableAction
              record={record}
              handleAssignDeviceToLocation={handleAssignDeviceToLocation}
            />
          </Fragment>
        );
      },
    },
  ];
}

function TableAction({
  handleAssignDeviceToLocation,
  record,
}: {
  handleAssignDeviceToLocation?: (id: string) => void;
  record: AssignableLocationsType;
}) {
  return (
    <Fragment>
      <Button
        size="small"
        type="primary"
        htmlType="button"
        onClick={() =>
          handleAssignDeviceToLocation &&
          handleAssignDeviceToLocation(record.locationID)
        }>
        Select
      </Button>
    </Fragment>
  );
}
