import { Fragment, ReactNode } from 'react';

interface PropsType {
  children?: ReactNode;
}

function AppLayout({ children }: PropsType) {
  return (
    <Fragment>
      <div className="container-fluid py-5 appContainer">{children}</div>
    </Fragment>
  );
}

export default AppLayout;
