import { FormInputType } from '../../../type-definitions';
import {
  ApiErrorType,
  ApiSuccessType,
  UserDetailsType,
} from '../../../type-definitions/api-types';

export const initialElements: FormElementsType = {
  // 0
  // userID: {
  //   elementType: 'input',
  //   elementConfig: {
  //     type: 'text',
  //     placeholder: 'User ID',
  //     name: 'userID',
  //   },
  //   value: '',
  //   validation: {
  //     required: true,
  //   },
  //   valid: false,
  //   touched: false,
  //   errorMessage: 'User ID is required',
  //   label: 'User ID *',
  //   disabled: true,
  //   notVisible: true,
  // },
  // 1
  // userName: {
  //   elementType: 'input',
  //   elementConfig: {
  //     type: 'text',
  //     placeholder: 'User Name',
  //     name: 'userName',
  //   },
  //   value: '',
  //   validation: {
  //     required: true,
  //   },
  //   valid: false,
  //   touched: false,
  //   errorMessage: 'User Name is required',
  //   label: 'User Name *',
  //   notVisible: true,
  // },
  // 2
  userEmail: {
    elementType: 'input',
    elementConfig: {
      type: 'email',
      placeholder: 'User Email',
      name: 'userEmail',
    },
    value: '',
    validation: {
      // required: true,
      isEmail: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'User Email must be valid',
    label: 'User Email',
  },
  // 3
  userPhone: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'User Phone',
      name: 'userPhone',
    },
    value: '',
    validation: {
      // required: true,
      isPhone: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'User Phone must be valid',
    label: 'User Phone *',
  },
  // 4
  // userLevel: {
  //   elementType: 'select',
  //   elementConfig: {
  //     name: 'userLevel',
  //   },
  //   optionValues: [],
  //   value: '',
  //   validation: {
  //     required: true,
  //   },
  //   valid: false,
  //   touched: false,
  //   errorMessage: 'User Level is required',
  //   label: 'User Level *',
  //   notVisible: true,
  // },
  // 5
  latitude: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Lat',
      name: 'latitude',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Lat is required',
    label: 'Lat *',
    colValue: 8,
  },
  // 6
  longitude: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Lon',
      name: 'longitude',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Lon is required',
    label: 'Lon *',
    colValue: 8,
  },
};

export type FormElementsType = {
  userEmail: FormInputType;
  userPhone: FormInputType;
  // userLevel: FormInputType;
  latitude: FormInputType;
  longitude: FormInputType;
};

export type StateType = {
  formElements: FormElementsType;
  showMap: boolean;
  mapZoom: number;
  initZoom: number;
  initLat: string;
  initLng: string;
  loading: boolean;
  userDetails: Partial<UserDetailsType>;
  success: ApiSuccessType;
  error: ApiErrorType;
};
