import { Button, Input, message } from 'antd';
import axios, { CancelTokenSource } from 'axios';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaClipboard, FaTrashAlt } from 'react-icons/fa';
import {
  FormEvent,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { v4 } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';
import { apiCall } from '../../../api-services/api';
import { userApi } from '../../../api-services/api-list';
import { updateToken } from '../../../redux/actions';
import {
  ApiErrorType,
  ApiSuccessType,
  UserDataType,
} from '../../../type-definitions/api-types';
import { handleTableSearch } from '../../../utils';
import cssStyles from '../styles/userDetails.module.scss';
import AntdTable from '../../AntdTable';
import Modal from 'antd/lib/modal/Modal';
import NotificationHandler from '../../NotificationHandler';
import { AntdTableColumnsType } from '../../../type-definitions';

type PropsType = {
  userID: string;
  userData: Partial<UserDataType>;
  hasEditRights: boolean;
};

type StateType = {
  loading: boolean;
  success: ApiSuccessType;
  error: ApiErrorType;
  apKeysList: ApiKeysTableType[];
  initApiKeysList: ApiKeysTableType[];
  showModal: boolean;
  searchInput: string;
};

const initState = {
  loading: true,
  success: {},
  error: {},
  apKeysList: [],
  initApiKeysList: [],
  showModal: false,
  searchInput: '',
};

type ApiKeysTableType = {
  keyName: string;
  keyValue: string;
  uuid: string;
  copyStatus: boolean;
  hiddenValue: string;
  showValue: boolean;
};

function ApiKeysTab({ userData, userID, hasEditRights }: PropsType) {
  const [state, setState] = useState<Readonly<StateType>>(initState);
  const _isMounted = useRef(false);
  const axiosCancelSource = useRef<CancelTokenSource>();
  const dispatch = useDispatch();

  function handleState(data: object) {
    _isMounted.current &&
      setState((prevState) => ({
        ...prevState,
        ...data,
      }));
  }

  useEffect(() => {
    axiosCancelSource.current = axios.CancelToken.source();
    _isMounted.current = true;
    return () => {
      _isMounted.current = false;
      axiosCancelSource.current?.cancel('Component Unmounted');
    };
  }, []);

  useEffect(() => {
    handleFetchedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchedData = useCallback(async () => {
    const stateData: Partial<StateType> = {};
    try {
      const { url, method, contentType } = userApi.getApiKeys(null, { userID });
      const response = await apiCall({
        storeToken: userData?.token,
        url,
        method,
        contentType,
        cancelToken: axiosCancelSource.current?.token,
      });
      const result = response?.data;
      stateData.loading = false;
      if (result) {
        dispatch(updateToken(result));
      }

      if (result?.status === 'ok') {
        if (result?.data && Object.keys(result.data).length > 0) {
          const tempData = Object.keys(result.data).map((item) => {
            return {
              uuid: v4(),
              keyName: item,
              keyValue: result.data[item],
              hiddenValue: 'XXXX-XXXX-XXXX-XXXX-XXXX',
              showValue: false,
              copyStatus: false,
            };
          });

          stateData.apKeysList = tempData;
          stateData.initApiKeysList = tempData;
        } else {
          const tempData = Array(5)
            .fill('')
            .map(() => {
              return {
                uuid: v4(),
                keyName: '',
                keyValue: '',
                hiddenValue: '',
                showValue: false,
                copyStatus: false,
              };
            });

          stateData.apKeysList = tempData;
          stateData.initApiKeysList = tempData;
        }
      }
    } catch (error: any) {
      stateData.loading = false;
      stateData.error = error;
    }

    handleState({ ...stateData });
  }, [dispatch, userData?.token, userID]);

  function handleOpenModal() {
    handleState({ showModal: true });
  }

  function handleCloseModal() {
    handleState({ showModal: false, loading: true });

    handleFetchedData();
  }

  function handleSearch(event: FormEvent<HTMLInputElement>) {
    const value = event?.currentTarget?.value;
    const stateData: Partial<StateType> = {
      searchInput: value,
    };
    if (value) {
      let tempData = cloneDeep(state.apKeysList);
      const columns = ['keyValue', 'keyName'];
      tempData = handleTableSearch({
        data: tempData,
        columnList: columns,
        searchData: value,
      });
      stateData.apKeysList = tempData;
    } else {
      stateData.apKeysList = state.initApiKeysList;
    }

    handleState({ ...stateData });
  }

  function handlePeep(keyName: string) {
    let tempData = cloneDeep(state.apKeysList);
    tempData = tempData.filter((item) => {
      if (item.keyName.toString() === keyName.toString()) {
        item.showValue = !item.showValue;
        return item;
      }
      return item;
    });

    handleState({ apKeysList: tempData, initApiKeysList: tempData });
  }

  function handleCopyToClipboard(keyName: string) {
    let tempData = cloneDeep(state.apKeysList);
    tempData = tempData.filter((item) => {
      item.copyStatus = false;
      if (item.keyName.toString() === keyName) {
        item.copyStatus = true;
        return item;
      }
      return item;
    });

    handleState({ apKeysList: tempData, initApiKeysList: tempData });
  }

  const handleDelete = useCallback(
    async (keyName: string) => {
      if (!state.loading) {
        _isMounted.current && handleState({ loading: true });
      }

      const stateData: Partial<StateType> = {};
      try {
        const { url, method, contentType } = userApi.deleteApiKeys(null, {
          userID,
          key: keyName,
        });
        const response = await apiCall({
          storeToken: userData?.token,
          url,
          method,
          contentType,
          cancelToken: axiosCancelSource.current?.token,
        });
        const result = response?.data;
        if (result) {
          dispatch(updateToken(result));

          if (result?.status === 'ok') {
            stateData.success = result;
            handleFetchedData();
          } else {
            stateData.error = result;
            stateData.loading = false;
          }
        }
      } catch (error: any) {
        stateData.loading = false;
        stateData.error = error;
      }

      handleState({ ...stateData });
    },
    [dispatch, handleFetchedData, state.loading, userData.token, userID]
  );

  return (
    <Fragment>
      <NotificationHandler
        success={state.success}
        error={state.error}
        obj={{ _isMounted: _isMounted.current, setState }}
      />
      <div className={cssStyles.apiKeysTab}>
        <div className="row title__root">
          <div
            className="col-lg-6"
            style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
            API Keys List
          </div>
        </div>
        <div className="row btn__root">
          <div className="col-lg-6">
            <Button
              disabled={!hasEditRights}
              type="primary"
              htmlType="button"
              onClick={handleOpenModal}
              loading={state.loading}>
              Add Key
            </Button>
          </div>
        </div>
        <div className="row pt-4 input__root">
          <div className="col-lg-6">
            <Input
              disabled={state.loading || !hasEditRights}
              type="text"
              value={state.searchInput}
              placeholder="Search"
              name="searchInput"
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className="row pt-4 tbl__root">
          <div className="col-lg-12">
            <AntdTable
              loading={state.loading}
              columns={getColumns({
                handlePeep,
                handleCopyToClipboard,
                handleDelete,
                hasEditRights,
              })}
              dataSource={state.apKeysList}
              pagination={{ pageSize: 5, total: state.apKeysList.length }}
              customConfig={{ rowKeyValue: 'uuid' }}
            />
          </div>
        </div>
      </div>

      {state.showModal && (
        <AddApiKeyModal
          showModal={state.showModal}
          handleCloseModal={handleCloseModal}
          userData={userData}
          userID={userID}
        />
      )}
    </Fragment>
  );
}

export default ApiKeysTab;

function getColumns({
  handlePeep,
  handleCopyToClipboard,
  handleDelete,
  hasEditRights,
}: {
  handlePeep: (keyName: string) => void;
  handleCopyToClipboard: (keyName: string) => void;
  handleDelete: (keyName: string) => void;
  hasEditRights: boolean;
}): AntdTableColumnsType<ApiKeysTableType>[] {
  return [
    {
      title: 'Key Name',
      key: 'keyName',
      dataIndex: 'keyName',
      width: '10%',
      sorter: (a: ApiKeysTableType, b: ApiKeysTableType) =>
        a.keyName.length - b.keyName.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Key Value',
      key: 'keyValue',
      render: (text: string, record: ApiKeysTableType, index: number) => {
        const clipIconProps: {
          style?: { cursor: string; color: string };
          onClick?: () => void;
        } = {};
        if (hasEditRights) {
          clipIconProps.style = {
            cursor: 'pointer',
            color: record.copyStatus ? '#50B93F' : '',
          };
          clipIconProps.onClick = () => handleCopyToClipboard?.(record.keyName);
        }
        return (
          <Fragment>
            {record.keyName && (
              <div className="keyValueRoot">
                <div
                  className="apiValue"
                  onMouseEnter={() => handlePeep?.(record.keyName)}
                  onMouseLeave={() => handlePeep?.(record.keyName)}>
                  {record.showValue ? record.keyValue : record.hiddenValue}
                </div>

                <div className="clipboardIcon">
                  <CopyToClipboard text={record.keyValue}>
                    <FaClipboard {...clipIconProps} size="1.2em" />
                  </CopyToClipboard>
                </div>
              </div>
            )}
          </Fragment>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record: ApiKeysTableType) => {
        const trashIconProps: {
          style?: { cursor: string; color: string };
          onClick?: () => void;
        } = {};
        if (hasEditRights) {
          trashIconProps.style = {
            cursor: 'pointer',
            color: 'red',
          };
          trashIconProps.onClick = () => handleDelete?.(record.keyName);
        }
        return (
          <Fragment>
            {record.keyName && <FaTrashAlt {...trashIconProps} />}
          </Fragment>
        );
      },
    },
  ];
}

type AddApiKeyModalPropsType = {
  showModal: boolean;
  handleCloseModal: () => void;
  userData: Partial<UserDataType>;
  userID: string;
};

type AddApiKeyModalStateType = {
  loading: boolean;
  apiKeyInput: string;
  success: ApiSuccessType;
  error: ApiErrorType;
};

const apiKeyModalState = {
  loading: false,
  apiKeyInput: '',
  success: {},
  error: {},
};

function AddApiKeyModal({
  showModal,
  handleCloseModal,
  userData,
  userID,
}: AddApiKeyModalPropsType) {
  const [state, setState] =
    useState<Readonly<AddApiKeyModalStateType>>(apiKeyModalState);
  const _isMounted = useRef(false);
  const axiosCancelSource = useRef<CancelTokenSource>();
  const dispatch = useDispatch();

  function handleState(data: object) {
    _isMounted.current &&
      setState((prevState) => ({
        ...prevState,
        ...data,
      }));
  }

  useEffect(() => {
    axiosCancelSource.current = axios.CancelToken.source();
    _isMounted.current = true;
    return () => {
      _isMounted.current = false;
      axiosCancelSource.current?.cancel('Component Unmounted');
    };
  }, []);

  function handleInputChange(event: FormEvent<HTMLInputElement>) {
    const value = event?.currentTarget?.value;
    handleState({ apiKeyInput: value });
  }

  async function handleClick() {
    if (!state.apiKeyInput) {
      message.warning(`API Key is required`);
      return;
    }

    if (!state.loading) {
      handleState({ loading: true });
    }

    const stateData: Partial<AddApiKeyModalStateType> = {};

    try {
      const { url, method, contentType } = userApi.putApiKeys(null, {
        userID,
        key: state.apiKeyInput,
      });
      const response = await apiCall({
        storeToken: userData?.token,
        url,
        method,
        contentType,
        cancelToken: axiosCancelSource.current?.token,
      });
      const result = response?.data;
      stateData.loading = false;

      if (result) {
        dispatch(updateToken(result));

        if (result?.status === 'ok') {
          stateData.success = result;
          handleCloseModal();
        }
      }
    } catch (error: any) {
      stateData.loading = false;
      stateData.error = error;
    }

    handleState({ ...stateData });
  }

  return (
    <Fragment>
      <Modal
        width={760}
        className={cssStyles.addApiKeyModal}
        visible={showModal}
        onCancel={handleCloseModal}
        closable={false}
        footer={null}>
        <div className="row title__root">
          <div className="col-lg-6">Add API Key</div>
        </div>
        <div className="row input__root">
          <div className="col-lg-6">
            <Input
              disabled={state.loading}
              type="text"
              value={state.apiKeyInput}
              placeholder="API Key"
              name="apiKeyInput"
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="row btn__root">
          <div className="col-lg-6">
            <Button
              type="primary"
              htmlType="button"
              onClick={handleClick}
              loading={state.loading}>
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
}
