import {
  ERRORED,
  LOGOUT,
  USER_RIGHT_LOADED,
  TOKEN_SUCCESS,
  AUTH_SUCCESS,
} from '../actionTypes/auth.types';
import { UPDATE_TOKEN } from '../actionTypes/shared.types';
import { ReduxActionType, ReduxAuthStateType } from '../../type-definitions';

const initialState: ReduxAuthStateType = {
  userData: {},
  userRightList: [],
  error: {},
  loading: false,
};

function reducer(state = { ...initialState }, action: ReduxActionType) {
  switch (action.type) {
    case TOKEN_SUCCESS:
      return {
        ...state,
        userData: action.payload?.userData || {},
        error: {},
        loading: false,
      };

    case ERRORED:
      return {
        ...state,
        error: action.payload?.error || {},
        loading: false,
      };

    case LOGOUT:
      return {
        ...state,
        userData: {},
        userRightList: [],
        error: {},
        loading: false,
      };

    case UPDATE_TOKEN:
      return {
        ...state,
        userData: { ...state.userData, token: action.payload?.token || '' },
      };

    case USER_RIGHT_LOADED:
      return {
        ...state,
        userRightList: action.payload?.userRightList || [],
      };

    case AUTH_SUCCESS:
      if (action.payload && Object.keys(action.payload).length > 0) {
        return {
          ...state,
          ...action.payload,
        };
      }
      return {
        ...state,
      };

    default:
      return state;
  }
}

export default reducer;
