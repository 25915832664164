import { FormEvent, Fragment, PureComponent } from 'react';
import { Table, Card, Button, Input, Modal } from 'antd';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import {
  getKeysFromEnum,
  handleSorting,
  handleTableSearch,
} from '../../../utils';
import {
  AssignableLocationsType,
  UserDataType,
} from '../../../type-definitions/api-types';
import { apiCall } from '../../../api-services/api';
import { deviceApi } from '../../../api-services/api-list';
import NotificationHandler from '../../NotificationHandler';
import { AssignToLocationStateType } from '../helpers';
import { AssignableLocationsEnumKeys } from '../../../api-services/api-responses';

type PropsType = {
  userData: Partial<UserDataType>;
  deviceID: string;
  showModal: boolean;
  handleModal: () => void;
  onLocationSelectFromLocationModal: (id: string) => void;
};

const tableColumnKeys = getKeysFromEnum(AssignableLocationsEnumKeys);

class AssignToLocation extends PureComponent<
  Readonly<PropsType>,
  Readonly<AssignToLocationStateType>
> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      dataList: [],
      initDataList: [],
      perPage: 10,
      showModalConfirm: false,
      searchInput: '',
      loading: true,
      error: {},
      success: {},
    };
  }

  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    this._isMounted = true;
    this.handleFetchedData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  fetchData = async () => {
    const { userData } = this.props;

    try {
      const { url, method, contentType } = deviceApi.getAssignableLocations();
      const response = await apiCall({
        storeToken: userData?.token,
        url,
        method,
        contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      if (result?.status === 'ok') {
        return { assignableLocationList: result.data || [] };
      } else {
        return { error: result };
      }
    } catch (error) {
      return { error };
    }
  };

  handleFetchedData = async () => {
    const { error, assignableLocationList } = await this.fetchData();
    if (error) {
      this._isMounted && this.setState({ loading: false, error });
    } else {
      if (assignableLocationList.length > 0) {
        this._isMounted &&
          this.setState({
            loading: false,
            dataList: assignableLocationList,
            initDataList: assignableLocationList,
          });
      } else {
        this._isMounted && this.setState({ loading: false });
      }
    }
  };

  handleSelect = async (locationID: string) => {
    const { deviceID, userData, onLocationSelectFromLocationModal } =
      this.props;

    try {
      const { url, method, contentType } = deviceApi.putAssignToLocation(
        undefined,
        {
          deviceID,
          locationID,
        }
      );
      const response = await apiCall({
        storeToken: userData?.token,
        url,
        method,
        contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      if (result?.status === 'ok') {
        onLocationSelectFromLocationModal(locationID);
      } else {
        this._isMounted && this.setState({ error: result });
      }
    } catch (error) {
      this._isMounted && this.setState({ error });
    }
  };

  // onPaginationChange = (pagination, filters, sorter, extra) => {
  //   // console.log('params', pagination, filters, sorter, extra);
  // };

  handleSearch = (event: FormEvent<HTMLInputElement>) => {
    const targetValue = event.currentTarget.value;
    const { initDataList } = this.state;

    let tempInitData = cloneDeep(initDataList);

    if (!targetValue) {
      this._isMounted &&
        this.setState({
          dataList: tempInitData,
          searchInput: targetValue,
        });
    } else {
      const sortedData: AssignableLocationsType[] = handleTableSearch({
        data: tempInitData,
        columnList: tableColumnKeys,
        searchData: targetValue,
      });

      this._isMounted &&
        this.setState({
          dataList: sortedData,
          searchInput: targetValue,
        });
    }
  };

  render() {
    const { showModal, handleModal } = this.props;
    const { dataList, searchInput, loading, success, error } = this.state;
    const paginationConfig = {
      pageSize: 10,
      total: dataList.length,
      // onChange: this.onPaginationChange,
    };

    const columns: any = getColumns(this.handleSelect);

    return (
      <Fragment>
        <NotificationHandler success={success} error={error} obj={this} />
        <Modal
          visible={showModal}
          closable={false}
          width={760}
          onCancel={handleModal}
          footer={null}>
          <Card bordered={false} loading={loading}>
            <div className="row">
              <div className="col-md-4 ml-auto mb-3">
                <label>Search:</label>
                <Input
                  type="text"
                  id="searchInput"
                  name="searchInput"
                  value={searchInput}
                  placeholder="Search..."
                  allowClear
                  onChange={this.handleSearch}
                  size="large"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Table
                  className="table-responsive"
                  rowKey={(record) => record['locationID']}
                  bordered
                  columns={columns}
                  dataSource={dataList}
                  pagination={paginationConfig}
                />
              </div>
            </div>
          </Card>
        </Modal>
      </Fragment>
    );
  }
}

function getColumns(handleSelect: (id: string) => void) {
  return [
    {
      title: 'Location ID',
      key: 'locationID',
      dataIndex: 'locationID',
      sorter: (a: AssignableLocationsType, b: AssignableLocationsType) =>
        handleSorting(a.locationID, b.locationID),
    },
    {
      title: 'Location Name',
      key: 'name',
      dataIndex: 'name',
      sorter: (a: AssignableLocationsType, b: AssignableLocationsType) =>
        handleSorting(a.locationName, b.locationName),
    },
    {
      title: 'Capacity',
      key: 'capacity',
      // dataIndex: 'capacity'
      // sorter: (a:AssignableLocationsType, b:AssignableLocationsType) => handleSorting(a.capacity, b.capacity),
      render: (record: AssignableLocationsType) => {
        let capacity: any = record.capacity;
        if (record.capacity === -1) {
          capacity = 'Unlimited';
        }
        return <span>{capacity}</span>;
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      sorter: (a: AssignableLocationsType, b: AssignableLocationsType) =>
        handleSorting(a.status, b.status),
    },
    {
      title: 'Action',
      key: 'action',
      render: (record: AssignableLocationsType) => (
        <Fragment>
          <Button
            onClick={(event) => handleSelect(record.locationID)}
            type="primary">
            Select
          </Button>
        </Fragment>
      ),
    },
  ];
}

export default AssignToLocation;
