import { Col, Row } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { Fragment } from 'react';
import {
  getMultipleInputElements,
  getSingleInputElement,
} from '../../../utils/get-input-element';

import { FormElementsType } from '../helpers';

type PropsType = {
  formElements: FormElementsType;
  inputChangedHandler: (name: keyof FormElementsType, value: string) => void;
  onCheckboxChange: (event: CheckboxChangeEvent) => void;
  allowEdit: boolean;
};

function DetailsTab({
  formElements,
  inputChangedHandler,
  onCheckboxChange,
  allowEdit,
}: PropsType) {
  const { allPhenoms, ...restFormElements } = formElements;

  const multiple = getMultipleInputElements({
    formElements: { ...restFormElements },
    inputChangedHandler: inputChangedHandler,
    sliceValue: [1, 3],
  });

  const multiple2 = getMultipleInputElements({
    formElements: { ...restFormElements },
    inputChangedHandler: inputChangedHandler,
    sliceValue: [3, 4],
  });

  const single = getSingleInputElement({
    formElements: { ...restFormElements },
    inputChangedHandler: inputChangedHandler,
    sliceValue: [4, 5],
  });

  const multiple3 = getMultipleInputElements({
    formElements: { ...restFormElements },
    inputChangedHandler: inputChangedHandler,
    sliceValue: [5, 7],
  });

  const multiple4 = getMultipleInputElements({
    formElements: { ...restFormElements },
    inputChangedHandler: inputChangedHandler,
    sliceValue: [7, 9],
  });

  const multiple5 = getMultipleInputElements({
    formElements: { ...restFormElements },
    inputChangedHandler: inputChangedHandler,
    sliceValue: [9, 10],
  });

  const multiple6 = getMultipleInputElements({
    formElements: { ...restFormElements },
    inputChangedHandler: inputChangedHandler,
    sliceValue: [10, 11],
  });

  const multiple7 = getMultipleInputElements({
    formElements: { ...restFormElements },
    inputChangedHandler: inputChangedHandler,
    sliceValue: [11, 12],
  });

  const multiple8 = getMultipleInputElements({
    formElements: { ...restFormElements },
    inputChangedHandler: inputChangedHandler,
    sliceValue: [12, 13],
  });

  let dateTimeElem = (
    <Fragment>
      {multiple3}
      {multiple4}
    </Fragment>
  );

  if (formElements.dateTimeRadio.value === 'rollingAccess') {
    dateTimeElem = multiple5;
  }
  if (formElements.dateTimeRadio.value === 'unlimitedAccess') {
    dateTimeElem = <Fragment></Fragment>;
  }

  return (
    <Fragment>
      {multiple}
      {multiple2}
      {single}
      {dateTimeElem}
      <Row className="pb-3 pt-1">
        <Col>
          <Checkbox
            disabled={!allowEdit}
            name="allPhenoms"
            checked={allPhenoms}
            onChange={onCheckboxChange}>
            All Phenoms
          </Checkbox>
        </Col>
      </Row>
      {multiple6}
      {multiple7}
      {multiple8}
    </Fragment>
  );
}

export default DetailsTab;
