import { Fragment, Component, FormEvent } from 'react';
import update from 'immutability-helper';
import { FaCog, FaTrashAlt } from 'react-icons/fa';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { StateType } from '../components/HardwareList/helpers';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import {
  ApiResultType,
  HardwareListType,
  UserDataType,
} from '../type-definitions/api-types';
import { AntdTableColumnsType, ReduxStateType } from '../type-definitions';
import { connect, ConnectedProps } from 'react-redux';
import { handleSorting, handleTableSearch } from '../utils';
import { HardwareListEnumKeys } from '../api-services/api-responses';
import axios from 'axios';
import {
  savePagination,
  storeHardwareData,
  updateToken,
} from '../redux/actions';
import { hardwareApi } from '../api-services/api-list';
import { apiCall } from '../api-services/api';
import { handleNotification } from '../utils/notification-handler';
import { v4 } from 'uuid';
import TableWrapper, {
  AddButton,
  PaginationDropdown,
} from '../components/TableWrapper';
import FilterInput from '../components/FilterInput';
import { hardwareRoutes } from '../Routes/routes-list';
import { hardwareRights } from '../utils/permission-list';
import { Col, Row, Modal } from 'antd';

interface PropsType extends RouteComponentProps, PropsFromRedux {
  userData: Partial<UserDataType>;
  userPermissionList: string[];
}

class HardwareList extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      dataList: [],
      perPage: 10,
      loading: true,
      pageName: 'hardwareList',
    };
  }

  initDataList: HardwareListType[] = [];
  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    this._isMounted = true;

    this.handleFetchedData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  handleState = (data: Partial<StateType>) => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          ...prevState,
          ...data,
        };
      });
  };

  handleFetchedData = async () => {
    const { userData, updateToken, filterByPartnerObj, storeHardwareData } =
      this.props;

    try {
      const { url, method, contentType } = hardwareApi.getHardwareList();

      const response = await apiCall({
        storeToken: userData?.token,
        url,
        method,
        contentType,
        cancelToken: this.axiosCancelSource.token,
      });

      const result = response?.data;
      updateToken(result);
      if (result?.status === 'ok') {
        if (result.data && result.data.length > 0) {
          let tempData: HardwareListType[] = result.data.map(
            (item: HardwareListType) => ({ ...item, uuid: v4() })
          );

          const tempPartner = tempData.map((item) =>
            item.partnerID?.toUpperCase()
          );
          const partnerList = [...Array.from(new Set(tempPartner))];
          if (partnerList.length > 0) {
            let tempFilter = filterByPartnerObj
              ? { ...filterByPartnerObj }
              : {};
            partnerList.forEach((el) => {
              if (el && el !== '' && !tempFilter.hasOwnProperty(el)) {
                tempFilter = update(tempFilter, { [el]: { $set: false } });
              }
            });

            storeHardwareData({ filterByPartnerObj: tempFilter });

            const hasFilter = Object.keys(tempFilter).some(
              (el) => tempFilter[el] === true
            );

            if (hasFilter) {
              tempData = tempData.filter((el) => {
                if (el.partnerID && tempFilter[el.partnerID]) {
                  return el;
                }
                return null;
              });
            }
          }

          this.initDataList = [...tempData];
          this._isMounted &&
            this.setState({ loading: false, dataList: tempData });
        } else {
          this.initDataList = [];
          this._isMounted && this.setState({ loading: false, dataList: [] });
        }
      } else {
        this._isMounted && handleNotification('error', result);
        this._isMounted && this.setState({ loading: false });
      }
    } catch (error: any) {
      this._isMounted && handleNotification('error', error.data);
      this._isMounted && this.setState({ loading: false });
    }
  };

  handleDelete = (id: string) => {
    const onDeleteConfirm = async () => {
      const { userData } = this.props;
      this._isMounted && this.setState({ loading: true });
      let stateData: Partial<StateType> = {};
      try {
        const { url, method, contentType } = hardwareApi.deleteHardwareDetails(
          undefined,
          { templateID: id }
        );
        const response = await apiCall({
          storeToken: userData?.token,
          url,
          method,
          contentType,
          cancelToken: this.axiosCancelSource.token,
        });
        const result: ApiResultType = response?.data;
        if (result?.status === 'ok') {
          this._isMounted && handleNotification('success', result);
          this.handleFetchedData();
        } else {
          stateData = update(stateData, { loading: { $set: false } });
          this._isMounted && handleNotification('error', result);
        }
      } catch (error: any) {
        this._isMounted && handleNotification('error', error.data);
        stateData = update(stateData, { loading: { $set: false } });
      }
      this.handleState(stateData);
    };

    Modal.confirm({
      title: 'Are you sure?',
      icon: <AiOutlineExclamationCircle />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        onDeleteConfirm();
      },
      onCancel: () => {},
    });
  };

  handleSearch = (event: FormEvent<HTMLInputElement>) => {
    const { storeHardwareData, savePagination } = this.props;
    const { pageName } = this.state;
    const targetValue = event?.currentTarget?.value;

    savePagination({ paginationKey: pageName, paginationValue: 1 });
    storeHardwareData({ searchInput: targetValue });

    const tempList = [...this.initDataList];

    const stateData = {
      dataList: [...tempList],
    };

    if (targetValue && tempList) {
      const tempData = [...tempList];
      const sortedData = handleTableSearch({
        data: tempData,
        columnList: [
          HardwareListEnumKeys[1],
          HardwareListEnumKeys[2],
          HardwareListEnumKeys[7],
        ],
        searchData: targetValue,
      });
      stateData.dataList = sortedData;
    }

    this._isMounted &&
      this.setState({
        ...stateData,
      });
  };

  handleCheckboxFilter = (checkBoxData: { [k: string]: boolean }) => {
    const { savePagination, storeHardwareData } = this.props;
    const stateData: {
      dataList?: HardwareListType[];
    } = {};
    if (checkBoxData) {
      const { pageName } = this.state;
      savePagination({ paginationKey: pageName, paginationValue: 1 });
      storeHardwareData({ filterByPartnerObj: checkBoxData });

      const temp = [...this.initDataList];
      const filteredData = temp.filter((item) => {
        if (item.partnerID && checkBoxData[item.partnerID]) {
          return item;
        }
        return null;
      });

      stateData.dataList = filteredData;
    }

    this._isMounted &&
      this.setState({
        dataList: stateData.dataList || [],
      });
  };

  handlePaginationOption = (value: string) => {
    const { savePagination } = this.props;
    const { pageName } = this.state;
    this._isMounted &&
      this.setState({
        perPage: parseInt(value),
      });

    savePagination({ paginationKey: pageName, paginationValue: 1 });
  };

  onPaginationChange = (page: number, pageSize?: number) => {
    const { savePagination } = this.props;
    const { pageName } = this.state;
    savePagination({ paginationKey: pageName, paginationValue: page });
  };

  getTableColumns = (): AntdTableColumnsType<HardwareListType>[] => {
    return [
      {
        title: 'Make',
        key: 'make',
        dataIndex: 'make',
        sorter: (a: HardwareListType, b: HardwareListType) =>
          handleSorting(a.make, b.make),
        sortDirections: ['descend', 'ascend'],
        // defaultSortOrder: 'ascend',
      },
      {
        title: 'Model',
        key: 'model',
        dataIndex: 'model',
        sorter: (a: HardwareListType, b: HardwareListType) =>
          handleSorting(a.model, b.model),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Sensors',
        key: 'sensors',
        // dataIndex: 'model',
        // sorter: (a: HardwareListType, b: HardwareListType) =>
        //   handleSorting(a.model, b.model),
        // sortDirections: ['descend', 'ascend'],
        render: (text, record: HardwareListType) => {
          let sensorData = '';

          if (record?.sensorSpecs && record.sensorSpecs.length > 0) {
            record.sensorSpecs.forEach((item, index) => {
              sensorData =
                sensorData + item?.shortName + '(' + item?.units + ')';
              if (index < record.sensorSpecs.length - 1) {
                sensorData = sensorData + ', ';
              }
            });
          }
          return sensorData;
        },
      },
      {
        title: 'Partner ID',
        key: 'partnerID',
        dataIndex: 'partnerID',
        sorter: (a: HardwareListType, b: HardwareListType) =>
          handleSorting(a.partnerID, b.partnerID),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        key: 'action',
        render: (text: string, record: HardwareListType) => {
          const { rights } = record;
          const allowEdit = rights?.includes(hardwareRights.edit) ?? false;
          const allowRemove = rights?.includes(hardwareRights.remove) ?? false;
          return (
            <Fragment>
              <Row>
                {allowEdit && (
                  <Col xs={8} className="text-center pr-4">
                    <FaCog
                      size="1.2em"
                      style={{ color: '#1890ff', cursor: 'pointer' }}
                      onClick={(event) => {
                        event.stopPropagation();
                        this.onClickRedirect(record);
                      }}
                    />
                  </Col>
                )}

                {allowRemove && (
                  <Col xs={8} className="text-center">
                    <FaTrashAlt
                      size="1.2em"
                      style={{ color: 'red', cursor: 'pointer' }}
                      onClick={(event) => {
                        event.stopPropagation();
                        this.handleDelete(record.id);
                      }}
                    />
                  </Col>
                )}
              </Row>
            </Fragment>
          );
        },
      },
    ];
  };

  onClickRedirect = (record: HardwareListType) => {
    const { history } = this.props;
    const { id, rights } = record;
    history.push({
      pathname: hardwareRoutes.details(),
      state: {
        hardwareID: id,
        allowEdit: rights?.includes(hardwareRights.edit) ?? false,
      },
    });
  };

  render() {
    const { loading, dataList, pageName, perPage } = this.state;
    const {
      paginationDetails,
      searchInput,
      filterByPartnerObj,
      history,
      userPermissionList,
    } = this.props;

    if (userPermissionList.length === 0) {
      return <Redirect to="/" />;
    }

    let currentPage: number | undefined = 1;
    if (paginationDetails && pageName) {
      currentPage = paginationDetails['hardwareList'];
    }
    const paginationConfig = {
      current: currentPage,
      pageSize: perPage,
      total: (dataList && dataList.length) ?? 0,
      onChange: this.onPaginationChange,
    };

    let showAdd = userPermissionList?.includes(hardwareRights.create) ?? false;

    return (
      <Fragment>
        <TableWrapper
          tableDetails={{
            dataSource: dataList || [],
            columns: this.getTableColumns(),
            pagination: paginationConfig,
            customConfig: {
              rowKeyValue: 'uuid',
              onRowClick: this.onClickRedirect,
            },
          }}
          loading={loading}
          pageTitle="Hardwares"
          inputComponents={() => (
            <Fragment>
              <div className="col-lg-2 col-md-2">
                <PaginationDropdown
                  onSelectChange={this.handlePaginationOption}
                />
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 ml-auto">
                <FilterInput
                  handleSearchFilter={this.handleSearch}
                  loading={loading}
                  searchValue={searchInput}
                  listData={filterByPartnerObj}
                  handleCheckboxFilter={this.handleCheckboxFilter}
                />
              </div>

              {showAdd && (
                <div className="col-xl-1 col-lg-2 col-md-3 col-sm-6">
                  <AddButton
                    onButtonClick={() => history.push(hardwareRoutes.add)}
                  />
                </div>
              )}
            </Fragment>
          )}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state: ReduxStateType) {
  return {
    paginationDetails: state.shared.paginationDetails,
    searchInput: state.hardware.searchInput,
    filterByPartnerObj: state.hardware.filterByPartnerObj,
  };
}

const mapDispatch = {
  updateToken: updateToken,
  savePagination: savePagination,
  storeHardwareData: storeHardwareData,
};

const connector = connect(mapStateToProps, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(HardwareList);
