import { Component, FormEvent, Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import { Redirect } from 'react-router-dom';
import { Modal } from 'antd';
import { FaCog, FaTrashAlt } from 'react-icons/fa';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import axios from 'axios';
import { v4 } from 'uuid';
import {
  saveFilterDevice,
  updateToken,
  savePagination,
  // saveNavbarDetails,
} from '../redux/actions';
import { getKeysFromEnum, handleSorting, handleTableSearch } from '../utils';
import { ApiResultType, UserDataType } from '../type-definitions/api-types';
import { ReduxStateType } from '../type-definitions';
import NotificationHandler from '../components/NotificationHandler';
import { deviceRights } from '../utils/permission-list';
import { apiCall } from '../api-services/api';
import { deviceApi } from '../api-services/api-list';
import TableWrapper, {
  AddButton,
  PaginationDropdown,
} from '../components/TableWrapper';
import FilterInput from '../components/FilterInput';
import {
  StateType,
  TableOptionsType,
  FiltersType,
} from '../components/Devices/helpers';
import { deviceRoutes } from '../Routes/routes-list';
import { DeviceListEnumKeys } from '../api-services/api-responses';
import { DeviceListType } from '../type-definitions/api-types';
import { TablePaginationConfig } from 'antd/lib/table';
import {
  Key,
  SorterResult,
  TableCurrentDataSource,
} from 'antd/lib/table/interface';
interface PropsType extends RouteComponentProps, PropsFromRedux {
  userData: Partial<UserDataType>;
  userPermissionList: string[];
}

function getColumnRender(text: string, locationStatus?: string) {
  if (locationStatus === 'STOCK') {
    return {
      props: {
        style: { background: `#D3D3D3` },
      },
      children: <Fragment>{text}</Fragment>,
    };
  }

  return {
    children: <Fragment>{text}</Fragment>,
  };
}

const tableColumnKeys = getKeysFromEnum(DeviceListEnumKeys);

class Devices extends Component<PropsType, StateType> {
  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();
  constructor(props: PropsType) {
    super(props);

    this.state = {
      dataList: [],
      initDataList: [],
      filteredList: [],
      perPage: 10,
      tableOptions: {
        columns: [
          {
            title: 'Device ID',
            dataIndex: 'mac',
            key: 'mac',
            filters: [],
            filterMultiple: true,
            onFilter: (value: string, record?: DeviceListType) => {
              return record?.mac?.indexOf?.(value) === 0;
            },
            sorter: (a: DeviceListType, b: DeviceListType) =>
              handleSorting(a?.mac, b?.mac),
            sortDirections: ['descend', 'ascend'],
            render: (text: string, record: DeviceListType) =>
              getColumnRender(text, record.locationStatus),
            defaultSortOrder: 'ascend',
          },
          {
            title: 'Manufacturer',
            dataIndex: 'manufacturer',
            key: 'manufacturer',
            filters: [],
            filterMultiple: true,
            onFilter: (value: string, record: DeviceListType) =>
              record?.manufacturer?.indexOf(value) === 0,
            sorter: (a: DeviceListType, b: DeviceListType) =>
              handleSorting(a?.manufacturer, b?.manufacturer),
            sortDirections: ['descend', 'ascend'],
            render: (text: string, record: DeviceListType) =>
              getColumnRender(text, record?.locationStatus),
          },
          {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
            filters: [],
            filterMultiple: true,
            onFilter: (value: string, record: DeviceListType) =>
              record?.model?.indexOf(value) === 0,
            sorter: (a: DeviceListType, b: DeviceListType) =>
              handleSorting(a?.model, b?.model),
            sortDirections: ['descend', 'ascend'],
            render: (text: string, record: DeviceListType) =>
              getColumnRender(text, record.locationStatus),
          },
          {
            title: 'Partner',
            dataIndex: 'partnerID',
            key: 'partnerID',
            filters: [],
            filterMultiple: true,
            onFilter: (value: string, record: DeviceListType) =>
              record?.partnerID?.indexOf(value) === 0,
            sorter: (a: DeviceListType, b: DeviceListType) =>
              handleSorting(a.partnerID, b.partnerID),
            sortDirections: ['descend', 'ascend'],
            render: (text: string, record: DeviceListType) =>
              getColumnRender(text, record.locationStatus),
          },
          {
            title: 'Location ID',
            dataIndex: 'locID',
            key: 'locID',
            filters: [],
            filterMultiple: true,
            onFilter: (value: string, record: DeviceListType) =>
              record?.locID?.indexOf(value) === 0,
            sorter: (a: DeviceListType, b: DeviceListType) =>
              handleSorting(a?.locID, b?.locID),
            sortDirections: ['descend', 'ascend'],
            render: (text: string, record: DeviceListType) =>
              getColumnRender(text, record.locationStatus),
          },
          {
            title: 'Location Name',
            dataIndex: 'locationName',
            key: 'locationName',
            // filters: [],
            // filterMultiple: true,
            // onFilter: (value: string, record: DeviceListType) =>
            //   record.locationName.indexOf(value) === 0,
            sorter: (a: DeviceListType, b: DeviceListType) =>
              handleSorting(a?.locationName, b?.locationName),
            sortDirections: ['descend', 'ascend'],
            render: (text: string, record: DeviceListType) =>
              getColumnRender(text, record.locationStatus),
          },
          {
            title: 'Action',
            key: 'action',
            render: (record: DeviceListType) => {
              const { rights } = record;

              const allowEdit = rights?.includes(deviceRights.edit) ?? false;
              const allowRemove =
                rights?.includes(deviceRights.remove) ?? false;

              const element = (
                <DeviceAction
                  allowEdit={allowEdit}
                  record={record}
                  onDeleteClick={this.onDeleteClick}
                  allowRemove={allowRemove}
                />
              );

              if (record?.locationStatus === 'STOCK') {
                return {
                  props: {
                    style: { background: `#D3D3D3` },
                  },
                  children: element,
                };
              }

              return {
                children: element,
              };
            },
          },
        ],
      },
      loading: true,
      error: {},
      success: {},
      pageName: 'devices',
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.axiosCancelSource) {
      this.axiosCancelSource = axios.CancelToken.source();
    }

    const { savePagination } = this.props;
    const { pageName } = this.state;
    savePagination({ paginationKey: pageName });

    this.handleFetchedData();
  }

  // componentDidUpdate() {
  //   const { navbarDetails, saveNavbarDetails } = this.props;
  //   if (navbarDetails?.device) {
  //     saveNavbarDetails({ navbarKey: 'device', navbarValue: false });
  //     this._isMounted && this.setState({ loading: true });
  //     this.handleFetchedData();
  //   }
  // }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  fetchData = async () => {
    const { userData, updateToken } = this.props;
    try {
      const { url, method, contentType } = deviceApi.getDevices();
      const response = await apiCall({
        storeToken: userData?.token,
        url,
        method,
        contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      result && updateToken(result);
      if (result?.status === 'ok') {
        return { deviceList: result.data || [] };
      } else {
        return { error: result, deviceList: [] };
      }
    } catch (error) {
      return { error };
    }
  };

  handleFetchedData = async () => {
    const { error, deviceList = [] } = await this.fetchData();
    if (error) {
      this._isMounted && this.setState({ loading: false, error });
    } else {
      const { searchInput, filterByPartnerObj, saveFilterDevice } = this.props;
      const { tableOptions } = this.state;

      const stateData: {
        initDataList?: DeviceListType[];
        dataList: DeviceListType[];
        filteredList?: DeviceListType[];
        tableOptions?: TableOptionsType;
      } = { dataList: [] };
      if (deviceList.length > 0) {
        const devices: DeviceListType[] = cloneDeep(
          deviceList.map((item: object) => ({ ...item, uuid: v4() }))
        );
        const columnList = cloneDeep(tableOptions.columns);

        stateData.initDataList = devices;
        stateData.dataList = devices || [];
        stateData.tableOptions = { ...tableOptions };

        let tempPartner: string[] = [];
        let tempLocationList: string[] = [];
        let tempModelList: string[] = [];
        let tempManufacturerList: string[] = [];
        let tempMacList: string[] = [];

        devices?.forEach((item) => {
          if (item.mac) {
            tempMacList.push(item.mac);
          }
          if (item.manufacturer) {
            tempManufacturerList.push(item.manufacturer);
          }
          if (item.model) {
            tempModelList.push(item.model);
          }
          if (item.locID) {
            tempLocationList.push(item.locID);
          }
          if (item.partnerID) {
            tempPartner.push(item.partnerID.toUpperCase());
          }
        });

        tempPartner = [...Array.from(new Set(tempPartner))];
        tempLocationList = [...Array.from(new Set(tempLocationList))];
        tempModelList = [...Array.from(new Set(tempModelList))];
        tempManufacturerList = [...Array.from(new Set(tempManufacturerList))];
        tempMacList = [...Array.from(new Set(tempMacList))];

        const partnerData: { [k: string]: boolean } = {};
        const filterByPartnerKeys =
          filterByPartnerObj && Object.keys(filterByPartnerObj);
        tempPartner.forEach((item) => {
          if (filterByPartnerKeys.length > 0) {
            if (filterByPartnerObj[item]) {
              partnerData[item] = true;
            } else {
              partnerData[item] = false;
            }
          } else {
            partnerData[item] = false;
          }
        });

        // ## saving the partner data in redux store
        saveFilterDevice({ filterByPartnerObj: partnerData });

        let locationList: FiltersType[] = [];
        tempLocationList.forEach((item, index) => {
          let tempObj: FiltersType = {};
          tempObj.text = item;
          tempObj.value = item;
          locationList.push(tempObj);
        });
        let modelList: FiltersType[] = [];
        tempModelList.forEach((item, index) => {
          let tempObj: FiltersType = {};
          tempObj.text = item;
          tempObj.value = item;
          modelList.push(tempObj);
        });
        let manufacturerList: FiltersType[] = [];
        tempManufacturerList.forEach((item, index) => {
          let tempObj: FiltersType = {};
          tempObj.text = item;
          tempObj.value = item;
          manufacturerList.push(tempObj);
        });
        let macList: FiltersType[] = [];
        tempMacList.forEach((item, index) => {
          let tempObj: FiltersType = {};
          tempObj.text = item;
          tempObj.value = item;
          macList.push(tempObj);
        });
        let partnerList: FiltersType[] = [];
        tempPartner.forEach((item, index) => {
          let tempObj: FiltersType = {};
          tempObj.text = item;
          tempObj.value = item;
          partnerList.push(tempObj);
        });

        columnList.forEach((item, index) => {
          if (item.key === 'locID') {
            item.filters = [...locationList];
          }
          if (item.key === 'model') {
            item.filters = [...modelList];
          }
          if (item.key === 'manufacturer') {
            item.filters = [...manufacturerList];
          }
          if (item.key === 'mac') {
            item.filters = [...macList];
          }
          if (item.key === 'partnerID') {
            item.filters = [...partnerList];
          }
        });

        stateData.tableOptions.columns = columnList;

        // ## if filtering needed on route change
        const hasFilter =
          filterByPartnerKeys.length > 0 &&
          filterByPartnerKeys.some((item) => filterByPartnerObj[item] === true);

        if (hasFilter) {
          const temp: DeviceListType[] = cloneDeep(stateData.dataList);
          const filteredData = temp.filter((item) => {
            if (item.partnerID && partnerData[item.partnerID]) {
              return item;
            }
            return null;
          });
          stateData.dataList = filteredData;
          stateData.filteredList = filteredData;
        }
        // ## -- end -- if filtering needed on route change

        // ## search from data
        if (searchInput) {
          let searchList = stateData.dataList;
          if (
            Array.isArray(stateData.filteredList) &&
            stateData.filteredList.length > 0
          ) {
            searchList = stateData.filteredList;
          }
          const sortedData = handleTableSearch({
            data: searchList,
            columnList: tableColumnKeys,
            searchData: searchInput,
          });
          stateData.dataList = sortedData;
        }
        // ## -- end -- search from data

        this._isMounted &&
          this.setState({
            loading: false,
            initDataList: stateData.initDataList,
            dataList: stateData.dataList,
            filteredList: stateData.filteredList || [],
            tableOptions: stateData.tableOptions,
          });
      } else {
        this._isMounted && this.setState({ loading: false });
      }
    }
  };

  handleSearch = (event: FormEvent<HTMLInputElement>) => {
    const { saveFilterDevice, savePagination } = this.props;
    const { initDataList, pageName, filteredList } = this.state;
    const targetValue = event?.currentTarget?.value;

    savePagination({ paginationKey: pageName, paginationValue: 1 });
    saveFilterDevice({ searchInput: targetValue });

    let tempList = initDataList;
    if (filteredList && filteredList.length > 0) {
      tempList = filteredList;
    }

    const stateData = {
      dataList: tempList,
    };

    if (targetValue && tempList) {
      const tempData = cloneDeep(tempList);
      const sortedData = handleTableSearch({
        data: tempData,
        columnList: tableColumnKeys,
        searchData: targetValue,
      });
      stateData.dataList = sortedData;
    }

    this._isMounted &&
      this.setState({
        ...stateData,
      });
  };

  handlePaginationOption = (value: string) => {
    const { savePagination } = this.props;
    const { pageName } = this.state;
    this._isMounted &&
      this.setState({
        perPage: parseInt(value),
      });

    savePagination({ paginationKey: pageName, paginationValue: 1 });
  };

  onPaginationChange = (page: number, pageSize?: number) => {
    const { savePagination } = this.props;
    const { pageName } = this.state;
    savePagination({ paginationKey: pageName, paginationValue: page });
  };

  onDeleteClick = (deviceID: string) => {
    const { userData } = this.props;

    const onDeleteConfirm = async () => {
      if (deviceID) {
        this.setState({ loading: true });
        const { url, method, contentType } = deviceApi.deleteDevice(null, {
          deviceID,
        });
        const response = await apiCall({
          storeToken: userData.token,
          url,
          method,
          contentType,
          cancelToken: this.axiosCancelSource.token,
        });
        const result: ApiResultType = response?.data;
        const stateData = {
          success: {},
          error: {},
        };

        if (result?.status === 'ok') {
          stateData.success = result;
        } else {
          stateData.error = result;
        }

        this.setState({ ...stateData });
        this.handleFetchedData();
      }
    };

    Modal.confirm({
      title: 'Are you sure?',
      icon: <AiOutlineExclamationCircle />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        onDeleteConfirm();
      },
      onCancel: () => {},
    });
  };

  handleCheckboxFilter = (checkBoxData: { [k: string]: boolean }) => {
    const { savePagination, saveFilterDevice } = this.props;
    const stateData: {
      dataList?: DeviceListType[];
      filteredList?: DeviceListType[];
    } = {};
    if (checkBoxData) {
      const { pageName, initDataList } = this.state;
      savePagination({ paginationKey: pageName, paginationValue: 1 });
      saveFilterDevice({ filterByPartnerObj: checkBoxData });

      const temp = cloneDeep(initDataList);
      const filteredData = temp?.filter((item) => {
        if (item.partnerID && checkBoxData[item.partnerID]) {
          return item;
        }
        return null;
      });

      stateData.dataList = filteredData;
      stateData.filteredList = filteredData;
    }

    this._isMounted &&
      this.setState({
        dataList: stateData.dataList || [],
        filteredList: stateData.filteredList || [],
      });
  };

  onTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, (Key | boolean)[] | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    extra: TableCurrentDataSource<any>
  ) => {
    const { filteredList, dataList } = this.state;

    let temp = dataList;
    if (filteredList.length > 0) {
      temp = filteredList;
    }

    if (Object.values(filters).every((el) => el === null)) {
      this._isMounted &&
        this.setState({
          dataList: temp,
        });
    } else {
      this._isMounted &&
        this.setState({
          dataList: extra.currentDataSource,
        });
    }
  };

  onRowClickRedirect = (record: DeviceListType) => {
    const { history } = this.props;
    const { rights, mac } = record;
    const allowEdit = rights?.includes(deviceRights.edit) ?? false;
    history.push({
      pathname: deviceRoutes.details(),
      state: {
        deviceID: mac,
        allowEdit,
      },
    });
  };

  render() {
    const {
      success,
      error,
      tableOptions,
      dataList,
      pageName,
      perPage,
      loading,
    } = this.state;
    const {
      paginationDetails,
      searchInput,
      filterByPartnerObj,
      userPermissionList,
      history,
    } = this.props;
    let currentPage: number | undefined = 1;
    if (paginationDetails && pageName) {
      currentPage = paginationDetails['devices'];
    }

    const paginationConfig = {
      current: currentPage,
      pageSize: perPage,
      total: (dataList && dataList.length) ?? 0,
      onChange: this.onPaginationChange,
    };

    if (userPermissionList.length === 0) {
      return <Redirect to="/" />;
    }

    const showAdd = userPermissionList?.includes(deviceRights.create) ?? false;

    return (
      <Fragment>
        <NotificationHandler success={success} error={error} obj={this} />

        <TableWrapper
          tableDetails={{
            dataSource: dataList || [],
            columns: tableOptions.columns,
            pagination: { ...paginationConfig },
            customConfig: {
              rowKeyValue: 'uuid',
              onRowClick: this.onRowClickRedirect,
              onTableChange: this.onTableChange,
            },
          }}
          loading={loading}
          pageTitle="Devices"
          inputComponents={() => (
            <Fragment>
              <div className="col-lg-2 col-md-2">
                <PaginationDropdown
                  onSelectChange={this.handlePaginationOption}
                />
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 ml-auto">
                <FilterInput
                  handleSearchFilter={this.handleSearch}
                  loading={loading}
                  searchValue={searchInput}
                  listData={filterByPartnerObj}
                  handleCheckboxFilter={this.handleCheckboxFilter}
                />
              </div>

              {showAdd && (
                <div className="col-xl-1 col-lg-2 col-md-3 col-sm-6">
                  <AddButton
                    onButtonClick={() => history.push(deviceRoutes.add)}
                  />
                </div>
              )}
            </Fragment>
          )}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state: ReduxStateType) {
  return {
    searchInput: state.device.searchInput,
    filterByPartnerObj: state.device.filterByPartnerObj,
    paginationDetails: state.shared.paginationDetails,
    navbarDetails: state.shared.navbarDetails,
  };
}

const mapDispatch = {
  saveFilterDevice,
  updateToken,
  savePagination,
  // saveNavbarDetails,
};

const connector = connect(mapStateToProps, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Devices);

function DeviceAction({
  record,
  allowEdit,
  allowRemove,
  onDeleteClick,
}: {
  record: DeviceListType;
  allowEdit?: boolean;
  allowRemove?: boolean;
  onDeleteClick: (mac: string) => void;
}) {
  return (
    <Fragment>
      <div className="row">
        {allowEdit && (
          <div className="col-2">
            <Link
              to={{
                pathname: deviceRoutes.details(),
                state: {
                  deviceID: record.mac,
                  allowEdit,
                },
              }}>
              <FaCog size="1.2em" />
            </Link>
          </div>
        )}
        {allowRemove && (
          <div className="col-2">
            <FaTrashAlt
              size="1.2em"
              style={{ cursor: 'pointer', color: 'red' }}
              onClick={(event) => {
                event.stopPropagation();
                onDeleteClick(record.mac || '');
              }}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
}
