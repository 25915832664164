import { Dispatch, Fragment, useReducer, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Spin, Tag, Button, Modal } from 'antd';
import { GrClose } from 'react-icons/gr';
import { AiOutlineDelete } from 'react-icons/ai';

import { groupApi, partnerApi } from '../../../api-services/api-list';
import useHttp from '../../../hooks/use-http-allSettled';
import { getUserData } from '../../../redux/selectors';
import { ReducerHookActionType } from '../../../type-definitions';
import {
  ApiResponseConfigDataType,
  ApiResponseDataType,
} from '../../../type-definitions/api-types';
// import AntdCoverSpinner from '../../AntdCoverSpinner';

import {
  RolesTabStateType,
  rolesTabState,
  rolesTabReducer,
  actionTypes,
} from '../helpers';

import cssStyles from '../styles/partnerDetails.module.scss';
import { handleNotification } from '../../../utils/notification-handler';
import { apiCall } from '../../../api-services/api';
import CreateRoleModal from './CreateRoleModal';

interface PropsType {
  partnerID: string;
}

const RolesTab = ({ partnerID }: PropsType) => {
  const [state, dispatchToState]: [
    state: RolesTabStateType,
    dispatchToState: Dispatch<ReducerHookActionType>
  ] = useReducer(rolesTabReducer, rolesTabState);
  const { isLoading, sendRequest } = useHttp();
  const userData = useSelector(getUserData);

  const { rolesList, showCreateRoleModal } = state;

  const fetchPartnerRoles = useCallback(() => {
    if (partnerID && userData.token) {
      const { method, url, contentType } = partnerApi.getPartnerRoles(
        undefined,
        { partnerID }
      );

      const handleResponses = (
        responses: PromiseSettledResult<
          ApiResponseConfigDataType<ApiResponseDataType>
        >[]
      ) => {
        if (responses.length > 0) {
          if (responses[0].status === 'fulfilled') {
            const result = responses[0].value;
            const tempRoles: string[] = result?.data?.data;

            if (tempRoles && tempRoles.length > 0) {
              dispatchToState({
                type: actionTypes.setState,
                payload: { rolesList: tempRoles },
              });
            }
          } else {
            handleNotification('error', {
              message: responses[0].reason?.response?.data?.message,
            });
          }
        }
      };

      sendRequest({
        requestConfig: [{ url, method, contentType }],
        headersConfig: { storeToken: userData.token },
        applyData: handleResponses,
      });
    }
  }, [partnerID, sendRequest, userData.token]);

  useEffect(() => {
    fetchPartnerRoles();
  }, [fetchPartnerRoles]);

  const onTagClose = useCallback(
    (record: string) => {
      const onDeleteConfirm = async () => {
        const { url, method, contentType } = groupApi.deleteGroup(undefined, {
          groupID: `${partnerID}_${record}`,
        });

        try {
          const response = await apiCall({
            storeToken: userData.token,
            url,
            method,
            contentType,
          });
          if (response?.data) {
            handleNotification('success', response.data);
            fetchPartnerRoles();
          }
        } catch (error) {
          handleNotification('error', error?.data);
        }
      };
      if (record) {
        Modal.confirm({
          title: 'Are you sure?',
          icon: <AiOutlineDelete size="1.5em" style={{ color: 'red' }} />,
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onOk: () => {
            onDeleteConfirm();
          },
          onCancel: () => {},
        });
      }
    },
    [fetchPartnerRoles, partnerID, userData.token]
  );

  const onOpenRoleModal = useCallback(() => {
    dispatchToState({
      type: actionTypes.setState,
      payload: { showCreateRoleModal: true },
    });
  }, []);

  const onCloseRoleModal = useCallback(() => {
    dispatchToState({
      type: actionTypes.setState,
      payload: { showCreateRoleModal: false },
    });
  }, []);

  const onSuccessRoleModal = useCallback(() => {
    dispatchToState({
      type: actionTypes.setState,
      payload: {
        showCreateRoleModal: false,
      },
    });

    fetchPartnerRoles();
  }, [fetchPartnerRoles]);

  return (
    <Fragment>
      <Row justify="center" align="middle" className="py-4">
        <Col>
          <Spin size="large" spinning={isLoading}>
            <Row justify="center" className="py-3">
              <Col>
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={onOpenRoleModal}>
                  Create New Role
                </Button>
              </Col>
            </Row>
            <Row>
              {rolesList.length > 0 &&
                rolesList.map((el, idx) => {
                  return (
                    <Fragment key={idx}>
                      <Col className="py-2">
                        <div className={cssStyles.customTagIcon}>
                          <GrClose
                            style={{ cursor: 'pointer' }}
                            onClick={() => onTagClose(el)}
                          />
                        </div>
                        <Tag className={cssStyles.customTag}>{el}</Tag>
                      </Col>
                    </Fragment>
                  );
                })}
            </Row>
          </Spin>
        </Col>
      </Row>

      {showCreateRoleModal && (
        <CreateRoleModal
          showModal={showCreateRoleModal}
          onCloseModal={onCloseRoleModal}
          partnerID={partnerID}
          onSuccess={onSuccessRoleModal}
          rolesList={rolesList}
        />
      )}
    </Fragment>
  );
};

export default RolesTab;
