import { Fragment, createRef, Component } from 'react';
import { MapContainer, Marker, Popup, ImageOverlay } from 'react-leaflet';
import Leaflet from 'leaflet';
import cloneDeep from 'lodash/cloneDeep';
// import isEqual from 'lodash/isEqual';
import { Button, Col, Row } from 'antd';
import update from 'immutability-helper';

// import update from 'immutability-helper';

import 'leaflet/dist/leaflet.css';

import MarkerIconBlue from '../../../../assets/images/map/marker-icon-blue.png';
import MarkerIconRed from '../../../../assets/images/map/marker_red.png';
import MarkerIconGrey from '../../../../assets/images/map/marker_grey.png';
import MarkerShadow from '../../../../assets/images/map/marker-shadow.png';
import {
  FloorPlanLocationListType,
  LocationListType,
} from '../../../../type-definitions/api-types';
import {
  getBoundsUnproject,
  getMapPoint,
} from '../../../../utils/leaflet-helpers';
import { MapDetailsType } from '../../../../type-definitions';

interface PropsType {
  mapDetails: MapDetailsType;
  floorPlanLocationList: FloorPlanLocationListType[];
  selectedLocation: Partial<LocationListType>;
  classes?: string;
  onUpdateLocation: (data: FloorPlanLocationListType) => void;
  onRemoveLocation: (data: FloorPlanLocationListType) => void;
  setMapDefaultParameters: (
    position: Leaflet.LatLng,
    coordinates: { x: number; y: number }
  ) => void;
  isEditable: boolean;
  selectedFloorPlanFromTable?: Partial<FloorPlanLocationListType>;
}

interface StateType {
  // currentPosition: { lat?: number; lng?: number };
  currentPosition: Partial<Leaflet.LatLng>;
  mapBounds?: Leaflet.LatLngBounds;
  floorPlanLocations: FloorPlanLocationListType[];
  movedFloorPlanLocations: FloorPlanLocationListType[];
  selectedLoc: Partial<LocationListType>;
  mapInstance?: Leaflet.Map;
  selectedDataFromTable: Partial<FloorPlanLocationListType>;
}

const BlueIcon = Leaflet.icon({
  iconUrl: MarkerIconBlue,
  shadowUrl: MarkerShadow,
  iconSize: [25, 41],
  shadowSize: [41, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
});

const RedIcon = Leaflet.icon({
  iconUrl: MarkerIconRed,
  shadowUrl: MarkerShadow,
  iconSize: [25, 41],
  shadowSize: [41, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
});

const GreyIcon = Leaflet.icon({
  iconUrl: MarkerIconGrey,
  shadowUrl: MarkerShadow,
  iconSize: [25, 41],
  shadowSize: [41, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
});

const customCRS = (Leaflet as any).extend({}, Leaflet.CRS, {
  projection: Leaflet.Projection.LonLat,
  transformation: new Leaflet.Transformation(1, 0, 1, 0),
});

const unprojectZoom = 4;

export default class FloorPlanMapMultiple extends Component<
  PropsType,
  StateType
> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      currentPosition: {},
      mapBounds: undefined,
      floorPlanLocations: [],
      movedFloorPlanLocations: [],
      selectedLoc: {},
      mapInstance: undefined,
      selectedDataFromTable: {},
    };
  }
  markerRef = createRef<Leaflet.Marker<any>>();
  _isMounted = false;
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps: PropsType, prevState: StateType) {
    const {
      selectedLocation,
      floorPlanLocationList,
      selectedFloorPlanFromTable,
    } = this.props;
    const { floorPlanLocations, mapInstance, selectedDataFromTable } =
      this.state;

    if (
      prevProps.selectedLocation &&
      selectedLocation &&
      prevProps.selectedLocation.locationID !== selectedLocation.locationID
    ) {
      this._isMounted && this.setState({ selectedLoc: selectedLocation });
    }

    if (
      floorPlanLocationList.length !== floorPlanLocations.length &&
      mapInstance
    ) {
      this.handleMap(mapInstance);
    }

    if (
      selectedDataFromTable?.locationID !==
        selectedFloorPlanFromTable?.locationID ||
      selectedDataFromTable?.isClickedFromTable !==
        selectedFloorPlanFromTable?.isClickedFromTable
    ) {
      const matched = floorPlanLocations.find(
        (el) => el.locationID === selectedFloorPlanFromTable?.locationID
      );

      if (matched) {
        matched.isClickedFromTable =
          selectedFloorPlanFromTable?.isClickedFromTable;

        let tempFloorPlans = floorPlanLocations.filter(
          (el) => el.locationID !== matched.locationID
        );

        tempFloorPlans = update(tempFloorPlans, { $push: [matched] });
        this.handleState({
          floorPlanLocations: tempFloorPlans,
          selectedDataFromTable: selectedFloorPlanFromTable,
        });
      }
    }
  }

  handleState = (data: Partial<StateType>) => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          ...prevState,
          ...data,
        };
      });
  };

  handleMap = (map: Leaflet.Map) => {
    const { mapDetails, floorPlanLocationList, setMapDefaultParameters } =
      this.props;

    if (mapDetails.height && mapDetails.width) {
      const bounds = getBoundsUnproject(
        map,
        mapDetails.height,
        mapDetails.width,
        unprojectZoom
      );

      if (bounds) {
        map.fitBounds(bounds);
      }

      const tempFloorPlanLocationList = cloneDeep(floorPlanLocationList);

      const temp = tempFloorPlanLocationList.map((item) => {
        const point = getMapPoint({
          x: item?.x,
          y: item?.y,
        });
        const position = map.unproject(point, unprojectZoom);
        item.position = position;
        return item;
      });

      if (!mapDetails.defaultCoordinates) {
        const center = map.getBounds().getCenter();

        let tempCoordinates = map.project(
          map.getBounds().getCenter(),
          unprojectZoom
        );
        const coordinates = {
          x: tempCoordinates.x,
          y: tempCoordinates.y,
        };
        setMapDefaultParameters(center, coordinates);
      }

      this._isMounted &&
        this.setState({
          floorPlanLocations: temp,
          mapBounds: bounds,
          mapInstance: map,
        });
    }

    // map.on('click', (event: Leaflet.LeafletMouseEvent) =>
    //   this.onAddMapMarker(event, map)
    // );
  };

  onMoveMarker = (
    event: Leaflet.DragEndEvent,
    location: FloorPlanLocationListType
  ) => {
    const { movedFloorPlanLocations, mapInstance } = this.state;
    // this.markerRef.current?.getLatLng();
    const tempLatLng = event?.target?.getLatLng?.();

    const position = {
      lat: tempLatLng.lat,
      lng: tempLatLng.lng,
    };

    const coordinates = mapInstance?.project(tempLatLng, unprojectZoom);

    let temp: FloorPlanLocationListType[] = [];
    if (movedFloorPlanLocations.length > 0 && coordinates?.x) {
      temp = cloneDeep(movedFloorPlanLocations);
      const matched = temp.find((el) => el.locationID === location?.locationID);
      if (matched) {
        temp = temp.map((item) => {
          if (location.locationID === item.locationID) {
            item.x = coordinates.x;
            item.y = coordinates.y;
            item.position = position;
          }
          return item;
        });
      } else {
        temp.push({ ...location, position });
      }
    } else {
      temp.push({ ...location, position });
    }

    // const selectedMarkerID = event?.target?.options?.title;
    // if (selectedMarkerID) {
    //   mapInstance?.eachLayer((layer) => {
    //     const temp: any = layer;
    //     if (temp?.options?.title === selectedMarkerID) {
    //       layer?.openPopup();
    //     }
    //   });
    // }

    const temp2 = temp!;

    this._isMounted &&
      this.setState({ movedFloorPlanLocations: temp2, selectedLoc: {} });
  };

  // onAddMapMarker = (event: Leaflet.LeafletMouseEvent, map: Leaflet.Map) => {
  //   const { selectedLoc, floorPlanLocations } = this.state;
  //   if (selectedLoc?.locationID && map) {
  //     let position = event?.latlng;
  //     let tempCoordinates = map.project(position, unprojectZoom);
  //     const coordinates = {
  //       x: tempCoordinates.x,
  //       y: tempCoordinates.y,
  //     };
  //     const temp = cloneDeep(floorPlanLocations);
  //     temp.push({
  //       position,
  //       coordinates,
  //       floorplanID: '',
  //       locationID: selectedLoc.locationID,
  //       locationName: selectedLoc.locationName,
  //       x: '',
  //       y: '',
  //       height: '',
  //       Latest: '',
  //       ColourName: '',
  //       ColourValue: '',
  //       Sensors: '',
  //       uuid: selectedLoc.uuid,
  //     });
  //     this._isMounted &&
  //       this.setState({
  //         floorPlanLocations: temp,
  //         selectedLoc: {},
  //       });
  //   }
  // };

  handleReset = (location: Partial<FloorPlanLocationListType>) => {
    const { movedFloorPlanLocations, mapInstance } = this.state;
    let temp = cloneDeep(movedFloorPlanLocations);
    temp = temp.filter((item) => item.locationID !== location.locationID);
    mapInstance?.closePopup?.();
    this._isMounted &&
      this.setState({
        movedFloorPlanLocations: temp,
      });
  };

  handleDelete = (location: FloorPlanLocationListType) => {
    const { floorPlanLocations, mapInstance } = this.state;
    const { onRemoveLocation } = this.props;
    let temp = cloneDeep(floorPlanLocations);
    temp = temp.filter((item) => item.locationID !== location.locationID);
    mapInstance?.closePopup?.();
    onRemoveLocation(location);
    this._isMounted &&
      this.setState({
        floorPlanLocations: temp,
        selectedLoc: {},
      });
  };

  handleConfirm = (locationData: FloorPlanLocationListType) => {
    const { mapInstance, floorPlanLocations, movedFloorPlanLocations } =
      this.state;
    mapInstance?.closePopup?.();
    const { onUpdateLocation } = this.props;

    let tempFloorPlans = cloneDeep(floorPlanLocations);
    let temp = cloneDeep(movedFloorPlanLocations);
    // const foundLocation = movedFloorPlanLocations.find(
    //   (item) => item.locationID === locationData.locationID
    // );

    // if (foundLocation) {
    temp = temp.filter((item) => item.locationID !== locationData.locationID);
    tempFloorPlans = tempFloorPlans.filter(
      (item) => item.locationID !== locationData.locationID
    );

    tempFloorPlans.push({
      floorplanID: locationData.floorplanID,
      locationID: locationData.locationID,
      deviceID: locationData.deviceID,
      locationName: locationData.locationName,
      x: locationData.x,
      y: locationData.y,
      height: locationData.height,
      lastContact: locationData.lastContact,
      status: locationData.status,
      position: locationData.position,
      uuid: locationData.uuid,
    });
    onUpdateLocation(locationData);
    this._isMounted &&
      this.setState({
        movedFloorPlanLocations: temp,
        floorPlanLocations: tempFloorPlans,
      });
    // }
  };

  render() {
    const { mapDetails, classes, isEditable } = this.props;
    const {
      mapBounds,
      currentPosition,
      floorPlanLocations,
      movedFloorPlanLocations,
      selectedLoc,
    } = this.state;

    const mapImage = `data:image/svg+xml,${encodeURIComponent(
      mapDetails.image || ''
    )}`;

    const center = Leaflet.latLng(
      currentPosition.lat || 0,
      currentPosition.lng || 0
    );

    return (
      <Fragment>
        <MapContainer
          tap={false}
          className={classes}
          whenCreated={this.handleMap}
          center={center}
          crs={customCRS}
          zoom={unprojectZoom}>
          {mapDetails.image && mapBounds && (
            <ImageOverlay bounds={mapBounds} url={mapImage} />
          )}
          {floorPlanLocations.length > 0 &&
            floorPlanLocations.map((item) => {
              if (item.position) {
                const { locationName } = item;
                let position = item.position;

                let matchedLocation: FloorPlanLocationListType | undefined =
                  undefined;
                if (movedFloorPlanLocations.length > 0) {
                  matchedLocation = movedFloorPlanLocations.find(
                    (el) => el.locationID === item.locationID
                  );
                }

                if (matchedLocation?.position) {
                  position = matchedLocation.position;
                }

                const popupDetails = {
                  locationName,
                };
                if (matchedLocation?.locationName) {
                  popupDetails.locationName = matchedLocation.locationName;
                }

                let icon = BlueIcon;
                if (selectedLoc.locationID === item.locationID) {
                  icon = GreyIcon;
                }
                if (matchedLocation?.locationID === item.locationID) {
                  icon = RedIcon;
                }
                if (item.isClickedFromTable === true) {
                  icon = GreyIcon;
                }

                return (
                  <Fragment key={item.locationID}>
                    <Marker
                      key={item.locationID}
                      title={item.locationID}
                      icon={icon}
                      draggable={true}
                      position={[position?.lat, position?.lng]}
                      ref={this.markerRef}
                      eventHandlers={{
                        dragend: (event) => {
                          this.onMoveMarker(event, item);
                        },
                      }}>
                      <CustomPopup
                        popupDetails={popupDetails}
                        floorPlanLocation={item}
                        matchedLocation={matchedLocation}
                        handleDelete={this.handleDelete}
                        handleConfirm={this.handleConfirm}
                        handleReset={this.handleReset}
                        isEditable={isEditable}
                      />
                    </Marker>
                  </Fragment>
                );
              }
              return null;
            })}
        </MapContainer>
      </Fragment>
    );
  }
}

const CustomPopup = ({
  popupDetails,
  floorPlanLocation,
  matchedLocation,
  handleReset,
  handleConfirm,
  handleDelete,
  isEditable,
}: {
  popupDetails: { locationName: string };
  floorPlanLocation: FloorPlanLocationListType;
  matchedLocation?: FloorPlanLocationListType;
  handleReset: (location: FloorPlanLocationListType) => void;
  handleConfirm: (location: FloorPlanLocationListType) => void;
  handleDelete: (location: FloorPlanLocationListType) => void;
  isEditable: boolean;
}) => {
  return (
    <Popup>
      <Row justify="center">
        <Col>
          {/* <pre>{JSON.stringify(popupDetails, null, 2)}</pre> */}
          Location Name: {popupDetails.locationName}
        </Col>
      </Row>
      <Row justify="center" className="pt-2">
        <Col>Do you want to update?</Col>
      </Row>
      <Row justify="center" className="pt-4">
        <Col className="text-center">
          <Button
            disabled={!isEditable}
            size={`small`}
            onClick={(event) =>
              handleReset(matchedLocation || floorPlanLocation)
            }>
            Cancel
          </Button>
        </Col>
        <Col className="text-center pl-2">
          <Button
            disabled={!isEditable}
            type="primary"
            size={`small`}
            onClick={(event) =>
              handleConfirm(matchedLocation || floorPlanLocation)
            }>
            Yes
          </Button>
        </Col>
        <Col className="text-center pl-2">
          <Button
            disabled={!isEditable}
            danger
            type="primary"
            size={`small`}
            onClick={(event) =>
              handleDelete(matchedLocation || floorPlanLocation)
            }>
            Remove
          </Button>
        </Col>
      </Row>
    </Popup>
  );
};

// type DraggableMarkerPropsType = {
//   lat: number;
//   lng: number;
//   icon: Leaflet.Icon;
//   popupDetails: { locationName: string };
//   floorPlanLocation: Partial<FloorPlanLocationListType>;
//   onMoveMarker: (
//     event: Leaflet.DragEndEvent,
//     location: Partial<FloorPlanLocationListType>
//   ) => void;
// };
// const DraggableMarker = forwardRef<Leaflet.Marker<any>, DraggableMarkerPropsType>(
//   (
//     { lat, lng, icon, popupDetails, onMoveMarker, floorPlanLocation },
//     ref
//   ) => {
//     return (
//       <Marker
//         key={floorPlanLocation.locationID}
//         title={floorPlanLocation.locationID}
//         icon={icon}
//         draggable={true}
//         position={[lat, lng]}
//         ref={ref}
//         eventHandlers={{
//           dragend: (event) => {
//             onMoveMarker(event, floorPlanLocation);
//           },
//         }}>
//         {/* <Popup>
//           Current location: <pre>{JSON.stringify({ lat, lng }, null, 2)}</pre>
//         </Popup> */}
//         <CustomPopup popupDetails={popupDetails} />
//       </Marker>
//     );
//   }
// );
