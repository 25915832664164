import { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { navbarLinks } from './helpers';
import DefaultNav from './DefaultNav';
import MobileNav from './MobileNav';

import cssStyles from './styles/navbar.module.scss';
import { authLogout } from '../../redux/actions';
import { getUserData, getUserPermissionList } from '../../redux/selectors';

const AntdNavbar = () => {
  const userData = useSelector(getUserData);
  const userPermissionList = useSelector(getUserPermissionList);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(authLogout());
  };

  return (
    <Fragment>
      <div className={`container-fluid px-0 py-0 ${cssStyles.navbar_root}`}>
        <div className="default_nav">
          <DefaultNav
            navbarLinks={navbarLinks}
            userPermissionList={userPermissionList}
            isAuth={!!userData.token}
            userData={userData}
            handleLogout={handleLogout}
          />
        </div>
        <div className="mobile_nav">
          <MobileNav
            navbarLinks={navbarLinks}
            userPermissionList={userPermissionList}
            isAuth={!!userData.token}
            userData={userData}
            handleLogout={handleLogout}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default AntdNavbar;
