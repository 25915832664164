import { Fragment, ReactNode, FC, memo } from 'react';
import { Card } from 'antd';

import styles from './antdCard.module.scss';
import { CardProps } from 'antd/lib/card';

interface PropsType extends CardProps {
  loading?: boolean;
  children: ReactNode;
  classNames?: string;
}

const AntdCard: FC<PropsType> = ({
  loading,
  children,
  classNames,
  ...rest
}) => {
  return (
    <Fragment>
      <Card
        {...rest}
        loading={loading}
        className={`${styles.card} ${classNames || ''}`}>
        {children}
      </Card>
    </Fragment>
  );
};

export default memo(AntdCard);
