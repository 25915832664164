import { notification } from 'antd';
import { capitalizeFirstLetter } from '.';

type NotificationDetailsType = {
  status?:
    | 'success'
    | 'error'
    | 'warning'
    | 'info'
    | 'ok'
    | 'invalidTokenError';
  message?: string;
  data?: any;
  token?: string;
};

export function handleNotification(
  type: 'success' | 'error' | 'warning' | 'info',
  details: NotificationDetailsType,
  options?: { duration?: number; onClose?: () => void }
) {
  let duration = options?.duration || 0;
  const message = capitalizeFirstLetter(type);
  let description = '';
  if (details?.message && !skipErrorMessagesList.includes(details.message)) {
    description = details.message;
  }

  duration = type !== 'error' ? 1.5 : 0;

  notification[type]({
    message: message,
    description: description,
    duration: duration,
    onClose: options?.onClose,
  });
}

const skipErrorMessagesList = [`Cannot read property 'message' of null`];
