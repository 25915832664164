import {
  Fragment,
  useState,
  useRef,
  useEffect,
  useCallback,
  FormEvent,
} from 'react';
import { Button, Col, Input, Row } from 'antd';
import axios, { CancelTokenSource } from 'axios';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { v4 } from 'uuid';

import AntdTable from '../../AntdTable';
import { updateToken } from '../../../redux/actions';

import NotificationHandler from '../../NotificationHandler';
import {
  ApiErrorType,
  ApiSuccessType,
  StockDevicesType,
  UserDataType,
} from '../../../type-definitions/api-types';
import { apiCall } from '../../../api-services/api';
import { deviceApi } from '../../../api-services/api-list';
import { getKeysFromEnum, handleTableSearch } from '../../../utils';
import { StockDevicesEnumKeys } from '../../../api-services/api-responses';
// import AddModal from './AddModal';
// import MoveModal from './MoveModal';
// import SwapModal from './SwapModal';

const initState = {
  loading: true,
  error: {},
  success: {},
  searchValue: '',
  initDevicesTabStockDevicesList: [],
  devicesTabStockDevicesList: [],
  showAddModal: false,
  showMoveModal: false,
  showSwapModal: false,
  moveSelectedDeviceID: '',
  swapSelectedDeviceID: '',
};

type StateType = {
  loading: boolean;
  error: ApiErrorType;
  success: ApiSuccessType;
  searchValue: string;
  initDevicesTabStockDevicesList: StockDevicesType[];
  devicesTabStockDevicesList: StockDevicesType[];
  showAddModal: boolean;
  showMoveModal: boolean;
  showSwapModal: boolean;
  moveSelectedDeviceID: string;
  swapSelectedDeviceID: string;
};

const stockDevicesTableColumnKeys = getKeysFromEnum(StockDevicesEnumKeys);

type PropsType = {
  locationID?: string;
  deviceModalStatus: '' | 'closed';
  userData: Partial<UserDataType>;
  openSwapModal: (id?: string) => void;
  openMoveModal: (id?: string) => void;
  openAddAssignModal: () => void;
  handleRedirectToDeviceDetails: (deviceID: string) => void;
};

function DevicesTab({
  locationID,
  userData,
  openAddAssignModal,
  openMoveModal,
  openSwapModal,
  deviceModalStatus,
  handleRedirectToDeviceDetails,
}: PropsType) {
  const [state, setState] = useState<Readonly<StateType>>(initState);
  const _isMounted = useRef(false);
  const axiosCancelSource = useRef<CancelTokenSource>();
  const dispatch = useDispatch();

  function handleState(data: object) {
    _isMounted.current &&
      setState((prevState) => ({
        ...prevState,
        ...data,
      }));
  }

  useEffect(() => {
    axiosCancelSource.current = axios.CancelToken.source();
    _isMounted.current = true;
    return () => {
      _isMounted.current = false;
      axiosCancelSource.current?.cancel('Component Unmounted');
    };
  }, []);

  useEffect(() => {
    if (locationID && state.initDevicesTabStockDevicesList.length === 0) {
      fetchDevicesTabData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (deviceModalStatus === 'closed') {
      handleState({ loading: true });
      fetchDevicesTabData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceModalStatus]);

  const fetchDevicesTabData = useCallback(async () => {
    try {
      const { url, method, contentType, params } = deviceApi.getStockDevices({
        locationID: locationID || '',
      });
      const response = await apiCall({
        storeToken: userData?.token,
        url,
        method,
        params,
        contentType,
        cancelToken: axiosCancelSource.current?.token,
      });
      const result = response?.data;
      const stateData: Partial<StateType> = {
        loading: false,
      };

      dispatch(updateToken(result));

      if (result?.status === 'ok') {
        if (result.data && result.data.length > 0) {
          const tempData: StockDevicesType[] = result.data.map(
            (item: StockDevicesType) => ({
              ...item,
              uuid: v4(),
            })
          );
          stateData.devicesTabStockDevicesList = tempData;
          stateData.initDevicesTabStockDevicesList = tempData;
        } else {
          stateData.devicesTabStockDevicesList = [];
          stateData.initDevicesTabStockDevicesList = [];
        }
      } else {
        stateData.error = result;
      }

      handleState({ ...stateData });
    } catch (error) {
      handleState({ error });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationID, userData?.token]);

  function handleOnChange(event: FormEvent<HTMLInputElement>) {
    const value = event?.currentTarget?.value;
    const stateData: Partial<StateType> = {
      searchValue: value,
    };
    if (value) {
      let tempData = cloneDeep(state.devicesTabStockDevicesList);
      tempData = handleTableSearch({
        data: tempData,
        columnList: stockDevicesTableColumnKeys,
        searchData: value,
      });
      stateData.devicesTabStockDevicesList = tempData;
    } else {
      stateData.devicesTabStockDevicesList =
        state.initDevicesTabStockDevicesList;
    }
    handleState({ ...stateData });
  }

  return (
    <Fragment>
      <NotificationHandler
        success={state.success}
        error={state.error}
        obj={{ setState, _isMounted: _isMounted.current }}
      />
      <Row justify="center" className="py-2">
        <Col>
          <Button
            // disabled
            type="primary"
            loading={state.loading}
            // disabled={state.showDevicesList}
            htmlType="button"
            onClick={openAddAssignModal}>
            Add Device
          </Button>
        </Col>
      </Row>
      <Row justify="end" className="py-3">
        <Col>
          <Input
            disabled={state.loading}
            type="text"
            value={state.searchValue}
            placeholder="Search"
            name="searchValue"
            onChange={handleOnChange}
          />
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={24}>
          <AntdTable
            loading={state.loading}
            columns={getStockDeviceColumns({
              openSwapModal,
              openMoveModal,
              handleRedirectToDeviceDetails,
            })}
            dataSource={state.devicesTabStockDevicesList}
            pagination={{
              pageSize: 5,
              total: state.devicesTabStockDevicesList.length,
            }}
            customConfig={{ rowKeyValue: 'uuid' }}
          />
        </Col>
      </Row>
    </Fragment>
  );
}

export default DevicesTab;

function getStockDeviceColumns(parms: {
  openMoveModal: (id: string) => void;
  openSwapModal: (id: string) => void;
  handleRedirectToDeviceDetails: (deviceID: string) => void;
}) {
  return [
    // 0
    {
      title: 'Device ID',
      dataIndex: 'deviceID',
      key: 'deviceID',
      width: '20%',
    },
    // 1
    {
      title: 'Manufacturer',
      dataIndex: 'manufacturer',
      key: 'manufacturer',
      width: '20%',
    },
    // 2
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
      width: '20%',
    },
    // 3
    {
      title: 'Partner ID',
      dataIndex: 'partnerID',
      key: 'partnerID',
      width: '20%',
    },
    // 4
    {
      title: 'Action',
      key: 'action',
      width: '25%',
      render: (text: string, record: StockDevicesType) => {
        return <StockDeviceAction record={record} {...parms} />;
      },
    },
  ];
}

function StockDeviceAction({
  record,
  openMoveModal,
  openSwapModal,
  handleRedirectToDeviceDetails,
}: {
  record: StockDevicesType;
  openMoveModal: (id: string) => void;
  openSwapModal: (id: string) => void;
  handleRedirectToDeviceDetails: (deviceID: string) => void;
}) {
  return (
    <Fragment>
      <div className="row">
        <div className="col-md-12 text-center">
          <Button
            size="small"
            type="primary"
            htmlType="button"
            onClick={() => openSwapModal(record.deviceID)}>
            Swap Device
          </Button>
        </div>
        <div className="col-md-12 text-center pt-2">
          <Button
            size="small"
            type="primary"
            htmlType="button"
            onClick={() => openMoveModal(record.deviceID)}>
            Move Device
          </Button>
        </div>
        <div className="col-md-12 text-center pt-2">
          <Button
            size="small"
            type="primary"
            htmlType="button"
            onClick={() => handleRedirectToDeviceDetails(record.deviceID)}>
            Device Details
          </Button>
        </div>
      </div>
    </Fragment>
  );
}
