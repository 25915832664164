import { Fragment } from 'react';
import { Row, Col, Checkbox } from 'antd';

import { FormElementsType } from '../helpers';
import AntdInput from '../../AntdInput';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

type PropsType = {
  formElements: FormElementsType;
  inputChangedHandler: (name: any, value: any) => void;
  handleCheckboxFormElements: (event: CheckboxChangeEvent) => void;
};

const CopyrightTab = ({
  formElements,
  inputChangedHandler,
  handleCheckboxFormElements,
}: PropsType) => {
  return (
    <Fragment>
      <Row align="middle" className="my-2">
        <Col xs={12}>
          <Checkbox
            name={`defaultCopyright`}
            onChange={handleCheckboxFormElements}
            checked={formElements.defaultCopyright}>
            {`Default Copyright`}
          </Checkbox>
        </Col>
      </Row>
      <Row className="pt-3">
        <Col xs={24}>
          <AntdInput
            {...formElements.copyright}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default CopyrightTab;
