import { Fragment, useState, useCallback } from 'react';
import { Modal, Row, Col, Input, Select, Button } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { AiOutlineCloseCircle } from 'react-icons/ai';

interface PropsType extends ModalProps {}

const modelList = ['AM107', 'UG65'];

const UrsalinkModal = ({ ...rest }: PropsType) => {
  const [serialInput, setSerialInput] = useState<string>('');
  const [euiInput, setEuiInput] = useState<string>('');
  const [modelInput, setModelInput] = useState<string>('');

  const onButtonClick = useCallback(() => {
    window.alert('This is where we send the request');
  }, []);

  let btnDisabled = true;
  if (serialInput && euiInput && modelInput) {
    btnDisabled = false;
  }

  return (
    <Fragment>
      <Modal
        {...rest}
        closeIcon={<AiOutlineCloseCircle size="1.3em" />}
        footer={null}
        width={400}>
        <Row className="py-3">
          <Col xs={24}>
            <Row className="py-2" align="middle">
              <Col xs={24} md={4} style={{ textAlign: 'start' }}>
                <strong>Model</strong>
              </Col>
              <Col xs={24} md={20}>
                <Select
                  value={modelInput}
                  onChange={(value) => setModelInput(value)}
                  style={{ width: '100%' }}>
                  <Select.Option value={''}>Select Model</Select.Option>
                  {modelList.map((el) => (
                    <Select.Option key={el} value={el}>
                      {el}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>

            <Row className="py-2" align="middle">
              <Col xs={24} md={4} style={{ textAlign: 'start' }}>
                <strong>Serial</strong>
              </Col>
              <Col xs={24} md={20}>
                <Input
                  value={serialInput}
                  onChange={(e) => setSerialInput(e.currentTarget.value)}
                />
              </Col>
            </Row>

            <Row className="py-2">
              <Col xs={24} md={4} style={{ textAlign: 'start' }}>
                <strong>EUI</strong>
              </Col>
              <Col xs={24} md={20}>
                <Input
                  value={euiInput}
                  onChange={(e) => setEuiInput(e.currentTarget.value)}
                />
              </Col>
            </Row>

            <Row className="py-2" justify="center">
              <Col xs={24} sm={12} md={8}>
                <Button
                  block
                  type="primary"
                  htmlType="button"
                  disabled={btnDisabled}
                  onClick={onButtonClick}>
                  Add
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </Fragment>
  );
};

export default UrsalinkModal;
