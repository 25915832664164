import {
  Dispatch,
  FormEvent,
  Fragment,
  useReducer,
  useCallback,
  MouseEvent,
  useEffect,
} from 'react';
import { Alert, Button, Col, message, Row, Tag } from 'antd';
import { RouteComponentProps } from 'react-router';
import update from 'immutability-helper';

import FormWrapper from '../components-shared/FormWrapper';
import AntdInput from '../components/AntdInput';
import {
  StateType,
  reducer,
  initState,
  FormElementsType,
  actionTypes,
} from '../components/BugReporting/helpers';
import useHttp from '../hooks/use-http-allSettled';
import { ReducerHookActionType, SelectOptionsType } from '../type-definitions';
import {
  ApiResponseConfigDataType,
  ApiResponseDataType,
  TagsType,
  UserDataType,
} from '../type-definitions/api-types';
import { checkValidation } from '../utils/validation';
import PromptPopup from '../components/PromptPopup';
import { privateApi } from '../api-services/api-list';
import { handleNotification } from '../utils/notification-handler';

interface PropsType extends RouteComponentProps {
  userData: Partial<UserDataType>;
  userPermissionList: string[];
}

const BugReporting = ({ userData }: PropsType) => {
  const [state, dispatchToState]: [
    state: StateType,
    dispatchToState: Dispatch<ReducerHookActionType>
  ] = useReducer(reducer, initState);
  const { isLoading, sendRequest } = useHttp();

  const {
    formElements,
    selectedLabels,
    hasInputChanged,
    labelsCollection,
    successData,
  } = state;

  useEffect(() => {
    if (userData.token) {
      const { url, method, contentType } = privateApi.getTags();

      const handleResponses = (
        responses: PromiseSettledResult<
          ApiResponseConfigDataType<ApiResponseDataType>
        >[]
      ) => {
        if (responses.length > 0) {
          if (responses[0].status === 'fulfilled') {
            const result = responses?.[0].value;
            if (result.data?.data) {
              const tempLabels: SelectOptionsType[] = result.data.data.map(
                (el: TagsType) => ({ text: el.description, value: el.name })
              );

              dispatchToState({
                type: actionTypes.setState,
                payload: { labelsCollection: tempLabels },
              });
            }
          } else {
            handleNotification('error', {
              message: responses[0].reason?.response?.data?.message,
            });
          }
        }
      };

      sendRequest({
        requestConfig: [{ url, method, contentType }],
        headersConfig: { storeToken: userData.token },
        applyData: handleResponses,
      });
    }
  }, [sendRequest, userData.token]);

  const handleSubmit = useCallback(
    (onConfirm?: () => void) => {
      let tempFormElements = { ...formElements };

      let key: keyof FormElementsType;

      for (key in tempFormElements) {
        tempFormElements = update(tempFormElements, {
          [key]: {
            touched: { $set: true },
            valid: {
              $set: checkValidation(
                tempFormElements[key].value,
                tempFormElements[key].validation
              ),
            },
          },
        });
      }

      dispatchToState({
        type: actionTypes.setState,
        payload: { formElements: tempFormElements },
      });

      for (key in tempFormElements) {
        if (!tempFormElements[key].valid) {
          message.error('Please fill all the required fields!');
          return;
        }
      }

      if (selectedLabels.length === 0) {
        message.error('Please select at least one tag!');
        return;
      }

      const data = {
        title: tempFormElements.title.value,
        body: tempFormElements.body.value,
        labels: selectedLabels,
      };

      if (userData.token) {
        const { url, method, contentType } = privateApi.postIssues();

        const handleResponses = (
          responses: PromiseSettledResult<
            ApiResponseConfigDataType<ApiResponseDataType>
          >[]
        ) => {
          if (responses.length > 0) {
            if (responses[0].status === 'fulfilled') {
              const result = responses?.[0].value;
              handleNotification(
                'success',
                { message: result.data?.message },
                {
                  onClose: onConfirm,
                }
              );
              dispatchToState({
                type: actionTypes.setState,
                payload: {
                  formElements: initState.formElements,
                  selectedLabels: [],
                  hasInputChanged: false,
                  successData: result,
                },
              });
            } else {
              handleNotification('error', {
                message: responses[0].reason?.response?.data?.message,
              });
            }
          }
        };

        sendRequest({
          requestConfig: [{ url, method, data, contentType }],
          headersConfig: { storeToken: userData.token },
          applyData: handleResponses,
        });
      }
    },
    [formElements, selectedLabels, sendRequest, userData.token]
  );

  const onFormSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      handleSubmit();
    },
    [handleSubmit]
  );

  const handlePopupConfirm = useCallback(
    (onConfirm: () => void) => {
      handleSubmit(onConfirm);
    },
    [handleSubmit]
  );

  const onInputChanged = useCallback(
    (name: keyof FormElementsType, value: any) => {
      let tempFormElements = { ...formElements };

      if (name) {
        tempFormElements = update(tempFormElements, {
          [name]: {
            value: { $set: value },
            touched: { $set: true },
            valid: {
              $set: checkValidation(value, tempFormElements[name].validation),
            },
          },
        });

        dispatchToState({
          type: actionTypes.setState,
          payload: {
            formElements: tempFormElements,
            hasInputChanged: true,
            successData: {},
          },
        });
      }
    },
    [formElements]
  );

  const onTagClick = useCallback((event: MouseEvent<HTMLElement>) => {
    dispatchToState({
      type: actionTypes.tagClick,
      payload: {
        labelVal: event.currentTarget.id,
        hasInputChanged: true,
        successData: {},
      },
    });
  }, []);

  return (
    <Fragment>
      {hasInputChanged && (
        <PromptPopup
          when={hasInputChanged}
          handleConfirm={handlePopupConfirm}
        />
      )}
      <FormWrapper loading={isLoading} title="Bug Reporting">
        <form onSubmit={onFormSubmit} noValidate>
          <Row justify="center" align="middle">
            <Col xs={24}>
              <AntdInput
                {...formElements.title}
                onInputChanged={onInputChanged}
              />
            </Col>
          </Row>
          <Row justify="center" align="middle">
            <Col xs={24}>
              <AntdInput
                {...formElements.body}
                onInputChanged={onInputChanged}
              />
            </Col>
          </Row>

          <Row
            align="middle"
            className="pt-3"
            style={{ maxHeight: '30vh', overflowY: 'auto' }}>
            {labelsCollection.length > 0 &&
              labelsCollection.map(({ text, value }) => {
                const matched = selectedLabels.find((el) => el === value);
                return (
                  <Fragment key={value}>
                    <Col className="p-2">
                      <Tag
                        color={matched ? 'blue' : undefined}
                        style={{ cursor: 'pointer' }}
                        onClick={onTagClick}
                        id={value}>
                        {text || value}
                      </Tag>
                    </Col>
                  </Fragment>
                );
              })}
          </Row>

          <Row className="pt-4" justify="center">
            <Col xs={24} md={8}>
              <Button type="primary" htmlType="submit" size="large" block>
                Submit
              </Button>
            </Col>
          </Row>

          <Row justify="center" align="middle" className="pt-4">
            <Col>
              {successData.data && (
                <Alert
                  type="success"
                  message="Success"
                  description={
                    <Fragment>
                      <Row>
                        <Col xs={24}>
                          <a
                            href={`${successData.data}`}
                            target="_blank"
                            rel="noopener noreferrer">
                            {`${successData.data}`}
                          </a>
                        </Col>
                      </Row>
                    </Fragment>
                  }
                />
              )}
            </Col>
          </Row>
        </form>
      </FormWrapper>
    </Fragment>
  );
};

export default BugReporting;
