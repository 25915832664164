export const browserStorageKey = {
  shared: {
    pagination: `shared/pagination`,
  },
};

export const colorCodes = {
  green: '#01F60D',
  blue: '#2F85CB',
  red: '#EB0C1B',
  grey: '#C4C4C4',
  amber: '#EF9D10',
};

export const errorConstants = {
  required: (name: string) => {
    return `${name} is required!`;
  },
};

export const reducerHookActions = {
  setState: 'SET_STATE',
};
