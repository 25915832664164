import { Fragment } from 'react';
import { Button, Row, Col } from 'antd';
import { FormElementsType } from '../helpers';
import AntdInput from '../../AntdInput';

function DeviceTab({
  formElements,
  inputChangedHandler,
  setCurrentTabIndex,
}: {
  formElements: FormElementsType;
  inputChangedHandler: (name: any, value: any) => void;
  setCurrentTabIndex: (index: string) => void;
}) {
  return (
    <Fragment>
      <Row justify="center" align="middle">
        <Col xs={24}>
          <AntdInput
            {...formElements.locationMac}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>

      <Row
        justify="center"
        align="middle"
        gutter={[{ sm: 16, md: 16, lg: 16 }, { xs: 16 }]}>
        <Col xs={24} sm={12} md={8}>
          <Button
            block
            type="primary"
            htmlType="button"
            size="large"
            onClick={() => setCurrentTabIndex('4')}>
            Back
          </Button>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Button block type="primary" htmlType="submit" size="large">
            Submit
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
}

export default DeviceTab;
