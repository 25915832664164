import moment from 'moment';

import {
  AxiosErrorResponseType,
  UserRightListType,
  ApiResponseDataType,
} from '../type-definitions/api-types';

export function handleError({
  message,
}: {
  message: string;
}): ApiResponseDataType {
  return { status: 'error', message: message || 'Some error occured!' };
}

export function handleNetworkError(err: any) {
  return !!err.isAxiosError && !err.response;
}

export function handleApiError(error: {
  response: AxiosErrorResponseType<ApiResponseDataType>;
}): AxiosErrorResponseType<ApiResponseDataType> {
  const errorResponse = error?.response;
  if (errorResponse) {
    if (errorResponse.status === 404) {
      return {
        ...errorResponse,
        data: {
          status: 'error',
          message: errorResponse.statusText,
        },
      };
    }
  } else if (handleNetworkError(error)) {
    return {
      data: { status: 'error', message: 'Network Error!' },
    };
  }

  return errorResponse;
}

// export const handleUserPermssion = (
//   userRightList: UserRightListType[],
//   partnerID: string
// ) => {
//   let userPermission: string[] = [];
//   userRightList.forEach((item, index) => {
//     if (partnerID === item.partnerid) {
//       userPermission = [...item.Rights];
//     }
//   });
//   return userPermission;
// };

export const handleUserPermssion = (userRightList: UserRightListType[]) => {
  let userPermission: string[] = [];
  userRightList.forEach((item) => {
    userPermission.push(...item.Rights);
  });

  userPermission = Array.from(new Set(userPermission));

  return userPermission;
};

export function getUtcTime({
  date,
  format,
}: {
  date: any;
  format?: 'date' | 'zuluFormat' | 'dateTime' | 'time';
}): string {
  const moment = require('moment');
  const dateFormat = 'YYYY-MM-DD';
  const timeFormat = 'HH:mm:ss';
  const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
  const zuluFormat = 'YYYY-MM-DD HH:mm:ss Z';
  let utcFormat;
  if (format === 'date') {
    utcFormat = dateFormat;
  } else if (format === 'time') {
    utcFormat = timeFormat;
  } else if (format === 'dateTime') {
    utcFormat = dateTimeFormat;
  } else if (format === 'zuluFormat') {
    utcFormat = zuluFormat;
  }

  const utcTime = moment.utc(date).format(utcFormat);
  return utcTime;
}

export function getLocalTime({
  date,
  format,
}: {
  date: object | string;
  format: string;
}): string {
  const momentTimezone = require('moment-timezone');
  const dateFormat = 'YYYY-MM-DD';
  const fullFormat = 'YYYY-MM-DD HH:mm:ssZ';
  const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
  const timeFormat = 'HH:mm:ss';
  let utcFormat;
  if (format === 'date') {
    utcFormat = dateFormat;
  }
  if (format === 'full') {
    utcFormat = fullFormat;
  }
  if (format === 'dateTime') {
    utcFormat = dateTimeFormat;
  }
  if (format === 'time') {
    utcFormat = timeFormat;
  }
  const currentTimeZone = momentTimezone.tz.guess(true);
  return momentTimezone.tz(date, currentTimeZone).format(utcFormat);
}

export function getZuluFormatUTC(date?: string, time?: string) {
  let dateTime: any = '';
  if (!date && !time) {
    dateTime = moment().toDate();
  } else if (time && !date) {
    dateTime = `${getLocalCurrentDate()} ${time}`;
  } else if (date && !time) {
    dateTime = `${date} 00:00:00`;
  } else {
    dateTime = `${date} ${time}`;
  }

  // const convertedDate = new Date(dateTime).toISOString();
  // const convertedDate = moment(dateTime).toISOString();
  const convertedDate = moment.utc(dateTime).toISOString();
  return convertedDate;
}

export function getLocalCurrentDate() {
  const today = new Date();
  const year = today.getFullYear().toString();
  let month = today.getMonth().toString();
  let date = today.getDate().toString();
  if (month.length === 1) {
    month = `0${month}`;
  }
  if (date.length === 1) {
    date = `0${date}`;
  }
  return `${year}-${month}-${date}`;
}

export function handleSorting(first?: string, second?: string) {
  if (first && second) {
    const nameA = first.toUpperCase();
    const nameB = second.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  }
}

export function handleTableSearch({
  data,
  columnList,
  searchData,
}: {
  data: any;
  columnList: string[];
  searchData: string;
}) {
  searchData = searchData.replace(/\\/g, '');
  const filteredData = data.filter((item: any) => {
    let temp = null;
    columnList.forEach((el) => {
      if (el && typeof item[el] === 'string') {
        if (item[el].toLowerCase().search(searchData.toLowerCase()) !== -1) {
          temp = item;
        }
      }
    });
    return temp;
  });

  return filteredData;
}

export function generateRandomID() {
  return (
    Date.now() +
    '-' +
    Math.random().toString(36).substr(2) +
    '-' +
    Math.floor(Math.random() * 100)
  );
}

export function getKeysFromEnum(data: { [key: number]: string }) {
  return Object.values(data).filter((value) => typeof value === 'string');
}

export function handleFormBody(data: { [k: string]: any }) {
  let tempData = [];
  for (const key in data) {
    const encodedKey = encodeURIComponent(key);
    const encodedValue = encodeURIComponent(data[key]);
    tempData.push(encodedKey + '=' + encodedValue);
  }
  const formBody: string = tempData.join('&');

  return formBody;
}

export function getDuplicatesArrayIndex(array: any[]) {
  const newArray = [];

  for (let i = 0; i < array.length; i++) {
    for (let j = i + 1; j < array.length; j++) {
      // quick elimination by comparing subarray lengths
      if (array[i].length !== array[j].length) {
        continue;
      }
      // look for dupes
      let dupe = true;
      for (let k = 0; k < array[i].length; k++) {
        if (array[i][k] !== array[j][k]) {
          dupe = false;
          break;
        }
      }
      // if a dupe then print
      if (dupe) {
        newArray.push(j);
      }
    }
  }
  return newArray;
}

export const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const generateRandomNumber = () => {
  return Date.now() + Math.floor(Math.random() * 100);
};

export const getCurrentUTCDate = () => {
  return getUtcTime({ date: new Date(), format: 'date' });
};

export const generatePassword = (params?: {
  length?: number;
  isSpecialChars?: boolean;
}): string => {
  const length = params?.length ?? 8;
  let charset =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let charsetWithSpecialChars =
    '0123456789abcdefghijklmnopqrstuvwxyz!@#$%&*_ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let source = charset;
  if (params?.isSpecialChars) {
    source = charsetWithSpecialChars;
  }
  let pass = '';
  for (let i = 0, n = source.length; i < length; ++i) {
    pass += source.charAt(Math.floor(Math.random() * n));
  }
  return pass;
};
