import { Fragment } from 'react';
import { Checkbox, Button } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Row, Col } from 'antd';

import { FormElementsType } from '../helpers';
import AntdInput from '../../AntdInput';

function DetailsTab({
  formElements,
  inputChangedHandler,
  onCancelMapModal,
  staticCheck,
  handleCheckbox,
  setCurrentTabIndex,
}: {
  formElements: FormElementsType;
  inputChangedHandler: (name: any, value: any) => void;
  setCurrentTabIndex: (index: string) => void;
  onCancelMapModal: () => void;
  staticCheck: boolean;
  handleCheckbox: (event: CheckboxChangeEvent) => void;
}) {
  return (
    <Fragment>
      <Row justify="center" align="middle">
        <Col xs={24}>
          <AntdInput
            {...formElements.locationID}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <Col xs={24}>
          <AntdInput
            {...formElements.partnerName}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <Col xs={24}>
          <AntdInput
            {...formElements.locationName}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <Col xs={24} md={8} className="pr-md-2">
          <AntdInput
            {...formElements.latitude}
            onInputChanged={inputChangedHandler}
          />
        </Col>
        <Col xs={24} md={8} className="pl-md-2 pr-md-2">
          <AntdInput
            {...formElements.longitude}
            onInputChanged={inputChangedHandler}
          />
        </Col>
        <Col xs={24} md={8} className="pl-md-4 pt-md-0_75">
          <Button type="primary" htmlType="button" onClick={onCancelMapModal}>
            MAP
          </Button>
        </Col>
      </Row>

      <Row align="middle" className="pt-3 pt-md-0">
        <Col xs={24} md={12}>
          <AntdInput
            {...formElements.status}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>

      {formElements.status.value === 'STOCK' && (
        <Row align="middle" className="pt-3 pt-md-0">
          <Col xs={24} md={12}>
            <AntdInput
              {...formElements.capacity}
              onInputChanged={inputChangedHandler}
            />
          </Col>
        </Row>
      )}

      <Row align="middle" className="my-2">
        <Col xs={12}>
          <Checkbox
            name={`staticCheck`}
            onChange={handleCheckbox}
            checked={staticCheck}>
            {`Static`}
          </Checkbox>
        </Col>
      </Row>

      <Row align="middle" className="pt-2">
        <Col xs={24}>
          <AntdInput
            {...formElements.friendlyName}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>

      <Row align="middle" className="pt-2">
        <Col xs={24}>
          <AntdInput
            {...formElements.friendlyDesc}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>

      <Row justify="center">
        <Col xs={24} sm={12} md={8}>
          <Button
            block
            type="primary"
            htmlType="button"
            size="large"
            onClick={() => setCurrentTabIndex('2')}>
            Next
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
}

export default DetailsTab;
