import { Button } from 'antd';
import { Fragment } from 'react';
import Leaflet from 'leaflet';
import {
  FloorPlanLocationListType,
  LocationListType,
} from '../../../type-definitions/api-types';
// import FloorPlanMapMultiple from '../FloorPlanMapMultiple';
import { FormElementsType } from '../helpers';
import FloorPlanMapMultiple from '../FloorPlanMapMultiple';

import cssStyles from '../styles/floorPlanDetails.module.scss';
import { MapDetailsType } from '../../../type-definitions';

function LocationsTab({
  mapDetails,
  floorPlanLocationList,
  selectedLocation,
  onUpdateLocation,
  onRemoveLocation,
  handleAddLocationModal,
  setMapDefaultParameters,
}: {
  formElements: FormElementsType;
  inputChangedHandler: (name: any, value: any) => void;
  mapDetails: MapDetailsType;
  floorPlanLocationList: FloorPlanLocationListType[];
  selectedLocation: LocationListType | {};
  onUpdateLocation: (data: FloorPlanLocationListType) => void;
  onRemoveLocation: (data: FloorPlanLocationListType) => void;
  handleAddLocationModal: () => void;
  setMapDefaultParameters: (
    position: Leaflet.LatLng,
    coordinates: { x: number; y: number }
  ) => void;
}) {
  return (
    <Fragment>
      <div className="row justify-content-center py-3">
        <div className="col-lg-8 col-md-6 col-sm-6 text-center">
          <Button onClick={handleAddLocationModal} type="primary" size="middle">
            Add Location
          </Button>
        </div>
      </div>

      <div className={`row`}>
        <div className={`col-lg-12`}>
          <FloorPlanMapMultiple
            mapDetails={mapDetails}
            // updateLocation={updateLocation}
            floorPlanLocationList={floorPlanLocationList}
            selectedLocation={selectedLocation}
            // removeLocation={removeLocation}
            classes={cssStyles.floorPlanMap}
            onUpdateLocation={onUpdateLocation}
            onRemoveLocation={onRemoveLocation}
            setMapDefaultParameters={setMapDefaultParameters}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default LocationsTab;
