import { RouteComponentProps } from 'react-router-dom';
import { FormInputType, MapDetailsType } from '../../../../type-definitions';
import {
  ApiErrorType,
  ApiSuccessType,
  FloorPlanLocationListType,
  LocationListType,
} from '../../../../type-definitions/api-types';

export interface FormElementsType {
  floorPlanName: FormInputType;
  description: FormInputType;
  partnerID: FormInputType;
  topLeftLat: FormInputType;
  topLeftLng: FormInputType;
  bottomRightLat: FormInputType;
  bottomRightLng: FormInputType;
}

export interface MapFormElementsType {
  radius: FormInputType;
}

export const detailsTabElements: FormElementsType = {
  // 0
  floorPlanName: {
    elementType: 'input',
    elementConfig: {
      name: 'floorPlanName',
      type: 'text',
      placeholder: 'FloorPlan Name',
    },
    optionValues: [],
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'FloorPlan Name is required',
    label: 'FloorPlan Name *',
  },
  // 1
  description: {
    elementType: 'input',
    elementConfig: {
      name: 'description',
      type: 'text',
      placeholder: 'Description',
    },
    optionValues: [],
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Description is required',
    label: 'Description *',
  },
  // 2
  partnerID: {
    elementType: 'select',
    elementConfig: {
      name: 'partnerID',
      type: 'text',
      placeholder: 'Partner ID',
    },
    optionValues: [],
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Partner ID is required',
    label: 'Partner ID *',
  },
  // 3
  topLeftLat: {
    elementType: 'input',
    elementConfig: {
      name: 'topLeftLat',
      type: 'text',
      placeholder: 'Top Left Lat',
    },
    optionValues: [],
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Top Left Lat is required',
    label: 'Top Left Lat *',
    colValue: 6,
  },
  // 4
  topLeftLng: {
    elementType: 'input',
    elementConfig: {
      name: 'topLeftLng',
      type: 'text',
      placeholder: 'Top Left Lng',
    },
    optionValues: [],
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Top Left Lng is required',
    label: 'Top Left Lng *',
    colValue: 6,
  },
  // 5
  bottomRightLat: {
    elementType: 'input',
    elementConfig: {
      name: 'bottomRightLat',
      type: 'text',
      placeholder: 'Bottom Right Lat',
    },
    optionValues: [],
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Bottom Right Lat is required',
    label: 'Bottom Right Lat *',
    colValue: 6,
  },
  // 6
  bottomRightLng: {
    elementType: 'input',
    elementConfig: {
      name: 'bottomRightLng',
      type: 'text',
      placeholder: 'Bottom Right Lng',
    },
    optionValues: [],
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Bottom Right Lng is required',
    label: 'Bottom Right Lng *',
    colValue: 6,
  },
};

export const mapFormElements: MapFormElementsType = {
  radius: {
    elementType: 'select',
    elementConfig: {
      type: 'text',
      placeholder: 'Radius',
      name: 'radius',
      // readOnly: true,
    },
    value: '1000',
    optionValues: [
      { value: '1000', text: '1km' },
      { value: '2000', text: '2km' },
      { value: '3000', text: '3km' },
      { value: '4000', text: '4km' },
      { value: '5000', text: '5km' },
    ],
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Radius is required',
    label: 'Radius',
  },
};

export interface PropsType
  extends RouteComponentProps<{
    token: string;
    floorPlanID: string;
    isEditable?: string;
  }> {}

export interface StateType {
  formElements: FormElementsType;
  loading: boolean;
  success: ApiSuccessType;
  error: ApiErrorType;
  mapDetails: MapDetailsType;
  floorPlanLocationList: FloorPlanLocationListType[];
  selectedLocation: LocationListType | {};
  showLocationModal: boolean;
  // floorPlanDetails: FloorPlanDetailsType | {};
  // currentTabIndex: string;

  token?: string;
  floorPlanID?: string;
  isEditable?: boolean;

  assetID?: string;
  userLat?: number;
  userLng?: number;
}
